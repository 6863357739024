import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';
import { fetchClientsPage, registerClient, exportClients, deleteClients } from 'actions/clientActions';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import AddClientDialog from 'components/Clients/AddClientDialog';
import ClientsTable from './ClientsTable';

import { singleCheckClickHandler, multipleCheckClickHandler } from 'utils/tableCheckboxHandlers';

import { ROLES } from 'constants/userConstants';
import './Clients.scss';

const Clients = ({
    agencyId,
    fetchClientsPage,
    registerClient,
    deleteClients,
    exportClients,
}) => {

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    const [isButtonInitial, setIsButtonInitial] = useState(true);
    const [checkedRows, setCheckedRows] = useState([]);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [clientsToRemove, setClientsToRemove] = useState([]);
    const [reload, setReload] = useState(false);

    const sortAlphabetically = (a, b) => a.name.localeCompare(b.name);

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(false);
        setClientsToRemove([]);
    };

    const removeClientsClickHandler = (client) => {
        setOpenConfirmRemoveDialog(true);
        setClientsToRemove([client]);
    };

    const handleRemoveClients = () => {
        deleteClients(agencyId, clientsToRemove.map(x => x._id));
        setOpenConfirmRemoveDialog(false);
        setCheckedRows([]);
        setIsButtonInitial(true);
    };

    const handleOpenDialog = () => setOpenAddDialog(true);

    const handleCloseDialog = () => setOpenAddDialog(false);

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleSubmitClick = async (values) => {
        if (values.email) {
            setNewUserData(values)
            setOpenConfirmDialog(true);
            return
        }

        await registerClient(agencyId, values);
        setReload(true);
        handleCloseDialog();
    };

    const handleProceedClick = async () => {
        handleCloseConfirmDialog();
        await registerClient(agencyId, newUserData);
        setReload(true);
        handleCloseDialog();
        setNewUserData({});
    };

    return (
        <div className="clients-table-wrapper">
            <ClientsTable
                handleSelectAllClick={multipleCheckClickHandler(setCheckedRows, setIsButtonInitial)}
                handleCheckClick={singleCheckClickHandler(setCheckedRows, setIsButtonInitial, checkedRows)}
                checkedRows={checkedRows}
                handleOpenDialog={handleOpenDialog}
                isButtonInitial={isButtonInitial}
                removeClientsClickHandler={removeClientsClickHandler}
                agencyId={agencyId}
                exportClients={exportClients}
                fetchData={fetchClientsPage}
                reload={reload}
                setReload={setReload}
            />

            <AddClientDialog
                openDialog={openAddDialog}
                handleCloseDialog={handleCloseDialog}
                registerClient={registerClient}
                agencyId={agencyId}
                handleSubmitClick={handleSubmitClick}
                sortAlphabetically={sortAlphabetically}
            />
            <ConfirmInviteUserDialog
                openDialog={openConfirmDialog}
                invitedUser={ROLES.CLIENT}
                handleInviteUser={handleProceedClick}
                handleCloseDialog={handleCloseConfirmDialog}
            />
            <ConfirmDeleteDialog
                itemToDelete={clientsToRemove.length > 1
                    ? `${clientsToRemove.length} clients`
                    : `${clientsToRemove[0]?.name}`
                }
                handleDeleteItem={handleRemoveClients}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    fetchClientsPage,
    registerClient,
    deleteClients,
    exportClients,
};

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
