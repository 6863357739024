import api from './api';
import requester from './requester';

const jobOportunityService = {
    uploadImage: (agencyId, oldImageName, croppedImgData) => requester(api.jobOpportunityImage(agencyId, oldImageName)).uploadFile(croppedImgData),
    deleteImage: (agencyId, imageName) => requester(api.jobOpportunityImage(agencyId, imageName)).delete(),
    createJob: (agencyId, jobData) => requester(api.jobOpportunities(agencyId)).uploadFile(jobData),
    fetchJobOpportunities: (agencyId, filters) => requester(api.jobOpportunities(agencyId), { ...filters }).getOne(),
    updateJob: (agencyId, jobId, jobData) => requester(api.jobOpportunity(agencyId, jobId)).updateFile(jobData),
    applyForJob: (agencyId, jobId, applicationData) => requester(api.jobOpportunity(agencyId, jobId)).create(applicationData),
    deleteJob: (agencyId, jobId) => requester(api.jobOpportunity(agencyId, jobId)).delete(),
    fetchJobOpportunityDetails: (agencyId, jobId) => requester(api.jobOpportunity(agencyId, jobId)).getOne(),
    updateJobOpportunityPrivacy: (agencyId, jobId, privacyData) => requester(api.jobOpportunityPrivacy(agencyId, jobId)).update(privacyData),
    fetchJobOpportunitiesTechnologies: (agencyId) => requester(api.jobOpportunitiesTechnologies(agencyId)).getMany(),
    fetchAgencyIndexedJobs: (agencyId, userId) => requester(api.indexedJobOpportunities(agencyId, userId)).getMany(),
    fetchJobOpportunityCandidateApplication: (agencyId, jobOpportunityId, candidateId, applicationId) => requester(api.jobOpportunityCandidateApplication(agencyId, jobOpportunityId, candidateId, applicationId)).getOne(),
    fetchJobOpportunityPage: (agencyId, jobId) => requester(api.jobOpportunityPage(agencyId, jobId)).getOne(),
    fetchJobMatchingConsultantsPage: (agencyId, jobId) => requester(api.jobMatchingConsultantsPage(agencyId, jobId)).getOne(),
    fetchJobOpportunitiesAdminPage: (agencyId) => requester(api.fetchJobOpportunitiesAdminPage(agencyId)).getMany(),
    exportJobOpportunities: (agencyId, filters) => requester(api.exportJobOpportunities(agencyId), { ...filters }).saveAs('jobOpportunities'),
    exportClientJobOpportunities: (agencyId, clientId, filters) => requester(api.exportClientJobOpportunities(agencyId, clientId), { ...filters }).saveAs('jobOpportunities'),
    sendProfilesToJobOpportunity: (agencyId, jobId, profiles, notifyClient) => requester(api.jobOpportunityProfiles(agencyId, jobId)).create({ profiles, notifyClient }),
    sendProfileToClient: (agencyId, jobId, profile, notifyClient) => requester(api.jobOpportunityProfiles(agencyId, jobId)).update({ profile, notifyClient }),
    getAiMatchedCandidatesScores: (agencyId, jobId, candidateIds) => requester(api.jobOpportunityProfiles(agencyId, jobId), { candidateIds }).getMany(),
    fetchMatchingJobs: (agencyId, userId) => requester(api.jobOpportunityTalentMatch(agencyId, userId)).getMany(),
    sendEmailToMatchedCandidates: (agencyId, jobId, oldJobData, updatedJobData) => requester(api.jobOpportunityMatchedEmails(agencyId, jobId)).create({ oldJobData, updatedJobData }),
}

export default jobOportunityService;
