import React from 'react';

import { LinearProgress } from '@material-ui/core';

import './MatchingLinearProgress.scss';

const MatchingLinearProgress = ({
    categoryWeights
}) => {
    return (
        <div className="applied-candidate-progress-container">
            {categoryWeights.skillsWeight >= 30 &&
                <LinearProgress
                    className="applied-candidate-progress"
                    title="Skills"
                    value={categoryWeights.skillsWeight}
                    variant="determinate"
                />
            }
            {categoryWeights.jobTitlesWeight >= 30 &&
                <LinearProgress
                    className="applied-candidate-progress"
                    title="Job Titles"
                    value={categoryWeights.jobTitlesWeight}
                    variant="determinate"
                />
            }
            {categoryWeights.industryExperienceWeight >= 30 &&
                <LinearProgress
                    className="applied-candidate-progress"
                    title="Industry Experience"
                    value={categoryWeights.industryExperienceWeight}
                    variant="determinate"
                />
            }
            {categoryWeights.managementExperienceWeight >= 30 &&
                <LinearProgress
                    className="applied-candidate-progress"
                    title="Management Experience"
                    value={categoryWeights.managementExperienceWeight}
                    variant="determinate"
                />
            }
        </div>
    );
}

export default MatchingLinearProgress;
