import React from 'react';

import { Tooltip } from '@material-ui/core';

import { STATUSES } from './JobStatusLabelConstants';

import './JobStatusLabel.scss';

const JobStatusLabel = ({
    status
}) => {
    const getStatusClass = (status) => {
        return status?.toLowerCase().split(' ').join('-');
    }

    return (
        <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={`This position is currently ${STATUSES[status?.toLowerCase()]?.toLowerCase()}`}>
            <span className={`job-status job-status-${getStatusClass(status)}`}>{STATUSES[status?.toLowerCase()]}</span>
        </Tooltip>
    );
};

export default JobStatusLabel;
