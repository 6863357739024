import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import AddClientFormView from './AddClientFormView';

import { REGISTRATION_TYPES, ROLES } from 'constants/userConstants';
import { maxLength } from './AddClientDialogConstants';

import { getAdmins, getCountries, getUserId } from 'reducers';
import { fetchAdmins } from 'actions/agencyActions'
import { fetchAllCountries } from 'actions/staticDataActions';

import {
    validateRequiredFields,
    validateEmailAddress,
    validateInputLength,
    validateRequiredPairFields
} from 'utils/formValidations';

const AddClientDialog = ({
    openDialog,
    handleCloseDialog,
    userId,
    agencyId,
    handleSubmitClick,
    sortAlphabetically,
    fetchAllCountries,
    fetchAdmins,
    accountManagers,
    countries,
}) => {
    useEffect(() => {
        fetchAllCountries();
        fetchAdmins(agencyId);
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
                contactPerson: '',
                email: '',
                accountManager: '',
                country: '',
                status: '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'accountManager', 'status']),
                    ...validateInputLength(values, [
                        { property: 'name', maxLength },
                        { property: 'contactPerson', maxLength },
                        { property: 'email', maxLength },
                    ]),
                    ...validateEmailAddress(values, 'email'),
                    ...validateRequiredPairFields(values, ['contactPerson', 'email']),
                }
            }}
            onSubmit={(values) => {
                values.name = values.name.trim();
                values.email = values.email.toLowerCase().trim();
                values.contactPerson = values.contactPerson.trim();
                values.registrationType = REGISTRATION_TYPES.INVITED_BY_ADMIN;
                values.addedBy = userId;
                values.isFirst = true;

                handleSubmitClick(values, ROLES.CLIENT);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    open={openDialog}
                    dialogTitle="Add new client account"
                    onClose={handleCloseDialog}
                    onSave={props.handleSubmit}
                    saveBtnText="Save"
                    discardBtnText="Discard"
                    size="md"
                >
                    <AddClientFormView
                        {...props}
                        agencyId={agencyId} handleCloseDialog={handleCloseDialog}
                        accountManagers={accountManagers.sort(sortAlphabetically)}
                        countries={countries}
                    />
                </CommonFormikDialog>

            }
        </Formik>
    );
}

const mapStateToProps = (state) => ({
    accountManagers: getAdmins(state),
    countries: getCountries(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
    fetchAdmins,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddClientDialog);
