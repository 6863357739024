import React, { useState } from 'react';

import MainMenu from './MainMenu';

import { getSupplierEmployeeMenuItems } from '../constants/supplierEmployeeConstants';

const SupplierEmployeeMenu = ({ agencyRouteName }) => {
    const [supplierEmployeeMenuItems] = useState(getSupplierEmployeeMenuItems(agencyRouteName));
    return (<MainMenu menuItems={supplierEmployeeMenuItems} />)
};

export default SupplierEmployeeMenu;
