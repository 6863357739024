import { PUBLIC_HOLIDAYS_MESSAGE } from "../constants/messageConstants";
import { authorizedShowNotification } from './actionHelpers';
import {
    PUBLIC_HOLIDAYS_FETCH_SUCCESS,
} from './actionTypes';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import publicHolidayService from '../services/publicHolidayService';

import { updateMomentLocale } from 'utils/formatTimes';

export const fetchPublicHolidaysSuccess = (publicHolidays) => ({
    type: PUBLIC_HOLIDAYS_FETCH_SUCCESS,
    payload: publicHolidays
});

export const fetchAgencyPublicHolidays = (agencyId, userId, role) => async dispatch => {
    try {
        const result = await publicHolidayService.fetchAgencyPublicHolidays(agencyId, userId, role);

        updateMomentLocale(result.publicHolidays, result.publicHolidaysCountryCode);

        dispatch(fetchPublicHolidaysSuccess(result.publicHolidays));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(PUBLIC_HOLIDAYS_MESSAGE.FETCH_PUBLIC_HOLIDAYS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchEmployeePublicHolidays = (agencyId, employeeId) => async dispatch => {
    try {
        const result = await publicHolidayService.fetchEmployeePublicHolidays(agencyId, employeeId);

        updateMomentLocale(result.publicHolidays, result.publicHolidaysCountryCode);
        dispatch(fetchPublicHolidaysSuccess(result.publicHolidays));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(PUBLIC_HOLIDAYS_MESSAGE.FETCH_PUBLIC_HOLIDAYS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

