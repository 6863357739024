import React from 'react';
import { Formik } from 'formik';

import UploadLinkDialogFormView from './UploadLinkDialogFormView';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import {
    validateRequiredFields,
    validateUrlAddress,
} from 'utils/formValidations';

const UploadLinkDialog = ({
    isDialogOpen,
    handleCloseDialog,
    agencyId,
    clientId,
    projectId,
    addProjectLink,
    handleCloseUploadMethodDialog,
}) => (
    <Formik
        initialValues={{ name: '', url: '' }}

        validate={values => {
            return {
                ...validateRequiredFields(values, ['name', 'url']),
                ...validateUrlAddress(values, 'url', false),
            }
        }}

        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            addProjectLink(agencyId, clientId, projectId, values);
            handleCloseUploadMethodDialog();
            handleCloseDialog();
        }}
    >
        {(props) =>
            <CommonFormikDialog
                className="project-upload-link-dialog"
                open={isDialogOpen}
                onClose={handleCloseDialog}
                maxWidth={'sm'}
                fullWidth={true}
                onSave={props.handleSubmit}
                dialogTitle='Upload Documents'
            >
                <UploadLinkDialogFormView {...props} />
            </CommonFormikDialog>
        }
    </Formik>
);

export default UploadLinkDialog;
