import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AddEditEducationFormView from './AddEditEductionFormView';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateDateFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate,
} from 'utils/formValidations';

import './AddEditEducationDialog.scss';

const AddEditEducationDialog = ({
    educationEntry,
    openDialog,
    handleCloseDialog,
    handleAddEntry,
    handleEditEntry,
}) => {
    return (
        <Dialog className="add-edit-education-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">Create new education</Typography>
                <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>

            <DialogContent className="dialog-content">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Formik
                        initialValues={{
                            title: educationEntry?.title || '',
                            institution: educationEntry?.institution || '',
                            startDate: educationEntry?.startDate ? moment.utc(educationEntry.startDate) : null,
                            endDate: educationEntry?.endDate ? moment.utc(educationEntry.endDate) : null,
                            currentlyStudy: educationEntry?.currentlyStudy || false,
                            datesUnknown: educationEntry?.datesUnknown || false,
                        }}

                        validate={values => {
                            const validateDates = values.datesUnknown
                                ? {}
                                : {
                                    ...validateDateFields(values, ['startDate', 'endDate']),
                                    ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                                    ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false)
                                };

                            return {
                                ...validateRequiredFields(values, ['title']),
                                ...validateDates
                            }
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(false);
                            const {
                                title,
                                institution,
                                startDate,
                                endDate,
                                currentlyStudy,
                                datesUnknown,
                            } = values;

                            const entry = {
                                title,
                                institution,
                                startDate: datesUnknown ? '' : addUtcOffset(startDate),
                                endDate: datesUnknown || currentlyStudy ? '' : addUtcOffset(endDate),
                                datesUnknown,
                                currentlyStudy
                            };

                            educationEntry
                                ? handleEditEntry({ _id: educationEntry._id, ...entry })
                                : handleAddEntry(entry);

                            handleCloseDialog();
                        }}
                    >
                        {(props) =>
                            <AddEditEducationFormView {...props}
                                handleCloseDialog={handleCloseDialog}
                            />}
                    </Formik>
                </MuiPickersUtilsProvider>
            </DialogContent>
        </Dialog>
    );
}

export default AddEditEducationDialog;
