export const AUTH_CONSTANTS = {
    TOKEN_REFRESH_TIME: 25 * 60 * 1000,
}

export const OOD_CODE_ERRORS = {
    EXPIRED: 'expired-action-code',
    INVALID: 'invalid-action-code',
    ACTION_CODE_PROBLEM: 'action-code',
}

export const INVALID_SESSION_MESSAGES = [
    'You are not authorized to perform this action!', 
    'You should specify id token for this request!',
    'Session is invalid. Please re-enter your credentials.',
];

export const UNAUTHORIZED_ACCESS_MESSAGES = [
    'You are not authorized to access this page.',
    'You have been timed out because of too many requests!'
]
