import moment from 'moment';

import { TABLE_COLUMN_DIRECTIONS } from 'constants/commonConstants';

export const employeeHolidaysComparer = (a, b, sortBy, sortByDir) => {
    const isAscending = sortByDir === TABLE_COLUMN_DIRECTIONS.ASCENDING;
    const fieldA = a[sortBy];
    const fieldB = b[sortBy];

    if (typeof fieldA === 'number' && typeof fieldB === 'number') {
        return isAscending ? fieldA - fieldB : fieldB - fieldA;
    }

    if (moment(fieldA).isValid() && moment(fieldB).isValid()) {
        const momentA = moment(fieldA);
        const momentB = moment(fieldB);
        return isAscending ? momentA.diff(momentB) : momentB.diff(momentA);
    }

    if (isAscending) {
        return fieldA?.localeCompare(fieldB);
    } else {
        return fieldB?.localeCompare(fieldA);
    }
};
