import React, { useState, useRef } from 'react';

import { Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

import RegisterClientFormik from '../RegisterClientFormik';

import { BASE_APP_TRADEMARK, ENVIRONMENTS } from 'constants/env';
import { CLIENT_SIGN_UP_SLIDES_DATA } from 'constants/layoutsConstants';

import { useClickOutside } from 'utils/hooks';

const RegisterClientMobileComponent = ({
    referrer,
    gclid,
    initialValues,
    registerClient,
    login,
    countries,
}) => {
    const [isFooterExpanded, setIsFooterExpanded] = useState(false);
    const [hasToggleBeenClicked, setHasToggleBeenClicked] = useState(false); // used for shrinking animation
    const [currentSlide, setCurrentSlide] = useState(0);
    const [mouseX, setMouseX] = useState(null);
    const footerRef = useRef(null);

    const toggleFooterClick = () => {
        setIsFooterExpanded(prevState => !prevState);
        setHasToggleBeenClicked(true);
    };

    const handleGoToNextSlide = () => {
        if (currentSlide + 1 <= CLIENT_SIGN_UP_SLIDES_DATA.length) {
            setCurrentSlide(prev => prev + 1);
        }
    };

    const handleGoToPreviousSlide = () => {
        if (currentSlide - 1 >= 0) {
            setCurrentSlide(prev => prev - 1);
        }
    };

    const closeAndReset = () => {
        setCurrentSlide(0);
        setIsFooterExpanded(false);
    };

    const handleMouseDown = (event) => {
        setMouseX(event.touches[0].clientX);
    }

    const handleMouseUp = (event) => {
        const currentMouseX = event.changedTouches[0].clientX;

        if (mouseX && currentMouseX < mouseX && !isCurrentSlideLast) {
            handleGoToNextSlide();
        } else if (mouseX && currentMouseX > mouseX && !(currentSlide === 0)) {
            handleGoToPreviousSlide();
        }

        setMouseX(null);
    }

    const ImageComponent = CLIENT_SIGN_UP_SLIDES_DATA[currentSlide].image;
    const isCurrentSlideLast = currentSlide === CLIENT_SIGN_UP_SLIDES_DATA.length - 1;

    const isWhoIsAvailableEnv = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE;

    useClickOutside(footerRef, closeAndReset);

    return (
        <div className="register-wrapper mobile">
            <div className="register-main-content-container grid-container rows gap-8">
                <a href={BASE_APP_TRADEMARK.LOGO_HREF}>
                    <CardMedia
                        className={`register-card-media${isFooterExpanded ? ' blur' : ''}`}
                        component="img"
                        image={BASE_APP_TRADEMARK.LOGO}
                        title={BASE_APP_TRADEMARK.TITLE}
                    />
                </a>
                <Typography variant='h3' className={`title${isFooterExpanded ? ' blur' : ''}`}>Sign up to {BASE_APP_TRADEMARK.TITLE}</Typography>
                <Typography variant='h5' className={`subtitle${isFooterExpanded ? ' blur' : ''}`}>Please complete to create your account.</Typography>
                <RegisterClientFormik
                    blur={isFooterExpanded}
                    referrer={referrer}
                    gclid={gclid}
                    initialValues={initialValues}
                    registerClient={registerClient}
                    login={login}
                    countries={countries}
                />
            </div>
            {!isWhoIsAvailableEnv &&
            <div ref={footerRef} onTouchStart={handleMouseDown} onTouchEnd={handleMouseUp} className={`footer${isFooterExpanded ? ' expand' : (hasToggleBeenClicked && !isFooterExpanded) ? ' shrink' : ''}`}>
                {!isFooterExpanded && <p className="footer-title" onClick={toggleFooterClick}>LEARN MORE</p>}
                <div className="footer-content">
                    <ImageComponent className="image" />
                    <Typography className="title" variant='h3'>{CLIENT_SIGN_UP_SLIDES_DATA[currentSlide].title}</Typography>
                    <Typography className="text" variant='h5'>{CLIENT_SIGN_UP_SLIDES_DATA[currentSlide].text}</Typography>
                    <div className="buttons-wrapper">
                        <p className="back-btn" onClick={handleGoToPreviousSlide}>{currentSlide === 0 ? '' : 'Back'}</p>
                        <p className="next-btn" onClick={isCurrentSlideLast ? closeAndReset : handleGoToNextSlide}>{isCurrentSlideLast ? 'Finish' : 'Next'}</p>
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default RegisterClientMobileComponent;
