import React, { useState, useRef } from 'react'
import moment from 'moment';
import { Formik, ErrorMessage } from 'formik';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { connect } from 'react-redux';
import { getEmploymentHolidayAllowance } from 'reducers'
import { editHolidayAllowance } from 'actions/employeeActions'

import { validateEmployeeHolidayAllowance } from 'utils/formValidations';
import FieldError from 'components/Shared/FieldError';
import { MONTH_NAME_FORMAT } from 'constants/timesFormat';

import './EmployeeHolidayAllowance.scss'

const EmployeeHolidayAllowance = ({
    employeeId,
    holidayAllowance,
    editHolidayAllowance,
    agencyId,
    departmentId,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const holidayAllowanceFormRef = useRef();

    const handleSubmit = async (holidayAllowance, resetForm) => {
        await editHolidayAllowance(holidayAllowance, employeeId, agencyId, departmentId);
        resetForm();
        setShowHeaderButtons(false);
    }

    const handleDiscard = () => {
        holidayAllowanceFormRef.current.handleReset();
        setShowHeaderButtons(false);
    }

    return (
        <div className="employment-info-wrapper holiday-allowance-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Holiday allowance'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={handleDiscard}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => holidayAllowanceFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>


            <Paper className="inner-content-wrapper">
                <Formik
                    initialValues={{
                        daysPerYear: holidayAllowance,
                    }}
                    validate={values => {
                        return { ...validateEmployeeHolidayAllowance(values, ['daysPerYear']) }
                    }}
                    innerRef={holidayAllowanceFormRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        handleSubmit(values, resetForm)
                    }}
                    enableReinitialize={true}
                >
                    {(props) => <EmployeeHolidayAllowanceFormView {...props} onInputChanged={() => setShowHeaderButtons(true)} />}
                </Formik>
            </Paper>
        </div >
    )
}

/* Your form view	*/
const EmployeeHolidayAllowanceFormView = (props) => (
    <form className="holiday-allowance-form" autoComplete="off">
        <div className="holiday-allowance-input ">
            <TextField
                name="daysPerYear"
                label={`Paid Holidays till ${moment().endOf('year').format(MONTH_NAME_FORMAT)}`}
                type="text"
                value={props.values.daysPerYear}
                onChange={props.handleChange}
                onInput={props.onInputChanged}
                error={!!props.errors.daysPerYear}
                variant="outlined"
            />
            <ErrorMessage name="daysPerYear" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
        </div>
    </form>
)

const mapStateToProps = state => ({
    holidayAllowance: getEmploymentHolidayAllowance(state),
});

const mapDispatchToProps = {
    editHolidayAllowance
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeHolidayAllowance);
