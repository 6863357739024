import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@material-ui/core/Link';
import { ErrorMessage } from 'formik';
import { motionRedirectLinks } from 'components/Register/RegisterConstants';
import PasswordInput from 'components/Common/PasswordInput';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ReactComponent as HomeIcon } from 'assets/home.svg';
import { Autocomplete } from '@material-ui/lab';

const RegisterAgencyFormView = ({
    countries,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
}) => {
    return (
        <form className="register-form-agency" autoComplete="off">
            <div className="account-name-field">
                <TextField
                    required
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Company Name"
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>
            <div className="email-field">
                <TextField
                    required
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    label="Email"
                    error={errors.email && touched.email}
                    onBlur={handleBlur}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="email" component="div" className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="login-field"
                    name="password"
                    handleChange={handleChange}
                    label="Password"
                    id="pwd-set"
                    error={errors.password}
                    touched={touched.password}
                />
                <ErrorMessage name="password" component="div" className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="login-field"
                    name="confirmPassword"
                    handleChange={handleChange}
                    label="Confirm Password"
                    id="pwd-set-confirm"
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                />
                <ErrorMessage name="confirmPassword" component="div" className="invalid-field-message" />
            </div>
            <div className="country-field">
                <Autocomplete
                    id="country"
                    value={values.country}
                    onChange={(event, newValue) => setFieldValue("country", newValue)}
                    name="country"
                    options={countries.map(country => country.name)}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                        <span>{option}</span>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onBlur={handleBlur}
                            label="Country"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />

                <ErrorMessage name="country" component="div" className="invalid-field-message" />
            </div>

            <div className="first-name-field">
                <TextField
                    required
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    label="Your First Name"
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="firstName" component="div" className="invalid-field-message" />
            </div>

            <div className="agency-final-block">
                <TextField
                    required
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    label="Your Last Name"
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="lastName" component="div" className="invalid-field-message" />
            </div>

            <footer className="sign-up-footer">
                <PrimaryButton text="Sign Up" type="submit" className="common-btn sign-up-btn" handleClick={handleSubmit} />

                <Button
                    color="primary"
                    size="small"
                    startIcon={<HomeIcon />}
                    href={motionRedirectLinks.wiaHome.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {motionRedirectLinks.wiaHome.label}
                </Button>

                <p>
                    Already have an account? <Link
                        underline="always"
                        component={RouterLink}
                        to={motionRedirectLinks.login.link}
                    >
                        {motionRedirectLinks.login.label}
                    </Link>
                </p>
            </footer>
        </form>
    )
}
export default RegisterAgencyFormView;
