import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';

import { subPages } from 'constants/pageNames';

import CandidateApplications from '../CandidateApplications';
import UpcomingInterviews from '../UpcomingInterviews';
import ProfileHiredBanner from 'components/Person/Profile/ProfileHiredBanner';
import ProfileVideoInterview from 'components/Person/Profile/ProfileVideoInterview';
import ProfileAssignee from 'components/Person/Profile/ProfileAssignee';
import ProfileTimeTracking from 'components/Person/Profile/ProfileTimeTracking';
import PersonalRelatedOpportunitiesOverview from 'components/Person/Overview';
import HiringChecklist from 'components/CandidateProfilePage/HiringChecklist';

import HistoricalNotes from 'components/Shared/HistoricalNotes';
import CandidateBasicInformation from 'components/Candidate/CandidateBasicInformation';
import CandidateContactDetails from 'components/Candidate/CandidateContactDetails';
import CandidateProfilePage from 'components/CandidateProfilePage';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    getIsRawCvPdfGenerated,
    getDisplayMode,
    getCandidateOverviewPageDataFetchState,
    getEmployeeBasicInfo,
    getHistoricalNotes,
    getContactInfo,
} from 'reducers';

import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import './CandidatePersonalInformation.scss'

export const CandidatePersonalInformationContext = createContext();

const { Provider } = CandidatePersonalInformationContext;

const CandidatePersonalInformation = ({
    userId,
    candidateId,
    agencyId,
    agencyRouteName,
    isRawCvPdfGenerated,
    isCandidateProfile,
    isSupplierEmployeeProfile,
    isMissingCvInformation,
    children,
    dataFetchState,
    basicInfo,
    pageName,
    isDataAvailable,
    displayMode,
    historicalNotes,
    contactInfo,
}) => {
    const context = {
        userId,
        candidateId,
        agencyId,
        agencyRouteName,
        isRawCvPdfGenerated,
        isCandidateProfile,
        isSupplierEmployeeProfile,
        isDataAvailable,
        displayMode,
        historicalNotes,
        basicInfo,
        contactInfo,
        isMissingCvInformation
    };

    const pageCanLoad = () => {
        if (!pageName) {
            return true;
        }
        switch (pageName) {
            case subPages.OVERVIEW:
                return !dataFetchState?.isDataFetching && dataFetchState?.isDataFetched
            default:
                return true;
        }
    }

    return (
        <Provider value={context}>
            <div className="candidate-personal-information-content-wrapper">
                {!pageCanLoad()
                    ? <CircularProgress size={50} disableShrink className="page-loader" />
                    : children
                }
            </div>
        </Provider>
    )
};

const HiredBanner = () => {
    const { candidateId, agencyId, isCandidateProfile } = useContext(CandidatePersonalInformationContext);

    return (
        <ProfileHiredBanner employeeId={candidateId} agencyId={agencyId} isCandidateProfile={isCandidateProfile} />
    )
};

const BasicInformation = () => {
    const { candidateId, agencyId, basicInfo, userId } = useContext(CandidatePersonalInformationContext);

    return (
        <CandidateBasicInformation basicInfo={basicInfo} candidateId={candidateId} agencyId={agencyId} userId={userId} />
    )
};

const ContactForm = () => {
    const { candidateId, agencyId, contactInfo } = useContext(CandidatePersonalInformationContext);

    return (
        <CandidateContactDetails agencyId={agencyId} contactInfo={contactInfo} candidateId={candidateId} />
    )
};

const VideoInterview = (props) => {

    return (
        <ProfileVideoInterview />
    )
};

const Assignee = () => {
    const { candidateId, agencyId } = useContext(CandidatePersonalInformationContext);

    return (
        <ProfileAssignee employeeId={candidateId} agencyId={agencyId} />
    )
};

const TimeTracking = () => {
    const { candidateId, agencyId } = useContext(CandidatePersonalInformationContext);

    return (
        <ProfileTimeTracking userId={candidateId} agencyId={agencyId} />
    )
};

const RelatedOpportunitiesOverview = () => {
    const { candidateId, agencyId } = useContext(CandidatePersonalInformationContext);

    return (
        <PersonalRelatedOpportunitiesOverview userId={candidateId} agencyId={agencyId} />
    )
};

const InterviewsView = () => {
    return (
        <UpcomingInterviews />
    )
};

const ProfileInformation = ({
    videoInterviewFeedback,
}) => {
    const { agencyId } = useContext(CandidatePersonalInformationContext);

    return (
        <CandidateProfilePage
            agencyId={agencyId}
            videoInterviewFeedback={videoInterviewFeedback}
        />
    )
};

const Applications = () => {
    const { displayMode, candidateId, isMissingCvInformation, agencyRouteName } = useContext(CandidatePersonalInformationContext);

    return (
        <CandidateApplications
            displayMode={displayMode}
            candidateId={candidateId}
            isMissingCvInformation={isMissingCvInformation}
            agencyRouteName={agencyRouteName}
        />
    );
};

const CandidateHistoricalNotes = ({
    ...rest
}) => {
    const { candidateId } = useContext(CandidatePersonalInformationContext);

    return (
        <HistoricalNotes
            targetId={candidateId}
            noteType={HISTORICAL_NOTES_TYPES.USER}
            isDataAvailable={false}
            {...rest}
        />
    );
};

CandidatePersonalInformation.HiredBanner = HiredBanner;
CandidatePersonalInformation.ProfileInformation = ProfileInformation;
CandidatePersonalInformation.BasicInformation = BasicInformation;
CandidatePersonalInformation.ContactForm = ContactForm;
CandidatePersonalInformation.VideoInterview = VideoInterview;
CandidatePersonalInformation.Assignee = Assignee;
CandidatePersonalInformation.TimeTracking = TimeTracking;
CandidatePersonalInformation.RelatedOpportunitiesOverview = RelatedOpportunitiesOverview;
CandidatePersonalInformation.InterviewsView = InterviewsView;
CandidatePersonalInformation.HistoricalNotes = CandidateHistoricalNotes;
CandidatePersonalInformation.HiringChecklist = HiringChecklist;
CandidatePersonalInformation.Applications = Applications;

const mapStateToProps = state => ({
    isRawCvPdfGenerated: getIsRawCvPdfGenerated(state),
    displayMode: getDisplayMode(state),
    basicInfo: getEmployeeBasicInfo(state),
    dataFetchState: getCandidateOverviewPageDataFetchState(state),
    historicalNotes: getHistoricalNotes(state).notes,
    contactInfo: getContactInfo(state),
});

export default connect(mapStateToProps, null)(CandidatePersonalInformation);
