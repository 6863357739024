import React from 'react';
import { Link } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';

import { ReactComponent as PhoneIcon } from 'assets/phone.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/google-play-icon.svg';
import { ReactComponent as AppStoreIcon } from 'assets/app-store-icon.svg';

import { LINKS } from './EvrotrustBannerConstants';

import './EvrotrustBanner.scss';

const EvrotrustBanner = () => {
    const path = `/${getStateAgencyName()}/employment/personal-info`;

    return (
        <div className="evrotrust-banner-outer-container" >
            <div className="evrotrust-banner-wrapper row">
                <div className="action-wrapper col-1of2">
                    <div className="action-wrapper-title">Update your Evrotrust email</div>
                    <div className="action-wrapper-content">You haven't updated your <span>Evrotrust email</span> on the platform. Please download and register your Evrotrust mobile application (iOS / Android) and update your information <Link to={path}>here</Link>.</div>
                    <div className="buttons-container">
                        <GooglePlayIcon className="store-btn" onClick={() => window.open(LINKS.GOOGLE_PLAY_LINK, '_blank')} />
                        <AppStoreIcon className="store-btn" onClick={() => window.open(LINKS.APP_STORE_LINK, '_blank')} />
                    </div>
                </div>
                <div className="icon-wrapper col-2of2">
                    <PhoneIcon className="image-info" />
                </div>
            </div>
        </div>
    );
};

export default EvrotrustBanner;
