import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';

import Paper from '@material-ui/core/Paper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { ReactComponent as VideoInterviewProfileImage } from 'assets/video-interview-profile.svg';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { getCandidateProgressData } from 'reducers';

import './ProfileVideoInterview.scss';

const ProfileVideoInterview = ({ candidateProgressData }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(`/${getStateAgencyName()}/interview-preferences/video-interview`);
    }

    return (
        !candidateProgressData.isInterviewCompleted
            ? <div className="video-interview-profile-wrapper">
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Video Interview'
                    />
                </SectionActionsWrapper>

                <Paper className="video-interview-profile-content">
                    <div className="video-interview-text">
                        <h2>Ready to show your expertise?</h2>
                        <p>We'd like to learn more about your personality and technical skills. Use our virtual interview module to answer 3 to 7 short questions.</p>
                        <PrimaryButton text="Go to Video Interview" handleClick={() => handleClick()} />
                    </div>
                    <div className="video-interview-image">
                        <VideoInterviewProfileImage />
                    </div>
                </Paper>
            </div>
            : ''
    )
};

const mapStateToProps = state => ({
    candidateProgressData: getCandidateProgressData(state),
});

export default connect(mapStateToProps, null)(ProfileVideoInterview);
