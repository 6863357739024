import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import moment from "moment";

import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Calendar from './Calendar';
import TrackTimeDialogUploadFile from './TrackTimeDialogUploadFile';
import TrackTimeDialog from './TrackTimeDialog';
import EditTimeDialog from './EditTimeDialog';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { showNotification } from 'actions/notificationActions';
import {
    fetchTrackedDays,
    fetchHolidays,
    trackDays,
    exportTimesheet,
    fetchTimeTrackingInformation,
} from 'actions/employeeActions';
import {
    getTrackedDays,
    getPendingHistoryList,
    getApprovedHolidays,
    getAgencyId,
    getEmployeeProjects,
    getPublicHolidays,
    getTimeTrackingDataFetchState,
    getEmploymentType,
    getEmployeeBasicInfo,
    getGeneralInfo,
    isAdmin,
} from 'reducers';

import { filterFictitiousProjects } from 'utils/timeTrackingUtils';

import { US_DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';
import { DATE_FORMAT, MONTH_FORMAT, REQUESTED_HOLIDAY_TITLE, MANDATORY_ADDITIONAL_PROJECTS } from './TimeTrackingConstants';
import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import './TimeTracking.scss';
import { ROLES } from 'constants/userConstants';

const TimeTracking = ({
    trackedDays,
    pendingHolidays,
    approvedHolidays,
    fetchTrackedDays,
    fetchHolidays,
    exportTimesheet,
    userId,
    agencyId,
    trackDays,
    departmentId,
    projects,
    publicHolidays,
    fetchTimeTrackingInformation,
    showNotification,
    dataFetchState,
    employmentType,
    employeeBasicInfo,
    employeeGeneralInfo,
    isAdmin,
}) => {
    const history = useHistory();
    const displayMonth = history.location?.state?.month;
    const currentMonth = moment().format(US_DATE_FORMAT_WITH_SLASH);
    const displayMonthFormat = moment(displayMonth || currentMonth, US_DATE_FORMAT_WITH_SLASH).format(MONTH_FORMAT);

    const [openUploadFileDialog, setOpenUploadFileDialog] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(displayMonthFormat);
    const timeTrackingUploadFileFormRef = useRef();
    const [calendarDateProp, setCalendarDateProp] = useState(displayMonth ? { date: new Date(displayMonth) } : {});

    useEffect(() => {
        fetchTimeTrackingInformation(agencyId, userId, departmentId, selectedMonth);
    }, []);

    const [selectedEvents, setSelectedEvents] = useState([]);
    const [openTrackDialog, setOpenTrackDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const handleOpenUploadFileDialog = () => setOpenUploadFileDialog(true);

    const handleOpenTrackDialog = () => setOpenTrackDialog(true);

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
        setSelectedEvents([]);
    };

    const handleCloseTrackDialog = () => {
        setOpenTrackDialog(false);
        setSelectedEvents([]);
    };

    const handleEditTimeClick = (selectedMonth, event) => {
        const isEventInCurrentMonth = event.start._i.includes(selectedMonth);

        const dates = trackedDays
            .filter(x => x?.start?.format(DATE_FORMAT) === event?.start?.format(DATE_FORMAT) && isEventInCurrentMonth)
            .map((d, i) => ({ ...d, id: i }));

        dates.forEach(date => {
            if (date && date?.project._id === event?.project._id) {
                date.selected = true;
            } else {
                date.selected = false;
            }
        });

        setSelectedEvents(dates);

        if (isEventInCurrentMonth) {
            setOpenEditDialog(true);
        }
    };

    // Handler for select one of the tracked projects from edit dialog

    const handleNavigate = newDate => {
        setCalendarDateProp(state => void 0);
        const selectedMonth = moment(newDate).format(MONTH_FORMAT);
        setSelectedMonth(selectedMonth);
        setSelectedEvents([]);
        fetchTrackedDays(selectedMonth, userId, agencyId, departmentId);
        fetchHolidays(userId, agencyId, departmentId, moment(newDate).subtract(1, 'month').format(DATE_FORMAT), moment(newDate).add(1, 'months').endOf('month').format(DATE_FORMAT));
    };

    // Track new event
    const handleTrackTime = (values) => {
        let trackedEventsData = [];

        //Delete all projects on edit
        if (values.events?.length === 0) {
            trackedEventsData = selectedEvents.map(x => ({ ...x, project: { ...x.project, _id: '' } }));
        } else if (values.events) {
            const events = [...values.events];
            const projectsTotalHours = events.reduce((acc, event) => {
                return acc + (event.hours ? Number(event.hours) : 25);
            }, 0);
            if (projectsTotalHours < 1 || projectsTotalHours > 24) {
                return;
            }

            trackedEventsData = events;
        } else {
            const currentTrackedProjects = trackedDays.filter(td => {
                return selectedEvents.some(x => x?.date === moment(td.start).format(DATE_FORMAT));
            });

            selectedEvents.concat(currentTrackedProjects).forEach(event => {
                const date = event.date ? event.date : event?.start;
                if (!event.deleted) {
                    trackedEventsData.push({
                        start: moment(date, DATE_FORMAT),
                        end: moment(date, DATE_FORMAT),
                        project: Object.keys(event.project).length > 0 ? event.project : values.project,
                        hours: event.hours || values.hours,
                        tasksDesc: event.tasksDesc || values.tasksDesc,
                        fileInfo: event.fileInfo,
                    });
                }
            });
        }

        const data = new FormData();

        timeTrackingUploadFileFormRef.current.values.filesAttached.forEach((file, i) => {
            data.append(`attachedfile${i + 1}`, file);
        });

        trackDays(agencyId, departmentId, userId, selectedMonth, trackedEventsData, data);

        handleCloseTrackDialog();
        handleCloseEditDialog();
        timeTrackingUploadFileFormRef.current.setFieldValue('filesAttached', []);
        //setSelectedEvents([]);
    };

    const handleExportTimesheet = () => exportTimesheet(selectedMonth, userId, agencyId, departmentId, filterFictitiousProjects(trackedDays));

    const handleDiscard = () => setSelectedEvents([]);

    const getHolidayDates = holidays => {
        return holidays.reduce((holidayDates, holiday) => {
            let currentDate = moment.utc(holiday.startDate).format(DATE_FORMAT);
            const holidayType = holiday.type;
            while (moment.utc(currentDate, DATE_FORMAT).isSameOrBefore(moment.utc(holiday.endDate))) {
                if (!holidayDates.includes(currentDate)) {
                    holidayDates.push({ date: currentDate, type: holidayType });
                }
                currentDate = moment.utc(currentDate, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
            }

            return holidayDates;
        }, [])
    };

    const getRequestedHolidayEvents = holidayDates => holidayDates.map(holidayDate => ({
        start: moment(holidayDate.date, DATE_FORMAT),
        end: moment(holidayDate.date, DATE_FORMAT),
        title: REQUESTED_HOLIDAY_TITLE,
        isHoliday: true
    }));

    return (
        <>
            {dataFetchState?.isDataFetching && trackedDays.length === 0
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div className="time-tracking-container">
                    <SectionActionsWrapper>
                        <SectionActionsWrapper.RightAlignedActions>
                            <SectionActionsWrapper.RightAlignedActions.PrimaryExportButton
                                text='Export Timesheet'
                                onClick={handleExportTimesheet}
                            />
                        </SectionActionsWrapper.RightAlignedActions>
                    </SectionActionsWrapper>

                    <Paper className="calendar-container">
                        <Typography className="hint-text" variant="subtitle2">Hint: you can select multiple days at once.</Typography>
                        <div className="btns-wrapper">
                            {selectedEvents.length > 0 &&
                                <div className="discard-btn-wrapper">
                                    <SecondaryButton text="Discard" handleClick={handleDiscard} buttonStyle="discard" />
                                </div>
                            }
                            <PrimaryButton text="Track Time" handleClick={handleOpenTrackDialog} className="track-time-btn" disabled={selectedEvents.length === 0} />
                        </div>

                        <Calendar
                            {...calendarDateProp}
                            projects={trackedDays}
                            pendingHolidays={getRequestedHolidayEvents(getHolidayDates(pendingHolidays))}
                            approvedHolidays={getHolidayDates(approvedHolidays)}
                            selectedMonth={selectedMonth}
                            defaultDate={new Date(moment(selectedMonth, MONTH_FORMAT).format(US_DATE_FORMAT_WITH_SLASH))}
                            selectedEvents={selectedEvents}
                            setSelectedEvents={setSelectedEvents}
                            handleNavigate={handleNavigate}
                            handleEditTimeClick={handleEditTimeClick}
                            publicHolidays={publicHolidays}
                            startDate={employeeGeneralInfo?.startDate}
                        />
                    </Paper>
                    <TrackTimeDialogUploadFile
                        formRef={timeTrackingUploadFileFormRef}
                        setOpenUploadFileDialog={setOpenUploadFileDialog}
                        openUploadFileDialog={openUploadFileDialog}
                    />
                    {openTrackDialog &&
                        <TrackTimeDialog
                            openDialog={openTrackDialog}
                            selectedEvents={selectedEvents}
                            trackedDays={trackedDays}
                            handleOpenUploadFileDialog={handleOpenUploadFileDialog}
                            uploadFilesFormRef={timeTrackingUploadFileFormRef}
                            projects={projects}
                            handleCloseDialog={handleCloseTrackDialog}
                            handleTrackTime={handleTrackTime}
                            publicHolidays={publicHolidays}
                            isFreelanceEmployee={employmentType === EMPLOYMENT_TYPES.FREELANCE_CONTRACT}
                            isPartnerProfile={employeeBasicInfo.role === ROLES.SUPPLIER_ADMIN || employeeBasicInfo.role === ROLES.SUPPLIER_EMPLOYEE}
                        />
                    }
                    {openEditDialog &&
                        <EditTimeDialog
                            openDialog={openEditDialog}
                            selectedEvents={selectedEvents}
                            handleOpenUploadFileDialog={handleOpenUploadFileDialog}
                            uploadFilesFormRef={timeTrackingUploadFileFormRef}
                            projects={projects}
                            handleCloseDialog={handleCloseEditDialog}
                            handleTrackTime={handleTrackTime}
                            trackedDays={trackedDays}
                            showNotification={showNotification}
                            isAdmin={isAdmin}
                        />
                    }
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    trackedDays: getTrackedDays(state),
    agencyId: getAgencyId(state),
    pendingHolidays: getPendingHistoryList(state),
    approvedHolidays: getApprovedHolidays(state),
    projects: [...MANDATORY_ADDITIONAL_PROJECTS, ...getEmployeeProjects(state)],
    publicHolidays: getPublicHolidays(state),
    dataFetchState: getTimeTrackingDataFetchState(state),
    employmentType: getEmploymentType(state),
    employeeBasicInfo: getEmployeeBasicInfo(state),
    employeeGeneralInfo: getGeneralInfo(state),
    isAdmin: isAdmin(state),
});

const mapDispatchToProps = {
    fetchTrackedDays,
    fetchHolidays,
    trackDays,
    exportTimesheet,
    fetchTimeTrackingInformation,
    showNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeTracking);
