import React from 'react';

import YourProfileWrapper from './Steps/YourProfileWrapper';
import JobPreferencesWrapper from './Steps/JobPreferencesWrapper';
import UploadResumeWrapper from './Steps/UploadResumeWrapper';
import VideoIntroductionWrapper from './Steps/VideoIntroductionWrapper';

import { substepIds } from './CandidateProfileJourneyConstants';
import { ScrollToTop } from 'utils/helpers';

const SUBSTEPS = {
    YOUR_PROFILE: [
        {
            _id: substepIds.expertise,
            title: 'expertise',
            getWrappedSubstebComponent: (props) => {
                return (
                    <YourProfileWrapper {...props} mainStepTitle='Your Profile'>
                        <ScrollToTop />
                        <YourProfileWrapper.JobTypeSelection substebTitle='Job Type' />
                    </YourProfileWrapper>
                )
            },
            subrouteName: 'expertise',
        },
        {
            _id: substepIds.experience,
            title: 'experience',
            getWrappedSubstebComponent: (props) => {
                return (
                    <YourProfileWrapper {...props} mainStepTitle='Your Profile'>
                        <ScrollToTop />
                        <YourProfileWrapper.ExperienceSelection substebTitle='Experience' />
                    </YourProfileWrapper>
                )
            },
            subrouteName: 'experience',
        },
    ],
    PREFERENCES: [
        {
            _id: substepIds.preferences,
            title: 'preferences',
            getWrappedSubstebComponent: (props) => {
                return (
                    <JobPreferencesWrapper mainStepTitle='Job Preferences' handleGoToNextStep={props.continueJourney}>
                        <ScrollToTop />
                        <JobPreferencesWrapper.CandidateJourneyPreferences />
                    </JobPreferencesWrapper>
                )
            },
            subrouteName: 'preferences',
        },
    ],
    UPLOAD_RESUME_FILE: [
        {
            _id: substepIds.uploadResume,
            title: 'uploadResume',
            getWrappedSubstebComponent: (props) => {
                return (
                    <UploadResumeWrapper
                        mainStepTitle='Upload Resume'
                        agencyId={props.agencyId}
                        userId={props.userId}
                        uploads={props.uploads}
                        setUploads={props.setUploads}
                    >
                        <ScrollToTop />
                        <UploadResumeWrapper.UploadResume
                            substebTitle='Upload Resume'
                            handleClick={props.continueJourney}
                        />
                    </UploadResumeWrapper>
                )
            },
            subrouteName: 'upload-cv',
        },
        {
            _id: substepIds.cvCompletion,
            title: 'cvCompletion',
            getWrappedSubstebComponent: (props) => {
                return (
                    <UploadResumeWrapper
                        mainStepTitle='Upload Resume'
                        agencyId={props.agencyId}
                        userId={props.userId}
                        handleNextStep={props.continueJourney}
                        discardAction={props.discardAction}
                    >
                        <UploadResumeWrapper.CvCompletionPage substebTitle='Cv Completion' />
                    </UploadResumeWrapper>
                )
            },
            subrouteName: 'edit-cv',
        }
    ],
    VIDEO_INTRODUCTION: [
        {
            _id: substepIds.choiseSection,
            title: 'choiseSection',
            getWrappedSubstebComponent: (props) => {
                return (
                    <VideoIntroductionWrapper mainStepTitle='Personal Introduction'>
                        <ScrollToTop />
                        <VideoIntroductionWrapper.NearlyThereScreen handleGoToNextStep={props.continueJourney} />
                    </VideoIntroductionWrapper>
                )
            },
            subrouteName: 'finish-profile',
        },
        {
            _id: substepIds.videoIntroduction,
            title: 'videoIntroduction',
            getWrappedSubstebComponent: (props) => {
                return (
                    <VideoIntroductionWrapper mainStepTitle='Personal Introduction' >
                        <VideoIntroductionWrapper.VideoIntroduction substebTitle='Personal Introduction'  {...props} />
                    </VideoIntroductionWrapper>
                )
            },
            subrouteName: 'video-introduction',
        },
    ]
};

const getUploadResumeSubsteps = () => {
    return SUBSTEPS.UPLOAD_RESUME_FILE;
};

const getYourProfileSubsteps = () => {
    return SUBSTEPS.YOUR_PROFILE;
};

const getJobPreferencesSubsteps = () => {
    return SUBSTEPS.PREFERENCES;
};

const getVideoIntroductionSubsteps = () => {
    return SUBSTEPS.VIDEO_INTRODUCTION;
};

export const CANDIDATE_CREATE_PROFILE_STEPS = () => [
    {
        title: 'Your Profile',
        subSteps: getYourProfileSubsteps()
    },
    {
        title: 'Job Preferences',
        subSteps: getJobPreferencesSubsteps()
    },
    {
        title: 'Upload Resume',
        subSteps: getUploadResumeSubsteps()
    },
    {
        title: 'Personal Introduction',
        subSteps: getVideoIntroductionSubsteps()
    },
];

export const getMainStepTitles = () => CANDIDATE_CREATE_PROFILE_STEPS().map(x => x.title);

export const getCandidateCurrentMainStepTitle = (journeyStepId) => {
    let stepTitle = '';

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();


    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (journeyStepId === steps[mainStepIndex].subSteps[subStepIndex]._id) {
                stepTitle = steps[mainStepIndex].title;
                break;
            }
        }

        if (stepTitle) {
            break;
        }
    }

    return stepTitle;
};

export const getCandidateCompletedMainStepTitles = (journeyStepsIds) => {
    let completedMainStepTitles = getMainStepTitles();

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();


    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (!journeyStepsIds.includes(steps[mainStepIndex].subSteps[subStepIndex]._id)) {
                completedMainStepTitles = completedMainStepTitles.filter(e => e !== steps[mainStepIndex].title);
                break;
            }
        }
    }

    return completedMainStepTitles;
};

export const getHasJourneyStepsLeft = (journeyStepsIds) => {
    let hasJourneyStepsLeft = false;

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (!journeyStepsIds.includes(steps[mainStepIndex].subSteps[subStepIndex]._id)) {
                hasJourneyStepsLeft = steps[mainStepIndex].subSteps[subStepIndex]._id;
                break;
            }
        }

        if (hasJourneyStepsLeft) {
            break;
        }
    }

    return hasJourneyStepsLeft;
};

export const getCandidateCurrentStepComponentAndSubrouteName = (journeyStepId) => {
    let stepComponent;
    let stepSubrouteName;

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (journeyStepId === steps[mainStepIndex].subSteps[subStepIndex]._id) {
                stepComponent = steps[mainStepIndex].subSteps[subStepIndex].getWrappedSubstebComponent;
                stepSubrouteName = steps[mainStepIndex].subSteps[subStepIndex].subrouteName;
                break;
            }
        }

        if (stepComponent) {
            break;
        }
    }

    return {
        StepComponent: stepComponent,
        subrouteName: stepSubrouteName
    };
};

export const getCandidateNextStepId = (journeyStepId) => {
    let _id = '';

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        for (let subStepIndex = 0; subStepIndex < steps[mainStepIndex].subSteps.length; subStepIndex++) {
            if (journeyStepId === steps[mainStepIndex].subSteps[subStepIndex]._id) {
                if (steps[mainStepIndex].subSteps.length > subStepIndex + 1) {
                    _id = steps[mainStepIndex].subSteps[subStepIndex + 1]._id;
                    break;
                } else if (steps.length > mainStepIndex + 1) {
                    _id = steps[mainStepIndex + 1].subSteps[0]._id;
                    break;
                }
            }
        }

        if (_id) {
            break;
        }
    }

    return _id || journeyStepId;
};

export const getCandidatePreviousStepId = (journeyStepId) => {
    let _id = '';

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = steps.length - 1; mainStepIndex >= 0; mainStepIndex--) {
        for (let subStepIndex = steps[mainStepIndex].subSteps.length - 1; subStepIndex >= 0; subStepIndex--) {
            if (journeyStepId === steps[mainStepIndex].subSteps[subStepIndex]._id) {
                if (subStepIndex - 1 >= 0) {
                    _id = steps[mainStepIndex].subSteps[subStepIndex - 1]._id;
                    break;
                } else if (mainStepIndex - 1 >= 0) {
                    _id = steps[mainStepIndex - 1].subSteps[steps[mainStepIndex - 1].subSteps.length - 1]._id;
                    break;
                }
            }
        }

        if (_id) {
            break;
        }
    }

    return _id || journeyStepId;
};

export const getFirstStepIdOfTitle = (title) => {
    const stepData = CANDIDATE_CREATE_PROFILE_STEPS().find(x => x.title === title)
    return stepData.subSteps[0]._id;
};

export const getCandidateNextMainStepTitle = (completedStepsIds) => {
    let stepTitle = '';
    const lastCompletedStep = completedStepsIds[completedStepsIds.length - 1];

    const steps = CANDIDATE_CREATE_PROFILE_STEPS();

    for (let mainStepIndex = 0; mainStepIndex < steps.length; mainStepIndex++) {
        const foundStep = steps[mainStepIndex].subSteps.find(x => x._id === lastCompletedStep)
        if (foundStep && steps[mainStepIndex + 1]) {
            stepTitle = steps[mainStepIndex + 1].title;
            break;
        }
    }

    return stepTitle;
};

export const isCvAvailable = (data) => {
    let isAvailable = false;

    if (data.profileSummary || data.profileInformation.position) {
        isAvailable = true;
    } else {
        for (const field in data) {
            if (Array.isArray(data[field]) && data[field].length > 0) {
                isAvailable = true;
            }
        }
    }
    return isAvailable;
}
