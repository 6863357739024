import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    mapDispatchToPropsPicker,
    getBrowserLocation
} from './TeamHolidaysUtils';

import { fetchAgencyEvrotrustInfo } from 'actions/agencyActions';
import {
    deleteHolidayRequests,
    downloadSignedHolidayFile,
    sendHolidaySignReminderEmail,
} from 'actions/employeeActions';

import { exportDocumentsData } from 'actions/documentActions';
import { showNotification } from 'actions/notificationActions';

import {
    getAgencyId,
    getPublicHolidays,
    getDisplayMode,
    getAccess,
    getAgencyBasicInfo,
    getActiveHolidaysTableDataFetchState,
} from 'reducers';

import { getStateAgencyName } from 'utils/helpers';

import ActiveHolidays from 'components/Shared/ActiveHolidays';
import PendingTeamHolidaysTable from './PendingTeamHolidaysTable';
import HistoryTeamHolidaysTable from './HistoryTeamHolidaysTable';

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants.js';
import { ROLES } from 'constants/userConstants';

const TeamHolidays = ({
    userId,
    agencyId,
    publicHolidays,
    displayMode,
    fetchPendingHolidays,
    fetchReviewedHolidays,
    fetchSickLeaves,
    deleteHolidayRequests,
    downloadSignedHolidayFile,
    sendHolidaySignReminderEmail,
    accessAsManager,
    fetchAgencyEvrotrustInfo,
    agencyInfo,
    showNotification,
    fetchApprovedHolidays,
    activeHolidaysDataFetchState,
    exportDocumentsData,
    downloadDocumentsFiles,
}) => {
    const [pendingReload, setPendingReload] = useState(false);
    const [historyReload, setHistoryReload] = useState(false);

    useEffect(() => {
        const from = moment().format(DATE_FORMAT_WITH_DASH);
        const to = moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH);

        fetchApprovedHolidays(agencyId, from, to, userId);
        fetchSickLeaves(agencyId, from, to, userId);
    }, []);

    const handleDownloadSignedFile = holiday => {
        const userInfo = { firstName: holiday.firstName, lastName: holiday.lastName };
        downloadSignedHolidayFile(agencyId, null, holiday, userInfo);
    }

    const generateToString = holiday => {
        if (displayMode === ROLES.EMPLOYEE && accessAsManager.length > 0) {
            return `/${getStateAgencyName()}/departments/${accessAsManager[0]._id}/members/${holiday.employeeId}/holidays`;
        }

        if (displayMode !== ROLES.EMPLOYEE) {
            return `/${getStateAgencyName()}/team/people/${holiday.employeeId}/holidays`;
        }
        return "";
    };

    return (
        <div className="team-holidays-wrapper">
            <PendingTeamHolidaysTable
                userId={userId}
                agencyId={agencyId}
                generateToString={generateToString}
                tableFetchAction={fetchPendingHolidays.bind(null, agencyId, userId)}
                pendingReload={pendingReload}
                setPendingReload={setPendingReload}
                setHistoryReload={setHistoryReload}
            />

            <ActiveHolidays
                dataFetchState={activeHolidaysDataFetchState}
                publicHolidays={publicHolidays}
            />

            <HistoryTeamHolidaysTable
                userId={userId}
                agencyId={agencyId}
                displayMode={displayMode}
                generateToString={generateToString}
                isUsingEvrotrust={agencyInfo.isUsingEvrotrust}
                tableFetchAction={fetchReviewedHolidays.bind(null, agencyId, userId)}
                tablePrefetchAction={fetchAgencyEvrotrustInfo.bind(null, agencyId)}
                historyReload={historyReload}
                setHistoryReload={setHistoryReload}
                sendHolidaySignReminderEmail={sendHolidaySignReminderEmail}
                handleDownloadSignedFile={handleDownloadSignedFile}
                deleteHolidayRequests={deleteHolidayRequests}
                showNotification={showNotification}
                exportDocumentsData={exportDocumentsData}
                downloadDocumentsFiles={downloadDocumentsFiles}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        agencyId: getAgencyId(state),
        publicHolidays: getPublicHolidays(state),
        displayMode: getDisplayMode(state),
        accessAsManager: getAccess(state),
        agencyInfo: getAgencyBasicInfo(state),
        activeHolidaysDataFetchState: getActiveHolidaysTableDataFetchState(state),
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const browserLocation = getBrowserLocation(ownProps.history);
    const dispatchToPropsPicker = mapDispatchToPropsPicker(browserLocation);

    return {
        fetchPendingHolidays: (...args) => dispatch(dispatchToPropsPicker.fetchPendingHolidays(...args)),
        fetchReviewedHolidays: (...args) => dispatch(dispatchToPropsPicker.fetchReviewedHolidays(...args)),
        fetchSickLeaves: (...args) => dispatch(dispatchToPropsPicker.fetchSickLeaves(...args)),
        deleteHolidayRequests: (...args) => dispatch(deleteHolidayRequests(...args)),
        downloadSignedHolidayFile: (...args) => dispatch(downloadSignedHolidayFile(...args)),
        sendHolidaySignReminderEmail: (...args) => dispatch(sendHolidaySignReminderEmail(...args)),
        fetchAgencyEvrotrustInfo: (...args) => dispatch(fetchAgencyEvrotrustInfo(...args)),
        fetchApprovedHolidays: (...args) => dispatch(dispatchToPropsPicker.fetchApprovedHolidays(...args)),
        exportDocumentsData: (...args) => dispatch(exportDocumentsData(...args)),
        downloadDocumentsFiles: (...args) => dispatch(dispatchToPropsPicker.downloadDocumentsFiles(...args)),
        showNotification: (...args) => dispatch(showNotification(...args)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamHolidays);
