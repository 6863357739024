export const CONTRACT_TYPES = {
    CLIENT: [
        { value: 'Non-disclosure Agreement' },
        { value: 'Master Service Agreement' },
        { value: 'SOW - Fixed Price' },
        { value: 'SOW - Time and Material' },
        { value: 'SOW - Support' },
        { value: 'Other' }
    ],
    EMPLOYEE: [
        { value: 'Permanent Employment Contract' },
        { value: 'Freelance Employment Contract' },
        { value: 'Other' }
    ],
    SUPPLIER: [
        { value: 'Other' },
    ],
}

export const CONTRACT_SIGNATURE_CASES = {
    RECEIVER: 'onlyReceiverSignatureRequired',
    AGENCY: 'onlyAgencySignatureRequired',
    ALL: 'eSignatureRequired',
};

export const CONTRACT_SIGNATURE_STATUSES = {
    PENDING: 'Sign pending',
    REJECTED: 'Sign rejected',
    SIGNED: 'Signed',
    NOT_APPLICABLE: 'N/A',
    ERROR: 'E-sign Error',
};
