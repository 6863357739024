import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import PiggyBankIcon from 'assets/PiggyBankIcon';
import HourGlassIcon from 'assets/HourGlassIcon';

import { getDedicatedEmployeeValue } from '../../../ProjectsUtils';

import styleConstants from 'style-constants.scss';
import './EmployeeProfilePopUp.scss';

const EmployeeProfilePopUp = ({
	employee,
	isClientMode,
	projectCurrency,
	handleOpenSubmitFeedbackPopup,
}) => {
	const employeeMonthlyServiceFee = getDedicatedEmployeeValue(employee.monthlyServiceFee);
	const employerTotalCost = getDedicatedEmployeeValue(employee.employerTotalCost);
	const currencyToDisplay = isClientMode ? projectCurrency : employee.currency;

	return (
		<List className="popover-list-container">
			<ListItem button className="popover-list-item">
				<ListItemIcon className="popover-list-icon">
					<HourGlassIcon style={{ color: styleConstants.greyDark }} />
				</ListItemIcon>
				<ListItemText
					className="list-item-text"
					primary={`${employeeMonthlyServiceFee} ${currencyToDisplay || ""} monthly service fee`}
				/>
			</ListItem>

			<ListItem button className="popover-list-item">
				<ListItemIcon className="popover-list-icon">
					<PiggyBankIcon style={{ color: styleConstants.greyDark }} />
				</ListItemIcon>
				<ListItemText
					className="list-item-text"
					primary={`${employerTotalCost} ${currencyToDisplay || ""} cost to employer`}
				/>
			</ListItem>

			{isClientMode && (
				<ListItem button className="popover-list-item submit-feedback-container">
					<ListItemIcon className="popover-list-icon">
						<AnnouncementIcon style={{ color: styleConstants.greyDark }} />
					</ListItemIcon>
					<ListItemText
						onClick={handleOpenSubmitFeedbackPopup}
						primary="Submit Feedback"
						className="list-item-text"
					/>
				</ListItem>
			)}
		</List>
	)
};

export default EmployeeProfilePopUp;
