export const DATE_FORMAT = 'DD/MM/YY';
export const NAME_MAX_LENGTH = 50;
export const DESCRIPTION_MAX_LENGTH = 1000;
export const feedbackCriteria = ['Problem Solving', 'Accomplished Tasks', 'Communication', 'Motivation'];

export const FILTER_TYPES = {
    ACTIVE: 'Active',
    PAST: 'Past',
}

export const PROJECTS_STATUS_SELECT = [
    { value: 1, name: 'All' },
    { value: 2, name: 'Active' },
    { value: 3, name: 'Past' },
];

export const PROJECTS_STATUS_SELECT_VALUES = [
    'All', 
    'Active',
    'Past',
];

export const PROJECTS_STATUS_SELECT_MAPPER = {
    'All': PROJECTS_STATUS_SELECT[0].name,
    'Active': PROJECTS_STATUS_SELECT[1].name,
    'Past': PROJECTS_STATUS_SELECT[2].name,
}

export const DEFAULT_FILTER_TYPE =  PROJECTS_STATUS_SELECT[0].name;
