import React from 'react';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as DragIcon } from 'assets/drag-icon.svg';

import './ProfileInfoRow.scss';

const ProfileInfoRow = ({
    item,
    editItem,
    removeItem,
    isViewMode,
    shouldBeDraggable,
    isPerksAndEquipmentEmployeeMode
}) => {

    return <div className="profile-info-row global-profile-info-row">
        {shouldBeDraggable &&
            <div className="drag-icon" title="Drag and drop projects to arrange">
                <DragIcon />
            </div>
        }

        {item.documentUrl
            ? <Link
                href={item.documentUrl.startsWith('https://') || item.documentUrl.startsWith('http://')
                    ? item.documentUrl
                    : `//${item.documentUrl}`}
                className="profile-row-name" target="_blank" rel="noreferrer">
                {item.title || item.name}
            </Link>
            : <Typography className="profile-row-name" variant="subtitle2">{item.title || item.name}</Typography>}

        {!isViewMode &&
            <>
                <button className="row-edit-icon-wrapper" onClick={() => editItem(item)}></button>
                <button className="row-remove-icon" onClick={() => removeItem(item)}></button>
            </>}
        {isPerksAndEquipmentEmployeeMode &&
        <>
            <button className="row-edit-icon-wrapper" onClick={() => editItem(item)}></button>
        </>}
    </div>
};

export default ProfileInfoRow;
