import React from 'react';

import TextField from '@material-ui/core/TextField';
import { ErrorMessage } from 'formik';

import './AddCriteriaFormView.scss';

const AddCriteriaFormView = ({
    values,
    handleChange,
    errors,
    touched,
    title
}) => (
        <form className="add-criteria-form" autoComplete="off">
            <div>
                <TextField
                    required
                    className="add-criteria-field"
                    name="criteria"
                    value={values.criteria}
                    onChange={handleChange}
                    label={title[0].toUpperCase() + title.substring(1)}
                    error={errors.criteria && touched.criteria}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="criteria" component="div" className="invalid-field-message" />
            </div>
        </form>
    );

export default AddCriteriaFormView;
