import React from 'react';

const PiggyBankIcon = ({ style = {} }) => (
	<div className='biggy-bank-icon-container'>
		<svg
			version='1.0'
			style={style}
			viewBox='0 0 566.000000 490.000000'
			width='25px'
			height='25px'
			preserveAspectRatio='xMidYMid meet'>
			<g
				transform='translate(0.000000,490.000000) scale(0.100000,-0.100000)'
				fill={style.color || '#000000'}
				stroke='none'>
				<path
					d='M2757 4635 c-189 -38 -351 -147 -452 -305 -82 -129 -109 -223 -108
                -380 1 -131 20 -207 81 -325 43 -83 169 -213 252 -262 246 -144 566 -122 785
                54 261 211 334 568 177 868 -25 47 -64 98 -122 156 -72 74 -99 93 -180 132
                -148 71 -286 91 -433 62z m288 -130 c170 -51 316 -189 378 -355 96 -259 -19
                -558 -267 -694 -174 -96 -391 -92 -565 11 -163 97 -282 304 -281 490 1 262
                193 500 452 558 75 18 210 13 283 -10z'
				/>
				<path
					d='M2850 4382 c0 -38 -1 -39 -54 -55 -81 -26 -136 -100 -136 -184 0 -21
                5 -23 54 -23 l53 0 6 36 c6 39 41 84 64 84 10 0 13 -25 13 -109 l0 -109 -48
                -16 c-60 -20 -120 -69 -138 -112 -8 -19 -14 -60 -14 -92 0 -105 56 -178 157
                -204 l43 -10 0 -49 0 -49 35 0 35 0 0 49 c0 48 1 50 33 56 98 22 158 88 161
                177 l1 53 -50 0 c-49 0 -50 0 -55 -35 -8 -45 -25 -77 -50 -90 -38 -20 -40 -13
                -40 113 l0 122 46 19 c59 24 103 62 120 103 17 39 18 121 3 160 -16 45 -68 91
                -121 109 -46 15 -48 17 -48 55 0 38 -1 39 -35 39 -34 0 -35 -1 -35 -38z m0
                -586 l0 -115 -31 11 c-22 8 -37 23 -50 53 -25 57 -12 106 39 140 20 14 38 25
                39 25 2 0 3 -52 3 -114z'
				/>
				<path
					d='M3966 3415 c-137 -73 -263 -135 -280 -138 -19 -3 -64 6 -118 25 l-87
                29 -68 -51 c-37 -29 -73 -55 -81 -58 -8 -2 39 -22 104 -44 65 -21 128 -48 141
                -60 44 -42 23 -119 -38 -141 -10 -3 -70 12 -135 33 -149 48 -236 66 -395 80
                -210 19 -417 -7 -643 -80 -109 -36 -122 -38 -151 -26 -37 16 -59 64 -46 99 15
                39 48 58 168 97 65 22 113 39 108 39 -6 1 -44 26 -85 57 l-74 56 -85 -32
                c-372 -141 -671 -400 -824 -712 -52 -105 -95 -244 -104 -333 -9 -93 -17 -107
                -55 -101 -18 3 -54 8 -80 11 l-47 6 19 42 c12 25 20 65 20 99 0 50 -4 62 -32
                94 -43 49 -100 74 -168 74 -133 0 -204 -101 -165 -236 13 -45 28 -68 70 -109
                l54 -53 -45 -52 c-24 -28 -44 -58 -44 -65 0 -16 27 -45 42 -45 6 0 40 27 74
                60 l63 61 35 -15 c39 -16 163 -44 215 -48 l34 -3 18 -87 c49 -247 149 -426
                350 -633 64 -66 121 -130 128 -142 6 -12 29 -127 50 -257 41 -241 49 -269 77
                -280 8 -3 118 -6 243 -6 276 0 261 -7 261 128 0 86 1 92 25 108 13 8 28 13 34
                10 32 -20 280 -46 436 -46 156 0 404 26 436 46 6 3 21 -2 34 -10 24 -16 25
                -22 25 -110 0 -133 -14 -126 262 -126 125 0 234 3 242 6 28 11 34 34 71 255
                19 118 40 232 46 253 8 29 42 71 133 165 123 128 190 217 251 337 35 69 59
                100 85 114 8 4 80 20 160 34 172 32 194 39 209 72 7 17 11 114 11 289 0 283
                -4 306 -49 324 -22 9 -289 61 -312 61 -21 0 -71 58 -98 113 -37 75 -108 184
                -166 252 -54 64 -65 86 -65 128 0 19 22 153 50 298 27 144 47 266 44 271 -11
                18 -49 2 -288 -127z m68 -716 c81 -63 71 -170 -18 -210 -34 -14 -50 -16 -76
                -9 -45 13 -88 56 -96 95 -19 101 111 186 190 124z'
				/>
			</g>
		</svg>
	</div>
);
export default PiggyBankIcon;
