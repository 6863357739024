import React from 'react';

import moment from 'moment';
import { ErrorMessage } from 'formik';
import MomentUtils from '@date-io/moment';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const PersonalInfoPersonalFormView = ({
    errors,
    touched,
    values,
    handleChange,
    setFieldValue,
    handleShowHeaderButtons,
}) => {
    return (
        <form className="person-personal-form" autoComplete="off">
            <div className="person-birthdate-field personal-info-form-field">
                <MuiPickersUtilsProvider
                    utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk
                        disableFuture
                        label="Date of birth"
                        name="birthdate"
                        openTo="year"
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        value={values.birthdate}
                        onChange={value => {
                            value
                                ? setFieldValue('birthdate', moment(value))
                                : setFieldValue('birthdate', null);
                            handleShowHeaderButtons();
                        }}
                        views={["year", "month", "date"]}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className='personal-info-form-field'>
                <TextField
                    name="hobbies"
                    value={values.hobbies}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    label="Hobbies"
                    error={errors.hobbies && touched.hobbies}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                />
                <ErrorMessage name="hobbies" component="div" className="invalid-field-message" />
            </div>
            <div className="personal-info-form-field person-fun-fact-field last">
                <TextField
                    name="funFact"
                    value={values.funFact}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    id="person-fun-fact"
                    label="Fun Fact"
                    error={errors.funFact && touched.funFact}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                />
                <ErrorMessage name="funFact" component="div" className="invalid-field-message" />
            </div>
        </form>
    )
};

export default PersonalInfoPersonalFormView;
