import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';



import {
    updateBasicInfo,
    updateAvatar,
    deleteAvatar,
} from 'actions/employeeActions';
import {
    getEmployeeBasicInfo,
    isAdminMode,
    isSupplierAdminMode,
    getCurrentUser,
} from 'reducers';

import PersonalInfoBasicFormView from './PersonalInfoBasicFormView';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ImageEditDialog from 'components/Shared/ImageEditDialog';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import {
    validateRequiredFields,
    validateEmailAddress,
} from 'utils/formValidations';

import { ROLES } from 'constants/userConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { NOTIFICATIONS_CONTAINER_COLORS } from 'components/Shared/SectionActionsWrapper/SectionActionsWrapperConstants';
import { REFERRAL_CAMPAIGN_COLORS } from 'constants/referralCampaign';

import './PersonalInfoBasicForm.scss';
import 'react-image-crop/lib/ReactCrop.scss';

const PersonalInfoBasicForm = ({
    agencyId,
    employeeId,
    departmentId,
    authUserId,
    basicInfo,
    updateBasicInfo,
    updateAvatar,
    isDepartmentView,
    deleteAvatar,
    isAdminMode,
    isSupplierAdminMode,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);
    const [currentImgSelected, setCurrentImgSelected] = useState(basicInfo.profilePictureImgUrl);
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);
    const profileImgRef = useRef();

    const isRecruiterEmployeeProfile =
        basicInfo.role === ROLES.RECRUITER_EMPLOYEE;
    const isRegistrationDateVisible =
        (isAdminMode || isSupplierAdminMode) && basicInfo.role === ROLES.CANDIDATE;

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener('load', () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setIsEditImgDialogOpen(true);
    };

    const handleCloseEditImgDialog = () => {
        setIsEditImgDialogOpen(false);
        setCurrentImgSelected(null);
        profileImgRef.current.value = '';
    }

    const handleUpdateAvatar = (imgData) => {
        updateAvatar(imgData, employeeId, agencyId, departmentId, authUserId);
    };

    const handleDeleteAvatar = () => {
        if (basicInfo?.profilePictureImgUrl && basicInfo?.profilePictureImgUrl !== '') {
            deleteAvatar(employeeId, agencyId, departmentId, authUserId);
            setCurrentImgSelected('');
        };
    }

    const handleUpdateInfo = async (updatedInfo, resetForm, email) => {
        await updateBasicInfo(
            agencyId,
            departmentId,
            employeeId,
            updatedInfo,
            authUserId,
            email
        );
        resetForm();
    };

    const basicInfoFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        basicInfoFormRef.current.values.firstName = basicInfo?.firstName;
        basicInfoFormRef.current.values.lastName = basicInfo?.lastName;
        basicInfoFormRef.current.values.email = basicInfo?.email || '';
    };

    return (
        <div className='personal-info-wrapper personal-basic-info'>
            <SectionActionsWrapper>
                <div className='flex-buttons'>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Basic'
                    />

                    {isRegistrationDateVisible && (
                        <SectionActionsWrapper.LeftAlignedActions>
                            <SectionActionsWrapper.LeftAlignedActions.NotificationsContainer
                                className='no-upper-case-button'
                                text={`Registration: ${moment(basicInfo.registrationDate).format(
                                    DATE_FORMAT_WITH_DOTS_FULL_YEAR
                                )}`}
                                color={NOTIFICATIONS_CONTAINER_COLORS.ORANGE}
                                isLowerCase={true}
                            />
                        </SectionActionsWrapper.LeftAlignedActions>
                    )}
                </div>

                {basicInfo.referrer && (
                    <div className='referrer-button-wrapper'>
                        <SectionActionsWrapper.LeftAlignedActions>
                            <SectionActionsWrapper.LeftAlignedActions.NotificationsContainer
                                className='no-upper-case-button referrer-info'
                                text={`Referral Campaign: ${basicInfo.referrer}`}
                                color={
                                    REFERRAL_CAMPAIGN_COLORS[basicInfo.referrer] ||
                                    REFERRAL_CAMPAIGN_COLORS['WebsiteOrganic']
                                }
                                isLowerCase={true}
                            />
                        </SectionActionsWrapper.LeftAlignedActions>
                    </div>
                )}

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => basicInfoFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>


            <Paper className="personal-info-content">
                <div className="personal-info-avatar-wrapper">
                    <Avatar className="personal-info-avatar" src={basicInfo?.profilePictureImgUrl} />
                    {
                        <div className="avatar-actions">
                            {basicInfo?.profilePictureImgUrl &&
                                <IconButton className="avatar-delete-button icon-button" size="small" onClick={handleDeleteAvatar}>
                                    <CloseIcon className="close-icon" fontSize="inherit" />
                                </IconButton>
                            }
                            <input
                                accept="image/*"
                                className="upload-input"
                                id="upload-file-input"
                                type="file"
                                onChange={imageSelectedHandler}
                                ref={profileImgRef}
                            />
                            <IconButton className="avatar-edit-button icon-button" size="small" onClick={() => { profileImgRef.current.click(); }}>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </IconButton>
                        </div>
                    }
                </div>

                <Formik
                    initialValues={{
                        firstName: basicInfo?.firstName,
                        lastName: basicInfo?.lastName,
                        email: basicInfo?.email || '',
                    }}
                    validate={(values) => {
                        return {
                            ...validateRequiredFields(values, ['firstName', 'lastName']),
                            ...validateEmailAddress(values, 'email'),
                        };
                    }}
                    innerRef={basicInfoFormRef}
                    onSubmit={(values, { resetForm }) => {
                        values.firstName = values.firstName.trim();
                        values.lastName = values.lastName.trim();
                        values.email = values.email.toLowerCase().trim();
                        values.role = basicInfo.role;
                        handleUpdateInfo(values, resetForm, basicInfo.email);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) => (
                        <PersonalInfoBasicFormView
                            {...props}
                            isAdminMode={isAdminMode}
                            isSupplierAdminMode={isSupplierAdminMode}
                            isRecruiterEmployeeProfile={isRecruiterEmployeeProfile}
                            isDepartmentView={isDepartmentView}
                            handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                        />
                    )}
                </Formik>
            </Paper >

            <ImageEditDialog
                dialogTitle="Edit Profile Picture"
                isDialogOpen={isEditImgDialogOpen}
                handleCloseDialog={handleCloseEditImgDialog}
                currentImgSelected={currentImgSelected}
                handleUpdateAvatar={handleUpdateAvatar}
                handleDeleteAvatar={handleDeleteAvatar}
            />
        </div >
    );
};

const mapStateToProps = (state) => ({
    basicInfo: getEmployeeBasicInfo(state),
    isAdminMode: isAdminMode(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    authUserId: getCurrentUser(state)._id,
});

const mapDispatchToProps = {
    updateBasicInfo,
    updateAvatar,
    deleteAvatar,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonalInfoBasicForm);
