import React from 'react';

import moment from 'moment';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { PROJECT_TYPES_ARRAY } from 'constants/projectConstants';

import { ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

import isHoliday from 'hoc/isHoliday';

import './AddProjectsFormView.scss';


const AddProjectsFormView = ({
	values,
	handleChange,
	errors,
	touched,
	setFieldValue,
}) => (
	<form className="add-project-form" autoComplete="off">
		<div className="name-type-fields-block">
			<div className="name-field">
				<TextField
					name="name"
					value={values.name}
					onChange={handleChange}
					label="Name"
					error={errors.name && touched.name}
					margin="normal"
					variant="outlined"
				/>
				<ErrorMessage
					name="name"
					component="div"
					className="invalid-field-message"
				/>
			</div>

			<div className="type-field">
				<TextField
					select
					name="type"
					value={values.type}
					onChange={handleChange}
					label="Type"
					error={errors.type && touched.type}
					variant="outlined"
					margin="normal"
				>
					{PROJECT_TYPES_ARRAY.map((type, i) => (
						<MenuItem
							native="true"
							key={i}
							value={type.key}
						>
							{type.value}
						</MenuItem>
					))}
				</TextField>
				<ErrorMessage name="type" component="div" className="invalid-field-message" />
			</div>
		</div>
		<div className="description-field">
			<TextField
				name="description"
				value={values.description}
				onChange={handleChange}
				label="Description"
				error={errors.description && touched.description}
				margin="normal"
				variant="outlined"
				multiline
				minRows={4}
				fullWidth
			/>
			<ErrorMessage
				name="description"
				component="div"
				className="invalid-field-message"
			/>
		</div>
		<div className="switch-wrapper">
			<FormControlLabel
				control={
					<Switch
						name="ongoing"
						checked={values.ongoing}
						onChange={handleChange}
						size="small"
						color="primary"
					/>
				}
				label="Ongoing"
				labelPlacement="start"
				className="switch-label"
			/>
		</div>
		<div className="date-fields-block">
			<div className="start-date-container">
				<KeyboardDatePicker
					autoOk={true}
					required
					className="start-date-field"
					name="startDate"
					label="From"
					disableToolbar
					maxDate={values.endDate || MAX_DATE}
					variant="inline"
					inputVariant="outlined"
					fullWidth
					value={values.startDate}
					onChange={(value) => {
						setFieldValue('startDate', moment(value));
					}}
					renderDay={isHoliday}
					format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
					allowKeyboardControl={true}
				/>
				<ErrorMessage
					name="startDate"
					component="div"
					className="invalid-field-message"
				/>
			</div>
			<div className="end-date-container">
				<KeyboardDatePicker
					autoOk={true}
					className="end-date-field"
					label="To"
					disabled={values.ongoing}
					disableToolbar
					minDate={values.startDate || new Date()}
					variant="inline"
					inputVariant="outlined"
					value={values.endDate}
					onChange={(value) => {
						setFieldValue('endDate', moment(value));
					}}
					renderDay={isHoliday}
					format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
					allowKeyboardControl={true}
				/>
			</div>
		</div>
		<div className="contact-person-field">
			<TextField
				name="contactPerson"
				label="Contact Person"
				value={values.contactPerson}
				onChange={handleChange}
				error={errors.contactPerson && touched.contactPerson}
				variant="outlined"
				fullWidth
				margin="normal"
			/>
			<ErrorMessage
				name="contactPerson"
				component="div"
				className="invalid-field-message"
			/>
		</div>
		<div className="contact-details-block">
			<div className="contact-email-field">
				<TextField
					name="contactEmail"
					value={values.contactEmail}
					onChange={handleChange}
					label="Contact Email"
					error={errors.contactEmail && touched.contactEmail}
					margin="normal"
					variant="outlined"
					fullWidth
				/>
				<ErrorMessage
					name="contactEmail"
					component="div"
					className="invalid-field-message"
				/>
			</div>
			<div className="contact-phone-field">
				<TextField
					name="contactPhone"
					value={values.contactPhone}
					onChange={handleChange}
					label="Contact Phone"
					error={errors.contactPhone && touched.contactPhone}
					margin="normal"
					variant="outlined"
					fullWidth
				/>
				<ErrorMessage
					name="contactPhone"
					component="div"
					className="invalid-field-message"
				/>
			</div>
		</div>
	</form>
);

export default AddProjectsFormView;
