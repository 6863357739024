export const STATUSES = {
    "open": "Open",
    "closed": "Closed",
    "profiles sent to client": "Open",
    "interview scheduled": "Open",
    "awaiting interview feedback": "Open",
    "won": "Closed",
    "lost": "Closed",
    "expired": "Closed",
};
