import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { filterMenuItems } from 'utils/navigationUtils';

import {
    fetchSupplierInformation,
    updateSupplierBasicInformation,
    updateSupplierContactInformation,
    updateSupplierInvoiceInformation,
    updateSupplierAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
} from 'actions/supplierActions';
import { resetSupplierInformationStates } from 'actions/sectionsLoadersActions';
import {
    restoreUsers,
} from 'actions/userActions';

import {
    getSupplierBasicInformation,
    getAgencyId,
    getSupplierContactInformation,
    getSupplierInvoicingInformation,
    getSupplierAccountManager,
    isHiddenSections,
    getDashboardDataFetchState,
    getSupplierIsDeleted,
    getDisplayMode,
} from 'reducers';

import Tasks from './Tasks';
import Billing from 'components/Billing';
import CompanyInformation from '../CompanyInformation';
import SupplierEmployees from '../SupplierEmployees';
import Contracts from 'components/Contracts';
import SubmenuActionButton from 'components/Shared/SubmenuActionButton';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';
import SubmenuContentLayout from 'layouts/SubmenuContentLayout';

import defaultLogo from 'assets/default-logo.svg';

import { supplierSubmenuItems, HIDDEN_MENU_ITEMS } from './SupplierConstants';
import { CONTRACT_TYPES } from 'constants/contractsConstants';
import { ROLES } from 'constants/userConstants';

import './Supplier.scss';

const Supplier = ({
    match,
    fetchSupplierInformation,
    resetSupplierInformationStates,
    basicInformation,
    contactInformation,
    invoicingInformation,
    agencyId,
    updateSupplierBasicInformation,
    updateSupplierContactInformation,
    updateSupplierInvoiceInformation,
    updateSupplierAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
    accountManager,
    hiddenSections,
    dataFetchState,
    restoreUsers,
    isDeleted,
    displayMode,
}) => {
    const { id: supplierId } = useParams();

    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

    const handleOpenRestoreDialog = () => setOpenRestoreDialog(true);
    const handleCloseRestoreDialog = () => setOpenRestoreDialog(false);

    const filterMenuItemCallback = useCallback(filterMenuItems(Object.values(supplierSubmenuItems), HIDDEN_MENU_ITEMS), [])

    useEffect(() => {
        fetchSupplierInformation(agencyId, supplierId);
        return resetSupplierInformationStates
    }, []);

    const restoreUser = () => {
        restoreUsers([supplierId], ROLES.SUPPLIER_ADMIN);
        handleCloseRestoreDialog();
    }

    const restoreSupplierButton = <SubmenuActionButton handleClick={handleOpenRestoreDialog} text="Restore Profile" buttonStyle="save" />;

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={basicInformation.name}
            avatarUrl={basicInformation.logoImgUrl || defaultLogo}
            items={hiddenSections
                ? filterMenuItemCallback
                : Object.values(supplierSubmenuItems)}
            actionButtons={isDeleted && restoreSupplierButton}
            displayMode={displayMode}
        >
            <Switch>
                <Route
                    path={`${match.path}${supplierSubmenuItems.companyInformation.path}`}
                    exact
                    render={(props) => (
                        <CompanyInformation {...props}
                            isSupplierPage={true}
                            dataFetchState={dataFetchState}
                            updateAccountManagementInformation={updateSupplierAccountManagerInformation}
                            accountManager={accountManager}
                            basicInformation={basicInformation}
                            companyId={supplierId}
                            agencyId={agencyId}
                            isAdmin={true}
                            isSupplier={true}
                            contactInformation={contactInformation}
                            invoicingInformation={invoicingInformation}
                            updateBasicInformation={updateSupplierBasicInformation}
                            updateContactInformation={updateSupplierContactInformation}
                            updateInvoiceInformation={updateSupplierInvoiceInformation}
                            updateAvatar={updateAvatar}
                            deleteAvatar={deleteAvatar}
                            isEmailChangeDisabled={false}
                        />)}
                />
                <Route
                    exact path={`${match.path}${supplierSubmenuItems.team.path}`}
                    render={(props) => (
                        <SupplierEmployees
                            {...props}
                            agencyId={agencyId} supplierId={supplierId}
                        />
                    )}
                />
                <Route
                    path={`${match.path}${supplierSubmenuItems.contracts.path}`}
                    render={(props) => (
                        <Contracts {...props}
                            userId={supplierId}
                            agencyId={agencyId}
                            contractTypes={CONTRACT_TYPES.CLIENT}
                            isAdmin={true}
                            receiverRole={ROLES.SUPPLIER_ONLY}
                        />
                    )}
                />
                <Route
                    path={`${match.path}${supplierSubmenuItems.billing.path}`}
                    render={(props) => (<Billing {...props}
                        isSupplierPage={true}
                        dataFetchState={dataFetchState}
                        agencyId={agencyId}
                        userId={supplierId}
                        invoicerRole={ROLES.SUPPLIER_ADMIN} />)}
                />
                <Route path={`${match.path}${supplierSubmenuItems.tasksReminders.path}`} component={Tasks} />
            </Switch>

            <ConfirmRestoreUserDialog
                openDialog={openRestoreDialog}
                handleCloseDialog={handleCloseRestoreDialog}
                handleConfirmDialog={restoreUser}
            />
        </SubmenuContentLayout >
    )
};

const mapStateToProps = state => ({
    basicInformation: getSupplierBasicInformation(state),
    agencyId: getAgencyId(state),
    contactInformation: getSupplierContactInformation(state),
    invoicingInformation: getSupplierInvoicingInformation(state),
    accountManager: getSupplierAccountManager(state),
    hiddenSections: isHiddenSections(state),
    dataFetchState: getDashboardDataFetchState(state),
    isDeleted: getSupplierIsDeleted(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchtoProps = {
    fetchSupplierInformation,
    updateSupplierBasicInformation,
    updateSupplierContactInformation,
    updateSupplierInvoiceInformation,
    updateSupplierAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
    resetSupplierInformationStates,
    restoreUsers
};

export default connect(mapStateToProps, mapDispatchtoProps)(Supplier);
