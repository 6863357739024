import {
    RECRUITER_REGISTER_FETCH_SUCCESS,
    RECRUITERS_FETCH_SUCCESS,
    RECRUITERS_REPLACE_SUCCESS,
    USERS_DISABLE_SUCCESS,
} from 'actions/actionTypes';

const recruitersInitialState = [];

const recruitersReducer = (state = recruitersInitialState, action) => {
    switch (action.type) {
        case RECRUITERS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case RECRUITERS_REPLACE_SUCCESS:
            return action.payload || recruitersInitialState;
        case RECRUITER_REGISTER_FETCH_SUCCESS:
            return [...[action.payload], ...state];
        case USERS_DISABLE_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        default:
            return state;
    }
};

export default recruitersReducer;

export const getRecruiters = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.slice(from, to || state.length);
};
