
export const OVERTIME_BANNER_TITLE = 'Overtime Hours';

export const OVERTIME_BANNER_CLOSE_TEXT = (agencyName) => `Find out what is ${agencyName}'s overtime policy.`;

export const OVERTIME_BANNER_POINTS_COMPONENT = <div className="overtime-banner-points-wrapper">
    <p>While overtime is discouraged as a disruptor to work-life balance, it is sometimes required. When that happens you should write an email to your account manager and your client requesting overtime.</p>
    <ul>
        <li><p>- Overtime is considered all work done during public or national holidays, weekends, and any hours on top of the standard 8.</p></li>
        <li><p>- All the overtime work must be approved by both Motion Software and the client.</p></li>
    </ul>
</div>
