import React, { useContext, useState, useEffect, useRef } from 'react';

import { browserName } from 'react-device-detect';

import HowToRecordBanner from '../HowToRecordBanner';
import VideoIntroductionWrapper, { VideoIntroductionWrapperContext } from '../VideoIntroductionWrapper';
import VideoRecordingView from './VideoRecordingView';

import { logEvent } from 'utils/amplitude';
import { getMediaType, ScrollToTop } from 'utils/helpers';

import { INTERVIEW_CATEGORIES } from 'constants/candidateConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

const videoType = getMediaType(browserName);

const VideoRecording = ({
    agencyId,
    userId,
    continueJourney,
    interviewQuestions
}) => {
    const { uploadCandidateVideoInterviewQuestionAnswer } = useContext(VideoIntroductionWrapperContext);

    const videoRef = useRef();
    const mediaRecorderRef = useRef();

    const [countDownSeconds, setCountDownSeconds] = useState(3);
    const [timerSeconds, setTimerSeconds] = useState(120);
    const [showCountDown, setShowCountDown] = useState(true);
    const [showStopRecordingButton, setShowStopRecordingButton] = useState(false);
    const [showStartRecordingButton, setShowStartRecordingButton] = useState(true);
    const [isCountingDown, setIsCountingDown] = useState(false);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [recordedVideoObjectUrl, setRecordedVideoObjectUrl] = useState(null);
    const [videoBlob, setVideoBlob] = useState(null);
    const [hasFinishedRecording, setHasFinishedRecording] = useState(false);
    const [chunks, setChunks] = useState([]);

    const startCountDown = async () => {
        await initRecorder();
        setShowStartRecordingButton(false);
        setIsCountingDown(true);
    }

    const startTimer = () => setIsTimerRunning(true);

    const startRecording = () => mediaRecorderRef.current.start(10);

    const stopRecorder = () => {
        if (mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
        mediaRecorderRef.current.ondataavailable = null;
    };

    const saveVideo = () => {
        const blob = new Blob(chunks, { type: videoType });
        setVideoBlob(blob);
        setRecordedVideoObjectUrl(URL.createObjectURL(blob));
    };

    const stopMediaStream = () => {
        if (videoRef.current) {
            const stream = videoRef.current.srcObject;

            if (stream) {
                const tracks = stream.getTracks();

                tracks.forEach(function (track) {
                    track.stop();
                });
            }

            videoRef.current.srcObject = null;
        }

        window.localStream.getTracks().forEach(track => track.stop());
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && window.localStream) {
            stopRecorder();
            saveVideo();
            stopMediaStream();
            setIsTimerRunning(false);
            setHasFinishedRecording(true);
        }
    };

    const finishInterview = () => {
        const data = new FormData();
        data.append("isInterviewFinished", true);
        data.append("data", videoBlob);

        const action = uploadCandidateVideoInterviewQuestionAnswer.bind(null, agencyId, userId, INTERVIEW_CATEGORIES.JOURNEY, interviewQuestions[0]._id, data);

        continueJourney(null, true, action);
    };

    if (mediaRecorderRef.current) {
        mediaRecorderRef.current.ondataavailable = (e) => {
            if (e.data && e.data.size > 0 && mediaRecorderRef.current.state !== "inactive") {
                setChunks([...chunks, e.data]);
            }
        };
    };

    const initStream = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });

        videoRef.current.srcObject = stream;
        window.localStream = stream;
        videoRef.current.onloadedmetadata = function (e) {
            if (videoRef.current) {
                videoRef.current.play();
            }
        };

        startTimer();
        setShowStopRecordingButton(true);
        startRecording();
    };

    const initRecorder = () => {
        return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: videoType,
            });
        });
    };

    const resetRecording = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_RERECORD_VIDEO_INTRODUCTION);
        setCountDownSeconds(3);
        setTimerSeconds(120);
        setShowCountDown(true);
        setRecordedVideoObjectUrl(null);
        setHasFinishedRecording(false);
        setShowStopRecordingButton(false);
        setShowStartRecordingButton(true);
        setChunks([]);
    };

    useEffect(() => {
        if (isCountingDown && countDownSeconds > 0) {
            const timer = setTimeout(() => setCountDownSeconds(state => state - 1), 1000);
            return () => clearInterval(timer);
        }
        if (isCountingDown && countDownSeconds === 0) {
            setShowCountDown(false);
            setIsCountingDown(false);
            initStream();
        }
    }, [countDownSeconds, isCountingDown]);

    useEffect(() => {
        if (isTimerRunning) {
            const timer = setTimeout(() => setTimerSeconds(state => state - 1), 1000);
            return () => clearInterval(timer);
        }
        if (isTimerRunning && timerSeconds === 0) {
            setIsTimerRunning(false);
            stopRecording();
        }
    }, [timerSeconds, isTimerRunning]);

    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current && window.localStream) {
                stopRecorder();
                stopMediaStream();
            }
        };
    }, []);

    return (
        <div className="step-wrapper no-top-padding">
            <ScrollToTop />
            <HowToRecordBanner />

            <VideoRecordingView
                videoRef={videoRef}
                showCountDown={showCountDown}
                countDownSeconds={countDownSeconds}
                timerSeconds={timerSeconds}
                recordedVideoObjectUrl={recordedVideoObjectUrl}
                hasRecordingStarted={isCountingDown || isTimerRunning}
                interviewQuestions={interviewQuestions}
            />

            <div className="journey-buttons-wrapper">
                {showStopRecordingButton && !hasFinishedRecording
                    ? <VideoIntroductionWrapper.SecondaryStepButton text="Stop Recording" handleClick={stopRecording} buttonStyle="delete" />
                    : hasFinishedRecording
                        ?
                        <>
                            <VideoIntroductionWrapper.SecondaryStepButton text="Record Again" handleClick={resetRecording} />
                            <VideoIntroductionWrapper.PrimaryStepButton text="Submit & Finish" handleClick={finishInterview} />
                        </>
                        : showStartRecordingButton ?
                            <VideoIntroductionWrapper.PrimaryStepButton
                                text="Start Recording"
                                handleClick={startCountDown}
                            />
                            : null
                }

            </div>
        </div>
    );
};

export default VideoRecording;
