import React from 'react';

import { Tooltip } from '@material-ui/core';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';

import './JobTypeLabel.scss';

const JobTypeLabel = ({
    jobType='',
}) => (
    <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={`This is a ${jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT ? 'contract' : 'full-time'} job opportunity`}>
        <div className={`job-type-label-${jobType?.toLowerCase()}`} >
            <span className="job-type-text">{jobType}</span>
        </div>
    </Tooltip>
);

export default JobTypeLabel;
