import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import { createJob, updateJob } from 'actions/jobOpportunityActions';
import {
    getAgencyRouteName,
    getDisplayMode,
    getJobOpportunities,
    getJobOpportunitiesTotalCount,
    isAdmin,
    isSupplierAdmin
} from 'reducers';

import Job from './Job';
import EmptyJobsList from '../EmptyJobsList';
import SelectFilterList from 'components/Shared/SelectFilterList';
import GroupedSelectFilterList from 'components/Shared/GroupedSelectFilterList';
import { CircularProgress, InputAdornment, InputBase, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JobFormDialog from 'components/Shared/JobFormDialog';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';

import { parseJobData } from 'utils/jobUtils';

import { JOB_FORM_TITLES } from 'constants/jobOpportunitiesConstants';
import { JOBS_DASHBOARD_SELECT_FILTER_LIST_ITEMS } from '../JobsDashboardConstants';

import './JobsList.scss';

const JobsList = ({
    agencyId,
    jobs,
    jobsTotalCount,
    user,
    clientId,
    createJob,
    updateJob,
    filters,
    showJobListLoader,
    hasMoreJobs,
    handleFetchMoreJobOpportunities,
    handleSingleSelectFilterChange,
    handleSearchFieldChange,
    selectedJobType,
    isAdmin,
    getPathToJobDetails,
    isSupplierAdmin,
    displayMode,
    handleExport,
    agencyRouteName,
    isDashboardPage,
}) => {
    const history = useHistory();

    const isClientJobsPage = history.location.pathname.includes('/clients');

    const [isDialogOpen, setIsDialogOpen] = useState(!!(selectedJobType || history.location.state?.isDialogOpen));
    const [selectedJob, setSelectedJob] = useState(selectedJobType ? { jobType: selectedJobType } : {});
    const [jobImgData, setJobImgData] = useState(null);
    const [jobFormTitle, setJobFormTitle] = useState('');
    const [closePopoverFunction, setClosePopoverFunction] = useState(() => () => void 0);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
        setJobFormTitle(JOB_FORM_TITLES.CREATE);
    }

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedJob({});
        setJobImgData(null);
    }

    const onEditClick = (job) => {
        setSelectedJob(job);
        setJobFormTitle(JOB_FORM_TITLES.EDIT);
        setIsDialogOpen(true);
        closePopoverFunction();
    }

    const handleSubmitJobForm = async (values) => {
        const parsedJob = parseJobData({ ...selectedJob, ...values }, isAdmin);

        const formData = new FormData();

        if (jobImgData) {
            formData.append('jobImageFile', jobImgData.file);
            formData.append('jobImageInfo', JSON.stringify(jobImgData.info));
        } else if (!jobImgData && !values.jobImgUrl) {
            formData.append('jobImageFile', null);
            formData.append('jobImageInfo', JSON.stringify({}));
        }

        if (selectedJob._id) {
            formData.append('jobData', JSON.stringify({ ...parsedJob }));
            await updateJob(agencyId, selectedJob._id, formData);
        } else {
            formData.append('jobData', JSON.stringify({ ...parsedJob, creator: user._id }));
            await createJob(agencyId, formData);
        }

        handleCloseDialog();
    }

    const handleViewAllClick = () => {
        history.push(`/${agencyRouteName}/jobs-dashboard`);
    };

    const jobClientId =
        isAdmin && selectedJob?.client?._id ? selectedJob.client._id : // admin editing job
            isAdmin && isClientJobsPage ? clientId : // admin on client details jobs page
                !isAdmin ? clientId : // client on dashboard page
                    null; // admin on jobs dashboard page

    return (
        <>
            <div className="jobs-list-container">
                <div className={`job-list-header ${(isAdmin || isSupplierAdmin) && "max-width"}`}>
                    <div className="job-list-header-left-wrapper">
                        <Typography variant="h1">Jobs</Typography>
                        {
                            !isSupplierAdmin &&
                            (isAdmin
                                ? <GroupedSelectFilterList
                                    title="Filter"
                                    values={filters}
                                    groups={JOBS_DASHBOARD_SELECT_FILTER_LIST_ITEMS[displayMode]}
                                    handleChange={handleSingleSelectFilterChange}
                                />
                                : <SelectFilterList
                                    name="status"
                                    value={filters['status']}
                                    title="Filter"
                                    items={JOBS_DASHBOARD_SELECT_FILTER_LIST_ITEMS[displayMode]}
                                    handleChange={handleSingleSelectFilterChange}
                                />
                            )
                        }
                        {isAdmin &&
                            <>
                                <InputBase
                                    className="search-term-field technology"
                                    placeholder="Technology"
                                    autoComplete="off"
                                    name="technologySearchTerm"
                                    value={filters.technologySearchTerm}
                                    onChange={event => handleSearchFieldChange(event, 'technologySearchTerm')}
                                    startAdornment={(
                                        <InputAdornment position="start">
                                            <SearchIcon className="search-icon icon" />
                                        </InputAdornment>
                                    )}
                                />
                                <InputBase
                                    className="search-term-field light-border wider"
                                    placeholder="Search"
                                    autoComplete="off"
                                    name="searchTerm"
                                    value={filters.searchTerm}
                                    onChange={event => handleSearchFieldChange(event, 'searchTerm')}
                                    startAdornment={(
                                        <InputAdornment position="start">
                                            <SearchIcon className="search-icon icon" />
                                        </InputAdornment>
                                    )}
                                />
                            </>
                        }
                    </div>

                    {!isSupplierAdmin &&
                        <div className="action-buttons-wrapper">
                            <PrimaryAddButton
                                text='Create New'
                                handleClick={handleOpenDialog}
                            />

                            <PrimaryExportButton
                                text='Export'
                                handleClick={handleExport}
                            />
                        </div>
                    }
                </div>
                {showJobListLoader
                    ? <CircularProgress className="jobs-list-loader" size={50} disableShrink />
                    : jobs?.length > 0
                        ? <InfiniteScroll
                            className="jobs-list"
                            dataLength={jobs.length}
                            next={handleFetchMoreJobOpportunities?.bind(null, jobs, jobsTotalCount)}
                            hasMore={hasMoreJobs}
                            scrollableTarget={isClientJobsPage ? "sub-menu-scroll-container" : "scroller"}
                            style={{ overflow: 'visible' }}
                        >
                            {jobs.map(job => (
                                <Job
                                    onEditClick={onEditClick}
                                    agencyId={agencyId}
                                    key={job._id}
                                    job={job}
                                    isAdmin={isAdmin}
                                    isSupplierAdmin={isSupplierAdmin}
                                    getPathToJobDetails={getPathToJobDetails}
                                    setClosePopoverFunction={setClosePopoverFunction}
                                />
                            ))}
                        </InfiniteScroll>
                        : <EmptyJobsList
                            filters={filters}
                            handleOpenDialog={handleOpenDialog}
                            isSupplierAdmin={isSupplierAdmin}
                            displayMode={displayMode}
                        />
                }

                {isSupplierAdmin && isDashboardPage &&
                    <div className="view-all-action-btn-wrapper">
                        <PrimaryButton
                            text='View All'
                            handleClick={handleViewAllClick}
                        />
                    </div>
                }
            </div>

            {!isSupplierAdmin &&
                <JobFormDialog
                    title={jobFormTitle}
                    open={isDialogOpen}
                    clientId={jobClientId}
                    isClientDisabled={isAdmin && jobClientId}
                    onClose={handleCloseDialog}
                    onSubmit={handleSubmitJobForm}
                    agencyId={agencyId}
                    selectedItem={selectedJob}
                    isClientRequested={isAdmin}
                    isAdmin={isAdmin}
                    setJobImgData={setJobImgData}
                />
            }
        </>
    );
};

const mapStateToProps = (state, ownProps) => ({
    jobs: getJobOpportunities(state, ownProps.filters),
    jobsTotalCount: getJobOpportunitiesTotalCount(state),
    isAdmin: isAdmin(state),
    isSupplierAdmin: isSupplierAdmin(state),
    displayMode: getDisplayMode(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    createJob,
    updateJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsList);
