import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { ErrorMessage } from 'formik';

import { COUNTRIES_OF_EMPLOYMENT } from 'constants/commonConstants';

import '../PersonalInfoContactForm.scss';

const PersonalInfoSupplierAdminContractFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    countries,
}) => {
    return (
        <form className="grid-form-double-row" autoComplete="off">
            <div className="form-field">
                <FormControl className="country-field" variant="outlined" fullWidth>
                    <InputLabel id="country-label">Country of Employment</InputLabel>
                    <Select
                        labelId="country-label"
                        id="person-country"
                        name="country"
                        value={values.country}
                        onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                        label="Country of Employment"
                        error={errors.country && touched.country}
                    >
                        {countries
                            .filter(country => COUNTRIES_OF_EMPLOYMENT.includes(country.name))
                            .map(country => (
                                <MenuItem key={country.name} value={country.name}>
                                    {country.name}
                                </MenuItem>
                            ))}
                    </Select>
                    <ErrorMessage name="country" component="div" className="invalid-field-message" />
                </FormControl>
            </div>
        </form>
    )
};

export default PersonalInfoSupplierAdminContractFormView;
