import React from 'react';
import { Formik } from 'formik';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import EmployeeTerminationDialogForm from './EmployeeTerminationDialogForm';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import Typography from '@material-ui/core/Typography';

import { validateRequiredFields, validateEmploymentTerminationDate } from 'utils/formValidations';

import './EmployeeTerminationDialog.scss';

const EmployeeTerminationDialog = ({
    employmentStartDate,
    isDialogOpen,
    handleCloseDialog,
    handleSubmit,
}) => {

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    terminationDate: null,
                }}
                validate={(values) => {
                    return {
                        ...validateRequiredFields(values, ['terminationDate']),
                        ...validateEmploymentTerminationDate({ ...values, startDate: employmentStartDate }),
                    };
                }}
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {(props) => (
                    <CommonFormikDialog
                        size="sm"
                        className="employee-termination-dialog"
                        dialogTitle="Employee Termination"
                        saveBtnText="Confirm"
                        discardBtnText="Discard"
                        open={isDialogOpen}
                        onClose={handleCloseDialog}
                        onSave={props.handleSubmit}
                    >
                        <Typography className="employee-termination-dialog-text" >
                            Do you want to terminate the employment of this user?
                        </Typography>
                        <EmployeeTerminationDialogForm {...props} />
                    </CommonFormikDialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default EmployeeTerminationDialog;
