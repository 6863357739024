import React, { useContext, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { bookFaceToFaceInterview } from 'actions/candidateActions';
import { getCurrentJourneyStepId, getInitialJourneyCompletedStepsIds } from 'reducers';

import VideoIntroductionWrapper, { VideoIntroductionWrapperContext } from '../VideoIntroductionWrapper';
import VideoIntroductionView from './VideoIntroductionView';
import VideoRecording from '../VideoRecording';
import CalendlyDialog from '../../../../Shared/CalendlyDialog';

import { LESS_THAN_ONE_YEAR_EXPERIENCE, ONE_TO_TWO_YEARS_EXPERIENCE } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';
import { EMPLOYEE_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { CALENDLY_DIALOG_STATES } from 'components/CandidateProfileJourney/CandidateProfileJourneyConstants';

import { parseQueryString, ScrollToTop } from 'utils/helpers';

const VideoIntroduction = ({
    agencyId,
    userId,
    continueJourney,
    interviewQuestions,
    bookFaceToFaceInterview,
    stepId,
    completedStepsIds,
}) => {
    const history = useHistory();

    const {
        showNotification,
        candidatePreferences,
        agencyCalendlyInfo,
        hasUserBookedFaceToFaceInterview,
        agencyName,
    } = useContext(VideoIntroductionWrapperContext);

    const [showRecordingView, setShowRecordingView] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isThroughReminderEmail, setIsThroughReminderEmail] = useState(false);

    const [permission, setPermission] = useState(false);
    const [audioInputList, setAudioInputList] = useState([]);
    const [videoInputList, setVideoInputList] = useState([]);
    const [audioInputSelected, setAudioInputSelected] = useState({});
    const [videoInputSelected, setVideoInputSelected] = useState({});
    const [hasStream, setHasStream] = useState(false);
    const [showHintPopup, setShowHintPopup] = useState(false);
    const videoRef = useRef();

    const isBookInterviewButtonVisible = agencyCalendlyInfo && agencyCalendlyInfo.calendlyUrl && !hasUserBookedFaceToFaceInterview;

    const hasAtLeastTwoYearsExperience =
        candidatePreferences.experienceSince !== LESS_THAN_ONE_YEAR_EXPERIENCE &&
        candidatePreferences.experienceSince !== ONE_TO_TWO_YEARS_EXPERIENCE &&
        candidatePreferences.experienceSince;

    const handleNextStep = async () => {
        setShowRecordingView(true);

        if (window.localStream) {
            window.localStream.getTracks().forEach(track => track.stop());
        }

        const hasAllowedUseOfMedia = await navigator.mediaDevices
            .getUserMedia({ audio: true, video: true })
            .then(stream => {
                const tracks = stream.getTracks();
                tracks.forEach(t => t.stop());
            })
            .then(() => {
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                    return false;
                } else {
                    return true;
                }
            })
            .catch(_ => false);

        if (!hasAllowedUseOfMedia) {
            showNotification(EMPLOYEE_MESSAGES.VIDEO_INTERVIEW_ALLOW_DEVICES, NOTIFICATION_TYPES.WARNING);
        }
    };

    useEffect(() => {
        initDevices();

        if (permission) {
            if (audioInputSelected && videoInputSelected && !hasStream) {
                updateVideoStream(videoOptions());
            }
        }

        return () => {
            if (window.localStream) {
                window.localStream.getTracks().forEach(track => track.stop());
            }
        }
    }, [permission]);

    useEffect(() => {
        const queryString = history.location.search;
        const params = parseQueryString(queryString);

        if (params.calendlyDialogState === CALENDLY_DIALOG_STATES.OPEN) {
            setIsDialogOpen(true);
            setIsThroughReminderEmail(true);
        }
    }, [history.location.search]);

    const initDevices = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .then(stream => {
                const tracks = stream.getTracks();
                tracks.forEach(t => t.stop());
            }).then(() => {
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                    showNotification(EMPLOYEE_MESSAGES.VIDEO_INTERVIEW_ALLOW_DEVICES, NOTIFICATION_TYPES.WARNING);
                    return;
                }

                // List cameras and microphones.
                navigator.mediaDevices.enumerateDevices()
                    .then(function (devices) {
                        const audioInputArr = [];
                        const videoInputArr = [];
                        devices.map(device => {
                            if (device.kind === "audioinput") {
                                audioInputArr.push(device);
                            }
                            if (device.kind === "videoinput") {
                                videoInputArr.push(device);
                            }
                            return device;
                        });
                        setAudioInputSelected(audioInputArr[0]);
                        setVideoInputSelected(videoInputArr[0]);
                        setAudioInputList([...audioInputArr]);
                        setVideoInputList([...videoInputArr]);
                    })
                    .catch(function (err) {
                        showNotification(EMPLOYEE_MESSAGES.VIDEO_INTERVIEW_ALLOW_DEVICES, NOTIFICATION_TYPES.WARNING);
                    });
                setPermission(true);
            }).catch(function (err) {
                showNotification(EMPLOYEE_MESSAGES.VIDEO_INTERVIEW_ALLOW_DEVICES, NOTIFICATION_TYPES.WARNING);
            });
    };

    const videoOptions = (audioInputSelectedProp = audioInputSelected, videoInputSelectedProp = videoInputSelected) => {
        return {
            audio: { deviceId: { exact: audioInputSelectedProp.deviceId } },
            video: { deviceId: { exact: videoInputSelectedProp.deviceId } }
        }
    };

    const updateVideoStream = async videoOptions => {
        setHasStream(true);
        const stream = await navigator.mediaDevices.getUserMedia(videoOptions);
        window.localStream = stream;

        if (videoRef.current && audioInputSelected && videoInputSelected) {
            videoRef.current.srcObject = stream;
            videoRef.current.onloadedmetadata = function (e) {
                videoRef.current.play();
            };
        } else {
            window.localStream.getTracks().forEach(track => track.stop());
        }
    };

    const handleAudioChange = (event) => {
        audioInputList.filter(device => {
            if (device.label === event.target.value) {
                setAudioInputSelected(device);
            }
            return device;
        });
    };

    const handleVideoChange = (event) => {
        videoInputList.filter(device => {
            if (device.label === event.target.value) {
                setVideoInputSelected(device);
            }
            return device;
        });
    };

    const handleBookCallClick = () => {
        if (window.localStream) {
            window.localStream.getTracks().forEach(track => track.stop());
            setHasStream(false);
        }
        setIsDialogOpen(true);
    }

    const handleCloseBookCallDialog = () => {
        if (permission) {
            if (audioInputSelected && videoInputSelected && !hasStream) {
                updateVideoStream(videoOptions());
            }
        }
        setIsDialogOpen(false);
    }

    const handleEventScheduling = (event) => {
        const eventUri = event.data.payload.event.uri;
        const inviteeUri = event.data.payload.invitee.uri;
        const condition = !completedStepsIds.includes(stepId);
        const action = bookFaceToFaceInterview.bind(null, agencyId, userId, { eventUri, inviteeUri }, isThroughReminderEmail);
        continueJourney(null, condition, action, true);
    };

    return (
        <div className="step-wrapper">
            {showRecordingView
                ? <VideoRecording
                    agencyId={agencyId}
                    userId={userId}
                    continueJourney={continueJourney}
                    interviewQuestions={interviewQuestions}
                />
                :
                <>
                    <ScrollToTop />
                    <VideoIntroductionView
                        canBookCalndlyCall={hasAtLeastTwoYearsExperience}
                        agencyName={agencyName}
                        permission={permission}
                        setShowHintPopup={setShowHintPopup}
                        showHintPopup={showHintPopup}
                        handleAudioChange={handleAudioChange}
                        audioInputSelected={audioInputSelected}
                        audioInputList={audioInputList}
                        handleVideoChange={handleVideoChange}
                        videoInputSelected={videoInputSelected}
                        videoInputList={videoInputList}
                        videoRef={videoRef}
                    />

                    <div className="journey-buttons-wrapper">
                        {hasAtLeastTwoYearsExperience && isBookInterviewButtonVisible && (
                            <VideoIntroductionWrapper.SecondaryStepButton
                                handleClick={handleBookCallClick}
                                text="Skip & Book call"
                            />
                        )}
                        <VideoIntroductionWrapper.PrimaryStepButton text="Continue" handleClick={handleNextStep} />
                    </div>
                </>
            }
            {isDialogOpen && (
                <CalendlyDialog
                    handleClose={handleCloseBookCallDialog}
                    calendlyInfo={agencyCalendlyInfo}
                    handleEventScheduling={handleEventScheduling}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    stepId: getCurrentJourneyStepId(state),
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
});

const mapDispatchToProps = {
    bookFaceToFaceInterview,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoIntroduction);
