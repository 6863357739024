import React from 'react';

import { Typography } from '@material-ui/core';
import { CANDIDATE_SIGN_UP_BENEFITS_DATA } from 'constants/layoutsConstants';

import bnpParibasLogo from 'assets/partner-logos/bnp-paribas-logo.png';
import dcByteLogo from 'assets/partner-logos/dc-byte-logo.png';
import chromaWayLogo from 'assets/partner-logos/chroma-way-logo.png';
import deloitteLogo from 'assets/partner-logos/deloitte-logo.png';
import kindredLogo from 'assets/partner-logos/kindred-logo.png';
import financialTimesLogo from 'assets/partner-logos/financial-times-logo.png';

const WelcomeCandidate = () => {
    const ImageComponents = {
        First: CANDIDATE_SIGN_UP_BENEFITS_DATA[0].image,
        Second: CANDIDATE_SIGN_UP_BENEFITS_DATA[1].image,
        Third: CANDIDATE_SIGN_UP_BENEFITS_DATA[2].image,
        Fourth: CANDIDATE_SIGN_UP_BENEFITS_DATA[3].image,
    };
    
    return (
        <div className="welcome-candidate-wrapper">
            <Typography variant="h3" className="footer-content-title">Work for top international companies</Typography>
            <ul className="benefits-list">
                <li className="item">
                    <ImageComponents.First className="item-image" />
                    <p className="item-text">{CANDIDATE_SIGN_UP_BENEFITS_DATA[0].text}</p>
                </li>
                <li className="item">
                    <ImageComponents.Second className="item-image" />
                    <p className="item-text">{CANDIDATE_SIGN_UP_BENEFITS_DATA[1].text}</p>
                </li>
                <li className="item">
                    <ImageComponents.Third className="item-image" />
                    <p className="item-text">{CANDIDATE_SIGN_UP_BENEFITS_DATA[2].text}</p>
                </li>
                <li className="item">
                    <ImageComponents.Fourth className="item-image" />
                    <p className="item-text">{CANDIDATE_SIGN_UP_BENEFITS_DATA[3].text}</p>
                </li>
            </ul>
            <div className="partner-logos">
                <img src={bnpParibasLogo} className="logo-image" />
                <img src={dcByteLogo} className="logo-image" />
                <img src={chromaWayLogo} className="logo-image" />
                <img src={deloitteLogo} className="logo-image" />
                <img src={kindredLogo} className="logo-image" />
                <img src={financialTimesLogo} className="logo-image" />
            </div>
        </div>
    )
};

export default WelcomeCandidate;
