import React from 'react';
import { Formik } from 'formik';

import ExportTimesheetFormView from './ExportTimesheetFormView';

import Dialog from 'components/Shared/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import { getMonths } from 'utils/formatTimes';
import {
    validateRequiredFields,
    validateEndDateNotBeforeStartDate,
    validateStartDateNotBeforeEndDate
} from 'utils/formValidations';

import './ExportTimesheetDialog.scss';

const ExportTimesheetDialog = ({ isDialogOpen, handleCloseDialog, handleExportTimesheet }) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
            initialValues={{
                startDate: null,
                endDate: null,
            }}
            validate={values => ({
                ...validateRequiredFields(values, ['startDate']),
                ...validateStartDateNotBeforeEndDate(values, "startDate", "endDate", false),
                ...validateEndDateNotBeforeStartDate(values, "startDate", "endDate"),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const months = getMonths(values.startDate, values.endDate);

                handleExportTimesheet(months);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="export-timesheet-dialog" open={isDialogOpen} onClose={handleCloseDialog}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Export Timesheet</Typography>

                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent className="dialog-content">
                        <ExportTimesheetFormView
                            {...props}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </DialogContent>
                </Dialog >
            }
        </Formik>
    </MuiPickersUtilsProvider>
);

export default ExportTimesheetDialog;
