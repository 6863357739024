import { STEPS } from './ApplicationStatusBannerConstants';

export const getProgressBarProperties = (currentStep) => {
    const state = [];
    
    let currentProperties = { variant: 'determinate', value: 100, className: 'custom-progress' };

    Object.values(STEPS).forEach(value => {
        // we found the current step
        if (value === currentStep) {
            // set no properties so the progress animates
            state.push({ className: 'custom-progress' })
            // set the current properties to empty loaders since we passed the current step
            currentProperties = { variant: 'determinate', value: 0, className: 'custom-progress' } 
        } else {
            // otherwise set current properties
            state.push(currentProperties);
        }
    });

    return state;
};
