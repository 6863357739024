import React from 'react';

import { REGISTER_CARD } from '../RegisterCandidateConstants';

import LoginCard from 'components/Shared/LoginCard';
import RegisterCandidateFormik from '../RegisterCandidateFormik';

const RegisterCandidateDesktopComponent = ({
    isNewStyle,
    initialValues,
    gotoUrl,
    jobOpportunityId,
    referrer,
    gclid,
    registerCandidate,
    login,
    history,
}) => (
    <LoginCard
        title={REGISTER_CARD.TITLE}
        subtitle={REGISTER_CARD.SUBTITLE}
        isNewStyle={isNewStyle}
    >
        <RegisterCandidateFormik
            initialValues={initialValues}
            gotoUrl={gotoUrl}
            jobOpportunityId={jobOpportunityId}
            referrer={referrer}
            gclid={gclid}
            registerCandidate={registerCandidate}
            login={login}
            history={history}
        />
    </LoginCard>
);

export default RegisterCandidateDesktopComponent;
