import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { isAdminMode, isClientProjectsModuleModified, isSupplierEmployeeMode } from 'reducers';
import EmployeeProfilePopUp from '../EmployeeProfilePopUp';
import AddEmployeeToProjectCardDialog from '../AddEmployeeProjectsCardDialog';
import DeleteEmployeeDialog from '../DeleteEmployeeDialog';
import { deleteProjectEmployee, deleteDedicatedEmployeeFromProject } from 'actions/clientActions';

import AllEmployees from './AllEmployees';
import Contract from './Contract';
import FullTime from './FullTime';

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';
import { DIALOG_TYPES } from 'constants/clientProjectConstants';

const CardPeople = ({
	project,
	agencyId,
	clientId,
	isAdminMode,
	isClientMode,
	isEmployeeMode,
	isSupplierEmployeeMode,
	projectCurrency,
	deleteProjectEmployee,
	deleteDedicatedEmployeeFromProject,
	handleOpenSubmitFeedbackDialog,
	currentIdSelected,
	setCurrentIdSelected,
	isClientProjectsModuleModified,
	isClientInvoicingCompleted
}) => {
	const [employeesAdded, setEmployeesAdded] = useState([]);
	const [employeeType, setEmployeeType] = useState('');

	const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
	const [isDeleteEmployeeDialogOpen, setIsDeleteEmployeeDialogOpen] = useState(false);

	const [employeeUpdateProject, setEmployeeUpdateProject] = useState({});
	const [isPopUpOpen, setIsPopUpOpen] = useState(false);
	const [isPopUpClosable, setIsPopUpClosable] = useState(true);
	const [popUpPosition, setPopUpPosition] = useState({ left: '-70px', top: '40px' });

	const [addDialogType, setAddDialogType] = useState('');

	useEffect(() => {
		const otherCategoryEmployees = [...project.employees, ...project.dedicatedEmployees];
		setEmployeesAdded(otherCategoryEmployees);
	}, [addDialogType]);

	const handlePopoverOpen = (id, type, divId) => {
		if (type) {
			setEmployeeType(type);
		}
		setCurrentIdSelected(id);

		const popupHeight = 174;

		const divElement = document.getElementById(divId);
		const offset = divElement.getBoundingClientRect();

		if (window.innerHeight - popupHeight < offset.top) {
			setPopUpPosition({ left: `${offset.left - 70}px`, top: `${offset.top - 144}px` })
		} else {
			setPopUpPosition({ left: `${offset.left - 70}px`, top: `${offset.top + 30}px` })
		}

		setIsPopUpOpen(true);
	};

	const handlePopoverEnter = () => setIsPopUpOpen(true);

	const handlePopoverClose = () => isPopUpClosable && setIsPopUpOpen(false);

	const handleAddEmployeeDialog = (type) => {
		setEmployeesAdded([...project.dedicatedEmployees, ...project.employees]);
		setEmployeeUpdateProject({});
		setAddDialogType(type);
		setIsAddEmployeeDialogOpen(true);
	};

	const handleEditEmployeeDialog = (employee, type) => {
		setEmployeeUpdateProject({ ...employee, isEdit: true });
		setAddDialogType(type);
		setIsAddEmployeeDialogOpen(true);
	};

	const handleOpenDeleteDialog = (employee, type) => {
		setEmployeeUpdateProject({ ...employee });
		setEmployeeType(type);
		setIsDeleteEmployeeDialogOpen(true);
	};

	const handleProjectEmployeeRemove = (data, type) => {
		const { employeeId, dateDeleted } = data;
		setIsDeleteEmployeeDialogOpen(false);

		const formattedDate = moment(dateDeleted).format(DATE_FORMAT_WITH_DASH);

		(type === DIALOG_TYPES.CONTRACT)
		? deleteProjectEmployee(agencyId, clientId, project._id, employeeId, formattedDate)
		: deleteDedicatedEmployeeFromProject(agencyId, clientId, project._id, employeeId, formattedDate);
	}

	return (
		<div className="employees">
			{isClientProjectsModuleModified
				?
				<AllEmployees
					project={project}
					handlePopoverOpen={handlePopoverOpen}
					handlePopoverClose={handlePopoverClose}
					isClientMode={isClientMode}
					isEmployeeMode={isEmployeeMode}
					handleAddEmployeeDialog={handleAddEmployeeDialog}
				/>
				:
				<div className="employees">
					<FullTime
						project={project}
						handlePopoverOpen={handlePopoverOpen}
						handlePopoverClose={handlePopoverClose}
						isClientMode={isClientMode}
						isEmployeeMode={isEmployeeMode}
						handleAddEmployeeDialog={handleAddEmployeeDialog}
						isSupplierEmployeeMode={isSupplierEmployeeMode}
					/>
					{ project.dedicatedEmployees.length > 0 && project.employees.length > 0 && <hr /> }
					<Contract
						project={project}
						handlePopoverOpen={handlePopoverOpen}
						handleAddEmployeeDialog={handleAddEmployeeDialog}
						handlePopoverClose={handlePopoverClose}
						isClientMode={isClientMode}
						isEmployeeMode={isEmployeeMode}
						isSupplierEmployeeMode={isSupplierEmployeeMode}
					/>

				</div>
			}

			<EmployeeProfilePopUp
				isOpen={isPopUpOpen && !isSupplierEmployeeMode && !isEmployeeMode}
				employeeType={employeeType}
				isClientProjectsModuleModified={isClientProjectsModuleModified}
				popUpPosition={popUpPosition}
				setIsPopUpClosable={setIsPopUpClosable}
				handlePopoverClose={handlePopoverClose}
				handlePopoverOpen={handlePopoverEnter}
				currentIdSelected={currentIdSelected}
				employees={{ onDemandEmployees: project.employees.filter(x => !x.isDeleted), dedicatedEmployees: project.dedicatedEmployees }}
				handleEditEmployeeDialog={handleEditEmployeeDialog}
				isClientMode={isClientMode}
				isEmployeeMode={isEmployeeMode}
				isAdminMode={isAdminMode}
				projectCurrency={projectCurrency.LABEL}
				handleOpenSubmitFeedbackPopup={handleOpenSubmitFeedbackDialog}
				handleOpenDeleteDialog={handleOpenDeleteDialog}
			/>

			<AddEmployeeToProjectCardDialog
				isOpen={isAddEmployeeDialogOpen}
				type={addDialogType}
				handleCloseDialog={() => {
					setIsAddEmployeeDialogOpen(false);
					setAddDialogType('');
					setEmployeeUpdateProject({});
				}}
				projectId={project._id}
				agencyId={agencyId}
				clientId={clientId}
				project={employeeUpdateProject}
				setProject={setEmployeeUpdateProject}
				projectCurrency={projectCurrency}
				isClientInvoicingCompleted={isClientInvoicingCompleted}
				employeesAdded={employeesAdded}
			/>

			<DeleteEmployeeDialog 
				isOpen={isDeleteEmployeeDialogOpen}
				handleCloseDialog={() => {
					setIsDeleteEmployeeDialogOpen(false);
					setEmployeeUpdateProject({});
				}}
				handleDelete={handleProjectEmployeeRemove}
				employee={employeeUpdateProject}
				type={employeeType}
			/>
		</div>
	);
};

const mapStateToProps = state => ({
	isAdminMode: isAdminMode(state),
	isClientProjectsModuleModified: isClientProjectsModuleModified(state),
	isSupplierEmployeeMode: isSupplierEmployeeMode(state),
});

const mapDispatchtoProps = {
	deleteProjectEmployee,
	deleteDedicatedEmployeeFromProject,
};

export default connect(mapStateToProps, mapDispatchtoProps)(CardPeople);
