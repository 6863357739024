import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStateAgencyName } from 'utils/helpers';

import { isOnboarded as isUserOnboarded, isClientMode, getUserId } from 'reducers';
import { fetchIsOnboardingPassed } from 'actions/agencyActions';
import { showNotification } from 'actions/notificationActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { USER_MESSAGES } from 'constants/messageConstants';

const isOnboarded = (Component) => {
    const OnboardingCheck = ({
        isUserOnboarded,
        isClientMode,
        showNotification,
        fetchIsOnboardingPassed,
        userId,
        ...props
    }) => {
        const history = useHistory();

        useEffect(() => {
            if (!isUserOnboarded && isClientMode && isUserOnboarded) {
                fetchIsOnboardingPassed(userId)
                    .then(isOnboardingPassed => {
                        //UseEffect doesn't wait for the store to update after fetch is over
                        //So the needed value is taken directly from the action and not the store
                        if (!isOnboardingPassed && isOnboardingPassed) {
                            history.push(`/${getStateAgencyName()}/terms-and-conditions`);
                            showNotification(USER_MESSAGES.ACCEPT_TERMS_AND_CONDITIONS_INFO, NOTIFICATION_TYPES.INFO);
                        }
                    })
            }
        });

        if (isClientMode) {
            return isUserOnboarded ? <Component {...props} /> : null;
        } else {
            return <Component {...props} />;
        }
    }

    const mapStateToProps = state => ({
        isClientMode: isClientMode(state),
        isUserOnboarded: isUserOnboarded(state),
        userId: getUserId(state)
    });

    const mapDispatchToProps = {
        showNotification,
        fetchIsOnboardingPassed
    };

    return connect(mapStateToProps, mapDispatchToProps)(OnboardingCheck);
}

export default isOnboarded;
