import React from 'react';
import { useHistory } from 'react-router-dom';
import { getStateAgencyName } from 'utils/helpers';

import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as ExpandTeamIcon } from 'assets/expand-team-banner-icon.svg';

import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';

import './ExpandTeamBanner.scss';

const ExpandTeamBanner = () => {
    const history = useHistory();
    const pathToJobsDashboard = `/${getStateAgencyName()}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`;

    return (
        <div className="expand-team-banner-wrapper">
            <div className="expand-team-content">
                <Typography className="expand-team-title" variant="h2">Want to expand your team or start a new project?</Typography>
                <Typography className="expand-team-additional-text">
                    Send us a request and we will find the right talent for the job. Choose between hiring on-demand contractors or building your own dedicated team.
                </Typography>
                <div className="expand-team-actions-wrapper">
                    <PrimaryButton
                        text='Add Job'
                        handleClick={() => history.push(pathToJobsDashboard, { isDialogOpen: true })}
                    />
                </div>
            </div>
            <div className="expand-team-icon-container">
                <ExpandTeamIcon />
            </div>
        </div>
    );
};

export default ExpandTeamBanner;
