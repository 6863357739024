import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getClientId,
    getDisplayMode,
    getInvoices,
    getPublicHolidays,
} from 'reducers';

import {
    addInvoice,
    editInvoice,
    fetchInvoices,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    updateInvoiceStatus,
    resendInvoiceEmail,
    deleteInvoice,
} from 'actions/invoiceActions';

import { fetchClientInformation } from 'actions/clientActions';
import { fetchSupplierInformation } from 'actions/supplierActions';

import BillingTable from './BillingTable';
import { ROLES } from 'constants/userConstants';

const Billing = ({
    userId,
    agencyId,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    fetchClientInformation,
    addInvoice,
    editInvoice,
    fetchInvoices,
    updateInvoiceStatus,
    resendInvoiceEmail,
    isAdmin,
    displayMode,
    publicHolidays,
    withoutFilter,
    isDataAvailable,
    deleteInvoice,
    clientId,
    fetchSupplierInformation,
    invoicerRole,
}) => {
    useEffect(() => {
        if (!isDataAvailable) {
            if (displayMode === ROLES.CLIENT) {
                fetchClientInformation(agencyId, clientId);
            } else if (displayMode === ROLES.SUPPLIER_ADMIN) {
                fetchSupplierInformation(agencyId, userId);
            }
        }
    }, []);

    const tableFetchAction = fetchInvoices.bind(null, agencyId, [ROLES.SUPPLIER_ADMIN, ROLES.ADMIN].includes(displayMode) ? userId : clientId);

    return (
        <div className="billing-wrapper">
            <BillingTable
                tableAction={tableFetchAction}
                updateInvoiceStatus={updateInvoiceStatus}
                agencyId={agencyId}
                userId={userId}
                dataSelector={getInvoices}
                downloadInvoiceFiles={downloadInvoiceFiles}
                exportInvoiceTimesheets={exportInvoiceTimesheets}
                resendInvoiceEmail={resendInvoiceEmail}
                addInvoice={addInvoice}
                editInvoice={editInvoice}
                isAdmin={isAdmin}
                displayMode={displayMode}
                publicHolidays={publicHolidays}
                withoutFilter={withoutFilter}
                deleteInvoice={deleteInvoice}
                invoicerRole={invoicerRole}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    displayMode: getDisplayMode(state),
    publicHolidays: getPublicHolidays(state),
    clientId: getClientId(state),
})

const mapDispatchtoProps = {
    addInvoice,
    editInvoice,
    updateInvoiceStatus,
    fetchInvoices,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    resendInvoiceEmail,
    fetchClientInformation,
    deleteInvoice,
    fetchSupplierInformation,
};

export default connect(mapStateToProps, mapDispatchtoProps)(Billing);
