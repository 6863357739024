import React from 'react';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import UpcomingInterviewsCard from './UpcomingInterviewsCard';

import './UpcomingInterviewsTable.scss';

const UpcomingInterviewsTable = ({
    upcomingInterviews
}) => (
    <div className="upcoming-interview-table-wrapper">
        {upcomingInterviews && upcomingInterviews.length
            ? <div>
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='My upcoming interviews'
                    />
                </SectionActionsWrapper>

                <div className="upcoming-interview-table-container">
                    {upcomingInterviews.map((interview, index) =>
                        <UpcomingInterviewsCard
                            key={index}
                            interview={interview}
                        />
                    )}
                </div>
            </div>
            : null
        }
    </div>
);

export default UpcomingInterviewsTable;
