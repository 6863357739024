import {
    FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS,
    FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS,
} from 'actions/actionTypes';

const exploreTalentsInitialState = {
    profiles: [],
    totalTalentsCount: 0,
};

const exploreTalents = (state = exploreTalentsInitialState, action) => {
    switch (action.type) {
        case FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS:
            return { ...exploreTalentsInitialState, ...action.payload };
        case FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS:
            return {
                ...state,
                profiles: [...state.profiles, ...action.payload.profiles],
                totalTalentsCount: action.payload.totalTalentsCount
            };
        default:
            return state;
    }
};

export default exploreTalents;

export const getExploreTalentsProfiles = (state) => state.profiles;
export const getTotalTalentsCount = (state) => state.totalTalentsCount;
