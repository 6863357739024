import React from 'react';

import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';

import defaultAvatar from 'assets/dummy-avatar.jpg';

import { CONTRACT_ID, DIALOG_TYPES } from 'constants/clientProjectConstants';

const Contract = ({
	project,
	handlePopoverOpen,
	handlePopoverClose,
	isClientMode,
	isEmployeeMode,
	isSupplierEmployeeMode,
	handleAddEmployeeDialog,
}) => {

	const handleAddOnDemandEmployeeDialog = () => {
		handleAddEmployeeDialog(DIALOG_TYPES.CONTRACT);
	};

	return (
		<>
			{!project.employees.length && (isClientMode || isEmployeeMode) ? null : (
				<div className="people-container">
					<h2 className="people-title-container">
						Contract Employees
					</h2>
					<div className="people-container-members">
						{project.employees.filter(x => !x.isDeleted).map((employee, i) => (
							<Avatar
								alt="Emplyoee Avatar"
								className="avatar2 profile-image"
								src={employee.profilePictureImgUrl}
								onError={(e) => (e.target.src = defaultAvatar)}
								key={i + "avatar"}
								id={employee._id + project._id + CONTRACT_ID}
								onMouseEnter={() => handlePopoverOpen(employee._id, CONTRACT_ID, employee._id + project._id + CONTRACT_ID)}
								onMouseLeave={handlePopoverClose}
							/>
						))}
						{!isClientMode && !isEmployeeMode && !isSupplierEmployeeMode && (
							<Button
								size="small"
								variant="outlined"
								color="primary"
								className="add-person-button"
								onClick={handleAddOnDemandEmployeeDialog}>
								<AddIcon />
							</Button>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default Contract;
