import React from 'react';
import { ErrorMessage } from 'formik';

import AddEmployeeToDepartmentAutoComplete from './AddEmployeeToDepartmentAutoComplete';

import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import './DepartmentFormView.scss';

const DepartmentFormView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    employees,
}) => {
    const removeSelectedManager = (managerId) => {
        setFieldValue('managers', values.managers.filter(m => m._id !== managerId));
    };

    const removeSelectedMember = (memberId) => {
        setFieldValue('members', values.members.filter(m => m._id !== memberId));
    };

    const getNotSelectedManagers = () => {
        return employees.filter(employee => !values.managers?.some(x => employee._id === x._id));
    };

    const getNotSelectedMembers = () => {
        return employees.filter(employee => !values.members?.some( x => employee._id === x._id));
    };

    return (
        <form className="add-department-form" autoComplete="off">
            <div className="add-department-form-field name">
                <TextField
                    required
                    label="Department Name"
                    variant="outlined"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name}
                    fullWidth
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>

            <div className="add-department-form-field admins">
                <Typography className="label">Assign Admins</Typography>
                <AddEmployeeToDepartmentAutoComplete
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    inputLabel="Assign Admin"
                    inputName="admins"
                    employees={getNotSelectedManagers()}
                    selectedEmployees={values.managers}
                    formikFieldName="managers"
                />
                <div className="selected-employees-field">
                    {values.managers?.length > 0 &&
                    <List className="results-list">
                        {values.managers.map(employee => (
                            <div key={employee._id} className="result-list-item">
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar alt={employee.name} src={employee.profilePictureImage} />
                                    </ListItemAvatar>
                                    <ListItemText className="employee-info"
                                                  primary={employee.name}
                                                  secondary={employee.position}
                                    />
                                    <IconButton className="close-item" onClick={() => removeSelectedManager(employee._id)}>
                                        <CloseIcon />
                                    </IconButton>
                                </ListItem>
                            </div>
                        ))}
                    </List >
                    }
                </div>
            </div>

            <div className="add-department-form-field members">
                <Typography className="label">Add People to Department</Typography>
                <AddEmployeeToDepartmentAutoComplete
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    inputLabel="People"
                    inputName="members"
                    selectedEmployees={values.members}
                    employees={getNotSelectedMembers()}
                    formikFieldName="members"
                />
                <div className="selected-employees-field">
                    {values.members?.length > 0 &&
                    <List className="results-list">
                        {values.members.map(employee => (
                            <div key={employee._id} className="result-list-item">
                                <ListItem alignItems="center">
                                    <ListItemAvatar>
                                        <Avatar alt={employee.name} src={employee.profilePictureImage} />
                                    </ListItemAvatar>
                                    <ListItemText className="employee-info"
                                                  primary={employee.name}
                                                  secondary={employee.position}
                                    />
                                    <IconButton className="close-item" onClick={() => removeSelectedMember(employee._id)}>
                                        <CloseIcon />
                                    </IconButton>
                                </ListItem>
                            </div>
                        ))}
                    </List >
                    }
                </div>
            </div>
        </form>
    );
};

export default DepartmentFormView;
