import React, { createContext, useContext } from 'react';

import CandidateJourneyPreferences from 'components/CandidateProfileJourney/Steps/JobPreferencesWrapper/CandidateJourneyPreferences';

export const JobPreferencesWrapperContext = createContext();
const { Provider } = JobPreferencesWrapperContext;


const JobPreferencesWrapper = ({
    mainStepTitle,
    children,
    handleGoToNextStep,
}) => {
    const context = { mainStepTitle, handleGoToNextStep };

    return (
            <Provider value={context}>
                {children}
            </Provider>
    );
};

const PreferencesSection = ({
    substebTitle
}) => {
    const { mainStepTitle } = useContext(JobPreferencesWrapperContext);

    return (
        <>
            <p> {`${mainStepTitle}`} </p>
            <p> {`TChoose your ${substebTitle} substep`} </p>
        </>
    );
};

const CandidatePreferences = () => {
    const { handleGoToNextStep } = useContext(JobPreferencesWrapperContext);
    return (<CandidateJourneyPreferences handleGoToNextStep={handleGoToNextStep} />);
};

JobPreferencesWrapper.PreferencesSection = PreferencesSection;
JobPreferencesWrapper.CandidateJourneyPreferences = CandidatePreferences;

export default JobPreferencesWrapper;
