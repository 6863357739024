import React, { useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';

import SelectionCard from '../SelectionCard';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { validateRequiredFields } from 'utils/formValidations';

import { CANDIDATE_JOB_TYPE_OPTIONS } from '../YourProfileConstants';

import './JobTypeSelection.scss';

const JobTypeSelection = ({
    handleContinueJourney,
    agencyId,
    userId,
    candidatePreferences,
    updateCandidatePreferences,
}) => {
    const initialValues = { jobType: candidatePreferences?.jobType || '' };
    const [errorClassName, setErrorClassName] = useState('');

    const handleContinueClick = ({ errors, touched, handleSubmit }) => {
        if (Object.values(errors).length > 0 && Object.values(touched).length > 0) {
            setErrorClassName('transition-error');
            setTimeout(() => setErrorClassName('animation-error'), 10);
        }

        handleSubmit();
    };

    return (
        <div className="job-type-selection-wrapper">
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={(values) => {
                    return { ...validateRequiredFields(values, ['jobType']) }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    const isDirty = !isEqual(values, initialValues);
                    const action = updateCandidatePreferences.bind(null, agencyId, userId, values);

                    handleContinueJourney(null, isDirty, action);
                }}
            >
                {(props) => (
                    <>
                        <SelectionCard
                            {...props}
                            title="You're a great..."
                            subTitle="Tell us about your expertise and what job positions are you interested in."
                            options={CANDIDATE_JOB_TYPE_OPTIONS}
                            name="jobType"
                            errorClassName={errorClassName}
                        />
                        <div className="journey-buttons-wrapper">
                            <PrimaryButton
                                text="Continue"
                                handleClick={handleContinueClick.bind(null, props)}
                            />
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default JobTypeSelection;
