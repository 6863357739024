import { ErrorMessage } from 'formik';

import UploadImage from '../UploadImage';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const FillInformationFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    countries,
}) => {

    return (
        <form autoComplete="off">
            <div className="client-fields-wrapper">
                <div className="name-field">
                    <TextField
                        required
                        fullWidth
                        name="name"
                        label="Company Name"
                        variant="outlined"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.name && touched.name}
                    />
                    <ErrorMessage name="name" component="div" className="invalid-field-message" />
                </div>
                <div className="country-field">
                    <Autocomplete
                        autoHighlight
                        id="hqLocation"
                        name="country"
                        value={values.country}
                        onChange={(event, newValue) => setFieldValue('country', newValue)}
                        options={countries.map(country => country.name)}
                        getOptionLabel={(option) => option}
                        renderOption={(option) => (<span>{option}</span>)}
                        popupIcon={
                            <Icon>
                                <KeyboardArrowDownIcon />
                            </Icon>
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="HQ Location"
                                variant="outlined"
                                onBlur={handleBlur}
                                inputProps={{ ...params.inputProps }}
                            />
                        )}
                    />
                    <ErrorMessage name="country" component="div" className="invalid-field-message" />
                </div>
                <UploadImage
                    value={values.image}
                    setFieldValue={setFieldValue}
                />
            </div>
            <PrimaryButton className="select-a-role-action-btn" text="Save" handleClick={handleSubmit}/>
        </form>
    );
};

export default FillInformationFormView;
