import React, { Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    getDisplayMode, isHiddenSections,
    getAllClientTeamHistory,
    getAdminDashboardVettedAndRejectedCandidates, getAdminDashboardEmployeesByCountry,
    getAdminDashboardPositionsNums,
    isAdmin,
    getDashboardProjectOverviewProjects,
    getAdminDashboardConsultantsCounts,
} from 'reducers';
import { fetchClientProjectsWithEmployees, fetchAllClientTeamHistory, registerClient } from 'actions/clientActions';
import { registerSupplier } from 'actions/supplierActions';

import { fetchAllClientConsultants, registerEmployee } from 'actions/employeeActions';
import ProjectsOverviewTable from './ProjectsOverviewTable';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import AddSupplierDialog from 'components/Suppliers/AddSupplierDialog';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { ROLES } from 'constants/userConstants';

import './ProjectsOverview.scss';

const AddClientDialog = React.lazy(() => import('components/Clients/AddClientDialog'));

const ProjectsOverview = ({
    agencyId,
    userId,
    projects,
    fetchClientProjectsWithEmployees,
    fetchAllClientConsultants,
    fetchAllClientTeamHistory,
    allClientTeamHistory,
    agencyDashboard,
    isDataAvailable,
    candidates,
    positionsData,
    employeesByCountry,
    registerClient,
    registerSupplier,
    registerEmployee,
    hiddenSections,
    displayMode,
    isAdmin,
    consultantsCount,
    dataFetchState
}) => {
    const requiredFields = ['firstName', 'lastName'];
    const adminData = hiddenSections ? [candidates] : [positionsData, employeesByCountry, candidates]

    useEffect(() => {
        if (!isDataAvailable) {
            if (!agencyDashboard) {
                fetchAllClientConsultants(agencyId, requiredFields);
                fetchClientProjectsWithEmployees(agencyId, userId);
                fetchAllClientTeamHistory(agencyId, userId)
            }
        }
    }, []);

    const [role, setRole] = useState('');
    const [newUserData, setNewUserData] = useState({});

    //Confirm Dialog

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    //Add Employee Dialog

    const [openAddEmployeeDialog, setOpenAddEmployeeDialog] = useState(false);

    const handleOpenAddEmployeeDialog = () => setOpenAddEmployeeDialog(true);

    const handleCloseAddEmployeeDialog = () => setOpenAddEmployeeDialog(false);

    //Add Supplier Dialog

    const [openAddSupplierDialog, setOpenAddSupplierDialog] = useState(false);

    const handleOpenAddSupplierDialog = () => setOpenAddSupplierDialog(true);

    const handleCloseAddSupplierDialog = () => setOpenAddSupplierDialog(false);

    //Add Client Dialog

    const [openAddClientDialog, setOpenAddClientDialog] = useState(false);

    const handleOpenAddClientDialog = () => setOpenAddClientDialog(true);

    const handleCloseAddClientDialog = () => setOpenAddClientDialog(false);

    const sortAlphabetically = (a, b) => a.name.localeCompare(b.name);

    const handleSubmitClick = async (values, role) => {
        if (role === ROLES.EMPLOYEE) {
            const { email, name, employmentType, startDate } = values;

            values = {
                email,
                name,
                employmentInformation: {
                    generalInfo: { startDate },
                    contractInformation: { employmentType }
                },
                role: ROLES.EMPLOYEE
            };
        }

        if (values.email) {
            setRole(role);
            setNewUserData(values)
            setOpenConfirmDialog(true);
            return
        }

        switch (role) {
            case ROLES.CLIENT:
                await registerClient(agencyId, values);
                break;
            case ROLES.SUPPLIER_ONLY:
                await registerSupplier(agencyId,
                    {
                        ...values,
                        employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } }
                    }
                );
                break;
            case ROLES.EMPLOYEE:
                await registerEmployee(values, agencyId);
                break;
            default:
                break;
        }
        handleCloseAddClientDialog();
        handleCloseAddEmployeeDialog();
        handleCloseAddSupplierDialog();
        setRole('');
    };

    const handleProceedClick = async () => {
        handleCloseConfirmDialog();

        switch (role) {
            case ROLES.CLIENT:
                await registerClient(agencyId, newUserData);
                break;
            case ROLES.SUPPLIER_ONLY:
                await registerSupplier(agencyId,
                    {
                        ...newUserData,
                        employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } }
                    }
                );
                break;
            case ROLES.EMPLOYEE:
                await registerEmployee(newUserData, agencyId);
                break;
            default:
                break;
        }

        handleCloseAddClientDialog();
        handleCloseAddSupplierDialog();
        handleCloseAddEmployeeDialog();
        setNewUserData({});
        setRole('');
    };

    const projectsData = projects => {
        return projects.reduce((acc, project) => {
            if (moment.utc(project.startDate).diff(moment.utc(), 'days') <= 0 && (!project.endDate || moment.utc(project.endDate) > moment.utc() || project.ongoing)) {
                acc.activeProjects++;
            } else if (moment.utc(project.startDate) > moment.utc()) {
                acc.upcomingProjects++;
            } else {
                acc.pastProjects++;
            }
            return acc;
        }, { activeProjects: 0, upcomingProjects: 0, pastProjects: 0 })
    }

    const filterOutCurrentTeamDuplicatedEmployees = (employees) => {
        const onDemand = Object.keys(employees.on_demand);
        const dedicated = Object.keys(employees.dedicated);

        const currentTeam = onDemand.concat(dedicated.filter(value => !onDemand.includes(value)));

        return Object.values(currentTeam);
    };

    const consultantsData = (projects) => {
        const employees = projects.reduce((acc, project) => {
            if (project.employees) {
                project.employees.map(employee => { acc.on_demand[employee._id] = true; return employee; });
            }
            if (project.dedicatedEmployees) {
                project.dedicatedEmployees.map(employee => { acc.dedicated[employee._id] = true; return employee; });
            }

            return acc;
        }, { on_demand: {}, dedicated: {} })

        return displayMode === ROLES.CLIENT && hiddenSections ?
            { currentTeam: filterOutCurrentTeamDuplicatedEmployees(employees).length }
            :
            { on_demand: Object.keys(employees.on_demand).length, dedicated: Object.keys(employees.dedicated).length }

    };

    return (
        <div className="projects-overview-container">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Projects overview'
                />

                {agencyDashboard &&
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                            text='Add Partner'
                            onClick={handleOpenAddSupplierDialog}
                        />
                        <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                            text='Add Client'
                            onClick={handleOpenAddClientDialog}
                        />
                        <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                            text='Add Employee'
                            onClick={handleOpenAddEmployeeDialog}
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                }
            </SectionActionsWrapper>

            <ProjectsOverviewTable
                dataFetchState={dataFetchState}
                projectsData={agencyDashboard ? {} : projectsData(projects)}
                consultantsData={agencyDashboard ? consultantsCount : consultantsData(projects)}
                adminData={adminData}
                agencyDashboard={agencyDashboard}
                hiddenSections={hiddenSections}
                displayMode={displayMode}
                allClientTeamHistory={allClientTeamHistory}
                isAdmin={isAdmin}
            />

            {openAddClientDialog &&
                <Suspense>
                    <AddClientDialog
                        openDialog={openAddClientDialog}
                        handleCloseDialog={handleCloseAddClientDialog}
                        registerClient={registerClient}
                        agencyId={agencyId}
                        handleSubmitClick={handleSubmitClick}
                        sortAlphabetically={sortAlphabetically}
                    />
                </Suspense>
            }

            {openAddSupplierDialog &&
                <AddSupplierDialog
                    openDialog={openAddSupplierDialog}
                    handleCloseDialog={handleCloseAddSupplierDialog}
                    registerSupplier={registerSupplier}
                    agencyId={agencyId}
                    handleSubmitClick={handleSubmitClick}
                    sortAlphabetically={sortAlphabetically}
                />
            }

            {openAddEmployeeDialog &&
                <AddTeamMemberDialog
                    isOpen={openAddEmployeeDialog}
                    handleCloseDialog={handleCloseAddEmployeeDialog}
                    handleRegisterMember={handleSubmitClick}
                    newUserData={newUserData}
                    isAddingEmployee={true}
                />
            }

            <ConfirmInviteUserDialog
                openDialog={openConfirmDialog}
                invitedUser={role}
                handleInviteUser={handleProceedClick}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => ({
    projects: getDashboardProjectOverviewProjects(state, ownProps.agencyDashboard),
    hiddenSections: isHiddenSections(state),
    displayMode: getDisplayMode(state),
    allClientTeamHistory: getAllClientTeamHistory(state),
    candidates: getAdminDashboardVettedAndRejectedCandidates(state),
    positionsData: getAdminDashboardPositionsNums(state),
    employeesByCountry: getAdminDashboardEmployeesByCountry(state),
    isAdmin: isAdmin(state),
    consultantsCount: getAdminDashboardConsultantsCounts(state),
});

const mapDispatchToProps = {
    fetchClientProjectsWithEmployees,
    fetchAllClientConsultants,
    fetchAllClientTeamHistory,
    registerClient,
    registerSupplier,
    registerEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsOverview);
