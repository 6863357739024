import React from 'react';

import TableHeader from '../TableHeader';
import TableColumnsFilter from '../TableColumnsFilter';
import TableActionButton from '../TableActionButton';
import TableFilter from '../TableFilter';
import TableSearch from '../TableSearch';
import TableDateFilter from '../TableDateFilter';

import './TableAlignedItems.scss';

const TableAlignedItems = ({
    children,
}) => {
    return (
        <div className="table-aligned-items">
            {children}
        </div>
    )
};

const LeftAligned = ({
    children,
}) => (
    <div className="left-aligned">
        {children}
    </div>
);

const RightAligned = ({
    children,
}) => (
    <div className="right-aligned">
        {children}
    </div>
);

TableAlignedItems.LeftAligned = LeftAligned;
TableAlignedItems.RightAligned = RightAligned;

TableAlignedItems.TableHeader = TableHeader;
TableAlignedItems.TableColumnsFilter = TableColumnsFilter;
TableAlignedItems.TableActionButton = TableActionButton;
TableAlignedItems.TableFilter = TableFilter;
TableAlignedItems.TableSearch = TableSearch;
TableAlignedItems.TableDateFilter = TableDateFilter;

export default TableAlignedItems;
