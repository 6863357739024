import React, { useState } from 'react';

import MainMenu from './MainMenu';

import { getClientMenuItems, getClientMenuItemsSaas } from '../constants/clientMenuConstants';

const ClientMenu = ({ hiddenSections, agencyRouteName }) => {
    const [clientMenuItems] = useState(getClientMenuItems(agencyRouteName));
    const [clientMenuItemsSaas] = useState(getClientMenuItemsSaas(agencyRouteName));

    return (
        <MainMenu
            menuItems={hiddenSections
                ? clientMenuItemsSaas
                : clientMenuItems}
        />
    )
};

export default ClientMenu;
