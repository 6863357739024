import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import { ExploreTalentsEmployeeCardContext } from '../ExploreTalentsEmployeeCard'

import { ReactComponent as CompanyIcon } from 'assets/icon-awesome-building.svg';
import { ReactComponent as LocationIcon } from 'assets/icon-map-location-arrow.svg';

import { getStateAgencyName, getProfileCvLink } from 'utils/helpers';
import { showPopupInScreen } from 'utils/uiHelpers';

const CardProfileInfoWrapper = ({
    employee,
}) => {
    const { setPersonalInfoInitialState } = useContext(ExploreTalentsEmployeeCardContext)
    const visibleSkills = employee.skills?.slice(0, 4);
    const restSkills = employee.skills?.slice(4);
    const restSkillsCount = restSkills?.length;
    const agencyRouteName = getStateAgencyName();
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({ transform: 'translateX(-50%)' });

    const handleClick = () => {
        setPersonalInfoInitialState()
    }

    //Handle mouse over on rest skills
    const handleRestSkillsHover = (visibility, employee) => {
        setIsShowMorePopupVisible(visibility);
        
        if (employee) {
            showPopupInScreen(`rest-skills-text-${employee._id}`, setPopupTransform);
            setSelectedEmployee(employee);
        } else {
            setSelectedEmployee({});
        }

    };

    return (
        <div className="employee-profile-info-container">
            <div className="employee-profile-header-wrapper">
                <div className="employee-name-info">
                    <Link className='employee-name-link-wrapper' to={getProfileCvLink(agencyRouteName, employee)} onClick={handleClick}>
                        <Typography className="employee-name">{`${employee.firstName} ${employee.lastName}`}</Typography>
                    </Link>
                </div>
                {employee.company &&
                    <div className="employee-company-info">
                        <div className="icon-wrapper">
                            <CompanyIcon className="employee-company-icon" />
                        </div>
                        <span className="employee-company-text">{employee.company}</span>
                    </div>
                }
                {employee.location &&
                    <div className="employee-location-info">
                        <div className="icon-wrapper">
                            <LocationIcon className="employee-location-icon" />
                        </div>
                        <span className="employee-location-text">{employee.location}</span>
                    </div>
                }
            </div>
            {employee.employmentInformation?.generalInfo?.position &&
                <div className="employee-job-title-wrapper">
                    <span className="job-title-text">{employee.employmentInformation.generalInfo.position}</span>
                </div>
            }
            {visibleSkills?.length > 0 &&
                <>
                    <Divider className="explore-talents-profile-info-divider" />
                    <div className="employee-skills-container">
                        <List className="employee-skills-list" disablePadding>
                            {visibleSkills?.map((skill, index) => (
                                <ListItem key={index} className="employee-skill-list-item">
                                    <Chip label={skill} variant="outlined" className="employee-skill" />
                                </ListItem>
                            ))}
                            {restSkillsCount > 0 &&
                                <div
                                    className="rest-skills-container"
                                    onMouseOver={() => handleRestSkillsHover(true, employee)}
                                    onMouseLeave={() => handleRestSkillsHover(false)}
                                >
                                    <ListItem className="rest-skills-count">
                                        <span id={`rest-skills-text-${employee._id}`}>
                                            {`+ ${restSkillsCount} more`}
                                        </span>
                                        {isShowMorePopupVisible && selectedEmployee._id === employee._id &&
                                            <div className="rest-skills-popup" style={popupTransform}>{restSkills.map((skill, index) => (
                                                <div
                                                    key={`${skill} ${index}`}
                                                    className="single-technology-box no-border"
                                                >
                                                    <span className="technology-text">{skill}</span>
                                                </div>
                                            ))}</div>
                                        }
                                    </ListItem>
                                </div>
                            }
                        </List>
                    </div>
                </>
            }
        </div>
    );
};


export default CardProfileInfoWrapper;
