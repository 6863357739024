import React from 'react';
import { connect } from 'react-redux';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';

import {
    getInitialJourneyCompletedStepsIds,
    getCurrentJourneyStepId,
    getMenuInformation,
} from 'reducers';

import {
    getMainStepTitles,
    getCandidateCurrentMainStepTitle,
    getCandidateCompletedMainStepTitles,
    getFirstStepIdOfTitle,
    getCandidateNextMainStepTitle,
} from 'components/CandidateProfileJourney/CandidateProfileJourneyUtils';

import './CandidateProfileJourneyMobileStepper.scss';

const CandidateProfileJourneyMobileStepper = ({
    completedStepsIds,
    currentJourneyStep,
    updateCandidateJourneyCurrentStepId,
}) => {
    const CANDIDATE_CREATE_PROFILE_JOURNEY_STEP_TITLES = getMainStepTitles();
    const CANDIDATE_CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE = getCandidateCurrentMainStepTitle(currentJourneyStep);
    const CANDIDATE_CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES = getCandidateCompletedMainStepTitles(completedStepsIds);
    const CANDIDATE_CREATE_PROFILE_JOURNEY_NEXT_MAIN_STEP_TITLE = getCandidateNextMainStepTitle(completedStepsIds);

    const stepsChangeHandler = (spanElement) => {
        const possibleSteps = [CANDIDATE_CREATE_PROFILE_JOURNEY_NEXT_MAIN_STEP_TITLE, ...CANDIDATE_CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES];    
        
        const stepTitle = spanElement.innerText;

        if(possibleSteps.includes(stepTitle)) {
            const stepId = getFirstStepIdOfTitle(stepTitle);
            updateCandidateJourneyCurrentStepId(stepId);
        }
    };

    return (
        <Stepper
            className="candidate-initial-journey-mobile-stepper"
            connector={<StepConnector className="step-connector" />}
        >
            {CANDIDATE_CREATE_PROFILE_JOURNEY_STEP_TITLES.map((stepTitle, index) => (
                <Step key={index} className="step-container">
                    <StepLabel
                        onClick={(e) => stepsChangeHandler(e.target)}
                        className={CANDIDATE_CREATE_PROFILE_JOURNEY_CURRENT_STEP_TITLE === stepTitle ? "step-label selected" : "step-label"}
                        StepIconComponent={() =>
                            <div className="step-box">
                                {
                                    CANDIDATE_CREATE_PROFILE_JOURNEY_COMPLETED_MAIN_STEP_TITLES.includes(stepTitle)
                                        ? <CheckmarkIcon className="checkmark-icon" />
                                        : <span className="step">{index + 1}</span>
                                }
                            </div>
                        }
                    >
                        {stepTitle}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

const mapStateToProps = (state) => ({
    completedStepsIds: getInitialJourneyCompletedStepsIds(state),
    currentJourneyStep: getCurrentJourneyStepId(state),
    menuInformation: getMenuInformation(state),
});

export default connect(mapStateToProps, null)(CandidateProfileJourneyMobileStepper); 
