import React, { useEffect, useState, useRef } from 'react';
import ReactCrop from 'react-image-crop';

import CommonDialog from 'components/Shared/CommonDialog';

import { makeClientCrop } from 'utils/imageProcessingUtils';

import { INITIAL_CROP } from 'constants/commonConstants';
import './ImageEditDialog.scss';

const ImageEditDialog = ({
    classes,
    dialogTitle,
    isDialogOpen,
    handleCloseDialog,
    currentImgSelected,
    currentProfilePictureFileName,
    handleUpdateAvatar,
}) => {
    const [croppedImage, setCroppedImage] = useState();
    const [imageRef, setImageRef] = useState();
    const [crop, setCrop] = useState(INITIAL_CROP);

    const canvasRefImageCroppRef = useRef();

    useEffect(() => {
        onCropComplete(crop);
    }, [imageRef]);

    const onImageLoaded = image => setImageRef(image);

    const onCropComplete = crop => makeClientCrop(currentImgSelected, imageRef, crop, canvasRefImageCroppRef, currentProfilePictureFileName, setCroppedImage);

    const onCropChange = crop => {
        setCrop(crop);
    };

    const onImgCropSave = avatarInfo => {
        const data = new FormData()
        data.append('croppedImg', avatarInfo.file);
        data.append('croppedImgInfo', JSON.stringify(avatarInfo));
        handleUpdateAvatar(data);
        handleCloseDialog();
    }

    return (
        <CommonDialog
            openDialog={isDialogOpen}
            secondaryButtonText="Discard"
            primaryButtonText="Save"
            dialogTitle={dialogTitle || "Edit Picture"}
            handleCloseDialog={handleCloseDialog}
            className={`edit-image-dialog${classes?.length > 0 ? ` ${classes.join(' ')}` : ''}`}
            handleProceedDialog={() => onImgCropSave(croppedImage)}
        >
            {currentImgSelected &&
                <div className="react-crop-container">
                    <ReactCrop
                        src={currentImgSelected}
                        crop={crop}
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        minHeight={50}
                        minWidth={50}
                        keepSelection={true}
                    />
                </div>
            }

            <canvas className="img-cropp-container" ref={canvasRefImageCroppRef}></canvas>
        </CommonDialog>
    )
};

export default ImageEditDialog;
