import { logEvent } from 'utils/amplitude';
import jobOpportunityService from 'services/jobOpportunityService'
import clientService from 'services/clientService'
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import {
    fetchCandidateVideoInterviewAnswersSuccess,
    fetchCandidatePreferencesSuccess,
    fetchProfileSuccess,
    fetchCandidateApplicationSuccess,
    fetchInterviewInvitationSuccess
} from 'actions/candidateActions'

import { fetchInterviewInvitationsSuccess } from './interviewInvitationActions';
import {
    fetchAppliedConsultantsDataSuccess,
    updateAppliedConsultantsDataSuccess,
    updateAppliedConsultantApplicationSuccess,
    updateAppliedConsultantsApplicationSuccess,
    updateApplicationStatusSuccess
} from './jobApplicationActions'
import { fetchHistoricalNotesSuccess } from './historicalNotesActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { JOB_OPPORTUNITY_MESSAGES, EMPLOYEE_MESSAGES, CLIENT_MESSAGES } from 'constants/messageConstants';
import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { JOB_OPPORTUNITIES_PRIVACY, JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';

import {
    JOB_OPPORTUNITY_ADD_SUCCESS,
    JOB_OPPORTUNITIES_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS,
    JOB_OPPORTUNITY_DELETE_SUCCESS,
    JOB_OPPORTUNITY_UPDATE_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS,
    CLIENT_JOBS_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS,
    JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS,
    JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS,
    JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS,
    JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS,
    JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS,
    JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS,
    MATCHING_CONSULTANTS_FETCH_SUCCESS,
    JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    TALENT_MATCHING_JOBS_FETCH_SUCCESS,
    CLIENT_JOBS_RESET_STATE_SUCCESS,
    JOBS_RESET_STATE_SUCCESS,
    INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS,
    AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS,
    AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS,
} from 'actions/actionTypes';

export const addJobOpportunitySuccess = jobData => ({
    type: JOB_OPPORTUNITY_ADD_SUCCESS,
    payload: jobData
});

export const fetchJobsSuccess = jobs => ({
    type: JOB_OPPORTUNITIES_FETCH_SUCCESS,
    payload: jobs
});

export const fetchJobOpportunitiesCountSuccess = data => ({
    type: JOB_OPPORTUNITIES_COUNT_FETCH_SUCCESS,
    payload: data,
});

const fetchClientJobOpportunitiesSuccess = jobs => ({
    type: CLIENT_JOBS_FETCH_SUCCESS,
    payload: jobs
});

const resetClientJobOpportunitiesSuccess = () => ({
    type: CLIENT_JOBS_RESET_STATE_SUCCESS,
});

const resetJobsSuccess = () => ({
    type: JOBS_RESET_STATE_SUCCESS,
});

export const updateJobSuccess = jobData => ({
    type: JOB_OPPORTUNITY_UPDATE_SUCCESS,
    payload: jobData,
});

export const deleteJobSuccess = jobData => ({
    type: JOB_OPPORTUNITY_DELETE_SUCCESS,
    payload: jobData,
});

export const fetchJobOpportunityDetailsSuccess = jobDetails => ({
    type: JOB_OPPORTUNITY_DETAILS_FETCH_SUCCESS,
    payload: jobDetails
});

const jobOpportunitiesListDataFetchSuccess = () => ({
    type: JOB_OPPORTUNITIES_LIST_DATA_FETCH_SUCCESS,
});

const jobOpportunitiesListDataFetchingSuccess = () => ({
    type: JOB_OPPORTUNITIES_LIST_DATA_FETCHING_SUCCESS,
});

const jobOpportunitiesPageFetchSuccess = () => ({
    type: JOB_OPPORTUNITY_PAGE_FETCH_SUCCESS,
});

const jobOpportunitiesPageFetchingSuccess = () => ({
    type: JOB_OPPORTUNITY_PAGE_FETCHING_SUCCESS,
});

const jobMatchingConsultantsPageFetchSuccess = () => ({
    type: JOB_MATCHING_CONSULTANTS_PAGE_FETCH_SUCCESS,
});

const jobMatchingConsultantsPageFetchingSuccess = () => ({
    type: JOB_MATCHING_CONSULTANTS_PAGE_FETCHING_SUCCESS,
});

const jobOpportunityDetailsDataFetchSuccess = () => ({
    type: JOB_OPPORTUNITY_DETAILS_DATA_FETCH_SUCCESS,
});

const jobOpportunityDetailsDataFetchingSuccess = () => ({
    type: JOB_OPPORTUNITY_DETAILS_DATA_FETCHING_SUCCESS,
});

const fetchJobOpportunitiesTechnologiesSuccess = data => ({
    type: JOB_OPPORTUNITIES_TECHNOLOGIES_FETCH_SUCCESS,
    payload: data
});


const fetchMatchingConsultantsSuccess = result => ({
    type: MATCHING_CONSULTANTS_FETCH_SUCCESS,
    payload: result,
});

const updateJobOpportunityNewApplicationsCount = data => ({
    type: JOB_OPPORTUNITY_NEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    payload: data,
});

const updateJobOpportunityPendingAdminReviewApplicationsCount = data => ({
    type: JOB_OPPORTUNITY_PENDING_ADMIN_REVIEW_APPLICATIONS_COUNT_UPDATE_SUCCESS,
    payload: data,
});

const fetchTalentMatchingJobsSuccess = matchingJobs => ({
    type: TALENT_MATCHING_JOBS_FETCH_SUCCESS,
    payload: matchingJobs
});

const fetchIndexedJobsSuccess = jobs => ({
    type: INDEXED_JOB_OPPORTUNITIES_FETCH_SUCCESS,
    payload: jobs
});

const aiMatchedCandidatesScoresFetchingSuccess = () => ({
    type: AI_MATCHED_CANDIDATES_SCORES_FETCHING_SUCCESS,
});

const aiMatchedCandidatesScoresFetchSuccess = () => ({
    type: AI_MATCHED_CANDIDATES_SCORES_FETCH_SUCCESS,
});

export const uploadImage = (agencyId, croppedImgData, oldImageName) => async dispatch => {
    try {
        const resultUploadedPictureObject = await jobOpportunityService.uploadImage(agencyId, oldImageName, croppedImgData);
        return resultUploadedPictureObject;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.UPLOAD_IMAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteImage = (agencyId, imageName) => async dispatch => {
    try {
        await jobOpportunityService.deleteImage(agencyId, imageName);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.DELETE_IMAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createJob = (agencyId, jobData) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await jobOpportunityService.createJob(agencyId, jobData);

        logEvent(AMPLITUDE_EVENT_TYPES.CREATE_JOB, { rolesPermitted: [ROLES.CLIENT, ROLES.ADMIN] }, { jobType: JOB_OPPORTUNITIES_TYPES.CONTRACT });
        dispatch(addJobOpportunitySuccess(result));
        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.CREATE_JOB_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.CREATE_JOB_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchJobOpportunities = (agencyId, filters, isInitialFetch, isSearching) => async dispatch => {
    try {
        if (isInitialFetch) {
            dispatch(jobOpportunitiesListDataFetchingSuccess());
            dispatch(resetJobsSuccess());
        }

        const { jobs, jobsTotalCount, contractorJobsTotalCount, fullTimeJobsTotalCount } = await jobOpportunityService.fetchJobOpportunities(agencyId, filters);
        
        if (isInitialFetch || isSearching) {
            dispatch(fetchIndexedJobsSuccess(jobs));
        } else {
            dispatch(fetchJobsSuccess(jobs))
        }

        dispatch(fetchJobOpportunitiesCountSuccess({ jobsTotalCount, contractorJobsTotalCount, fullTimeJobsTotalCount }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOBS_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(jobOpportunitiesListDataFetchSuccess());
    }
};

const sendEmailToMatchedCandidates = (agencyId, jobId, oldJobData, updatedJobData) => async dispatch => {
    try {
        const isValidForSendingEmails = await jobOpportunityService.sendEmailToMatchedCandidates(agencyId, jobId, oldJobData, updatedJobData);

        if (isValidForSendingEmails) {
            dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.SEND_EMAILS_TO_MATCHED_CANDIDATES_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.SEND_EMAILS_TO_MATCHED_CANDIDATES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateJob = (agencyId, jobId, jobData, isWithEmails = false) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const { oldJobData, updatedJobData } = await jobOpportunityService.updateJob(agencyId, jobId, jobData);

        dispatch(updateJobSuccess(updatedJobData));

        if (isWithEmails) {
            dispatch(sendEmailToMatchedCandidates(agencyId, jobId, oldJobData, updatedJobData));
        }

        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.UPDATE_JOB_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.UPDATE_JOB_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
}

export const deleteJob = (agencyId, jobId, jobType) => async dispatch => {
    try {
        await jobOpportunityService.deleteJob(agencyId, jobId);

        dispatch(deleteJobSuccess({ _id: jobId, jobType }));
        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.DELETE_JOB_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.DELETE_JOB_FAIL, NOTIFICATION_TYPES.ERROR))
    }
}

export const fetchJobOpportunityDetails = (agencyId, jobId, showErrorNotification = true) => async dispatch => {
    try {
        dispatch(jobOpportunityDetailsDataFetchingSuccess());

        const result = await jobOpportunityService.fetchJobOpportunityDetails(agencyId, jobId);

        dispatch(fetchJobOpportunityDetailsSuccess(result));
    } catch (error) {
        if (showErrorNotification) {
            dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOB_DETAILS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(jobOpportunityDetailsDataFetchSuccess());
    }
};

export const updateJobOpportunityPrivacy = (agencyId, jobId, privacyData, hasClientLogo = true) => async dispatch => {
    try {
        const { oldJobData, updatedJobData } = await jobOpportunityService.updateJobOpportunityPrivacy(agencyId, jobId, privacyData);

        dispatch(updateJobSuccess(updatedJobData));
        dispatch(sendEmailToMatchedCandidates(agencyId, jobId, oldJobData, updatedJobData));

        if (privacyData.privacy === JOB_OPPORTUNITIES_PRIVACY.PUBLISHED._id && !hasClientLogo) {
            dispatch(showNotification(CLIENT_MESSAGES.CLIENT_MISSING_LOGO_WARNING, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.UPDATE_JOB_PRIVACY_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
        }

    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.UPDATE_JOB_PRIVACY_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchClientJobOpportunities = (agencyId, clientId, filters, isInitialFetch, isSearching) => async dispatch => {
    try {
        if (isInitialFetch) {
            dispatch(jobOpportunitiesListDataFetchingSuccess());
            dispatch(resetClientJobOpportunitiesSuccess());
        }

        const { jobs, jobsTotalCount, contractorJobsTotalCount, fullTimeJobsTotalCount } = await clientService.fetchAllClientJobs(agencyId, clientId, filters);

        if (isInitialFetch || isSearching) {
            dispatch(fetchIndexedJobsSuccess(jobs));
        } else {
            dispatch(fetchClientJobOpportunitiesSuccess(jobs));
        }

        dispatch(fetchJobOpportunitiesCountSuccess({ jobsTotalCount, contractorJobsTotalCount, fullTimeJobsTotalCount }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOBS_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(jobOpportunitiesListDataFetchSuccess());
    }
};

export const fetchJobOpportunitiesTechnologies = agencyId => async dispatch => {
    try {
        const result = await jobOpportunityService.fetchJobOpportunitiesTechnologies(agencyId);

        dispatch(fetchJobOpportunitiesTechnologiesSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOBS_TECHNOLOGIES_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchAgencyIndexedJobs = (agencyId, userId) => async dispatch => {
    try {
        dispatch(jobOpportunitiesListDataFetchingSuccess());

        const result = await jobOpportunityService.fetchAgencyIndexedJobs(agencyId, userId);

        dispatch(fetchIndexedJobsSuccess(result.jobsData));

        return result.isWithMatch;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOBS_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(jobOpportunitiesListDataFetchSuccess());
    }
};

export const fetchJobOpportunityCandidateApplication = (agencyId, jobId, candidateId, applicationId) => async dispatch => {
    try {
        dispatch(jobOpportunityDetailsDataFetchingSuccess());

        const { journeyVideoInterviewAnswer, ...rest } = await jobOpportunityService.fetchJobOpportunityCandidateApplication(agencyId, jobId, candidateId, applicationId);

        dispatch(fetchCandidateVideoInterviewAnswersSuccess(journeyVideoInterviewAnswer ? [journeyVideoInterviewAnswer] : []));
        dispatch(fetchCandidatePreferencesSuccess(rest.preferences));
        dispatch(fetchProfileSuccess(rest.profile));
        dispatch(fetchCandidateApplicationSuccess(rest.application));
        dispatch(fetchInterviewInvitationSuccess(rest.interviewInvitation));
        dispatch(fetchJobOpportunityDetailsSuccess(rest.jobDetails));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CANDIDATE_APPLICATION_PAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(jobOpportunityDetailsDataFetchSuccess());
    }
};

export const fetchJobOpportunityPage = (agencyId, jobId) => async dispatch => {
    try {
        dispatch(jobOpportunitiesPageFetchingSuccess());
        const result = await jobOpportunityService.fetchJobOpportunityPage(agencyId, jobId);

        dispatch(fetchJobOpportunityDetailsSuccess(result.jobDetails));
        dispatch(fetchInterviewInvitationsSuccess(result?.upcomingInterviews || []));
        dispatch(fetchAppliedConsultantsDataSuccess(result.appliedCandidates));
        dispatch(fetchHistoricalNotesSuccess({ notes: result?.historicalNotes || [] }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOB_PAGE_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(jobOpportunitiesPageFetchSuccess());
    }
}

export const fetchJobMatchingConsultantsPage = (agencyId, jobId) => async dispatch => {
    try {
        dispatch(jobMatchingConsultantsPageFetchingSuccess());

        const result = await jobOpportunityService.fetchJobMatchingConsultantsPage(agencyId, jobId);

        dispatch(fetchJobOpportunityDetailsSuccess(result.jobDetails));
        dispatch(fetchMatchingConsultantsSuccess(result.matchingConsultants));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_JOB_PAGE_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(jobMatchingConsultantsPageFetchSuccess());
    }
}

export const exportJobOpportunities = (agencyId, filters) => async dispatch => {
    try {
        await jobOpportunityService.exportJobOpportunities(agencyId, filters);

        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.EXPORT_JOBS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.EXPORT_JOBS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportClientJobOpportunities = (agencyId, clientId, filters) => async dispatch => {
    try {
        await jobOpportunityService.exportClientJobOpportunities(agencyId, clientId, filters);

        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.EXPORT_JOBS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.EXPORT_JOBS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const sendProfilesToJobOpportunity = (agencyId, jobId, profiles, notifyClient) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await jobOpportunityService.sendProfilesToJobOpportunity(agencyId, jobId, profiles, notifyClient);

        dispatch(updateAppliedConsultantsDataSuccess(result.map(x => ({ ...x, isNew: true }))));
        if (result.find(x => x.status === APPLICATION_STATUSES.NEW_APPLICATION)) {
            dispatch(updateJobOpportunityNewApplicationsCount(result.length));
        } else {
            dispatch(updateJobOpportunityPendingAdminReviewApplicationsCount(result.length));
        }
        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.SEND_PROFILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.SEND_PROFILES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const sendProfileToClient = (agencyId, jobId, profile, notifyClient) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await jobOpportunityService.sendProfileToClient(agencyId, jobId, profile, notifyClient);

        dispatch(updateApplicationStatusSuccess({ status: result.status, comment: result.comment }))
        dispatch(updateAppliedConsultantApplicationSuccess(result));
        dispatch(updateJobOpportunityNewApplicationsCount(1));
        dispatch(showNotification(JOB_OPPORTUNITY_MESSAGES.SEND_PROFILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.SEND_PROFILES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const fetchMatchingJobs = (agencyId, userId) => async dispatch => {
    try {
        const matchingJobs = await jobOpportunityService.fetchMatchingJobs(agencyId, userId);

        dispatch(fetchTalentMatchingJobsSuccess(matchingJobs.jobsData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const getAiMatchedCandidatesScores = (agencyId, jobId, candidateIds) => async dispatch => {
    try {
        dispatch(aiMatchedCandidatesScoresFetchingSuccess());

        const result = await jobOpportunityService.getAiMatchedCandidatesScores(agencyId, jobId, candidateIds);

        dispatch(updateAppliedConsultantsApplicationSuccess(result.map(x => ({ ...x, isNew: false }))));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_OPPORTUNITY_MESSAGES.FETCH_MATCHING_SCORE_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(aiMatchedCandidatesScoresFetchSuccess());
    }
};
