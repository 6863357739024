import React from 'react';
import PrimaryButton from '../PrimaryButton';

import { ReactComponent as ExportButtonIcon } from 'assets/export-button-icon.svg';

const PrimaryExportButton = React.forwardRef((props, ref) => (
    <PrimaryButton
        ref={ref}
        icon={<ExportButtonIcon />}
        {...props}
    />
));


export default PrimaryExportButton;
