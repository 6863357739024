import React from 'react';
import { connect } from 'react-redux';

import { getMatchingJobOpportunities } from 'reducers';

import MatchingJobCard from './MatchingJobCard';
import { ReactComponent as NoItemsFoundIcon } from 'assets/no-items-found.svg';

const MatchingJobsRedux = ({
    agencyId,
    isAdmin,
    onEditClick,
    handleOpenDeleteDialog,
    updateJob,
    updateJobOpportunityPrivacy,
    setClosePopoverFunction,
    matchingJobs,
}) => {
    return (
        matchingJobs?.length > 0
            ? matchingJobs.map((job, i) => (
                <MatchingJobCard
                    key={job._id}
                    agencyId={agencyId}
                    isAdmin={isAdmin}
                    job={job}
                    onEditClick={onEditClick}
                    handleOpenDeleteDialog={handleOpenDeleteDialog.bind(null, job)}
                    updateJob={updateJob}
                    updateJobOpportunityPrivacy={updateJobOpportunityPrivacy}
                    setClosePopoverFunction={setClosePopoverFunction}
                >
                    <MatchingJobCard.MatchingJobTypeLabel />
                    <MatchingJobCard.MatchingJobLocationLabel />
                    <MatchingJobCard.MatchingJobMainInfo />
                    <MatchingJobCard.MatchingJobProfiles />
                    <MatchingJobCard.MatchingJobFooter />
                </MatchingJobCard>
            ))
            : <div className="no-candidate-found" >
                <div>
                    <NoItemsFoundIcon className='icon' />
                </div>
                <p>
                    No matches found!
                </p>
            </div>
    )
};

const mapStateToProps = (state, ownProps) => ({
    matchingJobs: getMatchingJobOpportunities(state, ownProps.filters),
});

export default connect(mapStateToProps)(MatchingJobsRedux);
