import React from 'react';

import ConfirmDialog from '../ConfirmDialog';
import { CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS } from '../ConfirmDialog/ConfirmDialogConstants';

import './ConfirmMoveToAgencyDialog.scss';

const ConfirmMoveToAgencyDialog = ({
    openDialog,
    user,
    handleMoveToAgency,
    handleCloseDialog
}) => (
    <ConfirmDialog
        openDialog={openDialog}
        dialogTitle={CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.GET_DIALOG_TITLE(user)}
        confirmButtonName={CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.CONFIRM_BUTTON_NAME}
        discardButtonName={CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS.DISCARD_BUTTON_NAME}
        handleConfirmDialog={handleMoveToAgency}
        handleCloseDialog={handleCloseDialog}
    />
);

export default ConfirmMoveToAgencyDialog;
