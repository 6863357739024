import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { getClientProjectAllTeamMembers, getClientProjectContractMembers, getClientProjectFullTimeMembers } from "reducers";

import TeamTable from "./TeamTable";

import { parseQueryString } from "utils/helpers";

import { CURRENCIES } from "constants/currenciesConstant";
import { DIALOG_TYPES } from "constants/clientProjectConstants";
import {
	CONTRACT_PAGE_SELECTOR,
	CONTRACT_QUERY_SELECTOR,
	FULL_TIME_PAGE_SELECTOR,
	FULL_TIME_QUERY_SELECTOR,
	TEAM_MEMBERS_PAGE_SELECTOR,
	TEAM_MEMBERS_QUERY_SELECTOR,
	contractColumns,
	defaultContractColumns,
	defaultFullTimeColumns,
	defaultTeamMembersColumns,
	fullTimeColumns,
	teamMembersColumns
} from "../ProjectDetailsConstants";
import { DEFAULT_ITEMS_PER_PAGE } from "components/Shared/TableWrapper/TableWrapperConstants";

const TeamMembers = ({
	agencyId,
	clientId,
	projectId,
	project,
	clientInvoicing,
	isClientProjectsModuleModified,
}) => {
	const history = useHistory();

	const projectCurrency = CURRENCIES.find(currency => currency.VALUE === clientInvoicing.currency);

	const allEmployeesDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = teamMembersColumns.find(x => x.value === query[TEAM_MEMBERS_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${TEAM_MEMBERS_QUERY_SELECTOR}Dir`];
		const page = query[TEAM_MEMBERS_PAGE_SELECTOR] ? +query[TEAM_MEMBERS_PAGE_SELECTOR] : 1;

		return getClientProjectAllTeamMembers(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const allEmployeesDataCount = (state) => getClientProjectAllTeamMembers(state).count;

	const contractEmployeesDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = contractColumns.find(x => x.value === query[CONTRACT_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${CONTRACT_QUERY_SELECTOR}Dir`];
		const page = query[CONTRACT_PAGE_SELECTOR] ? +query[CONTRACT_PAGE_SELECTOR] : 1;

		return getClientProjectContractMembers(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const contractEmployeesDataCount = (state) => getClientProjectContractMembers(state).count;

	const fullTimeEmployeesDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = fullTimeColumns.find(x => x.value === query[FULL_TIME_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${FULL_TIME_QUERY_SELECTOR}Dir`];
		const page = query[FULL_TIME_PAGE_SELECTOR] ? +query[FULL_TIME_PAGE_SELECTOR] : 1;

		return getClientProjectFullTimeMembers(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const fullTimeEmployeesDataCount = (state) => getClientProjectFullTimeMembers(state).count;

	return (
		<Fragment>
			{[...project.employees, ...project.dedicatedEmployees].length > 0 && isClientProjectsModuleModified &&
				<TeamTable
					agencyId={agencyId}
					projectId={projectId}
					clientId={clientId}
					tableTitle="Team Members"
					tableColumns={teamMembersColumns}
					tableDefaultColumns={defaultTeamMembersColumns}
					tableSortQuerySelector={TEAM_MEMBERS_QUERY_SELECTOR}
					tablePageQuerySelector={TEAM_MEMBERS_PAGE_SELECTOR}
					adjustKeysPrefix={"team"}
					tableDataSelector={allEmployeesDataSelector}
					tableDataCountSelector={allEmployeesDataCount}
					dialogType={DIALOG_TYPES.BILLING_TYPE}
					projectName={project.name}
					projectCurrency={projectCurrency}
					isClientProjectModuleModified={true}
				/>
			}

			{project.employees.length > 0 && !isClientProjectsModuleModified &&
				<TeamTable
					agencyId={agencyId}
					projectId={projectId}
					clientId={clientId}
					tableTitle="Current & recent Contract Team Members"
					tableColumns={contractColumns}
					tableDefaultColumns={defaultContractColumns}
					tableSortQuerySelector={CONTRACT_QUERY_SELECTOR}
					tablePageQuerySelector={CONTRACT_PAGE_SELECTOR}
					adjustKeysPrefix={"contract"}
					tableDataSelector={contractEmployeesDataSelector}
					tableDataCountSelector={contractEmployeesDataCount}
					dialogType={DIALOG_TYPES.CONTRACT}
					projectName={project.name}
					projectCurrency={projectCurrency}
				/>
			}

			{project.dedicatedEmployees.length > 0 && !isClientProjectsModuleModified &&
				<TeamTable
					agencyId={agencyId}
					projectId={projectId}
					clientId={clientId}
					tableTitle="Current & recent Full-time Team Members"
					tableColumns={fullTimeColumns}
					tableDefaultColumns={defaultFullTimeColumns}
					tableSortQuerySelector={FULL_TIME_QUERY_SELECTOR}
					tablePageQuerySelector={FULL_TIME_PAGE_SELECTOR}
					adjustKeysPrefix={"fullTime"}
					tableDataSelector={fullTimeEmployeesDataSelector}
					tableDataCountSelector={fullTimeEmployeesDataCount}
					dialogType={DIALOG_TYPES.FULL_TIME}
					projectName={project.name}
					projectCurrency={projectCurrency}
					isFullTime={true}
				/>
			}
		</Fragment>
	);
};

export default TeamMembers;
