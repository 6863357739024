import React, { useState } from 'react';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PerformanceReviewCard from './PerformanceReviewCard';

import './PerformanceReviewsHistory.scss';

const performancePeriods = [
    { value: 1, name: 'Current month' },
    { value: 2, name: 'Annual report' },
    { value: 3, name: 'Quater 1' },
    { value: 4, name: 'Quater 2' },
    { value: 5, name: 'Quater 3' },
    { value: 6, name: 'Quater 4' },
];

const PerformanceReviewsHistory = ({
    performanceReviews,
}) => {
    const [period, setPeriod] = useState(performancePeriods[0].value);

    const handlePeriodChange = (event) => setPeriod(event.target.value);

    const handleOpenDialog = () => { window.history.back() };

    return (
        <div className="performance-review-wrapper performance-review-history">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Review history'
                    className='section-title'
                />

                <SectionActionsWrapper.LeftAlignedActions>
                    <SectionActionsWrapper.LeftAlignedActions.SelectOneFilter
                        value={period}
                        onChangeHandler={handlePeriodChange}
                        items={performancePeriods}
                    />
                </SectionActionsWrapper.LeftAlignedActions>

                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryButton
                        text='Back To Project'
                        onClick={handleOpenDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>

            </SectionActionsWrapper>

            {(performanceReviews) &&
                <div className="review-cards-wrapper">
                    {performanceReviews.map(card =>
                        <PerformanceReviewCard key={card._id} cardInfo={card} />
                    ).reverse()}
                </div>
            }
        </div>
    )
};

export default PerformanceReviewsHistory;
