import { authorizedShowNotification } from './actionHelpers';

import businessTripService from 'services/businessTripService';

import { showNotification } from './notificationActions';

import { BUSINESS_TRIPS_FETCH_SUCCESS, BUSINESS_TRIPS_REPLACE_SUCCESS } from './actionTypes';

import { BUSINESS_TRIP_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

const fetchBusinessTripsSuccess = data => ({
    type: BUSINESS_TRIPS_FETCH_SUCCESS,
    payload: data,
});

const replaceBusinessTripsSuccess = data => ({
    type: BUSINESS_TRIPS_REPLACE_SUCCESS,
    payload: data,
});

export const createBusinessTrip = (agencyId, businessTripFormData) => async dispatch => {
    try {
        await businessTripService.createBusinessTrip(agencyId, businessTripFormData);

        dispatch(showNotification(BUSINESS_TRIP_MESSAGES.CREATE_BUSINESS_TRIP_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(BUSINESS_TRIP_MESSAGES.CREATE_BUSINESS_TRIP_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchBusinessTrips = (agencyId, query, resetState) => async dispatch => {
    try {
        const result = await businessTripService.fetchBusinessTrips(agencyId, query);

        if (resetState) {
            dispatch(replaceBusinessTripsSuccess(result.businessTrips));
        } else {
            dispatch(fetchBusinessTripsSuccess(result.businessTrips));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(BUSINESS_TRIP_MESSAGES.FETCH_BUSINESS_TRIPS_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const downloadBusinessTripFiles = (agencyId, businessTripId, fileName) => async dispatch => {
    try {
        await businessTripService.downloadBusinessTripFiles(agencyId, businessTripId, fileName);

        dispatch(showNotification(BUSINESS_TRIP_MESSAGES.DOWNLOAD_FILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(BUSINESS_TRIP_MESSAGES.DOWNLOAD_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportBusinessTrips = (agencyId, businessTripsData, fileName) => async dispatch => {
    try {
        await businessTripService.exportBusinessTrips(agencyId, businessTripsData, fileName);

        dispatch(showNotification(BUSINESS_TRIP_MESSAGES.EXPORT_BUSINESS_TRIPS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(BUSINESS_TRIP_MESSAGES.EXPORT_BUSINESS_TRIPS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
