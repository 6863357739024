import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AgencySettingsBasicForm from './AgencySettingsBasicForm';
import AgencySettingsEvrotrustForm from './AgencySettingsEvrotrustForm';
import AgencySettingsCalendlyForm from './AgencySettingsCalendlyForm';
import AgencySettingsTeamNotifications from './AgencySettingsTeamNotifications';

import {
    updateAgencyBasicInfo,
    fetchAgencyBasicInfo,
    uploadAgencyLogo,
    deleteAgencyLogo,
    updateAgencyEvrotrustInformation,
    updateAgencyCalendlyInformation,
    updateAgencyIsUsingEvrotrust,
    fetchAgencyEvrotrustInfo,
    fetchAgencyCalendlyInfo,
    fetchAgencySettings,
    updateAgencySettings,
} from 'actions/agencyActions';
import { fetchAllCountries } from 'actions/staticDataActions';
import { getAgencyBasicInfo, getAgencyId, getAgencySettings, getCountries } from 'reducers';

import './AgencySettings.scss';

const AgencySettings = ({
    agencyId,
    agencyInfo,
    updateAgencyBasicInfo,
    fetchAgencyBasicInfo,
    uploadAgencyLogo,
    deleteAgencyLogo,
    fetchAllCountries,
    countries,
    updateAgencyEvrotrustInformation,
    updateAgencyCalendlyInformation,
    updateAgencyIsUsingEvrotrust,
    fetchAgencyEvrotrustInfo,
    fetchAgencyCalendlyInfo,
    fetchAgencySettings,
    updateAgencySettings,
    agencySettings,
}) => {
    const [isUsingEvrotrustState, setIsUsingEvrotrustState] = useState(agencyInfo?.isUsingEvrotrust);

    useEffect(() => {
        fetchAgencyBasicInfo(agencyId).then(() => fetchAgencyCalendlyInfo(agencyId));
        fetchAgencyEvrotrustInfo(agencyId);
        fetchAllCountries();
        fetchAgencySettings(agencyId);
    }, [agencyId, updateAgencyEvrotrustInformation, updateAgencyIsUsingEvrotrust]);

    const handleSaveSettings = async (updatedInfo, updateAction, resetForm) => {
        await updateAction(agencyId, updatedInfo);
        resetForm();
    }

    const updateEvrotrustAction = async (data, resetForm) => {
        data.isUsingEvrotrust
            ? await updateAgencyEvrotrustInformation(agencyId, data)
            : await updateAgencyIsUsingEvrotrust(agencyId, data.isUsingEvrotrust);
        resetForm();
    };

    const updateCalalendlyAction = async (data, resetForm) => {
        await updateAgencyCalendlyInformation(agencyId, data);
        resetForm();
    };

    return (
        <div>
            <AgencySettingsBasicForm
                updateAgencyBasicInfo={updateAgencyBasicInfo}
                agencyInfo={agencyInfo}
                agencyId={agencyId}
                saveSettings={handleSaveSettings}
                uploadAgencyLogo={uploadAgencyLogo}
                deleteAgencyLogo={deleteAgencyLogo}
                countries={countries}
            />

            <AgencySettingsEvrotrustForm
                agencyInfo={agencyInfo}
                agencySettings={agencySettings}
                updateAction={updateEvrotrustAction}
                setIsUsingEvrotrust={setIsUsingEvrotrustState}
            />

            <AgencySettingsTeamNotifications
                agencyId={agencyId}
                agencySettings={agencySettings}
                updateAction={updateAgencySettings}
                isUsingEvrotrust={isUsingEvrotrustState}
            />

            <AgencySettingsCalendlyForm
                updateAction={updateCalalendlyAction}
                agencyInfo={agencyInfo}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    agencyInfo: getAgencyBasicInfo(state),
    agencyId: getAgencyId(state),
    countries: getCountries(state),
    agencySettings: getAgencySettings(state),
});

const mapDispatchToProps = {
    fetchAgencyBasicInfo,
    updateAgencyBasicInfo,
    uploadAgencyLogo,
    deleteAgencyLogo,
    fetchAllCountries,
    updateAgencyEvrotrustInformation,
    updateAgencyCalendlyInformation,
    updateAgencyIsUsingEvrotrust,
    fetchAgencyEvrotrustInfo,
    fetchAgencyCalendlyInfo,
    fetchAgencySettings,
    updateAgencySettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencySettings);
