import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
    getClientProjects,
    getEmployees,
    getClientStatus,
    getAgencyBasicInfo,
    getClientTotalRevenue,
    getClientOverviewFetchState,
} from 'reducers';

import { fetchHistoricalNotes } from 'actions/historicalNotesActions';
import { fetchClientProjectsWithEmployees, fetchClientProjectsTotalRevenue } from 'actions/clientActions';
import { fetchInternalEmployees } from 'actions/employeeActions';

import { CURRENCIES } from 'constants/currenciesConstant';
import { ROLES } from 'constants/userConstants';

import ClientOverview from './ClientOverview';
import Projects from './Projects';
import InvoicingAndAccountManagementForm from 'components/CompanyInformation/InvoicingAndAccountManagementForm';
import HistoricalNotes from 'components/Shared/HistoricalNotes';
import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';

import CircularProgress from '@material-ui/core/CircularProgress';

const LIMIT = 2;

const Overview = ({
    invoicingInformation,
    agencyId,
    clientId,
    clientProjects,
    location,
    history,
    fetchClientProjectsWithEmployees,
    fetchClientProjectsTotalRevenue,
    fetchHistoricalNotes,
    updateAccountManagementInformation,
    updateInvoiceInformation,
    accountManager,
    fetchInternalEmployees,
    accountManagers,
    clientStatus,
    adminInfo,
    totalRevenue,
    dataFetchState,
}) => {

    useEffect(() => {
        fetchClientProjectsWithEmployees(agencyId, clientId);
        fetchClientProjectsTotalRevenue(agencyId, clientId);
        fetchInternalEmployees(agencyId, ['firstName', 'lastName'], [ROLES.ADMIN]);
        fetchHistoricalNotes(agencyId, clientId, HISTORICAL_NOTES_TYPES.CLIENT, LIMIT)
    }, [clientId])

    const clientInvoicingCurrency = invoicingInformation.currency ? invoicingInformation.currency : adminInfo.currency;

    const { LABEL: clientCurrency } = CURRENCIES.find(x => x.VALUE === clientInvoicingCurrency) || '';
    let monthlySpending = 0;

    const projectsCount = {
        active: 0,
        complete: 0,
        total: clientProjects.length
    };

    clientProjects.forEach(project => {
        // Count projects
        project.ongoing || (project.endDate && moment.utc(project.endDate) > moment.utc()) ? projectsCount.active++ : projectsCount.complete++;
        // Calculate monthly spending
        monthlySpending += project.currentMonthMonthlySpending || 0;
        return monthlySpending;
    });

    return (
        <div>
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div>
                    <ClientOverview
                        clientCurrency={clientCurrency}
                        projectsCount={projectsCount}
                        monthlySpending={monthlySpending}
                        totalRevenue={totalRevenue}
                    />

                    <HistoricalNotes
                        isDataAvailable={true}
                        noteType={HISTORICAL_NOTES_TYPES.CLIENT}
                        targetId={clientId}
                    />

                    <InvoicingAndAccountManagementForm
                        agencyId={agencyId}
                        companyId={clientId}
                        currency={invoicingInformation.currency}
                        status={clientStatus}
                        accountManager={accountManager}
                        accountManagers={accountManagers}
                        updateAccountManagementInformation={updateAccountManagementInformation}
                        updateInvoiceInformation={updateInvoiceInformation}
                    />

                    <Projects clientProjects={clientProjects} location={location} history={history} />
                </div>
            }
        </div >
    )
}

const mapStateToProps = (state, ownProps) => ({
    clientProjects: getClientProjects(state, ownProps.clientId),
    accountManagers: getEmployees(state),
    clientStatus: getClientStatus(state),
    adminInfo: getAgencyBasicInfo(state),
    totalRevenue: getClientTotalRevenue(state),
    dataFetchState: getClientOverviewFetchState(state),
});

const mapDispatchToProps = {
    fetchClientProjectsWithEmployees,
    fetchInternalEmployees,
    fetchClientProjectsTotalRevenue,
    fetchHistoricalNotes
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
