import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import { getClientProjectAllTeamMembers, getClientProjectContractMembers, getClientProjectFullTimeMembers, isAdminMode, isClientMode } from 'reducers';
import { exportAgencyTimesheets } from 'actions/agencyActions';
import { deleteDedicatedEmployeeFromProject, deleteProjectEmployee } from 'actions/clientActions';

import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';
import HistoryIcon from '@material-ui/icons/History';
import { Typography } from '@material-ui/core';

import TableWrapper from 'components/Shared/TableWrapper';

import ExportOptionsPopover from 'components/Shared/ExportOptionsPopover';
import ExportTimesheetDialog from '../ExportTimesheetDialog/ExportTimesheetDialog';
import DeleteEmployeeDialog from '../../CardDetails/DeleteEmployeeDialog/DeleteEmployeeDialog';
import AddEmployeeToProjectCardDialog from '../../CardDetails/AddEmployeeProjectsCardDialog';
import EmployeeHistoryDialog from '../EmployeeHistoryDialog/EmployeeHistoryDialog';

import { getDedicatedEmployeeValue } from "components/Client/Projects/ProjectsUtils";
import { getEmployeeHistoryDialogTitle, getEmployeeHistoryDialogSubTitle, getEmployeeHistoryDialogField, RATE_DIALOG_OPTIONS } from './TeamTableUtils';

import { DATE_FORMAT_WITH_DASH, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from "constants/commonConstants";
import { CURRENCIES_SIGN, CURRENCY_NAMES } from "constants/currenciesConstant";
import { BILLING_TYPES } from 'constants/clientProjectConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import defaultAvatar from "assets/dummy-avatar.jpg";

import { ReactComponent as WarningIcon } from 'assets/icon-warning.svg';
import { ReactComponent as ExportBtnIcon } from 'assets/export-button-icon.svg';

import './TeamTable.scss';

const TeamTable = ({
    agencyId,
    projectId,
    clientId,
    tableTitle,
    tableColumns,
    tableDefaultColumns,
    tableSortQuerySelector,
    tablePageQuerySelector,
    adjustKeysPrefix,
    tableDataSelector,
    tableDataCountSelector,
    dialogType,
    projectName,
    projectCurrency,
    isFullTime = false,
    isClientProjectModuleModified = false,
    isClientMode,
    isAdminMode,
    allEmployees,
    contractEmployees,
    fullTimeEmployees,
    exportAgencyTimesheets,
    deleteProjectEmployee,
    deleteDedicatedEmployeeFromProject,
}) => {
    const totalCount = useSelector(tableDataCountSelector);

    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [isExportTimeSheetDialogOpen, setIsExportTimeSheetDialogOpen] = useState(false);
    const [isDeleteEmployeeDialogOpen, setIsDeleteEmployeeDialogOpen] = useState(false);
    const [isEditEmployeeDialogOpen, setIsEditEmployeeDialogOpen] = useState(false);
    const [isRateHistoryDialogOpen, setIsRateHistoryDialogOpen] = useState(false);
    const [rateDialogType, setRateDialogType] = useState(null);

    const [exportOptionsPopoverAnchor, setExportOptionsPopoverAnchor] = useState(null);
    const [isExportOptionsPopoverOpen, setIsExportOptionsPopoverOpen] = useState(false);
    const [exportSelectedOnly, setExportSelectedOnly] = useState(false);
    const [checkedRows, setCheckedRows] = useState([]);

    const handleCloseExportOptionsPopover = () => {
        setIsExportOptionsPopoverOpen(false);
        setExportOptionsPopoverAnchor(null);
    };

    const handelOpenExportOptionsPopover = (event) => {
        setIsExportOptionsPopoverOpen(true);
        setExportOptionsPopoverAnchor(event.target);
        setSelectedEmployee({});
    };

    const handleOpenExportTimeSheetDialog = () => setIsExportTimeSheetDialogOpen(true);
    const handleCloseExportTimeSheetDialog = () => {
        setIsExportTimeSheetDialogOpen(false);
        setSelectedEmployee({});
    };
    const handleOpenDeleteEmployeeDialog = () => setIsDeleteEmployeeDialogOpen(true);
    const handleCloseDeleteEmployeeDialog = () => {
        setIsDeleteEmployeeDialogOpen(false);
        setSelectedEmployee({});
    };
    const handleOpenEditEmployeeDialog = () => setIsEditEmployeeDialogOpen(true);
    const handleCloseEditEmployeeDialog = () => {
        setIsEditEmployeeDialogOpen(false);
        setSelectedEmployee({});
    };
    const handleOpenRateHistoryDialog = (employee, rateType) => {
        setSelectedEmployee(employee);
        setRateDialogType(rateType);
        setIsRateHistoryDialogOpen(true);
    };
    const handleCloseRateHistoryDialog = () => {
        setIsRateHistoryDialogOpen(false);
        setSelectedEmployee({});
    };

    const getSelectedEmployeeForEdit = () => ({
        employee: { _id: selectedEmployee._id, employeeId: selectedEmployee.employeeId },
        firstName: selectedEmployee.firstName,
        lastName: selectedEmployee.lastName,
        currency: selectedEmployee.currency,
        billingType: selectedEmployee.billingType || '',
        dateAdded: selectedEmployee.dateAdded,
        hourRate: selectedEmployee.hourRate,
        employerTotalCost: selectedEmployee.employerTotalCost,
        monthlyServiceFee: selectedEmployee.monthlyServiceFee,
        isEdit: true
    });

    const employeeCurrencySign = currency => CURRENCIES_SIGN.find(sign => sign.VALUE === currency).LABEL;

    const handleExportAllDocuments = () => {
        handleOpenExportTimeSheetDialog();
        handleCloseExportOptionsPopover();
        setExportSelectedOnly(false);
    };

    const handleExportSelectedDocuments = () => {
        handleOpenExportTimeSheetDialog();
        handleCloseExportOptionsPopover();
        setExportSelectedOnly(true);
    };

    const onExportTimeSheetHandler = async (months) => {
        let employees = [];
        let trackedProjects = [];
        const projectNames = [{ _id: projectId, name: projectName }];

        if (Object.keys(selectedEmployee).length > 0) {
            employees.push({ _id: selectedEmployee.employeeId, name: selectedEmployee.name });
            trackedProjects.push({ _id: selectedEmployee.employeeId, name: selectedEmployee.name, projectNames });
        } else {
            let selectedEmployees = isClientProjectModuleModified ? allEmployees : isFullTime ? fullTimeEmployees : contractEmployees;

            if (exportSelectedOnly && checkedRows.length > 0) {
                const checkedEmployeesIds = checkedRows.map(x => x.employeeId);
                selectedEmployees = selectedEmployees.filter(x => checkedEmployeesIds.includes(x.employeeId));
            }

            employees = selectedEmployees.map(employee => ({ _id: employee.employeeId, name: `${employee.firstName} ${employee.lastName}` }));
            trackedProjects = selectedEmployees.map(employee => ({ _id: employee.employeeId, projectNames }));
        }

        await exportAgencyTimesheets(employees, months, agencyId, { trackedProjects });
        handleCloseExportTimeSheetDialog();
    };

    const onEmployeeDeleteHandler = (data) => {
        const { employeeId, dateDeleted } = data;
        const formattedDate = moment(dateDeleted).format(DATE_FORMAT_WITH_DASH);

        switch (selectedEmployee.billingType) {
            case BILLING_TYPES.MONTHLY:
                deleteDedicatedEmployeeFromProject(agencyId, clientId, projectId, employeeId, formattedDate);
                break;
            case BILLING_TYPES.PER_HOUR:
                deleteProjectEmployee(agencyId, clientId, projectId, employeeId, formattedDate);
                break;
            default:
                break;
        }
        handleCloseDeleteEmployeeDialog();
    };

    const getPopoverOptions = (item) => {
        setSelectedEmployee(item);
        if (item.isDeleted || Object.keys(item).length === 0 || isClientMode) {
            return deletedEmployeePopoverOptions;
        } else if (Object.keys(item).length > 0) {
            return popoverOptions;
        }
    }

    const deletedEmployeePopoverOptions = [
        { title: 'Download Timesheet', handleClick: handleOpenExportTimeSheetDialog },
    ];

    const popoverOptions = [
        { title: 'Download Timesheet', handleClick: handleOpenExportTimeSheetDialog },
        { title: 'Edit Member', handleClick: handleOpenEditEmployeeDialog },
        { title: 'Delete Member', handleClick: handleOpenDeleteEmployeeDialog },
    ];

    const adjustKeysFunction = employees => employees.map(employee => {
        const currency = isClientMode ? projectCurrency.LABEL : employee.currency;
        const serviceFee = getDedicatedEmployeeValue(employee.monthlyServiceFee);
        const employerTotalCost = getDedicatedEmployeeValue(employee.employerTotalCost);
        const currentMonth = moment().format('MMMM');
        const lastMonth = moment().subtract(1, "month").format('MMMM');
        const userNoLongerActive = moment(employee.dateDeleted).isBefore(moment());
        const isUserCurrentlyInTheProject = moment(employee.dateAdded).isSameOrBefore(moment());

        return {
            _id: employee._id,
            billingType: employee.billingType,
            employeeId: employee.employeeId,
            [`${adjustKeysPrefix}Name`]:
                <div className="logo-name-wrapper">
                    {employee.dateDeleted ?
                        <Tooltip title={`Engagement ended ${moment(employee.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}`} classes={{ tooltip: 'custom-tooltip' }}>
                            <Avatar
                                alt="Emplyoee Avatar"
                                className={`avatar-logo-image ${userNoLongerActive ? 'deleted' : ''}`}
                                src={employee.profilePictureImgUrl}
                                onError={(e) => (e.target.src = defaultAvatar)}
                            />
                        </Tooltip>
                        :
                        <Avatar
                            alt="Emplyoee Avatar"
                            className={`avatar-logo-image ${userNoLongerActive ? 'deleted' : ''}`}
                            src={employee.profilePictureImgUrl}
                            onError={(e) => (e.target.src = defaultAvatar)}
                        />
                    }
                    <div className="name-text">
                        {`${employee.firstName} ${employee.lastName}`}
                    </div>
                </div>,
            firstName: employee.firstName,
            lastName: employee.lastName,
            position: '',
            [`${adjustKeysPrefix}TotalHours`]: isUserCurrentlyInTheProject ? employee.billingType === 'Per Hour' ?
                isAdminMode || isClientMode
                    ? <div>
                        <Typography variant="body1">{`${currentMonth} - ${employee.trackedHours} (${employee.trackedHoursTotal - employee.trackedHours}h on other projects)`}</Typography>
                        <Typography variant="body1">{`${lastMonth} - ${employee.trackedHoursPrevMonth} (${employee.trackedHoursPrevMonthTotal - employee.trackedHoursPrevMonth}h on other projects)`}</Typography>
                        {employee.missingTrackedHours > 0 &&
                            <Tooltip
                                title={`This employee has missing hours on their timesheet (less than 8 hours for each working day)`}
                                classes={{ tooltip: 'custom-tooltip' }}
                            >
                                <WarningIcon className="warning-icon" />
                            </Tooltip>
                        }
                    </div>
                    : `${employee.trackedHoursPrevMonth}`
                : employee.trackedHours || 0
                : 'N/A',
            [`${adjustKeysPrefix}Rate`]: employee.billingType === 'Per Hour' ? (
                <span className="employee-history-action">
                    {currency === CURRENCY_NAMES.BGN ? '' : employeeCurrencySign(currency)} {Number(employee.hourRate).toFixed(2)} {currency === CURRENCY_NAMES.BGN ? currency : ''}
                    {employee.employeeHistory && <HistoryIcon className="history-icon" onClick={() => handleOpenRateHistoryDialog(employee, RATE_DIALOG_OPTIONS.RATE)} />}
                </span>
            ) : '-',
            [`${adjustKeysPrefix}Tce`]: employee.billingType === 'Monthly' ? (
                <span className="employee-history-action">
                    {currency === CURRENCY_NAMES.BGN ? '' : employeeCurrencySign(currency)} {employerTotalCost} {currency === CURRENCY_NAMES.BGN ? currency : ''}
                    {(employee.ratesHistory?.totalCostHistory || employee.ratesHistory?.currencyHistory) && <HistoryIcon className="history-icon" onClick={() => handleOpenRateHistoryDialog(employee, RATE_DIALOG_OPTIONS.TCE)} />}
                </span>
            ) : '-',
            [`${adjustKeysPrefix}ServiceFee`]: employee.billingType === 'Monthly' ? (
                <span className="employee-history-action">
                    {currency === CURRENCY_NAMES.BGN ? '' : employeeCurrencySign(currency)} {serviceFee} {currency === CURRENCY_NAMES.BGN ? currency : ''}
                    {(employee.ratesHistory?.serviceFeeHistory || employee.ratesHistory?.currencyHistory) && <HistoryIcon className="history-icon" onClick={() => handleOpenRateHistoryDialog(employee, RATE_DIALOG_OPTIONS.SERVICE_FEE)} />}
                </span>
            ) : '-',
            rateValue: employee.billingType === 'Per Hour' ? Number(employee.hourRate).toFixed(2) : '-',
            tceValue: employee.billingType === 'Monthly' ? employerTotalCost : '-',
            serviceFeeValue: employee.billingType === 'Monthly' ? serviceFee : '-',
            [`${adjustKeysPrefix}Gained`]: `${currency !== CURRENCY_NAMES.BGN ? `${employeeCurrencySign(currency)} ${(employee.hourRate * employee.trackedHours).toFixed(2)}` : `${(employee.hourRate * employee.trackedHours).toFixed(2)} ${currency}`}`,
            [`${adjustKeysPrefix}Spent`]: `${currency !== CURRENCY_NAMES.BGN ? `${employeeCurrencySign(currency)} ${(employee.hourRate * employee.trackedHours).toFixed(2)}` : `${(employee.hourRate * employee.trackedHours).toFixed(2)} ${currency}`}`,
            profilePictureImgUrl: employee.profilePictureImgUrl,
            [`${adjustKeysPrefix}DateAdded`]: moment.utc(employee.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            isDeleted: employee.isDeleted,
            [`${adjustKeysPrefix}DateDeleted`]: employee.dateDeleted
                ? moment.utc(employee.dateDeleted).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
                : '-',
            item: employee,
        };
    });

    return (
        <div className="team-table-wrapper">
            <TableWrapper
                title={tableTitle}
                columns={tableColumns}
                defaultColumns={tableDefaultColumns}
                sortQuerySelector={tableSortQuerySelector}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={false}
                dataSelector={tableDataSelector}
                pageQuerySelector={tablePageQuerySelector}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                addMargin
                withPagination
                withActions
                withCheckboxes
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                getActionsHandler={getPopoverOptions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={"Export"} icon={<ExportBtnIcon />} handleClick={handelOpenExportOptionsPopover} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />

                {isEditEmployeeDialogOpen &&
                    <AddEmployeeToProjectCardDialog
                        isOpen={isEditEmployeeDialogOpen}
                        handleCloseDialog={handleCloseEditEmployeeDialog}
                        type={dialogType}
                        agencyId={agencyId}
                        projectId={projectId}
                        clientId={clientId}
                        project={getSelectedEmployeeForEdit()}
                        setProject={setSelectedEmployee}
                        projectCurrency={projectCurrency}
                    />
                }

                {isRateHistoryDialogOpen &&
                    <EmployeeHistoryDialog
                        openDialog={isRateHistoryDialogOpen}
                        agencyId={agencyId}
                        clientId={clientId}
                        projectId={projectId}
                        handleCloseDialog={handleCloseRateHistoryDialog}
                        currentEmployee={selectedEmployee}
                        isDedicated={isFullTime}
                        title={getEmployeeHistoryDialogTitle(rateDialogType)}
                        subtitle={getEmployeeHistoryDialogSubTitle(rateDialogType)}
                        field={getEmployeeHistoryDialogField(rateDialogType)}
                    />
                }

                {isExportTimeSheetDialogOpen &&
                    <ExportTimesheetDialog
                        handleExportTimesheet={onExportTimeSheetHandler}
                        isDialogOpen={isExportTimeSheetDialogOpen}
                        handleCloseDialog={handleCloseExportTimeSheetDialog}
                    />
                }

                {isDeleteEmployeeDialogOpen &&
                    <DeleteEmployeeDialog
                        isOpen={isDeleteEmployeeDialogOpen}
                        handleCloseDialog={handleCloseDeleteEmployeeDialog}
                        handleDelete={onEmployeeDeleteHandler}
                        employee={selectedEmployee}
                    />
                }

                {isExportOptionsPopoverOpen &&
                    <ExportOptionsPopover
                        anchorEl={exportOptionsPopoverAnchor}
                        handleClose={handleCloseExportOptionsPopover}
                        handleExportDocumentsFiles={handleExportAllDocuments}
                        handleExportSelectedDocumentsFiles={handleExportSelectedDocuments}
                    />
                }
            </TableWrapper>
        </div>
    )
};

const mapStateToProps = (state) => ({
    isAdminMode: isAdminMode(state),
    isClientMode: isClientMode(state),
    allEmployees: getClientProjectAllTeamMembers(state).result,
    fullTimeEmployees: getClientProjectFullTimeMembers(state).result,
    contractEmployees: getClientProjectContractMembers(state).result,
});

const mapDispatchToProps = {
    exportAgencyTimesheets,
    deleteProjectEmployee,
    deleteDedicatedEmployeeFromProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamTable);
