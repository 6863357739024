import React, { useState } from 'react';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';

import ResultsCardView from '../ResultsCardView';
import ResultsCardViewProject from '../ResultsCardViewProject';
import ResultsCardViewCandidate from '../ResultsCardViewCandidate';

import { ROLES } from 'constants/userConstants';

const SearchResultsAdminView = ({
    results,
    restoreUsers,
    agencyName,
    handleResultClick,
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [currentUser, setCurrentUser] = useState({ id: '', role: '' });

    const openConfirmRestoreDialog = (userId) => {
        setOpenDialog(true);
        setCurrentUser(id => userId);
    }

    const closeConfirmRestoreDialog = () => {
        setOpenDialog(false);
        setCurrentUser('');
    }

    const restoreUser = () => {
        restoreUsers([currentUser.id], currentUser.role);
        closeConfirmRestoreDialog();
    };

    const people = results.filter(x => x.role === ROLES.EMPLOYEE || x.role === ROLES.ADMIN);
    const clients = results.filter(x => x.role === ROLES.CLIENT);
    const supplierAdmins = results.filter(x => x.role === ROLES.SUPPLIER_ADMIN);
    const supplierEmployees = results.filter(x => x.role === ROLES.SUPPLIER_EMPLOYEE);
    const candidates = results.filter(x => x.role === ROLES.CANDIDATE);
    const projects = results.filter(x => x.isProject);

    return (
        <div className='search-results'>
            <ConfirmRestoreUserDialog
                openDialog={openDialog}
                handleCloseDialog={closeConfirmRestoreDialog}
                handleConfirmDialog={restoreUser}
                isUserEmployeeOrAdmin={[ROLES.ADMIN, ROLES.EMPLOYEE].includes(currentUser?.role)}
            />

            {
                people.length ? <>
                    <Typography variant="body1" className="title result-category">People</Typography>
                    <List >
                        {people.map((x) => (
                            <ResultsCardView
                                openConfirmRestoreDialog={openConfirmRestoreDialog}
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }

            {
                clients.length ? <>
                    <Typography variant="body1" className="title result-category">Clients</Typography>
                    <List >
                        {clients.map((x) => (
                            <ResultsCardView
                                openConfirmRestoreDialog={openConfirmRestoreDialog}
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }

            {
                supplierAdmins.length ? <>
                    <Typography variant="body1" className="title result-category">Partners</Typography>
                    <List >
                        {supplierAdmins.map((x) => (
                            <ResultsCardView
                                openConfirmRestoreDialog={openConfirmRestoreDialog}
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }

            {
                supplierEmployees.length ? <>
                    <Typography variant="body1" className="title result-category">Partner Employees</Typography>
                    <List >
                        {supplierEmployees.map((x) => (
                            <ResultsCardView
                                openConfirmRestoreDialog={openConfirmRestoreDialog}
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                                agencyName={agencyName}
                            />))
                        }
                    </List >
                </> : null
            }

            {
                candidates.length ? <>
                    <Typography variant="body1" className="title result-category">Candidates</Typography>
                    <List >
                        {candidates.map((x) => (
                            <ResultsCardViewCandidate
                                openConfirmRestoreDialog={openConfirmRestoreDialog}
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                            />))
                        }
                    </List >
                </> : null
            }

            {
                projects.length ? <>
                    <Typography variant="body1" className="title result-category">Projects</Typography>
                    <List >
                        {projects.map((x) => (
                            <ResultsCardViewProject
                                key={x._id}
                                result={x}
                                handleClick={handleResultClick}
                            />))
                        }
                    </List >
                </> : null
            }
        </div>
    )
};

export default SearchResultsAdminView;
