import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { connect } from 'react-redux';
import { getAgencyRouteName } from 'reducers';

import { RouteWrapper } from './utils/router';
import { ViewPage } from 'utils/amplitude';
import { ModifyFavicon, ModifyAppWidth, ScrollToTop } from './utils/helpers';

import isAuthenticated from './hoc/isAuthenticated';
import isEnviroment from 'hoc/isEnvironment';
import isDepartmentManager from "./hoc/isDepartmentManager";
import isOnboarded from './hoc/isOnboarded';
import isGuest from './hoc/isGuest';
import withUserData from 'hoc/withUserData/withUserData';

import GuestLayout from 'layouts/Guest';
import ContentLayout from 'layouts/ContentLayout';
import SinglePageLayout from 'layouts/SinglePageLayout';
import CandidateJourneyLayout from 'layouts/CandidateJourneyLayout';
import CandidateLayout from 'layouts/CandidateLayout';
import ChooseUsageLayout from 'layouts/ChooseUsageLayout';
import Notification from 'components/Common/Notifications';
import SessionChangeListener from 'components/Common/SessionChangeListener';
import Holidays from 'components/Person/Holidays';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dashboard from 'components/Dashboard';
import Register from 'components/Register';
import RegisterCandidate from 'components/Register/RegisterCandidate';
import RegisterAgencyCandidate from 'components/Register/RegisterAgencyCandidate';
import RegisterClient from 'components/Register/RegisterClient';
import RegisterAgency from 'components/Register/RegisterAgency';
import Login from 'components/Login';
import StartCandidateJourney from 'components/StartCandidateJourney';
import ThankYouPage from 'components/ThankYouPage';
import ExpiredLinkPage from 'components/ExpiredLinkPage';
import ForgottenPassword from 'components/ForgottenPassword';
import PasswordReset from 'components/PasswordReset';
import PasswordSet from 'components/PasswordSet';
import Employees from 'components/Employees';
import Person from 'components/Person';
import Candidate from 'components/Candidate';
import TimeManagement from 'components/TimeManagement';
import TeamHolidays from 'components/TeamHolidays';
import ExpenseReports from 'components/ExpenseReports';
import TeamDocuments from 'components/TeamDocuments';
import EmployeeProfile from 'components/Employee/EmployeeProfile';
import EmployeePersonalInfo from 'components/Employee/EmployeePersonalInfo';
import EmployeeContracts from 'components/Employee/Contracts';
import EmploymentInformation from 'components/Person/EmploymentInformation';
import HottestTalents from 'components/HottestTalents';
import ApplicationLoader from 'components/Common/ApplicationLoader';
import YourAccountManager from 'components/YourAccountManager';
import Contracts from 'components/Contracts';
import Billing from 'components/Billing';
import Suppliers from 'components/Suppliers';
import Supplier from 'components/Supplier';
import CurrentProjects from 'components/CurrentProjects';
import EmployeePerformanceReview from 'components/EmployeePerformanceReview';
import SearchResults from 'components/SearchResults/SearchResults';
import Onboarding from 'components/Onboarding';
import Benefits from 'components/Person/Benefits';
import ProjectDetails from 'components/Client/Projects/ProjectDetails';
import Recruiters from 'components/Recruiters';
import Recruiter from 'components/Recruiter';
import Tasks from 'components/Tasks';
import RecruiterProfiles from 'components/RecruiterProfiles';
import JobOpportunities from 'components/JobOpportunities';
import JobOpportunityDetails from 'components/JobOpportunities/JobOpportunityDetails';
import MyApplications from 'components/MyApplications';
import UpcomingInterviews from 'components/UpcomingInterviews';
import Candidates from 'components/Candidates';
import CandidatesChecklist from 'components/CandidatesChecklist';
import DepartmentMembers from 'components/DepartmentMembers';
import JobMatchingConsultants from 'components/JobMatchingConsultants';
import RedirectToHome from 'components/RedirectToHome';
import RedirectToVerification from 'components/RedirectToVerification';
import MaintenancePage from 'components/MaintenancePage';
import CustomerSupport from 'components/YourAccountManager/CustomerSupport';
import Client from 'components/Client';
import Trainings from 'components/Trainings';
import Training from 'components/Trainings/Training';
import IndividualTraining from 'components/Settings/Trainings/TrainingField/IndividualTraining';
import Clients from 'components/Clients';
import Settings from 'components/Settings';
import MeetYourTeam from 'components/MeetYourTeam';
import SupplierEmployee from 'components/SupplierEmployee';
import ExploreTalents from 'components/ExploreTalents';
import CandidateProfileJourney from 'components/CandidateProfileJourney';
import CandidateProfilePage from 'components/CandidateProfilePage';
import CandidateJourneyCompletionThankYouScreen from 'components/CandidateJourneyCompletionThanYouScreen'
import Jobs from 'components/JobOpportunities/Jobs';
import JobDetails from 'components/JobOpportunities/JobDetails';
import JobsDashboard from 'components/JobsDashboard';
import CandidateDetailsPage from 'components/CandidateDetailsPage';
import JobPage from 'components/JobPage';
import CoachingMentoring from 'components/CoachingMentoring';
import TimeTrackingPage from 'components/TimeTrackingPage';
import ProfileMatchingJobs from 'components/ProfileMatchingJobs';
import ReportsPage from 'components/ReportsPage';
import MyApplicationPage from 'components/MyApplicationPage';
import AutomaticallyTrackHours from 'components/AutomaticallyTrackHours';
import ChooseUsage from 'components/ChooseUsage';
import TestSentry from 'components/TestSentry';

import { CANDIDATE_SIGN_UP_LAYOUT, ENVIRONMENTS } from 'constants/env';
import { ROLES } from 'constants/userConstants';
import { CANDIDATE_SIGN_UP_SLIDES_DATA, CLIENT_SIGN_UP_SLIDES_DATA } from 'constants/layoutsConstants';

import './App.scss';

const ExploreTalentsViewProfile = React.lazy(() => import('components/ExploreTalents/ExploreTalentsViewProfile'));

const TermsAndConditionsOnboarding = React.lazy(() => import('components/TermsAndConditionsOnboarding'));
const TermsAndConditionsClient = React.lazy(() => import('components/TermsAndConditions/TermsAndConditionsClient'));
const TermsAndConditionsSupplier = React.lazy(() => import('components/TermsAndConditions/TermsAndConditionsSupplier'));
const TermsAndConditionsEmployee = React.lazy(() => import('components/TermsAndConditions/TermsAndConditionsEmployee'));
const TermsAndConditionsCandidate = React.lazy(() => import('components/TermsAndConditions/TermsAndConditionsCandidate'));
const TermsAndConditionsSaaS = React.lazy(() => import('components/TermsAndConditions/TermsAndConditionsSaaS'));

const App = ({ agencyRouteName }) => {
    return (
        <Fragment>
            <CssBaseline />
            <Notification />
            <ScrollToTop />
            <ViewPage />
            <ModifyFavicon />
            <ModifyAppWidth />
            <SessionChangeListener />
            <Suspense fallback={<ApplicationLoader />}>
                <Switch>
                    <RouteWrapper path={`/${agencyRouteName}/dashboard`} component={isAuthenticated(withUserData(Dashboard))} layout={ContentLayout} backgroundColor={{ [ROLES.CLIENT]: 'primary-light' }} />
                    <RouteWrapper path={`/${agencyRouteName}/reports`} component={isAuthenticated(withUserData(ReportsPage), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/meet-your-team`} exact component={isAuthenticated(withUserData(MeetYourTeam))} layout={ContentLayout} />
                    <RouteWrapper path='/register' exact component={isEnviroment(isGuest(Register), [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.QA, ENVIRONMENTS.STAGING])} layout={GuestLayout} />
                    <RouteWrapper path='/register/candidate' exact component={isEnviroment(isGuest(RegisterCandidate), [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.QA, ENVIRONMENTS.STAGING])} layout={isMobile ? GuestLayout : CANDIDATE_SIGN_UP_LAYOUT} slidesData={CANDIDATE_SIGN_UP_SLIDES_DATA} />
                    <RouteWrapper path='/register/candidate/:agencyData' component={isGuest(RegisterAgencyCandidate)} layout={GuestLayout} />
                    <RouteWrapper path='/register/client' component={isEnviroment(isGuest(RegisterClient), [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.QA, ENVIRONMENTS.STAGING])} layout={isMobile ? GuestLayout : CANDIDATE_SIGN_UP_LAYOUT} slidesData={CLIENT_SIGN_UP_SLIDES_DATA} />
                    {/* The below route is commented as it is not used for now */}
                    {/* <RouteWrapper path='/register/supplier' component={isEnviroment(isGuest(RegisterSupplier), [ENVIRONMENTS.PRODUCTION, ENVIRONMENTS.QA, ENVIRONMENTS.STAGING])} layout={GuestLayout} /> */}
                    <RouteWrapper path='/register/agency' component={isEnviroment(isGuest(RegisterAgency), [ENVIRONMENTS.WHOISAVAILABLE, ENVIRONMENTS.QA, ENVIRONMENTS.STAGING, ENVIRONMENTS.DEVELOPMENT])} layout={GuestLayout} />
                    <RouteWrapper path='/select-a-role' component={isAuthenticated(ChooseUsage, [ROLES.UNASSIGNED, ROLES.UNASSIGNED_CLIENT, ROLES.UNASSIGNED_ADMIN])} layout={ChooseUsageLayout} />
                    <RouteWrapper path='/login' component={isGuest(Login)} layout={GuestLayout} />
                    <RouteWrapper path='/thank-you/:role' component={isGuest(ThankYouPage)} layout={GuestLayout} />
                    <RouteWrapper path='/expired-link/:type' component={isGuest(ExpiredLinkPage)} layout={GuestLayout} />
                    <RouteWrapper path='/forgottenpassword' component={isGuest(ForgottenPassword)} layout={GuestLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/explore-talents/:id`} exact component={isAuthenticated(isOnboarded(withUserData(ExploreTalentsViewProfile)), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={SinglePageLayout} />
                    <RouteWrapper path='/account/passwordreset' component={isGuest(PasswordReset)} layout={GuestLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/explore-talents`} exact component={isAuthenticated(isOnboarded(ExploreTalents), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/hottest-talents`} exact component={isAuthenticated(isOnboarded(HottestTalents), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path='/account/passwordset' component={isGuest(PasswordSet)} layout={GuestLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/people`} exact component={isAuthenticated(Employees, [ROLES.SUPPLIER_ADMIN, ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/people/:id`} component={isAuthenticated(withUserData(Person), [ROLES.SUPPLIER_ADMIN, ROLES.ADMIN, ROLES.RECRUITER])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/time-management`} exact component={isAuthenticated(TimeManagement, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/holidays`} component={isAuthenticated(withUserData(TeamHolidays), [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/expense-reports`} exact component={isAuthenticated(ExpenseReports, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/team/documents`} exact component={isAuthenticated(TeamDocuments, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/holidays`} component={isDepartmentManager(withUserData(TeamHolidays))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/documents`} component={isDepartmentManager(withUserData(TeamDocuments))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/settings/trainings/:trainingId`} component={isAuthenticated(IndividualTraining, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/settings`} component={isAuthenticated(Settings, [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN, ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/cv`} component={isAuthenticated(withUserData(EmployeeProfile), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/personal-info`} component={isAuthenticated(withUserData(EmployeePersonalInfo), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/employment/contracts`} component={isAuthenticated(withUserData(EmployeeContracts), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/time-tracking`} component={isAuthenticated(withUserData(TimeTrackingPage), [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/holidays`} component={isAuthenticated(withUserData(Holidays))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/benefits`} component={isAuthenticated(withUserData(Benefits))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/performance-review`} exact component={isAuthenticated(withUserData(EmployeePerformanceReview))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/salary`} component={isAuthenticated(withUserData(EmploymentInformation))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/clients`} exact component={isAuthenticated(Clients, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/clients/:id`} component={isAuthenticated(withUserData(Client, [ROLES.ADMIN]))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/your-account-manager`} exact component={isAuthenticated(isOnboarded(withUserData(YourAccountManager)))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/contracts`} component={isAuthenticated(isOnboarded(withUserData(Contracts)), [ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/billing`} component={isAuthenticated(isOnboarded(withUserData(Billing)), [ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners`} exact component={isAuthenticated(Suppliers, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners/:id/team/people/:employeeId`} component={isAuthenticated(withUserData(SupplierEmployee), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/partners/:id`} component={isAuthenticated(Supplier, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/current-projects`} component={isAuthenticated(withUserData(CurrentProjects))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/projects/:projectId/clients/:id`} component={isAuthenticated(withUserData(ProjectDetails), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:jobId/matching-consultants`} component={isAuthenticated(withUserData(JobMatchingConsultants), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/search-results`} component={isAuthenticated(SearchResults, [ROLES.ADMIN, ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions`} component={isAuthenticated(withUserData(TermsAndConditionsOnboarding, [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE]))} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/trainings`} exact component={isAuthenticated(withUserData(Trainings), [ROLES.EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/upcoming-interviews`} exact component={isAuthenticated(withUserData(UpcomingInterviews), [ROLES.EMPLOYEE, ROLES.ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/trainings/:trainingId`} component={isAuthenticated(withUserData(Training), [ROLES.EMPLOYEE, ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/coaching-opportunities/:coachingOpportunityId`} component={isAuthenticated(withUserData(CoachingMentoring), [ROLES.EMPLOYEE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions-client`} component={isAuthenticated(withUserData(TermsAndConditionsClient), [ROLES.ADMIN, ROLES.CLIENT])} isWithoutMenu={true} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions-employee`} component={isAuthenticated(withUserData(TermsAndConditionsEmployee), [ROLES.ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE])} isWithoutMenu={true} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions-partner`} component={isAuthenticated(withUserData(TermsAndConditionsSupplier), [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN])} isWithoutMenu={true} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions-candidate`} component={isAuthenticated(withUserData(TermsAndConditionsCandidate), [ROLES.ADMIN, ROLES.CANDIDATE])} isWithoutMenu={true} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/terms-and-conditions-admin`} component={isAuthenticated(withUserData(TermsAndConditionsSaaS), [ROLES.ADMIN])} layout={SinglePageLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/onboarding`} component={isAuthenticated(withUserData(Onboarding), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE])} layout={CandidateJourneyLayout} noDesktopLayout={true} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiters`} exact component={isAuthenticated(Recruiters, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiters/:id`} component={isAuthenticated(Recruiter, [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/recruiter-profiles`} component={isAuthenticated(withUserData(RecruiterProfiles), [ROLES.RECRUITER])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/tasks`} exact component={isAuthenticated(withUserData(Tasks, [ROLES.ADMIN]))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/job-opportunities`} exact component={isAuthenticated(withUserData(JobOpportunities), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/job-opportunities/:id`} exact component={isAuthenticated(withUserData(JobOpportunityDetails), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates`} exact component={isAuthenticated(withUserData(Candidates), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates/checklist`} exact component={isAuthenticated(withUserData(CandidatesChecklist), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/create-profile/jobs`} exact component={isAuthenticated(withUserData(Jobs), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/create-profile/jobs/:id`} exact component={isAuthenticated(withUserData(JobDetails), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={[`/${agencyRouteName}/create-profile`, `/${agencyRouteName}/create-profile/:id`]} exact component={isAuthenticated(withUserData(CandidateProfileJourney), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/candidates/:id`} component={isAuthenticated(withUserData(Candidate), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/:departmentId/members`} exact component={isDepartmentManager(DepartmentMembers)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/departments/:departmentId/members/:memberId`} component={isDepartmentManager(withUserData(Person))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/my-applications`} exact component={isAuthenticated(withUserData(MyApplications), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/my-applications/:id`} exact component={isAuthenticated(withUserData(MyApplicationPage), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/customer-support`} exact component={isAuthenticated(isOnboarded(withUserData(CustomerSupport)))} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/maintenance`} component={MaintenancePage} layout={GuestLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/profile`} exact component={isAuthenticated(withUserData(CandidateProfilePage), [ROLES.CANDIDATE])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs`} exact component={isAuthenticated(withUserData(Jobs), [ROLES.CANDIDATE])} layout={CandidateLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/job-page/:jobId`} exact component={isAuthenticated(withUserData(JobPage), [ROLES.ADMIN, ROLES.CLIENT, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:id`} exact component={isAuthenticated(withUserData(JobDetails), [ROLES.CANDIDATE])} layout={CandidateLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs-dashboard`} exact component={isAuthenticated(withUserData(JobsDashboard), [ROLES.CLIENT, ROLES.ADMIN, ROLES.SUPPLIER_ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={[`/${agencyRouteName}/start-journey`, `/${agencyRouteName}/start-journey-signup-success`]} component={isAuthenticated(withUserData(StartCandidateJourney), [ROLES.CANDIDATE])} layout={CandidateJourneyLayout} noDesktopLayout={true} />
                    <RouteWrapper path={`/${agencyRouteName}/jobs/:jobId/candidates/:candidateId/applications/:applicationId`} exact component={isAuthenticated(withUserData(CandidateDetailsPage), [ROLES.ADMIN, ROLES.CLIENT])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/profile/:userId/matching-jobs`} component={isAuthenticated(withUserData(ProfileMatchingJobs), [ROLES.ADMIN])} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/automatic-track-hours`} component={withUserData(AutomaticallyTrackHours)} layout={ContentLayout} />
                    <RouteWrapper path={`/${agencyRouteName}/sentry-test`} component={withUserData(TestSentry)} layout={ContentLayout} />
                    <Route path={`/${agencyRouteName}/journey-end`} exact component={isAuthenticated(withUserData(CandidateJourneyCompletionThankYouScreen), [ROLES.CANDIDATE])} />
                    <Route path='/account' exact component={RedirectToVerification} />
                    <Route path='*' component={isAuthenticated(withUserData(RedirectToHome))} />
                </Switch>
            </Suspense>
            <ApplicationLoader />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    agencyRouteName: getAgencyRouteName(state),
})

export default connect(mapStateToProps, null)(App);

