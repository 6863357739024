import React from 'react';

import Typography from '@material-ui/core/Typography';

import { ROLES } from 'constants/userConstants';

export const getThankYouScreen = (role, agencyName, email) => {
    switch (role) {
        case ROLES.CANDIDATE:
            return <div>
                <div className="title-container">
                    <Typography className="thank-you-title" variant="h4">
                        {'Thank you for joining'}
                    </Typography>
                    <Typography className="thank-you-title" variant="h4">
                        {`${agencyName}.`}
                    </Typography>
                </div>

                <div className="content-text-container">
                    <Typography className="thank-you-content-text" variant="h5">
                        {'You can now browse and apply for remote softwate jobs and connect with our growing tech community.'}
                    </Typography>
                </div>
            </div >
        case ROLES.ADMIN:
            return <div>
                <div className="title-container">
                    <Typography className="thank-you-title" variant="h4">
                        {'Thank you for your registration'}
                    </Typography>
                    <Typography className="thank-you-title" variant="h4">
                        {`${agencyName}.`}
                    </Typography>
                </div>

                <div className="content-text-container">
                    <Typography className="thank-you-content-text" variant="h5">
                        {`In order to complete your registration, go to your email inbox for ${email} and follow the link to verify your email. `}
                    </Typography>
                </div>
            </div >
        default:
            return <div>
                <div className="title-container">
                    <Typography className="thank-you-title" variant="h4">
                        {'Thank you for joining'}
                    </Typography>
                    <Typography className="thank-you-title" variant="h4">
                        {`${agencyName}.`}
                    </Typography>
                </div>

                <div className="content-text-container">
                    <Typography className="thank-you-content-text" variant="h5">
                        {'You can now browse and apply for remote softwate jobs and connect with our growing tech community.'}
                    </Typography>
                    <Typography className="thank-you-content-text" variant="h5">
                        {`In order to complete your registration, go to your email inbox for ${email} and follow the verification link to set your password.`}
                    </Typography>
                </div>
            </div >
    }
}
