import { useContext } from 'react';

import { MatchingJobCardContext } from '../MatchingJobCard';

import { JOB_OPPORTUNITIES_PRIVACY } from 'constants/jobOpportunitiesConstants';

const MatchingJobFooter = () => {
    const { job } = useContext(MatchingJobCardContext);

    const jobPrivacyValue = Object.values(JOB_OPPORTUNITIES_PRIVACY).find(x => x._id === job.privacy)?.value;

    return (
        <div className="job-footer">
            <div className="job-labels">
                <p className={`box ${job.status.toLowerCase()}-status`}>{job.status}</p>
                <p className={`box ${jobPrivacyValue.toLowerCase()}-privacy`}>{jobPrivacyValue}</p>
                {job.priority && <p className={`box ${job.priority?.toLowerCase()}-priority`}>{job.priority} Priority</p>}
            </div>
            <div className="job-matching-score">
                {job.score} <small>/100</small>
            </div>
        </div>
    );
};

export default MatchingJobFooter;
