import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAgencyRouteName } from 'reducers';

import MatchingProfile from './MatchingProfile';

import { ROLES } from 'constants/userConstants';

import './MatchingProfilesTable.scss';

const MatchingProfilesTable = ({
    title,
    matchingProfiles,
    handleSendToClientClick,
    agencyRouteName,
}) => {
    const history = useHistory();

    const rateLinksMapper = {
        [ROLES.EMPLOYEE]: userId => `/${agencyRouteName}/team/people/${userId}`,
        [ROLES.ADMIN]: userId => `/${agencyRouteName}/team/people/${userId}`,
        [ROLES.CANDIDATE]: userId => `/${agencyRouteName}/candidates/${userId}/profile-information`,
        [ROLES.SUPPLIER_EMPLOYEE]: (userId, supplierId) => `/${agencyRouteName}/partners/${supplierId}/team/people/${userId}`,
        [ROLES.SUPPLIER_ADMIN]: (userId, supplierId) => `/${agencyRouteName}/partners/${supplierId}/team/people/${userId}`,
    };

    const nameLinksMapper = {
        [ROLES.EMPLOYEE]: userId => `/${agencyRouteName}/team/people/${userId}`,
        [ROLES.ADMIN]: userId => `/${agencyRouteName}/team/people/${userId}`,
        [ROLES.CANDIDATE]: userId => `/${agencyRouteName}/candidates/${userId}`,
        [ROLES.SUPPLIER_EMPLOYEE]: (userId, supplierId) => `/${agencyRouteName}/partners/${supplierId}/team/people/${userId}`,
        [ROLES.SUPPLIER_ADMIN]: (userId, supplierId) => `/${agencyRouteName}/partners/${supplierId}/team/people/${userId}`,
    };

    const handleRateClick = (role, userId, supplierId) => {
        history.push(rateLinksMapper[role](userId, supplierId));
    };

    const handleNameClick = (role, userId, supplierId) => {
        return nameLinksMapper[role](userId, supplierId);
    };

    return (
        <div className="matching-profiles-table">
            {matchingProfiles.length ?
                <div className="title">
                    {title}
                </div>
                : null
            }

            <div className="matching-profiles-wrapper">
                {
                    matchingProfiles.map((profile) =>
                        <MatchingProfile
                            key={profile._id}
                            profile={profile}
                            handleNameClick={handleNameClick}
                            handleRateClick={handleRateClick}
                            handleSendToClientClick={handleSendToClientClick}
                        />
                    )
                }
            </div>

        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
});


export default connect(mapStateToProps, null)(MatchingProfilesTable);
