import React from 'react';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';
import SearchIcon from '@material-ui/icons/Search';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import AccessTimeSharpIcon from '@material-ui/icons/AccessTimeSharp';
import EventSharpIcon from '@material-ui/icons/EventSharp';
import TimelineSharpIcon from '@material-ui/icons/TimelineSharp';
import AccountBalanceWalletSharpIcon from '@material-ui/icons/AccountBalanceWalletSharp';
import BusinessSharpIcon from '@material-ui/icons/BusinessSharp';
import ContactPhoneSharpIcon from '@material-ui/icons/ContactPhoneSharp';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import FlagSharpIcon from '@material-ui/icons/FlagSharp';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import StarSharpIcon from '@material-ui/icons/StarSharp';
import StoreSharpIcon from '@material-ui/icons/StoreSharp';
import LoyaltySharpIcon from '@material-ui/icons/LoyaltySharp';
import AnnouncementSharpIcon from '@material-ui/icons/AnnouncementSharp';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import CodeSharpIcon from '@material-ui/icons/CodeSharp';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import { ReactComponent as TasksIcon } from 'assets/tasks-icon.svg';
import { ReactComponent as SuitcaseSolid } from 'assets/suitcase-solid.svg';
import { ReactComponent as CandidatesIcon } from 'assets/icon-material-person-pin.svg';
import { ReactComponent as ReportsMenuIcon } from 'assets/reports-menu-icon.svg';
import { ReactComponent as TalentIcon } from 'assets/talent-icon.svg';
import { ReactComponent as HottestTalentsIcon } from 'assets/hottest-talents-icon.svg';
import { ReactComponent as ExpenseReportsIcon } from 'assets/expense-reports-icon.svg';
import { ReactComponent as DocumentsIcons } from 'assets/documents-icon.svg';

import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { INITIAL_QUERY_STRING_CLIENTS } from 'components/Clients/ClientsConstants';
import { temporaryRoute } from 'constants/commonConstants';

export const getAdminMenuItems = agencyRouteName => [
	{
		TITLE: 'Dashboard',
		ICON: <AssessmentSharpIcon />,
		ROUTE: `/${agencyRouteName}/dashboard`,
	},
	{
		TITLE: 'Talent',
		ICON: <TalentIcon className="talent-icon" />,
		ROUTE: `/${agencyRouteName}/hottest-talents`,
		ITEMS: [
			{ TEXT: 'Hottest Talent', ICON: <HottestTalentsIcon className="hottest-talents-icon" />, ENDPOINT: `/${agencyRouteName}/hottest-talents` },
			{ TEXT: 'Search', ICON: <SearchIcon />, ENDPOINT: `/${agencyRouteName}/explore-talents?profileType=Candidate,Partner,Employee` },
		],
	},
	{
		TITLE: 'Team',
		ICON: <PeopleSharpIcon />,
		ROUTE: `/${agencyRouteName}/team`,
		ITEMS: [
			{ TEXT: 'Employees', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/people` },
			{
				TEXT: 'Time Management',
				ICON: <AccessTimeSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/team/time-management`,
			},
			{
				TEXT: 'Holidays',
				ICON: <EventSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/team/holidays`,
			},
			{
				TEXT: 'Expense Reports',
				ICON: <ExpenseReportsIcon />,
				ENDPOINT: `/${agencyRouteName}/team/expense-reports`,
			},
			{
				TEXT: 'Documents',
				ICON: <DocumentsIcons />,
				ENDPOINT: `/${agencyRouteName}/team/documents`,
			},
			{
				TEXT: 'Performance Reviews',
				ICON: <TimelineSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Payrolls',
				ICON: <AccountBalanceWalletSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Candidates',
		ICON: <CandidatesIcon />,
		ROUTE: `/${agencyRouteName}/candidates`,
	},
	{
		TITLE: 'Reports',
		ICON: <ReportsMenuIcon />,
		ROUTE: `/${agencyRouteName}/reports`,
	},
	{
		TITLE: 'Clients',
		ICON: <BusinessSharpIcon />,
		ROUTE: `/${agencyRouteName}/clients`,
		ITEMS: [
			{
				TEXT: 'Accounts',
				ICON: <AccountCircleSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/clients${INITIAL_QUERY_STRING_CLIENTS}`,
			},
			{
				TEXT: 'Job Requests',
				ICON: <SuitcaseSolid />,
				ENDPOINT: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`,
			},
			{
				TEXT: 'Projects',
				ICON: <FlagSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Contracts',
				ICON: <InsertDriveFileSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Billing / Invoicing',
				ICON: <AttachMoneySharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Feedback',
				ICON: <StarSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Partners',
		ICON: <ContactPhoneSharpIcon />,
		ROUTE: `/${agencyRouteName}/partners`,
		ITEMS: [
			{
				TEXT: 'Companies',
				ICON: <StoreSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/partners`
			},
			{
				TEXT: 'Recruiters',
				ICON: <SearchIcon />,
				ENDPOINT: `/${agencyRouteName}/recruiters`,
			},
			{
				TEXT: 'Offers',
				ICON: <LoyaltySharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Invoicing',
				ICON: <AnnouncementSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Integrations',
		ICON: <CodeSharpIcon />,
		ROUTE: temporaryRoute,
	},
	{
		TITLE: 'Tasks',
		ICON: <TasksIcon />,
		ROUTE: `/${agencyRouteName}/tasks`,
	},
	{
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: `/${agencyRouteName}/settings`,
	},
];

export const getAdminMenuItemsSaas  = agencyRouteName => [
	{
		TITLE: 'Dashboard',
		ICON: <AssessmentSharpIcon />,
		ROUTE: `/${agencyRouteName}/dashboard`,
	},
	{
		TITLE: 'Talent',
		ICON: <TalentIcon className="talent-icon" />,
		ROUTE: `/${agencyRouteName}/hottest-talents`,
		ITEMS: [
			{ TEXT: 'Hottest Talent', ICON: <HottestTalentsIcon className="hottest-talents-icon" />, ENDPOINT: `/${agencyRouteName}/hottest-talents` },
			{ TEXT: 'Search', ICON: <SearchIcon />, ENDPOINT: `/${agencyRouteName}/explore-talents?profileType=Candidate,Partner,Employee` },
		],
	},
	{
		TITLE: 'Team',
		ICON: <PeopleSharpIcon />,
		ROUTE: `/${agencyRouteName}/team`,
		ITEMS: [
			{ TEXT: 'Employees', ICON: <PersonSharpIcon />, ENDPOINT: `/${agencyRouteName}/team/people` },
			{
				TEXT: 'Time Management',
				ICON: <AccessTimeSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/team/time-management`,
			},
			{
				TEXT: 'Holidays',
				ICON: <EventSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/team/holidays`,
			},
			{
				TEXT: 'Expense Reports',
				ICON: <ExpenseReportsIcon />,
				ENDPOINT: `/${agencyRouteName}/team/expense-reports`,
			},
			{
				TEXT: 'Documents',
				ICON: <DocumentsIcons />,
				ENDPOINT: `/${agencyRouteName}/team/documents`,
			},
			{
				TEXT: 'Performance Reviews',
				ICON: <TimelineSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Payrolls',
				ICON: <AccountBalanceWalletSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Candidates',
		ICON: <CandidatesIcon />,
		ROUTE: `/${agencyRouteName}/candidates`,
	},
	{
		TITLE: 'Reports',
		ICON: <ReportsMenuIcon />,
		ROUTE: `/${agencyRouteName}/reports`,
	},
	{
		TITLE: 'Clients',
		ICON: <BusinessSharpIcon />,
		ROUTE: `/${agencyRouteName}/clients`,
		ITEMS: [
			{
				TEXT: 'Accounts',
				ICON: <AccountCircleSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/clients${INITIAL_QUERY_STRING_CLIENTS}`,
			},
			{
				TEXT: 'Job Requests',
				ICON: <SuitcaseSolid />,
				ENDPOINT: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`,
			},
			{
				TEXT: 'All Projects',
				ICON: <AccountTreeRoundedIcon />,
				ENDPOINT: `/${agencyRouteName}/all-projects`,
			},
			{
				TEXT: 'Projects',
				ICON: <FlagSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Contracts',
				ICON: <InsertDriveFileSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Billing / Invoicing',
				ICON: <AttachMoneySharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Feedback',
				ICON: <StarSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Partners',
		ICON: <ContactPhoneSharpIcon />,
		ROUTE: `/${agencyRouteName}/partners`,
		ITEMS: [
			{
				TEXT: 'Companies',
				ICON: <StoreSharpIcon />,
				ENDPOINT: `/${agencyRouteName}/partners`
			},
			{
				TEXT: 'Recruiters',
				ICON: <SearchIcon />,
				ENDPOINT: `/${agencyRouteName}/recruiters`,
			},
			{
				TEXT: 'Offers',
				ICON: <LoyaltySharpIcon />,
				ENDPOINT: temporaryRoute,
			},
			{
				TEXT: 'Invoicing',
				ICON: <AnnouncementSharpIcon />,
				ENDPOINT: temporaryRoute,
			},
		],
	},
	{
		TITLE: 'Integrations',
		ICON: <CodeSharpIcon />,
		ROUTE: temporaryRoute,
	},
	{
		TITLE: 'Tasks',
		ICON: <TasksIcon />,
		ROUTE: `/${agencyRouteName}/tasks`,
	},
	{
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: `/${agencyRouteName}/settings`,
	},
];

export const HIDDEN_MENU_ITEMS = [
	{
		menuItemTitle: 'Team',
		subItemsTitles: ['Dedicated Opportunities'],
		action: 'hide-subitems',
	},
	{
		menuItemTitle: 'Clients',
		subItemsTitles: [],
		action: 'hide-subitems',
	},
];
