import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import SearchMenuView from '../SearchMenuView';

import { DropdownMenuContext } from '../SearchDropdown';

const ProjectsMenu = ({ compact }) => {

    const { searchResults, handleClick, handleBlur } = useContext(DropdownMenuContext);

    const projects = searchResults.filter(x => x.isProject);

    const results = compact ? projects.slice(0, 3) : projects;

    return projects.length > 0 ? (
        <div className="search-menu">
            <Typography variant="h5">Projects</Typography>
            <SearchMenuView
                results={results}
                handleClick={handleClick}
                handleBlur={handleBlur}
            />
        </div>
    ) : null
};

export default ProjectsMenu;
