import React from 'react';

import { ReactComponent as MaintenanceImage } from 'assets/maintenance-image.svg';

import './MaintenancePage.scss';

const MaintenancePage = () => {
    return (
        <div className="maintenance-page-wrapper">
            <MaintenanceImage className="maintenance-image" />
            <h1 className="maintenance-title">Maintenance</h1>
            <p className="maintenance-info">The platform is currently undergoing maintenance and it should be back up shortly.</p>
        </div>
    )
};

export default MaintenancePage;
