import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import JobDetails from '../JobDetails';

import {
    getJobOpportunityDetails,
    getJobOpportunityDetailsDataFetchState,
    getApplicationProcessInformationFetchState,
} from 'reducers';

import {
    fetchCandidateContractorFilters,
    fetchCandidatePersonalInformation,
    fetchCandidatePreferences,
    fetchCandidateApplicationProcessInformation,
} from 'actions/candidateActions';

import { fetchAgencyName } from 'actions/agencyActions';

import './JobOpportunityDetails.scss';

const JobOpportunityDetails = ({
    fetchAgencyName,
    jobOpportunityDetails,
    fetchCandidatePersonalInformation,
    fetchCandidateContractorFilters,
    fetchCandidatePreferences,
    fetchCandidateApplicationProcessInformation,
    agencyId,
    userId,
    match,
    dataFetchState,
    applicationProcessInformationFetchState,
}) => {
    useEffect(() => {
        fetchCandidatePersonalInformation(agencyId, userId);
        fetchCandidateContractorFilters(agencyId, userId);
        fetchCandidatePreferences(agencyId, userId)
        fetchAgencyName(agencyId);
        fetchCandidateApplicationProcessInformation(agencyId, userId);
    }, []);

    if (dataFetchState?.isDataFetching && !jobOpportunityDetails.agencyId && applicationProcessInformationFetchState?.isDataFetching) {
        return <CircularProgress size={50} disableShrink className="page-loader" />
    }

    return (
        <div className="job-details-page-wrapper">
            <JobDetails
                match={match}
                agencyId={agencyId}
                isCandidateMode={true}
            />
        </div>
    )
};

const mapStateToProps = state => ({
    jobOpportunityDetails: getJobOpportunityDetails(state),
    dataFetchState: getJobOpportunityDetailsDataFetchState(state),
    applicationProcessInformationFetchState: getApplicationProcessInformationFetchState(state),
});

const mapDispatchToProps = {
    fetchCandidatePersonalInformation,
    fetchCandidateContractorFilters,
    fetchCandidatePreferences,
    fetchCandidateApplicationProcessInformation,
    fetchAgencyName,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOpportunityDetails);
