import React from 'react';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as DateIcon } from 'assets/icon-date.svg';
import { ReactComponent as ClockIcon } from 'assets/icon-clock.svg';

import { CONTINENTAL_TIME_FORMAT, DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import './UpcomingInterviewDetailsDialog.scss';

const InformationSection = ({ informationField, text, className }) => {
    return (
        <div className={`upcoming-interview-information-section ${className ? className : ''}`}>
            <span className="upcoming-interview-information-field">{informationField}</span>
            <span className="upcoming-interview-text">{text}</span>
        </div>
    );
};

const UpcomingInterviewDetailsDialog = ({
    isOpen,
    handleCloseDialog,
    mandatorySkills,
    niceToHaveSkills,
    jobDescription,
    position,
    company,
    location,
    interviewDate,
    interviewTime,
    aboutTheClient,
    aboutCandidate,
    requirements,
}) => {
    return (
        <Dialog
            className="scheduled-interview-details-dialog"
            open={isOpen}
            onClose={handleCloseDialog}
            maxWidth={'md'}
        >
            <MuiDialogTitle disableTypography className="dialog-header">
                <Typography variant="h5" className="dialog-header-title">
                    Details of Scheduled Interview
                </Typography>

                <IconButton onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>

            <DialogContent className="dialog-content">
                <div className="upcoming-interview-information">
                    <div className="upcoming-interview-information-sections-wrapper">
                        {position && (
                            <InformationSection className="position" informationField="Position:" text={position} />
                        )}
                        {company && (
                            <InformationSection className="company" informationField="Company:" text={company} />
                        )}
                    </div>
                    <div className="upcoming-interview-information-sections-wrapper">
                        {location && (
                            <InformationSection className="location" informationField="Location:" text={location} />
                        )}
                        <div className="upcoming-interview-information-date-time date-time-wrapper">
                            {(interviewDate || interviewTime) && (
                                <div className="upcoming-interview-information-field">When:</div>
                            )}
                            <InformationSection
                                className="date-section"
                                informationField={<DateIcon className="upcoming-interview-icon date" />}
                                text={moment(interviewDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                            />
                            <InformationSection
                                className="time-section"
                                informationField={<ClockIcon className="upcoming-interview-icon hour" />}
                                text={`${moment(interviewTime).format(CONTINENTAL_TIME_FORMAT)} - ${moment(
                                    interviewTime
                                )
                                    .add(1, 'hours')
                                    .format(CONTINENTAL_TIME_FORMAT)}`}
                            />
                        </div>
                    </div>
                </div>

                {jobDescription?.length > 0 ? (
                    <div className="description-section">
                        <Typography variant="h6" className="dialog-content-title">
                            Job Description
                        </Typography>

                        <Typography variant="body1" className="job-description details-text">
                            {jobDescription}
                        </Typography>
                    </div>
                ) : null}

                {aboutTheClient?.length > 0 ? (
                    <div className="description-section">
                        <Typography variant="h6" className="dialog-content-title">
                            About The Client
                        </Typography>

                        <Typography variant="body1" className="job-description details-text">
                            {aboutTheClient}
                        </Typography>
                    </div>
                ) : null}

                {aboutCandidate?.length > 0 ? (
                    <div className="description-section">
                        <Typography variant="h6" className="dialog-content-title">
                            Responsibilities
                        </Typography>

                        <Typography variant="body1" className="job-description details-text">
                            {aboutCandidate}
                        </Typography>
                    </div>
                ) : null}

                {requirements?.length > 0 ? (
                    <div className="description-section">
                        <Typography variant="h6" className="dialog-content-title">
                            Requirements
                        </Typography>

                        <Typography variant="body1" className="job-description details-text">
                            {requirements}
                        </Typography>
                    </div>
                ) : null}

                <div className="skills-container">
                    {mandatorySkills?.length > 0 ? (
                        <div className="skills-section">
                            <Typography variant="h6" className="dialog-content-title">
                                Mandatory Skills
                            </Typography>

                            <div className="skills-content">
                                {mandatorySkills.map((x, i) => (
                                    <div key={i} className="skill-box">
                                        <span className="details-text">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}

                    {niceToHaveSkills?.length > 0 ? (
                        <div className="skills-section">
                            <Typography variant="h6" className="dialog-content-title">
                                Nice to Have
                            </Typography>

                            <div className="skills-content">
                                {niceToHaveSkills.map((x, i) => (
                                    <div key={i} className="skill-box">
                                        <span className="details-text">{x.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default UpcomingInterviewDetailsDialog;
