import React from 'react';

import './ChooseUsageLayout.scss';

const ChooseUsageLayout = ({ children }) => {

    return (
        <div className="select-a-role-layout-wrapper">
            <main className="select-a-role-layout-body-wrapper">
                <div id="scroller" className="select-a-role-layout-content">
                    {children}
                </div>
            </main>
        </div>
    );
};

export default ChooseUsageLayout;
