import React from 'react';

import Slider from 'components/Shared/Slider';
import WelcomeCandidate from 'components/Shared/WelcomeCandidate';
import GuestLayoutSlide from 'components/GuestLayoutSlide';

import MotionLogo from 'assets/motion-logo.svg';

import './ProdGuestLayout.scss';

const ProdGuestLayout = ({
    children,
    slidesData,
    history,
}) => {
    // this logic should be removed once the client part is done
    const isCandidateRegisterPage = history.location.pathname === '/register/candidate';

    const transformFunction = (values) => {
        return <GuestLayoutSlide slideData={values} />
    }

    return (
        <div className="prod-guest-layout">
            <div className="prod-guest-wrapper">
                <div className="guest-introduction">
                    <img className="trademark-image" src={MotionLogo} />
                    {isCandidateRegisterPage
                        ? <WelcomeCandidate />
                        : <Slider slidesData={slidesData} transformFunction={transformFunction} />
                    }
                </div>

                <div className="guest-content">
                    <div className="guest-content-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProdGuestLayout;
