import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';

import { getPublicHolidays } from 'reducers';

const Holiday = ({
    day,  
    isInCurrentMonth, 
    dayComponent,
    publicHolidays
}) => {
    const isWeekend = moment(day).format('E') > 5;
    const isHoliday = publicHolidays?.includes(moment(day).format(DATE_FORMAT_WITH_DASH));

    if((isWeekend || isHoliday) && !dayComponent.props.disabled && isInCurrentMonth) {
        return React.cloneElement(dayComponent, { className: 'MuiPickersDay-day holidays-and-weekends' });
    } else {
        return dayComponent;
    }
}

const mapStateToProps = state => ({
    publicHolidays: getPublicHolidays(state),
});

export default connect(mapStateToProps)(Holiday);
