import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getAgencyTrainings, getAgencyId, getAgencyRouteName } from 'reducers';

import { addTraining, fetchTrainings, removeTraining, updateTraining } from 'actions/agencyActions';

import TableWrapper from 'components/Shared/TableWrapper';
import AddDialog from '../../EmployeeSettings/PerformanceCriteria/AddCriteriaDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import {
    TRAININGS_TABLE_COLUMNS,
    DEFAULT_TRAININGS_TABLE_COLUMNS,
    TRAININGS_SORT_QUERY_SELECTOR,
    TRAININGS_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP,
} from './TrainingsFieldConstants';

import { usePreloadTable } from 'utils/hooks';

const Trainings = ({
    agencyId,
    agencyRouteName,
    addTraining,
    fetchTrainings,
    removeTraining,
    updateTraining,
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedTraining, setSelectedTraining] = useState(null);

    const tableFetchAction = fetchTrainings.bind(null, agencyId);
    const dataSelector = (state, page) => getAgencyTrainings(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction, reload, setReload);

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => {
        setSelectedTraining(null);
        setOpenAddDialog(false);
    };

    const handleEditClick = training => {
        setSelectedTraining(training);
        setOpenAddDialog(true);
    };

    const handleDeleteClick = training => {
        setSelectedTraining(training);
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setSelectedTraining(null);
    }

    const handleTrainingSubmit = async (data) => {
        if (selectedTraining) {
            await updateTraining(agencyId, selectedTraining._id, { ...selectedTraining, title: data.criteria }, false)
        } else {
            await addTraining(agencyId, { title: data.criteria }, false)
        }
        setReload(true);
    };

    const handeRemoveTraining = async () => {
        await removeTraining(agencyId, selectedTraining._id, false);
        setReload(true);
        handleCloseConfirmDialog();
    };

    const adjustTrainingsKeysFunction = (stateData) => stateData.map(x => ({
        title: <Link to={`/${agencyRouteName}/settings/trainings/${x._id}`}>{x.title}</Link>,
        item: x,
    }));

    const actions = [
        { title: 'Edit', handleClick: handleEditClick },
        { title: 'Remove', handleClick: handleDeleteClick },
    ];

    const getPopoverActions = () => actions;

    return (
        <>
            <TableWrapper>
                <TableWrapper
                    title='Trainings'
                    columns={TRAININGS_TABLE_COLUMNS}
                    defaultColumns={DEFAULT_TRAININGS_TABLE_COLUMNS}
                    dataSelector={dataSelector}
                    sortQuerySelector={TRAININGS_SORT_QUERY_SELECTOR}
                    adjustKeysFunction={adjustTrainingsKeysFunction}
                    isTableDataLoading={isTableDataLoading}
                    addMargin
                    withPagination
                    withActions
                    getActionsHandler={getPopoverActions}
                    pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                    pageQuerySelector={TRAININGS_PAGE_QUERY_SELECTOR}
                >
                    <TableWrapper.TableAlignedItems>
                        <TableWrapper.TableAlignedItems.LeftAligned>
                            <TableWrapper.TableAlignedItems.TableHeader />
                        </TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.RightAligned>
                            <TableWrapper.TableAlignedItems.TableActionButton
                                text={'Add New'}
                                icon={<AddButtonIcon />}
                                handleClick={handleOpenAddDialog}
                            />
                        </TableWrapper.TableAlignedItems.RightAligned>
                    </TableWrapper.TableAlignedItems>
                    <TableWrapper.TableContent />
                </TableWrapper>
            </TableWrapper>

            <AddDialog
                selectedCriteria={selectedTraining?.title}
                openDialog={openAddDialog}
                criterias={[]}
                handleCloseDialog={handleCloseAddDialog}
                handleCriteriaSubmit={handleTrainingSubmit}
                title="training"
            />

            <ConfirmDeleteDialog
                itemToDelete={selectedTraining?.title || ''}
                handleDeleteItem={() => handeRemoveTraining()}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </>
    )
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    addTraining,
    fetchTrainings,
    removeTraining,
    updateTraining
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainings);
