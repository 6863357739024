import React, { Fragment } from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';

import { ROLES } from 'constants/userConstants';

import './EmployeeProjectCard.scss';

const EmployeeProjectCard = ({
    project,
    handleProjectCardClick,
    isClientOverview,
    displayMode
}) => {

    const projectEndDate = new Date(project.endDate);
    const dateNow = new Date(Date.now());
    const isPassed = projectEndDate > dateNow;
    const isInSchedule = project.ongoing || isPassed;

    return (
        <Paper className="project-card" onClick={handleProjectCardClick}>
            <div className={`project-card-inner ${displayMode === ROLES.EMPLOYEE || displayMode === ROLES.SUPPLIER_EMPLOYEE && 'no-pointer'}`}>
                <div className="title-wrapper">
                    <Typography className="project-name">
                        {project.name}
                    </Typography> 
                    <div className={`project-status ${isInSchedule ? 'green-status' : 'red-status'}`} />
                </div> 
                <div className="project-client">
                    {project.client && <div className="info-row">
                        <PersonOutlineRoundedIcon fontSize="small" className="info-icon" />
                        <Typography variant="body2" className="info-text">{project.client.name}</Typography>
                    </div>}
                    <div className="info-row">
                        <div className="project-date">
                            <DateRangeRoundedIcon fontSize="small" className="info-icon" />
                            <Typography variant="body2" className="info-text">
                                {
                                    `${moment.utc(project.startDate).format("DD/MM/YY")}
                                    - 
                                    ${project.ongoing ? "ongoing" : moment.utc(project.endDate).format("DD/MM/YY")}`
                                }
                            </Typography>
                        </div>
                    </div> 
                    
                    <div className="additional-info-row">
                        <PeopleOutlineIcon fontSize="small" className="info-icon" />
                        <Typography variant="body2" className="info-text">{(project.employees?.length || 0) + (project.dedicatedEmployees?.length || 0)}</Typography> 
                        {isClientOverview &&
                        <Fragment>
                            <AttachMoneyOutlinedIcon fontSize="small" className="info-icon" />
                            <Typography variant="body2" className="info-text">{project.employees.reduce((acc, empl) => acc + (empl.trackedHours * empl.hourRate), 0).toFixed(2)}</Typography>
                        </Fragment>}
                    </div>
                </div> 
            </div> 
        </Paper>
    )
};

export default EmployeeProjectCard;
