import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import {
    getAgencyId,
    getRecruiterBasicInformation,
    getRecruiterAccountManager,
    getRecruiterContactInformation,
    getDisplayMode,
} from 'reducers';

import {
    fetchRecruiterInformation,
    updateRecruiterContactInformation,
    updateRecruiterBasicInformation,
    updateRecruiterAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
} from 'actions/recruiterActions';


import SubmenuContentLayout from 'layouts/SubmenuContentLayout';
import RecruiterEmployees from 'components/RecruiterEmployees';
import CompanyInformation from '../CompanyInformation';
import RecruiterEmployeeProfile from 'components/RecruiterEmployeeProfile';

import { recruiterSubmenuItems } from 'components/Recruiter/RecruiterConstants';

import defaultLogo from 'assets/default-logo.svg';

import './Recruiter.scss';

const Recruiter = ({
    match,
    fetchRecruiterInformation,
    basicInformation,
    agencyId,
    accountManager,
    displayMode,
    contactInformation,
    updateRecruiterContactInformation,
    updateRecruiterBasicInformation,
    updateRecruiterAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
}) => {
    const { id: recruiterId } = useParams();

    useEffect(() => {
        fetchRecruiterInformation(agencyId, recruiterId);
    }, []);

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={basicInformation.name}
            avatarUrl={basicInformation.logoImgUrl || defaultLogo}
            items={Object.values(recruiterSubmenuItems)}
            displayMode={displayMode}
        >
            <Switch>
                <Route
                    path={`${match.path}${recruiterSubmenuItems.companyInformation.path}`}
                    render={(props) => (
                        <CompanyInformation {...props}
                            updateAccountManagementInformation={updateRecruiterAccountManagerInformation}
                            accountManager={accountManager}
                            basicInformation={basicInformation}
                            companyId={recruiterId}
                            agencyId={agencyId}
                            isRecruiter={true}
                            contactInformation={contactInformation}
                            updateBasicInformation={updateRecruiterBasicInformation}
                            updateContactInformation={updateRecruiterContactInformation}
                            updateAvatar={updateAvatar}
                            deleteAvatar={deleteAvatar}
                        />
                    )}
                />
                <Route
                    path={`${match.path}${recruiterSubmenuItems.team.path}/:id`}
                    render={(props) => (
                        <RecruiterEmployeeProfile
                            {...props}
                            agencyId={agencyId}
                            recruiterId={recruiterId}
                        />
                    )}
                />
                <Route
                    path={`${match.path}${recruiterSubmenuItems.team.path}`}
                    render={(props) => (
                        <RecruiterEmployees
                            {...props}
                            agencyId={agencyId} recruiterId={recruiterId}
                        />
                    )}
                />
            </Switch>
        </SubmenuContentLayout >
    )
};

const mapStateToProps = state => ({
    basicInformation: getRecruiterBasicInformation(state),
    agencyId: getAgencyId(state),
    contactInformation: getRecruiterContactInformation(state),
    accountManager: getRecruiterAccountManager(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchtoProps = {
    fetchRecruiterInformation,
    updateRecruiterContactInformation,
    updateRecruiterBasicInformation,
    updateRecruiterAccountManagerInformation,
    updateAvatar,
    deleteAvatar,
};

export default connect(mapStateToProps, mapDispatchtoProps)(Recruiter);
