import React from 'react';
import { Worker, Viewer, Spinner } from '@react-pdf-viewer/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import '@react-pdf-viewer/core/lib/styles/index.css';
import './ModalPdfViewer.scss';

const ModalPdfViewer = ({
    open,
    onClose,
    filesUrl,
}) => {
    return (
        <Dialog className="pdf-viewer-dialog" open={open} onClose={onClose}>
            <MuiDialogTitle className="dialog-title">
                <IconButton className="dialog-close-btn" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent className="dialog-content">
                {
                    filesUrl?.map((file) =>
                        <>
                            <div className="pdf-file-name">{file.originalFileName}</div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                                <Viewer fileUrl={file.contractFileUrl} renderLoader={() => (
                                    <div className="pdf-file-spinner">
                                        <Spinner />
                                    </div>
                                )} />
                            </Worker>
                        </>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default ModalPdfViewer;
