import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import Dialog from 'components/Shared/Dialog';
import {
    IconButton,
    Typography,
    DialogTitle,
    DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import {
    validateInputLength,
    validateLinkedInURL,
    validateRequiredFields,
} from 'utils/formValidations';
import AddProfileFormView from './AddProfileFormView';

import './AddProfileDialog.scss'

const AddProfileDialog = ({
    openDialog,
    handleSubmitClick,
    handleCloseDialog,
}) => {
    const maxLength = 100;

    return (
        <Formik
            initialValues={{
                name: '',
                linkedin: '',
            }}
            validate={values => {
                const validateLinkedIn = values.linkedin
                    ? validateLinkedInURL(values, 'linkedin')
                    : {};
                return {
                    ...validateRequiredFields(values, ['name']),
                    ...validateInputLength(values, [
                        { property: 'name', maxLength },
                        { property: 'linkedin', maxLength },
                    ]),
                    ...validateLinkedIn,
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                values.name = values.name.trim();
                values.linkedin = values.linkedin.trim();
                setSubmitting(false);
                handleSubmitClick(values);
            }}
        >
            {(props) =>
                <Dialog className="add-profile-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                    <DialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Add new profile</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent className="dialog-content">
                        <AddProfileFormView {...props} handleCloseDialog={handleCloseDialog} />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(AddProfileDialog);
