export const PERFORMANCE_REVIEW_SUMMARY_MAX_LENGTH = 1500;

export const TAB_NAMES = {
    ALL: 'All',
    CLIENT_FEEDBACK: 'Client Feedback',
    INTERNAL_FFEDBACK: 'Internal Feedback'
}

export const TAB_ITEMS = [
    { index: 0, text: TAB_NAMES.ALL },
    { index: 1, text: TAB_NAMES.CLIENT_FEEDBACK },
    { index: 2, text: TAB_NAMES.INTERNAL_FFEDBACK }
];

export const defaultTab = TAB_ITEMS[0];
