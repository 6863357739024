import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    fetchAgencyCoachingOpportunityDetailsPage,
    fetchTagsSuggestions,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    exportBookedSessions,
} from 'actions/coachingOpportunitiesActions';
import { fetchStaticLanguagesSuggestions } from 'actions/staticDataActions';

import {
    getAgencyId,
    getCoachingOpportunity,
    getCoachingOpportunitySessions,
    getStaticLanguages,
} from 'reducers';

import { Avatar, CircularProgress, Paper } from '@material-ui/core';

import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import StatusBox from 'components/Shared/StatusBox';
import Section from 'components/Shared/Section';
import Subsection from 'components/Shared/Subsection';
import CoachingDialog from '../Coaching/CoachingDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';
import CoachingOpportunityPopover from '../Coaching/CoachingOpportunities/CoachingOpportunityCard/CoachingOpportunityPopover';
import BookedSessions from './BookedSessions';

import { getStateAgencyName } from 'utils/helpers';

import { COACHING_OPPORTUNITY_STATUSES } from '../Coaching/CoachingConstants';

import { ReactComponent as CoachingSessionIcon } from 'assets/coaching-session-icon.svg';

import './CoachingOpportunityDetails.scss';

const CoachingOpportunityDetails = ({
    history,
    match,
    agencyId,
    languagesSuggestions,
    fetchAgencyCoachingOpportunityDetailsPage,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    exportBookedSessions,
    coachingOpportunity,
    coachingSessions,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [tags, setTags] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [currentImageSelected, setCurrentImgSelected] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

    const [isCoachingOpportunityLoading, setIsCoachOpportunityLoading] = useState(true);

    useEffect(() => {
        const coachingOpportunityId = match.params.coachingOpportunityId;

        fetchAgencyCoachingOpportunityDetailsPage(agencyId, coachingOpportunityId).then(() => {
            setIsCoachOpportunityLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!isCoachingOpportunityLoading) {
            setTags([...coachingOpportunity.tags]);
            setLanguages([...coachingOpportunity.languages || []]);
        }
    }, [isCoachingOpportunityLoading, isEditDialogOpen]);

    useEffect(() => {
        if (!isCoachingOpportunityLoading) {
            setCurrentImgSelected(getImageUrl());
        }
    }, [isCoachingOpportunityLoading]);

    const updateStatus = (status) => {
        const formData = new FormData();

        formData.append('coachingOpportunityInfo', JSON.stringify({ status }));

        editCoachingOpportunity(agencyId, coachingOpportunity._id, formData);
    };

    const handleAddTag = (value, isNew = false) => {
        if (value !== null && value !== '' && !tags.some(x => x.title.toLowerCase() === value.toLowerCase())) {
            const oldState = [...tags];
            oldState.push({ _id: value.toLowerCase(), title: value, isNew });
            setTags([...oldState]);
        }
    };

    const handleRemoveTag = (value) => {
        const oldState = [...tags];
        const index = oldState.findIndex(x => x.title.toLowerCase() === value.toLowerCase());

        if (index >= 0) {
            oldState.splice(index, 1);
            setTags([...oldState]);
        }
    };

    const handleAddLanguage = value => {
        if (value !== null && value !== '' && !languages.some(language => language.toLowerCase() === value.toLowerCase())) {
            setLanguages(prevState => ([...prevState, value]));
        }
    };

    const handleRemoveLanguage = value => {
        setLanguages(prevState => prevState.filter(language => language !== value));
    };

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
            setImageFile(currentFile);
        }, false)

        imgReader.readAsDataURL(currentFile)
    };

    const handleClosePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleDotsClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDialogs = () => {
        setIsDeleteDialogOpen(false);
        setIsEditDialogOpen(false);
    };

    const handleDelete = () => {
        deleteCoachingOpportunity(agencyId, coachingOpportunity._id);
        handleGoBackBtnClick();
    }

    const handleGoBackBtnClick = () => history.push(`/${getStateAgencyName()}/settings/coaching`);

    const handleExportBookedSessions = () => exportBookedSessions(agencyId, match.params.coachingOpportunityId);

    const getImageUrl = () => {
        return coachingOpportunity.coachingOpportunityImgUrl || coachingOpportunity.imageData?.coachingOpportunityImgUrl;
    };

    return (
        <div className="coaching-opportunity-details-wrapper">
            <div className="button-container">
                <PreviousButton text="Back to Coaching Opportunities" handleClick={handleGoBackBtnClick} />
            </div>
            {isCoachingOpportunityLoading
                ? <CircularProgress size={50} disableShrink className="centered" />
                : <>
                    <Paper className="coaching-opportunity-wrapper">
                        <div className="coaching-opportunity-header">
                            <div className="coach-avatar">
                                {getImageUrl() ?
                                    <Avatar
                                        alt="profile logo"
                                        className="profile-logo"
                                        src={getImageUrl()}
                                    />
                                    : <Avatar className="profile-logo" />}
                            </div>
                            <div className="coaching-opportunity-info">
                                <h2 className="title">{coachingOpportunity.title}</h2>
                                <h3 className="sub-title">{coachingOpportunity.coachName}</h3>
                            </div>
                            <div className="actions">
                                <StatusBox
                                    title={coachingOpportunity.status}
                                    classes={[[COACHING_OPPORTUNITY_STATUSES[coachingOpportunity.status?.toUpperCase()]?.toLowerCase()]]}
                                />
                                <ThreeDotsIcon className="three-dots-action" onClick={handleDotsClick} />
                            </div>
                        </div>

                        <Subsection
                            iconComponent={<AccountCircleOutlined className="icon" />}
                            headerComponent={
                                <div className="subsection-header-wrapper">
                                    <h4 className="subsection-title">About the Coach</h4>
                                    {coachingOpportunity.languages?.length > 0 &&
                                        <div className="languages-list">
                                            {coachingOpportunity.languages.map((language, index) => (
                                                <div key={coachingOpportunity._id + language + index} className="language-item">
                                                    {language}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                            content={coachingOpportunity.aboutTheCoach}
                        />

                        <hr />

                        <Subsection
                            iconComponent={<CoachingSessionIcon className="icon" />}
                            headerComponent={<h4 className="subsection-title">About the Coaching Session</h4>}
                            content={coachingOpportunity.description}
                        />

                        <div className="global-tags-list">
                            {coachingOpportunity.tags.map(x => (
                                <div key={x._id} className="tag-name">{x.title}</div>
                            ))}
                        </div>



                        <CoachingDialog
                            agencyId={agencyId}
                            openDialog={isEditDialogOpen}
                            handleCloseDialog={handleCloseDialogs}
                            editMode={true}
                            tags={tags}
                            handleAddTag={handleAddTag}
                            handleRemoveTag={handleRemoveTag}
                            languages={languages}
                            languagesSuggestions={languagesSuggestions}
                            handleAddLanguage={handleAddLanguage}
                            handleRemoveLanguage={handleRemoveLanguage}
                            imageSelectedHandler={imageSelectedHandler}
                            currentImageSelected={currentImageSelected}
                            setCurrentImgSelected={setCurrentImgSelected}
                            imageFile={imageFile}
                            fetchTagsSuggestions={fetchTagsSuggestions}
                            fetchStaticLanguagesSuggestions={fetchStaticLanguagesSuggestions}
                            selectedOpportunity={coachingOpportunity}
                            editCoachingOpportunity={editCoachingOpportunity}
                        />

                        <CoachingOpportunityPopover
                            handleClose={handleClosePopover}
                            anchorEl={anchorEl}
                            onEditClick={(event) => {
                                setIsEditDialogOpen(true);
                                handleClosePopover(event);
                            }}
                            selectedOpportunity={coachingOpportunity}
                            updateStatus={updateStatus}
                            openDeleteDialog={(event) => {
                                setIsDeleteDialogOpen(true);
                                handleClosePopover(event);
                            }}
                        />

                        <ConfirmDeleteDialog
                            openDialog={isDeleteDialogOpen}
                            handleCloseDialog={handleCloseDialogs}
                            handleDeleteItem={handleDelete}
                            itemToDelete={coachingOpportunity.title}
                        />
                    </Paper>
                    {coachingSessions.length > 0 &&
                        <div className="booked-coaching-sessions-wrapper">
                            <Section
                                title="Booked Sessions"
                                actionComponent={
                                    <PrimaryExportButton
                                        text="Export"
                                        handleClick={handleExportBookedSessions}
                                    />
                                }
                            >
                                <BookedSessions
                                    coachingSessions={coachingSessions}
                                />
                            </Section>
                        </div>
                    }
                </>
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    coachingOpportunity: getCoachingOpportunity(state),
    coachingSessions: getCoachingOpportunitySessions(state),
    languagesSuggestions: getStaticLanguages(state),
});

const mapDispatchToProps = {
    fetchAgencyCoachingOpportunityDetailsPage,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
    editCoachingOpportunity,
    deleteCoachingOpportunity,
    exportBookedSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingOpportunityDetails);
