import {
    COACHING_OPPORTUNITIES_FETCH_SUCCESS,
    COACHING_OPPORTUNITY_FETCH_SUCCESS,
    COACHING_OPPORTUNITY_CREATE_SUCCESS,
    COACHING_OPPORTUNITIES_EDIT_SUCCESS,
    COACHING_OPPORTUNITIES_DELETE_SUCCESS,
    COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS,
    COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS,
    COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS,
} from './actionTypes';

import { authorizedShowNotification } from './actionHelpers';
import { COACHING_OPPORTUNITIES_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import coachingOpportunitiesService from 'services/coachingOpportunitiesService';
import { showNotification } from './notificationActions';

const createCoachingOpportunitySuccess = (data) => ({
    type: COACHING_OPPORTUNITY_CREATE_SUCCESS,
    payload: data,
});

const editCoachingOpportunitySuccess = (data) => ({
    type: COACHING_OPPORTUNITIES_EDIT_SUCCESS,
    payload: data,
});

const deleteCoachingOpportunitySuccess = (data) => ({
    type: COACHING_OPPORTUNITIES_DELETE_SUCCESS,
    payload: data,
});

const createCoachingOpportunitySessionSuccess = (data) => ({
    type: COACHING_OPPORTUNITY_SESSION_CREATE_SUCCESS,
    payload: data,
});

const fetchCoachingOpportunitiesSuccess = (data) => ({
    type: COACHING_OPPORTUNITIES_FETCH_SUCCESS,
    payload: data,
});

const fetchCoachingOpportunitySuccess = (data) => ({
    type: COACHING_OPPORTUNITY_FETCH_SUCCESS,
    payload: data,
});

const fetchEmployeeCoachingSessionsSuccess = (data) => ({
    type: COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS,
    payload: data,
});

const fetchCoachingOpportunitySessionsSuccess = (data) => ({
    type: COACHING_OPPORTUNITY_SESSIONS_FETCH_SUCCESS,
    payload: data,
});

const fetchCoachingOpportunitiesSessionsSuccess = (data) => ({
    type: COACHING_OPPORTUNITIES_SESSIONS_FETCH_SUCCESS,
    payload: data,
});

export const createCoachingOpportunity = (agencyId, formData) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.create(agencyId, formData);

        dispatch(createCoachingOpportunitySuccess(result));
        dispatch(showNotification(COACHING_OPPORTUNITIES_MESSAGES.CREATE_COACHING_OPPORTUNITY_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.CREATE_COACHING_OPPORTUNITY_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const editCoachingOpportunity = (agencyId, coachingOpportunityId, formData) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.edit(agencyId, coachingOpportunityId, formData);

        dispatch(editCoachingOpportunitySuccess(result));
        dispatch(showNotification(COACHING_OPPORTUNITIES_MESSAGES.EDIT_COACHING_OPPORTUNITY_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.EDIT_COACHING_OPPORTUNITY_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const deleteCoachingOpportunity = (agencyId, coachingOpportunityId) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.delete(agencyId, coachingOpportunityId);

        dispatch(deleteCoachingOpportunitySuccess(result));
        dispatch(showNotification(COACHING_OPPORTUNITIES_MESSAGES.DELETE_COACHING_OPPORTUNITY_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.DELETE_COACHING_OPPORTUNITY_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const createCoachingOpportunitySession = (agencyId, employeeId, coachingOpportunityId, eventUri) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.createSession(agencyId, employeeId, coachingOpportunityId, eventUri);

        dispatch(createCoachingOpportunitySessionSuccess(result));
        dispatch(showNotification(COACHING_OPPORTUNITIES_MESSAGES.CREATE_COACHING_OPPORTUNITY_SESSION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.CREATE_COACHING_OPPORTUNITY_SESSION_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchCoachingOpportunities = (agencyId, displayMode) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchCoachingOpportunities(agencyId, displayMode);

        dispatch(fetchCoachingOpportunitiesSuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_OPPORTUNITIES_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchCoachingOpportunity = (agencyId, coachingOpportunityId) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchCoachingOpportunity(agencyId, coachingOpportunityId);

        dispatch(fetchCoachingOpportunitySuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_OPPORTUNITY_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchTagsSuggestions = (agencyId, inputText) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchTagsSuggestions(agencyId, inputText);

        return result;
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_TAGS_SUGGESTIONS_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchEmployeeCoachingSessions = (agencyId, employeeId, upcoming) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchEmployeeCoachingSessions(agencyId, employeeId, upcoming);
        
        dispatch(fetchEmployeeCoachingSessionsSuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_SESSIONS_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchEmployeeCoachingOpportunitySessions = (agencyId, employeeId, coachingOpportunityId, upcoming) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchEmployeeCoachingOpportunitySessions(agencyId, employeeId, coachingOpportunityId, upcoming);
        
        dispatch(fetchCoachingOpportunitySessionsSuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_SESSIONS_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const fetchAgencyCoachingOpportunityDetailsPage = (agencyId, coachingOpportunityId) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchAgencyCoachingOpportunityDetailsPage(agencyId, coachingOpportunityId);

        dispatch(fetchCoachingOpportunitySuccess(result[0].coachingOpportunity));
        dispatch(fetchCoachingOpportunitySessionsSuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_OPPORTUNITY_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const exportBookedSessions = (agencyId, coachingOpportunityId) => async dispatch => {
    try {
        await coachingOpportunitiesService.exportBookedSessions(agencyId, coachingOpportunityId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCoachingOpportunitiesSessions = (agencyId, upcoming) => async dispatch => {
    try {
        const result = await coachingOpportunitiesService.fetchCoachingOpportunitiesSessions(agencyId, upcoming);
        
        dispatch(fetchCoachingOpportunitiesSessionsSuccess(result));
    } catch (err) {
        dispatch(authorizedShowNotification(err)(COACHING_OPPORTUNITIES_MESSAGES.FETCH_COACHING_SESSIONS_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};
