import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import MainMenu from './MainMenu';
import { filterMenuItems } from 'utils/navigationUtils';
import { getStateAgencyName } from 'utils/helpers';
import { fetchCandidateInterviewInvitations } from 'actions/interviewInvitationActions';
import { getEmployeeMenuItems, HIDDEN_MENU_ITEMS, getDepartmentsMenuSubItems } from '../constants/employeeMenuConstants';

import { getInterviewInvitationsList } from 'reducers';

import { getDepartmentSubItemEndpoint } from '../constants/NavigationConstants';
import { ReactComponent as MyTeamIcon } from 'assets/my-team-icon.svg';
import { ReactComponent as DepartmentIcon } from 'assets/department-icon.svg';

const EmployeeMenu = ({
    agencyId,
    userId,
    accessAsManager,
    hiddenSections,
    agencyRouteName,
    interviewInvitations,
    fetchCandidateInterviewInvitations
}) => {
    useEffect(() => {
        fetchCandidateInterviewInvitations(agencyId, userId);
    }, []);

    const [employeeMenuItems, setEmployeeMenuItems] = useState(getEmployeeMenuItems(agencyRouteName));
    const [departmentsMenuSubItems] = useState(getDepartmentsMenuSubItems(agencyRouteName));
    const departmentsMenuItem = {
        TITLE: 'My Department',
        ICON: <MyTeamIcon />,
        ROUTE: `/${getStateAgencyName()}/departments`,
        ITEMS: [],
    };

    if (accessAsManager && accessAsManager.length >= 1 && !employeeMenuItems.some(x => x.ROUTE === departmentsMenuItem.ROUTE)) {

        for (const department of accessAsManager) {
            const text = department.name;
            const endpoint = getDepartmentSubItemEndpoint(department._id);

            if (departmentsMenuItem.ITEMS.some(x => x.ENDPOINT === endpoint)) {
                continue;
            }

            departmentsMenuItem.ITEMS.push({ TEXT: text, ENDPOINT: endpoint, ICON: <DepartmentIcon /> });
        }
        departmentsMenuItem.ITEMS.unshift(...departmentsMenuSubItems);
        setEmployeeMenuItems([departmentsMenuItem, ...employeeMenuItems]);
    }

    const filterMenuItemCallback = useCallback(filterMenuItems(employeeMenuItems, HIDDEN_MENU_ITEMS), [])

    return (
        <MainMenu
            menuItems={hiddenSections
                ? filterMenuItemCallback
                : employeeMenuItems}
            interviewInvitations={interviewInvitations}
        />
    );
}

const mapStateToProps = state => ({
    interviewInvitations: getInterviewInvitationsList(state),
});

const mapDispatchToProps = {
    fetchCandidateInterviewInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeMenu);
