
import React from 'react';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import './SubmenuActionButton.scss';

const SubmenuActionButton = ({
    className,
    handleClick,
    text,
    helperText,
    buttonStyle,
    ...attrs
}) => (
    <div className={`submenu-action-button-container${helperText ? ' with-helper-text': ''}`}>
        <div className="submenu-action-button-wrapper">
            <SecondaryButton
                className={className ? `submenu-action-button ${className}` : 'submenu-action-button'}
                text={text}
                handleClick={handleClick}
                buttonStyle={buttonStyle}
                {...attrs}
            />
        </div>
        {helperText && <div className="helper-text">{helperText}</div>}
    </div>
);

export default SubmenuActionButton;
