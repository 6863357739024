export const FORMAT_TIMES_MAP = {
    DATE: 'L',
    TIME: 'HH:mm',
};

export const DATE_PICKER_FORMAT = 'DD/MM/YYYY';

export const DATE_SHORT_YEAR = 'DD/MM/YY';

export const DATE_PICKER_FORMAT_WITH_DASHES = 'DD-MM-YYYY';

export const DATE_PICKER_FORMAT_MM_YYYY = 'MM/YYYY';

export const MONTH_NAME_FORMAT = 'MMM YYYY';

export const FULL_DATE_WITH_UTC_OFFSET_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
