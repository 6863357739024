import React from 'react';
import moment from 'moment';
import { ErrorMessage } from 'formik';

import SelectTechStack from '../SelectTechStack';
import Switcher from 'components/Shared/Switcher';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { DIALOG_COMMENT_INPUT, MIN_DATE } from 'constants/commonConstants';
import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat'

const ProjectDialogView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
    const handleOnInputChangeTechnologies = (newValue) => {
        setFieldValue('technologies', [...values.technologies, newValue.title]);
    };

    const handleOnDeleteSkill = (index) => {
        setFieldValue('technologies', [...values.technologies.slice(0, index), ...values.technologies.slice(index + 1)]);
    };

    const addNewSkill = (newValue) => {
        setFieldValue('technologies', [...values.technologies, newValue]);
    };

    return (
        <form className="project-dialog-view form" autoComplete="off">
            <div className="project-name-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Project Name"
                    value={values.name}
                    onChange={handleChange}
                    error={errors.name && touched.name}
                />
                <ErrorMessage className="invalid-field-message" name="name" component="div" />
            </div>
            <div className="project-client-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="client"
                    label="Project Client"
                    value={values.client}
                    onChange={handleChange}
                    error={errors.client && touched.client}
                />
                <ErrorMessage className="invalid-field-message" name="client" component="div" />
            </div>

            <SelectTechStack
                label="Select Tech Stack"
                handleInputChange={handleOnInputChangeTechnologies}
                handleOnDeleteSkill={handleOnDeleteSkill}
                addNewSkill={addNewSkill}
                technologies={values.technologies}
                error={errors.technologies && touched.technologies ? errors.technologies : ''}
            />

            <div className="project-position-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="position"
                    label="Position"
                    value={values.position}
                    onChange={handleChange}
                    error={errors.position && touched.position}
                />
                <ErrorMessage className="invalid-field-message" name="position" component="div" />
            </div>
            <div className="project-description-field">
                <TextField
                    fullWidth
                    multiline
                    minRows={DIALOG_COMMENT_INPUT.ROWS}
                    variant="outlined"
                    name="description"
                    label="Project Description"
                    value={values.description}
                    onChange={handleChange}
                    error={errors.description && touched.description}
                />
                <ErrorMessage name="description" component="div" className="invalid-field-message" />
            </div>
            <div className="project-dates-information-wrapper">
                <div className="project-dates-options">
                    <Switcher
                        label="Currenly work here"
                        initialValue={values.currentlyWork}
                        isChecked={values.currentlyWork}
                        changeStatusHandler={(event) => setFieldValue('currentlyWork', event.target.checked)}
                    />
                    <Switcher
                        label="Dates unknown"
                        initialValue={values.datesUnknown}
                        isChecked={values.datesUnknown}
                        changeStatusHandler={(event) => setFieldValue('datesUnknown', event.target.checked)}
                    />
                </div>
                <div className="project-start-date-field">
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        allowKeyboardControl
                        variant="inline"
                        inputVariant="outlined"
                        openTo="year"
                        views={["year", "month"]}
                        label="Start Date"
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        disabled={values.datesUnknown}
                        value={
                            values.datesUnknown
                                ? null
                                : values.startDate
                        }
                        onChange={value => setFieldValue('startDate', moment(value))}
                    />
                </div>
                <div className="project-end-date-field">
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        allowKeyboardControl
                        variant="inline"
                        inputVariant="outlined"
                        openTo="year"
                        views={["year", "month"]}
                        label="End Date"
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        disabled={values.datesUnknown || values.currentlyWork}
                        minDate={values.startDate || MIN_DATE}
                        value={
                            (values.datesUnknown || values.currentlyWork)
                                ? null
                                : values.endDate
                        }
                        onChange={value => setFieldValue('endDate', moment(value))}
                    />
                </div>
            </div>
        </form>
    );
};

export default ProjectDialogView;
