import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { getAgencyId, getDisplayMode, getIndividualTraining, isAdminMode } from 'reducers';
import {
    fetchTraining,
    updateTraining,
    updateTrainingTopicDescription
} from 'actions/agencyActions';

import SubmenuContentLayout from 'layouts/SubmenuContentLayout';
import Topic from './Topic/Topic';
import Overview from './Overview/Overview';

import './IndividualTraining.scss';

const IndividualTraining = ({
    updateTrainingTopicDescription,
    individualTraining,
    fetchTraining,
    agencyId,
    isAdmin,
    displayMode,
    match
}) => {
    const { trainingId } = match.params;
   
    useEffect(() => {
        fetchTraining(agencyId, trainingId)
    }, []);

    const menuItems = individualTraining.topics?.map(x => ({ text: x.title, path: `/topics/${x._id}` }));

    return (
        <SubmenuContentLayout
            path={match.url}
            items={Object.values(menuItems)}
            isAdmin={isAdmin}
            isWithProfileInfo={false}
            displayMode={displayMode}
        >
            <div className="individual-training-container">
                <Switch>
                    <Route exact path={match.path} render={props => <Overview {...props} />} />
                    <Route path={`${match.path}/topics/:topicId`} render={props => (
                        <Topic
                            {...props}
                            agencyId={agencyId}
                            isAdmin={isAdmin}
                            topics={individualTraining.topics?.slice(0)}
                            updateTrainingTopicDescription={updateTrainingTopicDescription} />
                    )} />
                </Switch>
            </div>
        </SubmenuContentLayout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    agencyId: getAgencyId(state),
    individualTraining: getIndividualTraining(state, ownProps.match.params.trainingId),
    isAdmin: isAdminMode(state),
    displayMode: getDisplayMode(state),
})

const mapDispatchToProps = {
    fetchTraining,
    updateTraining,
    updateTrainingTopicDescription
};

export default connect(mapStateToProps, mapDispatchToProps)(IndividualTraining);
