import { Box, CircularProgress } from '@material-ui/core';
import { ReactComponent as CulturalFitRejectionIcon } from 'assets/cultural-fit-rejection-icon.svg';

import { CANDIDATE_REJECTION_REASONS_KEYS } from 'constants/candidateConstants';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { calculateDaysLeft } from 'utils/formatTimes';

export const getRejectionBannerInfo = (rejectionReason, rejectionDate, handleRequestNewReview) => {
    const totalDaysUntilRequestNewReview = 180;
    const { daysLeft, daysLeftInPercentage } = calculateDaysLeft(rejectionDate, totalDaysUntilRequestNewReview);

    switch (rejectionReason) {
        case CANDIDATE_REJECTION_REASONS_KEYS.LACK_OF_CULTURAL_FIT:
        case CANDIDATE_REJECTION_REASONS_KEYS.HARD_REJECT:
        case CANDIDATE_REJECTION_REASONS_KEYS.OTHER:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: 'Your profile has not been approved.',
                message: `At this time, you won't be allowed to apply for active jobs as there are a high number of candidates with similar skill sets who match better our culture and values.`
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.LACKING_SKILLS:
            return {
                icon: (
                    <Box className="box-wrapper">
                        <CircularProgress className="circular-progress background" variant="determinate" size={66} thickness={3} value={100} />
                        <CircularProgress className="circular-progress foreground" variant="determinate" size={66} thickness={3} value={daysLeftInPercentage} />

                        <Box className="days-left-box">
                            <p className="days-left">{daysLeft}</p>
                            <p className>{daysLeft === 1 ? 'day' : 'days'}</p>
                        </Box>
                    </Box>
                ),
                title: `You have ${daysLeft === 1 ? `${daysLeft} day left` : `${daysLeft} days left`} to update your profile`,
                message: `Please update your CV and job preferences below, before you apply again.`,
                action: (
                    <PrimaryButton
                        text="Request New Review"
                        handleClick={handleRequestNewReview}
                        disabled={daysLeft > 0}
                    />
                )
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.UNREASONABLE_REMUNERATION:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: 'Your profile has not been approved.',
                message: 'If you update your expected salary our team will automatically review your profile again.',
            };
        case CANDIDATE_REJECTION_REASONS_KEYS.UNSUITABLE_LOCATION:
            return {
                icon: <CulturalFitRejectionIcon />,
                title: 'Your profile has not been approved.',
                message: 'If you update your tax residency to a location we support, our team will automatically review your profile again.'
            };
        default:
            return {};
    }
};
