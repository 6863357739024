import React from 'react';
import { connect } from 'react-redux';

import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';
import { fetchUserInformationSuccess } from 'actions/authActions';

import {
    validateRequiredFields,
    validateEmailAddress,
    validateInputLength,
    validatePasswordRequirements,
    validatePasswordEquality
} from 'utils/formValidations';

import { Formik } from 'formik';
import RegisterFormView from '../../RegisterFormView';

import { REGISTRATION_TYPES, ROLES, REFERRALS } from 'constants/userConstants';
import { IS_WHOISAVAILABLE_ENV } from 'constants/env';

const maxLength = 100;

const RegisterClientFormik = ({
    blur,
    referrer,
    gclid,
    initialValues,
    registerClient,
    login,
    countries,
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
    fetchUserInformationSuccess,
}) => (
    <Formik
        initialValues={initialValues}
        validate={values => {
            const requiredFields = IS_WHOISAVAILABLE_ENV
                ? ['name', 'email', 'password', 'confirmPassword', 'contactPerson']
                : ['name', 'email', 'password', 'confirmPassword', 'contactPerson', 'isTermsAndConditionsAccepted'];

            return {
                ...validatePasswordRequirements(values, 'password'),
                ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                ...validateRequiredFields(values, requiredFields),
                ...validateInputLength(values, [
                    { property: 'name', maxLength },
                    { property: 'contactPerson', maxLength },
                    { property: 'email', maxLength },
                ]),
                ...validateEmailAddress(values, 'email'),
            }
        }}
        onSubmit={(values, { setSubmitting }) => {
            values.name = values.name.trim();
            values.email = values.email.toLowerCase().trim();
            values.password = values.password.trim();
            values.contactPerson = values.contactPerson.trim();
            values.registrationTypes = REGISTRATION_TYPES.SELF_REGISTERED;
            values.isFirst = true;
            setSubmitting(false);
            values = {
                ...values,
                role: ROLES.CLIENT,
                referrer: gclid && (!referrer || referrer === REFERRALS.WEBSITE_ORGANIC) ? REFERRALS.GOOGLE : referrer,
                campaignId: gclid,
            }
            registerClient(values)
                .then(async () => {
                    showApplicationLoader();
                    await login(values.email, values.password);
                    hideApplicationLoader();
                })
                .catch(() => hideApplicationLoader());
        }}
    >
        {(props) => <RegisterFormView
            {...props}
            countries={countries}
            blur={blur}
            referrer={referrer}
            campaignId={gclid}
            login={login}
            showApplicationLoader={showApplicationLoader}
            hideApplicationLoader={hideApplicationLoader}
            createSSOLoggingRecord={createSSOLoggingRecord}
            fetchUserInformationSuccess={fetchUserInformationSuccess}
        />}
    </Formik>
);

const mapDispatchToProps = {
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
    fetchUserInformationSuccess,
};

export default connect(null, mapDispatchToProps)(RegisterClientFormik);
