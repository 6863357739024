import React from 'react';

import './MissingInformation.scss';

const MissingInformation = ({

}) => {
    return (
        <div className="missing-information-wrapper">
            <p>Missing Information</p>
        </div>
    )
};

export default MissingInformation;
