import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

import amplitude from 'amplitude-js';
import store from 'utils/store';

import {
    isAuthenticated,
    getUserId,
    getEmail,
    getRoles,
    getDisplayMode,
    getClientContactInformation,
    getSupplierContactInformation,
    getRecruiterContactInformation,
    getCurrentUser,
    getAgencyName,
    getSupplierBasicInformation
} from 'reducers';

import { AMPLITUDE_CONFIG } from 'constants/env';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

const amplitudeClient = amplitude.getInstance();
amplitudeClient.init(AMPLITUDE_CONFIG.API_KEY, null, AMPLITUDE_CONFIG.OPTIONS);

export const setUserId = userId => {
    amplitudeClient.setUserId(userId);
};

export const setUserProperties = userProperties => {
    amplitudeClient.setUserProperties(userProperties);
};

export const addUserPropertyValue = ({ key, value }) => {
    const identify = new amplitude.Identify().add(key, value);
    amplitude.identify(identify);
};

const getInitialUserProperties = (state, displayMode) => {
    const email = getEmail(state);
    const roles = getRoles(state);
    const agencyName = getAgencyName(state);

    const userProperties = { email, roles, agencyName };

    if (displayMode === ROLES.ADMIN || displayMode === ROLES.EMPLOYEE || displayMode === ROLES.SUPPLIER_EMPLOYEE || displayMode === ROLES.CANDIDATE) {
        const user = getCurrentUser(state)
        userProperties.name = user.name;
    } else if (displayMode === ROLES.CLIENT) {
        const clientContactInformation = getClientContactInformation(state);
        userProperties.name = clientContactInformation.contactPerson;
    } else if (displayMode === ROLES.SUPPLIER_ADMIN) {
        const supplierContactInformation = getSupplierContactInformation(state);
        userProperties.name = supplierContactInformation.contactPerson;
    } else if (displayMode === ROLES.RECRUITER) {
        const recruiterContactInformation = getRecruiterContactInformation(state);
        userProperties.name = recruiterContactInformation.contactPerson;
    }

    if (displayMode === ROLES.SUPPLIER_ADMIN || displayMode === ROLES.SUPPLIER_EMPLOYEE) {
        const supplierBasicInformation = getSupplierBasicInformation(state);
        userProperties.supplierCompanyName = supplierBasicInformation.name;
    }

    return userProperties;
};

export const logEvent = (eventType, options, eventProperties, userProperties) => {
    if (process.env.REACT_APP_ENV !== 'development' && process.env.NODE_ENV !== 'development') {
        const state = store.getState();

        if (isAuthenticated(state)) {
            const userId = getUserId(state);
            const displayMode = getDisplayMode(state);
            const initialUserProperties = getInitialUserProperties(state, displayMode);

            if (options?.rolesPermitted && !options?.rolesPermitted.includes(displayMode)) {
                return;
            };


            setUserId(userId);
            setUserProperties({ ...initialUserProperties, ...userProperties });
        }
        amplitudeClient.logEvent(eventType, eventProperties);
    }
};

export const ViewPage = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        logEvent(AMPLITUDE_EVENT_TYPES.PAGE_VIEWED, null, { path: pathname });
    }, [pathname]);

    return null;
};
