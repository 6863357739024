import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { getEmploymentHolidayAllowance } from 'reducers';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import HolidayRejectFormView from './HolidayRejectFormView';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import Avatar from '@material-ui/core/Avatar';
import './HolidayRejectDialog.scss';


const HolidayRejectDialog = ({
    openDialog,
    employeeInfo,
    handleCloseDialog,
    handleHolidayReject,
    employeeSpentHolidays,
    employeeHolidayAllowance,
}) => {
    const holidayAllowance = employeeInfo.holidayAllowance || employeeHolidayAllowance;
    const remainingDays = holidayAllowance - employeeSpentHolidays;
    const daysLeft = remainingDays ? `${remainingDays}/${holidayAllowance}` : 'N/A';

    return (
        <Formik
            initialValues={{
                rejectionReason: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                const { rejectionReason } = values;

                handleHolidayReject(rejectionReason);
            }}
        >
            {(props) =>
                <Dialog className="reject-holiday-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Reject Holiday Request</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>

                    <DialogContent className="dialog-content">
                        <div className="profile">
                            <Avatar
                                alt="Empoyee Avatar"
                                className="avatar-logo-image"
                                src={employeeInfo?.profilePictureImgUrl}
                            />
                            <Typography variant="h5" className="profile-name">
                                {employeeInfo && employeeInfo.name}
                            </Typography>
                        </div>
                        <div className="request-details">
                            <div>From: <span className="request-details-value">{employeeInfo && moment.utc(employeeInfo.from).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span></div>
                            <div>To: <span className="request-details-value">{employeeInfo && moment.utc(employeeInfo.to).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</span></div>
                            <div>Type: <span className="request-details-value">{employeeInfo && employeeInfo.type}</span></div>
                            <div>Days left: <span className={remainingDays > 0 ? "request-details-days" : (!remainingDays ? "request-details-no-days" : "red")}>{employeeInfo && daysLeft}</span></div>
                        </div>
                        <div className="request-reason">
                            <div>Reason: <span className="request-details-value">{employeeInfo && employeeInfo.reason}</span></div>
                        </div>
                        <HolidayRejectFormView {...props}
                            handleCloseDialog={handleCloseDialog}
                        />
                    </DialogContent>
                </Dialog >
            }
        </Formik>
    );
}

const mapStateToProps = (state, props) => ({
    employeeSpentHolidays: props.bookedHolidaysCountSelector(state, props.employeeInfo.employeeId),
    employeeHolidayAllowance: getEmploymentHolidayAllowance(state),
})

export default connect(mapStateToProps)(HolidayRejectDialog);
