import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import RestoreIcon from '@material-ui/icons/Restore';
import Button from '@material-ui/core/Button';


import { ROLES } from 'constants/userConstants';

import logoImg from 'assets/default-logo.svg';

const ResultsCardView = ({
    result,
    agencyName,
    handleClick,
    openConfirmRestoreDialog,
    hideProjects,
}) => {
    const address = ` ${result.address || ""}${" " + (result.country || "")}`;
    const name = result.clientName || result.name || `${result.firstName} ${result.lastName}`;
    const picture = result.role === ROLES.ADMIN || result.role === ROLES.EMPLOYEE || result.role === ROLES.SUPPLIER_EMPLOYEE ?
        result.profilePictureImgUrl :
        (result.logoImgUrl ? result.logoImgUrl : logoImg);
    const secondaryInfo = result.role === ROLES.EMPLOYEE || result.role === ROLES.ADMIN || result.role === ROLES.SUPPLIER_EMPLOYEE
        ? `${(result.position || "Employee")} at ${result.companyInfo || agencyName}`
        : address;
    const projects = result.projects ? result.projects.map(x => x.name).join(", ") : "No projects";

    return (
        <div className="card-view-wrapper">
            <ListItem
                className='result-list-item'
                button={!!handleClick}
                onClick={handleClick ? handleClick.bind(null, result) : null}
            >
                <div className="top-section">
                    <div className="main-info">
                        <ListItemAvatar>
                            <Avatar alt={name} src={picture} className="search-result-avatar" />
                        </ListItemAvatar>
                        <ListItemText
                            className="name-info"
                            primary={name}
                            secondary={secondaryInfo}
                        />
                    </div>
                </div>
                <div className="secondary-info">
                    <ListItemText
                        primary="E-mail"
                        secondary={result.email || "N/A"}
                        className="email-field"
                    />
                    {
                        result.role !== ROLES.SUPPLIER_ADMIN && !hideProjects && <ListItemText
                            primary="Current Projects"
                            secondary={projects || "No projects"}
                            className="projects-field"
                        />
                    }
                </div>
            </ListItem>

            {result.isDeleted &&
                <div className="restore-profile" onClick={() => openConfirmRestoreDialog({ id: result._id, role: result.role })}>
                    <div tooltip="Restore profile from here" className="restore-user-tooltip">
                        <Button >Deleted</Button>
                        <RestoreIcon className="restore-icon" />
                    </div>
                </div>}
        </div>

    )
}

export default ResultsCardView;
