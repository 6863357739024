import React from 'react';

import LoginCard from 'components/Shared/LoginCard';
import LoginFormik from '../LoginFormik';

import { LOGIN_CARD } from '../LoginConstants';

const LoginDesktopComponent = ({
    gotoUrl,
    signUpButtonLink,
    loginLoading,
    login,
}) => (
    <LoginCard msg={LOGIN_CARD.MESSAGE}>
        <LoginFormik
            gotoUrl={gotoUrl}
            signUpButtonLink={signUpButtonLink}
            loginLoading={loginLoading}
            login={login}
        />
    </LoginCard>
);

export default LoginDesktopComponent;
