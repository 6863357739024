import {
    EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR,
    EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS,
    PROJECT_TEMPLATES_FETCH_SUCCESS,
    PROJECT_TEMPLATE_GET_SUCCESS,
    PROJECT_TEMPLATE_ADD_SUCCESS,
    PROFILE_FETCH_SUCCESS,
    EMPLOYEE_PROJECTS_UPDATE_SUCCESS,
    CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
} from 'actions/actionTypes';

const personalProject = (state, action) => {
    if (state?._id && action?.payload?._id !== state._id && action?.type !== PROJECT_TEMPLATE_GET_SUCCESS) {
        return state;
    }

    switch (action.type) {
        case EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS:
            return { ...action.payload, isExisting: true };
        case PROJECT_TEMPLATE_GET_SUCCESS:
            const id = state?._id ? state._id : '';
            return {
                ...action.payload,
                _id: id,
                isExisting: state.isExisting
            };
        case EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS:
            return { ...action.payload };
        case PROJECT_TEMPLATE_ADD_SUCCESS:
            return { ...action.payload };
        case EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
};

const personalProjectsInitialState = { list: [], current: {}, templates: [] }

const personalProjects = (state = personalProjectsInitialState, action) => {
    switch (action.type) {
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return personalProjectsInitialState;
        case EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR:
            return { ...state, current: {} };
        case PROFILE_FETCH_SUCCESS:
            const projects = action.payload.projects || [];
            return { ...state, list: [...projects], current: {} };
        case PROJECT_TEMPLATES_FETCH_SUCCESS:
            const templates = action.payload || [];
            return { ...state, templates: [...templates] }
        case EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS:
            return { ...state, current: personalProject(undefined, action) };
        case PROJECT_TEMPLATE_GET_SUCCESS:
            return { ...state, current: personalProject(state.current, action) };
        case EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS:
            return { ...state, list: [personalProject(undefined, action), ...state.list], current: {} };
        case PROJECT_TEMPLATE_ADD_SUCCESS:
            return { ...state, templates: [...state.templates, personalProject(undefined, action)], current: {} }
        case EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS:
            return { ...state, list: state.list.map(x => personalProject(x, action)), current: {} };
        case EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS:
            return { ...state, list: state.list.filter(x => x._id !== action.payload._id), current: {} };
        case EMPLOYEE_PROJECTS_UPDATE_SUCCESS:
            return { ...state, list: action.payload, current: {} };
        case CANDIDATE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
        case EMPLOYEE_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return personalProjectsInitialState;
        default:
            return state;
    }
};

export default personalProjects;

export const getEmployeePersonalProjectsList = state => state.list;
export const getEmployeeCurrentPersonalProject = state => state.current;
export const getPersonalProjectTemplatesList = state => state.templates;
