import React from 'react'
import { DAYS_PERIOD } from '../ActiveHolidaysConstants';

import * as dates from 'react-big-calendar/lib/utils/dates';
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

const ActiveHolidaysWeekView = (props) => {
return <TimeGrid {...props} range={ActiveHolidaysWeekView.range(props.date, props)} />;
}

ActiveHolidaysWeekView.range = (date, props) => dates.range(date, dates.add(date, DAYS_PERIOD, 'day'), 'day');

ActiveHolidaysWeekView.defaultProps = TimeGrid.defaultProps;

ActiveHolidaysWeekView.title = (date, { localizer }) => {
    const [start, ...rest] = ActiveHolidaysWeekView.range(date, { localizer });
    return localizer.format({ start: new Date(start), end: rest.pop() }, 'dayRangeHeaderFormat')
}

export default ActiveHolidaysWeekView;
