import React, { useContext, createContext } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { getDisplayMode } from 'reducers';

import CvProfileInformation from './CvProfileInformation';
import CvProfileSummary from './CvProfileSummary';
import CvSkills from './CvSkills';
import CvEducation from './CvEducation';
import CvExperience from './CvExperience';
import CvCertificates from './CvCertificates';
import CvLanguages from './CvLanguages';

import './PaperCv.scss';

export const CandidateCvContext = createContext();
const { Provider } = CandidateCvContext;

const PaperCv = ({
    cvInformation,
    companyLogoUrl,
    enterEditMode,
    enableEdit,
    displayMode,
    children,
    scrollToRef,
}) => {
    const context = { cvInformation, enterEditMode, enableEdit, displayMode, scrollToRef };

    return (
        <div className={`cv-completion-page${isMobile ? ' mobile' : ''}`}>
            <div className="content">
                <Provider value={context}>
                    {children}
                </Provider>
            </div>
            {companyLogoUrl &&
                <img className={`cv-logo-content-image ${isMobile && 'mobile'}`} src={companyLogoUrl} />
            }
        </div>
    )
};

const ProfileInformation = ({ }) => {
    const { cvInformation } = useContext(CandidateCvContext);

    return (
        <CvProfileInformation profileInformation={cvInformation.profileInformation} />
    );
};

const ProfileSummary = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvProfileSummary
            displayMode={displayMode}
            profileSummary={cvInformation.profileSummary}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Skills = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvSkills
            displayMode={displayMode}
            skills={cvInformation.skills}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Education = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvEducation
            displayMode={displayMode}
            education={cvInformation.education}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Experience = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvExperience
            displayMode={displayMode}
            projects={cvInformation.projects}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Certificates = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode, scrollToRef } = useContext(CandidateCvContext);

    return (
        <CvCertificates
            scrollToRef={scrollToRef}
            displayMode={displayMode}
            certificates={cvInformation.certificates}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

const Languages = ({ }) => {
    const { cvInformation, enterEditMode, enableEdit, displayMode } = useContext(CandidateCvContext);

    return (
        <CvLanguages
            displayMode={displayMode}
            languages={cvInformation.languages}
            enterEditMode={enterEditMode}
            enableEdit={enableEdit}
        />
    );
};

PaperCv.ProfileInformation = ProfileInformation;
PaperCv.ProfileSummary = ProfileSummary;
PaperCv.Skills = Skills;
PaperCv.Education = Education;
PaperCv.Experience = Experience;
PaperCv.Certificates = Certificates;
PaperCv.Languages = Languages;

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, null)(PaperCv);
