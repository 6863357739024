import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import EmployeeStatusBanner from './EmployeeStatusBanner';
import PerformanceReviewOverallCard from '../PerformanceReview/PerformanceReviewOverall/PerformanceReviewOverallCard';
import SectionBanner from 'components/Shared/SectionBanner';
import EditStatusDialog from 'components/Employees/EditStatusDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import {
    isAdminMode,
    getEmploymentInformation,
    getPerformanceReviews,
    getEmployeeOverviewInformation,
    getEmployeeOverviewFetchState,
    getAgencyRouteName,
} from 'reducers';

import { updateEmployeeOccupationStatus, fetchEmployeeOverviewInformation, employeeOverviewFetchingSuccess } from 'actions/employeeActions';

import { EMPLOYEE_OCCUPATION_STATUSES_MAP } from 'constants/employeeConstants';

import { getOverviewBannersItems } from './OverviewUtils';
import { generateOverallPerformance } from '../PerformanceReview/PerformanceReviewUtils';

import './Overview.scss';

const Overview = ({
    agencyRouteName,
    isAdminMode,
    employmentInformation,
    performanceReviews,
    employeeOverviewInformation,
    dataFetchState,
    userId,
    agencyId,
    departmentId,
    updateEmployeeOccupationStatus,
    fetchEmployeeOverviewInformation,
    employeeOverviewFetchingSuccess,
}) => {
    const selectedEmployee = { _id: userId, ...employmentInformation };
    const [isEditStatusDialogOpen, setIsEditStatusDialogOpen] = useState(false);

    const openEditStatusDialog = () => setIsEditStatusDialogOpen(true);
    const closeEditStatusDialog = () => setIsEditStatusDialogOpen(false);

    const handleEditStatus = (employeeId, data) => {
        updateEmployeeOccupationStatus(agencyId, employeeId, data);
    };

    useEffect(() => {
        fetchEmployeeOverviewInformation(agencyId, userId, departmentId);

        return () => employeeOverviewFetchingSuccess();
    }, [userId]);

    const hasMissingHours = employeeOverviewInformation.missingHoursInMonths.length > 0;
    const bannersItems = getOverviewBannersItems(employeeOverviewInformation);

    bannersItems.contracts.buttonProps = departmentId
        ? { component: Link, to: `/${agencyRouteName}/departments/${departmentId}/members/${userId}/employment-information?scrollIntoContracts=true` }
        : { component: Link, to: `/${agencyRouteName}/team/people/${userId}/employment-information?scrollIntoContracts=true` };
    bannersItems.cv.buttonProps = departmentId
        ? { component: Link, to: `/${agencyRouteName}/departments/${departmentId}/members/${userId}/profile-cv` }
        : { component: Link, to: `/${agencyRouteName}/team/people/${userId}/profile-cv` };
    bannersItems.trackTime.buttonProps = departmentId
        ? { component: Link, to: `/${agencyRouteName}/departments/${departmentId}/members/${userId}/time-tracking` }
        : { component: Link, to: `/${agencyRouteName}/team/people/${userId}/time-tracking` }
    bannersItems.trackTime.bannerTextAdditionalInfo = hasMissingHours
        ? <Tooltip
            title="Employee has less than 8 hours tracked for each working day of that month."
            classes={{ tooltip: 'custom-tooltip' }}
        >
            <InfoOutlinedIcon className="global-information-icon" />
        </Tooltip>
        : null;
    bannersItems.historicalNotes.buttonProps = departmentId
        ? { component: Link, to: `/${agencyRouteName}/departments/${departmentId}/members/${userId}/personal-information?scrollIntoHistoricalNotes=true` }
        : { component: Link, to: `/${agencyRouteName}/team/people/${userId}/personal-information?scrollIntoHistoricalNotes=true` };

    return (
        dataFetchState?.isDataFetching
            ? <CircularProgress size={50} disableShrink className="page-loader" />
            : <>
                <div className="overview-wrapper">
                    {(isAdminMode || departmentId) &&
                        <>
                            {EMPLOYEE_OCCUPATION_STATUSES_MAP[selectedEmployee.occupationStatus] &&
                                <EmployeeStatusBanner
                                    selectedEmployee={selectedEmployee}
                                    openEditStatusDialog={openEditStatusDialog}
                                />
                            }
                        </>
                    }

                    <PerformanceReviewOverallCard
                        overallPerformance={generateOverallPerformance(performanceReviews)}
                    />

                    {Object.values(bannersItems).map((x, index) => {
                        return (
                            <SectionBanner
                                key={'overview-section-banner' + index}
                                sectionTitle={x.sectionTitle}
                                bannerText={x.bannerText}
                                bannerTextAdditionalInfo={x.bannerTextAdditionalInfo}
                                buttonText={x.buttonText}
                                buttonProps={x.buttonProps}
                            />
                        );
                    })}
                </div>

                <EditStatusDialog
                    isOpen={isEditStatusDialogOpen}
                    handleCloseDialog={closeEditStatusDialog}
                    selectedEmployee={selectedEmployee}
                    handleEditStatus={handleEditStatus}
                />
            </>
    )
}

const mapStateToProps = (state) => ({
    agencyRouteName: getAgencyRouteName(state),
    isAdminMode: isAdminMode(state),
    employmentInformation: getEmploymentInformation(state),
    performanceReviews: getPerformanceReviews(state),
    employeeOverviewInformation: getEmployeeOverviewInformation(state),
    dataFetchState: getEmployeeOverviewFetchState(state),
});

const mapDispatchToProps = {
    updateEmployeeOccupationStatus,
    fetchEmployeeOverviewInformation,
    employeeOverviewFetchingSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
