import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";

import moment from 'moment';

import { ReactComponent as ExportIcon } from 'assets/export-button-icon.svg';
import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import { usePreloadTable } from 'utils/hooks';
import { getStateAgencyName } from 'utils/helpers';

import { setPersonalInfoInitialState, updateEmployeeOccupationStatus } from 'actions/employeeActions';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import EditSharpIcon from '@material-ui/icons/EditSharp';

import EditStatusDialog from 'components/Employees/EditStatusDialog';
import TableWrapper from 'components/Shared/TableWrapper/TableWrapper';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import {
    EMPLOYEE_OCCUPATION_STATUSES_MAP,
    EMPLOYEE_OCCUPATION_STATUSES,
} from 'constants/employeeConstants';
import {
    TEAM_TABLE_INITIAL_QUERY,
    TEAM_SORT_QUERY_SELECTOR,
    TEAM_PAGE_QUERY_SELECTOR,
    TEAM_SEARCH_QUERY_SELECTOR,
    TEAM_TABLE_QUERY_MAP,
    EMPLOYMENT_TYPES_TO_CLASS_NAME_MAP
} from './EmployeesTableConstants';
import { ROLES } from 'constants/userConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';
import { DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY } from 'constants/candidateConstants';

import './EmployeesTable.scss';

const EmployeesTable = ({
    agencyId,
    handleOpenAddMemberDialog,
    removeEmployeesClickHandler,
    handleExportCV,
    dataSelector,
    updateEmployeeOccupationStatus,
    exportAdminTeam,
    isSupplierAdminMode,
    tableColumns,
    defaultTableColumns,
    legendItems,
    fetchPage,
    reload,
    setReload,
}) => {
    const history = useHistory();

    const [isTeamTableLoading, totalCount] = usePreloadTable(TEAM_TABLE_INITIAL_QUERY, TEAM_TABLE_QUERY_MAP, [], fetchPage, reload, setReload);

    const [selectedEmployee, setSelectedEmployee] = useState({});

    const generateToString = (user) => {
        if (isSupplierAdminMode) {
            return `/${getStateAgencyName()}/team/people/${user._id}/personal-information`;
        } else {
            if (user.role === ROLES.SUPPLIER_ADMIN || user.role === ROLES.SUPPLIER_EMPLOYEE) {
                return `/${getStateAgencyName()}/partners/${user.supplierId || user._id}/team/people/${user._id}`;
            }

            return `/${getStateAgencyName()}/team/people/${user._id}`;
        }
    };

    //Action functions
    const handleExportEmployeeCV = (employee) => {
        handleExportCV(employee);
    }

    const handleRemoveEmployee = (employee) => {
        removeEmployeesClickHandler(employee);
    }

    const handleFindMatch = (employee) => {
        history.push(`/${getStateAgencyName()}/profile/${employee._id}/matching-jobs${DEFAULT_PROFILE_MATCHING_JOB_FILTER_QUERY}`)
    }

    //Edit Status Dialog
    const [isEditStatusDialogOpen, setIsEditStatusDialogOpen] = useState(false);

    const handleCloseDialog = () => {
        setSelectedEmployee({});
        setIsEditStatusDialogOpen(false);
    };
    const handleEditStatusClick = (employee) => {
        setSelectedEmployee(employee);
        setIsEditStatusDialogOpen(true);
    };

    const handleEditStatus = (employeeId, data) => {
        updateEmployeeOccupationStatus(agencyId, employeeId, data);
    };

    //Table filters
    const handleExportAdminTeam = () => exportAdminTeam(agencyId);

    //Table data mapping
    const adjustEmployeeKeysMap = (data) => {
        return data.map(employee => {
            const experienceSince = moment.utc(employee.employmentInformation?.generalInfo?.experienceSince);
            const currentDate = moment.utc(new Date());
            const experience = moment.duration(currentDate.diff(experienceSince));
            const yearsOfExperience = experience._data.years;
            const monthsOfExperience = experience._data.months;
            const projectEndDate = employee.projectEndDate
                ? moment(employee.projectEndDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)
                : null;

            const employmentType = employee.employmentInformation?.contractInformation?.employmentType;
            const position = employee.employmentInformation?.contractInformation?.position;

            const contractType = (employee.role === ROLES.SUPPLIER_ADMIN || employee.role === ROLES.SUPPLIER_EMPLOYEE) && !isSupplierAdminMode ? 'contractor' : employmentType;
            const toString = generateToString(employee);

            return {
                _id: employee._id,
                firstName: employee.firstName,
                lastName: employee.lastName,
                fullName: (
                    <Link className="user-link" to={toString}>
                        <div className="logo-name-wrapper">
                            <Avatar alt="Emplyoee Avatar" className={`avatar-logo-image  ${EMPLOYMENT_TYPES_TO_CLASS_NAME_MAP[contractType] || contractType}`} src={employee.profilePictureImgUrl} />
                            <div className="name-text">
                                {employee.contactPerson
                                    ? `${employee.contactPerson}`
                                    : `${employee.firstName} ${employee.lastName}`}
                            </div>
                        </div>
                    </Link>
                ),
                position: position,
                projectNames: (
                    <div className="project-name-wrapper">
                        {employee.projectNames?.join('; ') || '-'}
                    </div>
                ),
                occupationStatus: (
                    <div className="status-cell">
                        <div className={employee.occupationStatus
                            ? EMPLOYEE_OCCUPATION_STATUSES_MAP[employee.occupationStatus]?.replace(/\s/g, '')
                            : 'empty'
                        }>
                            {EMPLOYEE_OCCUPATION_STATUSES_MAP[employee.occupationStatus]}
                            {
                                EMPLOYEE_OCCUPATION_STATUSES_MAP[employee.occupationStatus] === EMPLOYEE_OCCUPATION_STATUSES.PROJECT_ENDING
                                && projectEndDate
                                && <p>({projectEndDate})</p>
                            }
                        </div>
                        <IconButton className="edit-status-button" onClick={() => handleEditStatusClick(employee)}>
                            <Icon>
                                <EditSharpIcon className="edit-sharp-icon" />
                            </Icon>
                        </IconButton>
                    </div>
                ),
                experience: (
                    <span className="experience-since">
                        {yearsOfExperience > 0 && <span>{yearsOfExperience} year(s) </span>}
                        {monthsOfExperience > 0 && <span>{monthsOfExperience} month(s)</span>}
                    </span>
                ),
                supplierId: employee.supplierId,
                role: employee.role,
                item: employee,
            };

        });
    };

    const dataSelectorMapped = (state, page) => dataSelector(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const allActions = [
        { title: 'Find a match', handleClick: handleFindMatch },
        { title: 'Download CV', handleClick: handleExportEmployeeCV },
        { title: 'Delete', handleClick: handleRemoveEmployee },
    ];

    const supplierActions = [
        { title: 'Download CV', handleClick: handleExportEmployeeCV },
        { title: 'Delete', handleClick: handleRemoveEmployee },
    ];

    const getPopoverActions = () => {
        if (isSupplierAdminMode) {
            return supplierActions;
        } else {
            return allActions;
        }
    }

    return (
        <div className="employees-table-container">
            <TableWrapper
                title="Team"
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                dataSelector={dataSelectorMapped}
                sortQuerySelector={TEAM_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustEmployeeKeysMap}
                isTableDataLoading={isTeamTableLoading}
                addMargin
                withPagination
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={TEAM_PAGE_QUERY_SELECTOR}
                withLegend
                legendItems={legendItems}
                getActionsHandler={getPopoverActions}
                withActions
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                        <TableWrapper.TableAlignedItems.TableSearch queryParamName={TEAM_SEARCH_QUERY_SELECTOR} />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton text={"Add Profile"} icon={<AddIcon />} handleClick={handleOpenAddMemberDialog} />
                        <TableWrapper.TableAlignedItems.TableActionButton text={"Export"} icon={<ExportIcon />} handleClick={handleExportAdminTeam} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
                {
                    <EditStatusDialog
                        isOpen={isEditStatusDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        selectedEmployee={selectedEmployee}
                        handleEditStatus={handleEditStatus}
                    />
                }
            </TableWrapper>
        </div >
    );
};

const mapDispatchToProps = {
    setPersonalInfoInitialState,
    updateEmployeeOccupationStatus,
};

export default connect(null, mapDispatchToProps)(EmployeesTable);
