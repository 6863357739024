import React from 'react';
import { useHistory } from 'react-router-dom';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import Banner from 'components/Shared/Banner';

import { ReactComponent as ContractorIcon } from 'assets/banner-contractor-jobs-icon.svg';
import { ReactComponent as FulltimeIcon } from 'assets/banner-full-time-jobs-icon.svg';

import { getStateAgencyName } from 'utils/helpers';

import { BANNERS_TITLES } from './ClientJobOpportunitiesBannersConstants';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';
import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';

import { BANNER_TYPES } from 'components/Shared/Banner/BannerConstants';

import './ClientJobOpportunitiesBanners.scss';

const ClientJobOpportunitiesBanners = () => {
    const history = useHistory();
    const pathToJobsDashboard = `/${getStateAgencyName()}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`;

    return (
        <div className="client-job-opportunities-banners-wrapper">
            <div className="client-job-opportunities-banners-container">
                <Banner
                    title={BANNERS_TITLES.CONTRACTOR.TITLE}
                    content="Browse and hire vetted software consultants on flexible terms and with zero upfront costs. We take care of all administrative and legal aspects while you focus on building successful digital products."
                    icon={<ContractorIcon />}
                    type={BANNER_TYPES.VERTICAL}
                    primaryButtonComponent={<SecondaryButton
                        text={BANNERS_TITLES.CONTRACTOR.BUTTON_TEXT}
                        handleClick={() => history.push(pathToJobsDashboard, { jobType: JOB_OPPORTUNITIES_TYPES.CONTRACT })}
                    />}
                />

                <Banner
                    title={BANNERS_TITLES.FULL_TIME.TITLE}
                    content="Grow your in-house team and employ IT professionals internationally without worrying about local legislation and tax implications. Our team will support you during every step of the process, from selecting the right candidate to managing their payroll compliantly."
                    icon={<FulltimeIcon />}
                    primaryButtonComponent={<SecondaryButton
                        text={BANNERS_TITLES.FULL_TIME.BUTTON_TEXT}
                        handleClick={() => history.push(pathToJobsDashboard, { jobType: JOB_OPPORTUNITIES_TYPES.FULL_TIME })}
                    />}
                    type={BANNER_TYPES.VERTICAL}
                />
            </div>
        </div>
    );
};


export default ClientJobOpportunitiesBanners;
