import moment from "moment";
import {
    RECRUITER_PROFILES_FETCH_SUCCESS,
    REGISTER_RECRUITER_EMPLOYEE_SUCCESS,
    RECRUITER_PROFILE_INFO_FETCH_SUCCESS,
    DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS,
    UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS,
} from "actions/actionTypes";
import { TABLE_COLUMN_DIRECTIONS } from "constants/commonConstants";

const initialState = {
    currentProfile: {},
    profiles: []
};

const recruiterProfilesReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECRUITER_PROFILES_FETCH_SUCCESS: return { ...state, profiles: [...action.payload] };
        case RECRUITER_PROFILE_INFO_FETCH_SUCCESS: return { ...state, currentProfile: action.payload };
        case REGISTER_RECRUITER_EMPLOYEE_SUCCESS: return { ...state, profiles: [...state.profiles, action.payload] };
        case DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS: {
            const { profileCV, ...currentProfile } = state;
            return { ...state, currentProfile };
        }
        case UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS: {
            state.currentProfile.profileCV = { cloudName: action.payload };
            return { ...state };
        }
        default: return state;
    }
}

export default recruiterProfilesReducer;

export const getRecruiterProfiles = (state, page, count, sortBy, sortByDir) => {
    const from = page * count;
    const to = page * count + count;

    if (sortBy && sortByDir) {
        state.profiles = state.profiles
            .map(x => ({
                ...x,
                name: `${x.firstName || ''} ${x.lastName || ''}`,
                position: x.employmentInformation?.generalInfo?.position || '',
                experience: x.employmentInformation?.generalInfo?.experienceSince || '',
            })).sort((a, b) => {
                const isAscending = sortByDir === TABLE_COLUMN_DIRECTIONS.ASCENDING;
                const fieldA = a[sortBy];
                const fieldB = b[sortBy];

                if (moment(fieldA).isValid() && moment(fieldB).isValid()) {
                    const momentA = moment(fieldA);
                    const momentB = moment(fieldB);
                    return isAscending ? momentA.diff(momentB) : momentB.diff(momentA);
                }

                return isAscending
                    ? fieldA?.localeCompare(fieldB)
                    : fieldB?.localeCompare(fieldA);
            });
    }

    return {
        recruiterProfiles: state.profiles.slice(from || 0, to || state.profiles.length),
        totalCount: state.profiles.length
    };
}
export const getRecruiterProfile = state => state.currentProfile;
