import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

import CustomCheckbox from 'components/Shared/CustomCheckbox';

import './SelectColumns.scss';

const StyledMenu = ((props) => (
	<Menu
		className="select-menu"
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'left',
		}}
		{...props}
	/>
));

const SelectColumns = ({
	displayColumns,
	setDisplayColumns,
	columnsTitles
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleCheck = (e, key) => setDisplayColumns({ ...displayColumns, [key]: !displayColumns[key] });

	return (
		<div>
			<Button
				className="button"
				variant="outlined"
				size="small"
				onClick={handleClick}
				disableElevation={true}
			>
				Columns
				<ExpandMoreSharpIcon />
			</Button>
			<StyledMenu
				className="styled-menu"
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{columnsTitles.map(columnTitle =>
					<MenuItem className="menu-item" key={columnTitle.column}>
						<CustomCheckbox
							isChecked={displayColumns[columnTitle.column]}
							size="small"
							className="checkbox"
							onChangeHandler={(e) => handleCheck(e, columnTitle.column)}
						/>
						{columnTitle.title}
					</MenuItem>)
				}
			</StyledMenu>
		</div>
	);
};

export default SelectColumns;
