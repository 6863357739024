import { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCandidateApplications } from 'reducers';

import CandidateApplication from './CandidateApplication/CandidateApplication';

import { APPLIED_CANDIDATE_STATUSES } from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';

import './CandidateApplicationsList.scss';

const CandidateApplicationsList = ({
    candidateId,
    selectCandidateStatusTab,
    filters,
    candidateApplications,
    isSearchTermMatch,
    displayMode
}) => {

    useEffect(() => {
        if (isSearchTermMatch && candidateApplications.length <= 0) {
            selectCandidateStatusTab(APPLIED_CANDIDATE_STATUSES.ALL);
        }
    }, [isSearchTermMatch, filters.searchTerm]);

    return (
        candidateApplications.length > 0 &&
        <div className="candidate-applications-list">
            {candidateApplications.map(application =>
                <CandidateApplication
                    key={application.applicationId}
                    candidateId={candidateId}
                    application={application}
                    displayMode={displayMode}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { list: candidateApplications, isSearchTermMatch } = getCandidateApplications(state, ownProps.filters);

    return {
        candidateApplications,
        isSearchTermMatch,
    };
};

export default connect(mapStateToProps)(CandidateApplicationsList);
