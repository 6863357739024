import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Collapse, Divider, List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as TableFilterIcon } from 'assets/table-filter-icon.svg';
import { ReactComponent as CheckIcon } from 'assets/icon-check.svg';

import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import { TableWrapperContext } from '../TableWrapper';

const TableFilter = ({
    filters,
}) => {
    const history = useHistory();

    const { handleFilterChanges, withPagination, pageQuerySelector } = useContext(TableWrapperContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filtersOpenState, setFilterOpenState] = useState(filters.reduce((acc, curr) => {
        acc[curr.name] = false;
        return acc;
    }, {}));
    
    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);
        if (queryParams.filters) {
            setSelectedFilters(queryParams.filters);
        } else {
            setSelectedFilters([]);
        }

        // In case some tables do not have any filters
        Boolean(handleFilterChanges) && handleFilterChanges();
    }, [history.location.search]);
    
    const handleOpenPopover = (event) => setAnchorEl(event.currentTarget);
    const handleClosePopover = () => setAnchorEl(null);
    
    const handleListItemClick = (itemKey) => {
        setFilterOpenState(prevState => ({
            ...prevState,
            [itemKey]: !prevState[itemKey],
        }));
    };

    const handleSubmenuItemClick = (item) => {
        let newFilters = [];

        // If the filter is currently selected, deselect it
        if (selectedFilters.includes(item)) {
            newFilters = selectedFilters.filter(x => x !== item);
        } else {
            newFilters = [...selectedFilters, item];
        }

        const filterObj = { filters: newFilters };

        // if the filters have changed, we need to set the page back to 1
        if (withPagination) {
            filterObj[pageQuerySelector] = 1;
        }

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj);


        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleResetFilters = (event) => {
        event.stopPropagation();

        // In the query there might be filters from other tables, so we need to preserve them
        const allTableFilterValues = filters.map(x => x.value).flat(2);
        const otherTablesFilters = parseQueryString(history.location.search, true)?.filters?.filter(filter => !allTableFilterValues.includes(filter)) || [];

        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, { filters: otherTablesFilters });
        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <Fragment>
            <div className={`table-filter${Boolean(anchorEl) || selectedFilters.length ? ' selected' : ''}`} onClick={handleOpenPopover}>
                <TableFilterIcon />
                <Typography variant="body1">Filters</Typography>
                { selectedFilters.length > 0 && <CloseIcon onClick={handleResetFilters}/> }
            </div>

            <Popover
                className="global-table-filter-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List className="table-filter-list">
                    {filters.map((filterObj, idx) => (
                        <Fragment key={idx}>
                            <ListItem button disableRipple className="popover-list-item" key={filterObj.name}>
                                <div className="table-filter-category" onClick={() => handleListItemClick(filterObj.name)}>
                                    <ListItemText className={filtersOpenState[filterObj.name] ? 'bold-text' : ''} primary={filterObj.name} />
                                    {filtersOpenState[filterObj.name] ? <ExpandLess /> : <ExpandMore />}
                                </div>
                                <Collapse in={filtersOpenState[filterObj.name]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {filterObj.value.map(submenuItem => (
                                            <ListItem button className="table-filter-item" key={submenuItem} onClick={() => handleSubmenuItemClick(submenuItem)}>
                                                <ListItemText primary={submenuItem} />
                                                { selectedFilters.includes(submenuItem) && <CheckIcon className="check-icon" />}
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem>
                            { idx !== filters.length - 1 && <Divider variant="middle" className="table-filters-divider"/> }
                        </Fragment>
                    ))}
                </List>
            </Popover>
        </Fragment>
    )
};

export default TableFilter;
