import React, { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { getStateAgencyName } from 'utils/helpers';
export const ShorcutsContext = createContext();

const { Provider } = ShorcutsContext;

const Shortcuts = ({
    children
}) => {
    const history = useHistory();
    const handleClick = (path) => {
        history.push(path)
    }

    const context = { handleClick };

    return (
        <Provider value={context}>
            <div className="btns-wrapper">
                {children}
            </div>
        </Provider>
    )
};

const RequestHolidayButton = () => {
    const { handleClick } = useContext(ShorcutsContext);

    return <PrimaryButton text="Request Holiday" handleClick={() => handleClick(`/${getStateAgencyName()}/holidays`)} />
}

const TrackTimeButton = () => {
    const { handleClick } = useContext(ShorcutsContext);

    return <PrimaryButton text="Track Time" handleClick={() => handleClick(`/${getStateAgencyName()}/time-tracking`)} />
}

Shortcuts.RequestHoliday = RequestHolidayButton;
Shortcuts.TrackTime = TrackTimeButton;

export default Shortcuts;
