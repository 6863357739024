import {
    CONTRACTS_FETCH_SUCCESS,
    CONTRACTS_REPLACE_SUCCESS,
    CONTRACT_ADD_SUCCESS,
    CONTRACT_DELETE_SUCCESS,
    CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    CONTRACT_UPDATE_SUCCESS,
    UPDATE_PENDING_CONTRACT_STATUS_SUCCESS,
} from 'actions/actionTypes';

const contracts = (state = [], action) => {
    switch (action.type) {
        case CONTRACTS_FETCH_SUCCESS:
            return action.payload ? [...state, ...action.payload] : [];
        case CONTRACT_ADD_SUCCESS:
            return [action.payload.contract, ...state];
        case CONTRACT_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload);
        case CLIENT_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return [];
        case CONTRACTS_REPLACE_SUCCESS:
            return [...action.payload];
        case CONTRACT_UPDATE_SUCCESS:
            return [...state.map(contract => contract._id === action.payload.contract._id ? action.payload.contract : contract)];
        case UPDATE_PENDING_CONTRACT_STATUS_SUCCESS:
            return [...state.map(contract => contract.signatureStatus === 'Pending' ? { ...contract, signatureStatus: action.payload } : contract)];
        default:
            return state;
    }
};

export default contracts;


export const getAllContracts = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.slice(from, to || state.length)
};
