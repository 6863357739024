import { combineReducers } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
    SUPPLIER_REGISTER_FETCH_SUCCESS,
    SUPPLIER_FETCH_ALL_SUCCESS,
    SUPPLIER_REPLACE_ALL_SUCCESS,
    SUPPLIER_INFORMATION_FETCH_SUCCESS,
    SUPPLIER_AVATAR_UPDATE_SUCCESS,
    SUPPLIER_AVATAR_DELETE_SUCCESS,
    SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS,
    SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS,
    SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    USERS_DISABLE_SUCCESS,
    SUPPLIER_TASKS_FETCH_SUCCESS,
    SUPPLIER_TASK_CREATE_SUCCESS,
    SUPPLIER_TASK_EDIT_SUCCESS,
    SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS,
    AGENCY_TASK_DELETE_SUCCESS,
    SUPPLIER_EMPLOYEE_FETCH_SUCCESS,
    SUPPLIER_UPDATE_FLAG_SUCCESS,
    SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS,
    SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
    USERS_RESTORE_SUCCESS,
} from 'actions/actionTypes';

const suppliersInitialState = [];

const suppliers = (state = suppliersInitialState, action) => {
    switch (action.type) {
        case SUPPLIER_FETCH_ALL_SUCCESS:
            return [...state, ...action.payload];
        case SUPPLIER_REPLACE_ALL_SUCCESS:
            return action.payload || suppliersInitialState;
        case USERS_DISABLE_SUCCESS:
            return state.filter(x => !action.payload.includes(x._id));
        case SUPPLIER_REGISTER_FETCH_SUCCESS:
            return [...[action.payload], ...state];
        case SUPPLIER_UPDATE_FLAG_SUCCESS:
            return state.map(x => {
                if (x._id === action.payload._id) {
                    x.flag = action.payload.flag;
                }

                return x;
            });
        default:
            return state;
    }
};

const supplierTasksInitialState = [];

const supplierTasks = (state = supplierTasksInitialState, action = {}) => {
    switch (action.type) {
        case SUPPLIER_TASKS_FETCH_SUCCESS:
            return action.payload || supplierTasksInitialState;
        case SUPPLIER_TASK_CREATE_SUCCESS:
            return [...state, action.payload]
        case SUPPLIER_TASK_EDIT_SUCCESS:
            return state.map(task => {
                if (task._id === action.payload._id) {
                    return action.payload
                }
                return task
            })
        case AGENCY_TASK_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload)
        default:
            return state;
    }
};

const informationInitialState = {
    basic: {
        name: '',
        country: '',
        email: '',
    },
    contact: {
        contactPerson: '',
        contactEmail: '',
        contactNumber: ''
    },
    invoicing: {
        currency: ''
    },
    logoImgUrl: '',
    logoImgName: '',
    logoImgDetails: {
        width: 0,
        height: 0,
        size: 0,
        sizeBits: 'B',
        extension: '',
    },
    tasks: supplierTasks(),
    accountManager: {
        name: '',
        email: '',
        phone: '',
        _id: '',
        profilePictureImgUrl: '',
        contactNumber: '',
    },
    invoiceStatus: '',
};

const information = (state = informationInitialState, action) => {
    switch (action.type) {
        case SUPPLIER_INFORMATION_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case SUPPLIER_BASIC_INFORMATION_UPDATE_SUCCESS:
        case SUPPLIER_CONTACT_INFORMATION_UPDATE_SUCCESS:
        case SUPPLIER_INVOICE_INFORMATION_UPDATE_SUCCESS:
        case SUPPLIER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS:
        case SUPPLIER_AVATAR_UPDATE_SUCCESS:
        case SUPPLIER_ACCOUNT_MANAGER_INFORMATION_FETCH_SUCCESS:
            return { ...state, ...action.payload };
        case AGENCY_SUPPLIER_HOME_INFORMATION_FETCH_SUCCESS:
            return {
                ...state,
                logoImgUrl: action.payload.logoImgUrl || '',
                basic: { ...state.basic, name: action.payload.name },
                contact: { ...state.contact, contactPerson: action.payload.contactPerson },
            };
        case SUPPLIER_AVATAR_DELETE_SUCCESS:
            return {
                ...state,
                logoImgUrl: '',
                logoImgName: '',
                logoImgDetails: {
                    width: 0,
                    height: 0,
                    size: 0,
                    sizeBits: 'B',
                    extension: ''
                }
            };
        case SUPPLIER_TASKS_FETCH_SUCCESS:
        case SUPPLIER_TASK_CREATE_SUCCESS:
        case SUPPLIER_TASK_EDIT_SUCCESS:
        case AGENCY_TASK_DELETE_SUCCESS:
            return {
                ...state,
                tasks: supplierTasks(state.tasks, action)
            };
        case SUPPLIER_PROFILE_PAGE_UNMOUNTED_SUCCESS:
            return informationInitialState;
        case USERS_RESTORE_SUCCESS:
            return { ...state, isDeleted: false }
        default:
            return state;
    }
};

const supplierEmployeesInitialState = {
    currentEmployee: {},
};

const supplierEmployees = (state = supplierEmployeesInitialState, action) => {
    switch (action.type) {
        case SUPPLIER_EMPLOYEE_FETCH_SUCCESS:
            return { ...state, currentEmployee: action.payload }
        default:
            return state;
    }
};

const accountManagers = (state = [], action) => {
    switch (action.type) {
        case SUPPLIERS_ACCOUNT_MANAGERS_FETCH_SUCCESS:
            return action.payload;
        default:
            return state;
    }
};

const supplier = combineReducers({
    suppliers,
    information,
    supplierEmployees,
    accountManagers,
});

export default supplier;

export const getSuppliers = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.suppliers.slice(from, to || state.suppliers.length);
};

export const getSuppliersAccountManagers = state => state.accountManagers;

export const getSupplierBasicInformation = state => ({
    name: state.information.basic.name,
    country: state.information.basic.country,
    email: state.information.basic.email,
    logoImgUrl: state.information.logoImgUrl,
    logoImgDetails: state.information.logoImgDetails,
});

export const getSupplierContactInformation = state => state.information.contact;
export const getSupplierInvoicingInformation = state => state.information.invoicing;
export const getSupplierCurrency = state => state.information.invoicing.currency;

const supplierNameSelector = (state) => state.information.basic.name;
const supplierContactPersonSelector = (state) => state.information.contact.contactPerson;
const supplierLogoSelector = (state) => state.information.logoImgUrl;
export const getSupplierMenuInformation = createStructuredSelector({
    name: supplierNameSelector,
    contactPerson: supplierContactPersonSelector,
    logoImgUrl: supplierLogoSelector,
});

export const getSupplierAccountManager = state => state.information.accountManager;
export const getSelectedSupplier = (state, supplierId) => {
    const { name } = state.suppliers.find(x => x._id === supplierId) || getSupplierBasicInformation(state) || { name: '' };
    return {
        _id: supplierId,
        name,
    }
};
export const getSupplierTasks = state => state.information.tasks;
export const getCurrentEmployee = state => state.supplierEmployees.currentEmployee;
export const getSupplierInvoiceStatus = state => state.information.invoiceStatus;
export const getSupplierIsDeleted = state => state.information.isDeleted;
