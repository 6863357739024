import React from 'react';

import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { profileTypes } from './ProfileGeneralFormConstants';

import isHoliday from 'hoc/isHoliday';

import './ProfileGeneralForm.scss';
import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';
import { DIALOG_COMMENT_INPUT } from 'constants/commonConstants';

const MAX_LENGTH_PROFILE_SUMMARY = 500;

const ProfileGeneralFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleShowHeaderButtons,
    setFieldValue,
}) => (
    <form className="profile-general-form" autoComplete="off">
        <div className="top-inputs-block">
            <div className="profile-info-form-field top-field top-left-field">
                <TextField
                    name="position"
                    value={values.position}
                    onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                    id="profile-general-position"
                    label="Current/Previous Position"
                    error={errors.position && touched.position}
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="position" component="div" className="invalid-field-message" />
            </div>
            <div className="profile-info-form-field top-field">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk={true}
                        className="start-date-field"
                        label="Experience Since"
                        name="experienceSince"
                        disableToolbar
                        disableFuture
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={values.experienceSince}
                        onChange={value => {
                            setFieldValue('experienceSince', moment(value));
                            handleShowHeaderButtons();
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </div>

        <div className="profile-info-form-field profile-type-field">
            <TextField
                select
                className='profile-type-dropdown'
                name="profileType"
                variant="outlined"
                label="Profile Type"
                onChange={(e) => {
                    handleChange(e);
                    handleShowHeaderButtons(true);
                }}
                value={values.profileType}
                error={errors.profileType && touched.profileType}>
                {
                    profileTypes.map((x, i) => (
                        <MenuItem key={i} value={x}>
                            {x}
                        </MenuItem>
                    ))
                }
            </TextField>
            <ErrorMessage name="profileType" component="div" className="invalid-field-message" />
        </div>
        
        <div className="profile-info-form-field last">
            <TextField
                name="profileSummary"
                value={values.profileSummary}
                onChange={(e) => { handleChange(e); handleShowHeaderButtons(); }}
                id="profile-summary"
                label="Profile Summary"
                error={errors.profileSummary && touched.profileSummary}
                variant="outlined"
                fullWidth
                multiline
                minRows={DIALOG_COMMENT_INPUT.ROWS}
                inputProps={{
                    maxLength: MAX_LENGTH_PROFILE_SUMMARY
                }}
            />
            <ErrorMessage name="profileSummary" component="div" className="invalid-field-message" />
        </div>
    </form>
);

export default ProfileGeneralFormView;
