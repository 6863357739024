const {
    CLIENT_USERS_FETCH_SUCCESS,
    CLIENT_USERS_REPLACE_SUCCESS,
    CLIENT_REGISTER_FETCH_SUCCESS,
    USER_UPDATE_SUCCESS,
    USERS_DISABLE_SUCCESS,
} = require("actions/actionTypes");

const clientUsersInitialState = [];

const clientUsers = (state = clientUsersInitialState, action = {}) => {
    switch (action.type) {
        case CLIENT_USERS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case CLIENT_USERS_REPLACE_SUCCESS:
            return action.payload;
        case CLIENT_REGISTER_FETCH_SUCCESS:
            return [...state, action.payload];
        case USER_UPDATE_SUCCESS:
            return [...state].map((user => {
                if (user._id === action.payload._id) {
                    return action.payload;
                } else {
                    return user;
                }
            }));
        case USERS_DISABLE_SUCCESS:
            return [...state].filter(x => !action.payload.includes(x._id));
        default:
            return clientUsersInitialState;
    }
};

export default clientUsers;
