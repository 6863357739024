import React from 'react';
import DateWithInterviews from './DateWithInterviews'

const isDateWithInterviews = (
    interviewsData,
    day,
    selectedDate,
    isInCurrentMonth,
    dayComponent,
) => {
    return <DateWithInterviews
        day={day}
        isInCurrentMonth={isInCurrentMonth}
        dayComponent={dayComponent}
        selectedDate={selectedDate}
        interviewsData={interviewsData}
    />;
}

export default isDateWithInterviews;
