import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import EditSharpIcon from '@material-ui/icons/EditSharp';

import ActiveInvoicingDialogContent from './ActiveInvoicingDialogContent';

import { activeInvoicingFlags, defaultActiveInvoicingFlag } from '../ClientsConstants';


import './ActiveInvoicingFlags.scss';

const ActiveInvoicingFilters = ({
    flag,
    agencyId,
    userId,
    updateFlag
}) => {
    const currentFlag = activeInvoicingFlags.find(x => x.name === flag) || defaultActiveInvoicingFlag;

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState(currentFlag);

    const handleFlagUpdate = () => {
        handleCloseDialog();
        updateFlag(agencyId, userId, selectedFlag.name);
    };

    const handleFlagSelect = (e) => {
        const newFlag = activeInvoicingFlags.find(x => x.value === e.target.value);
        setSelectedFlag(newFlag);
    }

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <>
            <div className="flag-container">
                <p>{currentFlag.name}</p>
                <div className="flag-edit-icon-container">
                    <IconButton className="flag-edit-button" onClick={() => handleOpenDialog()}>
                        <Icon>
                            <EditSharpIcon className="edit-sharp-icon" />
                        </Icon>
                    </IconButton>
                </div>
            </div>
            <Dialog className="change-status-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth='md'>
                <MuiDialogTitle disableTypography className="dialog-title">
                    <Typography variant="body2" className="dialog-title-text">Change Status</Typography>
                    <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent className="dialog-content">
                    <ActiveInvoicingDialogContent
                        selectedFlag={selectedFlag}
                        handleFlagSelect={handleFlagSelect}
                        handleCloseDialog={handleCloseDialog}
                        handleFlagUpdate={handleFlagUpdate}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

export default connect(mapStateToProps, null)(ActiveInvoicingFilters);
