import { EXPLORE_TALENTS_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import exploreTalentsService from 'services/exploreTalentsService';
import { authorizedShowNotification } from './actionHelpers';
import {
    FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS,
    FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS,
    EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS,
    EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS,
} from './actionTypes';
import { adjustFilters } from 'components/ExploreTalents/ExploreTalentsUtils.js';

const fetchExploreTalentsProfilesSuccess = (data) => ({
    type: FETCH_EXPLORE_TALENTS_PROFILES_SUCCESS,
    payload: data,
});

const fetchMoreExploreTalentsProfilesSuccess = (data) => ({
    type: FETCH_MORE_EXPLORE_TALENTS_PROFILES_SUCCESS,
    payload: data,
});

const exploreTalentsProfilesDataFetchSuccess = () => ({
    type: EXPLORE_TALENTS_PROFILES_DATA_FETCH_SUCCESS,
});

const exploreTalentsProfilesDataFetchingSuccess = () => ({
    type: EXPLORE_TALENTS_PROFILES_DATA_FETCHING_SUCCESS,
});

export const fetchExploreTalentsProfiles = (agencyId, filters, page) => async (dispatch) => {
    try {
        dispatch(exploreTalentsProfilesDataFetchingSuccess());
        const adjustedFilters = adjustFilters(filters);

        const result = await exploreTalentsService.fetchExploreTalentsProfiles(agencyId, adjustedFilters, page);

        dispatch(fetchExploreTalentsProfilesSuccess({ profiles: result.talents, totalTalentsCount: result.totalTalentsCount }));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EXPLORE_TALENTS_MESSAGES.FETCH_EXPLORE_TALENTS_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(exploreTalentsProfilesDataFetchSuccess());
    }
};

export const fetchMoreExploreTalentsProfiles = (agencyId, filters, page) => async (dispatch) => {
    try {
        const adjustedFilters = adjustFilters(filters);

        const result = await exploreTalentsService.fetchExploreTalentsProfiles(agencyId, adjustedFilters, page);

        dispatch(fetchMoreExploreTalentsProfilesSuccess({ profiles: result.talents, totalTalentsCount: result.totalTalentsCount }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EXPLORE_TALENTS_MESSAGES.FETCH_EXPLORE_TALENTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
