import React from 'react';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ErrorMessage } from 'formik';

import SecondaryButton from '../Buttons/SecondaryButton';

import isHoliday from 'hoc/isHoliday';

import { EMPLOYMENT_TYPES } from 'constants/employeeConstants'
import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

const SelectEmploymentOptionsDialogForm = ({
    infoText,
    values,
    handleCloseDialog,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
}) => {
    return (
        <form className="select-employment-options-form" autoComplete="off">
            <div>
                {infoText && 
                    <div className="helper-text-wrapper">
                        <span>
                            {infoText}
                        </span>
                    </div>
                }
                <TextField
                    required
                    select
                    name="employmentType"
                    value={values.employmentType}
                    onChange={e => { setFieldValue('employmentType', e.target.value) }}
                    label="Employment Type"
                    error={errors.employmentType && touched.employmentType}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {Object.values(EMPLOYMENT_TYPES).map((type, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={type}
                        >
                            {type}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="employmentType" component="div" className="invalid-field-message" />
            </div>

            <div>
                <div className="helper-text-wrapper">
                    <span>
                        The start date you enter will determine the number of paid holidays the full-time employee will receive.
                    </span>
                </div>
                <KeyboardDatePicker
                    autoOk
                    required
                    label="Start Date"
                    name="startDate"
                    variant="inline"
                    disableToolbar
                    format={DATE_PICKER_FORMAT}
                    openTo="date"
                    inputVariant="outlined"
                    value={values.startDate}
                    onChange={value => {
                        setFieldValue('startDate', moment(value));
                    }}
                    fullWidth
                    renderDay={isHoliday}
                />
                <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
            </div>

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={() => handleCloseDialog()} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Submit" type="submit" />
            </DialogActions>
        </form>
    )
};

export default SelectEmploymentOptionsDialogForm;
