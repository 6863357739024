import { getHomePathDetails } from 'utils/helpers';
import { ROLES, ROLES_TO_ROUTE_MAPPER } from 'constants/userConstants';

const renameMainItemAction = (menuItem, newMenuItemTitle) => {
    return { ...menuItem, TITLE: newMenuItemTitle };
};

const hideSubmenuItemsAction = (menuItem, subItemsTitles) => {
    const newItems = menuItem.ITEMS.filter(subitem => !subItemsTitles.includes(subitem.TEXT));
    return { ...menuItem, ITEMS: newItems };
};

export const getHomePath = (gotoUrl) => {
    const { agencyName, role, termsAndConditions, onboarded, ...rest } = getHomePathDetails();

    if (!termsAndConditions && ![ROLES.UNASSIGNED, ROLES.UNASSIGNED_CLIENT, ROLES.UNASSIGNED_ADMIN].includes(role)) {
        return { pathname: `/${agencyName}/terms-and-conditions-${ROLES_TO_ROUTE_MAPPER[role]}`, state: { gotoUrl: gotoUrl } };
    }

    if (role === ROLES.CANDIDATE && rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/job-opportunities`, state: { gotoUrl: gotoUrl } };
    }

    if (role === ROLES.CANDIDATE && !rest.isJourneyStarted && !rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/start-journey` };
    }

    if (role === ROLES.CANDIDATE && !rest.isInitialJourneyCompleted) {
        return { pathname: `/${agencyName}/create-profile` };
    }

    if (role === ROLES.UNASSIGNED) {
        return { pathname: `/select-a-role` };
    }

    if (role === ROLES.UNASSIGNED_CLIENT || role === ROLES.UNASSIGNED_ADMIN) {
        return { pathname: `/select-a-role/client` };
    }

    if (gotoUrl && gotoUrl.includes(agencyName)) {
        return { pathname: gotoUrl, state: { gotoUrl: gotoUrl } }
    }

    switch (role) {
        case ROLES.CANDIDATE:
            return { pathname: `/${agencyName}/job-opportunities` };
        case ROLES.RECRUITER:
            return { pathname: `/${agencyName}/recruiter-profiles` };
        default:
            return { pathname: `/${agencyName}/dashboard` };
    }
};

export const filterMenuItems = (menuItems, hiddenMenuItems) => {
    const newMenuItems = menuItems.reduce((acc, menuItem) => {
        const hiddenItem = hiddenMenuItems.find(element => element.menuItemTitle === menuItem.TITLE || element.menuItemTitle === menuItem.text);

        if (hiddenItem) {
            switch (hiddenItem.action) {
                case 'hide-all':
                    break;
                case 'hide-subitems':
                    acc.push(hideSubmenuItemsAction(menuItem, hiddenItem.subItemsTitles));
                    break;
                case 'rename-mainitem':
                    acc.push(renameMainItemAction(menuItem, hiddenItem.newMenuItemTitle));
                    break;
                default:
                    break;
            }
        } else {
            acc.push(menuItem);
        }

        return acc;
    }, []);

    return newMenuItems;
}

