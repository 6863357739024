import React from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { Formik, ErrorMessage } from 'formik';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateRequiredFields } from 'utils/formValidations';

import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

const DeleteEmployeeDialog = ({
	isOpen,
	handleCloseDialog,
	handleDelete,
	employee,
	type,
}) => {
	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Formik
				initialValues={{ employeeId: employee._id, dateDeleted: moment.utc().toDate() }}
				validate={(values) => ({
					...validateRequiredFields(values, ['dateDeleted']),
				})}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(false);
					handleDelete(values, type);
				}}
				enableReinitialize={true}
			>
				{({ values, setFieldValue, handleSubmit }) => (
					<CommonFormikDialog
						open={isOpen}
						onClose={handleCloseDialog}
						maxWidth={"xs"}
						fullWidth={true}
						onSave={handleSubmit}
						dialogTitle='Delete Employee'
					>
						<div className="date-deleted-field-wrapper">
							<KeyboardDatePicker
								className="date-deleted-field"
								autoOk
								required
								label="Last Active Date"
								name="dateDeleted"
								variant="inline"
								disableToolbar
								format={DATE_PICKER_FORMAT}
								openTo="date"
								inputVariant="outlined"
								value={values.dateDeleted}
								onChange={value => {
									setFieldValue('dateDeleted', moment(value));
								}}
								fullWidth
							/>
							<ErrorMessage name="dateDeleted" component="div" className="invalid-field-message" />
						</div>
					</CommonFormikDialog>
				)}
			</Formik>
		</MuiPickersUtilsProvider>
	);
};

export default DeleteEmployeeDialog;
