import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getImportantDocuments } from 'reducers';
import { downloadImportantDocuments, fetchAgencyImportantDocuments } from 'actions/agencyActions';

import TableWrapper from 'components/Shared/TableWrapper';

import {
    IMPORTANT_DOCUMENTS_TABLE_COLUMNS,
    DEFAULT_IMPORTANT_DOCUMENTS_TABLE_COLUMNS,
    IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR,
    IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP,
} from './ImportantDocumentsConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { usePreloadTable } from 'utils/hooks';
import { concatName } from 'utils/helpers';

const ImportantDocuments = ({
    agencyId,
    fetchAgencyImportantDocuments,
    downloadImportantDocuments,
}) => {
    const tableFetchAction = fetchAgencyImportantDocuments.bind(null, agencyId);
    const dataSelector = (state, page) => getImportantDocuments(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction);

    const handleDownloadClick = document => {
        if (document.files.length > 0) {
            const fileName = document.files.length > 1
                ? `${concatName(document.title)}`
                : `${concatName(document.files[0].originalFileName)}`;

            downloadImportantDocuments(agencyId, document._id, fileName.toLocaleLowerCase());
        }
    };

    const adjustImportantDocumentsKeysFunction = (stateData) => stateData.map(document => {
        return {
            title: document.title,
            uploadedOn: document.uploadedOn ? moment.utc(document.uploadedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR) : '-',
            item: document,
        }
    });

    const actions = [
        { title: 'Download', handleClick: handleDownloadClick },
    ];

    const getPopoverActions = () => actions;

    return (
        <div className='important-documents-table-wrapper'>
            <TableWrapper
                title='Important Documents'
                columns={IMPORTANT_DOCUMENTS_TABLE_COLUMNS}
                defaultColumns={DEFAULT_IMPORTANT_DOCUMENTS_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustImportantDocumentsKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverActions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>
        </div>
    );
};

const mapDispatchToProps = {
    fetchAgencyImportantDocuments,
    downloadImportantDocuments
};

export default connect(null, mapDispatchToProps)(ImportantDocuments);
