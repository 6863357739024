import React from 'react';

import { DEVELOPER } from './CandidatesTableConstants';

const CandidateSpeciality = ({
    preferences
}) => {
    return (
    <div>
        {preferences.jobType === DEVELOPER
            ? <p>{preferences.technologies?.map(x => x.title).join(', ')}</p>
            : preferences.jobType || '-'}
    </div>
) 
};

export default CandidateSpeciality;
