import React from 'react';
import moment from 'moment';
import { ErrorMessage } from 'formik';

import Switcher from 'components/Shared/Switcher';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';
import { MIN_DATE } from 'constants/commonConstants';

const EducationDialogView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
}) => {
    return (
        <form className="education-dialog-view form" autoComplete="off">
            <div className="education-title-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="title"
                    label="Degree"
                    value={values.title}
                    onChange={handleChange}
                    error={errors.title && touched.title}
                />
                <ErrorMessage className="invalid-field-message" name="title" component="div" />
            </div>
            <div className="education-institution-field">
                <TextField
                    fullWidth
                    variant="outlined"
                    name="institution"
                    label="Institution"
                    value={values.institution}
                    onChange={handleChange}
                    error={errors.institution && touched.institution}
                />
                <ErrorMessage className="invalid-field-message" name="institution" component="div" />
            </div>

            <div className="education-dates-information-wrapper">
                <div className="education-dates-options">
                    <Switcher
                        label="Currently study"
                        initialValue={values.currentlyStudy}
                        isChecked={values.currentlyStudy}
                        changeStatusHandler={(event) => setFieldValue('currentlyStudy', event.target.checked)}
                    />
                    <Switcher
                        label="Dates unknown"
                        initialValue={values.datesUnknown}
                        isChecked={values.datesUnknown}
                        changeStatusHandler={(event) => setFieldValue('datesUnknown', event.target.checked)}
                    />
                </div>
                <div className="education-start-date-field">
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        allowKeyboardControl
                        variant="inline"
                        inputVariant="outlined"
                        openTo="year"
                        views={["year", "month"]}
                        label="Start Date"
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        disabled={values.datesUnknown}
                        value={
                            values.datesUnknown
                                ? null
                                : values.startDate
                        }
                        onChange={value => setFieldValue('startDate', moment(value))}
                    />
                </div>
                <div className="education-end-date-field">
                    <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        allowKeyboardControl
                        variant="inline"
                        inputVariant="outlined"
                        openTo="year"
                        views={["year", "month"]}
                        label="End Date"
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        disabled={values.datesUnknown || values.currentlyStudy}
                        minDate={values.startDate || MIN_DATE}
                        value={
                            (values.datesUnknown || values.currentlyStudy)
                                ? null
                                : values.endDate
                        }
                        onChange={value => setFieldValue('endDate', moment(value))}
                    />
                </div>
            </div>
        </form>
    );
};

export default EducationDialogView;
