import React from 'react';
import moment from 'moment';

import { Formik } from 'formik';
import TrackTimeDialogFormView from './TrackTimeDialogFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { validateDecimalInputPeriod, validateRequiredFields, validateFilesArrayLength } from 'utils/formValidations';

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';
import { DATE_PICKER_FORMAT_WITH_DASHES } from 'constants/timesFormat';
import { SICK_LEAVE } from '../TimeTrackingConstants';

import 'react-day-picker/lib/style.css';
import './TrackTimeDialog.scss';

const TrackTimeDialog = ({
    openDialog,
    selectedEvents,
    trackedDays,
    handleCloseDialog,
    handleTrackTime,
    projects,
    publicHolidays,
    handleOpenUploadFileDialog,
    uploadFilesFormRef,
    isFreelanceEmployee,
    isPartnerProfile
}) => {
    const currentFileName = uploadFilesFormRef.current.values?.filesAttached[0]?.name || '.pdf .jpeg .jpg';
    const selectedDays = selectedEvents.map(event => new Date(moment(event.date, DATE_PICKER_FORMAT_WITH_DASHES)));

    const renderDay = day => {

        const isWeekend = (moment.utc(day).day() === 0 || moment.utc(day).day() === 6);
        const isHoliday = publicHolidays?.includes(moment.utc(day).format(DATE_FORMAT_WITH_DASH));

        if (isWeekend || isHoliday) {
            return <div className="day-wrapper holiday"> {day.getDate()} </div>
        }

        return <div className="day-wrapper"> {day.getDate()} </div>
    }

    const filterProjects = projects => {
        // filtering projects that are already tracked
        const selectedDates = selectedEvents.map(x => x.date);
        const selectedTrackedProjects = trackedDays
            .filter(x => selectedDates.includes(moment(x.start).format(DATE_FORMAT_WITH_DASH)))
            .map(x => x.project);

        let filteredProjects = projects
            .filter(project => project.name === SICK_LEAVE.name
                ? !selectedTrackedProjects.some(x => x.name === SICK_LEAVE.name)
                : !selectedTrackedProjects.some(x => x._id === project._id));

        // removing projects on which the user is no longer active
        filteredProjects = filteredProjects
            .filter(project => project.lastActiveDate ? !selectedDays.some(x => moment(x).isAfter(moment(project.lastActiveDate), 'date')) : true);

        // removing projects on which the user is not yet active
        filteredProjects = filteredProjects
            .filter(project => project.startDate ? !selectedDays.some(x => moment(x).isBefore(moment(project.startDate), 'date')) : true);

        return filteredProjects;
    };

    //Validation on different projects
    const handleValidation = (values) => {
        if (values.project?.isSickLeave) {
            return {
                ...validateRequiredFields(values, ['project']),
                ...validateFilesArrayLength(values, 'fileInfo', Number(!isFreelanceEmployee && !isPartnerProfile)),
            }
        } else {
            return {
                ...validateDecimalInputPeriod(values, [{ property: 'hours', maxLength: 24, minLength: 0 }]),
                ...validateRequiredFields(values, ['hours', 'project']),
            }
        }
    };

    return (
        <Formik
            initialValues={{
                project: '',
                hours: '',
                tasksDesc: '',
                fileInfo: [],
            }}
            validate={(values) => handleValidation(values)}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleTrackTime(values);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="track-time-dialog"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth={'md'}
                    dialogTitle='Track Time'
                    onSave={props.handleSubmit}
                >
                    <TrackTimeDialogFormView
                        {...props}
                        selectedDays={selectedDays}
                        handleOpenUploadFileDialog={handleOpenUploadFileDialog}
                        projects={filterProjects(projects)}
                        currentFileName={currentFileName}
                        renderDay={renderDay}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    );
}

export default TrackTimeDialog;
