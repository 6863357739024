import React, { useState } from 'react'

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PerformanceReviewOverallCard from './PerformanceReviewOverallCard';
import PerformanceReviewDialog from '../PerformanceReviewDialog';

import { TAB_NAMES } from '../PerformanceReviewConstants';

const PerformanceReviewOverall = ({
    displayName,
    createReview,
    overallPerformance,
    performanceCriterias,
    agencyId,
    employeeId,
    selectedTab,
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => setOpenDialog(true);
    const handleCloseDialog = () => setOpenDialog(false);

    return (
        <div className="performance-review-wrapper performance-overall">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Performance'
                />

                {selectedTab.text !== TAB_NAMES.CLIENT_FEEDBACK && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                            text='Create Review'
                            onClick={handleOpenDialog}
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <PerformanceReviewOverallCard
                overallPerformance={overallPerformance}
            />

            <PerformanceReviewDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                displayName={displayName}
                handleReview={createReview}
                performanceCriterias={performanceCriterias}
                agencyId={agencyId}
                userId={employeeId}
            />
        </div>
    )
}

export default PerformanceReviewOverall;
