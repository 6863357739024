import React from 'react';

import { Paper } from '@material-ui/core';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { ReactComponent as AccountManagerIcon } from 'assets/icon-account-manager.svg';
import { ReactComponent as ContactNumberIcon } from 'assets/icon-contact-number.svg';
import { ReactComponent as ContactEmailIcon } from 'assets/icon-contact-email.svg';

import './ClientYourAccountManagerInformation.scss';

const ClientYourAccountManagerInformation = ({
    accountManager,
    contactNumber,
    contactEmail,
}) => {

    return (
        <div className="client-your-account-manager-information-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle="Your Account Manager" />
            </SectionActionsWrapper>
            <Paper className="client-your-account-manager-content">
                <div className="information-section">
                    <h3 className="title">Account Manager</h3>
                    <div className="icon-text-wrapper">
                        <AccountManagerIcon className="icon" />
                        <span className="text">{accountManager}</span>
                    </div>
                </div>
                <div className="information-section">
                    <h3 className="title">Contact Number</h3>
                    <div className="icon-text-wrapper">
                        <ContactNumberIcon className="icon" />
                        <span className="text">{contactNumber ? `+${contactNumber}` : 'TBC'}</span>
                    </div>
                </div>
                <div className="information-section">
                    <h3 className="title">Contact Email</h3>
                    <div className="icon-text-wrapper">
                        <ContactEmailIcon className="icon" />
                        <span className="text">{contactEmail || 'TBC'}</span>
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default ClientYourAccountManagerInformation;
