import { ROLES } from "constants/userConstants";
import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';

export const getProfileLabelText = (role, employmentType) => {
    switch (role) {
        case ROLES.ADMIN:
        case ROLES.EMPLOYEE:
            return employmentType === EMPLOYMENT_TYPES.FREELANCE_CONTRACT
                ? 'Freelance Employee'
                : 'Full-time Employee';
        case ROLES.SUPPLIER_ADMIN:
        case ROLES.SUPPLIER_EMPLOYEE:
            return 'Partner Profile'
        case ROLES.RECRUITER:
        case ROLES.RECRUITER_EMPLOYEE:
            return 'Recruiter Profile'
        case ROLES.CANDIDATE:
            return 'Candidate Profile'
        default:
            return ''
    }
};
