export const START_JOURNEY_STEPS = [
    {
        title: 'Complete your profile',
        description: 'Tell us about your experience',
        duration: 1,
    },
    {
        title: 'Setup job preferences',
        description: 'Describe your dream remote job',
        duration: 1,
    },
    {
        title: 'Upload resume',
        description: 'Let our AI extract the data',
        duration: 1,
    },
    {
        title: 'Record personal introduction',
        description: 'Tell us about your personality',
        duration: 2,
    },
];
