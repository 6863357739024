import React, { Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import { ROLES } from 'constants/userConstants';
import { adminDataMarkupProperties, adminDataMarkupPropertiesSaaS } from './ProjectsOverviewTableConstants';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton/';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getStateAgencyName } from 'utils/helpers';

import "./ProjectsOverviewTable.scss";

const ProjectsOverviewTable = ({
    projectsData,
    consultantsData,
    allClientTeamHistory,
    agencyDashboard,
    hiddenSections,
    displayMode,
    adminData,
    isAdmin,
    dataFetchState
}) => {
    const selectedAdminDashboardProperties = hiddenSections ? adminDataMarkupPropertiesSaaS : adminDataMarkupProperties;
    const history = useHistory();

    const handleNavigateToReportsPage = () => history.push(`/${getStateAgencyName()}/reports`);

    const adminDashboard = (data, i) => {
        return <div key={uuidv4()} className={`projects-overview-${selectedAdminDashboardProperties[i].classType}`}>
            <Typography variant="h6" className="positions-title">{selectedAdminDashboardProperties[i].name}</Typography>
            <div className={`${selectedAdminDashboardProperties[i].classType} data-field`}>
                {
                    selectedAdminDashboardProperties[i].fields.map(field =>
                        <div key={uuidv4()} className="overall-performance no-border with-tooltip" tooltip={field.tooltip}>
                            <Typography variant="h5" className={`${selectedAdminDashboardProperties[i].classType}-single-title`}>{field.name}</Typography>
                            {dataFetchState?.isDataFetching
                                ? <CircularProgress size={25} disableShrink />
                                : <Typography variant="h4"
                                    className={
                                        `${selectedAdminDashboardProperties[i].classType}-single-amount
                                    ${field.dataProp === 'rejectedCandidates'
                                            ? 'zero'
                                            : data[field.dataProp] === 0
                                                ? 'zero'
                                                : 'active'}`}>
                                    {data[field.dataProp]}
                                </Typography>
                            }
                        </div>
                    )
                }
            </div>
        </div >
    }


    return (
        <Paper className="projects-overview-table-wrapper">
            <div className="projects-overview-inner-table-wrapper">
                <Fragment>
                    {!isAdmin &&
                        <div className="projects-overview-performance">
                            <Typography variant="h6" className="performance-title">Engagement</Typography>

                            <div className="performance data-field">
                                <div className="overall-performance no-border with-tooltip"
                                    tooltip='Number of projects marked as on-going or the end date of which is in the future.'>
                                    <Typography variant="h5" className="performance-single-title">Active Projects</Typography>
                                    {dataFetchState?.isDataFetching
                                        ? <CircularProgress size={25} disableShrink />
                                        : <Typography variant="h4" className="performance-single-amount active">{projectsData.activeProjects}</Typography>
                                    }
                                </div>
                                <div className="overall-performance with-tooltip"
                                    tooltip='Number of projects the start date of which is in the future.'>
                                    <Typography variant="h5" className="performance-single-title">Upcoming Projects</Typography>
                                    {dataFetchState?.isDataFetching
                                        ? <CircularProgress size={25} disableShrink />
                                        : <Typography variant="h4" className="performance-single-amount upcoming">{projectsData.upcomingProjects}</Typography>
                                    }
                                </div>
                                <div className="overall-performance with-tooltip"
                                    tooltip='Number of projects the end date of which is in the past.'>
                                    <Typography variant="h5" className="performance-single-title">Past Projects</Typography>
                                    {dataFetchState?.isDataFetching
                                        ? <CircularProgress size={25} disableShrink />
                                        : <Typography variant="h4" className="performance-single-amount past">{projectsData.pastProjects}</Typography>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className="projects-overview-resourse">
                        <Typography variant="h6" className="resourse-title">{agencyDashboard ? 'Resources' : 'Allocation'}</Typography>
                        <div className="resourse data-field">
                            {hiddenSections && displayMode === ROLES.CLIENT
                                ? <>
                                    <div
                                        className="overall-consultants no-border"
                                        tooltip='Team members marked as "Billable" or "Project Ends"'>
                                        <Typography variant="h5" className="consultant-single-title">Current Team</Typography>
                                        {dataFetchState?.isDataFetching
                                            ? <CircularProgress size={25} disableShrink />
                                            : <Typography variant="h4" className="consultant-single-amount active">{consultantsData.currentTeam}</Typography>
                                        }
                                    </div>
                                    <div
                                        className="overall-consultants"
                                        tooltip='Team members marked as "Bench" or "Non-billable Project"'>
                                        <Typography variant="h5" className="consultant-single-title">Team History</Typography>
                                        {dataFetchState?.isDataFetching
                                            ? <CircularProgress size={25} disableShrink />
                                            : <Typography variant="h4" className="consultant-single-amount inactive">{allClientTeamHistory?.length}</Typography>
                                        }
                                    </div>
                                </>
                                : <>
                                    <div
                                        className="overall-consultants no-border"
                                        tooltip='Team members marked as "Billable" or "Project Ends"'>
                                        <Typography variant="h5" className="consultant-single-title">{
                                            agencyDashboard ? hiddenSections ?
                                                'Active Talent' : 'Active consultants'
                                                : 'Contract Team Members'
                                        }</Typography>
                                        {dataFetchState?.isDataFetching
                                            ? <CircularProgress size={25} disableShrink />
                                            : <Typography variant="h4" className="consultant-single-amount active">{agencyDashboard ? consultantsData.placed : consultantsData.on_demand}</Typography>
                                        }
                                    </div>
                                    <div
                                        className="overall-consultants"
                                        tooltip='Team members marked as "Bench" or "Non-billable Project"'>
                                        <Typography variant="h5" className="consultant-single-title">{
                                            agencyDashboard ? hiddenSections ?
                                                'Inactive Talent' : 'Inactive consultants'
                                                : 'Full-time Team Members'
                                        }</Typography>
                                        {dataFetchState?.isDataFetching
                                            ? <CircularProgress size={25} disableShrink />
                                            : <Typography variant="h4" className="consultant-single-amount inactive">{agencyDashboard ? consultantsData.bench : consultantsData.dedicated}</Typography>
                                        }
                                    </div>
                                </>
                            }

                            {displayMode === ROLES.ADMIN &&
                                <div
                                    className="overall-consultants"
                                    tooltip='Team members marked as "Leadership Team"'>
                                    <Typography variant="h5" className="management-team-title">Leadership Team</Typography>
                                    {dataFetchState?.isDataFetching
                                        ? <CircularProgress size={25} disableShrink />
                                        : <Typography variant="h4" className="management-team-amount">{consultantsData.managementTeam}</Typography>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {displayMode === ROLES.ADMIN &&
                        adminData.length && adminData.map((data, i) => adminDashboard(data, i))
                    }
                </Fragment>
            </div>
            <PrimaryButton text="See Reports" className="reports-btn" handleClick={handleNavigateToReportsPage} />
        </Paper>
    )
}

export default ProjectsOverviewTable;
