import React, { useState } from 'react';

import { CANDIDATE_STATUSES } from 'constants/candidateConstants';

import './CandidateStatusIcon.scss';

const CandidateStatusIcon = ({ status, feedback, isAdminMode }) => {
    
    const [showFeedback, setShowFeedback] = useState(false);
    
    const feedbackBox = () => {
        return (
            <div className="custom-tooltip feedback-box">
                <pre className="feedback">
                    <b>{isAdminMode ? 'Candidate' : 'Your'} Feedback:</b>
                    <br />
                    {feedback}
                </pre>
            </div>
        )
    }
    
    const getStatusClass = (status) => {
        return status?.toLowerCase().split(' ').join('-');
    }

    const showFeedbackBox = () => feedback && setShowFeedback(true);
    const hideFeedbackBox = () => setShowFeedback(false);

    const statusMapper = {
        [CANDIDATE_STATUSES.VETTED]: <div className="status-icon">Approved</div>,
        [CANDIDATE_STATUSES.REJECTED]: <div className="status-icon rejected">Rejected</div>,
        [CANDIDATE_STATUSES.PENDING]: <div className="status-icon">Pending</div>,
        [CANDIDATE_STATUSES.NEW]: <div className="status-icon">Pending</div>,
        [CANDIDATE_STATUSES.PENDING_VIDEO_REVIEW]: <div className="status-icon">Pending</div>,
        [CANDIDATE_STATUSES.PENDING_CALENDLY_REVIEW]: <div className="status-icon">Pending</div>,
        [CANDIDATE_STATUSES.CV_COMPLETED]: <div className="status-icon">Pending</div>,
        [CANDIDATE_STATUSES.INCOMPLETE_PROFILE]: <div className="status-icon">Incomplete Profile</div>,
    }

    return (
        <div className={`candidate-status-wrapper ${getStatusClass(status)}`} onMouseEnter={showFeedbackBox} onMouseLeave={hideFeedbackBox}>
            {   showFeedback &&
                feedbackBox()
            }
            {
                statusMapper[status]
            }
        </div>
    )
}

export default CandidateStatusIcon;
