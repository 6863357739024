import { persistReducer } from 'redux-persist';

import {
    FETCH_MAIN_MENU_INFORMATION_SUCCESS,
    HOME_INFORMATION_INITIAL_STATE_SET,
    UPDATE_MAIN_MENU_INFORMATION_SUCCESS,
    INITIAL_JOURNEY_FETCH_SUCCESS,
    INITIAL_JOURNEY_UPDATE_SUCCESS,
    INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS,
    INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS,
} from 'actions/actionTypes';

import { PERSIST_MENU_CONFIG } from 'utils/persistUtils';

const menuInitialState = {
    menuName: '',
    menuSubInfo: '',
    menuAvatar: '',
    companyLogoUrl: '',
    initialJourney: {
        completedSteps: [],
        isCompleted: false,
        currentStepId: '',
    }
}

const menu = (state = menuInitialState, action) => {
    switch (action.type) {
        case FETCH_MAIN_MENU_INFORMATION_SUCCESS:
            return { ...menuInitialState, ...action.payload }
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return { ...menuInitialState, menuName: state.menuName, companyLogoUrl: state.companyLogoUrl }
        case UPDATE_MAIN_MENU_INFORMATION_SUCCESS:
            return { ...state, ...action.payload }
        case INITIAL_JOURNEY_FETCH_SUCCESS:
            return {
                ...state,
                initialJourney: {
                    ...menuInitialState.initialJourney,
                    ...action.payload,
                }
            };
        case INITIAL_JOURNEY_UPDATE_SUCCESS:
            const currentStepIds = state.initialJourney.completedSteps.slice().map(x => x._id);

            const completedSteps =
                currentStepIds.includes(action.payload.completedStep._id)
                    ? [...state.initialJourney.completedSteps]
                    : [...state.initialJourney.completedSteps, action.payload.completedStep];

            const isCompleted = action.payload.isCompleted;

            return { ...state, initialJourney: { ...state.initialJourney, completedSteps, isCompleted } };
        case INITIAL_JOURNEY_CURRENT_STEP_ID_UPDATE_SUCCESS:
            return {
                ...state,
                initialJourney: { ...state.initialJourney, currentStepId: action.payload }
            };
        case INITIAL_JOURNEY_COMPLETED_STEPS_UPDATE_SUCCESS: {
            return {
                ...state,
                initialJourney: {
                    ...state.initialJourney,
                    completedSteps: [...state.initialJourney.completedSteps, action.payload]
                }
            };
        }
        default:
            return state;
    }
};

export default persistReducer(PERSIST_MENU_CONFIG, menu);

export const getMenuInformation = state => ({
    menuName: state.menuName,
    menuSubInfo: state.menuSubInfo,
    menuAvatar: state.menuAvatar,
    companyLogoUrl: state.companyLogoUrl,
});
export const getInitialJourneyCompletedStepsIds = state => state.initialJourney.completedSteps.map(x => x._id);
export const getCompletedByAdminStepsIds = state => state.initialJourney.completedSteps.reduce((acc, curr) => {
    if (curr.isStepCompletedByAdmin) {
        acc.push(curr._id);
    }
    return acc;
}, []);
export const getIsInitialJourneyCompleted = state => state.initialJourney.isCompleted;
export const getCurrentJourneyStepId = state => state.initialJourney.currentStepId;
