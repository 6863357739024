import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AdminAccountsTable from './AdminAccountsTable';
import DepartmentsTable from './DepartmentsTable';
import UsersTable from './UsersTable';

import { fetchUserManagementPage } from 'actions/agencyActions';
import { getAgencyId, getDisplayMode, getClientId } from 'reducers';

import { usersRequiredFields, userRoles, filters } from './UserManagementConstants';
import { ROLES } from 'constants/userConstants';

import isAuthenticated from 'hoc/isAuthenticated';

const UserManagement = ({
    agencyId,
    fetchUserManagementPage,
    displayMode,
    clientId,
}) => {

    useEffect(() => {
        if (displayMode !== ROLES.CLIENT) {
            fetchUserManagementPage(agencyId, usersRequiredFields, userRoles, JSON.stringify(filters));
        } 
    }, []);

    return (
        <div>
            <AdminAccountsTable
                agencyId={agencyId}
                clientId={clientId}
                displayMode={displayMode}
            />
            {
                ROLES.CLIENT !== displayMode &&
                <>
                    <DepartmentsTable
                        agencyId={agencyId}
                    />
                    <UsersTable
                        agencyId={agencyId}
                    />
                </>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    displayMode: getDisplayMode(state),
    clientId: getClientId(state),
});

const mapDispatchToProps = {
    fetchUserManagementPage,
};

export default isAuthenticated(connect(mapStateToProps, mapDispatchToProps)(UserManagement), [ROLES.ADMIN, ROLES.CLIENT]);
