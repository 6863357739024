import React from 'react';
import moment from 'moment';
import { ErrorMessage } from 'formik';

import { KeyboardDatePicker } from '@material-ui/pickers';

import isHoliday from 'hoc/isHoliday';

import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

const EmployeeTerminationDialogForm = ({
    values,
    errors,
    touched,
    setFieldValue,
}) => {

    return (
        <form className="employee-termination-dialog-form form" autoComplete="off">
            <div>
                <KeyboardDatePicker
                    autoOk
                    required
                    fullWidth
                    disableToolbar
                    helperText=""
                    openTo="date"
                    variant="inline"
                    name="terminationDate"
                    label="Termination Date"
                    inputVariant="outlined"
                    renderDay={isHoliday}
                    format={DATE_PICKER_FORMAT}
                    value={values.terminationDate}
                    error={!!(errors.terminationDate && touched.terminationDate)}
                    onChange={value => {
                        setFieldValue('terminationDate', moment(value).utc().format());
                    }}
                />
                <ErrorMessage name="terminationDate" component="div" className="invalid-field-message" />
            </div>
        </form>
    );
};

export default EmployeeTerminationDialogForm;
