import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { ReactComponent as ThankYouBanner } from 'assets/thank-you-confetti.svg';

import { getThankYouScreen } from './ThankYouCardConstants';
import './ThankYouCard.scss';

const ThankYouCard = ({
    children,
    role,
    agencyName,
    email
}) => {
    const thankYouScreen = getThankYouScreen(role, agencyName, email);
    return (
        <Card className="thank-you-card">
            <div className="application-banner-image application-success">
                <ThankYouBanner />
            </div>
            <CardContent className="card-content">
                {thankYouScreen}
            </CardContent>

            <CardActions className='thank-you-card-actions' disableSpacing>
                {children}
            </CardActions>
        </Card>
    )
};

export default ThankYouCard;
