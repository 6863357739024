import React, { useState } from 'react';
import { connect } from 'react-redux';

import { getAgencyId, getRecruitersAccountManagers } from 'reducers';
import { registerRecruiter, fetchRecruiters, fetchRecruitersManagers } from 'actions/recruiterActions';
import { disableUsers } from 'actions/userActions';

import RecruitersTable from './RecruitersTable';
import AddRecruiterDialog from './AddRecruiterDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import { ROLES } from 'constants/userConstants';

import './Recruiters.scss';

const Recruiters = ({
    agencyId,
    recruitersAccountManagers,
    fetchRecruiters,
    fetchRecruitersManagers,
    registerRecruiter,
    disableUsers,
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    const [recruiterToRemove, setRecruiterToRemove] = useState({});
    const [reload, setReload] = useState(false);

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => setOpenAddDialog(false);

    const handleDeleteClick = recruiter => {
        setRecruiterToRemove(recruiter);
        setOpenConfirmRemoveDialog(true);
    };

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(false);
        setRecruiterToRemove({});
    };

    const handleAddSubmit = async (values) => {
        if (values.email) {
            setNewUserData(values)
            setOpenConfirmDialog(true);
            return
        }

        await registerRecruiter(agencyId, values, false);
        setReload(true);
        handleCloseAddDialog();
    };

    const handleConfirmInviteSubmit = async () => {
        await registerRecruiter(agencyId, newUserData, false);
        setReload(true);
        handleCloseConfirmDialog();
        handleCloseAddDialog();
        setNewUserData({});
    };

    const handleConfirmDeleteSubmit = async () => {
        await disableUsers([recruiterToRemove._id], ROLES.RECRUITER, false);
        setReload(true);
        setOpenConfirmRemoveDialog(false);
        setRecruiterToRemove({});
    };

    const sortAlphabetically = (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName);

    const actions = [
        { title: 'Delete', handleClick: handleDeleteClick },
    ];

    const getActionsHandler = () => actions;

    return (
        <div className="recruiters-table-wrapper">
            <RecruitersTable
                recruitersAccountManagers={recruitersAccountManagers}
                tableFetchAction={fetchRecruiters.bind(null, agencyId)}
                tablePrefetchAction={fetchRecruitersManagers.bind(null, agencyId)}
                reload={reload}
                setReload={setReload}
                getActionsHandler={getActionsHandler}
                handleOpenAddDialog={handleOpenAddDialog}
            />

            {openAddDialog &&
                <AddRecruiterDialog
                    agencyId={agencyId}
                    openDialog={openAddDialog}
                    handleSubmitClick={handleAddSubmit}
                    handleCloseDialog={handleCloseAddDialog}
                    sortAlphabetically={sortAlphabetically}
                />
            }

            {openConfirmDialog &&
                <ConfirmInviteUserDialog
                    invitedUser={ROLES.RECRUITER}
                    openDialog={openConfirmDialog}
                    handleInviteUser={handleConfirmInviteSubmit}
                    handleCloseDialog={handleCloseConfirmDialog}
                />
            }

            {openConfirmRemoveDialog &&
                <ConfirmDeleteDialog
                    itemToDelete={recruiterToRemove?.name}
                    openDialog={openConfirmRemoveDialog}
                    handleDeleteItem={handleConfirmDeleteSubmit}
                    handleCloseDialog={handleCloseConfirmRemoveDialog}
                />
            }
        </div>
    );
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    recruitersAccountManagers: getRecruitersAccountManagers(state),
});

const mapDispatchToProps = {
    fetchRecruiters,
    fetchRecruitersManagers,
    registerRecruiter,
    disableUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Recruiters);
