import React from 'react';

const ActiveHolidaysLegend = () => (
    <div className='active-holidays-legend'>
        <div className='upcoming-holiday'></div>
        <span>Upcoming</span>
        <div className='active-holiday'></div>
        <span>Active</span>
        <div className='sick-leave'></div>
        <span>Sick Leave</span>
    </div>
)

export default ActiveHolidaysLegend;
