import React, { useEffect, useState, useRef } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { DOT_UNICODE } from 'constants/commonConstants';

const MaskedToggableInputField = ({
    label,
    name,
    value,
    errors,
    touched,
    setFieldValue,
    handleShowHeaderButtons,
}) => {
    const [isInputFieldValueVisible, setIsInputFieldValueVisible] = useState(false);
    const [maskedInputFieldValue, setMaskedInputFieldValue] = useState(DOT_UNICODE.repeat(value?.length || 0));
    const [inputCursorPos, setInputCursorPos] = useState(0);
    const inputRef = useRef();

    const toggleInputFieldValueVisibility = () => {
        setIsInputFieldValueVisible(prevState => !prevState);
    };

    useEffect(() => {
        setMaskedInputFieldValue(DOT_UNICODE.repeat(value?.length || 0));
    }, [value]);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.setSelectionRange(inputCursorPos, inputCursorPos);
        }
    }, [maskedInputFieldValue]);

    return (
        <TextField
            required
            fullWidth
            variant="outlined"
            inputRef={inputRef}
            label={label}
            name={name}
            value={isInputFieldValueVisible ? value : maskedInputFieldValue}
            error={errors.evrotrustVendorNumber && touched.evrotrustVendorNumber}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle input field value visibility"
                            onClick={toggleInputFieldValueVisibility}
                            style={{ padding: "0" }}
                        >
                            {isInputFieldValueVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            onChange={(e) => {
                const startPos = e.target.selectionStart;
                setInputCursorPos(startPos);

                if (isInputFieldValueVisible) {
                    setFieldValue(name, e.target.value);
                } else {
                    const regexResult = new RegExp(`[^${DOT_UNICODE}]+`).exec(e.target.value);
                    if (regexResult) {
                        const newCharacters = regexResult[0];
                        const indexMatch = regexResult.index;
                        const endIndex = (indexMatch + newCharacters.length) - e.target.value.length;
                        setFieldValue(name, value.slice(0, indexMatch) + newCharacters + (endIndex === 0 ? '' : value.slice(endIndex)));
                    } else {
                        const endIndex = startPos - e.target.value.length;
                        setFieldValue(name, value.slice(0, startPos) + (endIndex === 0 ? '' : value.slice(endIndex)));
                    }
                }
                handleShowHeaderButtons();
            }}
        />
    );
};

export default MaskedToggableInputField;
