import React from 'react';
import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import AddImportantDocumentsDialogView from './AddImportantDocumentsDialogView';

import { validateRequiredFields, validateInputLength } from 'utils/formValidations';

import './AddImportantDocumentsDialog.scss';

const AddImportantDocumentsDialog = ({
    openDialog,
    handleCloseDialog,
    addImportantDocumentsHandler
}) => {
    return (
        <Formik
            initialValues={{
                title: '',
                filesAttached: []
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['title']),
                    ...validateInputLength(values, [
                        { property: 'title', maxLength: 30 }
                    ])
                }
            }}

            onSubmit={(values, { setSubmitting }) => {
                const data = new FormData()
                const { filesAttached } = values;
                data.append('onboardingDocumentsInfo', JSON.stringify({ ...values, filesCount: filesAttached.length }));
                filesAttached.forEach((file, i) => {
                    data.append(`attachedfile${i + 1}`, file);
                });

                addImportantDocumentsHandler(data);
                setSubmitting(false);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    size="sm"
                    className="add-important-documents-dialog"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    dialogTitle={'Add new important document'}
                    onSave={props.handleSubmit}
                    saveBtnText="Save"
                    discardBtnText="Discard"
                >   
                    <AddImportantDocumentsDialogView
                        {...props}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
};

export default AddImportantDocumentsDialog;
