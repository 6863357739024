import React, { useState } from 'react';

import MainMenu from './MainMenu';

import { getRecruiterMenuItems } from '../constants/recruiterMenuConstants';

const RecruiterMenu = ({ agencyRouteName }) => {
    const [recruiterMenuItems] = useState(getRecruiterMenuItems(agencyRouteName));
    return (<MainMenu menuItems={recruiterMenuItems} />)
};

export default RecruiterMenu;
