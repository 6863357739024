import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DATE_FORMAT_WITH_DASH } from '../../../constants/commonConstants';
import moment from "moment";

import {
    getEmployeePendingHolidaysCount,
    getEmployeeBasicInfo,
    getAgencyId,
    isAdminMode,
    getPublicHolidays,
    isSupplierAdminMode,
    isEmployeeMode,
    getDisplayMode,
    getAgencyBasicInfo,
    getActiveHolidaysTableDataFetchState,
    getEmploymentType,
    getAccess,
    getUserId,
} from 'reducers';

import {
    deleteHolidayRequests,
    downloadSignedHolidayFile,
    sendHolidaySignReminderEmail,
    fetchEmployeeHolidayPage,
    fetchEmploymentInformation,
    createHolidayRequest,
} from 'actions/employeeActions';
import { downloadManagersEmployeesDocumentsFiles } from 'actions/managerActions';

import { showNotification } from 'actions/notificationActions';
import { fetchAgencyEvrotrustInfo, fetchSickLeaves } from 'actions/agencyActions';
import { exportDocumentsData, downloadDocumentsFiles } from 'actions/documentActions';

import HistoryHolidaysTable from './HistoryHolidaysTable';
import PendingHolidaysTable from './PendingHolidaysTable';
import HolidaysRequests from './HolidaysRequests';
import ActiveHolidays from 'components/Shared/ActiveHolidays';
import EvrotrustInfoBanner from "./EvrotrustInfoBanner";
import EmployeeHolidayAllowance from '../EmploymentInformation/EmployeeHolidayAllowance';
import HolidayRequestDialog from './HolidaysRequests/HolidayRequestDialog';

import { logEvent } from 'utils/amplitude';

import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';
import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { HOLIDAY_TYPES } from './HolidaysRequests/HolidayRequestDialog/HolidayRequestDialogConstants';
import { HOLIDAY_APPROVAL_STATUSES } from 'constants/holidayConstants';

import './Holidays.scss';

const Holidays = ({
    displayMode,
    userId,
    departmentId,
    isAgencyAdminMode,
    isSupplierAdminMode,
    isEmployeeMode,
    agencyId,
    pendingHolidaysCount,
    basicEmployeeInfo,
    deleteHolidayRequests,
    downloadSignedHolidayFile,
    sendHolidaySignReminderEmail,
    fetchEmployeeHolidayPage,
    fetchSickLeaves,
    publicHolidays,
    fetchAgencyEvrotrustInfo,
    agencyInfo,
    activeHolidaysDataFetchState,
    fetchEmploymentInformation,
    employmentType,
    currentUserId,
    accessAsManager,
    isSupplierEmployee,
    exportDocumentsData,
    downloadDocumentsFiles,
    downloadManagersEmployeesDocumentsFiles,
    showNotification,
    createHolidayRequest,
}) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
        logEvent(AMPLITUDE_EVENT_TYPES.CLICK_REQUEST_HOLIDAY, { rolesPermitted: [ROLES.ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE] });
    }

    const handleCloseDialog = () => setOpenDialog(false);

    const handleHolidayRequest = holiday => {
        if (holiday.type === HOLIDAY_TYPES.ACCRUED_OVERTIME) {
            holiday = { ...holiday, status: HOLIDAY_APPROVAL_STATUSES.APPROVED };
        } else {
            holiday = { ...holiday, status: HOLIDAY_APPROVAL_STATUSES.PENDING };
        }
        createHolidayRequest(holiday, userId, agencyId, departmentId, accessAsManager);
        setOpenDialog(false);
    };

    useEffect(() => {
        fetchAgencyEvrotrustInfo(agencyId);
        fetchEmployeeHolidayPage(agencyId, userId, moment().format(DATE_FORMAT_WITH_DASH), moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH), departmentId);
        fetchSickLeaves(agencyId, moment().format(DATE_FORMAT_WITH_DASH), moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH), userId);
        fetchEmploymentInformation(agencyId, userId);
    }, []);

    const handleHolidaysAction = (holidayAction, updateItems) => {
        const fromDate = moment().format(DATE_FORMAT_WITH_DASH);
        const toDate = moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH);

        holidayAction(userId, agencyId, departmentId, updateItems, fromDate, toDate, accessAsManager);
    };

    const handleDownloadSignedFile = holiday => {
        downloadSignedHolidayFile(agencyId, departmentId, holiday, basicEmployeeInfo);
    };

    const showEvrotrustInfoBanner = (isEmployeeMode && employmentType !== EMPLOYMENT_TYPES.FREELANCE_CONTRACT) && !departmentId && agencyInfo.isUsingEvrotrust;

    return (
        <div className="holidays-review-wrapper holidays-overall">
            <div className="holidays-content">
                {(displayMode === ROLES.SUPPLIER_ADMIN) &&
                    <EmployeeHolidayAllowance
                        employeeId={userId}
                        agencyId={agencyId}
                        departmentId={departmentId}
                    />
                }

                <HolidaysRequests
                    dataFetchState={activeHolidaysDataFetchState}
                    handleOpenDialog={handleOpenDialog}
                />

                <ActiveHolidays
                    publicHolidays={publicHolidays}
                    dataFetchState={activeHolidaysDataFetchState}
                />

                <PendingHolidaysTable
                    agencyId={agencyId}
                    userId={userId}
                    departmentId={departmentId}
                    isAdminOrSupplierAdmin={isAgencyAdminMode || isSupplierAdminMode}
                    accessAsManager={accessAsManager}
                    isTableDataLoading={activeHolidaysDataFetchState.isDataFetching}
                    pendingHolidaysCount={pendingHolidaysCount}
                    deleteHolidayRequests={deleteHolidayRequests}
                    handleHolidaysAction={handleHolidaysAction}
                    handleOpenDialog={handleOpenDialog}
                />

                {showEvrotrustInfoBanner && <EvrotrustInfoBanner />}

                <HistoryHolidaysTable
                    currentUserId={currentUserId}
                    userId={userId}
                    agencyId={agencyId}
                    departmentId={departmentId}
                    displayMode={displayMode}
                    isUsingEvrotrust={agencyInfo.isUsingEvrotrust}
                    isTableDataLoading={activeHolidaysDataFetchState.isDataFetching}
                    sendHolidaySignReminderEmail={sendHolidaySignReminderEmail}
                    handleDownloadSignedFile={handleDownloadSignedFile}
                    deleteHolidayRequests={deleteHolidayRequests}
                    showNotification={showNotification}
                    exportDocumentsData={exportDocumentsData}
                    downloadDocumentsFiles={downloadDocumentsFiles}
                    downloadManagersEmployeesDocumentsFiles={downloadManagersEmployeesDocumentsFiles}
                />

                <HolidayRequestDialog
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                    handleHolidayRequest={handleHolidayRequest}
                    displayMode={displayMode}
                    employmentType={isSupplierEmployee ? EMPLOYMENT_TYPES.FREELANCE_CONTRACT : employmentType}
                    isDepartmentView={!!departmentId}
                />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    pendingHolidaysCount: getEmployeePendingHolidaysCount(state),
    agencyId: getAgencyId(state),
    basicEmployeeInfo: getEmployeeBasicInfo(state),
    isAgencyAdminMode: isAdminMode(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    isEmployeeMode: isEmployeeMode(state),
    publicHolidays: getPublicHolidays(state),
    agencyInfo: getAgencyBasicInfo(state),
    activeHolidaysDataFetchState: getActiveHolidaysTableDataFetchState(state),
    employmentType: getEmploymentType(state),
    accessAsManager: getAccess(state),
    currentUserId: getUserId(state),
});

const mapDispatchToProps = {
    downloadSignedHolidayFile,
    deleteHolidayRequests,
    sendHolidaySignReminderEmail,
    fetchEmployeeHolidayPage,
    fetchAgencyEvrotrustInfo,
    fetchSickLeaves,
    fetchEmploymentInformation,
    exportDocumentsData,
    downloadDocumentsFiles,
    downloadManagersEmployeesDocumentsFiles,
    showNotification,
    createHolidayRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
