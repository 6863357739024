import { defaultOwnerValue, taskTypeSelect } from './TasksConstants';

export const mapQueryToFilters = (queryObj, additionalSelectOptions) => {
    const initFilters = {
        owner: defaultOwnerValue[0],
        type: taskTypeSelect[0],
    }

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'owner':
                foundItem = additionalSelectOptions.find(y => y.name === queryObj[x]);
                acc.owner = foundItem ? foundItem : acc.owner;
                break;
            case 'type':
                foundItem = taskTypeSelect.find(y => y.name === queryObj[x])
                acc.type = foundItem ? foundItem : acc.type;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};
