import React from 'react';

import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import './MultiselectFilter.scss';

const MultiselectFilter = ({
    value,
    title,
    onChangeHandler,
    items,
    className,
    name,
}) => {
    return (
        <div>
            <FormControl variant="outlined">
                <Select
                    className={className ? `slim-select ${className}` : 'slim-select'}
                    multiple
                    value={value}
                    displayEmpty
                    renderValue={() => <span>{title}</span>}
                    IconComponent={ExpandMoreSharpIcon}
                    onChange={onChangeHandler}
                    MenuProps={{
                        className: "select-menu-technology",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    name={name}
                >
                    {items.map(item =>
                        <MenuItem key={item} value={item}>
                            <Checkbox color="default" checked={value.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultiselectFilter;
