import React from 'react';
import { connect } from 'react-redux';

import { getExploreTalentsDataFetchState, getExploreTalentsProfiles, getTotalTalentsCount } from 'reducers';

import ExploreTalentsContent from './ExploreTalentsContent';

const ExploreTalentsContentRedux = ({
    talents,
    totalTalentsCount,
    dataFetchState,
    ...props
}) => (
    <ExploreTalentsContent
        talents={talents}
        totalTalentsCount={totalTalentsCount}
        dataFetchState={dataFetchState}
        {...props}
    />
);

const mapStateToProps = (state) => ({
    talents: getExploreTalentsProfiles(state),
    totalTalentsCount: getTotalTalentsCount(state),
    dataFetchState: getExploreTalentsDataFetchState(state),
});

export default connect(mapStateToProps, null)(ExploreTalentsContentRedux);
