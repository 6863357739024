import React from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ROLES } from 'constants/userConstants';

const AddEmployeeProjectsCardAutoComplete = ({
	employees,
	errors,
	touched,
	handleChange,
	isEdit,
	inputLabel,
	employeeValue
}) => {
	const handleSelection = (event, newValue) => {
		handleChange({ target: { name: ROLES.EMPLOYEE, value: newValue || { firstName: '', lastName: '', _id: '' } } });
		handleChange({ target: { name: 'hourRate', value: newValue.hourRate || 0 } });
	}

	return (
		isEdit
			? <TextField
				label={inputLabel}
				value={`${employeeValue.firstName} ${employeeValue.lastName}`}
				margin="normal"
				variant="outlined"
				fullWidth
				disabled
			/>
			: <Autocomplete
				onChange={handleSelection}
				options={employees}
				filterOptions={(options, params) => {
					return options ? options.filter((x) => {
						const optionName = `${x.firstName} ${x.lastName}`;
						const valueName = params.inputValue;
						return valueName ? optionName.toLowerCase().includes(valueName.toLowerCase()) : true;
					}) : [];
				}}
				getOptionLabel={(option) => !option.firstName && !option.lastName ? '' : `${option.firstName} ${option.lastName}`}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				blurOnSelect
				className="add-employee-autocomplete-input"
				renderInput={(params) => (
					<TextField
						{...params}
						name="name"
						label={inputLabel}
						error={errors.name && touched.name}
						margin="normal"
						variant="outlined"
						fullWidth />
				)}
			/>
	);
};

export default AddEmployeeProjectsCardAutoComplete;
