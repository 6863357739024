import React, { createContext, useContext } from 'react';
import { connect } from 'react-redux';

import { updateCandidateJourneyCvPage } from 'actions/candidateActions';
import { getEmployeeCvInformation, getCompanyLogoUrl } from 'reducers';

import CvCompletionPage from './CvCompletionPage';
import CandidateJourneyCv from './CandidateJourneyCv';

import './UploadResumeWrapper.scss';

export const UploadResumeWrapperContext = createContext();
const { Provider } = UploadResumeWrapperContext;

const UploadResumeWrapper = ({
    agencyId,
    userId,
    mainStepTitle,
    children,
    updateCandidateJourneyCvPage,
    handleNextStep,
    uploads,
    setUploads,
    employeeCvInformation,
    discardAction,
    companyLogoUrl,
}) => {
    const context = {
        agencyId,
        userId,
        mainStepTitle,
        handleNextStep,
        updateCandidateJourneyCvPage,
        uploads,
        setUploads,
        employeeCvInformation,
        discardAction,
        companyLogoUrl,
    };

    return (
            <Provider value={context}>
                {children}
            </Provider>
    );
};

const UploadResume = ({
    handleClick,
}) => {
    const { agencyId, userId, uploads, setUploads } = useContext(UploadResumeWrapperContext);

    return (
        <>
            <CandidateJourneyCv
                agencyId={agencyId}
                userId={userId}
                handleClickNextStep={handleClick}
                uploads={uploads}
                setUploads={setUploads}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    employeeCvInformation: getEmployeeCvInformation(state),
    companyLogoUrl: getCompanyLogoUrl(state),
});

const mapDispatchToProps = {
    updateCandidateJourneyCvPage,
};

UploadResumeWrapper.UploadResume = UploadResume;
UploadResumeWrapper.CvCompletionPage = CvCompletionPage;

export default connect(mapStateToProps, mapDispatchToProps)(UploadResumeWrapper);
