import React from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import { BASE_APP_TRADEMARK } from 'constants/env';

import './LoginCard.scss';

const LoginCard = (props) => {
    let component = (
        <Card className={`login-wrapper login-card${props.isMobile ? ' mobile' : ''}${props.classes ? ` ${props.classes}` : ''}`}>
            <a href={BASE_APP_TRADEMARK.LOGO_HREF}>
                <CardMedia
                    className="login-card-media"
                    component="img"
                    image={BASE_APP_TRADEMARK.LOGO}
                    title={BASE_APP_TRADEMARK.TITLE}
                />
            </a>
            {props.msg &&
                <CardContent>
                    <Typography className="login-card-msg" variant="h5">
                        {props.msg}
                    </Typography>
                </CardContent>
            }

            <CardActions className={`login-card-actions${props.isMobile ? ' mobile' : ''}`} disableSpacing>
                {props.children}
            </CardActions>
        </Card>
    )

    if (props.isNewStyle) {
        component = (
            <Card className={`login-card-prod-style${props.isMobile ? ' mobile' : ''}${props.classes ? ` ${props.classes}` : ''}`}>
                {(props.title || props.subtitle) &&
                    <CardContent className="login-card-header">
                        <Typography className="login-card-title" variant="h3">
                            {props?.title}
                        </Typography>
                        <Typography className="login-card-subtitle" variant="h5">
                            {props?.subtitle}
                        </Typography>
                    </CardContent>
                }

                <CardActions className={`login-card-actions${props.isMobile ? ' mobile' : ''}`} disableSpacing>
                    {props.children}
                </CardActions>

                {(props.footer) &&
                    <CardContent className="login-card-footer">
                        <Typography variant="h3">
                            {props?.footer}
                        </Typography>
                    </CardContent>
                }
            </Card>
        )
    }

    return component
};

export default LoginCard;
