import React from 'react';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PeopleSharpIcon from '@material-ui/icons/PeopleSharp';
import {ReactComponent as OpenProjectsIcon} from 'assets/public-opportunities.svg';

export const getRecruiterMenuItems = agencyRouteName => [
    {
        TITLE: 'Profiles',
        ICON: <PeopleSharpIcon />,
        ROUTE: `/${agencyRouteName}/recruiter-profiles`,
    },
    {
        TITLE: 'Open Projects',
        ICON: <OpenProjectsIcon style={{width: '100%'}}/>,
        ROUTE: `/${agencyRouteName}/public-opportunities`,
    },
    {
        TITLE: 'Account Manager',
        ICON: <PersonOutlineIcon />,
        ROUTE: `/${agencyRouteName}/your-account-manager`
    },
];
