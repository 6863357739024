import React, { useEffect, useState, useRef } from 'react';

import './Slider.scss';

const Slider = ({
    slidesData,
    transformFunction,
    intervalTime,
    isAutoScroll,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [pressed, setPressed] = useState(false);
    const [position, setPosition] = useState({ x: 0 });
    const timeoutRef = useRef(null);
    const sliderRef = useRef();

    const slideLength = slidesData?.length;

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    const nextSlide = () => {
        setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
    }

    const getTransformX = () => {
        const style = window.getComputedStyle(sliderRef.current)
        const matrix = style.transform || style.webkitTransform || style.mozTransform;

        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

        return Number(matrixValues[4]);
    }

    const snapX = () => {
        let transformX = getTransformX();
        transformX = transformX > 0 ? 0 : -transformX;
        const slideWidth = sliderRef.current.firstChild.clientWidth;

        const index = Math.min(Math.floor(transformX / slideWidth) + Math.floor((transformX % slideWidth) / (slideWidth / 2)), slideLength - 1);

        setCurrentSlide(index);
        sliderRef.current.style.transform = `translate3d(${-index * 100}%, 0, 0)`;
    }

    const dragSlide = () => {
        if (sliderRef.current) {
            const transformX = getTransformX();
            const slideWidth = sliderRef.current.firstChild.clientWidth;

            const leftConstraint = (transformX - (slideWidth / 2)) < 0;
            const rightConstraint = (-transformX / slideWidth) < (slideLength - 0.5);

            if (leftConstraint && rightConstraint) {
                sliderRef.current.style.transform = `translate3d(${position.x}px, 0, 0)`;
            }
        }
    }

    useEffect(() => {
        setCurrentSlide(0);
    }, []);

    useEffect(() => {
        if (!pressed && intervalTime) {
            resetTimeout();
            timeoutRef.current = setTimeout(nextSlide, intervalTime);
        }

        return () => {
            resetTimeout();
        };
    }, [currentSlide]);

    useEffect(() => {
        dragSlide();
    }, [position])

    const onMouseMove = (event) => {
        if (pressed) {
            setPosition({
                x: position.x + event.movementX,
            })
            event.preventDefault();
        }
    }

    const onMouseUp = () => {
        setPressed(false);
        sliderRef.current.style.transition = "ease 1000ms";
        snapX();
    }

    const onMouseDown = () => {
        resetTimeout();
        setPressed(true);

        const style = window.getComputedStyle(sliderRef.current)
        const matrix = style.transform || style.webkitTransform || style.mozTransform;

        const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

        const transformX = Number(matrixValues[4]);

        setPosition({ x: transformX });

        sliderRef.current.style.transition = "none";
    }

    return (
        <div className="slider-wrapper">
            <div
                ref={sliderRef}
                className="slides-container"
                onMouseMove={onMouseMove}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
                style={{ transform: `translate3d(${-currentSlide * 100}%, 0, 0)` }}
            >
                {slidesData.map(x => transformFunction ? transformFunction(x) : x).map((slide, index) => {
                    return (
                        <div
                            className={`slide${index === currentSlide ? " current" : ""}`}
                            key={index}
                        >
                            {slide}
                        </div>
                    );
                })}
            </div>

            <div className="slideshow-dots">
                {slidesData.map((_, index) => (
                    <div
                        key={index}
                        className={`slideshow-dot${currentSlide === index ? " active" : ""}`}
                        onClick={() => {
                            setCurrentSlide(index);
                        }}
                    ></div>
                ))}
            </div>
        </div>
    )
};

export default Slider;
