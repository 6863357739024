import React, { useState } from 'react';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Paper } from '@material-ui/core';

import VideoPlayer from 'components/Shared/VideoPlayer';
import ApplicationCustomTooltip from 'components/MyApplications/ApplicationDetails/Application/ApplicationCustomTooltip';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import CalendlyDialog from 'components/Shared/CalendlyDialog';

import { DATE_SHORT_YEAR, FORMAT_TIMES_MAP } from 'constants/timesFormat';
import { CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS } from 'constants/candidateConstants';

import { ReactComponent as CalenldyLogo } from 'assets/calendly.svg';
import { ReactComponent as NoVideosAvailableIcon } from 'assets/no-matches-found.svg';

import './InterviewRecordingsSection.scss';

const InterviewRecordingsSection = ({
    title,
    videos,
    hasBookedInterview,
    calendlyInterviewInfo,
    isAdminMode,
    isCandidateMode,
    candidateJourneyInfo,
    calendlyInfo,
    handleEventScheduling,
    isDeleted,
}) => {
    const hasVideos = videos && videos?.length > 0;
    const [showTooltip, setShowTooltip] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getDate = (date) => moment(date).format(DATE_SHORT_YEAR);

    const getHour = (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format(FORMAT_TIMES_MAP.TIME);
        const endDateFormatted = moment(endDate).format(FORMAT_TIMES_MAP.TIME);

        return `${startDateFormatted} - ${endDateFormatted}`;
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    }

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    }

    const isVideoIntroductionReached = candidateJourneyInfo?.initialJourney?.completedSteps ? candidateJourneyInfo?.initialJourney?.completedSteps.find(x => x._id === CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.CV_COMPLETION) : false;

    return (
        ((hasVideos && isCandidateMode) || isAdminMode) &&
        <div className="video-recordings">
            <div className="recordings-wrapper">
                {(hasBookedInterview && isAdminMode)
                    ?
                    <div className="video-recordings-wrapper calendly-wrapper">
                        <div className="candidate-interview-face-to-face">
                            <CalenldyLogo className="icon" />
                            <p>
                                This candidate was invited to a Calendly call.
                            </p>
                        </div>
                        <div className="face-to-face-footer">
                            <div className="info-item"><span className="bold">Date: </span>{getDate(calendlyInterviewInfo.startDateTime)}</div>
                            <div className="info-item"><span className="bold">Hour: </span>{getHour(calendlyInterviewInfo.startDateTime, calendlyInterviewInfo.endDateTime)}</div>
                            <div className="info-item"><span className="bold">Recruiter: </span>{calendlyInterviewInfo.recruiter}</div>
                            <div className="info-item"><span className="bold">Status: </span>{calendlyInterviewInfo.status}</div>
                        </div>
                    </div>
                    : (!hasVideos && isAdminMode)
                        ?
                        <Paper className="no-videos-available-wrapper">
                            <Typography className="title">Video Introduction</Typography>
                            <div className="no-videos-available-content">
                                <NoVideosAvailableIcon className="no-videos-available-icon" />
                                <div className="no-videos-available-message">
                                    <p className="no-videos-available-text">No video available for this candidate</p>
                                    <InfoOutlinedIcon
                                        className="global-information-icon"
                                        onMouseEnter={() => setShowTooltip(true)}
                                        onMouseLeave={() => setShowTooltip(false)}
                                    />
                                    {showTooltip &&
                                        <ApplicationCustomTooltip styles={{ width: '285px', top: '30px', right: '-35px' }}>
                                            No video is available for this candidate, as they have successfully completed an interview with our team.
                                        </ApplicationCustomTooltip>
                                    }
                                </div>
                                {isVideoIntroductionReached && !isDeleted &&
                                    <div className="action-buttons-wrapper">
                                        <PrimaryButton
                                            text={'Invite via Calendly'}
                                            handleClick={handleOpenDialog}
                                        />
                                    </div>
                                }
                            </div>
                        </Paper>
                        : <div className="video-recordings-wrapper">
                            <Typography variant="h5" className="title">{title}</Typography>
                            {videos.map(video => (
                                <div key={video.question._id} className="recording-wrapper">
                                    <Typography variant="h6" className="sub-title">Question</Typography>
                                    <p className="question">{video.question.transcription}</p>
                                    <VideoPlayer videoUrl={video.temporarySignedUrl} />
                                </div>
                            ))}
                        </div>
                }
            </div>

            {
                isDialogOpen &&
                <CalendlyDialog
                    handleClose={handleCloseDialog}
                    calendlyInfo={calendlyInfo}
                    handleEventScheduling={handleEventScheduling}
                    isAdminMode={isAdminMode}
                />
            }
        </div>
    )
}

export default InterviewRecordingsSection;
