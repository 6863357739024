import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { ErrorMessage } from 'formik';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';

import PasswordInput from 'components/Common/PasswordInput';
import FieldError from 'components/Shared/FieldError';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import { ReactComponent as GoogleIcon } from 'assets/google.svg';

import { signInWithPopup } from 'utils/firebase';

import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { AUTH_MESSAGES } from 'constants/messageConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import { getHomePath } from 'utils/navigationUtils';
import { logEvent } from 'utils/amplitude';
import { getReferrer, getGCLID } from 'utils/queryUtils';

import '../Login.scss';

const LoginFormView = (props) => {
    const history = useHistory();

    const handleSSOLogin = (event) => {
        /* 
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isSSOLogin: true } });

        const referrer = getReferrer();
        const gclid = getGCLID();

        event.preventDefault();
        props.loginSuccess(false);
        signInWithPopup(null, null, referrer, gclid, null, true)
            .then(async (user) => {
                user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: user.role, type: 'SSO' });
                await props.login(user.email, null, false, false, true, user.credential);
                const homePath = getHomePath(history.location.state?.gotoUrl);
                history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });

                props.loginSuccess(true);
            })
            .catch(err => {
                props.createSSOLoggingRecord({ message: err.message });
                // making a request to the backend will trigger refreshing the token and storing it
                // so we need to trigger logout to clear it
                props.logout().then(() => {
                    if (err.message === AUTH_MESSAGES.NO_ACCOUNT_ASSOCIATED_WITH_EMAIL) {
                        props.showNotification(err.message, NOTIFICATION_TYPES.INFO);
                        history.push(`/register`);
                    }
                });
            })
    };

    return (
        <form className={`login-form${props.blur ? ' blur' : ''}`} autoComplete="off">
            <div>
                <TextField
                    fullWidth
                    required
                    className="login-field"
                    name="email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    id="login-email"
                    label="Email"
                    error={props.errors.email && props.touched.email}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="email" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="login-field"
                    name="password"
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    label="Password"
                    id="login-password"
                    error={props.errors.password}
                    touched={props.touched.password}
                />
                <ErrorMessage name="password" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
            </div>

            <div className="pwd-options">
                <FormControlLabel
                    className="remember-pwd-label"
                    name="rememberMe"
                    value={props.values.rememberMe}
                    onChange={props.handleChange}
                    control={<Checkbox className="remember-pwd-checkbox" color="primary" />}
                    label="Remember me"
                    labelPlacement="end"
                />
                <Link className="forgotten-pwd-link" component={RouterLink} to="/forgottenpassword">
                    Forgot Password
                </Link>
            </div>

            <div className="login-btns">
                {!isMobile &&
                    <SecondaryButton text="Sign Up" component={RouterLink} to={{ pathname: props.signUpButtonLink, state: { gotoUrl: props.gotoUrl } }} />
                }
                <PrimaryButton
                    text={props.loginLoading ? 'Login' : <CircularProgress color={'inherit'} size={16} />}
                    className={`${isMobile ? 'login-mobile-btn' : 'login-btn'}`}
                    type="submit"
                    handleClick={props.handleSubmit}
                />


                {isMobile &&
                    <p className="sign-up-link">
                        Haven't joined yet? <Link component={RouterLink} to={{ pathname: props.signUpButtonLink, state: { gotoUrl: props.gotoUrl } }}>Sign up now</Link>
                    </p>
                }
            </div>
            <div className={`text-divider${isMobile ? ' mobile' : ''}`}><p className="divider-content">or</p></div>
            <button className={`google-btn${isMobile ? ' mobile' : ''}`} onClick={handleSSOLogin}>
                <GoogleIcon />
                <p className="google-btn-text">login with google</p>
            </button>
        </form>
    )
};

export default LoginFormView;
