import { ROLES } from 'constants/userConstants';
import { getAdmins, getClientAdmins } from 'reducers';

export const ADMIN_ACCOUNTS_TABLE_COLUMNS = {
    [ROLES.ADMIN]: [
        { name: 'Name', value: 'name', isSortable: true },
        { name: 'Role', value: 'position', isSortable: true },
        { name: 'Date added', value: 'adminSince', isSortable: true },
    ],
    [ROLES.CLIENT]: [
        { name: 'Name', value: 'name', isSortable: true },
        { name: 'Email', value: 'email', isSortable: true },
        { name: 'Date added', value: 'registrationDate', isSortable: true },
    ]
};

export const DEFAULT_ADMIN_ACCOUNTS_TABLE_COLUMNS = {
    [ROLES.ADMIN]: ['name', 'position', 'adminSince'],
    [ROLES.CLIENT]: ['name', 'email', 'registrationDate'],
};

export const ADMIN_ACCOUNTS_TABLE_SORT_QUERY_SELECTOR = 'adminAccountsSort';
export const ADMIN_ACCOUNTS_TABLE_PAGE_QUERY_SELECTOR = 'adminAccountsTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: 'name',
    sortByDir: 'desc',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: ADMIN_ACCOUNTS_TABLE_PAGE_QUERY_SELECTOR,
    sortBy: ADMIN_ACCOUNTS_TABLE_SORT_QUERY_SELECTOR,
    sortByDir: `${ADMIN_ACCOUNTS_TABLE_SORT_QUERY_SELECTOR}Dir`,
};

export const ACTION_BUTTON_TEXT = {
    [ROLES.ADMIN]: 'Assign New Admin',
    [ROLES.CLIENT]: 'Add New Admin',
}

export const DATA_SELECTOR = {
    [ROLES.ADMIN]: getAdmins,
    [ROLES.CLIENT]: getClientAdmins,
}
