import React from 'react';

import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { ErrorMessage } from 'formik';

import SecondaryButton from '../Buttons/SecondaryButton';

const RejectInterviewAndApplicationDialogForm = ({
    handleChange,
    values,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
}) => {
    return (
        <form className="reject-interview-and-application-form" autoComplete="off">
            <div>
                <p className="feedback-msg">The feedback you provide in the following field will be sent to the candidate. Please provide constructive and comprehensive comments.</p>

                <TextField
                    inputProps={{ "data-testid": "reject-feedback-field" }}
                    className="reject-feedback-field"
                    name="comment"
                    value={values.comment}
                    onChange={handleChange}
                    id="reject-interview-and-application-feedback"
                    label="Overall Feedback"
                    error={errors.comment && touched.comment}
                    margin="normal"
                    variant="outlined"
                    multiline
                    minRows='6'
                />
                <ErrorMessage name="comment" component="div" className="invalid-field-message" />
            </div>

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" text="Discard" handleClick={() => handleCloseDialog()} />
                <SecondaryButton buttonStyle="save" text="Send" handleClick={handleSubmit} type="submit" data-testid="send-feedback-btn" />
            </DialogActions>
        </form>
    )
};

export default RejectInterviewAndApplicationDialogForm;
