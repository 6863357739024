import api from './api';
import requester from './requester';

const invoiceService = {
    addInvoice: (agencyId, userId, invoiceData, senderRole) => 
    requester(api.addInvoice(agencyId, userId, senderRole)).uploadFile(invoiceData),
    updateInvoiceStatus: (agencyId, invoiceId, statusData) =>
        requester(api.updateInvoiceStatus(agencyId, invoiceId)).updatePatch(statusData),
    fetchInvoices: (agencyId, query) =>
        requester(api.fetchInvoices(agencyId, query)).getMany(),
    downloadInvoiceFiles: (agencyId, userId, invoiceId, fileName) =>
        requester(api.downloadInvoiceFiles(agencyId, userId, invoiceId)).saveAs(fileName),
    exportInvoiceTimesheets: (agencyId, invoiceId, month, fileName, projectData, userId, userRole) => 
        requester(api.exportInvoiceTimesheets(agencyId, invoiceId, month, userId, userRole)).saveAsAction(fileName, projectData),
    resendInvoiceEmail: (agencyId, userId, invoicesData) =>
        requester(api.resendInvoiceEmail(agencyId, userId)).create(invoicesData),
    editInvoice: (agencyId, userId, invoiceId, invoiceData) =>
        requester(api.invoice(agencyId, invoiceId, userId)).updateFile(invoiceData),
    deleteInvoice: (agencyId, invoiceId) =>
        requester(api.invoice(agencyId, invoiceId)).delete(),
};

export default invoiceService;
