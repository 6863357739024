import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    addProjectFiles,
    fetchProjectDocuments,
    addProjectLink,
    downloadProjectFile,
    deleteProjectFile,
} from 'actions/clientActions';

import ClientProjectDocumentsTable from './ClientProjectDocumentsTable';


const ClientProjectDocuments = ({
    projectId,
    agencyId,
    clientId,
    addProjectFiles,
    fetchProjectDocuments,
    addProjectLink,
    downloadProjectFile,
    deleteProjectFile,
    isAdminMode,
}) => {
    
    useEffect(() => {
        fetchProjectDocuments(agencyId, clientId, projectId);
    }, []);

    return (
        <div className="client-project-documents-wrapper">
            <ClientProjectDocumentsTable
                projectId={projectId}
                agencyId={agencyId}
                clientId={clientId}
                addProjectFiles={addProjectFiles}
                addProjectLink={addProjectLink}
                downloadProjectFile={downloadProjectFile}
                deleteProjectFile={deleteProjectFile}
                isAdminMode={isAdminMode}
            />
        </div>
    );
};

const mapDispatchtoProps = {
    addProjectFiles,
    addProjectLink,
    fetchProjectDocuments,
    downloadProjectFile,
    deleteProjectFile,
};

export default connect(null, mapDispatchtoProps)(ClientProjectDocuments);
