import React from 'react';
import { connect } from 'react-redux';

import { updateWorkspaceMigrationInfo } from 'actions/employeeActions';
import { getAgencyId, getIsWorkspaceMigrationDialogOpen, getUserId } from 'reducers';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import PrimaryButton from '../Buttons/PrimaryButton';

import { ReactComponent as MessageIcon } from 'assets/workspace-message-icon.svg';
import { ReactComponent as CollaborationIcon } from 'assets/workspace-collaboration-icon.svg';
import { ReactComponent as CalendarIcon } from 'assets/workspace-calendar-icon.svg';

import './WorkspaceMigrationDialog.scss';

const WorkspaceMigrationDialog = ({
    isDialogOpen,
    updateWorkspaceMigrationInfo,
    agencyId,
    userId,
}) => {
    const handleCloseDialog = () => updateWorkspaceMigrationInfo(agencyId, userId, true);

    const handleLearnMoreClick = () => {
        window.open('https://crystal-cornucopia-b09.notion.site/Motion-Software-s-Google-Migration-to-Exadel-ea356f538650416ca39a044219e4d97c', '_blank', 'noreferrer')
    }

    return (
        <Dialog className="workspace-migration-dialog" open={isDialogOpen} onClose={handleCloseDialog}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">Our Google Workspace is migrating to Exadel</Typography>
                <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent className="workspace-dialog-content">
                <p>
                    All accounts of Motion Software employees who use a company email and Google services such as Drive, Calendar, GCP, etc. will be migrated to Exadel's infrastructure. To ensure the transition is smooth, please follow carefully any further communication on the topic. This project aims at bringing the following benefits to all of us:
                </p>
                <div className="benefits-wrapper">
                    <MessageIcon />
                    <div className="text-container">
                        <p className="title">Effortless internal communication</p>
                        <p className="subtitle">Quickly search for individuals under one directory</p>
                    </div>
                </div>
                <div className="benefits-wrapper">
                    <CollaborationIcon />
                    <div className="text-container">
                        <p className="title">Enhanced collaboration</p>
                        <p className="subtitle">Easily share files with internal members</p>
                    </div>
                </div>
                <div className="benefits-wrapper">
                    <CalendarIcon />
                    <div className="text-container">
                        <p className="title">Faster scheduling</p>
                        <p className="subtitle">Rapidly coordinate meetings with improved calendar functionality</p>
                    </div>
                </div>
                <p>If you have more questions about the migration please send email to <a href='mailto:migrationfaqs@motion-software.com'>migrationfaqs@motion-software.com.</a></p>
                <div className="buttons-wrapper">
                    <PrimaryButton
                        className="read-more-btn"
                        text="Read More"
                        handleClick={handleLearnMoreClick}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
};

const mapStateToProps = (state) => ({
    isDialogOpen: getIsWorkspaceMigrationDialogOpen(state),
    agencyId: getAgencyId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    updateWorkspaceMigrationInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMigrationDialog);
