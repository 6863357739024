import React from 'react';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';

import './CvProfileSummary.scss';

const CvProfileSummary = ({
    profileSummary,
    enterEditMode,
    enableEdit,
    displayMode,
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="profile-summary-wrapper">
                <div className="header">
                    <h1 className="title">
                        PROFILE SUMMARY
                    </h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('profileSummary')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>
                {profileSummary
                    ? <p className="summary">{profileSummary}</p>
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
};

export default CvProfileSummary;
