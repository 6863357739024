import React from 'react';

import { Typography } from '@material-ui/core';

import './Section.scss';

const Section = ({
    title,
    subTitle,
    actionComponent,
    children
}) => {
    return (
        <div className="section-wrapper">
            <div className="section-heading">
                <Typography variant="h1">{title}</Typography>
                {actionComponent}
            </div>

            { subTitle && <Typography variant="body1">{subTitle}</Typography> }

            {children}
        </div>
    )
};

export default Section;
