import React from 'react';

import MissingInformation from '../MissingInformation';

import EditSharpIcon from '@material-ui/icons/EditSharp';

import { ROLES } from 'constants/userConstants';
import './CvSkills.scss';

const CvSkills = ({
    skills,
    enterEditMode,
    enableEdit,
    displayMode
}) => {
    const isClient = displayMode === ROLES.CLIENT;

    return (
        <>
            <div className="cv-skills-wrapper">
                <div className="header">
                    <h1 className="title">
                        Skills
                    </h1>
                    {enableEdit &&
                        <div className="item-edit-icon-wrapper" onClick={() => enterEditMode('skills')}>
                            <EditSharpIcon className="item-edit-icon" />
                        </div>
                    }
                </div>
                {skills?.length > 0
                    ?
                    <div className="skills-container">
                        {skills.map((s, idx) =>
                            <div className="skill-box" key={`${idx}-${s._id}`}>{s.name}</div>
                        )}
                    </div>
                    : isClient ? <div className="empty-category" /> : <MissingInformation />
                }
            </div>
        </>
    )
};

export default CvSkills;
