export const CANDIDATES_CHECKLIST_HEAD_CELLS = [
    { title: 'Name', value: 'name' },
    { title: 'Status', value: 'status' },
    { title: 'Last Change', value: 'lastChange' },
];

export const CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES = {
    ACTION_REQUIRED: 'Action required',
    ALL_STEPS_COMPLETED: 'All steps completed',
};

export const INITIAL_CADNIDATE_CHECKLIST_STATUS_FILTER = '';
export const INITIAL_SEARCH_TERM_FILTER = '';

export const INITIAL_CANDIDATES_CHECKLIST_FILTERS = {
    status: INITIAL_CADNIDATE_CHECKLIST_STATUS_FILTER,
    searchTerm: INITIAL_SEARCH_TERM_FILTER,
};

export const CANDIDATES_CHECKLIST_FILTER_LIST_ITEMS = {
    status: { name: 'Status', values: Object.values(CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES) },
};

export const INITIAL_QUERY_STRING_CANIDATES_CHECKLIST = `?status=${encodeURIComponent(CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES.ACTION_REQUIRED)}`;

export const CANDIDATES_CHECKLIST_TABLE_ROWS_OPTIONS = [30, 50, 100];
export const INITIAL_TABLE_ROWS = 30;
