import { CANDIDATE_REJECTION_REASONS_KEYS, CANDIDATE_STATUSES } from 'constants/candidateConstants';

export const getWelcomeInformation = (personalInfo, preferences) => {
    switch (personalInfo?.status) {
        case CANDIDATE_STATUSES.VETTED:
            return { text: `Let's pick up where you left off by browsing the list of new and exciting remote job opportunities below and submitting your application.` }
        case CANDIDATE_STATUSES.REJECTED:
            let showButton;
            let buttonText;
            let text;

            switch (personalInfo?.rejectionReason) {
                case CANDIDATE_REJECTION_REASONS_KEYS.UNSUITABLE_LOCATION:
                    showButton = true;
                    buttonText = 'Update';
                    text = `Your profile has been rejected as you've selected an unsupported location for your desired tax residency${preferences?.countryOfTax ? ` (${preferences?.countryOfTax})` : ''}. To be eligible to submit a job application, please update your profile preferences.`;
                    break;
                case CANDIDATE_REJECTION_REASONS_KEYS.UNREASONABLE_REMUNERATION:
                    showButton = true;
                    buttonText = 'Update';
                    text = `Your profile has been rejected as your desired monthly net salary doesn't reflect the current market conditions. To be eligible to submit a job application, please update your profile preferences.`;
                    break;
                case CANDIDATE_REJECTION_REASONS_KEYS.LACKING_SKILLS:
                    showButton = true;
                    buttonText = 'Request';
                    text = `You need to update your profile before being able to submit your job applications. Click the button below to request a new review from our team.`;
                    break;
                default:
                    break;
            }

            return {
                showButton,
                buttonText,
                text
            }
        case CANDIDATE_STATUSES.INCOMPLETE_PROFILE:
            return { text: '' }
        default:
            return { text: `Your profile is still being reviewed by our team, but you can already submit your job application.` }
    }
};
