import React, { createContext, Fragment, useContext, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { fetchSearchResults } from 'actions/searchActions';
import { getAgencyId, getSearchResultsWithoutDeleted, getDisplayMode } from 'reducers';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';

import SupplierAdminMenu from 'components/Shared/Search/SearchDropdown/DropdownMenus/SupplierAdminMenu';
import SupplierEmployeesMenu from 'components/Shared/Search/SearchDropdown/DropdownMenus/SuppliersEmployeesMenu';
import PeopleMenu from 'components/Shared/Search/SearchDropdown/DropdownMenus/PeopleMenu';
import CompaniesMenu from './DropdownMenus/CompaniesMenu';
import ClientsMenu from './DropdownMenus/ClientsMenu';
import CandidatesMenu from './DropdownMenus/CandidatesMenu';
import ProjectsMenu from './DropdownMenus/ProjectsMenu';
import TeamMenu from './DropdownMenus/TeamMenu';
import ApplicantsMenu from './DropdownMenus/ApplicantsMenu';

import { SearchContext } from '../Search';
import { ROLES } from 'constants/userConstants';
import { SEARCH_RESULTS_LIMIT } from 'constants/commonConstants';

import './SearchDropdown.scss';

export const DropdownMenuContext = createContext();
const { Provider } = DropdownMenuContext;

const SearchDropdown = ({
    dropdownTitle,
    displayMode,
    agencyId,
    searchResults,
    children,
    displayForStringInDropdown,
    displayDropdownTitleAsButton,
    fetchSearchResults,
}) => {
    const history = useHistory();
    const searchDropdownRef = useRef(null);
    const isSearchPage = history.location.pathname.includes('search');

    //NOTE: Here you define, which roles you want to fetch from the database
    const roles = [...React.Children.toArray(children)].reduce((roles, child) => {
        const mapper = {
            [ROLES.ADMIN]: roles => roles.filter(role => [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN, ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE, ROLES.CLIENT, ROLES.CANDIDATE].includes(role)),
            [ROLES.CLIENT]: roles => roles.filter(role => [ROLES.EMPLOYEE, ROLES.SUPPLIER_EMPLOYEE, ROLES.CANDIDATE].includes(role)),
            [ROLES.SUPPLIER_ADMIN]: roles => roles.filter(role => [ROLES.SUPPLIER_EMPLOYEE, ROLES.SUPPLIER_ADMIN].includes(role)),
            [ROLES.SUPPLIER_EMPLOYEE]: roles => roles.filter(role => [].includes(role)),
            [ROLES.EMPLOYEE]: roles => roles.filter(role => [].includes(role)),
            [ROLES.CANDIDATE]: roles => roles.filter(role => [].includes(role)),
            [ROLES.RECRUITER]: roles => roles.filter(role => [ROLES.RECRUITER_EMPLOYEE].includes(role)),
        }
        if (child.type.name === SupplierAdminMenu.name) {
            roles = Array.from(new Set([ROLES.SUPPLIER_ADMIN, ...roles]));
        }

        if (child.type.name === PeopleMenu.name) {
            roles = Array.from(new Set([
                ROLES.ADMIN,
                ROLES.EMPLOYEE,
                ROLES.SUPPLIER_ADMIN,
                ROLES.SUPPLIER_EMPLOYEE,
                ROLES.CLIENT,
                ROLES.RECRUITER_EMPLOYEE,
                ...roles
            ]));
        }

        if (child.type.name === CompaniesMenu.name) {
            roles = Array.from(new Set([
                ROLES.ADMIN,
                ROLES.SUPPLIER_ADMIN,
                ROLES.CLIENT,
                ...roles
            ]));
        }

        if (child.type.name === CandidatesMenu.name) {
            roles = Array.from(new Set([
                ROLES.ADMIN,
                ROLES.EMPLOYEE,
                ROLES.SUPPLIER_ADMIN,
                ROLES.SUPPLIER_EMPLOYEE,
                ROLES.CLIENT,
                ROLES.RECRUITER_EMPLOYEE,
                ROLES.CANDIDATE,
                ...roles
            ]));
        }

        return mapper[displayMode](roles);
    }, [])

    const { debouncedSearchTerm, handleLoader, loader, showResultsDropdown, searchTerm, handleClick, handleBlur, handleClickTitle, alwaysShowSuggestions } = useContext(SearchContext);


    useEffect(() => {
        if ((debouncedSearchTerm || alwaysShowSuggestions) && roles.length > 0) {
            handleLoader(true);
            fetchSearchResults(agencyId, debouncedSearchTerm, roles, false, SEARCH_RESULTS_LIMIT).then(() => {
                handleLoader(false)
            });
        }

        if (isSearchPage) {
            const detailedInformation = true;
            fetchSearchResults(agencyId, debouncedSearchTerm, roles, detailedInformation);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        /* 
        This prevents scrolling the parent component when we hit the bottom or the top of the SearchDropdown (scroll chaining).
        Basically something like css `overscroll-behavior: none`, but with JS, because of browser support.
        */
        function handleScrollSearchDropdown(e) {
            const { scrollTop, scrollHeight, clientHeight } = searchDropdownRef.current;
            const delta = e.deltaY;
            const isDeltaPositive = delta > 0;

            if ((isDeltaPositive && scrollTop + clientHeight >= scrollHeight) || (!isDeltaPositive && scrollTop <= 0)) {
                e.preventDefault();
                e.stopPropagation();
            }
        }

        if (searchDropdownRef?.current) {
            searchDropdownRef.current.addEventListener('wheel', handleScrollSearchDropdown);
        }

        return () => {
            if (searchDropdownRef?.current) {
                searchDropdownRef.current.removeEventListener('wheel', handleScrollSearchDropdown);
            }
        }
    }, [searchDropdownRef?.current, showResultsDropdown]);

    const context = {
        searchResults,
        handleClick,
        handleBlur,
        children,
        displayMode,
        loader
    }

    return !isSearchPage && (
        <Provider value={context}>
            {showResultsDropdown &&
                <div className='search-results-dropdown' ref={searchDropdownRef} onMouseDown={(e) => e.preventDefault()}>
                    <>
                        {handleClickTitle && (displayMode === ROLES.ADMIN || displayMode === ROLES.CLIENT) && dropdownTitle
                            ?
                            <IconButton disableFocusRipple className='icon-button' onClick={handleClickTitle.bind(null, handleBlur, searchResults, searchTerm)}>
                                <SearchIcon className="search-icon icon" />
                                <Typography className={`all-results-title ${displayDropdownTitleAsButton ? 'button-style' : ''}`} variant="h5" >{(dropdownTitle) + ` ${displayForStringInDropdown !== false ? 'for' : ''} "${searchTerm}"`}</Typography>
                            </IconButton>
                            : dropdownTitle ? <Typography className="all-results-title" variant="h5" >{(dropdownTitle) + ` for "${searchTerm}"`}</Typography> : null}
                    </>


                    <Fragment className='search-results-dropdown-result'>
                        {loader ? <CircularProgress size={25} disableShrink className="search-bar-loader" />
                            : children
                        }
                    </Fragment>

                </div>
            }
        </Provider>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    displayMode: getDisplayMode(state),
    searchResults: getSearchResultsWithoutDeleted(state)
});

const mapDispatchToProps = {
    fetchSearchResults
};

SearchDropdown.SupplierAdminMenu = SupplierAdminMenu;
SearchDropdown.SupplierEmployeesMenu = SupplierEmployeesMenu;
SearchDropdown.PeopleMenu = PeopleMenu;
SearchDropdown.CompaniesMenu = CompaniesMenu;
SearchDropdown.ClientsMenu = ClientsMenu;
SearchDropdown.CandidatesMenu = CandidatesMenu;
SearchDropdown.ProjectsMenu = ProjectsMenu;
SearchDropdown.TeamMenu = TeamMenu;
SearchDropdown.ApplicantsMenu = ApplicantsMenu;

export default connect(mapStateToProps, mapDispatchToProps)(SearchDropdown);
