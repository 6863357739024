import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import InvoicePopover from '../InvoicePopover';
import Tooltip from '@material-ui/core/Tooltip';
import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { formatStringAsClassName, getStateAgencyName } from 'utils/helpers';

import './InvoiceCard.scss';

const InvoiceCard = ({
    invoice,
    downloadInvoice,
    downloadTimesheets,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClosePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleDotsClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className="invoice-card-wrapper">
            <Link className="invoice-card-link-wrapper" to={`/${getStateAgencyName()}/billing`}>
                <div className="invoice-project-name-wrapper cell">
                    <span>{invoice.projectName}</span>
                </div>

                <div className="invoice-number-wrapper cell">
                    <Tooltip title={`Invoice number ${invoice.invoiceNumber}`} classes={{ tooltip: 'custom-tooltip' }}>
                        <span>{invoice.invoiceNumber}</span>
                    </Tooltip>
                </div>

                <div className="invoice-dates-wrapper cell">
                    <Tooltip title="Issue date and due date" classes={{ tooltip: 'custom-tooltip' }}>
                        <span>
                            {moment(invoice.issueDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)} - {moment(invoice.dueDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                        </span>
                    </Tooltip>
                </div>

                <div className="invoice-amount-wrapper cell">
                    <span>{(invoice.amount)?.toFixed(2)} {invoice.currency}</span>
                </div>

                <div className="invoice-status-wrapper cell">
                    <div className={`invoice-status-label ${formatStringAsClassName(invoice.status)}`}>
                        <span>{invoice.status}</span>
                    </div>
                </div>

                <div className="invoice-actions-wrapper cell">
                    <div className="actions-icon-wrapper" onClick={handleDotsClick}>
                        <ThreeDotsIcon />
                    </div>
                </div>

            </Link>

            <InvoicePopover
                anchorEl={anchorEl}
                handleClose={handleClosePopover}
                downloadTimesheets={downloadTimesheets.bind(null, invoice)}
                downloadInvoice={downloadInvoice.bind(null, invoice)}
            />
        </div>
    );
};

export default InvoiceCard;
