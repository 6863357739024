import React from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { ReactComponent as CheckIcon } from 'assets/icon-check.svg';
import { ReactComponent as FilterListIcon } from 'assets/icon-material-filter-list.svg';

import './SelectFilterList.scss'

const SelectFilterList = ({
    name,
    value,
    title,
    items,
    handleChange,
    closeMenuOnSelect,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className="multi-filter-list-wrapper">
            <Button
                className="multi-filter-list-button"
                variant="outlined"
                onClick={handleOpen}
            >
                <FilterListIcon className="filter-list-icon" />
                <span>{title}</span>
            </Button>
            <Menu
                className="filter-list-menu"
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '130px',
                        marginTop: '10px',
                        marginLeft: '9px',
                        boxShadow: '0px 1px 6px #00000033'
                    }
                }}
            >
                {items.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className={value === item ? "filter-list-menu-item selected" : "filter-list-menu-item"}
                            onClick={() => {
                                handleChange(name, item);

                                if (closeMenuOnSelect) {
                                    handleClose();
                                }
                            }}
                        >
                            <span>{item}</span>
                            {value === item && <CheckIcon className="check-icon" />}
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
};

export default SelectFilterList;
