import React from 'react';

import { COUNTRY_ABBREVIATIONS } from './CountryAbbreviationsConstants';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import FieldError from 'components/Shared/FieldError';

const EmployeeContractInformationFormView = ({
    values,
    handleChange,
    errors,
    onInputChanged,
    isUsingEvrotrust,
    contractInformation,
    hiddenSections,
    agencyInfo,
}) => {
    const countryCode = COUNTRY_ABBREVIATIONS.find(x => x.name === contractInformation.country)?.code || 'US';

    return (
        <form className="contract-information-form" autoComplete="off">
            <div className="contract-information-input">
                <TextField
                    name="legalName"
                    label={`Legal Name (${countryCode})`}
                    type="text"
                    value={values.legalName}
                    onChange={handleChange}
                    onInput={onInputChanged}
                    error={!!errors.legalName}
                    variant="outlined"
                />
                <ErrorMessage name="legalName" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
            </div>
            <div className="contract-information-input">
                <TextField
                    name="position"
                    label={`Position (${countryCode})`}
                    type="text"
                    value={values.position}
                    onChange={handleChange}
                    onInput={onInputChanged}
                    error={!!errors.position}
                    variant="outlined"
                />
                <ErrorMessage name="position" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
            </div>
            {
                isUsingEvrotrust
                    ? <div className="contract-information-input email">
                        <TextField
                            name="evrotrustEmail"
                            label="Evrotrust Email"
                            type="email"
                            value={values.evrotrustEmail}
                            onChange={handleChange}
                            onInput={onInputChanged}
                            error={!!errors.position}
                            variant="outlined"
                        />
                        <ErrorMessage name="evrotrustEmail" component={(name) => <FieldError name={name} />} className="invalid-field-message" />
                    </div>
                    : null
            }
        </form>
    )
}

export default EmployeeContractInformationFormView;
