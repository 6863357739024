import React from 'react';
import PrimaryButton from '../PrimaryButton';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

const PrimaryAddButton = React.forwardRef((props, ref) => (
    <PrimaryButton
        ref={ref}
        icon={<AddButtonIcon />}
        {...props}
    />
));


export default PrimaryAddButton;
