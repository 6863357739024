import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import AddImportantDocumentsDialog from './AddImportantDocumentsDialog';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import {
    fetchAgencyImportantDocuments,
    uploadOnboardingDocuments,
    deleteImportantDocuments,
    downloadImportantDocuments
} from 'actions/agencyActions';
import { getImportantDocuments } from 'reducers';

import {
    IMPORTANT_DOCUMENTS_TABLE_COLUMNS,
    DEFAULT_IMPORTANT_DOCUMENTS_TABLE_COLUMNS,
    IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR,
    IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP,
} from './OnboardingDocumentsConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { usePreloadTable } from 'utils/hooks';

const OnboardingDocuments = ({
    agencyId,
    fetchAgencyImportantDocuments,
    uploadOnboardingDocuments,
    deleteImportantDocuments,
    downloadImportantDocuments
}) => {
    const [openAddImportantDocsDialog, setOpenAddImportantDocsDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState({});

    const tableFetchAction = fetchAgencyImportantDocuments.bind(null, agencyId);
    const dataSelector = (state, page) => getImportantDocuments(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction, reload, setReload);

    const handleOpenAddDialog = () => setOpenAddImportantDocsDialog(true);

    const handleCloseAddDialog = () => {
        setOpenAddImportantDocsDialog(false);
    };

    const addImportantDocumentsHandler = async (data) => {
        await uploadOnboardingDocuments(agencyId, data, false);
        setReload(true);
    };

    const handleDeleteClick = (document) => {
        setSelectedDocument(document);
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setSelectedDocument({});
    }

    const handleRemoveDocument = async () => {
        await deleteImportantDocuments(agencyId, selectedDocument, false);
        setReload(true);
        setOpenConfirmDialog(false);
        setSelectedDocument({});
    };

    const handleDownloadClick = document => {
        downloadImportantDocuments(agencyId, document._id, document.title);
    };

    const adjustImportantDocumentsKeysFunction = (stateData) => stateData.map(document => {
        return {
            title: document.title,
            uploadedOn: document.uploadedOn ? moment.utc(document.uploadedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR) : '-',
            item: document,
        }
    });

    const actions = [
        { title: 'Download', handleClick: handleDownloadClick },
        { title: 'Remove', handleClick: handleDeleteClick },
    ];

    const getPopoverActions = () => actions;

    return (
        <div className="onboarding-documents-wrapper">
            <TableWrapper
                title="Important Documents"
                columns={IMPORTANT_DOCUMENTS_TABLE_COLUMNS}
                defaultColumns={DEFAULT_IMPORTANT_DOCUMENTS_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={IMPORTANT_DOCUMENTS_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustImportantDocumentsKeysFunction}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverActions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={IMPORTANT_DOCUMENTS_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text={"Add New"}
                            icon={<AddButtonIcon />}
                            handleClick={handleOpenAddDialog}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <AddImportantDocumentsDialog
                openDialog={openAddImportantDocsDialog}
                handleCloseDialog={handleCloseAddDialog}
                addImportantDocumentsHandler={addImportantDocumentsHandler}
            />
            <ConfirmDeleteDialog
                itemToDelete={selectedDocument.title || ''}
                handleDeleteItem={() => handleRemoveDocument()}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    )
};

const mapDispatchToProps = {
    fetchAgencyImportantDocuments,
    uploadOnboardingDocuments,
    deleteImportantDocuments,
    downloadImportantDocuments
};

export default connect(null, mapDispatchToProps)(OnboardingDocuments);
