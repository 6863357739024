import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import ProfileLanguageRow from './ProfileLanguageRow/ProfileLanguageRow';

import { addLanguage, editLanguage, removeLanguage } from 'actions/employeeActions';
import { getEmployeeLanguages } from 'reducers'

import './ProfileLanguages.scss';

const ProfileLanguages = ({
    employeeId,
    agencyId,
    departmentId,
    languages,
    addLanguage,
    editLanguage,
    removeLanguage
}) => {
    const [newLanguages, setNewLanguages] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [languageToRemove, setLanguageToRemove] = useState({});

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setLanguageToRemove({});
    }

    const handleRemoveClick = language => {
        setOpenConfirmDialog(true);
        setLanguageToRemove(language);
    };

    const handleRemoveLanguage = language => {
        language.isNew
            ? setNewLanguages(state => state.filter((lang) => lang._id !== language._id))
            : removeLanguage(language._id, employeeId, agencyId, departmentId);

        setOpenConfirmDialog(false);
    };

    const handleAddLanguage = () => {
        setNewLanguages(state => [...state, { _id: uuidv4(), name: '', level: '', isNew: true }]);
    };

    const handleChange = (e, language) => {
        const updatedLanguage = { ...language, [e.target.name]: e.target.value };

        if (updatedLanguage.name && updatedLanguage.level) {
            if (updatedLanguage.isNew) {
                setNewLanguages(state => state.filter((lang) => lang._id !== updatedLanguage._id));
                addLanguage(updatedLanguage, employeeId, agencyId, departmentId);
            } else {
                editLanguage(updatedLanguage, employeeId, agencyId, departmentId);
            }
        } else {
            setNewLanguages(state => {
                const index = state.findIndex(x => x._id === updatedLanguage._id);
                const newState = state.slice();
                newState.splice(index, 1, updatedLanguage);

                return newState;
            });
        }
    };

    return (
        <div className="profile-info-wrapper profile-languages-info-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Languages'
                />

                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text='Add Language'
                        onClick={handleAddLanguage}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <Paper className="profile-info-content">
                {newLanguages.concat(languages).map((language) =>
                    <ProfileLanguageRow
                        key={language._id}
                        language={language}
                        removeLanguage={handleRemoveClick}
                        handleChange={handleChange}
                    />
                )}
            </Paper>

            <ConfirmDeleteDialog
                itemToDelete={languageToRemove.name || 'this'}
                handleDeleteItem={() => handleRemoveLanguage(languageToRemove)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    );
}

const mapStateToProps = state => ({
    languages: getEmployeeLanguages(state),
});

export default connect(mapStateToProps, {
    addLanguage,
    editLanguage,
    removeLanguage,
})(ProfileLanguages);
