import api from './api';
import requester from './requester';

const businessTripService = {
    createBusinessTrip: (agencyId, businessTripFormData) => requester(api.businessTrips(agencyId)).uploadFile(businessTripFormData),
    fetchBusinessTrips: (agencyId, query) => requester(api.businessTrips(agencyId, query)).getMany(),
    downloadBusinessTripFiles: (agencyId, businessTripId, fileName) => requester(api.businessTripFiles(agencyId, businessTripId)).saveAs(fileName),
    exportBusinessTrips: (agencyId, businessTripsIds, fileName) => requester(api.businessTripsExport(agencyId, businessTripsIds)).saveAs(fileName),
};

export default businessTripService;
