import React from 'react';

import moment from 'moment';
import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

import { invoiceStatuses, INVOICE_STATUSES_STRINGS } from '../BillingTable/BillingTableConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { CURRENCIES } from 'constants/currenciesConstant';

import isHoliday from 'hoc/isHoliday';

import './EditStatusFormView.scss';

const EditStatusFormView = ({
    values,
    errors,
    touched,
    handleChange,
    handleCloseDialog,
    handleSubmit,
    setFieldValue
}) => {
    return (
        <form className="edit-invoice-status-form" autoComplete="off">
            <div className="edit-invoice-status-field">
                <TextField
                    select
                    name="status"
                    label="Status"
                    value={values.status}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    error={errors.status && touched.status}
                >
                    {invoiceStatuses.map((invoiceStatus, i) => (
                        <MenuItem
                            key={i}
                            value={invoiceStatus.name}
                        >
                            {invoiceStatus.name}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="status" component="div" className="invalid-field-message" />
            </div>

            {values.status === INVOICE_STATUSES_STRINGS.PAID && 
                <div className="additional-status-fields">
                    <div className="edit-invoice-status-field">
                        <TextField
                            name="amountPaid"
                            value={values.amountPaid}
                            onChange={handleChange}
                            label="Amount Paid"
                            variant="outlined"
                            fullWidth
                            inputProps={{ inputMode: 'numeric', pattern: '^[+]?[0-9]\d*([\.\,]\d{1,2})?\s*$' }} 
                        />
                        <ErrorMessage name="amountPaid" component="div" className="invalid-field-message" />
                    </div>
                    
                    <div className="edit-invoice-status-field">
                        <TextField
                            select
                            label="Currency"
                            value={values.currencyPaid}
                            variant="outlined"
                            onChange={handleChange}
                            name="currencyPaid"
                            id="currencyPaid"
                            fullWidth
                        >
                            {CURRENCIES.map(currency => {
                                return (
                                    <MenuItem key={currency.VALUE} value={currency.LABEL}>{currency.LABEL}</MenuItem>
                                )
                            })}
                        </TextField>

                        <ErrorMessage name="currencyPaid" component="div" className="invalid-field-message" />
                    </div>

                    <div className="edit-invoice-status-field">
                        <KeyboardDatePicker
                            autoOk={true}
                            label="Payment Date"
                            name="paymentDate"
                            disableToolbar
                            maxDate={MAX_DATE}
                            variant="inline"
                            inputVariant="outlined"
                            value={values.paymentDate}
                            onChange={value => {
                                setFieldValue('paymentDate', moment(value));
                            }}
                            renderDay={isHoliday}
                            fullWidth
                            format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                            allowKeyboardControl={true}
                        />
                        <ErrorMessage name="paymentDate" component="div" className="invalid-field-message" />
                    </div>
                </div>
            }

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </form>
    );
};

export default EditStatusFormView;
