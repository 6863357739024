import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar, Paper } from '@material-ui/core';

import { getStateAgencyName } from 'utils/helpers';

import './CoachingMentoringCard.scss';

const CoachingMentoringCard = ({
    coachingOpportunity,
}) => {
    const cutText = (text, wordCount) => {
        const cutElements = text.split(' ').slice(0, wordCount);

        if (text.split(' ').length > cutElements.length) {
            cutElements.push('...');
        }
        
        return cutElements.join(' ');
    };

    return (
        <Paper className="coaching-mentoring-card-wrapper">
            <Link
                className="coaching-mentoring-card-link"
                to={`/${getStateAgencyName()}/coaching-opportunities/${coachingOpportunity._id}`}
            >
                <div className="coaching-mentoring-card-content">
                    <div className="coaching-mentoring-card-header-information">
                        <div className="coach-avatar">
                            {coachingOpportunity.coachingOpportunityImgUrl ?
                                <Avatar
                                    alt="profile logo"
                                    className="profile-logo"
                                    src={coachingOpportunity.coachingOpportunityImgUrl}
                                />
                                : <Avatar className="profile-logo" />}
                        </div>
                        <div className="title-name-wrapper">
                            <div className="coaching-title">
                                <h1>{coachingOpportunity.title}</h1>
                            </div>
                            <div className="coach-name">
                                <h4>{coachingOpportunity.coachName}</h4>
                            </div>
                        </div>
                    </div>
                    {coachingOpportunity.languages?.length > 0 &&
                        <div className="languages-list">
                            {coachingOpportunity.languages.map((language, index) => (
                                <div key={coachingOpportunity._id + language + index} className="language-item">
                                    {language}
                                </div>
                            ))}
                        </div>
                    }
                    <div className="description-wrapper">
                        <p className="description">{cutText(coachingOpportunity.description, 30)}</p>
                    </div>
                    {coachingOpportunity.tags?.length > 0 &&
                        <div className="tags-list">
                            {coachingOpportunity.tags.map((tag, index) => (
                                <div key={coachingOpportunity._id + tag._id + index} className="tag-item">
                                    {tag.title}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            </Link>
        </Paper>
    )
};

export default CoachingMentoringCard;
