import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import SendProfilesDialog from 'components/Shared/SendProfilesDialog';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';
import Banner from 'components/Shared/Banner';

import { ReactComponent as BannerIcon } from 'assets/banner-user-check.svg';

import { getStateAgencyName } from 'utils/helpers';
import { logEvent } from 'utils/amplitude';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { DEFAULT_FILTER_QUERY } from 'constants/matchingConsultantsConstants';
import { ROLES } from 'constants/userConstants';

import './JobOptionsBanner.scss';

const JobOptionsBanner = ({
    jobId,
    job,
    isAdmin,
    agencyName,
    displayName,
}) => {
    const history = useHistory();

    const handleFindMatchClick = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.ADMIN_CLICK_FIND_MATCH_EVENT, { rolesPermitted: ROLES.ADMIN }, { jobUrl: window.location.href, fullName: displayName });
        history.push(`/${getStateAgencyName()}/jobs/${jobId}/matching-consultants${DEFAULT_FILTER_QUERY}`);
    };

    const [isSendProfilesDialogOpen, setIsSendProfilesDialogOpen] = useState(false);

    const handleOpenSendProfilesDialog = () => setIsSendProfilesDialogOpen(true);

    const handleCloseSendProfilesDialog = () => setIsSendProfilesDialogOpen(false);

    const getBannerText = () => {
        if (isAdmin) {
            return "By clicking \"Find a Match\" you will see all profiles on WIA (employees, partners or candidates) who match the client request. Using the other option gives you the ability to hand pick one or more profiles and send them to the client."
        } else {
            return `Using the button below enables you to handpick profiles of your available employees and send them for review to ${agencyName}.`
        }
    };

    return (
        <Fragment>
            <Banner
                title="Find a Match"
                content={getBannerText()}
                icon={<BannerIcon />}
                primaryButtonComponent={isAdmin ? <SecondaryButton
                    text="Find a Match"
                    handleClick={handleFindMatchClick}
                /> : null}
                secondaryButtonComponent={<PrimaryButton
                    className="send-profiles-button"
                    text="Send Profiles"
                    handleClick={handleOpenSendProfilesDialog}
                />}
            />

            {isSendProfilesDialogOpen &&
                <SendProfilesDialog
                    job={job}
                    isSelectDialogOpen={isSendProfilesDialogOpen}
                    handleCloseDialog={handleCloseSendProfilesDialog}
                />
            }
        </Fragment>
    );
};

export default JobOptionsBanner;
