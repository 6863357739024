import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import SearchMenuView from '../SearchMenuView';

import { DropdownMenuContext } from '../SearchDropdown';
import { ROLES } from 'constants/userConstants';

const TeamMenu = ({ compact }) => {
    const { searchResults, handleClick, handleBlur, displayMode } = useContext(DropdownMenuContext);

    const mapper = {
        [ROLES.EMPLOYEE]: (_) => [],
        [ROLES.SUPPLIER_ADMIN]: (_) => [],
        [ROLES.CLIENT]: (searchResults) => searchResults.filter(x => x.isProjectUsersResult),
        [ROLES.ADMIN]: (_) => [],
        [ROLES.RECRUITER]: (_) => [],
    };
    
    const results = mapper[displayMode](searchResults);
    
    let mappedResult = results.reduce((acc, project) => {
        for (const user of project.projectUsers) {
            acc.push({
                ...user,
                ...project,
                name: `${user.firstName} ${user.lastName}` || user.name,
                position: project.name,
            })
        }
        return acc;
    }, []);
    
    mappedResult = compact ? mappedResult.splice(0, 3) : mappedResult;

    return mappedResult.length > 0 ? (
        <div className="search-menu">
            <Typography variant="h5">Your Team</Typography>
            <SearchMenuView
                results={mappedResult}
                handleClick={handleClick}
                handleBlur={handleBlur}
            />
        </div>
    ) : null
};

export default TeamMenu;
