import React from 'react';
import { connect } from 'react-redux';

import { CircularProgress } from '@material-ui/core';

import { getResultClickRoute } from 'utils/searchUtils';

import { getSearchResults, getAgencyName, getDisplayMode, getDisplayName, getSearchDataFetchState } from 'reducers';
import { restoreUsers } from 'actions/userActions';

import NoMatchesFound from 'components/Shared/NoMatchesFound';
import SearchResultsAdminView from './SearchResultsViews/SearchResultsAdminView';
import SearchResultsClientView from './SearchResultsViews/SearchResultsClientView';

import { ROLES } from 'constants/userConstants';

import './SearchResults.scss';

const SearchResults = ({
    displayMode,
    results,
    restoreUsers,
    agencyName,
    history,
    displayName,
    dataFetchState,
}) => {
    const handleResultClick = (result) => history.push(getResultClickRoute(displayMode, result));

    return (
        <>
            { dataFetchState?.isDataFetching ? <CircularProgress size={50} className="page-loader"/> : 
                <>
                    { results.length === 0 ? <NoMatchesFound /> :
                        displayMode === ROLES.CLIENT ?
                            <SearchResultsClientView 
                                results={results}
                                agencyName={agencyName}
                                handleResultClick={handleResultClick}
                                displayName={displayName}
                            />
                        : 
                            <SearchResultsAdminView 
                                results={results}
                                restoreUsers={restoreUsers}
                                agencyName={agencyName}
                                handleResultClick={handleResultClick}
                            />
                    }
                </> 
            }
        </>
    ) 
}

const mapDispatchToProps = {
    restoreUsers
};

const mapStateToProps = (state) => ({
    results: getSearchResults(state),
    agencyName: getAgencyName(state),
    displayMode: getDisplayMode(state),
    displayName: getDisplayName(state),
    dataFetchState: getSearchDataFetchState(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
