import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { getClientProjectDocuments } from 'reducers';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import UploadMethodDialog from '../UploadMethodDialog';
import UploadLinkDialog from '../UploadLinkDialog';
import UploadFilesDialog from '../UploadFilesDialog';
import TableWrapper from 'components/Shared/TableWrapper';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { SORT_QUERY_SELECTOR, defaultTableColumns, tableColumns } from './ClientProjectDocumentsTableConstants';

import { parseQueryString } from 'utils/helpers';

const ClientProjectDocumentsTable = ({
    projectId,
    agencyId,
    clientId,
    addProjectFiles,
    addProjectLink,
    downloadProjectFile,
    deleteProjectFile,
    isAdminMode,
}) => {
    const history = useHistory();

    const [isUploadMethodDialogOpen, setIsUploadMethodDialogOpen] = useState(false);
    const [isUploadLinkDialogOpen, setIsUploadLinkDialogOpen] = useState(false);
    const [isUploadFilesDialogOpen, setIsUploadFilesDialogOpen] = useState(false);
    const [isConfirmRemoveDialogOpen, setIsConfirmRemoveDialogOpen] = useState(false);

    const [selectedItem, setSelectedItem] = useState({});

    const handleOpenUploadMethodDialog = () => setIsUploadMethodDialogOpen(true);
    const handleCloseUploadMethodDialog = () => setIsUploadMethodDialogOpen(false);

    const handleOpenUploadLinkDialog = () => setIsUploadLinkDialogOpen(true);
    const handleCloseUploadLinkDialog = () => setIsUploadLinkDialogOpen(false);

    const handleOpenUploadFilesDialog = () => setIsUploadFilesDialogOpen(true);
    const handleCloseUploadFilesDialog = () => setIsUploadFilesDialogOpen(false);

    const handleCloseConfirmRemoveDialog = () => {
        setIsConfirmRemoveDialogOpen(false);
        setSelectedItem({});
    };

    const handleOpenConfirmRemoveDialog = () => setIsConfirmRemoveDialogOpen(true);

    const handleRemoveDocument = async () => {
        await deleteProjectFile(agencyId, clientId, projectId, selectedItem._id, selectedItem.type);
        handleCloseConfirmRemoveDialog();
    };

    const getDocumentLink = () => {
        return selectedItem.url.startsWith('https://') || selectedItem.url.startsWith('http://')
            ? selectedItem.url
            : `//${selectedItem.url}`;
    };

    const adjustKeysFunction = documents => documents.map(document => {
        const adjustedDocumentKeys = {
            _id: document._id,
            dateAdded: moment.utc(document.dateAdded).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            type: document.type,
            url: document.url,
            item: document,
        };

        if (document.type === 'file') {
            return {
                ...adjustedDocumentKeys,
                name: document.originalFileName,
            };
        }

        return {
            ...adjustedDocumentKeys,
            name: document.name,
        };
    });

    const dataSelector = (state) => {
        const query = parseQueryString(history.location.search);
        const sortBy = tableColumns.find(x => x.value === query[SORT_QUERY_SELECTOR])?.fieldValue;
        const sortByDir = query[`${SORT_QUERY_SELECTOR}Dir`];

        return getClientProjectDocuments(state, null, null, sortBy, sortByDir).result;
    };

    const handleDownloadProjectFile = () => downloadProjectFile(agencyId, clientId, projectId, selectedItem._id, selectedItem.type, selectedItem.name);

    const handleOpenProjectLink = () => {
        const link = getDocumentLink();
        window.open(link, "_blank");
    };

    const adminFileActions = [
        { title: 'Download File', handleClick: handleDownloadProjectFile },
        { title: 'Delete File', handleClick: handleOpenConfirmRemoveDialog },
    ];

    const adminLinkActions = [
        { title: 'Open Link', handleClick: handleOpenProjectLink },
        { title: 'Delete Link', handleClick: handleOpenConfirmRemoveDialog },
    ];

    const clientFileActions = [
        { title: 'Download File', handleClick: handleDownloadProjectFile },
    ];

    const clientLinkActions = [
        { title: 'Open Link', handleClick: handleOpenProjectLink },
    ];

    const getPopoverActions = (item) => {
        setSelectedItem(item);
        if (isAdminMode && item.type === 'file') {
            return adminFileActions;
        } else if (isAdminMode) {
            return adminLinkActions
        } else if (item.type === 'file') {
            return clientFileActions;
        } else {
            return clientLinkActions;
        }
    };

    return (
        <div className="client-project-documents-table-wrapper">
            <TableWrapper
                title="Uploaded Documents"
                columns={tableColumns}
                defaultColumns={defaultTableColumns}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustKeysFunction}
                isTableDataLoading={false}
                dataSelector={dataSelector}
                addMargin
                withActions
                getActionsHandler={getPopoverActions}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    {isAdminMode &&
                        <TableWrapper.TableAlignedItems.RightAligned>
                            <TableWrapper.TableAlignedItems.TableActionButton text={"Upload Documents"} handleClick={handleOpenUploadMethodDialog} />
                        </TableWrapper.TableAlignedItems.RightAligned>
                    }
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />

                <UploadMethodDialog
                    isDialogOpen={isUploadMethodDialogOpen}
                    handleCloseDialog={handleCloseUploadMethodDialog}
                    handleOpenUploadLinkDialog={handleOpenUploadLinkDialog}
                    handleOpenUploadFilesDialog={handleOpenUploadFilesDialog}
                />

                <UploadLinkDialog
                    isDialogOpen={isUploadLinkDialogOpen}
                    handleCloseDialog={handleCloseUploadLinkDialog}
                    agencyId={agencyId}
                    clientId={clientId}
                    projectId={projectId}
                    addProjectLink={addProjectLink}
                    handleCloseUploadMethodDialog={handleCloseUploadMethodDialog}
                />

                <UploadFilesDialog
                    isDialogOpen={isUploadFilesDialogOpen}
                    handleCloseDialog={handleCloseUploadFilesDialog}
                    agencyId={agencyId}
                    clientId={clientId}
                    projectId={projectId}
                    addProjectFiles={addProjectFiles}
                    handleCloseUploadMethodDialog={handleCloseUploadMethodDialog}
                />

                <ConfirmDeleteDialog
                    itemToDelete={'document'}
                    handleDeleteItem={handleRemoveDocument}
                    openDialog={isConfirmRemoveDialogOpen}
                    handleCloseDialog={handleCloseConfirmRemoveDialog}
                />
            </TableWrapper>
        </div>
    );
};

export default ClientProjectDocumentsTable;
