import React from 'react';
import { connect } from 'react-redux';

import { showNotification, clearNotification } from "actions/notificationActions"
import { isNotificationOpen, getNotificationMessage, getNotificationType, getNotificationInfoList, isNotificationClickawayEnabled } from 'reducers';

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import './Notification.scss';

import { duration, NOTIFICATION_CLICK_REASON } from './notificationConstants';

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// TODO: #319 Extract to constants file 
export const severityLevels = {
	success: 'success',
	error: 'error',
	warning: 'warning',
	info: 'info'
}

const Notification = ({
	showNotification,
	clearNotification,
	notificationOpen,
	notificationMessage,
	notificationInfoList,
	notificationType,
	notificationClickaway
}) => {
	const handleClose = () => clearNotification();

	const handleOnClose = (e, reason) => {
		if (Array.isArray(notificationMessage) && notificationMessage.length > 0) {
			const newMessages = notificationMessage.length < 3 ? notificationMessage.pop() : notificationMessage.slice(1);
			const newNotifications = Array.isArray(notificationType)
				? (notificationType.length < 3 ? notificationType.pop() : notificationType.slice(1))
				: notificationType;

			if (!newMessages) {
				return reason === NOTIFICATION_CLICK_REASON.CLICKAWAY && !notificationClickaway ? null : handleClose();
			}

			return reason === NOTIFICATION_CLICK_REASON.CLICKAWAY && !notificationClickaway ? null : showNotification(newMessages, newNotifications, notificationInfoList, notificationClickaway);
		} else {
			return reason === NOTIFICATION_CLICK_REASON.CLICKAWAY && !notificationClickaway ? null : handleClose();
		}
	}

	const currentNotificationType = Array.isArray(notificationType) && notificationType.length > 0 ? notificationType[0] : notificationType;
	const currentNotificationMessage = Array.isArray(notificationMessage) && notificationMessage.length > 0 ? notificationMessage[0] : notificationMessage;

	return (
		notificationOpen
			?
			<div className="notification">
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={notificationOpen}
					autoHideDuration={duration}
					onClose={handleOnClose}
				>
					<Alert
						onClose={handleOnClose}
						style={currentNotificationType === 'info-list' && { backgroundColor: 'gray', color: '#d3d3d3' }}
						severity={currentNotificationType === 'info-list' ? 'info' : currentNotificationType}
					>
						{currentNotificationMessage}
						{notificationInfoList && currentNotificationType === 'info-list' &&
							<ul>
								{notificationInfoList.map((item, index) => (
									<li key={index}>
										{item}
									</li>
								))}
							</ul>
						}
					</Alert>
				</Snackbar>
			</div>
			: null
	);
};

const mapDispatchToProps = {
	showNotification,
	clearNotification,
};

const mapStateToProps = state => ({
	notificationOpen: isNotificationOpen(state),
	notificationMessage: getNotificationMessage(state),
	notificationInfoList: getNotificationInfoList(state),
	notificationType: getNotificationType(state),
	notificationClickaway: isNotificationClickawayEnabled(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
