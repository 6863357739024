import React from 'react';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { ReactComponent as StartCandidateJourneyIcon } from 'assets/start-candidate-journey-icon.svg';
import Paper from '@material-ui/core/Paper';
import WhatToExpect from '../WhatToExpect/WhatToExpect';

import { ReactComponent as IconLocation } from 'assets/icon_location.svg';

import './MobilePageWithJob.scss';

const MobilePageWithJob = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    agencyName,
    jobOpportunity,
}) => (
    <div className="start-candidate-journey-mobile-wrapper">
        <PaperElement title={`Thank you for joining ${agencyName}.`}>
            <p className="thank-you-for-joining-sub-header">You can now browse and apply for remote software jobs and connect with our growing tech community.</p>
        </PaperElement>
        <PaperElement classes={["start-candidate-journey-page", "max"]} paperContentClasses={["paper-content-centered"]}>
            <div className="start-candidate-journey-steps-container">
                <div className="start-candidate-journey-icon-container">
                    <StartCandidateJourneyIcon className="start-candidate-journey-icon" />
                </div>
                { jobOpportunity.agencyId &&
                    <div className="seen-job-wrapper">
                        <h1 className="start-candidate-journey-header">Seen Jobs</h1>
                            <Paper className="seen-job-container">
                                <div className="job-position">
                                    <h3>{jobOpportunity.client.name}</h3>
                                    <p>{jobOpportunity.position}</p>
                                    <div className="job-location">
                                        <div>
                                            <IconLocation />
                                        </div>
                                        <p>{`${jobOpportunity.geographicLocations?.length ? `Remote (${jobOpportunity.geographicLocations.join(", ")})` : 'Remote'}`}</p>
                                    </div>
                                </div>
                            </Paper>
                    </div>
                }
                <WhatToExpect
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                />
            </div>
        </PaperElement >
        <div className="journey-buttons-wrapper">
            <PrimaryButton
                text="Start"
                handleClick={handleStartCandidateJourney}
            />
        </div>
    </div>
);

export default MobilePageWithJob;
