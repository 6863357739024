import api from './api';
import requester from './requester';

const staticDataService = {
    fetchCountriesData: () => requester(api.countries()).getOne(),
    fetchStaticLanguagesData: () => requester(api.staticLanguagesData()).getOne(),
    fetchStaticLanguagesSuggestions: inputText => requester(api.staticLanguagesSuggestions(inputText)).getMany(),
};

export default staticDataService;
