import api from './api';
import requester from './requester';

const reportsService = {
    getReportsPage: (agencyId, month) => requester(api.reports(agencyId, month)).getMany(),
    getKpiReportData: (agencyId) => requester(api.kpiReport(agencyId)).getMany(),
    exportReportsPage: (agencyId, month, fileName) => requester(api.reportsExport(agencyId, month)).saveAs(fileName),
    exportReportsTableRow: (agencyId, tableRowId, month, fileName) => requester(api.reportsExportTableRow(agencyId, tableRowId, month)).saveAs(fileName),
};

export default reportsService;
