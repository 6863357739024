import { combineReducers } from 'redux';


import { BUSINESS_TRIPS_FETCH_SUCCESS, BUSINESS_TRIPS_REPLACE_SUCCESS, } from 'actions/actionTypes';

const businessTripsListInitialState = [];

const list = (state = businessTripsListInitialState, action) => {
    switch (action.type) {
        case BUSINESS_TRIPS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case BUSINESS_TRIPS_REPLACE_SUCCESS:
            return [...action.payload];
        default:
            return state;
    }
};

const businessTrips = combineReducers({
    list
});

export default businessTrips;

export const getBusinessTrips = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.list.slice(from, to || state.list.length);
};
