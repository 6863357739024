export const calculateCategoryPerformance = (category, reviews) => {
    return reviews.reduce((a, c, index, array) => a + (c.ratings[category] || 0) / array.length, 0);
};

export const generateOverallPerformance = reviews => {
    const overallPerformance = reviews
        .reduce((a, c, index, array) => a + c.overallRating / array.length, 0)

    const performanceCriterias = reviews.reduce((acc, curr) => {
        return [...new Set([...acc, ...Object.keys(curr.ratings || {})])]
    }, []);

    const categoriesPerformance = performanceCriterias.map(criteria => {
        return { criteria, score: calculateCategoryPerformance(criteria, reviews) }
    });

    return {
        reviews,
        overallPerformance: overallPerformance ? overallPerformance.toFixed(1) : 0,
        categoriesPerformance
    };
};
