import { combineReducers } from 'redux';
import {
    AGENCY_TRAININGS_FETCH_SUCCESS,
    AGENCY_TRAININGS_ADD_SUCCESS,
    AGENCY_TRAINING_REMOVE_SUCCESS,
    AGENCY_TRAINING_UPDATE_SUCCESS,
    TRAINING_TOPIC_ADD_SUCCESS,
    TRAINING_TOPIC_REMOVE_SUCCESS,
    TRAINING_FETCH_SUCCESS,
    TRAINING_TOPIC_UPDATE_SUCCESS,
} from 'actions/actionTypes';
import { TABLE_COLUMN_DIRECTIONS } from 'constants/commonConstants';

const trainingInitialState = {
    _id: '',
    title: '',
    description: '',
};

const training = (state = trainingInitialState, action = {}) => {
    if (state._id && state._id !== action.trainingId) {
        return state;
    }

    switch (action.type) {
        case AGENCY_TRAININGS_FETCH_SUCCESS:
        case AGENCY_TRAININGS_ADD_SUCCESS:
            return { ...trainingInitialState, ...action.payload };
        case AGENCY_TRAINING_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

const trainingsInitialState = [];

const trainings = (state = trainingsInitialState, action) => {
    switch (action.type) {
        case AGENCY_TRAININGS_ADD_SUCCESS:
            return [...state, training(undefined, action)]
        case AGENCY_TRAININGS_FETCH_SUCCESS:
            return action.payload.map(x => training(undefined, { ...action, payload: x }))
        case AGENCY_TRAINING_REMOVE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id)
        case AGENCY_TRAINING_UPDATE_SUCCESS:
            return state.map(x => training(x, action));
        default:
            return state;
    }
};

const topicInitialState = {};

const topic = (state = topicInitialState, action = {}) => {
    if (state._id && state._id !== action.topicId) {
        return state
    }

    switch (action.type) {
        case TRAINING_TOPIC_UPDATE_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
};

const topicsInitialState = [];

const topics = (state = topicsInitialState, action = {}) => {
    switch (action.type) {
        case TRAINING_TOPIC_ADD_SUCCESS:
            return [...state, action.payload]
        case TRAINING_TOPIC_REMOVE_SUCCESS:
            return state.filter(x => x._id !== action.payload._id)
        case TRAINING_TOPIC_UPDATE_SUCCESS:
            return state.map(x => topic(x, action))
        default:
            return state;
    }
}

const currentTrainingInitialState = {
    _id: '',
    title: '',
    description: '',
    topics: []
};

const currentTraining = (state = currentTrainingInitialState, action = {}) => {
    switch (action.type) {
        case TRAINING_FETCH_SUCCESS:
            return { ...currentTrainingInitialState, ...action.payload }
        case TRAINING_TOPIC_ADD_SUCCESS:
        case TRAINING_TOPIC_REMOVE_SUCCESS:
        case TRAINING_TOPIC_UPDATE_SUCCESS:
            return { ...state, topics: topics(state.topics, action) }
        case AGENCY_TRAINING_UPDATE_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default combineReducers({
    list: trainings,
    current: currentTraining,
});

export const getAgencyTrainings = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.list.slice(from, to || state.list.length);
};
export const getIndividualTraining = (state) => state.current;

export const getCurrentTrainingFiles = (state, topicId, page, count, sortBy, sortByDir) => {
    const from = page * count;
    const to = page * count + count;

    const topic = state.current.topics.find(x => x._id === topicId);
    let files = topic?.uploadedFiles?.map(x => ({ name: x.originalFileName, type: x.fileType })) || [];

    if (sortBy && sortByDir) {
        files = files.sort((a, b) => {
            const isAscending = sortByDir === TABLE_COLUMN_DIRECTIONS.ASCENDING;
            const fieldA = a[sortBy];
            const fieldB = b[sortBy];

            return isAscending
                ? fieldA?.localeCompare(fieldB)
                : fieldB?.localeCompare(fieldA);
        });
    }

    return {
        files: files.slice(from || 0, to || files.length),
        totalCount: files.length,
    };
};

export const getTrainingTopicVideos = (state, topicId) => {
    const topic = state.current.topics.find(x => x._id === topicId);
    return topic?.videos?.map(x => ({ _id: x._id, videoId: x.videoId }));
}
