import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";

import { getAccountRoute } from 'utils/router';

const RedirectToVerification = ({
    location
}) => {
    const [accountRoute, setAccountRoute] = useState(null);
    const getAccountRouteTest = () => {
        getAccountRoute(location).then(result => setAccountRoute(result));
    }

    useEffect(() => {
        if (!accountRoute) {
            getAccountRouteTest();
        }
    }, [accountRoute])

    return (
        <>
            {
                accountRoute &&
                <Redirect to={accountRoute} />
            }
        </>
    );
};

export default RedirectToVerification;
