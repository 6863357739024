import moment from 'moment';

import { ROLES } from 'constants/userConstants';
import { mapEmployeeOccupationStatusToStyles, getToolTipTitleBasedOnOccupationStatus } from 'constants/employeeConstants';

export const handleScoreTooltipHover = (e, visibility, setPopupTransform, setIsShowMorePopupVisible) => {
    if (e) {
        const popup = e.target.parentNode.nextSibling;

        const popupBoundingRect = popup.getBoundingClientRect();
        const boundingRect = e.target.getBoundingClientRect();
        const topOffset = boundingRect.top;

        const yOffset = window.innerHeight - (topOffset + popupBoundingRect.height + 20);

        let yTranslate = 0;
        const xTranslate = e.target.offsetLeft - 115;

        if (yOffset < 0) {
            yTranslate = - (popupBoundingRect.height + 22);
        }

        setPopupTransform({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
    }
    setIsShowMorePopupVisible(visibility);
};

export const getStatusDotInformation = profile => {
    switch (profile.role) {
        case ROLES.EMPLOYEE:
        case ROLES.ADMIN:
            return {
                dotTooltipTitle: `${getToolTipTitleBasedOnOccupationStatus(profile.occupationStatus)}`,
                dotClassName: `status-dot ${mapEmployeeOccupationStatusToStyles(profile.occupationStatus)} `,
            };
        case ROLES.CANDIDATE:
            const isCandidateAvailable = (profile.role === ROLES.CANDIDATE && profile.earliestDate)
                ? moment(new Date()).isAfter(profile.earliestDate)
                : false;
            return {
                dotTooltipTitle: `This candidate profile is currently ${isCandidateAvailable ? 'available' : 'unavailable'}`,
                dotClassName: isCandidateAvailable ? 'status-dot available' : 'status-dot occupied',
            };
        case ROLES.SUPPLIER_EMPLOYEE:
        case ROLES.SUPPLIER_ADMIN:
            const isParnterAvailable = profile.isAvailable;
            return {
                dotTooltipTitle: `This partner profile is currently ${isParnterAvailable ? 'available' : 'unavailable'}`,
                dotClassName: isParnterAvailable ? 'status-dot available' : 'status-dot occupied'
            };
        default:
            return {
                dotTooltipTitle: '',
                dotClassName: '',
            };
    }
};
