export const BUSINESS_TRIP_CATEGORIES = [
    { _id: 1, value: 'Еvent registration fee' },
    { _id: 2, value: 'Lodging' },
    { _id: 3, value: 'Meals' },
    { _id: 4, value: 'Miscellaneous Transportation' },
    { _id: 5, value: 'Business Meals' },
    { _id: 6, value: 'Business Expenses' },
    { _id: 7, value: 'Visa / Passport / Immunisations' },
];

export const BUSINESS_TRIP_TITLE = 'Business Trips';

export const BUSINESS_TRIP_TEXT = 'Do you have any upcoming business trips? Please talk to your account manager.';

export const BUSINESS_TRIP_POINTS_COMPONENT = <div className="business-trip-points-wrapper">
    <p>Do you have any upcoming business trips? Please talk to your account manager.</p>
    <ul>
        <li><p>- Provide your account manager with the full details of the upcoming trip.</p></li>
        <li><p>- Collect all expense receipts and take a photo of them.</p></li>
        <li><p>- After your trip submit an expense report.</p></li>
        <li><p>- All receipt papers and invoices should be sent to the HQ office. If you're unsure how to send them, speak to your account manager.</p></li>
    </ul>
</div> 
