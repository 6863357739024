import React from 'react';
import { Typography } from '@material-ui/core';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

import './TopicRow.scss';

const TopicRow = ({
    item,
    editItem,
    removeItem
}) => {
    return (
        <div className="topic-row">
            <Typography className="topic-row-title" variant="subtitle1" > {item.title}</Typography>
            <div className="row-edit-icon-wrapper" onClick={() => editItem(item)}>
                <EditSharpIcon className="row-edit-icon" />
            </div>
            <CancelSharpIcon className="row-remove-icon" onClick={() => removeItem(item)} />
        </div>
    )
};

export default TopicRow;
