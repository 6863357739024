export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true },
    { name: 'Email', value: 'email', isSortable: true },
    { name: 'Pending Holidays', value: 'pendingHolidaysCount', isSortable: true },
];

export const DEFAULT_TABLE_COLUMNS = [
    'name',
    'email',
    'pendingHolidaysCount',
];

export const SORT_QUERY_SELECTOR = 'sort';
export const PAGE_QUERY_SELECTOR = 'page';
export const SEARCH_QUERY_SELECTOR = 'search';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    page: 1,
    search: '',
    sortBy: 'name',
    sortByDir: 'desc',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const TABLE_QUERY_MAP = {
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
