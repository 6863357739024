import api from './api';
import requester from './requester';

const departmentService = {
    fetchDepartments: (agencyId, query) => requester(api.departments(agencyId, query)).getMany(),
    createDepartment: (agencyId, departmentData) => requester(api.departments(agencyId)).create(departmentData),
    deleteDepartment: (agencyId, departmentId) => requester(api.department(agencyId, departmentId)).delete(),
    fetchDepartmentDetails: (agencyId, departmentId) => requester(api.department(agencyId, departmentId)).getOne(),
    editDepartment: (agencyId, departmentId, departmentData) => requester(api.department(agencyId, departmentId)).update(departmentData),
    fetchDepartmentsMembers: (agencyId, departmentId, query) => requester(api.departmentMembers(agencyId, departmentId, query)).getMany(),
    registerDepartmentEmployee: (agencyId, departmentId, employeeData) => requester(api.departmentMembers(agencyId, departmentId)).create(employeeData),
}

export default departmentService;
