import {
	APPLICATION_LOADER_SHOW_SUCCESS,
	APPLICATION_LOADER_HIDE_SUCCESS,
} from './actionTypes';

const showApplicationLoaderSuccess = () => ({
	type: APPLICATION_LOADER_SHOW_SUCCESS,
	payload: true
});
  
const hideApplicationLoaderSuccess = () => ({
    type: APPLICATION_LOADER_HIDE_SUCCESS,
    payload: false
});

export const showApplicationLoader = () => dispatch => dispatch(showApplicationLoaderSuccess());
export const hideApplicationLoader = () => dispatch => dispatch(hideApplicationLoaderSuccess());
