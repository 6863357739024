import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import ExploreTalentsEmployeeCard from '../ExploreTalentsEmployeeCard';
import CircularProgress from '@material-ui/core/CircularProgress';

const ExploreTalentsContent = ({
    talents,
    totalTalentsCount,
    handleFetchMoreTalents,
    hasMore,
    dataFetchState,
}) => {
    return (
        <div>
            {dataFetchState?.isDataFetching && talents.length === 0
                ? <CircularProgress size={50} disableShrink className="explore-talents-loader" />
                : <div className="explore-talents-content">
                    {
                        talents.length > 0 &&
                        <InfiniteScroll
                            dataLength={talents.length}
                            next={handleFetchMoreTalents.bind(null, talents, totalTalentsCount)}
                            hasMore={hasMore}
                            scrollableTarget="scroller"
                            style={{ overflow: 'visible' }}
                        >
                            {talents.map(talent => {
                                return (
                                    <ExploreTalentsEmployeeCard
                                        key={talent._id}
                                        employee={talent}
                                    >
                                        <ExploreTalentsEmployeeCard.ProfileWrapper>
                                            <ExploreTalentsEmployeeCard.ProfileWrapper.RoleBadge />
                                            <div className="avatar-status-wrapper">
                                                <ExploreTalentsEmployeeCard.ProfileWrapper.Avatar />
                                                <ExploreTalentsEmployeeCard.ProfileWrapper.StatusOrb />
                                            </div>
                                            <ExploreTalentsEmployeeCard.ProfileWrapper.VettedProfile />

                                        </ExploreTalentsEmployeeCard.ProfileWrapper>

                                        <ExploreTalentsEmployeeCard.ProfileInfoWrapper employee={talent}>
                                            <ExploreTalentsEmployeeCard.ProfileInfoWrapper />
                                        </ExploreTalentsEmployeeCard.ProfileInfoWrapper>

                                        <ExploreTalentsEmployeeCard.ButtonsWrapper>
                                            <ExploreTalentsEmployeeCard.ButtonsWrapper.ViewProfile />
                                            <ExploreTalentsEmployeeCard.ButtonsWrapper.EditProfile />
                                        </ExploreTalentsEmployeeCard.ButtonsWrapper>
                                    </ExploreTalentsEmployeeCard>
                                )
                            })}
                        </InfiniteScroll>
                    }
                </div>
            }
        </div>
    );
};

export default ExploreTalentsContent;
