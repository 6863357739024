import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';
import DialogCloseButton from 'components/Shared/DialogButtons/DialogCloseButton'
import { DialogActions } from '@material-ui/core';

import { ReactComponent as UnsupportedLocationIcon } from 'assets/unsupported-location-icon.svg';

import './UnsupportedLocationDialog.scss';

const defaultTextComponent = () => (
    <p className="unsupported-location-text">
        Your current tax residency is outside of our supported countries, meaning you can only be hired as a <span>Contractor</span> (via a B2B contract).
        To help us match you to the rights jobs, please indicate your current tax residency in the field below.
    </p>
);

const dialogActions = (handleCloseDialog) => (
    <DialogSaveButton onClick={handleCloseDialog}>Got It</DialogSaveButton>
);

const UnsupportedLocationDialog = ({
    isOpen,
    handleCloseDialog,
    locationTextComponent=null,
    dialogActionsComponent=null,
}) => {
    return (
        <Dialog className="unsupported-location-dialog" open={isOpen} onClose={handleCloseDialog}>
            <MuiDialogTitle disableTypography className="dialog-title">
                <Typography variant="body2" className="dialog-title-text">
                    Information about your location
                </Typography>
                <DialogCloseButton className="dialog-close-btn" onClick={handleCloseDialog}></DialogCloseButton>
            </MuiDialogTitle>
            <DialogContent className="dialog-content">
                <div className="unsupported-location-icon-wrapper">
                    <UnsupportedLocationIcon />
                </div>
                { locationTextComponent ? locationTextComponent() : defaultTextComponent() }
            </DialogContent>
            <DialogActions className="dialog-actions-container">
                { dialogActionsComponent ? dialogActionsComponent() : dialogActions(handleCloseDialog) }
            </DialogActions>
        </Dialog>
    );
};

export default UnsupportedLocationDialog;
