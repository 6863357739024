export const NOTIFICATION_TYPES = {
	SUCCESS: 'success',
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
	INFO_LIST: 'info-list'
};

export const NOTIFICATION_CLICKAWAY = {
	ENABLED: true,
	DISABLED: false,
};
