const inputFieldMaxLength = {
    name: 50,
    client: 30,
    description: 3000,
    position: 100,
    technologyField: 50,
}

const inputFieldCharMapper = {
    "•": "-",
    "◦": "-",
    "∙": "-",
    "‣": "-",
    "⁃": "-",
    "": "-",
    "": "-",
    "": "-",
    "": "-",
    "	": " "
}

export { inputFieldMaxLength, inputFieldCharMapper };
