import Typography from '@material-ui/core/Typography';

import { getRejectionBannerInfo } from './RejectionBannerUtils';

import './RejectionBanner.scss';

const RejectionBanner = ({ rejectionReason, rejectionDate, handleRequestNewReview }) => {
    const { icon, title, message, action } = getRejectionBannerInfo(rejectionReason, rejectionDate, handleRequestNewReview);

    return (
        <div className="cultural-fit-rejection-banner-wrapper">
            <div className="cultural-fit-rejection-banner-container">
                <div className="cultural-fit-rejection-icon-wrapper">
                    {icon}
                </div>

                <Typography className="cultural-fit-rejection-banner-title">
                    {title}
                </Typography>

                <Typography className="cultural-fit-rejection-banner-message">
                    {message}
                </Typography>

                {action &&
                    <div className="cultural-fit-rejection-banner-action-wrapper">
                        {action}
                    </div>
                }
            </div>
        </div>
    );
};

export default RejectionBanner;
