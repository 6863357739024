import moment from 'moment';

export const getRemainingTime = (statusRequestDate) => {
    const nowUTC = moment().utc();
    const addOneDayToStatusRequestDateUTC = moment.utc(statusRequestDate).add(1, 'day');

    const duration = moment.duration(addOneDayToStatusRequestDateUTC.diff(nowUTC));

    const hours = duration.get('hours');
    const minutes = duration.get('minutes');
    const seconds = duration.get('seconds');

    if (!statusRequestDate || addOneDayToStatusRequestDateUTC.isBefore(nowUTC)) {
        return '';
    }

    return `${hours}h ${minutes}m ${seconds < 10 ? `0${seconds}` : seconds}s`;
};
