import React from 'react';

import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogActions from '@material-ui/core/DialogActions';
import Switch from '@material-ui/core/Switch';
import { ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import isHoliday from 'hoc/isHoliday';

import './AddEditEductionFormView.scss';
import { DATE_PICKER_FORMAT_MM_YYYY } from 'constants/timesFormat';

const AddEditEductionFormView = ({
    values,
    handleChange,
    errors,
    touched,
    handleCloseDialog,
    handleSubmit,
    setFieldValue,
}) => (
        <form className="add-edit-education-form" autoComplete="off">
            <div className={errors.title && touched.title ? 'title-field invalid' : 'title-field'}>
                <TextField
                    required
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    id="add-education-title"
                    label="Degree"
                    error={errors.title && touched.title}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="title" component="div" className="invalid-field-message" />
            </div>
            <div className={errors.institution && touched.institution ? 'institution-field invalid' : 'institution-field'}>
                <TextField
                    name="institution"
                    value={values.institution}
                    onChange={handleChange}
                    id="add-education-institution"
                    label="Institution"
                    error={errors.institution && touched.institution}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="institution" component="div" className="invalid-field-message" />
            </div>
            <div>
                <div className="switch-wrapper">
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                color="primary"
                                name="datesUnknown"
                                onChange={handleChange}
                                checked={values.datesUnknown} />
                        }
                        label="Dates unknown"
                        labelPlacement="start"
                        className="switch-label"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                color="primary"
                                name="currentlyStudy"
                                onChange={handleChange}
                                disabled={values.datesUnknown}
                                checked={values.currentlyStudy} />
                        }
                        label="Currently study"
                        labelPlacement="start"
                        className="switch-label"
                    />
                </div>
                <div className="date-fields-block">
                    <KeyboardDatePicker
                        autoOk={true}
                        className="start-date-field"
                        label="Start Date"
                        disableToolbar
                        disabled={values.datesUnknown}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={
                            values.datesUnknown
                                ? null
                                : values.startDate
                        }
                        onChange={value => {
                            setFieldValue('startDate', moment(value));
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />
                    <KeyboardDatePicker
                        autoOk={true}
                        label="End Date"
                        disabled={values.datesUnknown || values.currentlyStudy}
                        disableToolbar
                        minDate={values.startDate || new Date()}
                        variant="inline"
                        inputVariant="outlined"
                        fullWidth
                        value={
                            (values.datesUnknown || values.currentlyStudy)
                                ? null
                                : values.endDate
                        }
                        onChange={value => {
                            setFieldValue('endDate', moment(value));
                        }}
                        openTo="year"
                        views={["year", "month"]}
                        renderDay={isHoliday}
                        format={DATE_PICKER_FORMAT_MM_YYYY}
                        allowKeyboardControl={true}
                    />
                </div>
            </div>
            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Save" type="submit" />
            </DialogActions>
        </form>
    );

export default AddEditEductionFormView;
