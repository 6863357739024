import React from 'react';
import './NothingToShow.scss';

const NothingToShow = ({
    items, 
    icon,
    tableView,
    sectionView,
    message,
    style
}) => (
    <div className={`nothing-to-show-wrapper ${tableView ? 'table-view' : sectionView ? 'section-view' : ''}`} style={style}>
        {!tableView && icon}
        {message ? <p>{message}</p> : <p>No {items} to show</p>}
    </div>
);

export default NothingToShow;
