import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchCandidateApplicationPage } from 'actions/candidateActions';
import { resetCandidateApplicationStates } from 'actions/sectionsLoadersActions';

import { 
    getAgencyId, 
    getCandidateApplicationDetails, 
    getDisplayMode, 
    getInterviewInvitationDetails, 
    getJobOpportunityDetails, 
    getUserId,
    getCandidateApplicationDataFetchState,
} from 'reducers';

import { CircularProgress } from '@material-ui/core';

import ApplicationStatusBanner from 'components/Shared/ApplicationStatusBanner';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import JobCard from 'components/Shared/JobCard';
import Section from 'components/Shared/Section';

import ApplicationInterviewSlots from 'components/MyApplications/ApplicationDetails/Application/ApplicationInterviewSlots';
import MyUpcomingInterviewCard from 'components/MyApplications/MyUpcomingInterviewsList/MyUpcomingInterviewCard';
import AskRecruiterBanner from 'components/Shared/AskRecruiterBanner';

import { getStateAgencyName } from 'utils/helpers';

import { INTERVIEW_INVITATION_CONTRIBUTORS, INTERVIEW_INVITATION_STATUSES } from 'constants/interviewInvitationConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';

import './MyApplicationPage.scss';
import { areAllDatesInThePast } from 'utils/dateUtils';

const MyApplicationPage = ({
    agencyId,
    userId,
    history,
    match,
    displayMode,
    interviewInvitationDetails,
    applicationDetails,
    jobDetails,
    fetchCandidateApplicationPage,
    resetCandidateApplicationStates,
    applicationDataFetchState,
}) => {
    const applicationId = match.params.id;

    useEffect(() => {
        fetchCandidateApplicationPage(agencyId, userId, applicationId);

        return () => resetCandidateApplicationStates();
    }, []);

    const handleGoBackClick = () => {
        history.push(`/${getStateAgencyName()}/my-applications`);
    };

    const interviewSectionTitle = interviewInvitationDetails.status === INTERVIEW_INVITATION_STATUSES.PENDING 
        ? 'Invitations for Interview'
        : 'Upcoming Interviews';

    const isInterviewPending = interviewInvitationDetails.status === INTERVIEW_INVITATION_STATUSES.PENDING;
    const isInterviewConfirmed = interviewInvitationDetails?.interviewDate?.date;

    const isApplicationCompleted = [APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER, APPLICATION_STATUSES.APPROVED, APPLICATION_STATUSES.REJECTED].includes(applicationDetails?.status);
    const isInterviewProposedByCandidate = interviewInvitationDetails?.proposedBy === INTERVIEW_INVITATION_CONTRIBUTORS.PARTICIPANT;
    const showApplicationStatusBanner = !isApplicationCompleted;
    const isInterviewUpcoming = 
        isInterviewConfirmed ? moment(interviewInvitationDetails?.interviewDate?.date).isAfter(moment()) :
        Array.isArray(interviewInvitationDetails?.interviewTimeSlots) ? !areAllDatesInThePast(interviewInvitationDetails?.interviewTimeSlots.map(x => x.date)) :
        false
    ;

    if (!applicationDataFetchState?.isDataFetched) {
        return <CircularProgress size={50} className="page-loader" />
    }

    return (
        <div className="my-application-page-wrapper">
            <PreviousButton text={"Back to Applications"} handleClick={handleGoBackClick} />
            <div className="my-application-page-container">
                { showApplicationStatusBanner && 
                    <ApplicationStatusBanner
                        status={applicationDetails.status}
                        isInterviewPending={isInterviewPending}
                        isInterviewConfirmed={isInterviewConfirmed}
                    /> 
                }
                <Section title="Job Description">
                    <JobCard
                        interview={interviewInvitationDetails}
                        applicationDetails={applicationDetails}
                        jobDetails={jobDetails}
                    />
                </Section>
                { interviewInvitationDetails && interviewInvitationDetails._id && !isApplicationCompleted && isInterviewUpcoming &&
                <Section title={interviewSectionTitle}>
                    {(isInterviewPending) ?
                        <ApplicationInterviewSlots
                            agencyId={agencyId}
                            userId={userId}
                            candidateId={userId}
                            clientId={applicationDetails.clientId}
                            applicationId={applicationId}
                            displayMode={displayMode}
                            interviewInvitation={interviewInvitationDetails}
                            isWaitingForResponse={isInterviewProposedByCandidate}
                            subtitle={
                                isInterviewProposedByCandidate
                                ? "Here you have submitted a proposal for interview days and times. Please wait for a response from the client."
                                : "Here the client has proposed days and hours for the interview. Please choose below which is convenient for you."
                            }
                        /> : 
                        <MyUpcomingInterviewCard
                            interview={{...interviewInvitationDetails, position: applicationDetails.position, company: applicationDetails.company}}
                        />
                    }
                </Section>
                }

                {!isApplicationCompleted &&
                    <AskRecruiterBanner
                        agencyId={agencyId}
                        applicationId={applicationId}
                        applicationStatuRequestDate={applicationDetails.statusRequestDate}
                    />
                }
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    interviewInvitationDetails: getInterviewInvitationDetails(state),
    applicationDetails: getCandidateApplicationDetails(state),
    jobDetails: getJobOpportunityDetails(state),
    displayMode: getDisplayMode(state),
    applicationDataFetchState: getCandidateApplicationDataFetchState(state),
});

const mapDispatchToProps = {
    fetchCandidateApplicationPage,
    resetCandidateApplicationStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyApplicationPage);
