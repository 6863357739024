import {
    PROFILE_MATCHING_JOBS_FILTERS,
    LOCATION_FILTER_VALUES,
} from './ProfileMatchingJobsConstants';

export const mapQueryToFilters = (queryObj) => {
    const initFilters = { ...PROFILE_MATCHING_JOBS_FILTERS };

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'location':
                foundItem = LOCATION_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.location = foundItem ? foundItem : acc.location;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};
