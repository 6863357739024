import React from 'react';
import moment from 'moment';

import { Divider, Tooltip } from '@material-ui/core';
import CandidateDetailsHeader from './CandidateDetailsHeader';
import MatchingDetails from './MatchingDetails';
import Notes from './Notes';
import ApplicationInterviewActions from 'components/MyApplications/ApplicationDetails/Application/ApplicationInterviewActions';

import { ReactComponent as RejectionReasonIcon } from 'assets/rejection-reason-icon.svg';

import { candidateStatusMapper, candidateStatusToTooltipText } from 'utils/applicationUtils';

import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import './CandidateDetails.scss';
import { ROLES } from 'constants/userConstants';

const CandidateDetails = ({
    agencyId,
    applicationId,
    candidatePreferences,
    candidateDetails,
    profilePictureImgUrl,
    agencyName,
    applicationStatus,
    interviewInvitation,
    clientId,
    displayMode,
    reviewerName,
    candidateId,
}) => {
    const adjustValues = (obj) => ({
        ...obj,
        appliedDate: moment(obj.appliedDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
        agencyName,
        candidateId,
    });

    return (
        <div className="candidate-details-container">
            {(candidateDetails.status === APPLICATION_STATUSES.REJECTED || candidateDetails.status === APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER) &&
                <>
                    <div className="rejection-reason-container">
                        <div className="rejection-reason-header">
                            <RejectionReasonIcon />
                            <h4 className="feedback">Rejection Reason</h4>
                            {reviewerName && displayMode === ROLES.ADMIN ? <h4 className="feedback">(Added by: {reviewerName})</h4> : <></>}
                        </div>
                        <p className="comment">
                            {candidateDetails.status === APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER
                                ? 'This candidate has accepted another offer and their application has been terminated.'
                                : candidateDetails.rejectionReason || 'N/A'
                            }
                        </p>
                    </div>
                    <Divider variant="middle" className="section-divider" />
                </>
            }
            <div className="details-header">
                <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={candidateStatusToTooltipText(applicationStatus)}>
                    <div className={`applied-candidate-label ${candidateStatusMapper(applicationStatus)?.toLowerCase().split(" ").join("-")}`} >
                        <span className="applied-candidate-text">{candidateStatusMapper(applicationStatus)}</span>
                    </div>
                </Tooltip>
                <CandidateDetailsHeader
                    candidateDetails={adjustValues(candidateDetails)}
                    candidatePreferences={candidatePreferences}
                    profilePictureImgUrl={profilePictureImgUrl}
                    displayMode={displayMode}
                />
            </div>
            <Divider variant="middle" className="section-divider" />
            <MatchingDetails matchingScores={candidateDetails.candidateMatchingScores} />
            <Divider variant="middle" className="section-divider" />
            {candidateDetails.comment &&
                <Notes agencyName={agencyName} note={candidateDetails.comment} />
            }
            {candidateDetails.status === APPLICATION_STATUSES.APPROVED && candidateDetails?.feedback.comment &&
                <Notes
                    title={reviewerName && displayMode === ROLES.ADMIN ? `Hiring Feedback (Added by: ${reviewerName})` : 'Hiring Feedback'}
                    note={candidateDetails.feedback.comment}
                />
            }
            <ApplicationInterviewActions
                agencyId={agencyId}
                applicationId={applicationId}
                agencyName={agencyName}
                interviewInvitation={interviewInvitation}
                clientId={clientId}
            />
        </div>
    )
};

export default CandidateDetails;
