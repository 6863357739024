import moment from 'moment'; 

import { ReactComponent as ArrowRoundForwardIcon } from 'assets/icon-arrow-round-forward.svg';
import { ReactComponent as CheckmarkIcon } from 'assets/checkmark-icon.svg';
import { ReactComponent as EditIcon } from 'assets/jobs-edit-icon.svg';

import CloseIcon from '@material-ui/icons/Close';

import { HIRING_CHECKLIST_TITLES, CHECKLIST_STEPS_KEYS, HIRING_CHECKLIST_STEPS_ORDER } from './HiringChecklistConstants';
import { CANDIDATE_RATING_STATUSES } from 'constants/candidateConstants';

import { getDifferenceInDays, transformDifferenceInDaysToText } from 'utils/formatTimes';

export const getInitialFormValues = (candidateProfileSteps, stepKey) => {
    switch (stepKey) {
        case CHECKLIST_STEPS_KEYS.SOFT_SKILLS_STEP:
            return {
                description: candidateProfileSteps.softSkillsStep.description || '',
                languages: candidateProfileSteps.softSkillsStep.languagesRate || 0,
                communication: candidateProfileSteps.softSkillsStep.communicationRate || 0,
                personality: candidateProfileSteps.softSkillsStep.personalityRate || 0,
                candidateMissing: candidateProfileSteps.softSkillsStep.candidateMissing || false,
            };
        case CHECKLIST_STEPS_KEYS.HARD_SKILLS_STEP:
            return {
                description: candidateProfileSteps.hardSkillsStep.description || '',
                hardSkillsAssessment: candidateProfileSteps.hardSkillsStep.hardSkillsAssessment || 0,
                candidateMissing: candidateProfileSteps.hardSkillsStep.candidateMissing || false,
            };
        case CHECKLIST_STEPS_KEYS.CANDIDATE_RATED_STEP:
            const isStatusVettedRejectedOrIncomplete = [
                CANDIDATE_RATING_STATUSES.VETTED,
                CANDIDATE_RATING_STATUSES.REJECTED,
                CANDIDATE_RATING_STATUSES.INCOMPLETE_PROFILE,
            ].includes(candidateProfileSteps.sendFeedbackToCandidateStep.status);

            return {
                softSkillsDescription: candidateProfileSteps.softSkillsStep.description,
                softSkillsReviewer: candidateProfileSteps.softSkillsStep.ratedBy,
                softSkillsRateDate: candidateProfileSteps.softSkillsStep.dateOfRate,
                softSkillsAverageScore: candidateProfileSteps.sendFeedbackToCandidateStep.softSkillsAverageScore,
                softSkillsCandidateMissing: candidateProfileSteps.softSkillsStep.candidateMissing,
                hardSkillsDescription: candidateProfileSteps.hardSkillsStep.description,
                hardSkillsReviewer: candidateProfileSteps.hardSkillsStep.ratedBy,
                hardSkillsRateDate: candidateProfileSteps.hardSkillsStep.dateOfRate,
                hardSkillsCandidateMissing: candidateProfileSteps.hardSkillsStep.candidateMissing,
                hardSkillsAverageScore: candidateProfileSteps.sendFeedbackToCandidateStep.hardSkillsAverageScore,
                status: isStatusVettedRejectedOrIncomplete ? candidateProfileSteps.sendFeedbackToCandidateStep.status : '',
                internalFeedback: candidateProfileSteps.sendFeedbackToCandidateStep.internalFeedback || '',
                summary: candidateProfileSteps.sendFeedbackToCandidateStep.summary || '',
                rejectionReason: candidateProfileSteps.sendFeedbackToCandidateStep.rejectionReason || '',
                isSoftSkillsStepCompleted: candidateProfileSteps.softSkillsStep.isCompleted,
                isHardSkillsStepCompleted: candidateProfileSteps.hardSkillsStep.isCompleted,
                isSubmitDisabled: !candidateProfileSteps.softSkillsStep.isCompleted || !candidateProfileSteps.hardSkillsStep.isCompleted
            };
        default:
            return {};
    }
};

export const mapValuesToRequestBody = (userId, values) => {
    const rejectionReason = values.status !== CANDIDATE_RATING_STATUSES.REJECTED ? '' : values.rejectionReason;

    return {
        status: values.status,
        reviewer: userId,
        dateOfRate: moment.utc().format(),
        rejectionReason,
        internalFeedback: values.internalFeedback,
        summary: values.summary,
    }
};

export const getStepContent = (handleOpenDialog, dialogKey, title, step, isRejectedOrIncomplete, showEditPen, setShowEditPen, isPreviousStepCompleted) => {
    let isStepFinished;
    let differenceInDays;
    let periodOfCompletion;

    switch (title) {
        case HIRING_CHECKLIST_TITLES.SOFT_SKILLS_VETTED:
            isStepFinished = step.isCompleted || step.candidateMissing;
            differenceInDays = getDifferenceInDays(step.dateOfRate);
            periodOfCompletion = transformDifferenceInDaysToText(differenceInDays);

            return (
                <div key={title} className="step-container">
                    <div className="step-box-wrapper" onClick={isPreviousStepCompleted ? () => handleOpenDialog(dialogKey, isStepFinished) : null}>
                        <div className={`step-box ${ isPreviousStepCompleted ? 'with-action' : '' } ${isStepFinished ? ` done` : ''}`}>
                            <div className="step-box-content">
                                {isStepFinished
                                    ? <CheckmarkIcon className="checkmark-icon" />
                                    : <span>1</span>
                                }
                            </div>
                        </div>
                        <ArrowRoundForwardIcon className="arrow-icon" />
                    </div>
                    <div
                        className="step-title-action-wrapper"
                        onMouseEnter={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, softSkillsVetted: true }))}
                        onMouseLeave={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, softSkillsVetted: false }))}
                    >
                        <p className="step-title">{title}</p>
                        {showEditPen.softSkillsVetted && <div className="edit-icon-wrapper"><EditIcon className="edit-icon" onClick={() => handleOpenDialog(dialogKey, isStepFinished)}/></div>}
                    </div>
                    {isStepFinished && <p className={`step-completion${differenceInDays >= 3 ? ' text-red' : ''}`}>{periodOfCompletion}</p>}
                </div>
            );
        case HIRING_CHECKLIST_TITLES.HARD_SKILLS_VETTED:
            isStepFinished = step.isCompleted || step.candidateMissing;
            differenceInDays = getDifferenceInDays(step.dateOfRate);
            periodOfCompletion = transformDifferenceInDaysToText(differenceInDays);

            return (
                <div key={title} className="step-container">
                    <div className="step-box-wrapper" onClick={isPreviousStepCompleted ? () => handleOpenDialog(dialogKey, isStepFinished) : null}>
                        <div className={`step-box ${ isPreviousStepCompleted ? 'with-action' : '' } ${isStepFinished ? ` done` : ''}`}>
                            <div className="step-box-content">
                                {isStepFinished
                                    ? <CheckmarkIcon className="checkmark-icon" />
                                    : <span>2</span>
                                }
                            </div>
                        </div>
                        <ArrowRoundForwardIcon className="arrow-icon" />
                    </div>
                    <div
                        className="step-title-action-wrapper"
                        onMouseEnter={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, hardSkillsVetted: true }))}
                        onMouseLeave={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, hardSkillsVetted: false }))}
                    >
                        <p className="step-title">{title}</p>
                        {showEditPen.hardSkillsVetted && <div className="edit-icon-wrapper"><EditIcon className="edit-icon" onClick={() => handleOpenDialog(dialogKey, isStepFinished)}/></div>}
                    </div>
                    {isStepFinished && <p className={`step-completion${differenceInDays >= 3 ? ' text-red' : ''}`}>{periodOfCompletion}</p>}
                </div>
            );
        case HIRING_CHECKLIST_TITLES.CANDIDATE_RATED:
            isStepFinished = step.isCompleted;
            differenceInDays = getDifferenceInDays(step.dateOfRate);
            periodOfCompletion = transformDifferenceInDaysToText(differenceInDays);

            return (
                <div key={title} className="step-container">
                    <div className="step-box-wrapper" onClick={isPreviousStepCompleted ? () => handleOpenDialog(dialogKey, isStepFinished) : null}>
                        <div className={`step-box ${ isPreviousStepCompleted ? 'with-action' : '' } ${isStepFinished ? ` done` : ''}`}>
                            <div className="step-box-content">
                                {(isStepFinished && isRejectedOrIncomplete) && <CloseIcon className="close-icon" />}
                                {(isStepFinished && !isRejectedOrIncomplete) && <CheckmarkIcon className="checkmark-icon" />}
                                {(!isStepFinished && !isRejectedOrIncomplete) && <span>3</span>}
                            </div>
                        </div>
                        <ArrowRoundForwardIcon className="arrow-icon" />
                    </div>
                    <div
                        className="step-title-action-wrapper"
                        onMouseEnter={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, candidateRated: true }))}
                        onMouseLeave={() => isStepFinished && setShowEditPen(prevState => ({ ...prevState, candidateRated: false }))}
                    >
                        <p className="step-title">{title}</p>
                        {showEditPen.candidateRated && <div className="edit-icon-wrapper"><EditIcon className="edit-icon" onClick={() => handleOpenDialog(dialogKey, isStepFinished)}/></div>}
                    </div>
                    {(isStepFinished && !isRejectedOrIncomplete) && <p className={`step-completion${differenceInDays >= 3 ? ' text-red' : ''}`}>{periodOfCompletion}</p>}
                    {(isStepFinished && isRejectedOrIncomplete) && <p className={`step-completion text-red`}>{step.status}</p>}
                </div>
            );
        case HIRING_CHECKLIST_TITLES.PROFILE_SENT_TO_CLIENT:
            isStepFinished = step.isCompleted;
            differenceInDays = getDifferenceInDays(step.dateOfRate);
            periodOfCompletion = transformDifferenceInDaysToText(differenceInDays);

            return (
                <div key={title} className="step-container">
                    <div className="step-box-wrapper">
                        <div className={`step-box${(isStepFinished) ? ' done' : ''}${isRejectedOrIncomplete ? ' inactive' : ''}`}>
                            <div className="step-box-content">
                                {(isStepFinished && !isRejectedOrIncomplete) ? <CheckmarkIcon className="checkmark-icon" /> : <span>4</span>}
                            </div>
                        </div>
                        <ArrowRoundForwardIcon className="arrow-icon" />
                    </div>
                    <div className="step-title-action-wrapper">
                        <p className={`step-title${isRejectedOrIncomplete ? ' inactive' : ''}`}>{title}</p>
                    </div>
                    {(isStepFinished && !isRejectedOrIncomplete) && <p className={`step-completion${differenceInDays >= 3 ? ' text-red' : ''}`}>{periodOfCompletion}</p>}
                </div>
            );
        case HIRING_CHECKLIST_TITLES.APPLICATIONS_UPDATED:
            isStepFinished = step.isCompleted;

            return (
                <div key={title} className="step-container">
                    <div className="step-box-wrapper">
                        <div className={`step-box${(isStepFinished) ? ' done' : ''}${isRejectedOrIncomplete ? ' inactive' : ''}`}>
                            <div className="step-box-content">
                                {(isStepFinished && !isRejectedOrIncomplete) ? <CheckmarkIcon className="checkmark-icon" /> : <span>5</span>}
                            </div>
                        </div>
                    </div>
                    <div className="step-title-action-wrapper">
                        <p className={`step-title${isRejectedOrIncomplete ? ' inactive' : ''}`}>{title}</p>
                    </div>
                </div>
            );
        default:
            return null;
    }
};

export const getFirstUncompletedStep = (steps) => HIRING_CHECKLIST_STEPS_ORDER.find(step => !steps[step].isCompleted);

export const getHelperButtonTextFromStepName = (stepName) => {
    switch (stepName) {
        case HIRING_CHECKLIST_STEPS_ORDER[0]:
        case HIRING_CHECKLIST_STEPS_ORDER[1]:
            return 'Submit Feedback';
        case HIRING_CHECKLIST_STEPS_ORDER[2]:
            return 'Rate Candidate';
        case HIRING_CHECKLIST_STEPS_ORDER[3]:
            return 'Send to Client';
        case HIRING_CHECKLIST_STEPS_ORDER[4]:
            return 'Update Applications';
        default:
            return '';
    }
};
