import React, {useState, useRef, useEffect} from 'react';

import ReactCrop from 'react-image-crop';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import DialogDiscardButton from 'components/Shared/DialogButtons/DialogDiscardButton';
import DialogSaveButton from 'components/Shared/DialogButtons/DialogSaveButton';

import { makeClientCrop } from 'utils/imageProcessingUtils';

import './CropImageDialog.scss';

const initialCrop = {
    unit: 'px',
    width: 200,
    height: 200,
    x: 0,
    y: 0,
};

const CropImageDialog = ({
    showEditImgDialog,
    currentImgSelected,
    onImgEditClose,
    currentProfilePictureFileName,
    onSave
}) => {
    const [croppedImage, setCroppedImage] = useState();
    const [imageRef, setImageRef] = useState();
    const [crop, setCrop] = useState(initialCrop);

    const canvasRefImageCroppRef = useRef();

    useEffect(() => {
        onCropComplete(crop);
    }, [imageRef]);

    const onImageLoaded = image => setImageRef(image);

    const onCropComplete = crop => makeClientCrop(currentImgSelected, imageRef, crop, canvasRefImageCroppRef, currentProfilePictureFileName, setCroppedImage);

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };


    const resetCrop = () => {
        setCrop(initialCrop);

        setCroppedImage(null);
        onCropComplete(initialCrop);
    }

    return (
        <Dialog className='edit-img-dialog' maxWidth={'md'} open={showEditImgDialog} onClose={() => onImgEditClose()}>
                <MuiDialogTitle className='edit-img-header'>
                    <IconButton onClick={() => onImgEditClose()}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                
                <DialogContent className='img-edit-content'>
                    {currentImgSelected && 
                    <div className='react-crop-container'>
                        <ReactCrop
                            src={currentImgSelected}
                            crop={crop}
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                            minHeight={50}
                            minWidth={50}
                            keepSelection={true}
                            // style={{width: '760px', height: '460px'}}
                        />
                    </div>
                    }
                    <div className='dialog-actions'>
                        <div className='dialog-inner-position-buttons'>                                 
                            <DialogDiscardButton onClick={() => resetCrop()}>Discard</DialogDiscardButton>
                            <DialogSaveButton onClick={() => onSave(croppedImage)}>Save</DialogSaveButton>
                        </div> 
                    </div>
                </DialogContent>
                <canvas className='img-cropp-container' ref={canvasRefImageCroppRef}></canvas>
            </Dialog> 
        );
}

export default CropImageDialog;
