import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { BANNER_TYPES } from './BannerConstants';

import './Banner.scss';

const Banner = ({
    title,
    icon,
    content,
    primaryButtonComponent = null,
    secondaryButtonComponent = null,
    type = BANNER_TYPES.HORIZONTAL,
    boldTitle=true
}) => (
    <Paper className={`banner-wrapper ${type}`}>
        <div className="content-wrapper">
            {type === BANNER_TYPES.HORIZONTAL ?
                <div className="title-wrapper">
                    <Typography variant="body1" className={`${boldTitle && 'bold-title'}`}>{title}</Typography>
                    {icon}
                </div>
                :
                <div className="title-wrapper">
                    {icon}
                    <Typography variant="body1" className={`${boldTitle && 'bold-title'}`}>{title}</Typography>
                </div>
            }
            { content && <Typography variant="body1">{content}</Typography> }
        </div>
        <div className="banner-buttons-wrapper">
            {primaryButtonComponent}
            {secondaryButtonComponent}
        </div>
    </Paper>
);

export default Banner;
