import jobApplicationService from 'services/jobApplicationService';
import { logEvent } from 'utils/amplitude';
import { fetchInterviewInvitationSuccess, updateCandidateApplicationProcessInformationSuccess } from 'actions/candidateActions';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { JOB_APPLICATION_MESSAGES } from 'constants/messageConstants';
import { APPLICATION_STATUSES } from 'constants/applicationConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { JOB_OPPORTUNITIES_TYPES } from 'constants/jobOpportunitiesConstants';

import {
    APPLICATION_UPDATE_STATUS_SUCCESS,
    CANDIDATE_APPLICATION_FETCH_SUCCESS,
    CANDIDATE_APPLICATIONS_FETCH_SUCCESS,
    JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS,
    APPLICATION_UPDATE_ASSIGNEE_SUCCESS,
    APPLICATION_UPDATE_STATUS_REQUEST_DATE,
    CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS,
    CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS,
    APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS,
    JOB_APPLICATION_DELETE_SUCCESS,
    APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS,
    APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS,
    APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS,
} from './actionTypes';

const fetchCandidateApplicationSuccess = applicationData => ({
    type: CANDIDATE_APPLICATION_FETCH_SUCCESS,
    payload: applicationData
});

export const updateApplicationStatusSuccess = applicationData => ({
    type: APPLICATION_UPDATE_STATUS_SUCCESS,
    payload: applicationData,
});

const updateApplicationAssigneeSuccess = applicationData => ({
    type: APPLICATION_UPDATE_ASSIGNEE_SUCCESS,
    payload: applicationData,
});

export const fetchCandidateApplicationsSuccess = applicationsData => ({
    type: CANDIDATE_APPLICATIONS_FETCH_SUCCESS,
    payload: applicationsData
});

const updateJobOpportunityDetailsSuccess = jobDetails => ({
    type: JOB_OPPORTUNITY_DETAILS_UPDATE_SUCCESS,
    payload: jobDetails
});

const candidateApplicationsDataFetchSuccess = () => ({
    type: CANDIDATE_APPLICATIONS_DATA_FETCH_SUCCESS,
});

const candidateApplicationsDataFetchingSuccess = () => ({
    type: CANDIDATE_APPLICATIONS_DATA_FETCHING_SUCCESS,
});

export const fetchAppliedConsultantsDataSuccess = data => ({
    type: APPLIED_CONSULTANTS_DATA_FETCH_SUCCESS,
    payload: data,
})

export const updateAppliedConsultantsDataSuccess = data => ({
    type: APPLIED_CONSULTANTS_DATA_UPDATE_SUCCESS,
    payload: data
});

export const updateAppliedConsultantApplicationSuccess = data => ({
    type: APPLIED_CONSULTANT_APPLICATION_UPDATE_SUCCESS,
    payload: data
});

export const updateAppliedConsultantsApplicationSuccess = data => ({
    type: APPLIED_CONSULTANTS_APPLICATION_UPDATE_SUCCESS,
    payload: data
});

export const updateApplicationStatusRequestDate = data => ({
    type: APPLICATION_UPDATE_STATUS_REQUEST_DATE,
    payload: data,
});

const deleteJobApplicationSuccess = data => ({
    type: JOB_APPLICATION_DELETE_SUCCESS,
    payload: data,
});

export const applyForJob = (agencyId, jobId, applicationDetails) => async dispatch => {
    try {
        const result = await jobApplicationService.addJobApplication(agencyId, jobId, applicationDetails);

        dispatch(updateJobOpportunityDetailsSuccess(result));
        dispatch(updateCandidateApplicationProcessInformationSuccess({ isSearchForJobStepFinished: true, isSendJobApplicationStepFinished: true }))
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.ADD_APPLICATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));

        return result.applicationId;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.ADD_APPLICATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidateApplication = (agencyId, applicationId) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        const result = await jobApplicationService.fetchJobApplication(agencyId, applicationId);

        dispatch(fetchCandidateApplicationSuccess(result.application));
        dispatch(fetchInterviewInvitationSuccess(result.interviewInvitation));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.FETCH_CANDIDATE_APPLICATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const updateApplicationStatus = (agencyId, applicationId, statusData) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await jobApplicationService.updateJobApplicationStatus(agencyId, applicationId, statusData);

        const inviteType = result.jobType === JOB_OPPORTUNITIES_TYPES.CONTRACT ? 'on-demand' : 'dedicated';

        if (statusData.status === APPLICATION_STATUSES.APPROVED && !result.isAdminResponse) {
            logEvent(AMPLITUDE_EVENT_TYPES.HIRE_CANDIDATE, null, { inviteType });
        } else if (statusData.status === APPLICATION_STATUSES.REJECTED && !result.isAdminResponse) {
            logEvent(AMPLITUDE_EVENT_TYPES.REJECT_CANDIDATE, null, { inviteType });
        }

        dispatch(updateApplicationStatusSuccess(result));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_STATUS_SUCCESS, NOTIFICATION_TYPES.SUCCESS))
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_STATUS_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const updateApplicationStatusByAdmin = (agencyId, applicationId, statusData) => async dispatch => {
    try {
        const result = await jobApplicationService.updateJobApplicationStatusByAdmin(agencyId, applicationId, statusData);

        dispatch(updateApplicationStatusSuccess(result));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_STATUS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_STATUS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateApplicationAssignee = (agencyId, applicationId, assignee) => async dispatch => {
    try {
        const result = await jobApplicationService.updateJobApplicationAssignee(agencyId, applicationId, assignee);

        dispatch(updateApplicationAssigneeSuccess(result));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_ASSIGNEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_ASSIGNEE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchCandidateApplications = (agencyId, candidateId) => async dispatch => {
    try {
        dispatch(candidateApplicationsDataFetchingSuccess());
        
        const candidateApplications = await jobApplicationService.fetchCandidateApplications(agencyId, candidateId);
        dispatch(fetchCandidateApplicationsSuccess(candidateApplications));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.FETCH_CANDIDATE_APPLICATIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(candidateApplicationsDataFetchSuccess());
    }
};

export const fetchCandidateApplicationApproved = (agencyId, candidateId) => async dispatch => {
    try {
        const applicationsData = await jobApplicationService.fetchCandidateApplicationApproved(agencyId, candidateId);

        dispatch(fetchCandidateApplicationSuccess(applicationsData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.FETCH_CANDIDATE_APPLICATION_APPROVED_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateApplicationStartDate = (agencyId, applicationId, startDate) => async dispatch => {
    try {
        const result = await jobApplicationService.updateJobApplicationStartDate(agencyId, applicationId, startDate);

        dispatch(updateApplicationAssigneeSuccess(result));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_START_DATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.UPDATE_APPLICATION_START_DATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteJobApplication = (agencyId, applicationId, applicationStatus) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        await jobApplicationService.deleteJobApplication(agencyId, applicationId);

        dispatch(deleteJobApplicationSuccess({ applicationId, applicationStatus }));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.DELETE_JOB_APPLICATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.DELETE_JOB_APPLICATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const sendJobApplicationStatusRequestEmail = (agencyId, applicationId) => async dispatch => {
    try {
        const statusRequestDate = await jobApplicationService.sendJobApplicationStatusRequestEmail(agencyId, applicationId);

        dispatch(updateApplicationStatusRequestDate({ statusRequestDate }));
        dispatch(showNotification(JOB_APPLICATION_MESSAGES.SEND_JOB_APPLICATION_STATUS_REQUEST_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(JOB_APPLICATION_MESSAGES.SEND_JOB_APPLICATION_STATUS_REQUEST_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
