import React from 'react';

import { Paper } from '@material-ui/core';
import './InterviewSectionPaper.scss';
const InterviewSectionPaper = ({ title, text, classes, children }) => {

    return (
        <Paper className="interview-section">
            <div className="header">
                <h3 className="title">
                    {title}
                </h3>
                <p className="text">
                    {text}
                </p>
            </div>
            {children}
        </Paper>
    )
};

export default InterviewSectionPaper;
