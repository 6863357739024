import React from 'react';

import Banner from 'components/Shared/Banner';

import { ReactComponent as BannerIcon } from 'assets/banner-signing-icon.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/google-play-icon-white.svg';
import { ReactComponent as AppStoreIcon } from 'assets/app-store-icon-white.svg';

const EvrotrustInfoBanner = ({
    title = "Quick and easy signing of a holiday",
    content = "Easily sign your timeoff request form. If your time off is approved, you will receive аn email to sign the leave in your e-signature mobile app. Open the Evrotrust mobile app and sign electronically."
}) => (
    <Banner
        title={title}
        content={content}
        icon={<BannerIcon />}
        primaryButtonComponent={<a href="https://play.google.com/store/apps/details?id=com.evrotrust.app" target="_blank">
            <GooglePlayIcon className="google-play-icon" />
        </a>}
        secondaryButtonComponent={<a href="https://apps.apple.com/bg/app/evrotrust/id1195807317" target="_blank">
            <AppStoreIcon className="app-store-icon" />
        </a>}
    />
);

export default EvrotrustInfoBanner;
