import React from 'react';

import LoginCard from 'components/Shared/LoginCard';
import RegisterClientFormik from '../RegisterClientFormik';

const RegisterClientDesktopComponent = ({
    referrer,
    gclid,
    initialValues,
    registerClient,
    login,
    countries,
}) => (
    <LoginCard title="Sign up to Motion Software" subtitle="Please complete to create your account." isNewStyle={true} >
        <RegisterClientFormik
            referrer={referrer}
            gclid={gclid}
            initialValues={initialValues}
            registerClient={registerClient}
            login={login}
            countries={countries}
        />
    </LoginCard>
);

export default RegisterClientDesktopComponent;
