import React from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './Onboarding.scss';

const OnboardingStep = ({
    onboardingStepsObj,
    activeStep,
    handleSkip,
    checkIsLastStepActive,
    handleNext,
    menuInformation,
}) => (
    <div className={`onboarding-wrapper step-${checkIsLastStepActive() ? 'last' : 'middle'}`}>
        { menuInformation.companyLogoUrl && <img src={menuInformation.companyLogoUrl} alt="company logo" className="company-logo"/> }
        {onboardingStepsObj.steps[activeStep].content}
        <div className="buttons-container">
            <PrimaryButton
                text={checkIsLastStepActive() ? "Finish" : "Next"}
                handleClick={handleNext}
            />
            { !checkIsLastStepActive() &&
                <SecondaryButton className="link-button" text="Skip" handleClick={handleSkip} />
            }
        </div>
    </div>
);

export default OnboardingStep;
