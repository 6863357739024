import React, { useState, useRef } from 'react';
import { Formik } from 'formik';

import InvoicingAndAccountManagementFormView from './InvoicingAndAccountManagementFormView';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import Paper from '@material-ui/core/Paper';

import { validateRequiredFields } from 'utils/formValidations';

const InvoicingAndAccountManagementForm = ({
    agencyId,
    companyId,
    currency,
    status,
    accountManager,
    accountManagers,
    updateAccountManagementInformation,
    updateInvoiceInformation,
}) => {
    const formRef = useRef(null);
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const handleDiscard = () => {
        setShowHeaderButtons(false);
        formRef.current.values.currency = currency || '';
        formRef.current.values.status = status || '';
        formRef.current.values.accountManager = accountManager?._id || '';
    };

    const handleUpdateInvoiceInformation = async (data, resetForm) => {
        await updateInvoiceInformation(agencyId, companyId, data);
        resetForm();
    };

    const handleUpdateAccountManagementInformation = async (data, isAccountManagerUpdated, resetForm) => {
        await updateAccountManagementInformation(agencyId, companyId, data, isAccountManagerUpdated);
        resetForm();
    };

    return (
        <div className="company-information-wrapper invoicing-and-account-management-form">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Invoicing and Account Management'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={() => handleDiscard()}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => formRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>

                )}
            </SectionActionsWrapper>

            <Paper className="company-information-content-wrapper">
                <Formik
                    enableReinitialize={true}
                    innerRef={formRef}
                    initialValues={{
                        currency: currency || '',
                        status: status || '',
                        accountManager: accountManager?._id || '',
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['currency', 'status', 'accountManager']),
                        }
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        if (values.currency !== currency) {
                            const newCurrency = { currency: values.currency };
                            handleUpdateInvoiceInformation(newCurrency, resetForm);
                        }

                        if (values.status !== status || values.accountManager !== accountManager?._id) {
                            const isAccountManagerUpdated = values.accountManager !== accountManager?._id;
                            const newAccountManagementInfo = { accountManagerId: values.accountManager, status: values.status };
                            handleUpdateAccountManagementInformation(newAccountManagementInfo, isAccountManagerUpdated, resetForm);
                        }

                        setShowHeaderButtons(false);
                    }}
                >
                    {(props) =>
                        <InvoicingAndAccountManagementFormView
                            {...props}
                            handleShowHeaderButtons={handleShowHeaderButtons}
                            accountManagers={accountManagers}
                        />
                    }
                </Formik>
            </Paper>
        </div>
    );
};

export default InvoicingAndAccountManagementForm;
