import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getAgencyId } from 'reducers';

import { sendJobApplicationStatusRequestEmail } from 'actions/jobApplicationActions';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as AskRecruiterIcon } from 'assets/ask-recruiter-icon.svg';

import { logEvent } from 'utils/amplitude';
import { getRemainingTime } from './AskRecruiterBannerUtils';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import './AskRecruiterBanner.scss';

let interval;

const AskRecruiterBanner = ({
    agencyId,
    applicationId,
    applicationStatuRequestDate,
    sendJobApplicationStatusRequestEmail,
}) => {
    const [remainingTime, setRemainingTime] = useState(getRemainingTime(applicationStatuRequestDate));

    useEffect(() => {
        if (remainingTime) {
            interval = setInterval(() => setRemainingTime(getRemainingTime(applicationStatuRequestDate)), 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [remainingTime]);

    useEffect(() => {
        setRemainingTime(getRemainingTime(applicationStatuRequestDate));
    }, [applicationStatuRequestDate]);

    const handleAskRecruiterClick = () => {
        logEvent(AMPLITUDE_EVENT_TYPES.ASK_A_RECRUITER);
        sendJobApplicationStatusRequestEmail(agencyId, applicationId);
    };

    return (
        <div className="ask-recruiter-banner-container">
            <div className="ask-recruiter-banner-content">
                <h4 className="banner-title">What's the status?</h4>
                <p className="banner-body-text">
                    We know waiting for a response is unpleasant and we aim to move your application forward as fast as we can.
                    However, if you wish to receive an up to date information about your application click the button below and our recruitment team will be in touch.
                </p>
                <div>
                    <PrimaryButton
                        className="ask-the-recruiter-button"
                        text="Ask The Recruiter"
                        handleClick={handleAskRecruiterClick}
                        disabled={Boolean(remainingTime)}
                    />
                    {remainingTime && <p className="remaining-time">Available in {remainingTime}</p>}
                </div>
            </div>
            <div className="ask-recruiter-banner-icon-wrapper">
                <AskRecruiterIcon />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    sendJobApplicationStatusRequestEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(AskRecruiterBanner);
