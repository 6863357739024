import {
    HISTORICAL_NOTES_FETCH_SUCCESS,
    HISTORICAL_NOTES_ADD_SUCCESS,
    HISTORICAL_NOTES_EDIT_SUCCESS,
    HISTORICAL_NOTES_DELETE_SUCCESS,
    HISTORICAL_NOTES_FETCH_PENDING,
    HISTORICAL_NOTES_DATA_FETCH_SUCCESS,
    HISTORICAL_NOTES_DATA_FETCHING_SUCCESS
} from 'actions/actionTypes';
import { authorizedShowNotification } from 'actions/actionHelpers';
import { showNotification } from './notificationActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';

import historicalNotesService from 'services/historicalNotesService';

import { HISTORICAL_NOTES_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

// simple action creators
export const fetchHistoricalNotesSuccess = (data) => ({
    type: HISTORICAL_NOTES_FETCH_SUCCESS,
    payload: data,
});

const addHistoricalNoteSuccess = (data) => ({
    type: HISTORICAL_NOTES_ADD_SUCCESS,
    payload: data,
});

const editHistoricalNoteSuccess = (data) => ({
    type: HISTORICAL_NOTES_EDIT_SUCCESS,
    payload: data,
});

const deleteHistoricalNoteSuccess = (data) => ({
    type: HISTORICAL_NOTES_DELETE_SUCCESS,
    payload: data,
});

export const fetchHistoricalNotesPending = () => ({
    type: HISTORICAL_NOTES_FETCH_PENDING
});

const historicalNotesDataFetchSuccess = () => ({
    type: HISTORICAL_NOTES_DATA_FETCH_SUCCESS,
});

const historicalNotesDataFetchingSuccess = () => ({
    type: HISTORICAL_NOTES_DATA_FETCHING_SUCCESS,
});

// complex action creators
export const fetchHistoricalNotes = (agencyId, targetId, noteType, limit, departmentId) => async dispatch => {
    try {
        dispatch(historicalNotesDataFetchingSuccess());

        const result = await historicalNotesService.fetchHistoricalNotes(agencyId, targetId, noteType, limit, departmentId);

        dispatch(fetchHistoricalNotesSuccess(result));

        return result;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HISTORICAL_NOTES_MESSAGES.FETCH_HISTORICAL_NOTES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(historicalNotesDataFetchSuccess());
    }
};

export const addHistoricalNote = (agencyId, noteData, departmentId) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await historicalNotesService.addHistoricalNote(agencyId, noteData, departmentId);

        dispatch(addHistoricalNoteSuccess(result));
        dispatch(showNotification(HISTORICAL_NOTES_MESSAGES.ADD_HISTORICAL_NOTES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HISTORICAL_NOTES_MESSAGES.ADD_HISTORICAL_NOTES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const editHistoricalNote = (agencyId, noteId, noteData, departmentId) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const result = await historicalNotesService.editHistoricalNote(agencyId, noteId, noteData, departmentId);

        dispatch(editHistoricalNoteSuccess(result));
        dispatch(showNotification(HISTORICAL_NOTES_MESSAGES.EDIT_HISTORICAL_NOTE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HISTORICAL_NOTES_MESSAGES.EDIT_HISTORICAL_NOTE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const deleteHistoricalNote = (agencyId, noteId, departmentId) => async dispatch => {
    try {
        const result = await historicalNotesService.deleteHistoricalNote(agencyId, noteId, departmentId);

        dispatch(deleteHistoricalNoteSuccess(result));
        dispatch(showNotification(HISTORICAL_NOTES_MESSAGES.DELETE_HISTORICAL_NOTE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HISTORICAL_NOTES_MESSAGES.DELETE_HISTORICAL_NOTE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
