import React from 'react';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import { Paper, Typography } from '@material-ui/core';

import './ClientOverview.scss';

const ClientOverview = ({
    projectsCount,
    monthlySpending,
    totalRevenue,
    clientCurrency
}) => (
    <div className="client-overview-wrapper">
        <SectionActionsWrapper>
            <SectionActionsWrapper.SectionTitle
                sectionTitle='Client Overview'
                className='section-title'
            />
        </SectionActionsWrapper>
        <div>
            <Paper className="client-overview-content">
                <Typography className="overview-subtitle" variant="h6">Project</Typography>
                <div className="projects-content">
                    <div className="active-projects">
                        <Typography variant="subtitle2" >Active projects</Typography>
                        <span>{projectsCount.active}</span>
                    </div>
                    <div className="complete-projects">
                        <Typography variant="subtitle2" >Complete projects</Typography>
                        <span>{projectsCount.complete}</span>
                    </div>
                    <div className="total-projects">
                        <Typography variant="subtitle2" >Total projects</Typography>
                        <span>{projectsCount.total}</span>
                    </div>
                </div>
                <Typography className="overview-subtitle" variant="h6">Financial</Typography>
                <div className="financial-content">
                    <div className="monthly-finance">
                        <Typography variant="subtitle2" >Monthly revenue</Typography>
                        <span>{`${monthlySpending.toFixed(2)} ${clientCurrency}`}</span>
                    </div>
                    <div className="total-finance">
                        <Typography variant="subtitle2" >Total revenue</Typography>
                        <span>{`${Math.round(totalRevenue)} ${clientCurrency}`}</span>
                    </div>
                </div>
            </Paper>
        </div>
    </div>
);


export default ClientOverview;
