import api from './api';
import requester from './requester';

const interviewInvitationService = {
    fetchCandidateInterviewInvitations: (agencyId, candidateId) => requester(api.candidateInterviewInvitations(agencyId, candidateId)).getOne(),
    updateInterviewDate: (agencyId, interviewId, data) => requester(api.candidateInterviewDate(agencyId, interviewId)).update(data),
    updateInterviewData: (agencyId, interviewId, data) => requester(api.candidateInterviewData(agencyId, interviewId)).update(data),
    updateInterviewStatus: (agencyId, interviewId, data) => requester(api.candidateInterviewStatus(agencyId, interviewId)).update(data),
    createInterviewInvitation: (agencyId, data) => requester(api.interviewInvitation(agencyId)).create(data),
    rescheduleInterview: (agencyId, interviewId, data) => requester(api.rescheduleInterview(agencyId, interviewId)).create(data),
};

export default interviewInvitationService;
