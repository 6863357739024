import React from 'react';

import moment from 'moment';

import { ErrorMessage, Form } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MenuItem, TextField, DialogActions } from '@material-ui/core';

import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import UploadFiles from 'components/Shared/UploadFiles';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, MAX_DATE } from 'constants/commonConstants';
import { BUSINESS_TRIP_CATEGORIES } from '../BusinessTripBannerConstants';

import './BusinessTripDialog.scss';

const ACCEPTED_FORMATS = ['png', 'jpg', 'pdf', 'jpeg', 'tiff', 'gif', 'bmp', 'heic'];

const BusinessTripFormView = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleCloseDialog,
    handleSubmit,
    countries,
}) => (
    <Form className="business-trip-form">
        <div className="form-field">
            <KeyboardDatePicker
                autoOk={true}
                className="date-field"
                label="Start date"
                name="startDate"
                disableToolbar
                helperText={null}
                maxDate={values.endDate || MAX_DATE}
                variant="inline"
                inputVariant="outlined"
                value={values.startDate}
                onChange={value => {
                    setFieldValue('startDate', moment(value));
                }}
                format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                allowKeyboardControl={true}
                fullWidth
            />
            <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <KeyboardDatePicker
                autoOk={true}
                className="date-field"
                label="End date"
                name="endDate"
                disableToolbar
                helperText={null}
                minDate={values.startDate || new Date()}
                variant="inline"
                inputVariant="outlined"
                value={values.endDate}
                onChange={value => {
                    setFieldValue('endDate', moment(value));
                }}
                format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                allowKeyboardControl={true}
                fullWidth
            />
            <ErrorMessage name="endDate" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <TextField
                select
                name="country"
                label="Country"
                value={values.country}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={errors.country && touched.country}
            >
                {countries.map((country, i) => (
                    <MenuItem native="true" key={i} value={country.name}>
                        {country.name}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="country" component="div" className="invalid-field-message" />
        </div>
        <div className="form-field">
            <TextField
                select
                name="category"
                label="Category"
                value={values.category}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                error={errors.category && touched.category}
            >
                {BUSINESS_TRIP_CATEGORIES.map((category) => (
                    <MenuItem native="true" key={category._id} value={category.value}>
                        {category.value}
                    </MenuItem>
                ))}
            </TextField>
            <ErrorMessage name="category" component="div" className="invalid-field-message" />
        </div>
        <div className="boarding-card-field">
            <h2 className="upload-file-title">Upload Boarding Card</h2>
            <p className="upload-file-description">Take a photo of your boarding passes and upload them. Make sure the text is easily readable.</p>
            <UploadFiles
                name="boardingCardFiles"
                filesAttached={values.boardingCardFiles}
                setFieldValue={setFieldValue}
                acceptedFormats={ACCEPTED_FORMATS}
                variant="new"
            />
            <ErrorMessage name="boardingCardFiles" component="div" className="invalid-field-message" />
        </div>
        <div className="photos-field">
            <h2 className="upload-file-title">Upload Photos</h2>
            <p className="upload-file-description">Take a photo of all the receipts you've collected and upload them. Make sure the text is easily readable.</p>
            <UploadFiles
                name="photoFiles"
                filesAttached={values.photoFiles}
                setFieldValue={setFieldValue}
                acceptedFormats={ACCEPTED_FORMATS}
                variant="new"
            />
            <ErrorMessage name="photoFiles" component="div" className="invalid-field-message" />
        </div>
        <DialogActions className="dialog-actions">
            <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text="Discard" />
            <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Submit" type="submit" />
        </DialogActions>
    </Form>
);

export default BusinessTripFormView;
