import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { fetchJobMatchingConsultantsPage } from 'actions/jobOpportunityActions';
import { getDisplayMode, getJobOpportunityDetails, getJobMatchingConsultantsPageFetchState, getAgencyRouteName } from 'reducers';

import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import MatchingConsultants from './MatchingConsultants';
import JobDetails from 'components/JobPage/JobDetails';
import { ROLES } from 'constants/userConstants';

const JobMatchingConsultants = ({
    agencyId,
    userId,
    dataFetchState,
    displayMode,
    agencyRouteName,
    job,
    fetchJobMatchingConsultantsPage,
}) => {
    const { jobId } = useParams();
    const history = useHistory();

    useEffect(() => {
        fetchJobMatchingConsultantsPage(agencyId, jobId);
    }, [])

    const handleGoBackBtnClick = () => {
        const backPath = `job-page/${jobId}`;

        history.push(`/${agencyRouteName}/${backPath}`);
    }

    return (
        <div>
            <div className="previous-button-container">
                <PreviousButton text={"Back to Job"} handleClick={handleGoBackBtnClick} />
            </div>

            <JobDetails
                agencyId={agencyId}
                isAdmin={displayMode === ROLES.ADMIN}
                isClient={displayMode === ROLES.CLIENT}
                job={job}
                isWithLinkToJobPage={true}
            />

            <MatchingConsultants
                dataFetchState={dataFetchState}
                job={job}
                userId={userId}
                agencyId={agencyId}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    dataFetchState: getJobMatchingConsultantsPageFetchState(state),
    displayMode: getDisplayMode(state),
    job: getJobOpportunityDetails(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    fetchJobMatchingConsultantsPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobMatchingConsultants);
