const api = {
    users: (type) => `users/actions?type=${type}`,
    user: (userId) => `users/${userId}`,
    userUnassignedRole: (userId) => `users/unassigned/${userId}/role`,
    userUnassignedTermsAndConditions: (userId) => `users/unassigned/${userId}/terms-and-conditions`,
    userCompanyInformation: (userId) => `users/${userId}/company-information`,
    registerCandidate: (isSSO) => `users/register/candidate?isSSO=${!!isSSO}`,
    registerUnassigned: 'users/register/unassigned',
    rememberMe: () => `users/remember-me`,
    registerClient: 'users/register/client',
    registerSupplier: 'users/register/supplier',
    registerAgency: 'users/register/agency',
    verificationLink: (email) => `users/verification-link/${email}`,
    customerSupport: () => 'users/customer-support',
    clientUsers: (clientId, query) => `users/clients/${clientId}${query}`,
    userAccountManagerInformation: (agencyId, userId) => `users/account-manager?agencyId=${agencyId}&userId=${userId}`,
    userUpdate: (email) => `users/${email}`,
    deleteRecruiterEmployees: () => 'users/recruiter-employees',
    isOnboardingPassed: (userId) => `users/${userId}/is-onboarding-passed`,
    userForgottenPassword: (email) => `users/forgotten-password/${email}`,
    resendVerificationEmail: () => `users/resend-verification-email`,
    employeePublicHolidays: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/public-holidays`,
    agencyPublicHolidays: (agencyId, userId, role) => `agencies/${agencyId}/public-holidays?userId=${userId}&role=${role}`,
    performanceReviews: (employeeId, agencyId, reviewId) => `agencies/${agencyId}/employees/${employeeId}/performance-reviews?reviewId=${reviewId}`,
    employeeProfile: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/profile?departmentId=${departmentId}`,
    personalInfo: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/personal-information?departmentId=${departmentId}`,
    personalInfoView: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/view`,
    employmentInformation: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/employment-information?departmentId=${departmentId}`,
    employeeUploadedCv: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/uploaded-cv`,
    employeeUploadedCvDocument: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/uploaded-cv-document`,
    holidayAllowance: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/holidayAllowance?departmentId=${departmentId}`,
    updateEmployeeActiveCompensation: (employeeId, agencyId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/active-compensation`,
    bankDetails: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/bank-details?departmentId=${departmentId}`,
    languages: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/languages?departmentId=${departmentId}`,
    languageModify: (employeeId, langId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/languages/${langId}?departmentId=${departmentId}`,
    education: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/education?departmentId=${departmentId}`,
    educationModify: (employeeId, eduId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/education/${eduId}?departmentId=${departmentId}`,
    educationModifyEntries: (employeeId, agencyId) => `agencies/${agencyId}/employees/${employeeId}/education`,
    profileSkills: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/profile-skills/?departmentId=${departmentId}`,
    profileSkill: (agencyId, employeeId, profileSkillId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/profile-skills/${profileSkillId}?departmentId=${departmentId}`,
    getProject: (employeeId, projectId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/projects/${projectId}?departmentId=${departmentId}`,
    project: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/projects?departmentId=${departmentId}`,
    projectModify: (employeeId, projectId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/projects/${projectId}?departmentId=${departmentId}`,
    template: (agencyId) => `agencies/${agencyId}/project-templates/`,
    templateModify: (templateId, agencyId) => `agencies/${agencyId}/project-templates/${templateId}`,
    certificates: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/certificates?departmentId=${departmentId}`,
    certificateModify: (employeeId, certId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/certificates/${certId}?departmentId=${departmentId}`,
    certificatesModify: (employeeId, agencyId) => `agencies/${agencyId}/employees/${employeeId}/certificates`,
    generalInfo: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/general-info?departmentId=${departmentId}`,
    contactInfo: (agencyId, departmentId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/contact?departmentId=${departmentId}`,
    personalInformation: (agencyId, departmentId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/personal?departmentId=${departmentId}`,
    basicInfo: (agencyId, departmentId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/basic?departmentId=${departmentId}`,
    trackedDays: (agencyId, departmentId, employeeId, month) => `agencies/${agencyId}/employees/${employeeId}/time-tracking/${month}/tracked-days?departmentId=${departmentId}`,
    trackedDaysWithType: (agencyId, departmentId, employeeId, month, type) => `agencies/${agencyId}/employees/${employeeId}/time-tracking/${month}/tracked-days?type=${type}&departmentId=${departmentId}`,
    employeeTimeTracking: (agencyId, employeeId, departmentId, month) => `agencies/${agencyId}/employees/${employeeId}/time-tracking?departmentId=${departmentId}&month=${month}`,
    cv: (agencyId, employeeIds, departmentId) => `agencies/${agencyId}/employees/cvs?employeeIds=${employeeIds}&departmentId=${departmentId}`,
    candidateCV: (agencyId, candidateIds) => `agencies/${agencyId}/candidates/cvs?candidateIds=${candidateIds}`,
    supplierEmployeesCVs: (agencyId, supplierIds) => `agencies/${agencyId}/suppliers/cvs?supplierIds=${supplierIds}`,
    fetchEmployeeViewProfileData: (userId, agencyId) => `agencies/${agencyId}/employees/${userId}/talent-cv`,
    supplierEmployeeViewProfileData: (userId, agencyId) => `agencies/${agencyId}/suppliers/${userId}/talent-cv`,
    employees: (agencyId, fields, roles) => `agencies/${agencyId}/employees?fields=${fields}&roles=${roles}`,
    adminTeamPage: (agencyId, query) => `agencies/${agencyId}/employees/admin-team-page${query}`,
    adminTeamExport: agencyId => `agencies/${agencyId}/employees/export/admin-team`,
    teamSample: (agencyId, userId, size) => `agencies/${agencyId}/employees/${userId}/sample?size=${size}`,
    fetchSkillSuggestions: (agencyId, inputText) => `agencies/${agencyId}/employees/search/skills?input=${inputText}`,
    talents: (agencyId) => `agencies/${agencyId}/talents`,
    hottestTalents: (agencyId, query) => `agencies/${agencyId}/hottest-talents${query}`,
    hottestTalent: (agencyId, userId) => `agencies/${agencyId}/hottest-talents/${userId}`,
    supplierEmployees: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/employees`,
    supplierTeamPage: (agencyId, supplierId, query) => `agencies/${agencyId}/suppliers/${supplierId}/employees/page${query}`,
    candidates: (agencyId, queryParams) => `agencies/${agencyId}/candidates${queryParams ? queryParams : ''}`,
    candidatesExport: agencyId => `agencies/${agencyId}/candidates/export`,
    transferredCandidates: agencyId => `agencies/${agencyId}/candidates/transferred-candidates`,
    checklistedCandidates: agencyId => `agencies/${agencyId}/candidates/checklisted`,
    updateAvatar: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/avatar?departmentId=${departmentId}`,
    deleteAvatar: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/avatar?departmentId=${departmentId}`,
    candidateApplications: (agencyId, candidateId) => `agencies/${agencyId}/job-applications/candidates/${candidateId}`,
    candidateApplicationPage: (agencyId, candidateId, applicationId) => `agencies/${agencyId}/candidates/${candidateId}/applications/${applicationId}`,
    candidateApplicationApproved: (agencyId, candidateId) => `agencies/${agencyId}/job-applications/candidates/${candidateId}/approved`,
    candidateApplicationFeedback: (agencyId, candidateId, applicationId) => `agencies/${agencyId}/candidates/${candidateId}/applications/${applicationId}/feedback`,
    candidateProfileSteps: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/profile-steps`,
    jobOpportunities: (agencyId) => `agencies/${agencyId}/job-opportunities`,
    jobOpportunityPage: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}/page`,
    jobMatchingConsultantsPage: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}/matching-consultants`,
    fetchJobOpportunitiesAdminPage: (agencyId) => `agencies/${agencyId}/job-opportunities/page`,
    indexedJobOpportunities: (agencyId, userId) => `agencies/${agencyId}/job-opportunities/indexed?userId=${userId}`,
    exportJobOpportunities: (agencyId) => `agencies/${agencyId}/job-opportunities/export`,
    exportClientJobOpportunities: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/job-opportunities/export`,
    jobOpportunity: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}`,
    jobOpportunityPrivacy: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}/privacy`,
    jobApplicationJob: (agencyId, jobId) => `agencies/${agencyId}/job-applications/job/${jobId}`,
    jobOpportunityImage: (agencyId, imageName) => `agencies/${agencyId}/job-opportunities/image?imageName=${imageName}`,
    jobOpportunitiesTechnologies: (agencyId) => `agencies/${agencyId}/job-opportunities/technologies`,
    jobOpportunityProfiles: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}/profiles`,
    jobOpportunityMatchedEmails: (agencyId, jobId) => `agencies/${agencyId}/job-opportunities/${jobId}/matched-emails`,
    jobOpportunityTalentMatch: (agencyId, userId) => `agencies/${agencyId}/job-opportunities/talent-match?userId=${userId}`,
    jobApplication: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}`,
    jobApplicationStatusAdmin: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}/admin/status`,
    jobApplicationStatus: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}/status`,
    jobApplicationAssignee: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}/assignee`,
    jobApplicationStartDate: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}/start-date`,
    jobApplicationStatusRequest: (agencyId, applicationId) => `agencies/${agencyId}/job-applications/${applicationId}/status-request-email`,
    allClientJobs: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/jobs`,
    task: (employeeId, agencyId, onBehalfOf) => `agencies/${agencyId}/employees/${employeeId}/tasks?onBehalfOf=${onBehalfOf}`,
    clientTasks: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/tasks`,
    clientTaskUpdate: (agencyId, clientId, taskId) => `agencies/${agencyId}/clients/${clientId}/tasks/${taskId}`,
    clientInvoicing: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/invoicing`,
    taskModify: (employeeId, taskId, agencyId) => `agencies/${agencyId}/employees/${employeeId}/tasks/${taskId}`,
    employeeTimesheet: (employeeId, month, agencyId, departmentId, type) =>
        `agencies/${agencyId}/employees/${employeeId}/time-tracking/${month}/timesheet/actions?type=${type}&departmentId=${departmentId}`,
    holidayRequest: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/holidays?departmentId=${departmentId}`,
    employeeHolidayRequests: (employeeId, agencyId, departmentId, type) => `agencies/${agencyId}/employees/${employeeId}/holidays/actions?type=${type}&departmentId=${departmentId}`,
    employeeHolidays: (employeeId, agencyId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/holidays?departmentId=${departmentId}`,
    employeeHolidaysForPeriod: (employeeId, agencyId, from, to, departmentId) => `agencies/${agencyId}/employees/${employeeId}/holidays?from=${from}&to=${to}&departmentId=${departmentId}`,
    downloadSignedHolidayFile: (agencyId, departmentId, employeeId, holidayId, isDeleted) => `agencies/${agencyId}/employees/${employeeId}/holidays/${holidayId}/download-signed-holiday?departmentId=${departmentId}&isDeleted=${isDeleted}`,
    downloadSignedHolidayFiles: (agencyId, departmentId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/holidays/download-signed-holidays?departmentId=${departmentId}`,
    sendHolidaySignReminderEmail: (agencyId, departmentId, employeeId, holidayId) => `agencies/${agencyId}/employees/${employeeId}/holidays/${holidayId}/sign-holiday-email-reminder?departmentId=${departmentId}`,
    managedEmployeesPendingHolidays: (agencyId, managerId, query) => `agencies/${agencyId}/managers/${managerId}/employees-holidays/pending${query || ''}`,
    agencyInfo: (agencyId, departmentId) => `agencies/${agencyId}/company-information?departmentId=${departmentId}`,
    fetchAllHolidays: (employeeId, agencyId) => `agencies/${agencyId}/employees/${employeeId}/holidays`,
    agencyLogo: agencyId => `agencies/${agencyId}/company-information/logo`,
    clients: (agencyId, clientIds) => `agencies/${agencyId}/clients${clientIds ? `?clientIds=${clientIds}` : ''}`,
    clientsPage: (agencyId, query) => `agencies/${agencyId}/clients/page${query}`,
    clientsExport: agencyId => `agencies/${agencyId}/clients/export`,
    countries: () => `static-data/countries`,
    staticLanguagesData: () => 'static-data/languages',
    staticLanguagesSuggestions: inputText => `static-data/languages/search?inputText=${inputText}`,
    agencyHolidaysUpdate: agencyId => `agencies/${agencyId}/employee-settings/holidays`,
    agencyEmployeeSettings: agencyId => `agencies/${agencyId}/employee-settings`,
    agencyPerformanceCriterias: (agencyId, query) => `agencies/${agencyId}/employee-settings/performance-criterias${query || ''}`,
    agencyImportantDocuments: (agencyId, query) => `agencies/${agencyId}/employee-settings/important-documents${query || ''}`,
    agencyDownloadImportantDocuments: (agencyId, documentId) => `agencies/${agencyId}/employee-settings/important-documents/${documentId}`,
    agencyTimesheets: (agencyId, month, query) => `agencies/${agencyId}/time-management/tracked-months/${month}/timesheet${query || ''}`,
    agencyTimesheetsExport: (agencyId, employeeIds, months, type) =>
        `agencies/${agencyId}/time-management/tracked-months/${months}/timesheet/actions?type=${type}&employeeIds=${employeeIds}`,
    agencyPendingHolidays: (agencyId, query) => `agencies/${agencyId}/holidays/pending${query || ''}`,
    updateAgencyHolidayRequests: (agencyId, type) => `agencies/${agencyId}/holidays/actions?type=${type}`,
    agencyReviewedHolidays: (agencyId, query) => `agencies/${agencyId}/holidays/reviewed${query || ''}`,
    agencyApprovedHolidays: (agencyId, from, to) => `agencies/${agencyId}/holidays/approved?from=${from}&to=${to}`,
    agencySickLeaves: (agencyId, from, to) => `agencies/${agencyId}/holidays/sick-leaves?from=${from}&to=${to}`,
    clientInformation: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information`,
    updateClientAvatar: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information/avatar`,
    deleteClientAvatar: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information/avatar`,
    updateClientBasicInformation: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information/basic`,
    updateClientContactInformation: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information/contact`,
    clientFlag: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/flag`,
    clientAccountManagementInformation: (agencyId, clientId, field) => `agencies/${agencyId}/clients/${clientId}/client-information/account-management?field=${field}`,
    updateInvoiceInformation: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/client-information/invoice`,
    createContract: (agencyId, clientId) => `agencies/${agencyId}/contracts?userId=${clientId}`,
    signContract: (agencyId, clientId, contractId) => `agencies/${agencyId}/contracts/${contractId}/evrotrust-signature?userId=${clientId}`,
    deleteContract: (agencyId, contractId, departmentId) => `agencies/${agencyId}/contracts/${contractId}?departmentId=${departmentId}`,
    fetchContracts: (agencyId, query) => `agencies/${agencyId}/contracts${query}`,
    downloadContractFiles: (agencyId, clientId, contractId) => `agencies/${agencyId}/contracts/${contractId}?userId=${clientId}`,
    contractFilesUrl: (agencyId, clientId, contractId) => `agencies/${agencyId}/contracts/${contractId}/public-url?userId=${clientId}`,
    sendContractSignReminderEmail: (agencyId, userId, contractId) => `agencies/${agencyId}/contracts/${contractId}/${userId}/sign-contract-email-reminder`,
    addInvoice: (agencyId, userId, senderRole) => `agencies/${agencyId}/invoices?userId=${userId}&senderRole=${senderRole}`,
    updateInvoiceStatus: (agencyId, invoiceId) => `agencies/${agencyId}/invoices/${invoiceId}`,
    fetchInvoices: (agencyId, query) => `agencies/${agencyId}/invoices${query}`,
    downloadInvoiceFiles: (agencyId, userId, invoiceId) => `agencies/${agencyId}/invoices/${invoiceId}`,
    searchUsersByNames: (agencyId, searchTerm, roles) => `agencies/${agencyId}/search/users/by-names?roles=${roles}&term=${encodeURIComponent(searchTerm)}`,
    search: (agencyId, roles, searchTerm, detailedInformation, limit) => `agencies/${agencyId}/search/?roles=${roles}&term=${encodeURIComponent(searchTerm)}&limit=${limit}&detailedInformation=${detailedInformation}`,
    searchUsersResponses: (agencyId, searchTerm) => `agencies/${agencyId}/search/users/responses?term=${encodeURIComponent(searchTerm)}`,
    clientProjects: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/projects`,
    clientProjectsFeedback: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/projects/feedback`,
    clientProject: (agencyId, clientId, projectId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}`,
    allClientTeamHistory: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/projects/all-team-history`,
    clientProjectEmployeeHistory: (agencyId, clientId, projectId, employeeId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/employees/${employeeId}/history`,
    clientProjectDedicatedEmployeeHistory: (agencyId, clientId, projectId, employeeId, field) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/dedicated_employees/${employeeId}/history?field=${field}`,
    //TO DO discuss a better end for the route
    fetchClientProjectsWithEmployees: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/projects/employees`,
    clientProjectsTotalRevenue: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/projects/total-revenue`,
    fetchClientProjects: (agencyId, clientId, fields) => `agencies/${agencyId}/clients/${clientId}/projects?fields=${fields}`,
    addFeedbackToProject: (agencyId, clientId, projectId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/feedback`,
    clientProjectDocuments: (agencyId, clientId, projectId, type) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/documents${type ? `?type=${type}` : ''}`,
    clientProjectDocument: (agencyId, clientId, projectId, documentId, documentType) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/documents/${documentId}?documentType=${documentType}`,
    fetchProjectReviews: (agencyId, clientId, projectId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/feedback`,
    addEmployeeToProject: (agencyId, clientId, projectId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/employees`,
    addDedicatedEmployeeToProject: (agencyId, clientId, projectId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/dedicated_employees`,
    updateProjectEmployee: (agencyId, clientId, projectId, employeeId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/employees/${employeeId}`,
    updateProjectDedicatedEmployee: (agencyId, clientId, projectId, employeeId) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/dedicated_employees/${employeeId}`,
    removeProjectEmployee: (agencyId, clientId, projectId, employeeId, dateDeleted) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/employees/${employeeId}?dateDeleted=${dateDeleted}`,
    removeProjectDedicatedEmployee: (agencyId, clientId, projectId, employeeId, dateDeleted) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/dedicated_employees/${employeeId}?dateDeleted=${dateDeleted}`,
    suppliers: (agencyId, query) => `agencies/${agencyId}/suppliers${query || ''}`,
    suppliersAccountManagers: agencyId => `agencies/${agencyId}/suppliers/account-managers`,
    supplierEmployee: (agencyId, supplierId, employeeId) => `agencies/${agencyId}/suppliers/${supplierId}/employees/${employeeId}`,
    suppliersExport: agencyId => `agencies/${agencyId}/suppliers/export`,
    supplierInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information`,
    supplierEmployeesProjects: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/employees-projects`,
    updateSupplierAvatar: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/avatar`,
    deleteSupplierAvatar: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/avatar`,
    updateSupplierBasicInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/basic`,
    updateSupplierContactInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/contact`,
    updateSupplierInvoiceInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/invoice`,
    supplierEmployeeSettings: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-settings`,
    updateSupplierHolidayAllowance: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-settings/holidays`,
    supplierAccountManagerInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-information/account-manager`,
    supplierTasks: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/tasks`,
    supplierTaskUpdate: (agencyId, supplierId, taskId) => `agencies/${agencyId}/suppliers/${supplierId}/tasks/${taskId}`,
    employeeProjects: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/projects`,
    employeeProjectsWithClientInfo: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/projects/with-clients`,
    homeInformation: (agencyId, userId, role, isLogin) => `agencies/${agencyId}/home?userId=${userId}${role ? `&role=${role}` : ''}${isLogin ? `&login=true` : ''}`,
    admins: (agencyId, query) => `agencies/${agencyId}/admins${query || ''}`,
    employeesRole: (agencyId) => `agencies/${agencyId}/employees/role`,
    benefitTemplates: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/perks-and-equipment/templates`,
    benefits: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/perks-and-equipment`,
    benefitModify: (agencyId, employeeId, benefitId) => `agencies/${agencyId}/employees/${employeeId}/perks-and-equipment/${benefitId}`,
    consultants: (agencyId, fields) => `agencies/${agencyId}/consultants?fields=${fields}`,
    clientConsultants: (agencyId, fields) => `agencies/${agencyId}/consultants/client?fields=${fields}`,
    agencyTrainings: (agencyId, query) => `agencies/${agencyId}/trainings${query || ''}`,

    createTrainingTopicVideo: (agencyId, trainingId, topicId) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/videos`,
    deleteTrainingTopicVideo: (agencyId, trainingId, topicId, videoId) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/videos/${videoId}`,

    agencySingleTraining: (agencyId, trainingId) => `agencies/${agencyId}/trainings/${trainingId}`,
    agencyTrainingTopicUploadFiles: (agencyId, trainingId, topicId) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/upload-files`,
    agencyTrainingTopicDownloadFile: (agencyId, trainingId, topicId, fileName) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/download/${fileName}`,
    agencyTrainingTopicFileDelete: (agencyId, trainingId, topicId, fileName) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/delete/${fileName}`,
    exportInvoiceTimesheets: (agencyId, invoiceId, month, userId, userRole) =>
        `agencies/${agencyId}/time-management/invoices/${invoiceId}/tracked-month/${month}/timesheets?userId=${userId}&role=${userRole}`,
    resendInvoiceEmail: (agencyId, userId) => `agencies/${agencyId}/invoices/resend-email?userId=${userId}`,
    invoice: (agencyId, invoiceId, userId) => `agencies/${agencyId}/invoices/${invoiceId}?userId=${userId || ''}`,
    agencyProjects: agencyId => `agencies/${agencyId}/projects`,
    agencyTasks: agencyId => `agencies/${agencyId}/tasks`,
    agencyTask: (agencyId, taskId) => `agencies/${agencyId}/tasks/${taskId}`,
    agencyTrainingTopics: (agencyId, trainingId) => `agencies/${agencyId}/trainings/${trainingId}/topics`,
    agencyTrainingSingleTopic: (agencyId, trainingId, topicId) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}`,
    agencyTrainingSingleTopicDescription: (agencyId, trainingId, topicId) => `agencies/${agencyId}/trainings/${trainingId}/topics/${topicId}/description`,
    recruiters: (agencyId, query) => `agencies/${agencyId}/recruiters${query || ''}`,
    recruiterEmployees: (agencyId, recruiterId) => `agencies/${agencyId}/recruiters/${recruiterId}/profiles`,
    recruiterEmployee: (agencyId, recruiterId, profileId) => `agencies/${agencyId}/recruiters/${recruiterId}/profiles/${profileId}`,
    recruiterInformation: (agencyId, recruiterId) => `agencies/${agencyId}/recruiters/${recruiterId}/recruiter-information`,
    recruiterAvatar: (agencyId, supplierId) => `agencies/${agencyId}/recruiters/${supplierId}/recruiter-information/avatar`,
    recruiterBasicInformation: (agencyId, supplierId) => `agencies/${agencyId}/recruiters/${supplierId}/recruiter-information/basic`,
    recruiterContactInformation: (agencyId, supplierId) => `agencies/${agencyId}/recruiters/${supplierId}/recruiter-information/contact`,
    recruiterAccountManagerInformation: (agencyId, supplierId) => `agencies/${agencyId}/recruiters/${supplierId}/recruiter-information/account-manager`,
    recruitersAccountManagers: agencyId => `agencies/${agencyId}/recruiters/account-managers`,
    contractInformation: (agencyId, departmentId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/contract-information?departmentId=${departmentId}`,
    employmentDates: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/employment-information/employment-dates?departmentId=${departmentId}`,
    uploadRecruiterEmployeeCV: (agencyId, recruiterId, profileId) => `agencies/${agencyId}/recruiters/${recruiterId}/profiles/${profileId}/upload-cv`,
    downloadRecruiterEmployeeCV: (agencyId, recruiterId, profileId) => `agencies/${agencyId}/recruiters/${recruiterId}/profiles/${profileId}/download-cv`,
    deleteRecruiterEmployeeCV: (agencyId, recruiterId, profileId) => `agencies/${agencyId}/recruiters/${recruiterId}/profiles/${profileId}/delete-cv`,
    employeeRelatedOpportunities: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/related-opportunities?departmentId=${departmentId}`,
    employeeDashboardInformation: (agencyId, employeeId, from, to) => `agencies/${agencyId}/employees/${employeeId}/dashboard-information?from=${from}&to=${to}`,
    clientDashboardInformation: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/dashboard-information`,
    supplierAdminDashboardInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/dashboard-information`,
    agencyDashboardInformation: (agencyId, from, to, fields, roles, agencyAdminId) => `agencies/${agencyId}/dashboard-information?from=${from}&to=${to}&fields=${fields}&agencyAdminId=${agencyAdminId}&roles=${roles}`,
    agencyDashboardCandidates: (agencyId) => `agencies/${agencyId}/dashboard-information/assigned-candidates`,
    candidatePersonalInformation: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/personal-information`,
    candidatePreferences: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/preferences`,
    candidateCriteria: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/criteria`,
    candidateVideoInterviewQuestion: (agencyId, candidateId, category, questionId) => `agencies/${agencyId}/candidates/${candidateId}/video-interview/question/${category}${questionId ? `?questionId=${questionId}` : ''}`,
    candidateVideoInterviewQuestions: (agencyId, candidateId, category) => `agencies/${agencyId}/candidates/${candidateId}/video-interview/questions/${category}`,
    candidateVideoInterviewAnswers: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/video-interview/answers`,
    candidateVideoInterviewFinish: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/video-interview/finish`,
    candidateVideoInterviewFeedbackInformation: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/video-interview/feedback-information`,
    candidateFaceToFaceInterview: (agencyId, candidateId, withEmail) => `agencies/${agencyId}/candidates/${candidateId}/face-to-face-interview?withEmail=${withEmail}`,
    candidateContractorFilters: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/contractor-filters`,
    userRole: (agencyId, userId) => `agencies/${agencyId}/users/${userId}/role`,
    agencyDeletedUsers: (agencyId, query) => `agencies/${agencyId}/users/deleted${query || query}`,
    agencyEvrotrustInformation: (agencyId) => `agencies/${agencyId}/company-information/evrotrust-information`,
    agencyEvrotrustIntegration: (agencyId) => `agencies/${agencyId}/company-information/evrotrust-integration`,
    agencyEvrotrustUsage: (agencyId) => `agencies/${agencyId}/company-information/evrotrust-information/usage`,
    agencyCalendlyInformation: (agencyId) => `agencies/${agencyId}/agency-calendly/calendly-information`,
    supplierAgencyEvrotrustInformation: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/supplier-settings/evrotrust-information`,
    departments: (agencyId, query) => `agencies/${agencyId}/departments${query || ''}`,
    department: (agencyId, departmentId) => `agencies/${agencyId}/departments/${departmentId}`,
    departmentMembers: (agencyId, departmentId, query) => `agencies/${agencyId}/departments/${departmentId}/members${query || ''}`,
    candidateAssignee: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/assignee`,
    candidateLinkedInUrl: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/linked-in-url`,
    candidateProfile: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/profile`,
    interviewInvitation: agencyId => `agencies/${agencyId}/interview-invitation`,
    rescheduleInterview: (agencyId, interviewId) => `agencies/${agencyId}/interview-invitation/${interviewId}/reschedule`,
    candidateInterviewInvitations: (agencyId, candidateId) => `agencies/${agencyId}/interview-invitation/candidates/${candidateId}`,
    candidateInterviewDate: (agencyId, interviewId) => `agencies/${agencyId}/interview-invitation/${interviewId}/date`,
    candidateInterviewData: (agencyId, interviewId) => `agencies/${agencyId}/interview-invitation/${interviewId}`,
    candidatePersonalInformationPages: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/personal-information/page`,
    candidateInterviewStatus: (agencyId, interviewId) => `agencies/${agencyId}/interview-invitation/${interviewId}/status`,
    userManagementPage: (agencyId, fields, roles, filters) => `agencies/${agencyId}/user-management?fields=${fields}&roles=${roles}${filters && `&filters=${filters}`}`,
    clientUserManagementPage: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/user-management`,
    employeeHolidayPage: (agencyId, employeeId, from, to, departmentId) => `agencies/${agencyId}/employees/${employeeId}/holidays/page?from=${from}&to=${to}&departmentId=${departmentId}`,
    employeeOverview: (agencyId, employeeId, departmentId) => `agencies/${agencyId}/employees/${employeeId}/overview?${departmentId}`,
    employeesTimesheetCompletion: (agencyId, employeeId, from, to) => `agencies/${agencyId}/employees/${employeeId}/timesheet-completion?from=${from}&to=${to}`,
    employeeRawCv: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/raw-cv`,
    employeeRawCvProgress: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/personal-information/raw-cv/progress`,
    projectEmployeesApprovedHolidays: (agencyId, clientId, projectId, from, to) => `agencies/${agencyId}/clients/${clientId}/projects/${projectId}/approved?from=${from}&to=${to}`,
    employeeOccupationStatus: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/occupation-status`,
    employeeRecruiter: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/recruiter`,
    termsAndConditionsVersion: (agencyId) => `agencies/${agencyId}/terms-and-conditions`,
    agencyName: (agencyId) => `agencies/${agencyId}/name`,
    agencyMainAdmin: (agencyId) => `agencies/${agencyId}/main-admin`,
    holidayInformationValidity: (agencyId, type, employeeIds, departmentId) => `agencies/${agencyId}/holidays/information-validity?type=${type}&employeeIds=${employeeIds}&departmentId=${departmentId}`,
    managedEmployeesReviewedHolidays: (agencyId, managerId, query) => `agencies/${agencyId}/managers/${managerId}/employees-holidays/reviewed${query || ''}`,
    managedEmployeesApprovedHolidays: (agencyId, from, to, managerId) => `agencies/${agencyId}/managers/${managerId}/employees-holidays/approved?from=${from}&to=${to}`,
    managedEmployeesSickLeaves: (agencyId, from, to, managerId) => `agencies/${agencyId}/managers/${managerId}/employees-holidays/sick-leaves?from=${from}&to=${to}`,
    managedEmployeesHolidaysRequests: (agencyId, type, managerId) => `agencies/${agencyId}/managers/${managerId}/holidays/actions?type=${type}`,
    managedEmployeesDocuments: (agencyId, managerId, query) => `agencies/${agencyId}/managers/${managerId}/documents${query}`,
    managedEmployeesDocumentsExport: (agencyId, managerId) => `agencies/${agencyId}/managers/${managerId}/documents/export`,
    managedEmployeesDocumentsFiles: (agencyId, managerId, query) => `agencies/${agencyId}/managers/${managerId}/documents/files${query ? query : ''}`,
    historicalNotes: (agencyId, targetId, noteType, limit, noteId, departmentId) => `agencies/${agencyId}/historical-notes${noteId ? `/${noteId}` : ''}?targetId=${targetId}&noteType=${noteType}&limit=${limit}&departmentId=${departmentId}`,
    exploreTalentsProfiles: (agencyId) => `agencies/${agencyId}/talents`,
    getEmployeeTeam: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/team`,
    mainMenuInformation: (agencyId, userId, role) => `agencies/${agencyId}/main-menu?userId=${userId}&role=${role}`,
    candidateJourney: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/candidates-journey`,
    candidateJourneyStartInformation: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/journey-start`,
    candidateJourneyImproveCvPage: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/improve-cv/page`,
    candidateViewedJob: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/viewed-job`,
    candidateProfilePage: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/profile/page`,
    candidateApplicationProcessInformation: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/application-process-information`,
    updateCandidateJourneyIsCompleted: (agencyId, candidateId) => `agencies/${agencyId}/candidates/${candidateId}/journey-completion`,
    supplierFlag: (agencyId, supplierId) => `agencies/${agencyId}/suppliers/${supplierId}/flag`,
    employeeCv: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/improve-cv`,
    clientInterviewDates: (agencyId, clientId, startDate, endDate) => `agencies/${agencyId}/clients/${clientId}/interview-dates?startDate=${startDate}&endDate=${endDate}`,
    clientJobsDashboard: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/jobs-dashboard`,
    clientNotifications: (agencyId, clientId) => `agencies/${agencyId}/clients/${clientId}/notifications`,
    clientAdmins: (agencyId, clientId, query) => `agencies/${agencyId}/clients/${clientId}/admins${query || ''}`,
    clientAdmin: (agencyId, clientId, clientAdminId) => `agencies/${agencyId}/clients/${clientId}/admins/${clientAdminId}`,
    jobOpportunityCandidateApplication: (agencyId, jobOpportunityId, candidateId, applicationId) => `agencies/${agencyId}/job-opportunities/${jobOpportunityId}/candidates/${candidateId}/applications/${applicationId}`,
    coachingOpportunities: (agencyId, displayMode) => `agencies/${agencyId}/coaching-opportunities${displayMode ? `?displayMode=${displayMode}` : ''}`,
    coachingOpportunity: (agencyId, coachingOpportunityId) => `agencies/${agencyId}/coaching-opportunities/${coachingOpportunityId}`,
    coachingOpportunitiesSessions: (agencyId, upcoming) => `agencies/${agencyId}/coaching-opportunities/sessions?${upcoming ? 'upcoming=true' : 'upcoming=false'}`,
    agencyCoachingOpportunityPage: (agencyId, coachingOpportunityId) => `agencies/${agencyId}/coaching-opportunities/${coachingOpportunityId}/page`,
    tagsSuggestions: (agencyId, inputText) => `agencies/${agencyId}/coaching-opportunities/search/tags?inputText=${inputText}`,
    employeeCoachingOpportunitySessions: (agencyId, employeeId, coachingOpportunityId, upcoming) => `agencies/${agencyId}/employees/${employeeId}/coaching-opportunities/${coachingOpportunityId}/sessions?${upcoming ? 'upcoming=true' : 'upcoming=false'}`,
    employeeCoachingSessions: (agencyId, employeeId, upcoming) => `agencies/${agencyId}/employees/${employeeId}/coaching-opportunities/sessions?${upcoming ? 'upcoming=true' : 'upcoming=false'}`,
    bookedSessionsExport: (agencyId, coachingOpportunityId) => `agencies/${agencyId}/coaching-opportunities/${coachingOpportunityId}/booked-sessions-export`,
    businessTrips: (agencyId, query) => `agencies/${agencyId}/business-trips${query ? query : ''}`,
    businessTripFiles: (agencyId, businessTripId) => `agencies/${agencyId}/business-trips/${businessTripId}/files`,
    businessTripsExport: (agencyId, businessTripsIds) => `agencies/${agencyId}/business-trips/export?businessTripsIds=${businessTripsIds}`,
    agencyDocuments: (agencyId, query) => `agencies/${agencyId}/documents${query}`,
    agencyDocumentsExport: (agencyId, query) => `agencies/${agencyId}/documents/export${query ? query : ''}`,
    agencyDocumentsFiles: (agencyId, query) => `agencies/${agencyId}/documents/files${query ? query : ''}`,
    reports: (agencyId, month) => `agencies/${agencyId}/reports?month=${month}`,
    kpiReport: (agencyId) => `agencies/${agencyId}/reports/kpi`,
    reportsExport: (agencyId, month) => `agencies/${agencyId}/reports/export?month=${month}`,
    reportsExportTableRow: (agencyId, tableRowId, month) => `agencies/${agencyId}/reports/export/${tableRowId}?month=${month}`,
    workspaceMigrationInfo: (agencyId, employeeId) => `agencies/${agencyId}/employees/${employeeId}/workspace-migration-info`,
    logging: (type) => `logging/${type}`,
    jobRecommendationRatings: (agencyId) => `agencies/${agencyId}/job-opportunities/recommendation-ratings`,
    jobRecommendationRating: (agencyId, recommendationId) => `agencies/${agencyId}/job-opportunities/recommendation-ratings/${recommendationId}`,
    agencySettings: (agencyId) => `agencies/${agencyId}/settings`,
    generateSentryError: (agencyId) => `agencies/${agencyId}/sentry-error`,
};

export default api;
