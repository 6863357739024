import React from 'react';

import { ReactComponent as NoteIcon } from 'assets/note-icon.svg';

import './Notes.scss';

const Notes = ({ note, agencyName, title }) => {
    return (
        <div className="agency-note-container">
            <div className="note-header">
                <NoteIcon />
                <h4>{title || `Note from ${agencyName}`}</h4>
            </div>
            <div className="note-body">
                <pre className="note-content">
                    {note}
                </pre>
            </div>
        </div>
    );
}

export default Notes;
