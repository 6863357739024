export const PERFORMANCE_CRITERIA_TABLE_COLUMNS = [
    { name: 'Title', value: 'title', isSortable: true },
];

export const DEFAULT_PERFORMANCE_CRITERIA_TABLE_COLUMNS = ['title'];

export const PERFORMANCE_CRITERIA_SORT_QUERY_SELECTOR = 'performanceCriteriaSort';
export const PERFORMANCE_CRITERIA_PAGE_QUERY_SELECTOR = 'performanceCriteriaTablePage';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const INITIAL_QUERY = {
    page: 1,
    sortBy: null,
    sortByDir: null,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    pagesToLoad: 5,
};

export const QUERY_PARAMS_MAP = {
    page: PERFORMANCE_CRITERIA_PAGE_QUERY_SELECTOR,
    sortBy: PERFORMANCE_CRITERIA_SORT_QUERY_SELECTOR,
    sortByDir: `${PERFORMANCE_CRITERIA_SORT_QUERY_SELECTOR}Dir`,
};
