import React from 'react';

import Paper from '@material-ui/core/Paper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import RateCandidateSvg from 'assets/rate-candidate.svg';

import './RateCandidateBanner.scss';

const RateCandidateBanner = ({
    handleRateCandidateClick,
}) => {

    return (
        <div className="rate-candidate-banner-wrapper">
            <Paper className="rate-candidate-container">
                <div className="rate-candidate-data">
                    <div className="text">
                        This profile has not been approved to the platform yet. Once you've rated them, their application(s) will be forwarded to the client.
                    </div>
                    <div className="rate-candidate-button-wrapper">
                            <PrimaryButton
                                text="Rate Candidate"
                                handleClick={event => handleRateCandidateClick(event)}
                            />
                        </div>
                </div>
                <img src={RateCandidateSvg} className="rate-candidate-img"/>
            </Paper>
        </div>
    )
};

export default RateCandidateBanner;
