import React, { useState, useRef } from 'react';

import { Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';

import RegisterCandidateFormik from '../RegisterCandidateFormik';

import { BASE_APP_TRADEMARK, ENVIRONMENTS } from 'constants/env';

import { useClickOutside } from 'utils/hooks';
import WelcomeCandidate from 'components/Shared/WelcomeCandidate';


const RegisterCandidateMobileComponent = ({
    initialValues,
    gotoUrl,
    jobOpportunityId,
    referrer,
    gclid,
    registerCandidate,
    login,
    history,
}) => {
    const [isFooterExpanded, setIsFooterExpanded] = useState(false);
    const [hasToggleBeenClicked, setHasToggleBeenClicked] = useState(false); // used for shrinking animation
    const footerRef = useRef(null);

    const toggleFooterClick = () => {
        setIsFooterExpanded(prevState => !prevState);
        setHasToggleBeenClicked(true);
    };

    const closeAndReset = () => {
        setIsFooterExpanded(false);
    };

    const isWhoIsAvailableEnv = process.env.REACT_APP_ENV === ENVIRONMENTS.WHOISAVAILABLE;

    useClickOutside(footerRef, closeAndReset);

    return (
        <div className="register-wrapper mobile">
            <div className="register-main-content-container grid-container rows gap-8">
                <a href={BASE_APP_TRADEMARK.LOGO_HREF}>
                    <CardMedia
                        className={`register-card-media${isFooterExpanded ? ' blur' : ''}`}
                        component="img"
                        image={BASE_APP_TRADEMARK.LOGO}
                        title={BASE_APP_TRADEMARK.TITLE}
                    />
                </a>
                <Typography variant='h3' className={`title${isFooterExpanded ? ' blur' : ''}`}>SIGN UP TO UNLOCK YOUR JOB MATCHES</Typography>
                <Typography variant='h5' className={`subtitle${isFooterExpanded ? ' blur' : ''}`}>It's free to join.</Typography>
                <RegisterCandidateFormik
                    blur={isFooterExpanded}
                    initialValues={initialValues}
                    gotoUrl={gotoUrl}
                    jobOpportunityId={jobOpportunityId}
                    referrer={referrer}
                    gclid={gclid}
                    registerCandidate={registerCandidate}
                    login={login}
                    history={history}
                />
            </div>
            {!isWhoIsAvailableEnv &&
                <div ref={footerRef} className={`footer${isFooterExpanded ? ' expand' : (hasToggleBeenClicked && !isFooterExpanded) ? ' shrink' : ''}`}>
                    {!isFooterExpanded && <p className="footer-title" onClick={toggleFooterClick}>LEARN MORE</p>}
                    <div className="candidate-footer-content">
                        <WelcomeCandidate />
                    </div>
                </div>
            }
        </div>
    );
};

export default RegisterCandidateMobileComponent;
