import React from 'react';
import moment from 'moment';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { ErrorMessage } from 'formik';

import isHoliday from 'hoc/isHoliday';
import SecondaryButton from '../Buttons/SecondaryButton';
import DialogActions from '@material-ui/core/DialogActions';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

const EditStartDateDialogForm = ({
    values,
    handleCloseDialog,
    handleSubmit,
    setFieldValue,
}) => {
    return (
        <form className="edit-start-date-form" autoComplete="off">
            <div>
                <KeyboardDatePicker
                    autoOk={true}
                    label="Start date"
                    name="startDate"
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    value={values.startDate}
                    onChange={value => {
                        setFieldValue('startDate', moment(value));
                    }}
                    renderDay={isHoliday}
                    fullWidth
                    format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                    allowKeyboardControl={true}
                />
                <ErrorMessage name="startDate" component="div" className="invalid-field-message" />
            </div>

            <DialogActions className="dialog-actions">
                <SecondaryButton buttonStyle="discard" handleClick={() => handleCloseDialog()} text="Discard" />
                <SecondaryButton buttonStyle="save" handleClick={handleSubmit} text="Submit" type="submit" />
            </DialogActions>
        </form>
    )
};

export default EditStartDateDialogForm;
