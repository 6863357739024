import React, { useEffect, createContext, useContext } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Shortcuts from '../Shortcuts';
import EmployeeProjects from '../EmployeeProjects';
import ImportantDocuments from '../ImportantDocuments';
import KnowYourTeam from '../KnowYourTeam';
import ActiveHolidaysDashboard from '../ActiveHolidaysDashboard';
import EvrotrustBanner from '../EvrotrustBanner';
import UpcomingInterviewBanner from '../UpcomingInterviewBanner';
import BusinessTripBanner from '../BusinessTripBanner';

import { fetchEmployeeDashboardInformation } from 'actions/employeeActions';
import { fetchAgencyBasicInfo, fetchSickLeaves } from 'actions/agencyActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DATE_FORMAT_WITH_DASH } from '../../../constants/commonConstants';

import { getDashboardDataFetchState, getDisplayMode } from 'reducers';

export const EmployeeDashboardContext = createContext();

const { Provider } = EmployeeDashboardContext;

const EmployeeDashboard = (
    {
        userId,
        agencyId,
        fetchEmployeeDashboardInformation,
        fetchSickLeaves,
        fetchAgencyBasicInfo,
        children,
        isDataAvailable,
        displayMode,
        dataFetchState,
    }) => {
    const context = { userId, agencyId, displayMode };
       
    useEffect(() => {
        if (!isDataAvailable) {
        fetchSickLeaves(agencyId, moment().format(DATE_FORMAT_WITH_DASH), moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH));
        fetchEmployeeDashboardInformation(agencyId, userId, moment().format(DATE_FORMAT_WITH_DASH), moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH));
        fetchAgencyBasicInfo(agencyId);
   } 
}, []);

    return (
        <div>
            {dataFetchState?.isDataFetching && !dataFetchState?.isDataFetched
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <Provider value={context} >
                    <div className="employee-dashboard-wrapper">
                        {children}
                    </div>
                </Provider >
            }
        </div>
    )
};

const EmployeeProjectsWrapper = () => {
    const { userId, agencyId, displayMode } = useContext(EmployeeDashboardContext);

    return <EmployeeProjects userId={userId} agencyId={agencyId} isDataAvailable={true} displayMode={displayMode} />
}

const ImportantDocumentsWrapper = () => {
    const { userId, agencyId } = useContext(EmployeeDashboardContext);

    return <ImportantDocuments userId={userId} agencyId={agencyId} isDataAvailable={true} />
}
const KnowYourTeamWrapper = () => {
    const { userId, agencyId } = useContext(EmployeeDashboardContext);

    return <KnowYourTeam userId={userId} agencyId={agencyId} />
}

const ActiveHolidays = () => {
    const { dataFetchState } = useContext(EmployeeDashboardContext);

    return <ActiveHolidaysDashboard dataFetchState={dataFetchState} />
}

const EvrotrustBannerWrapper = () => {
    return <EvrotrustBanner />
}

const UpcomingInterviewBannerWrapper = () => {
    return <UpcomingInterviewBanner />
}

const BusinessTripBannerWrapper = () => {
    return <BusinessTripBanner />;
};

EmployeeDashboard.Shortcuts = Shortcuts;
EmployeeDashboard.EmployeeProjects = EmployeeProjectsWrapper;
EmployeeDashboard.ImportantDocuments = ImportantDocumentsWrapper;
EmployeeDashboard.KnowYourTeam = KnowYourTeamWrapper;
EmployeeDashboard.ActiveHolidays = ActiveHolidays;
EmployeeDashboard.EvrotrustBanner = EvrotrustBannerWrapper;
EmployeeDashboard.UpcomingInterviewBanner = UpcomingInterviewBannerWrapper;
EmployeeDashboard.BusinessTripBanner = BusinessTripBannerWrapper;

const mapDispatchToProps = {
    fetchEmployeeDashboardInformation,
    fetchAgencyBasicInfo,
    fetchSickLeaves,
};

const mapStateToProps = state => ({
    dataFetchState: getDashboardDataFetchState(state),
    displayMode : getDisplayMode(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard);
