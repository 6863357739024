import React from 'react';
import moment from 'moment';

import { ErrorMessage } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';

import AddEmployeeToProjectAutoComplete from '../AddEmployeeProjectsCardAutoComplete';
import AddEmployeeBillingType from './AddEmployeeBillingType';
import EmployeeFields from './EmployeeFields';
import DedicatedEmployeeFields from './DedicatedEmployeeFields';

import { BILLING_TYPE_STATIC_VALUES, BILLING_TYPES, DIALOG_TYPES } from 'constants/clientProjectConstants';
import { DATE_PICKER_FORMAT } from 'constants/timesFormat';

import './AddEmployeeProjectsCardFormView.scss';

const AddEmployeeProjectsCardFormView = ({
	values,
	handleChange,
	errors,
	touched,
	employees,
	isEdit,
	projectCurrency,
	isClientInvoicingCompleted,
	setFieldValue,
	type,
}) => (
	<form className="add-project-employee-form" autoComplete="off">
		{type === DIALOG_TYPES.BILLING_TYPE &&
			<AddEmployeeBillingType values={values} handleChange={handleChange} errors={errors} touched={touched} />
		}
		{(type !== DIALOG_TYPES.BILLING_TYPE || Object.values(BILLING_TYPES).includes(values.billingType)) &&
			<div className="add-project-employee-field autocomplete">
				<AddEmployeeToProjectAutoComplete
					errors={errors}
					isEdit={isEdit}
					employeeValue={values.employee}
					touched={touched}
					employees={employees}
					handleChange={handleChange}
					inputLabel="Person"
				/>
				<ErrorMessage name="employee" component="div" className="invalid-field-message" />
			</div>
		}

		{(type === DIALOG_TYPES.CONTRACT || values.billingType === BILLING_TYPE_STATIC_VALUES[0].LABEL) &&
			<EmployeeFields values={values} handleChange={handleChange} errors={errors} touched={touched} isClientInvoicingCompleted={isClientInvoicingCompleted} projectCurrency={projectCurrency} />
		}

		{(type === DIALOG_TYPES.FULL_TIME || values.billingType === BILLING_TYPE_STATIC_VALUES[1].LABEL) &&
			<DedicatedEmployeeFields values={values} handleChange={handleChange} errors={errors} touched={touched} isClientInvoicingCompleted={isClientInvoicingCompleted}/>
		}

		<div>
			<KeyboardDatePicker
				className="add-member-field"
				autoOk
				required
				label="Start Date"
				name="dateAdded"
				variant="inline"
				disableToolbar
				format={DATE_PICKER_FORMAT}
				openTo="date"
				inputVariant="outlined"
				value={values.dateAdded}
				onChange={value => {
					setFieldValue('dateAdded', moment(value));
				}}
				fullWidth
			/>
			<ErrorMessage name="dateAdded" component="div" className="invalid-field-message" />
		</div>
	</form>
);

export default AddEmployeeProjectsCardFormView;
