import React from 'react';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { ErrorMessage } from 'formik';

//TODO Refator - the place of reducers/actions is not here ! - move them in the dialog component issue #1012
import { SUPPLIER_STATUSES } from 'constants/supplierConstants'
import './AddSupplierFormView.scss';

const AddSupplierFormView = ({
    values,
    handleChange,
    errors,
    touched,
    accountManagers,
    handleBlur,
    countries,
}) => {
    //TODO extract to constants after actions/reducers are extracted issue #1012
    return (<form className="add-supplier-form" autoComplete="off">
        <div className="supplier-name-field">
            <TextField
                required
                name="name"
                value={values.name}
                onChange={handleChange}
                label="Name"
                error={errors.name && touched.name}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="name" component="div" className="invalid-field-message" />
        </div>
        <div className="email-field">
            <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                label="Email"
                error={errors.email && touched.email}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="email" component="div" className="invalid-field-message" />
        </div>
        <div className="supplier-details-block">
            <div className="country-field">
                <TextField
                    select
                    name="country"
                    value={values.country}
                    onChange={handleChange}
                    label="Country"
                    error={errors.country && touched.country}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {countries.map((country, i) => (
                        <MenuItem native="true" key={i} value={country.name}>
                            {country.name}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="country" component="div" className="invalid-field-message" />
            </div>
            <div className="supplier-status-field">
                <TextField
                    select
                    required
                    name="invoiceStatus"
                    value={values.status}
                    onChange={handleChange}
                    label="Partner Status"
                    error={errors.status && touched.status}
                    onBlur={handleBlur}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {SUPPLIER_STATUSES.map((status, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={status}
                        >
                            {status}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="invoiceStatus" component="div" className="invalid-field-message" />
            </div>
        </div>
        <div className="contact-person-field">
            <TextField
                required
                name="contactPerson"
                value={values.contactPerson}
                onChange={handleChange}
                label="Contact Person"
                error={errors.contactPerson && touched.contactPerson}
                margin="normal"
                variant="outlined"
                fullWidth
            />
            <ErrorMessage name="contactPerson" component="div" className="invalid-field-message" />
        </div>
        <div className="supplier-final-block">
            <div className="contact-number-field">
                <TextField
                    name="contactNumber"
                    value={values.contactNumber}
                    onChange={handleChange}
                    label="Contact Phone"
                    error={errors.contactNumber && touched.contactNumber}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
                <ErrorMessage name="contactNumber" component="div" className="invalid-field-message" />
            </div>
            <div className="account-manager-field">
                <TextField
                    select
                    required
                    name="accountManager"
                    value={values.accountManager}
                    onChange={handleChange}
                    label="Account Manager"
                    error={errors.accountManager && touched.accountManager}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                >
                    {accountManagers.map((accountManager, i) => (
                        <MenuItem
                            native="true"
                            key={i}
                            value={accountManager._id}
                        >
                            {`${accountManager.name}`}
                        </MenuItem>
                    ))}
                </TextField>
                <ErrorMessage name="accountManager" component="div" className="invalid-field-message" />
            </div>
        </div>
    </form>)
};


export default AddSupplierFormView;
