import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import SearchMenuView from '../SearchMenuView';

import { DropdownMenuContext } from '../SearchDropdown';
import { ROLES } from 'constants/userConstants';

const ApplicantsMenu = ({ compact }) => {
    const { searchResults, handleClick, handleBlur, displayMode } = useContext(DropdownMenuContext);

    const mapper = {
        [ROLES.EMPLOYEE]: (_) => [],
        [ROLES.SUPPLIER_ADMIN]: (_) => [],
        [ROLES.CLIENT]: (searchResults) => searchResults.filter(x => x.isUserResult),
        [ROLES.ADMIN]: (_) => [],
        [ROLES.RECRUITER]: (_) => [],
    };

    const results = compact ? mapper[displayMode](searchResults).slice(0, 3) : mapper[displayMode](searchResults);

    const mappedResult = results.reduce((acc, result) => {
        acc.push({
            ...result,
            name: `${result.candidate.firstName} ${result.candidate.lastName}` || result.candidate.name,
            logoImgUrl: result.candidate.profilePictureImgUrl,
        });
        return acc;
    }, []);

    return mappedResult.length > 0 ? (
        <div className="search-menu">
            <Typography variant="h5">Your Applicants</Typography>
            <SearchMenuView
                results={mappedResult}
                handleClick={handleClick}
                handleBlur={handleBlur}
            />
        </div>
    ) : null
};

export default ApplicantsMenu;
