import React from 'react';

import JobsDashboard from 'components/JobsDashboard/JobsDashboard';

import { ROLES } from 'constants/userConstants';
import { getStateAgencyName } from 'utils/helpers';

const Jobs = ({
    clientId,
}) => (
    <JobsDashboard>
        <JobsDashboard.JobsDashboardAdminView>
            <div className="client-jobs-dashboard-content-wrapper">
                <div className="client-jobs-dashboard-header">
                    <JobsDashboard.JobsDashboardJobTypeBoxes displayMode={ROLES.ADMIN} />
                </div>
                <JobsDashboard.JobsDashboardJobsList
                    getPathToJobDetails={(jobId) => `/${getStateAgencyName()}/clients/${clientId}/jobs/${jobId}`}
                />
            </div>
        </JobsDashboard.JobsDashboardAdminView>
    </JobsDashboard>
);

export default Jobs;
