import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { getHomePath } from 'utils/navigationUtils';
import { setReferrer, setGCLID } from 'utils/queryUtils';

const RedirectToHome = () => {
    const history = useHistory();

    setReferrer(history.location.search);
    setGCLID(history.location?.search);

    const homePath = getHomePath();
    return (
        <Redirect to={homePath} />
    );
};

export default RedirectToHome;
