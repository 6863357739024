import React from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/jobs-delete-icon.svg';

import './AppliedCandidateOptionsPopover.scss';

const AppliedCandidateOptionsPopover = ({
    handleClose,
    anchorEl,
    openDeleteDialog,
}) => {

    return (
        <div className="popover-container">
            <Popover
                className="applied-candidates-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popover-list">
                    <ListItem button className="popover-list-item" onClick={openDeleteDialog}>
                        <Typography className="list-item-text" />
                        <span className="job-popover-icon"><DeleteIcon /></span>
                        <span className="job-popover-text">Delete</span>
                        <Typography />
                    </ListItem>
                </List>
            </Popover>
        </div>
    );
}

export default AppliedCandidateOptionsPopover;
