import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import TableWrapper from 'components/Shared/TableWrapper';
import KPIList from 'components/Shared/KPIList';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

import {
    getEmploymentHolidayAllowance,
    getBookedHolidaysCount,
    getBookedUnpaidHolidaysCount,
} from 'reducers';

import './HolidaysRequests.scss';

const REMAINING_DAYS_MIN_AMOUNT = 5;

const HolidaysRequests = ({
    totalHolidays,
    holidaysHistoryCount,
    bookedUnpaidHolidaysCount,
    dataFetchState,
    handleOpenDialog
}) => {
    const NOT_AVAILABLE = 'N/A';
    const REMAINING = 'remaining';
    const currentYear = moment().format('YYYY');

    const remaining = totalHolidays - holidaysHistoryCount || NOT_AVAILABLE;

    const holidaysKPIsData = [
        {
            title: `Accrued Paid Holiday ${currentYear}`,
            amount: totalHolidays || totalHolidays === 0 ? totalHolidays : NOT_AVAILABLE,
            additionalClasses: ['normal'],
        },
        {
            title: `Booked Holiday ${currentYear}`,
            amount: holidaysHistoryCount,
            additionalClasses: ['booked'],
        },
        {
            title: `Remaining Holiday ${currentYear}`,
            amount: remaining,
            additionalClasses: [remaining <= REMAINING_DAYS_MIN_AMOUNT ? REMAINING : ''],
        },
        {
            title: `Used Unpaid Holiday ${currentYear}`,
            amount: bookedUnpaidHolidaysCount,
            additionalClasses: ['unpaid']
        },
    ];

    return (
        <div className="holiday-requests-wrapper">
            <KPIList
                data={holidaysKPIsData}
                dataFetchState={dataFetchState}
            />

            <TableWrapper>
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.TableActionButton
                        text='Request Holiday'
                        icon={<AddIcon />}
                        handleClick={handleOpenDialog}
                    />
                </TableWrapper.TableAlignedItems>
            </TableWrapper>
        </div>
    )
};

const mapStateToProps = state => ({
    totalHolidays: getEmploymentHolidayAllowance(state),
    holidaysHistoryCount: getBookedHolidaysCount(state),
    bookedUnpaidHolidaysCount: getBookedUnpaidHolidaysCount(state),
});

export default connect(mapStateToProps, null)(HolidaysRequests);
