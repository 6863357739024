import React from 'react';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import DescriptionIcon from '@material-ui/icons/Description';
import { ReactComponent as SuitcaseSolid } from 'assets/suitcase-solid.svg';
import { ReactComponent as CustomerSupportIcon } from 'assets/icon-customer-support.svg';

export const getCandidateMenuItems = agencyRouteName => [
    {
        TITLE: 'Job Opportunities',
        ICON: <SuitcaseSolid />,
        ROUTE: `/${agencyRouteName}/job-opportunities`,
    },
    {
        TITLE: 'My Applications',
        ICON: <DescriptionIcon />,
        ROUTE: `/${agencyRouteName}/my-applications`,
    },
    {
        TITLE: 'Customer Support',
        ICON: <CustomerSupportIcon />,
        ROUTE: `/${agencyRouteName}/customer-support`,
    },
    {
        TITLE: 'My Profile',
        ICON: <PersonSharpIcon />,
        ROUTE: `/${agencyRouteName}/profile`,
    },
];
