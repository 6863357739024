import React from 'react';
import { connect } from 'react-redux';

import { getStaticLanguages } from 'reducers';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

import { languageLevels } from './ProfileLanguageRowConstants';

import './ProfileLanguageRow.scss';

const ProfileLanguageRow = ({
    language,
    handleChange,
    removeLanguage,
    staticLanguages,
}) => {

    return (
    <div className="language-row">
        <FormControl className="language-select" variant="outlined" fullWidth>
            <InputLabel id="language-name-label">Name</InputLabel>
            <Select
                labelId="language-name-label"
                id="language-name"
                name="name"
                value={language.name}
                onChange={(e) => handleChange(e, language)}
                label="Name"
            >
                {staticLanguages.map((lang, i) => (
                    <MenuItem key={i} value={lang}>
                        {lang}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        <div className="language-level-container language-name">
            <FormControl className="language-select" variant="outlined" fullWidth >
                <InputLabel id="language-level-label">Level</InputLabel>
                <Select
                    labelId="language-level-label"
                    name="level"
                    value={language.level}
                    onChange={e => handleChange(e, language)}
                    id="language-level"
                    label="Level"
                >
                    {languageLevels.map((lang, i) => (
                        <MenuItem key={i} value={lang}>
                            {lang}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <CancelSharpIcon className="language-delete-icon" onClick={() => removeLanguage(language)} />
        </div>
    </div>
) 
}

const MapStateToProps = state => ({
    staticLanguages: getStaticLanguages(state),
});

export default connect(MapStateToProps)(ProfileLanguageRow);
