import styleConstants from 'style-constants.scss';

export const TABLE_COLUMNS = [
  { name: "Name", value: "name", isSortable: true },
  { name: "Skills", value: "technologies", isSortable: true },
  { name: "Experience", value: "experience", isSortable: true },
  { name: "Status", value: "status", isSortable: true },
];

export const DEFAULT_COLUMNS = ["name", "technologies", "experience", "status"];

export const SORT_QUERY_SELECTOR = "sort";
export const SEARCH_QUERY_SELECTOR = "search";
export const CATEGORY_QUERY_SELECTOR = "tab";

export const PROFILES_CATEGORY_FILTER = [
  { name: "Employees", value: "employee" },
  { name: "Candidates", value: "candidate" },
  { name: "Partners", value: "supplier-employee" },
];

export const PROFILES_CATEGORY_DEFAULT_FILTER = PROFILES_CATEGORY_FILTER[0];

export const TABLE_INITIAL_QUERY = {
  filter: PROFILES_CATEGORY_DEFAULT_FILTER.value,
  search: "",
  sortBy: null,
  sortByDir: null
};

export const TABLE_QUERY_MAP = {
  filter: CATEGORY_QUERY_SELECTOR,
  search: SEARCH_QUERY_SELECTOR,
  sortBy: SORT_QUERY_SELECTOR,
  sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};

export const TABS = {
  EMPLOYEES: 'employee',
  CANDIDATES: 'candidate',
  PARTNER: 'supplier-employee',
};

export const LEGEND_ITEMS = {
  [TABS.EMPLOYEES]: [
    { color: styleConstants.greenLight, text: "Full-Time" },
    { color: styleConstants.yellowLight, text: "Freelance" },
  ],
  [TABS.CANDIDATES]: [
    { color: styleConstants.yellowLight, text: "Registered via link" },
    { color: styleConstants.greenLight, text: "Invited by the admin" },
    { color: styleConstants.blueLight, text: "LinkedIn" },
    { color: styleConstants.purple, text: "Google" },
    { color: styleConstants.greyDark, text: "Other" },
  ],
};
