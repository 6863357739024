import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';

import { getDisplayMode } from 'reducers';

import SubmitFeedbackDialogFormView from './SubmitFeedbackDialogFormView';
import CommonFormikDialog from '../CommonFormikDialog';

import { validateRequiredFields } from 'utils/formValidations';

import './SubmitFeedbackDialog.scss';

const SubmitFeedbackDialog = ({
    openDialog,
    handleCloseDialog,
    feedbackCriteria,
    handleSubmit,
    displayMode,
    chooseProject,
    projects,
}) => {
    return (
        <Formik
            enableReinitialize
            initialValues={chooseProject ? {
                details: '',
                project: '',
                ...feedbackCriteria.reduce((acc, criteria) => {
                    acc[criteria] = 0;

                    return acc;
                }, {})
            } : {
                details: '',
                ...feedbackCriteria.reduce((acc, criteria) => {
                    acc[criteria] = 0;

                    return acc;
                }, {})
            }}
            validate={(values) => {
                return {
                    ...validateRequiredFields(values, feedbackCriteria),
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                handleSubmit({ ...values });
                handleCloseDialog();
            }}
        >
            {(props) => (
                <CommonFormikDialog
                    className="submit-feedback-dialog"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth={'sm'}
                    fullWidth={true}
                    dialogTitle='Submit feedback'
                    onSave={props.handleSubmit}
                >
                    <SubmitFeedbackDialogFormView
                        {...props}
                        feedbackCriteria={feedbackCriteria}
                        displayMode={displayMode}
                        chooseProject={chooseProject}
                        projects={projects}
                    />
                </CommonFormikDialog>
            )}
        </Formik>
    );
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
});

export default connect(mapStateToProps, null)(SubmitFeedbackDialog);
