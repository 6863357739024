import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PerformanceBar from '../PerformanceBar';

import './PerformanceReviewOverall.scss'

const performancePeriods = [
    { value: 1, name: 'Current month' },
    { value: 2, name: 'Annual report' },
    { value: 3, name: 'Quater 1' },
    { value: 4, name: 'Quater 2' },
    { value: 5, name: 'Quater 3' },
    { value: 6, name: 'Quater 4' },
];

const PerformanceReviewOverall = ({
    overallPerformance,
}) => {
    const [period, setPeriod] = useState(performancePeriods[0].value);

    const handlePeriodChange = (event) => setPeriod(event.target.value);
    const handleOpenDialog = () => { window.history.back() };

    return (
        <div className="performance-review-wrapper performance-overall">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='View feedback'
                    className='section-title'
                />

                <SectionActionsWrapper.LeftAlignedActions>
                    <SectionActionsWrapper.LeftAlignedActions.SelectOneFilter
                        value={period}
                        onChangeHandler={handlePeriodChange}
                        items={performancePeriods}
                    />
                </SectionActionsWrapper.LeftAlignedActions>
                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryButton
                        text='Back To Project'
                        onClick={handleOpenDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>

            </SectionActionsWrapper>

            {(overallPerformance.categoriesPerformance) &&
                <Paper className="performance-content-wrapper">
                    <div className="overall-performance">
                        <Typography variant="h5" className="overall-performance-title">Overall Performance</Typography>
                        <Typography variant="h4" className="overall-performance-score">{overallPerformance.overallPerformance}</Typography>
                        <div className="performance-bar-container large" >
                            <PerformanceBar score={overallPerformance.overallPerformance} />
                        </div>
                    </div>

                    <div className="performance-criteria">
                        {overallPerformance.categoriesPerformance.map((performance, i) => (
                            <div key={i} className="performance-criterion">
                                <Typography component="h6" className="performance-criterion-title">{performance.criteria}</Typography>
                                <div className="performance-bar-container small" >
                                    <PerformanceBar score={performance.score} />
                                </div>
                            </div>
                        ))}
                    </div>
                </Paper>
            }
        </ div>
    )
}

export default PerformanceReviewOverall;
