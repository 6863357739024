import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateDateFields,
    validateRequiredFields,
    validateInputForPositiveIntegerType,
} from 'utils/formValidations';

import { JOB_TYPES } from 'constants/candidateConstants';

const CandidateProfileInfoFormik = ({
    profileInfo,
    updateProfileInfo,
    requiredField,
    positiveFields,
    children
}) => {

    return (
        <Formik
            enableReinitialize
            initialValues={{
                salaryValue: profileInfo?.salaryValue || '',
                salaryCurrency: profileInfo?.salaryCurrency || '',
                countryOfTax: profileInfo?.countryOfTax || '',
                earliestDate: profileInfo?.earliestDate ? moment.utc(profileInfo.earliestDate) : null,
                experienceSince: profileInfo?.experienceSince || '',
                jobType: profileInfo?.jobType || '',
                assignee: profileInfo?.assignee || '',
                technologies: profileInfo?.technologies || [],
            }}
            validate={(values) => {
                const requestedFieldsValidation = requiredField ? validateRequiredFields(values, requiredField) : {};
                return profileInfo.earliestDate != null
                    ? {
                        ...requestedFieldsValidation,

                        ...validateDateFields(values, ['earliestDate']),

                        ...validateInputForPositiveIntegerType(values, positiveFields)
                    }
                    : {
                        ...requestedFieldsValidation,

                        ...validateInputForPositiveIntegerType(values, positiveFields)
                    }
                ;
            }}
            onSubmit={async (values, { resetForm }) => {
                const preferencesData = values.jobType === JOB_TYPES.DEVELOPER
                    ? { ...values, earliestDate: addUtcOffset(values.earliestDate), isWithTechStack: profileInfo?.technologies?.length > 0 }
                    : { ...values, earliestDate: addUtcOffset(values.earliestDate) }

                await updateProfileInfo(preferencesData, resetForm);
            }}
        >
            {children}
        </Formik>
    );
};

export default CandidateProfileInfoFormik;
