import React from 'react';

import MomentUtils from '@date-io/moment';

import { Formik } from 'formik';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Dialog from 'components/Shared/Dialog';
import BusinessTripFormView from './BusinessTripFormView';

import {
    validateFilesArrayLength,
    validateRequiredFields,
    validateStartDateNotBeforeEndDate,
    validateEndDateNotBeforeStartDate
} from 'utils/formValidations';
import { BUSINESS_TRIP_CATEGORIES } from '../BusinessTripBannerConstants';

import './BusinessTripDialog.scss';

const BusinessTripDialog = ({
    agencyId,
    userId,
    openDialog,
    handleCloseDialog,
    handleCreate,
    countries,
    initialValues,
}) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
            initialValues={initialValues || {
                startDate: null,
                endDate: null,
                country: '',
                category: '',
                boardingCardFiles: [],
                photoFiles: [],
            }}

            validate={values => {
                return {
                    ...validateRequiredFields(values, ['startDate', 'endDate', 'country', 'category']),
                    ...validateStartDateNotBeforeEndDate(values, 'startDate', 'endDate', false),
                    ...validateEndDateNotBeforeStartDate(values, 'startDate', 'endDate'),
                    ...validateFilesArrayLength(values, 'boardingCardFiles', 1),
                    ...validateFilesArrayLength(values, 'photoFiles', 1),
                }
            }}
            onSubmit={(values) => {
                const categoryId = BUSINESS_TRIP_CATEGORIES.find(x => x.value === values.category)._id;

                const formData = new FormData();

                let i = 1;

                values.boardingCardFiles.forEach((file) => {
                    formData.append(`attatchfile${i}`, file);
                    i++;
                });

                values.photoFiles.forEach((file) => {
                    formData.append(`attatchfile${i}`, file);
                    i++;
                });

                const businessTripData = {
                    agencyId,
                    userId,
                    category: categoryId,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    country: values.country,
                    filesCount: i - 1,
                };

                formData.append('businessTripData', JSON.stringify(businessTripData));

                handleCreate(agencyId, formData);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="add-report-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'} onDragEnter={() => void 0}>
                    <MuiDialogTitle disableTypography className="dialog-title">
                        <Typography variant="body2" className="dialog-title-text">Submit Report</Typography>
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <DialogContent className="dialog-content">
                        <BusinessTripFormView {...props} countries={countries} handleCloseDialog={handleCloseDialog} />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    </MuiPickersUtilsProvider>
);

export default BusinessTripDialog;

