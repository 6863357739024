import { getStateAgencyName } from './helpers';
import { ROLES } from 'constants/userConstants';

export const getResultClickRoute = (displayMode, result) => {
    if (displayMode === ROLES.SUPPLIER_ADMIN) {
        if (result.role === ROLES.EMPLOYEE || result.role === ROLES.ADMIN
            || result.role === ROLES.SUPPLIER_ADMIN || result.role === ROLES.SUPPLIER_EMPLOYEE) {
            return `/${getStateAgencyName()}/explore-talents/${result._id}`;
        }
    }
    if (displayMode === ROLES.RECRUITER) {
        if (result.role === ROLES.RECRUITER_EMPLOYEE) {
            return `/${getStateAgencyName()}/team/people/${result._id}/personal-information`;
        }
    }
    if (displayMode === ROLES.ADMIN) {
        if (result.role === ROLES.EMPLOYEE || result.role === ROLES.ADMIN) {
            return `/${getStateAgencyName()}/team/people/${result._id}`;
        }
        if (result.role === ROLES.CLIENT) {
            return `/${getStateAgencyName()}/clients/${result._id}`;
        }
        if (result.role === ROLES.SUPPLIER_EMPLOYEE) {
            return `/${getStateAgencyName()}/partners/${result.supplierId}/team/people/${result._id}`;
        }
        if (result.role === ROLES.SUPPLIER_ADMIN) {
            return `/${getStateAgencyName()}/partners/${result._id}/team/people`;
        }
        if (result.role === ROLES.CANDIDATE) {
            return `/${getStateAgencyName()}/candidates/${result._id}`;
        }
        if (result.isProject || result.isProjectUsersResult) {
            return `/${getStateAgencyName()}/projects/${result._id}/clients/${result.clientId}`;
        }
    }
    if (displayMode === ROLES.CLIENT) {
        if (result.isProject || result.isProjectUsersResult) {
            return `/${getStateAgencyName()}/projects/${result._id}/clients/${result.clientId}`;
        } else if (result.isUserResult) {
            return `/${getStateAgencyName()}/jobs/${result.jobOpportunityId}/candidates/${result.candidate._id}/applications/${result._id}`;
        }
    }
};
