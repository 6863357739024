import {
    APPLICATION_LOADER_SHOW_SUCCESS,
    APPLICATION_LOADER_HIDE_SUCCESS
} from 'actions/actionTypes';

const initialState = { isActive: false };

const applicationLoader = (state = initialState, action) => {
    switch (action.type) {
      case APPLICATION_LOADER_SHOW_SUCCESS:
        return {...state, isActive: action.payload};
      case APPLICATION_LOADER_HIDE_SUCCESS:
        return {...state, isActive: action.payload};
      default:
        return state;
    }
};

export default applicationLoader;

export const isAppLoaderActive = state => state.isActive;
