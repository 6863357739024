import React from 'react';
import Holiday from './Holiday'

const isHoliday = (
    day, 
    selectedDate, 
    isInCurrentMonth, 
    dayComponent,
) => {
    
    return <Holiday 
        day={day}
        isInCurrentMonth={isInCurrentMonth}
        dayComponent={dayComponent}/>;
}

export default isHoliday;
