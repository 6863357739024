import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowLeftSharpIcon from '@material-ui/icons/ArrowLeftSharp';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import {
    getAgencyHolidaysCount,
    getToggleNavigation,
    getNewApplicationsCount,
    getOwnEmployeesPendingHolidayRequestsCount
} from 'reducers';

import * as componentConstants from './ExpandableListConstants';

import './ExpandableList.scss';

const useStyles = makeStyles((theme) => ({
    displayNone: {
        display: 'none',
    }
}));

const ExpandableList = ({
    isOpen,
    listItemIcon,
    listItemSvg,
    listItemTitle,
    listItemsArray,
    handleExpandList,
    pendingHolidaysCount,
    managersPendingHolidaysRequests,
    isSelected,
    newApplicationsCount,
    toggleNavigationValue,
    toggleMobileNavigation
}) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Tooltip
                title={listItemTitle}
                placement="right"
                classes={!toggleNavigationValue ? { tooltip: classes.displayNone } : { tooltip: 'custom-tooltip less-padding' }}
            >
                <ListItem button
                    className={`list-item expandable${isOpen ? ' expanded' : ''}`}
                    selected={isSelected}
                    onClick={() => handleExpandList(listItemTitle)}
                >
                    {listItemIcon &&
                        <div className="list-item-icon-wrapper">
                            <ListItemIcon className="list-item-icon">
                                {listItemIcon}
                            </ListItemIcon>
                        </div>
                    }
                    <ListItemText primary={listItemTitle} />
                    {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowLeftSharpIcon />}
                </ListItem>
            </Tooltip>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="nested-list">
                    {listItemsArray.filter(item => !item.ENDPOINT.includes('temporary')).map(item => (

                        <Tooltip
                            title={item.TEXT}
                            key={item.TEXT}
                            placement="right"
                            classes={!toggleNavigationValue ? { tooltip: classes.displayNone } : { tooltip: 'custom-tooltip less-padding' }}
                        >
                            <ListItem
                                button
                                component={NavLink}
                                to={item.ENDPOINT}
                                className="nested-list-item"
                                id={item.TEXT}
                                activeClassName="Mui-selected"
                                onClick={() => toggleMobileNavigation(false)}
                            >
                                <div className="list-item-icon-wrapper">
                                    <ListItemIcon className="list-item-icon">
                                        {item.ICON
                                            ? item.ICON
                                            : null
                                        }

                                        {item.TEXT === componentConstants.NAVIGATION_SUBITEM_HOLIDAYS
                                            && item.ENDPOINT !== componentConstants.NAVIGATION_SUBITEM_DEPARTMENT_HOLIDAYS_ENDPOINT
                                            && pendingHolidaysCount > 0
                                            ? <div className="notification-badge"><span className="badge-text">{pendingHolidaysCount}</span></div>
                                            : null
                                        }

                                        {item.ENDPOINT === componentConstants.NAVIGATION_SUBITEM_DEPARTMENT_HOLIDAYS_ENDPOINT
                                            && managersPendingHolidaysRequests > 0
                                            ? <div className="notification-badge"><span className="badge-text">{managersPendingHolidaysRequests}</span></div>
                                            : null
                                        }

                                        {item.TEXT === componentConstants.NAVIGATION_SUBMENU_APPLICATIONS && newApplicationsCount > 0
                                            ? <div className="notification-badge"><span className="badge-text">{newApplicationsCount}</span></div>
                                            : null
                                        }
                                    </ListItemIcon>
                                </div>

                                <ListItemText primary={item.TEXT} />
                                {item.TEXT === componentConstants.NAVIGATION_SUBITEM_HOLIDAYS && pendingHolidaysCount > 0
                                    ? <div className="notification-badge-mobile"><span className="badge-text">{pendingHolidaysCount}</span></div>
                                    : null
                                }
                                {item.TEXT === componentConstants.NAVIGATION_SUBMENU_APPLICATIONS && newApplicationsCount > 0
                                    ? <div className="notification-badge-mobile"><span className="badge-text">{newApplicationsCount}</span></div>
                                    : null
                                }
                            </ListItem>
                        </Tooltip>
                    ))}
                </List>
            </Collapse>
        </Fragment>
    );
}

const mapStateToProps = state => ({
    pendingHolidaysCount: getAgencyHolidaysCount(state),
    managersPendingHolidaysRequests: getOwnEmployeesPendingHolidayRequestsCount(state),
    toggleNavigationValue: getToggleNavigation(state),
    newApplicationsCount: getNewApplicationsCount(state),
});

export default connect(mapStateToProps, null)(ExpandableList);
