export const getOverviewBannersItems = employeeOverviewInformation => {
    const {
        contractsCount,
        historicalNotesCount,
        hasCVInformation,
        missingHoursInMonths
    } = employeeOverviewInformation;
    const bannerItems = {};

    const hasContracts = contractsCount > 0;
    const hasHistoricalNotes = historicalNotesCount > 0;
    const hasMissingHours = missingHoursInMonths.length > 0;

    bannerItems.contracts = {
        sectionTitle: 'Contracts',
        bannerText: hasContracts
            ? `Employee has ${contractsCount > 1 ? `${contractsCount} contracts` : `${contractsCount} contract`}.`
            : 'No contracts added yet.',
        buttonText: hasContracts ? 'See Contracts' : 'Add Contract',
    };

    bannerItems.cv = {
        sectionTitle: 'CV',
        bannerText: hasCVInformation
            ? `Employee has a CV.`
            : "Employee didn't upload a CV.",
        buttonText: hasCVInformation ? 'Export CV' : 'Upload CV',
    }

    bannerItems.trackTime = {
        sectionTitle: 'Track Time',
        bannerText: hasMissingHours
            ? `Employee has missing hours in ${missingHoursInMonths.join(', ')}.`
            : `Employee has no missing hours.`,
        buttonText: hasMissingHours ? 'Track Time' : 'See More'
    }

    bannerItems.historicalNotes = {
        sectionTitle: 'Historical Notes',
        bannerText: hasHistoricalNotes
            ? `Employee has ${historicalNotesCount > 1 ? `${historicalNotesCount} historical notes` : `${historicalNotesCount} historical note`}.`
            : 'No historical notes added yet.',
        buttonText: hasHistoricalNotes ? 'See Notes' : 'Create New',
    }

    return bannerItems;
};
