import React from 'react';
import { Formik } from 'formik';

import Dialog from 'components/Shared/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import EditStartDateDialogForm from './EditStartDateDialogForm';

import { addUtcOffset } from 'utils/formatTimes';
import { validateRequiredFields } from 'utils/formValidations';
import './EditStartDateDialog.scss';

const EditStartDateDialog = ({
    openDialog,
    handleCloseDialog,
    handleSubmit,
    formData,
}) => {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik
                enableReinitialize
                initialValues={{
                    startDate: formData.startDate || null,
                }}
                validate={(values) => {
                    return {
                        ...validateRequiredFields(values, ['startDate']),
                    }
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    handleSubmit({ ...values, startDate: addUtcOffset(values.startDate) });
                    handleCloseDialog();
                }}
            >
                {(props) => (
                    <Dialog className="edit-start-date-dialog" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
                        <MuiDialogTitle disableTypography className="dialog-title">
                            <Typography variant="body2" className="dialog-title-text">Edit Start date</Typography>
                            <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                                <CloseIcon />
                            </IconButton>
                        </MuiDialogTitle>

                        <DialogContent>
                            <EditStartDateDialogForm
                                {...props}
                                handleCloseDialog={handleCloseDialog}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            </Formik>
        </MuiPickersUtilsProvider>
    );
};

export default EditStartDateDialog;
