import React from 'react';

import { Tooltip } from '@material-ui/core';
import { ReactComponent as MatchingLocationIcon } from 'assets/icon-matching-location.svg';
import { ReactComponent as NonMatchingLocationIcon } from 'assets/icon-non-matching-location.svg';
import { ReactComponent as MissingLocationIcon } from 'assets/icon-missing-location.svg';

import './LocationLabel.scss';

const LocationLabel = ({ isMatch, location }) => {
    const getLabelInfo = () => {
        if (isMatch === true) {
            return {
                icon: <MatchingLocationIcon />,
                title: `Matching Location (${location})`
            };
        } else if (isMatch === false) {
            return {
                icon: <NonMatchingLocationIcon />,

                title: `Not Matching Location (${location})`
            };
        } else {
            return {
                icon: <MissingLocationIcon />,
                title: `Missing Location`
            };
        }
    }

    const { title, icon } = getLabelInfo();

    return (
        <Tooltip placement="bottom-end" classes={{ tooltip: 'custom-tooltip' }} title={title}>
            <div className="location-label-wrapper">
                {icon}
            </div>
        </Tooltip>
    );
};

export default LocationLabel;
