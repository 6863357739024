import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CompanyInformationBasicForm from './CompanyInformationBasicForm';
import CompanyInformationContactPeople from './CompanyInformationContactPeople';
import CompanyInformationAccountManagerForm from './CompanyInformationAccountManagerForm';
import InvoicingAndAccountManagementForm from './InvoicingAndAccountManagementForm';
import HistoricalNotes from 'components/Shared/HistoricalNotes';

import { fetchAllCountries } from 'actions/staticDataActions';
import { fetchInternalEmployees } from 'actions/employeeActions';
import { getCountries, getEmployees, getClientStatus, getClientCompanyInformationFetchState, getSupplierInvoiceStatus } from 'reducers';

import { HISTORICAL_NOTES_TYPES } from 'components/Shared/HistoricalNotes/HistoricalNotesConstants';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ROLES } from 'constants/userConstants';

import './CompanyInformation.scss';

const CompanyInformation = ({
    agencyId,
    companyId,
    basicInformation,
    invoicingInformation,
    countries,
    updateAvatar,
    deleteAvatar,
    updateBasicInformation,
    updateInvoiceInformation,
    updateAccountManagementInformation,
    fetchAllCountries,
    isEmailChangeDisabled,
    accountManagers,
    accountManager,
    isClient,
    isRecruiter,
    invoiceStatus,
    dataFetchState,
    isSupplierPage,
    fetchInternalEmployees,
}) => {
    useEffect(() => {
        fetchAllCountries();
        fetchInternalEmployees(agencyId, ['firstName', 'lastName'], [ROLES.ADMIN]);
    }, []);

    const handleUpdateInfo = async (updatedInfo, updateAction, resetForm) => {
        await updateAction(agencyId, companyId, updatedInfo);
        resetForm();
    };

    return (
        <div>
            {dataFetchState.isDataFetching && accountManager._id === ''
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : <div>
                    <CompanyInformationBasicForm
                        companyId={companyId}
                        agencyId={agencyId}
                        basicInformation={basicInformation}
                        updateAvatar={updateAvatar}
                        deleteAvatar={deleteAvatar}
                        countries={countries}
                        updateBasicInformation={updateBasicInformation}
                        saveBasicInfo={handleUpdateInfo}
                        isEmailChangeDisabled={isEmailChangeDisabled}
                        isClient={isClient}
                    />
                    {isSupplierPage &&
                        <HistoricalNotes
                            noteType={HISTORICAL_NOTES_TYPES.USER}
                            targetId={companyId}
                        />
                    }

                    {!isSupplierPage && !isRecruiter &&
                        <CompanyInformationContactPeople
                            companyId={companyId}
                            agencyId={agencyId}
                            accountManager={accountManager}
                        />
                    }


                    {
                        !isRecruiter
                            ? <InvoicingAndAccountManagementForm
                                agencyId={agencyId}
                                companyId={companyId}
                                currency={invoicingInformation.currency}
                                status={invoiceStatus}
                                accountManager={accountManager}
                                accountManagers={accountManagers}
                                updateAccountManagementInformation={updateAccountManagementInformation}
                                updateInvoiceInformation={updateInvoiceInformation}
                            />
                            : <CompanyInformationAccountManagerForm
                                accountManager={accountManager}
                                accountManagers={accountManagers}
                                updateAccountManagerInformation={updateAccountManagementInformation}
                                saveInvoiceInfo={handleUpdateInfo}
                            />
                    }
                </div>}
        </div >
    );
};

const mapStateToProps = (state, props) => ({
    countries: getCountries(state),
    accountManagers: getEmployees(state),
    invoiceStatus: props.isClient ? getClientStatus(state) : getSupplierInvoiceStatus(state),
    dataFetchState: props.isSupplierPage ? props.dataFetchState : getClientCompanyInformationFetchState(state),
});

const mapDispatchToProps = { fetchAllCountries, fetchInternalEmployees };

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformation);
