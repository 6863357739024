export const motionRedirectLinks = {
    careers: { link: '/register/candidate', label: 'GET A JOB' },
    clients: { link: '/register/client', label: 'GET A TEAM' },
    suppliers: { link: 'https://motion-software.com/partnership', label: 'BECOME A PARTNER' },
    home: { link: 'https://motion-software.com', label: 'GO BACK TO HOME' },
    wiaHome: { link: 'https://wiasaas.com/', label: 'GO BACK TO HOME' },
    login: { link: `/login`, label: 'Login here' },
    privacy: { link: 'https://motion-software.com/privacy', label: 'terms and conditions' },
};

export const MAX_NAME_LENGTH = 100;
