import { persistReducer } from 'redux-persist';
import { PERSIST_UI_CONFIG } from 'utils/persistUtils';

import {
    TOGGLE_NAVIGATION,
    TOGGLE_MOBILE_NAVIGATION,
    HOME_INFORMATION_INITIAL_STATE_SET,
    TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS,
    TOGGLE_OVERTIME_BANNER_SUCCESS,
    TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS,
    TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS,
    TOGGLE_JOURNEY_COMPLETED_SUCCESS,
} from 'actions/actionTypes';

const uiInitialState = {
    toggleNavigation: false, //desktop nav is full-width by default
    toggleMobileNavigation: false,  //mobile nav is hidden by default
    isBusinessTripsBannerOpen: true,
    isOvertimeBannerOpen: true,
    isWorkspaceMigrationDialogOpen: true,
    isIncompleteDialogOpen: false,
    isJourneyCompleted: false,
};

const ui = (state = uiInitialState, action) => {
    switch (action.type) {
        case TOGGLE_NAVIGATION:
            return { ...state, ...action.payload };
        case TOGGLE_MOBILE_NAVIGATION:
            return { ...state, ...action.payload };
        case HOME_INFORMATION_INITIAL_STATE_SET:
            return { ...uiInitialState, isBusinessTripsBannerOpen: state.isBusinessTripsBannerOpen, isOvertimeBannerOpen: state.isOvertimeBannerOpen };
        case TOGGLE_BUSINESS_TRIP_BANNER_SUCCESS:
            return { ...state, isBusinessTripsBannerOpen: action.payload };
        case TOGGLE_OVERTIME_BANNER_SUCCESS:
            return { ...state, isOvertimeBannerOpen: action.payload }
        case TOGGLE_WORKSPACE_MIGRATION_DIALOG_SUCCESS:
            return { ...state, isWorkspaceMigrationDialogOpen: action.payload };
        case TOGGLE_INCOMPLETE_PROFILE_DIALOG_SUCCESS:
            return { ...state, isIncompleteDialogOpen: action.payload };
        case TOGGLE_JOURNEY_COMPLETED_SUCCESS:
            return { ...state, isJourneyCompleted: action.payload };
        default:
            return state;
    }
};

export default persistReducer(PERSIST_UI_CONFIG, ui);

export const getToggleNavigation = state => state.toggleNavigation;
export const getToggleMobileNavigation = state => state.toggleMobileNavigation;
export const getIsBusinessTripBannerOpen = state => state.isBusinessTripsBannerOpen;
export const getIsOvertimeBannerOpen = state => state.isOvertimeBannerOpen;
export const getIsWorkspaceMigrationDialogOpen = state => state.isWorkspaceMigrationDialogOpen;
export const getIsIncompleteProfileDialogOpen = state => state.isIncompleteDialogOpen;
export const getIsJourneyCompleted = state => state.isJourneyCompleted;
