import React, { useState, useRef } from 'react';
import { Formik } from 'formik';

import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import AgencySettingsTeamNotificationsFormView from './AgencySettingsTeamNotificationsFormView';

import { validateEmailAddress } from 'utils/formValidations';

import './AgencySettingsTeamNotifications.scss';

const TOOLTIP_TEXT = "Keep your whole team in the loop by adding your email groups. This allows all team members to stay informed on crucial updates such as new candidates, expiring contracts and agreements, and changes to commercial rates."

const AgencySettingsTeamNotifications = ({
    agencyId,
    agencySettings,
    updateAction,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const handleShowHeaderButtons = () => setShowHeaderButtons(true);

    const teamNotificationsSettingsFormRef = useRef();

    const discardHandlerBasicInfo = () => {
        setShowHeaderButtons(false);
        teamNotificationsSettingsFormRef.current.resetForm();
    };

    return (
        <div className="agency-settings-wrapper agency-team-notifications-settings">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Team Notifications'
                />

                <Tooltip title={TOOLTIP_TEXT} classes={{ tooltip: 'custom-tooltip' }}>
                    <InfoOutlinedIcon className="global-information-icon" />
                </Tooltip>

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={discardHandlerBasicInfo}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => teamNotificationsSettingsFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>
            <Paper className="team-notifications-settings-content">
                <Formik
                    initialValues={{
                        recruitmentEmail: agencySettings?.recruitmentEmail || '',
                        financeEmail: agencySettings?.financeEmail || '',
                        hrEmail: agencySettings?.hrEmail || '',
                    }}
                    innerRef={teamNotificationsSettingsFormRef}
                    validate={values => {
                        return {
                            ...validateEmailAddress(values, 'recruitmentEmail'),
                            ...validateEmailAddress(values, 'financeEmail'),
                            ...validateEmailAddress(values, 'hrEmail'),
                        };
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        updateAction(agencyId, values);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <AgencySettingsTeamNotificationsFormView
                            handleShowHeaderButtons={handleShowHeaderButtons}
                            {...props}
                        />
                    }
                </Formik>
            </Paper>
        </div>
    )
};

export default AgencySettingsTeamNotifications;
