import moment from 'moment';

import { CANDIDATE_STATUSES, STEPS_OF_REJECTION } from 'constants/candidateConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from './commonConstants';

export const applicationProgress = (agencyName, isAdmin, reviewerName, interviewDate) => ({
    [CANDIDATE_STATUSES.VETTED]: reviewerName && isAdmin ? `<p>Candidate <span>approved</span> by ${reviewerName} from ${agencyName}</p>` : `<p>Candidate <span>approved</span> by ${agencyName}</p>`,
    [APPLICATION_STATUSES.INVITED_FOR_INTERVIEW]: reviewerName && isAdmin ? `<p>Client <span>invited</span> candidate to an interview (Added by: ${reviewerName})</p>` : `<p>Client <span>invited</span> candidate to an interview</p>`,
    [APPLICATION_STATUSES.ACCEPTED_INTERVIEW]: `<p>Interview <span>scheduled</span> for ${moment(interviewDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>`,
    [APPLICATION_STATUSES.APPROVED]: reviewerName && isAdmin ? `<p>Client <span>hired</span> candidate (Added by: ${reviewerName})</p>` : '<p>Client <span>hired</span> candidate</p>',
    [APPLICATION_STATUSES.ACCEPTED_ANOTHER_OFFER]: '<p>Candidate has <span>accepted another offer</span></p>',
    [APPLICATION_STATUSES.NEW_APPLICATION]: `<p>Candidate <span>applied</span> for the job</p>`,
    [APPLICATION_STATUSES.CANCELLED_INTERVIEW]: `<p>Interview <span>cancelled</span></p>`,
    [APPLICATION_STATUSES.REJECTED]: {
        [STEPS_OF_REJECTION.REJECTED_BY_AGENCY]: reviewerName && isAdmin ? `<p>Candidate <span>rejected</span> by ${reviewerName} from ${agencyName}</p>` : `<p>Candidate <span>rejected</span> ${agencyName}</p>`,
        [STEPS_OF_REJECTION.FIRST_STEP]: reviewerName && isAdmin ? `<p>Client <span>rejected</span> candidate at CV stage (Added by: ${reviewerName})</p>` : '<p>Client <span>rejected</span> candidate at CV stage</p>',
        [STEPS_OF_REJECTION.SECOND_STEP]: reviewerName && isAdmin ? `<p>Client <span>rejected</span> candidate after interview (Added by: ${reviewerName})</p>` : '<p>Client <span>rejected</span> candidate after interview</p>',
        [STEPS_OF_REJECTION.THIRD_STEP]: reviewerName && isAdmin ? `<p>Candidate <span>rejected</span> by ${reviewerName} from ${agencyName}</p>` : `<p>Candidate <span>rejected</span> ${agencyName}</p>`,
        [STEPS_OF_REJECTION.FOURTH_STEP]: reviewerName && isAdmin ? `<p>Candidate <span>rejected</span> by ${reviewerName} from ${agencyName}</p>` : `<p>Candidate <span>rejected</span> ${agencyName}</p>`,
    },
});

export const APPLICATION_FEEDBACK_RATING_CRITERIA = {
    TECHNICAL_SKILLS: 'Technical Skills',
    ATTITUDE: 'Attitude',
    COMMUNICATION: 'Communication',
    EXPERIENCE: 'Experience'
};

export const APPLICATION_STATUSES = {
    NON_VETTED_CANDIDATE:'Non-Vetted Candidate',
    NEW_APPLICATION: 'New Application',
    INVITED_FOR_INTERVIEW: 'Invited for Interview',
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
    ACCEPTED_ANOTHER_OFFER: 'Accepted Another Offer',
    PENDING_ADMIN_REVIEW: 'Pending Admin Review',
    ACCEPTED_INTERVIEW: 'Accepted Interview',
    AWAITING_INTERVIEW_FEEDBACK: 'Awaiting Interview Feedback',
    REJECTED_BY_ADMIN: 'Rejected by Admin',
    CANCELLED_INTERVIEW: 'Cancelled Interview',
};

export const PRO_APPLICATION_STATUSES_SELECT = Object.values(APPLICATION_STATUSES).slice(1, -1);
export const APPLICATION_STATUSES_SELECT = Object.values(APPLICATION_STATUSES).slice(1, -1);
