import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import { resetPassword, login } from 'actions/authActions';
import PasswordSetFormView from './PasswordSetForm';
import { useHistory } from 'react-router-dom';

import { getLoginLoading } from 'reducers';

import './PasswordSet.scss';

import {
    validateRequiredFields,
    validatePasswordRequirements,
    validatePasswordEquality
}
    from 'utils/formValidations';
import { BASE_APP_TRADEMARK, IS_WHOISAVAILABLE_ENV } from 'constants/env';

const PasswordSet = ({
    resetPassword,
    login,
    loginLoading,
}) => {
    const history = useHistory();
    const { passwordOobCode, email } = history.location?.state || {};

    const initialValues = IS_WHOISAVAILABLE_ENV
        ? { password: '', confirmPassword: '', }
        : { password: '', confirmPassword: '', isTermsAndConditionsAccepted: false };

    return (
        <div className="pwd-set-container">
            <img src={BASE_APP_TRADEMARK.LOGO} alt="logo" className="logo-icon" />
            <Typography className="pwd-set-msg" variant="h5">
                Set your password
            </Typography>
            <Formik
                initialValues={initialValues}
                validate={values => {
                    const requiredFields = IS_WHOISAVAILABLE_ENV
                        ? ['password', 'confirmPassword']
                        : ['password', 'confirmPassword', 'isTermsAndConditionsAccepted'];

                    return {
                        ...validatePasswordRequirements(values, 'password'),
                        ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                        ...validateRequiredFields(values, requiredFields)
                    }
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.password = values.password.trim();
                    values.confirmPassword = values.confirmPassword.trim();
                    setSubmitting(false);

                    await resetPassword(passwordOobCode, values.password)
                    login(email, values.password, false, values.isTermsAndConditionsAccepted);
                }}
            >
                {(props) => <PasswordSetFormView
                    loginLoading={loginLoading}
                    {...props}
                />}
            </Formik>
        </div>
    )
};

const mapStateToProps = state => ({
    loginLoading: getLoginLoading(state),
});


const mapDispatchToProps = {
    resetPassword,
    login,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordSet);
