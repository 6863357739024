import { CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS } from './candidateConstants';

export const AMPLITUDE_EVENT_TYPES = {
    LOGIN: 'Login',
    REGISTER: 'Register',
    PAGE_VIEWED: 'Page Viewed',
    FINISH_ONBOARDING: 'Finish Onboarding',
    SKIP_ONBOARDING: 'Skip Onboarding',
    CREATE_JOB: 'Create Job',
    DOWNLOAD_INVOICE: 'Download Invoice',
    DOWNLOAD_TIMESHEETS: 'Download Timesheets',
    WATCH_VIDEO_INTERVIEW: 'Watch Video Interview',
    VIEW_CV_ON_REQUEST: 'View CV on Request',
    CLICK_ADD_TEAM_MEMBER: 'Click "Add Team Member"',
    ADD_TEAM_MEMBER: 'Add Team Member',
    CLICK_ADD_CLIENT: 'Click "Add Client"',
    ADD_CLIENT_PROFILE: 'Add Client Profile',
    CLICK_ADD_SUPPLIER: 'Click "Add Supplier"',
    ADD_SUPPLIER_PROFILE: 'Add Supplier Profile',
    EXPORT_CV: 'Export CV',
    CLICK_ADD_TASK: 'Click Add Task',
    ADD_TASK: 'Add Task',
    CLICK_UPLOAD_RAW_CV: 'Click "Upload Raw CV"',
    UPLOAD_RAW_CV: 'Upload Raw CV',
    SWITCH_TO_EMPLOYEE_MODE: 'Switch To Employee Mode',
    ADD_CLIENT_PROJECT: 'Add Client Project',
    ADD_EMPLOYEE_LANGUAGE: 'Add Employee Language',
    ADD_EMPLOYEE_EXPERIENCE: 'Add Employee Experience',
    ADD_EMPLOYEE_CERTIFICATION: 'Add Employee Certification',
    ADD_EMPLOYEE_EDUCATION: 'Add Employee Education',
    ADD_EMPLOYEE_GENERAL_DETAILS: 'Add Employee General Details',
    ADD_RATING_CRITERIA: 'Add Rating Criteria',
    ADD_EMPLOYEE_HOLIDAY_ALLOWANCE: 'Add Employee Holiday Allowance',
    ADD_AGENCY_LOGO: 'Add Agency Logo',
    TRACK_EMPLOYEE_TIME: 'Track Employee Time',
    EXPORT_EMPLOYEE_TIME: 'Export Employee Time',
    CLICK_REQUEST_HOLIDAY: 'Click "Request Holiday"',
    REQUEST_HOLIDAY: 'Request Holiday',
    CLICK_ADD_PERKS_AND_EQUIPMENT: 'Clicks "Add Perks & Equipment"',
    ADD_PERKS_AND_EQUIPMENT: 'Add Perks and Equipment',
    OPEN_REQUEST_POPUP: 'Open Request popup',
    CLICK_RESPOND_TO_REQUEST: 'Clicks "Respond to Request"',
    RESPOND_TO_REQUEST: 'Respond to Request',
    CLICK_FORWARD_REQUEST: 'Clicks "Forward Request"',
    FORWARD_REQUEST: 'Forward Request',
    CHANGE_CLIENT_ACCOUNT_MANAGER: 'Change Client Account Manager',
    CHANGE_SUPPLIER_ACCOUNT_MANAGER: 'Change Supplier Account Manager',
    ADD_EMPLOYEE_CONTRACT: 'Add Employee Contract',
    ADD_CLIENT_CONTRACT: 'Add Client Contract',
    ADD_SUPPLIER_CONTRACT: 'Add Supplier Contract',
    CLICK_ADD_EMPLOYEE_CONTRACT: 'Click "Add Employee Contract"',
    CLICK_ADD_CLIENT_CONTRACT: 'Click "Add Client Contract"',
    CLICK_ADD_SUPPLIER_CONTRACT: 'Click "Add Supplier Contract"',
    CLICK_ADD_CLIENT_INVOICE: 'Click "Add Client Invoice"',
    ADD_CLIENT_INVOICE: 'Add Client Invoice',
    REJECT_CANDIDATE: 'Reject Candidate',
    HIRE_CANDIDATE: 'Hire Candidate',
    REGISTER_AGENCY_SUCCESS: 'Register Agency Success',
    CANDIDATE_USED_LINKEDIN_RESUME: 'Use LinkedIn resume',
    CANDIDATE_USED_UPLOAD_RESUME: 'Use upload resume',
    CANDIDATE_RERECORD_VIDEO_INTRODUCTION: 'Re-record video introduction',
    CANDIDATE_CLICK_EXPLORE_JOBS: 'Click explore jobs',
    CANDIDATE_CLICK_FINISH_PROFILE: 'Click finish profile',
    CANDIDATE_APPLY_FOR_JOB_BEFORE_PROFILE_FINISH: 'Apply for job before profile finish',
    CANDIDATE_APPLIES_FOR_JOB: 'Apply for job success',
    CLIENT_FILTER_JOBS_BY_TYPE: 'Client filter jobs by type',
    CLIENT_HOVER_MATCHING_SCORE: 'Client hover matching score',
    CLIENT_FILTER_APPLICANTS_BY_STATUS: 'Client filter applicants by status',
    CLIENT_INVITE_APPLICANT_TO_INTERVIEW: 'Client invite applicant to interview',
    CLIENT_REJECT_CANDIDATE: 'Client reject candidate',
    CLIENT_HIRE_CANDIDATE: 'Client hire candidate',
    CLIENT_SEARCH_CANDIDATE_ON_JOB_PAGE: 'Client search candidate on job page',
    CLIENT_OPEN_CANDIDATE_PROFILE_FROM_JOB_PAGE: 'Client open candidate profile from job page',
    CLIENT_CLICK_NOTIFICATION: 'Client click notification',
    CLIENT_CLICK_CALENDAR_EVENT: 'Client click on calendar event',
    ADMIN_CLICK_FIND_MATCH_EVENT: 'Admin click on find match event',
    ASK_A_RECRUITER: 'Ask a Recruiter',
    CANDIDATE_SEES_UNSUPPORTED_LOCATION_DIALOG: 'Candidate sees unsupported location dialog',
    RATE_JOB_RECOMMENDATION: 'Rate job recommendation',
    UNASSIGNED_USER_SELECTS_ROLE: 'Unassigned user selects role',
    CANDIDATE_COMPLETES_EXPERTISE: 'Candidate completes expertise step',
    CANDIDATE_COMPLETES_EXPERIENCE: 'Candidate completes experience step',
    CANDIDATE_COMPLETES_PREFERENCES: 'Candidate completes preferences step',
    CANDIDATE_COMPLETES_UPLOAD_CV: 'Candidate completes upload cv step',
    CANDIDATE_COMPLETES_EDIT_CV: 'Candidate completes edit cv step',
    CANDIDATE_COMPLETES_VIDEO_INTRODUCTION: 'Candidate completes video introduction step',
};

export const USER_PROPERTIES = {
    VIDEOS_WATCHED: { key: 'Videos Watched', value: 1 }
};

export const CANDIDATE_JOURNEY_AMPLITUDE_EVENT_NAME_BY_SUBSTEP_ID = {
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.EXPERTISE]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_EXPERTISE, 
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.EXPERIENCE]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_EXPERIENCE,
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.PREFERENCES]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_PREFERENCES,
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.UPLOAD_RESUME]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_UPLOAD_CV,
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.CV_COMPLETION]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_EDIT_CV,
    [CANDIDATE_PROFILE_JOURNEY_SUBSTEP_IDS.VIDEO_INTRODUCTION]: AMPLITUDE_EVENT_TYPES.CANDIDATE_COMPLETES_VIDEO_INTRODUCTION,
};
