import React from 'react';
import { connect } from 'react-redux';

import {
    isAdminMode,
    isEmployeeMode,
    isSupplierEmployeeMode,
    getDisplayMode,
    isRecruiterMode,
    isCandidateMode,
    getGeneralInfo,
    getCurrentUser,
    getEmployeeBasicInfo,
} from 'reducers';

import { editGeneralInfo, exportCV } from 'actions/employeeActions';
import { exportSupplierEmployeesCVs } from 'actions/supplierActions';
import { exportCandidateCV } from 'actions/candidateActions';

import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';

import { logEvent } from 'utils/amplitude';

import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { ROLES } from 'constants/userConstants';

import './ProfileAvailability.scss';

const ProfileAvailability = ({
    agencyId,
    employeeId,
    employeeBasicInfo,
    displayMode,
    exportCV,
    exportSupplierEmployeesCVs,
    isCandidateProfile,
    departmentId,
    exportCandidateCV,
}) => {
    const exportCVsFunctions = {
        'admin': isCandidateProfile ? exportCandidateCV : exportCV,
        'supplier-admin': exportSupplierEmployeesCVs,
        'candidate': exportCandidateCV,
        'employee': exportCV,
        'supplier-employee': exportSupplierEmployeesCVs,
    }

    const handleExportCV = () => {
        exportCVsFunctions[displayMode]([{
            _id: employeeId,
            firstName: employeeBasicInfo.firstName,
            lastName: employeeBasicInfo.lastName
        }], agencyId, departmentId);
    };

    return (
        <div className="availability-wrapper">
            <div className="switch-container">
                <PrimaryExportButton text="Export CV" className="export-cv-btn" handleClick={() => {
                    logEvent(AMPLITUDE_EVENT_TYPES.EXPORT_CV, { rolesPermitted: [ROLES.ADMIN] });
                    handleExportCV();
                }} />
            </div>
        </div>
    )
};

const mapStateToProps = state => ({
    displayMode: getDisplayMode(state),
    isAdminMode: isAdminMode(state),
    isEmployeeMode: isEmployeeMode(state),
    isSupplierEmployeeMode: isSupplierEmployeeMode(state),
    isRecruiterMode: isRecruiterMode(state),
    isCandidateMode: isCandidateMode(state),
    generalInfo: getGeneralInfo(state),
    authUserId: getCurrentUser(state)._id,
    employeeBasicInfo: getEmployeeBasicInfo(state),
});

const mapDispatchtoProps = {
    editGeneralInfo,
    exportCV,
    exportSupplierEmployeesCVs,
    exportCandidateCV
};

export default connect(mapStateToProps, mapDispatchtoProps)(ProfileAvailability);
