import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from "moment";

import {
	getProject,
	getClientInvoicingInformation,
	isAdminMode,
	isClientMode,
	getPublicHolidays,
	getAgencyId,
	isClientProjectsModuleModified,
	getProjectDetailsFetchState,
	getProjectAllEmployeesHistory,
	getProjectContractEmployeesHistory,
	getProjectFullTimeEmployeesHistory,
} from "reducers";

import { fetchAllClientConsultants } from 'actions/employeeActions';
import { fetchProjectEmployeesApprovedHolidays, fetchClientProject, fetchClientInformation } from 'actions/clientActions';
import { fetchAgencyBasicInfo } from 'actions/agencyActions';
import { resetProjectDetailsState } from 'actions/sectionsLoadersActions';

import ActiveHolidays from 'components/Shared/ActiveHolidays';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';
import CardDetails from './CardDetails';
import ExpandTeamBanner from './ExpandTeamBanner';
import Team from './Team';
import ClientProjectDocuments from './ClientProjectDocuments';
import TeamHistory from './TeamHistory';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DATE_FORMAT_WITH_DASH } from 'constants/commonConstants';
import { defaultCurrencyEUR } from 'constants/currenciesConstant';
import {
	CONTRACT_HISTORY_PAGE_SELECTOR,
	CONTRACT_HISTORY_QUERY_SELECTOR,
	FULL_TIME_HISTORY_PAGE_SELECTOR,
	FULL_TIME_HISTORY_QUERY_SELECTOR,
	TEAM_HISTORY_PAGE_SELECTOR,
	TEAM_HISTORY_QUERY_SELECTOR,
	contractHistoryColumns,
	defaultContractHistoryColumns,
	defaultFullTimeHistoryColumns,
	defaultTeamMembersHistoryColumns,
	fullTimeHistoryColumns,
	teamMembersHistoryColumns
} from './ProjectDetailsConstants';

import { parseQueryString } from 'utils/helpers';

import './ProjectDetails.scss';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

const ProjectDetails = ({
	history,
	project,
	agencyId,
	isAdminMode,
	isClientMode,
	clientInvoicing,
	publicHolidays,
	fetchClientProject,
	fetchAllClientConsultants,
	fetchProjectEmployeesApprovedHolidays,
	fetchClientInformation,
	fetchAgencyBasicInfo,
	isClientProjectsModuleModified,
	dataFetchState,
	resetProjectDetailsState,
}) => {
	const currency = clientInvoicing.currency ? clientInvoicing : { currency: defaultCurrencyEUR };
	const { id: userId, projectId } = useParams();
	const requiredFields = ['firstName', 'lastName', 'occupationStatus'];

	useEffect(() => {
		const from = moment().format(DATE_FORMAT_WITH_DASH);
		const to = moment().add(14, 'day').format(DATE_FORMAT_WITH_DASH);
		fetchProjectEmployeesApprovedHolidays(agencyId, userId, projectId, from, to);
		fetchClientProject(agencyId, userId, projectId);
		if (isAdminMode || isClientMode) {
			fetchAllClientConsultants(agencyId, requiredFields);
			fetchClientInformation(agencyId, userId);
			fetchAgencyBasicInfo(agencyId);
		}

		return resetProjectDetailsState;
	}, [projectId]);

	const handleBackToProjects = () => {
		history.goBack();
	};

	const allEmployeesHistoryDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = teamMembersHistoryColumns.find(x => x.value === query[TEAM_HISTORY_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${TEAM_HISTORY_QUERY_SELECTOR}Dir`];
		const page = query[TEAM_HISTORY_PAGE_SELECTOR] ? +query[TEAM_HISTORY_PAGE_SELECTOR] : 1;

		return getProjectAllEmployeesHistory(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const allEmployeesHistoryDataCountSelector = (state) => getProjectAllEmployeesHistory(state).count;

	const contractEmployeesHistoryDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = contractHistoryColumns.find(x => x.value === query[CONTRACT_HISTORY_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${CONTRACT_HISTORY_QUERY_SELECTOR}Dir`];
		const page = query[CONTRACT_HISTORY_PAGE_SELECTOR] ? +query[CONTRACT_HISTORY_PAGE_SELECTOR] : 1;

		return getProjectContractEmployeesHistory(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const contractEmployeesHistoryDataCountSelector = (state) => getProjectContractEmployeesHistory(state).count;

	const fullTimeEmployeesHistoryDataSelector = (state) => {
		const query = parseQueryString(history.location.search);
		const sortBy = fullTimeHistoryColumns.find(x => x.value === query[FULL_TIME_HISTORY_QUERY_SELECTOR])?.fieldValue;
		const sortByDir = query[`${FULL_TIME_HISTORY_QUERY_SELECTOR}Dir`];
		const page = query[FULL_TIME_HISTORY_PAGE_SELECTOR] ? +query[FULL_TIME_HISTORY_PAGE_SELECTOR] : 1;

		return getProjectFullTimeEmployeesHistory(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir).result;
	};

	const fullTimeEmployeesHistoryDataCountSelector = (state) => getProjectFullTimeEmployeesHistory(state).count;

	return (
		<>
			<PreviousButton
				text={"Back"}
				handleClick={handleBackToProjects}
			/>
			<div className="project-details-wrapper">
				{dataFetchState?.isDataFetching && project._id === ''
					? <CircularProgress size={50} disableShrink className="project-details-loader" />
					:
					<>
						<CardDetails
							project={project}
						/>
						{isClientMode && <ExpandTeamBanner />}

						<Team
							agencyId={agencyId}
							clientId={userId}
							projectId={projectId}
							project={project}
							clientInvoicing={currency}
							isClientProjectsModuleModified={isClientProjectsModuleModified}
						/>

						<ActiveHolidays
							dataFetchState={dataFetchState}
							publicHolidays={publicHolidays}
							clientId={userId}
							projectId={projectId}
						/>

						<ClientProjectDocuments
							projectId={projectId}
							agencyId={agencyId}
							clientId={userId}
							isAdminMode={true}
						/>

						{isClientProjectsModuleModified ?
							<TeamHistory
								agencyId={agencyId}
								projectId={projectId}
								project={project}
								tableTitle="Team History"
								columns={teamMembersHistoryColumns}
								defaultColumns={defaultTeamMembersHistoryColumns}
								sortQuerySelector={TEAM_HISTORY_QUERY_SELECTOR}
								pageQuerySelector={TEAM_HISTORY_PAGE_SELECTOR}
								dataSelector={allEmployeesHistoryDataSelector}
								dataCountSelector={allEmployeesHistoryDataCountSelector}
								adjustKeysPrefix={"teamHistory"}
							/>

							: <Fragment>
								<TeamHistory
									agencyId={agencyId}
									projectId={projectId}
									project={project}
									tableTitle="Contract Team History"
									columns={contractHistoryColumns}
									defaultColumns={defaultContractHistoryColumns}
									sortQuerySelector={CONTRACT_HISTORY_QUERY_SELECTOR}
									pageQuerySelector={CONTRACT_HISTORY_PAGE_SELECTOR}
									dataSelector={contractEmployeesHistoryDataSelector}
									dataCountSelector={contractEmployeesHistoryDataCountSelector}
									adjustKeysPrefix={"contractHistory"}
								/>
								<TeamHistory
									agencyId={agencyId}
									projectId={projectId}
									project={project}
									tableTitle="Full-Time Team History"
									columns={fullTimeHistoryColumns}
									defaultColumns={defaultFullTimeHistoryColumns}
									sortQuerySelector={FULL_TIME_HISTORY_QUERY_SELECTOR}
									pageQuerySelector={FULL_TIME_HISTORY_PAGE_SELECTOR}
									dataSelector={fullTimeEmployeesHistoryDataSelector}
									dataCountSelector={fullTimeEmployeesHistoryDataCountSelector}
									adjustKeysPrefix={"fullTimeHistory"}
								/>
							</Fragment>
						}
					</>
				}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	project: getProject(state),
	clientInvoicing: getClientInvoicingInformation(state),
	isAdminMode: isAdminMode(state),
	isClientMode: isClientMode(state),
	publicHolidays: getPublicHolidays(state),
	agencyId: getAgencyId(state),
	isClientProjectsModuleModified: isClientProjectsModuleModified(state),
	dataFetchState: getProjectDetailsFetchState(state),
});

const mapDispatchtoProps = {
	fetchProjectEmployeesApprovedHolidays,
	fetchClientProject,
	fetchAllClientConsultants,
	fetchClientInformation,
	fetchAgencyBasicInfo,
	resetProjectDetailsState,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ProjectDetails);
