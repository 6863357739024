import React from 'react';
import { connect } from 'react-redux';
import { getUserId, getAgencyId, getDisplayMode, getAgencyName } from 'reducers';

const withUserData = Component => {
    const UserData = ({
        userId,
        agencyId,
        agencyName,
        displayMode,
        ...props
    }) => <Component {...props} userId={userId} agencyId={agencyId} agencyName={agencyName} displayMode={displayMode} />;

    const mapStateToProps = state => ({
        userId: getUserId(state),
        agencyId: getAgencyId(state),
        agencyName: getAgencyName(state),
        displayMode: getDisplayMode(state)
    });

    return connect(mapStateToProps)(UserData);
}

export default withUserData;
