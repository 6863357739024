import React from 'react';
import { useHistory } from 'react-router-dom';

import { Avatar, Paper } from '@material-ui/core';
import ThreeDotsIcon from '@material-ui/icons/MoreHoriz';

import StatusBox from 'components/Shared/StatusBox';

import { getStateAgencyName } from 'utils/helpers';

import { COACHING_OPPORTUNITY_STATUSES } from '../../CoachingConstants';

import './CoachingOpportunityCard.scss';

const CoachingOpportunityCard = ({
    coachingOpportunity,
    setAnchorEl,
    setSelectedOpportunity,
}) => {
    const history = useHistory();

    const handleDotsClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setSelectedOpportunity(coachingOpportunity)
    };

    return (
        <Paper className="coaching-opportunity-card-wrapper">
            <div className="coach-avatar">
                { coachingOpportunity.coachingOpportunityImgUrl ? 
                    <Avatar 
                        alt="profile logo"
                        className="profile-logo"
                        src={coachingOpportunity.coachingOpportunityImgUrl}
                    />
                : <Avatar className="profile-logo" /> }
            </div>
            <div className="coaching-opportunity-info" onClick={() => history.push(`/${getStateAgencyName()}/settings/coaching/${coachingOpportunity._id}`)}>
                <div className="info-header">
                    <div className="flex-items">
                        <h2 className="title">{coachingOpportunity.coachName}</h2>
                        {coachingOpportunity.languages?.length > 0 &&
                            <div className="languages-list">
                                {coachingOpportunity.languages.map((language, index) => (
                                    <div key={coachingOpportunity._id + language + index} className="language-item">
                                        {language}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="actions">
                        <StatusBox
                            title={coachingOpportunity.status}
                            classes={[COACHING_OPPORTUNITY_STATUSES[coachingOpportunity.status?.toUpperCase()]?.toLowerCase()]}
                        />
                        <ThreeDotsIcon className="three-dots-action" onClick={handleDotsClick} />
                    </div>
                </div>
                <p className="description">{coachingOpportunity.aboutTheCoach}</p>
                <hr/>
                <h2 className="title">{coachingOpportunity.title}</h2>
                <p className="description">{coachingOpportunity.description}</p>
                <div className="global-tags-list">
                    { coachingOpportunity.tags.map(x => (
                        <div key={x._id} className="tag-name">{x.title}</div>
                    )) }
                </div>
            </div>
        </Paper>
    );
};

export default CoachingOpportunityCard;
