import React from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getBannerContent } from './EmployeeStatusBannerConstants';

import './EmployeeStatusBanner.scss';

const EmployeeStatusBanner = ({
    selectedEmployee,
    openEditStatusDialog
}) => {
    const { TEXT, ICONS } = getBannerContent(selectedEmployee.occupationStatus);

    return (
        <>
            {TEXT ?
                <div className="employee-status-banner-wrapper">
                    <div className="employee-status-text-wrapper">
                        {TEXT}
                    </div>
                    <div className="employee-status-actions-wrapper">
                        <PrimaryButton
                            className="light"
                            text="Change Status"
                            handleClick={openEditStatusDialog}
                        />
                    </div>
                    <div className="employee-status-icons-wrapper">
                        {ICONS}
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default EmployeeStatusBanner;
