import React, { useState } from 'react';
import { connect } from 'react-redux';

import DepartmentMembersTable from './DepartmentMembersTable';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';

import { setPersonalInfoInitialState } from 'actions/employeeActions';
import { fetchDepartmentMembers, registerDepartmentEmployee } from 'actions/departmentActions';
import { getAgencyId } from 'reducers';

import { ROLES } from 'constants/userConstants';

import './DepartmentMembers.scss'

const DepartmentMembers = ({
    agencyId,
    departmentId,
    fetchDepartmentMembers,
    registerDepartmentEmployee,
}) => {
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [newUserData, setNewUserData] = useState({});
    const [reload, setReload] = useState(false);

    const handleOpenAddDialog = () => {
        setIsAddDialogOpen(true);
    };

    const handleCloseAddDialog = () => {
        setNewUserData({});
        setIsAddDialogOpen(false);
    };

    const handleCloseConfirmDialog = () => setIsConfirmDialogOpen(false);

    const handleRegisterEmployee = async employeeData => {
        const { email, name, employmentType, startDate } = employeeData;

        employeeData = {
            email,
            name,
            employmentInformation: {
                generalInfo: { profileVisibility: 'Private', startDate },
                contractInformation: { employmentType }
            },
            role: ROLES.EMPLOYEE,
        };

        if (employeeData.email) {
            setNewUserData(employeeData);
            setIsConfirmDialogOpen(true);
            return;
        }

        await registerDepartmentEmployee(agencyId, departmentId, employeeData);
        setReload(true);
        handleCloseAddDialog();
    };

    const handleConfirmInviteUserClick = async () => {
        try {
            handleCloseConfirmDialog();
            await registerDepartmentEmployee(agencyId, departmentId, newUserData);
            setReload(true);
            handleCloseAddDialog();
        } catch (error) {
            setNewUserData(prevState => ({ ...prevState, email: '' }));
            handleCloseConfirmDialog();
        }
    };

    return (
        <div className="department-members-wrapper">
            <DepartmentMembersTable
                key={departmentId} // User can have multiple departments, so this re-renders the component on department change.  
                handleOpenAddDialog={handleOpenAddDialog}
                reload={reload}
                setReload={setReload}
                tableFetchAction={fetchDepartmentMembers.bind(null, agencyId, departmentId)}
            />

            <AddTeamMemberDialog
                isOpen={isAddDialogOpen}
                isAddingEmployee={true}
                newUserData={newUserData}
                handleCloseDialog={handleCloseAddDialog}
                handleRegisterMember={handleRegisterEmployee}
            />

            <ConfirmInviteUserDialog
                openDialog={isConfirmDialogOpen}
                invitedUser={ROLES.EMPLOYEE}
                handleCloseDialog={handleCloseConfirmDialog}
                handleInviteUser={handleConfirmInviteUserClick}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    fetchDepartmentMembers,
    setPersonalInfoInitialState,
    registerDepartmentEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentMembers);
