import api from './api';
import requester from './requester';

const historicalNotesService = {
    fetchHistoricalNotes: (agencyId, targetId, noteType, limit, departmentId) => requester(api.historicalNotes(agencyId, targetId, noteType, limit, null, departmentId)).getMany(),
    addHistoricalNote: (agencyId, noteData, departmentId) => requester(api.historicalNotes(agencyId, null, null, null, null, departmentId)).create(noteData),
    editHistoricalNote: (agencyId, noteId, noteData, departmentId) => requester(api.historicalNotes(agencyId, null, null, null, noteId, departmentId)).update(noteData),
    deleteHistoricalNote: (agencyId, noteId, departmentId) => requester(api.historicalNotes(agencyId, null, null, null, noteId, departmentId)).delete(),
};

export default historicalNotesService;
