export const setInvoiceFormValues = (invoice) => {
    return {
        _id: invoice._id,
        projectId: invoice.projectId,
        period: invoice.period,
        issueDate: invoice.issueDate,
        dueDate: invoice.dueDate,
        emailsTo: invoice.recipientEmails?.to?.join(', '),
        emailsCc: invoice.recipientEmails?.cc?.join(', '),
        emailsBcc: invoice.recipientEmails?.bcc?.join(', '),
        amount: invoice.amount,
        filesAttached: invoice.filesAttached.map(x => ({ ...x, name: x.originalFileName })),
        currency: invoice.currency,
        invoiceNumber: invoice.invoiceNumber || ''
    }
};

