import React from 'react';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

const TableActionButton = ({
    icon,
    text,
    handleClick,
    className,
    component,
    props
}) => (
    <PrimaryButton
        className={className ? `table-action-button ${className}` : 'table-action-button'}
        text={text}
        icon={icon}
        onClick={handleClick}
        component={component ? component : 'button'}
        {...props}
    />
);

export default TableActionButton;
