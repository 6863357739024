export const LOGIN_CARD = {
    MESSAGE: "Please complete to create your account.",
    TITLE: "Sign up to Motion Software",
    SUBTITLE: "Please complete to create your account."
}

export const REGISTER_CARD = {
    TITLE: "SIGN UP TO UNLOCK YOUR JOB MATCHES",
    SUBTITLE: "It's free to join."
}
