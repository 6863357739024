import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';
import MatchingLinearProgress from 'components/JobPage/SharedComponents/MatchingLinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import AppliedCandidateInformationIcon from 'assets/applied-candidates-information-icon.svg';
import { ReactComponent as CandidateApplicationCalendarIcon } from 'assets/applied-candidate-calendar.svg';

import { candidateStatusMapper, candidateStatusToTooltipText, getPathToCandidateApplication } from 'utils/applicationUtils';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { CANDIDATE_MATCHING_TOOLTIP_TITLE } from 'constants/candidateConstants';

import './CandidateApplication.scss';

const CandidateApplication = ({
    candidateId,
    application,
    displayMode,
}) => {
    const [isShowMorePopupVisible, setIsShowMorePopupVisible] = useState(false);
    const [popupTransform, setPopupTransform] = useState({});

    const handleTooltipHover = (e, visibility) => {
        if (e) {
            const popup = e.target.parentNode.nextSibling;

            const popupBoundingRect = popup.getBoundingClientRect();
            const boundingRect = e.target.getBoundingClientRect();
            const topOffset = boundingRect.top;

            const yOffset = window.innerHeight - (topOffset + popupBoundingRect.height + 20);

            let yTranslate = 0;
            const xTranslate = e.target.offsetLeft - 115;

            if (yOffset < 0) {
                yTranslate = - (popupBoundingRect.height + 22);
            }

            setPopupTransform({ transform: `translate(${xTranslate}px, ${yTranslate}px)` });
        }
        setIsShowMorePopupVisible(visibility);
    };

    return (
        <Link
            className="candidate-application-wrapper"
            to={getPathToCandidateApplication(application.jobOpportunityId, candidateId, application.applicationId, displayMode)}
        >
            <JobTypeLabel jobType={application.jobType} />

            <Tooltip classes={{ tooltip: 'custom-tooltip' }} title={candidateStatusToTooltipText(application.status)}>
                <div className={`candidate-application-status-label ${candidateStatusMapper(application.status)?.toLowerCase().split(" ").join("-")}`} >
                    <span className="candidate-application-status-text">{candidateStatusMapper(application.status)}</span>
                </div>
            </Tooltip>

            <div>
                <div className="candidate-application-position">
                    {application.position}
                </div>

                <div className="candidate-application-date">
                    <div className="candidate-application-calendar-icon">
                        <CandidateApplicationCalendarIcon />
                    </div>
                    <div className="candidate-application-date-text">
                        Applied: {moment(application.applicationDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}
                    </div>
                </div>
            </div>
            <div className="candidate-application-matching">
                <div className="candidate-application-matching-text-container">
                    <span className="candidate-application-matching-text">Matching Score</span>
                    <span
                        className="candidate-application-matching-icon"
                        onMouseOver={(e) => handleTooltipHover(e, true)}
                        onMouseLeave={() => handleTooltipHover(null, false)}
                    >
                        <img src={AppliedCandidateInformationIcon} />
                    </span>

                    <div className={`candidate-application-matching-tooltip ${isShowMorePopupVisible ? 'show' : 'hide'}`} style={popupTransform}>
                        <h3 className="candidate-application-matching-tooltip-title">{CANDIDATE_MATCHING_TOOLTIP_TITLE}</h3>
                        {Object.values(application.categoryWeights || {}).some(weight => weight !== 0) && <h3 className="candidate-application-matching-tooltip-title">This candidate has scored high in these categories</h3>}
                        <MatchingLinearProgress categoryWeights={application.categoryWeights || {}} />
                    </div>

                </div>
                <div className="candidate-application-matching-number">
                    {application.matchingScore} <small>/100</small>
                </div>
            </div>
        </Link>
    );
};

export default CandidateApplication;
