import React from 'react';

import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as StartCandidateJourneyIcon } from 'assets/start-candidate-journey-icon.svg';

const WhatToExpect = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
}) => (
    <>
        <PaperHeader title="What to expect?" classes={["start-candidate-journey-header"]}/>
        <Typography className="start-candidate-journey-sub-header">
            How to get matched to a remote job in <span className="highlighted-text green">{getCandidateJourneyDuration()} mins</span>
        </Typography>
        <div className="start-candidate-journey-steps-container">
            <ul className="start-candidate-journey-stepper">
                {START_JOURNEY_STEPS.map((step, index) => (
                    <li key={index} className="single-step">
                        <div className="step-box">
                            <span className="box-text">{index + 1}</span>
                        </div>
                        <div className="step-content">
                            <div className="common-line">
                                <span className="step-title">{step.title}</span>
                                <span className="step-duration">{step.duration}min</span>
                            </div>
                            <span className="step-description">{step.description}</span>
                        </div>
                    </li>
                ))}
            </ul>

            <div className="start-candidate-journey-icon-wrapper">
                <StartCandidateJourneyIcon className="start-candidate-journey-icon" />
            </div>
        </div>
    </>
);

export default WhatToExpect;
