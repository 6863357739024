import React from 'react';
import { connect } from 'react-redux';

import CandidatesChecklistTableView from './CandidatesChecklistTableView';

const CandidatesChecklistTableRedux = ({
    slicedFilteredChecklistedCandidates,
    totalChecklistedCandidatesCount,
    ...props
}) => {
    return (
        <CandidatesChecklistTableView
            slicedFilteredChecklistedCandidates={slicedFilteredChecklistedCandidates}
            totalChecklistedCandidatesCount={totalChecklistedCandidatesCount}
            {...props}
        />
    );
};

const mapStateToProps = (state, props) => {
    const {
        slicedFilteredChecklistedCandidates,
        totalChecklistedCandidatesCount
    } = props.dataSelector(state, props.fromPage, props.rowsPerPage, props.filters);

    return {
        slicedFilteredChecklistedCandidates,
        totalChecklistedCandidatesCount,
    };
};

export default connect(mapStateToProps, null)(CandidatesChecklistTableRedux);
