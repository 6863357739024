import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getCountries, getJobOpportunitiesTechnologies } from 'reducers';

import { updateCandidatePreferences } from 'actions/candidateActions';
import { updateAvatar, deleteAvatar } from 'actions/employeeActions';
import { fetchJobOpportunitiesTechnologies } from 'actions/jobOpportunityActions';

import CandidateProfileInfoFormik from './CandidateProfileInfoFormik';
import ExperienceDialog from './Dialogs/ExperienceDialog';
import TaxResidencyDialog from './Dialogs/TaxResidencyDialog';
import AvailableFromDialog from './Dialogs/AvailableFromDialog';
import ExpectedSalaryDialog from './Dialogs/ExpectedSalaryDialog';
import ImageEditDialog from 'components/Shared/ImageEditDialog';
import JobTypeDialog from './Dialogs/JobTypeDialog';
import TechStackDialog from './Dialogs/TechStackDialog';

import Avatar from '@material-ui/core/Avatar';
import { Divider, Typography } from '@material-ui/core';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import CandidateStatusIcon from './CandidateStatusIcon';

import { ReactComponent as EmailIcon } from 'assets/email-icon.svg';

import { JOB_TYPES } from 'components/CandidateProfileJourney/Steps/YourProfileWrapper/YourProfileConstants';
import { DATE_FORMAT } from 'constants/commonConstants';
import { ROWS_MAPPER, REQUIRED_FIELDS, POSITIVE_FIELDS } from './CandidateProfileInfoConstants';

import './CandidateProfileInfo.scss';

const CandidateProfileInfo = ({
    profileInfo,
    personalInfo,
    updateCandidatePreferences,
    agencyId,
    userId,
    candidateId,
    setEditMode,
    isCandidateMode,
    isAdminMode,
    countries,
    deleteAvatar,
    updateAvatar,
    jobsTechnologies,
    fetchJobOpportunitiesTechnologies,
}) => {
    const classes = ["candidate-preferences", "background-white"].join(' ');
    const [dialog, setDialog] = useState('');

    const [currentImgSelected, setCurrentImgSelected] = useState(personalInfo.profilePictureImgUrl);
    const [isEditImgDialogOpen, setIsEditImgDialogOpen] = useState(false);

    const profileImgRef = useRef();

    useEffect(() => {
        fetchJobOpportunitiesTechnologies(agencyId);
    }, [])

    const currentlyOpenedDialog = (props) => {
        switch (dialog) {
            case 'experienceSince':
                return <ExperienceDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'countryOfTax':
                return <TaxResidencyDialog {...props} open={true} onClose={handleCloseDialog} isCandidateMode={isCandidateMode} />
            case 'earliestDate':
                return <AvailableFromDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'salaryValue':
                return <ExpectedSalaryDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'jobType':
                return <JobTypeDialog {...props} open={true} onClose={handleCloseDialog} />
            case 'techStack':
                return <TechStackDialog {...props} open={true} onClose={handleCloseDialog} jobsTechnologies={jobsTechnologies} handleAddTechnology={handleAddTechnology} handleRemoveTechnology={handleRemoveTechnology}/>
            default:
                break;
        }
    }

    const handleAddTechnology = (newData, fieldValue, setFieldValue) => {
        const title = jobsTechnologies.find(x => x._id === newData.value).name;

        setFieldValue('technologies', [...fieldValue, { _id: newData.value, title }]);
    }

    const handleRemoveTechnology = (newData, fieldValue, setFieldValue) => {
        const fieldValueFilterd = fieldValue.filter(x => x._id !== newData._id);

        setFieldValue('technologies', [...fieldValueFilterd]);
    }

    const updateProfileInfo = async (preferencesData, resetForm) => {
        await updateCandidatePreferences(agencyId, candidateId, preferencesData);
        resetForm();
        handleCloseDialog();
        setEditMode(false);
    };

    const handleOpenDialog = (dialogName) => {
        setDialog(dialogName)
    }

    const handleCloseDialog = () => setDialog('');

    const adjustProfileInfo = (profileInfo) => {
        const salaryValueNumber = +profileInfo.salaryValue;

        return {
            experienceSince: (<p>{profileInfo?.experienceSince || 'Not selected yet'}</p>),
            countryOfTax: (<p>{profileInfo?.countryOfTax || 'Not selected yet'}</p>),
            earliestDate: (
                profileInfo.earliestDate == null
                    ? <p>Not sure yet</p>
                    : <p>{moment(profileInfo.earliestDate).format(DATE_FORMAT)}</p>
            ),
            salaryValue: <p>{salaryValueNumber || profileInfo.salaryValue === 0 ? `${salaryValueNumber.toFixed(2)} ${profileInfo.salaryCurrency}` : 'Not sure yet'}</p>,
            jobType: <p>
                {
                    profileInfo.jobType === JOB_TYPES.DEVELOPER
                        ? 'Software Engineer'
                        : profileInfo.jobType || 'Not selected yet'
                }
            </p>,
            techStack: <p>
                {profileInfo.technologies.map((skill, index) =>
                    <>
                        {skill.title}
                        {index === profileInfo.technologies.length - 1 ? "" : ", "}
                    </>
                )}
            </p>
        }
    }

    const imageSelectedHandler = (e) => {
        const currentFile = e.target.files[0]
        const imgReader = new FileReader()

        imgReader.addEventListener("load", () => {
            const resultImg = imgReader.result
            setCurrentImgSelected(resultImg);
        }, false)

        imgReader.readAsDataURL(currentFile)

        setIsEditImgDialogOpen(true);
    };

    const handleCloseEditImgDialog = () => {
        setIsEditImgDialogOpen(false);
        setCurrentImgSelected(null);
        profileImgRef.current.value = "";
    }

    const handleUpdateAvatar = (imgData) => {
        updateAvatar(imgData, personalInfo._id, agencyId, null, userId);
    };

    const handleDeleteAvatar = () => {
        if (personalInfo?.profilePictureImgUrl && personalInfo?.profilePictureImgUrl !== '') {
            deleteAvatar(personalInfo._id, agencyId, null, userId);
            setCurrentImgSelected('');
        }
    }

    return (
        <div className={classes}>
            <div className="header-wrapper">
                <div className="header">
                    <div className="candidate-avatar-wrapper">
                        <Avatar className={`candidate-avatar ${isCandidateMode && 'darker'}`} src={personalInfo.profilePictureImgUrl} />
                        {(isCandidateMode || isAdminMode) &&
                            <div className="avatar-actions">
                                <IconButton className="avatar-delete-button icon-button" onClick={handleDeleteAvatar}>
                                    <Icon>
                                        <CloseIcon className="close-icon" />
                                    </Icon>
                                </IconButton>
                                <input
                                    accept="image/*"
                                    className="upload-input"
                                    id="upload-file-input"
                                    type="file"
                                    onChange={imageSelectedHandler}
                                    ref={profileImgRef}
                                />
                                <IconButton className="avatar-edit-button icon-button" onClick={() => { profileImgRef.current.click(); }}>
                                    <Icon>
                                        <EditSharpIcon className="edit-sharp-icon" />
                                    </Icon>
                                </IconButton>
                            </div>
                        }
                    </div>
                    <div className="header-info">
                        <Typography variant="h4" className="candidate-name">
                            {`${personalInfo.firstName} ${personalInfo.lastName}`}
                        </Typography>
                        <div className="candidate-email">
                            <EmailIcon className="email-icon" />
                            <Typography variant="h4" className="candidate-email-address">
                                {personalInfo.email}
                            </Typography>
                        </div>
                    </div>
                </div>
                <CandidateStatusIcon isAdminMode={isAdminMode} status={personalInfo.status} feedback={personalInfo?.publicCandidateFeedback} />
            </div>
            {
                ROWS_MAPPER.filter(rowAttributes => rowAttributes.VALUE !== 'techStack' || profileInfo.jobType === 'Developer').map((rowAttributes, i) => (
                    <div key={i} className={`preferences-row-wrapper ${i === ROWS_MAPPER.length - 1 ? 'last' : ''}`}>
                        <Divider variant="middle" className="divider" />
                        <div className="preferences-row">
                            <div className="row-title">
                                {rowAttributes.ICON}
                                <Typography variant="h5" className="preferences-title">
                                    {rowAttributes.TITLE}
                                </Typography>
                            </div>
                            <div className="row-value">
                                {adjustProfileInfo(profileInfo)[rowAttributes.VALUE]}
                            </div>
                            <div className="job-edit-icon-container">
                                {((rowAttributes.VALUE !== 'jobType' && isCandidateMode) || isAdminMode) &&
                                    <IconButton className="job-edit-button" onClick={() => handleOpenDialog(rowAttributes.VALUE)}>
                                        <Icon>
                                            <EditSharpIcon className="edit-sharp-icon" />
                                        </Icon>
                                    </IconButton>
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
            <CandidateProfileInfoFormik
                profileInfo={profileInfo}
                updateProfileInfo={updateProfileInfo}
                requiredField={REQUIRED_FIELDS[dialog]}
                positiveFields={POSITIVE_FIELDS[dialog]}>
                {(props) => (
                    currentlyOpenedDialog({ ...props, countries })
                )}
            </CandidateProfileInfoFormik>

            <ImageEditDialog
                dialogTitle="Edit Profile Picture"
                isDialogOpen={isEditImgDialogOpen}
                handleCloseDialog={handleCloseEditImgDialog}
                currentImgSelected={currentImgSelected}
                handleUpdateAvatar={handleUpdateAvatar}
                handleDeleteAvatar={handleDeleteAvatar}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    countries: getCountries(state),
    jobsTechnologies: getJobOpportunitiesTechnologies(state),
});

const mapDispatchToProps = {
    updateCandidatePreferences,
    deleteAvatar,
    updateAvatar,
    fetchJobOpportunitiesTechnologies,
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfileInfo);
