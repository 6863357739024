import React, { useState } from 'react';

import MainMenu from './MainMenu';

import { getSupplierMenuItems } from '../constants/supplierMenuConstants';

const SupplierMenu = ({ agencyRouteName }) => {
    const [supplierMenuItems] = useState(getSupplierMenuItems(agencyRouteName));

    return (
        <MainMenu
            menuItems={supplierMenuItems}
        />
    )
};

export default SupplierMenu;
