import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const PasswordInput = ({
    className,
    name,
    handleChange,
    label,
    id,
    error,
    touched,
    handleBlur
}) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    }
  
    return (
        <TextField
            fullWidth
            required
            className={className}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            type={passwordVisibility ? 'text' : 'password'}
            id={id}
            label={label}
            error={error && touched}
            margin="normal"
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordVisibility}
                            style={{padding: "0"}}
                        >
                            {passwordVisibility ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
};

export default PasswordInput;
