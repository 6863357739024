import React from 'react';

import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import { ReactComponent as StartCandidateJourneyIcon } from 'assets/start-candidate-journey-icon.svg';
import WhatToExpect from '../WhatToExpect';

const MobilePageWithoutJob = ({
    getCandidateJourneyDuration,
    START_JOURNEY_STEPS,
    handleStartCandidateJourney,
    agencyName,
}) => (
    <div className="start-candidate-journey-mobile-wrapper">
        <PaperElement title={`Thank you for joining ${agencyName}.`}>
            <p className='thank-you-for-joining-sub-header'>You can now browse and apply for remote software jobs and connect with our growing tech community.</p>
        </PaperElement>
        <PaperElement classes={['start-candidate-journey-page', 'max']} paperContentClasses={["paper-content-centered"]}>
            <div className="start-candidate-journey-steps-container">
                <div className="start-candidate-journey-icon-container">
                    <StartCandidateJourneyIcon className="start-candidate-journey-icon" />
                </div>
                <WhatToExpect
                    getCandidateJourneyDuration={getCandidateJourneyDuration}
                    START_JOURNEY_STEPS={START_JOURNEY_STEPS}
                />
            </div>
        </PaperElement >
        <div className="journey-buttons-wrapper">
            <PrimaryButton
                text="Start"
                handleClick={handleStartCandidateJourney}
            />
        </div>
    </div>
);

export default MobilePageWithoutJob;
