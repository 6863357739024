import storage from 'redux-persist/lib/storage';
import { createTransform } from 'redux-persist';
import { APP_VERSION } from '../constants/env';

const authMigrate = (state) => {
    try {
        const currentVersion = localStorage.getItem('app-version');

        if (currentVersion !== APP_VERSION) {
            localStorage.removeItem('state'); // remove old persist state
            localStorage.setItem('app-version', APP_VERSION);

            return Promise.resolve(undefined);
        }

        return Promise.resolve(state);
    } catch (err) {
        return Promise.resolve(undefined);
    }
}

export const PERSIST_UI_CONFIG = {
    key: 'ui',
    storage,
}

export const PERSIST_AUTH_CONFIG = {
    key: 'auth',
    storage,
    migrate: authMigrate
}

export const PERSIST_MENU_CONFIG = {
    key: 'menu',
    storage,
}

export const PERSIST_PERSONAL_INFORMATION_CONFIG = {
    key: 'personalInformation',
    storage,
    whitelist: ['uploadedCv']
};

export const PERSIST_JOB_OPPORTUNITIES_CONFIG = {
    key: 'jobTechnologies',
    storage,
    whitelist: ['jobOpportunitiesTechnologies', 'jobOpportunityDetails']
};

export const PERSIST_AGENCY_INFORMATION_CONFIG = {
    key: 'agencyName',
    storage,
    whitelist: ['name']
};

export const PERSIST_CLIENT_CONFIG = {
    key: 'client',
    storage,
    blacklist: ['contracts', 'invoices', 'projects', 'tasks', 'projectsFeedback']
};

const transform = createTransform(
    (inboundState, key) => {
        if (key === 'personalInformation') {
            return { firstName: inboundState.firstName, lastName: inboundState.lastName, status: inboundState.status, uploadedCv: inboundState.uploadedCv }
        } else if (key === 'personalProjects') {
            return { list: inboundState.list }
        } else if (key === 'employmentInformation') {
            return {
                generalInfo:
                {
                    position: inboundState.generalInfo?.position,
                    experienceSince: inboundState.generalInfo?.experienceSince,
                    profileSummary: inboundState.generalInfo?.profileSummary
                }
            }
        }

        return inboundState;
    },
    (outboundState, key) => {
        if (key === 'personalInformation') {
            return { firstName: outboundState.firstName, lastName: outboundState.lastName, status: outboundState.status, uploadedCv: outboundState.uploadedCv }
        } else if (key === 'personalProjects') {
            return { list: outboundState.list }
        } else if (key === 'employmentInformation') {
            return {
                generalInfo:
                {
                    position: outboundState.generalInfo?.position,
                    experienceSince: outboundState.generalInfo?.experienceSince,
                    profileSummary: outboundState.generalInfo?.profileSummary
                }
            }
        }

        return outboundState;
    },
    { whitelist: ['personalInformation', 'employmentInformation', 'languages', 'profileSkills', 'educationList', 'personalProjects', 'certificates', 'candidatePreferences', 'lastViewedJob'] }
);

export const PERSIST_EMPLOYEE_CONFIG = {
    key: 'employee',
    storage,
    whitelist: ['personalInformation', 'employmentInformation', 'languages', 'profileSkills', 'educationList', 'personalProjects', 'certificates', 'candidatePreferences', 'lastViewedJob'],
    transforms: [transform]
};

export const PERSIST_MANAGER_CONFIG = {
    key: 'manager',
    storage,
    whitelist: ['pendingHolidayRequestsCount'],
};
