import React from 'react';
import { useHistory } from "react-router-dom";

import { getStateAgencyName } from 'utils/helpers';
import EmployeeProjectCard from './EmployeeProjectCard';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { ROLES } from 'constants/userConstants';

import './EmployeeProjectCardList.scss';

const EmployeeProjectCardList = ({
    projects,
    displayMode
}) => {
    const history = useHistory();

    const handleProjectCardClick = (projectId, clientId) => {
        if (displayMode !== ROLES.EMPLOYEE && displayMode !== ROLES.SUPPLIER_EMPLOYEE) {
            history.push(`/${getStateAgencyName()}/projects/${projectId}/clients/${clientId}`);
        }
    }

    return (
        <div>
            <div className="projects-wrapper all-tasks">
                {projects && projects.length
                    ? <div>
                        <SectionActionsWrapper>
                            <SectionActionsWrapper.SectionTitle
                                sectionTitle='Projects'
                            />
                        </SectionActionsWrapper>
                        <div className="projects-cards-wrapper">
                            {projects && projects.map(project => {
                                return (
                                    <EmployeeProjectCard
                                        displayMode={displayMode}
                                        key={project._id}
                                        project={project}
                                        handleProjectCardClick={() => handleProjectCardClick(project._id, project.clientId)} />
                                )
                            })}
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    )
};

export default EmployeeProjectCardList;
