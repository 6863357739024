import api from './api';
import requester from './requester';

const coachingOpportunitiesService = {
    create: (agencyId, formData) => requester(api.coachingOpportunities(agencyId)).uploadFile(formData),
    edit: (agencyId, coachingOpportunityId, formData) => requester(api.coachingOpportunity(agencyId, coachingOpportunityId)).updateFile(formData),
    delete: (agencyId, coachingOpportunityId) => requester(api.coachingOpportunity(agencyId, coachingOpportunityId)).delete(),
    createSession: (agencyId, employeeId, coachingOpportunityId, eventUri) => requester(api.employeeCoachingOpportunitySessions(agencyId, employeeId, coachingOpportunityId)).create({ eventUri }),
    fetchEmployeeCoachingSessions: (agencyId, employeeId, upcoming) => requester(api.employeeCoachingSessions(agencyId, employeeId, upcoming)).getMany(),
    fetchEmployeeCoachingOpportunitySessions: (agencyId, employeeId, coachingOpportunityId, upcoming) => requester(api.employeeCoachingOpportunitySessions(agencyId, employeeId, coachingOpportunityId, upcoming)).getMany(),
    fetchTagsSuggestions: (agencyId, inputText) => requester(api.tagsSuggestions(agencyId, inputText)).getMany(),
    fetchCoachingOpportunities: (agencyId, displayMode) => requester(api.coachingOpportunities(agencyId, displayMode)).getMany(),
    fetchCoachingOpportunity: (agencyId, coachingOpportunityId) => requester(api.coachingOpportunity(agencyId, coachingOpportunityId)).getOne(),
    fetchAgencyCoachingOpportunityDetailsPage: (agencyId, coachingOpportunityId) => requester(api.agencyCoachingOpportunityPage(agencyId, coachingOpportunityId)).getOne(),
    exportBookedSessions: (agencyId, coachingOpportunityId) => requester(api.bookedSessionsExport(agencyId, coachingOpportunityId)).saveAsAction('booked-sessions'),
    fetchCoachingOpportunitiesSessions: (agencyId, upcoming) => requester(api.coachingOpportunitiesSessions(agencyId, upcoming)).getMany(),
};

export default coachingOpportunitiesService;
