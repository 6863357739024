import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
    getEmployeeCvInformation,
    getCompanyLogoUrl,
    isCandidateMode,
    isAdminMode,
    isSupplierAdminMode,
    isEmployeeMode,
    isSupplierEmployeeMode,
    getEmployeeUploadedCv,
    getDisplayMode,
    getCvPageUpdateState,
} from 'reducers';

import { exportCV, updateEmployeeCv, downloadEmployeeCv } from 'actions/employeeActions';
import { exportSupplierEmployeesCVs } from 'actions/supplierActions';
import { exportCandidateCV } from 'actions/candidateActions';

import { checkForFilledCvSections } from 'utils/cvUtils';

import CircularProgress from '@material-ui/core/CircularProgress';
import CvSection from 'components/Shared/CvSection/CvSection';
import UploadCv from 'components/Shared/UploadCv';

import './ProfileCvSection.scss';

const ProfileCvSection = ({
    employeeId,
    agencyId,
    cvInformation,
    isCandidateMode,
    companyLogoUrl,
    isAdminMode,
    isSupplierAdminMode,
    isEmployeeMode,
    isSupplierEmployeeMode,
    uploadedCv,
    displayMode,
    exportCV,
    exportSupplierEmployeesCVs,
    exportCandidateCV,
    updateEmployeeCv,
    downloadEmployeeCv,
    dataFetchState,
    isEmployee,
    dataUpdateState,
}) => {
    const [isEditMode, setEditMode] = useState(false);
    const [uploads, setUploads] = useState({});

    const scrollToRef = useRef(null);

    const hasFilledCvSection = checkForFilledCvSections(cvInformation);
    const showUploadCv = !uploadedCv.originalFileName && !hasFilledCvSection && !isEditMode;

    const exportCVsFunctions = {
        'admin': exportCV,
        'supplier-admin': exportSupplierEmployeesCVs,
        'candidate': exportCandidateCV,
        'employee': exportCV,
        'supplier-employee': exportSupplierEmployeesCVs,
    }

    const enterEditMode = () => {
        setEditMode(true);
    }

    const exitEditMode = () => {
        setEditMode(false);
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView();
        }
    };

    const handleExportCV = (users) => {
        exportCVsFunctions[displayMode](users, agencyId);
    };

    const handleDownloadCV = () => {
        const fileName = uploadedCv?.originalFileName.split('.').slice(0, -1).join('.')
        downloadEmployeeCv(agencyId, employeeId, fileName);
    };

    return (
        <div className="profile-cv-sections">{
            dataFetchState.isDataFetching || dataUpdateState?.isDataUpdating
                ? <CircularProgress size={50} disableShrink className="page-loader" />
                : showUploadCv
                    ? <UploadCv
                        agencyId={agencyId}
                        userId={employeeId}
                        uploads={uploads}
                        setUploads={setUploads}
                        enterEditMode={enterEditMode}
                        isEmployeeMode={isEmployeeMode}
                        isSupplierEmployeeMode={isSupplierEmployeeMode}
                    />
                    : <CvSection
                        userId={employeeId}
                        agencyId={agencyId}
                        isCandidateProfilePage={true}
                        cvInformation={cvInformation}
                        enterEditMode={enterEditMode}
                        exitEditMode={exitEditMode}
                        isEditMode={isEditMode}
                        exportCandidateCV={handleExportCV}
                        companyLogoUrl={companyLogoUrl}
                        updateCandidateJourneyCvPage={updateEmployeeCv}
                        isCandidateMode={isCandidateMode}
                        isAdminMode={isAdminMode}
                        isSupplierAdminMode={isSupplierAdminMode}
                        isEmployeeMode={isEmployeeMode}
                        isSupplierEmployeeMode={isSupplierEmployeeMode}
                        uploadedCv={uploadedCv}
                        scrollToRef={scrollToRef}
                        handleDownloadCV={handleDownloadCV}
                        isEmployee={isEmployee}
                    />
        }
        </div>
    )
};

const mapStateToProps = state => ({
    cvInformation: getEmployeeCvInformation(state),
    companyLogoUrl: getCompanyLogoUrl(state),
    isCandidateMode: isCandidateMode(state),
    isAdminMode: isAdminMode(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    isEmployeeMode: isEmployeeMode(state),
    isSupplierEmployeeMode: isSupplierEmployeeMode(state),
    uploadedCv: getEmployeeUploadedCv(state),
    displayMode: getDisplayMode(state),
    dataUpdateState: getCvPageUpdateState(state),
});

const mapDispatchToProps = {
    exportCV,
    exportSupplierEmployeesCVs,
    exportCandidateCV,
    updateEmployeeCv,
    downloadEmployeeCv,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCvSection);
