import { connect } from 'react-redux';

import { getAgencyId, getBusinessTrips } from 'reducers';

import {
    fetchBusinessTrips,
    downloadBusinessTripFiles,
    exportBusinessTrips,
} from 'actions/businessTripActions';

import ExpenseReportsTable from './ExpenseReportsTable';

import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

const ExpenseReports = ({
    agencyId,
    fetchBusinessTrips,
    downloadBusinessTripFiles,
    exportBusinessTrips,
}) => {
    const action = fetchBusinessTrips.bind(null, agencyId);
    const dataSelector = (state, page) => getBusinessTrips(state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    return (
        <div className="expense-reports-wrapper">
            <ExpenseReportsTable
                agencyId={agencyId}
                downloadBusinessTripFiles={downloadBusinessTripFiles}
                exportBusinessTrips={exportBusinessTrips}
                dataSelector={dataSelector}
                action={action}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    agencyId: getAgencyId(state)
});

const mapDispatchToProps = {
    fetchBusinessTrips,
    downloadBusinessTripFiles,
    exportBusinessTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseReports);
