import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { getCountries } from 'reducers';

import { fetchAllCountries } from 'actions/staticDataActions';

import RoleSelection from './RoleSelection';
import FillInformation from './FillInformation';
import CardMedia from '@material-ui/core/CardMedia';

import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back-button.svg';

import { BASE_APP_TRADEMARK } from 'constants/env';

import './ChooseUsage.scss';

const ChooseUsage = ({
    location,
    match,
    countries,
    fetchAllCountries,
}) => {
    const showBackButton = location.pathname !== match.path;

    const history = useHistory();

    const handleBackClick = () => history.push('/select-a-role');

    useEffect(() => {
        fetchAllCountries();
    }, [])

    return (
        <div className={`select-a-role-wrapper${isMobile ? ' mobile' : ''}`}>
            {showBackButton &&
                <div className="back-button-wrapper" onClick={handleBackClick}>
                    <ArrowBackIcon />
                    <span>Back</span>
                </div>
            }
            <div className="select-a-role-content">
                <div className="logo-card-media-wrapper">
                    <CardMedia
                        className="logo-card-media"
                        component="img"
                        image={BASE_APP_TRADEMARK.LOGO}
                    />
                </div>
                <Switch>
                    <Route exact path={`${match.path}`} render={(props) => (
                        <RoleSelection {...props} />
                    )} />

                    <Route exact path={`${match.path}/client`} render={(props) => (
                        <FillInformation {...props} countries={countries} />
                    )} />
                </Switch>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseUsage);
