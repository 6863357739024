import React from 'react';
import { ErrorMessage } from 'formik';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import PasswordInput from 'components/Common/PasswordInput';
import SignUpFooter from 'components/Shared/SignUpFooter';
import TermsAndConditionsCheckBox from 'components/Shared/TermsAndConditionsCheckBox';

import { ROLES } from 'constants/userConstants';
import { IS_WHOISAVAILABLE_ENV } from 'constants/env';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { motionRedirectLinks } from '../RegisterConstants';

import { getHomePath } from 'utils/navigationUtils';
import { signInWithPopup } from 'utils/firebase';
import { logEvent } from 'utils/amplitude';

import './RegisterFormView.scss';

const RegisterFormView = ({
    blur,
    countries,
    referrer,
    campaignId,
    login,
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
    fetchUserInformationSuccess,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
}) => {
    const history = useHistory();

    const handleRegisterWithSSO = (event) => {
        event.preventDefault();
        /*
        This will prevent redirecting from the isGuest component,
        so we can redirect manually after successful login.
        */
        history.replace({ state: { isSSORegisterClient: true } });
        showApplicationLoader();
        signInWithPopup(null, null, referrer, campaignId, null, true).then(async (user) => {
            user.isNew && logEvent(AMPLITUDE_EVENT_TYPES.REGISTER, null, { role: ROLES.CLIENT, type: 'SSO' });
            await login(user.email, null, false, false, true, user.credential);
            fetchUserInformationSuccess({ preselectedRole: ROLES.UNASSIGNED_CLIENT, user: {} });
            const homePath = getHomePath(history.location.state?.gotoUrl);
            history.push({ pathname: homePath.pathname, state: { ...history.location.state, ...homePath.state } });
        }).catch(err => {
            createSSOLoggingRecord({ message: err.message });
        }).finally(() => {
            hideApplicationLoader();
        });
    };

    return (
        <form className={`register-form${blur ? ' blur' : ''}`} autoComplete="off">
            <div className='account-name-field'>
                <TextField
                    required
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    label='Company Name'
                    onBlur={handleBlur}
                    error={errors.name && touched.name}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
                <ErrorMessage name='name' component='div' className='invalid-field-message' />
            </div>
            <div className='email-field'>
                <TextField
                    required
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    label='Email'
                    error={errors.email && touched.email}
                    onBlur={handleBlur}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
                <ErrorMessage name='email' component='div' className='invalid-field-message' />
            </div>
            <div>
                <PasswordInput
                    className="login-field"
                    name="password"
                    handleChange={handleChange}
                    label="Password"
                    id="pwd-set"
                    error={errors.password}
                    touched={touched.password}
                />
                <ErrorMessage name="password" component="div" className="invalid-field-message" />
            </div>
            <div>
                <PasswordInput
                    className="login-field"
                    name="confirmPassword"
                    handleChange={handleChange}
                    label="Confirm Password"
                    id="pwd-set-confirm"
                    error={errors.confirmPassword}
                    touched={touched.confirmPassword}
                />
                <ErrorMessage name="confirmPassword" component="div" className="invalid-field-message" />
            </div>
            <div className="country-field">
                <Autocomplete
                    id="hqLocation"
                    value={values.country}
                    onChange={(event, newValue) => setFieldValue('country', newValue)}
                    name='country'
                    options={countries.map(country => country.name)}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    renderOption={(option) => (
                        <span>{option}</span>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onBlur={handleBlur}
                            label='HQ Location'
                            margin='normal'
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                        />
                    )}
                />

                <ErrorMessage name="country" component="div" className="invalid-field-message" />
            </div>

            <div className='client-final-block'>
                <TextField
                    required
                    name='contactPerson'
                    value={values.contactPerson}
                    onChange={handleChange}
                    label='Your Name'
                    onBlur={handleBlur}
                    error={errors.contactPerson && touched.contactPerson}
                    margin='normal'
                    variant='outlined'
                    fullWidth
                />
                <ErrorMessage name='contactPerson' component='div' className='invalid-field-message' />
            </div>
            {!IS_WHOISAVAILABLE_ENV &&
                <div>
                    <TermsAndConditionsCheckBox
                        isTermsAndConditionsAccepted={values.isTermsAndConditionsAccepted}
                        handleChange={handleChange}
                    />
                    <ErrorMessage name="isTermsAndConditionsAccepted" component="div" className="invalid-field-message" />
                </div>
            }
            <SignUpFooter
                handleSubmit={handleSubmit}
                label={motionRedirectLinks.login.label}
                link={motionRedirectLinks.login.link}
                handleRegisterWithSSO={handleRegisterWithSSO}
            />
        </form>
    )
}
export default RegisterFormView;
