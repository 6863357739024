import React, { useState } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import ProfileInfoRow from '../ProfileInfoRow';
import AddEditCertificateDialog from './AddEditCertificateDialog';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

import './ProfileCertificates.scss';

import { addCertificate, editCertificate, removeCertificate, updateEmployeeCertificates } from 'actions/employeeActions';
import { getCertificates } from 'reducers';

const ProfileCertificates = ({
    employeeId,
    agencyId,
    departmentId,
    certificates,
    addCertificate,
    updateEmployeeCertificates,
    editCertificate,
    removeCertificate,
}) => {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [certificateToRemove, setCertificateToRemove] = useState({});
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const handleCertificateDrag = async result => {
        await updateEmployeeCertificates(result, employeeId, agencyId);
    };

    const handleOpenAddDialog = () => setOpenAddDialog(true);

    const handleCloseAddDialog = () => {
        setSelectedCertificate(null);
        setOpenAddDialog(false);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setCertificateToRemove({});
    }

    const handleRemoveClick = (index) => {
        setOpenConfirmDialog(true);
        setCertificateToRemove(certificates[index]);
    };

    const handleEditClick = certificate => {
        setSelectedCertificate(certificate);
        setOpenAddDialog(true);
    };

    const handleAddCertificate = certificate => {
        addCertificate(certificate, employeeId, agencyId, departmentId);
    };

    const handleRemoveCertificate = id => {
        removeCertificate(id, employeeId, agencyId, departmentId);
        setOpenConfirmDialog(false);
    };

    const handleEditCertificate = certificate => {
        editCertificate(certificate, employeeId, agencyId, departmentId);
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        handleCertificateDrag(arrayMove(certificates, oldIndex, newIndex))
    };

    const SortableItem = SortableElement(({ index, item, onRemove, editItem }) => {
        return (
            <ProfileInfoRow
                item={item}
                shouldBeDraggable={true}
                removeItem={() => onRemove(index)}
                editItem={editItem}
            />
        )
    });

    const SortableList = SortableContainer(({ items, onRemove, editItem }) => {
        return (
            <div className="profile-info-content profile-certificates-rows-wrapper">
                {items.map((value, index) => {
                    return (
                        <SortableItem
                            key={`item-${index}`}
                            index={index}
                            onRemove={() => { onRemove(index) }}
                            item={value}
                            editItem={editItem}
                        />
                    )
                })}
            </div>
        );
    });

    return (
        <div className="profile-info-wrapper profile-certificates-info-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Certificates'
                />

                <SectionActionsWrapper.RightAlignedActions>
                    <SectionActionsWrapper.RightAlignedActions.PrimaryAddButton
                        text='Add'
                        onClick={handleOpenAddDialog}
                    />
                </SectionActionsWrapper.RightAlignedActions>
            </SectionActionsWrapper>

            <Paper className="profile-info-content profile-certificate-rows-wrapper">
                <SortableList
                    axis="y"
                    items={certificates}
                    onRemove={(index) => handleRemoveClick(index)}
                    onSortEnd={onSortEnd}
                    editItem={handleEditClick}
                    distance={2}
                />
            </Paper>

            <AddEditCertificateDialog
                certificate={selectedCertificate}
                openDialog={openAddDialog}
                handleCloseDialog={handleCloseAddDialog}
                handleAddCertificate={handleAddCertificate}
                handleEditCertificate={handleEditCertificate}
            />

            <ConfirmDeleteDialog
                itemToDelete={certificateToRemove.title}
                handleDeleteItem={() => handleRemoveCertificate(certificateToRemove._id)}
                openDialog={openConfirmDialog}
                handleCloseDialog={handleCloseConfirmDialog}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    certificates: getCertificates(state).slice(),
});

const mapDispatchToProps = {
    addCertificate,
    editCertificate,
    removeCertificate,
    updateEmployeeCertificates
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCertificates);
