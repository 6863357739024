import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchEmployeeTeam } from 'actions/employeeActions';
import { getEmployeeTeam, getAgencyRouteName } from 'reducers';

import KnowYourTeamCard from 'components/Dashboard/KnowYourTeam/KnowYourTeamCard';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import './MeetYourTeam.scss';

const MeetYourTeam = ({
    userId,
    agencyId,
    fetchEmployeeTeam,
    employeeTeam,
    history,
    agencyRouteName,
}) => {
    useEffect(() => {
        fetchEmployeeTeam(agencyId, userId);
    }, []);

    return (
        <div className="employee-team-section">
            <PrimaryButton 
                className="back-to-dashboard-btn"
                handleClick={() => history.push(`/${agencyRouteName}/dashboard`)}
                text="Back To Dashboard"
            />
            <div className="employee-team-cards-wrapper">
                {employeeTeam.map(employee => <KnowYourTeamCard key={employee._id} employee={employee} />)}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => ({
    employeeTeam: getEmployeeTeam(state),
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchToProps = {
    fetchEmployeeTeam,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetYourTeam);
