import React, { useRef, useState, useEffect } from 'react';

import { ErrorMessage, Form } from 'formik';

import TextField from '@material-ui/core/TextField';
import { Avatar, Icon, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import { Autocomplete } from '@material-ui/lab';

import { useDebounce } from 'utils/hooks';

import { ReactComponent as UploadSvg } from 'assets/upload.svg';

import './CoachingDialog.scss';

let tagsSuggestions = [];

const CoachingDialogFormView = ({
    agencyId,
    values,
    touched,
    errors,
    handleChange,
    setFieldValue,
    tags,
    handleAddTag,
    handleRemoveTag,
    languages,
    languagesSuggestions,
    handleAddLanguage,
    handleRemoveLanguage,
    imageSelectedHandler,
    currentImageSelected,
    setCurrentImgSelected,
    fetchTagsSuggestions,
    fetchStaticLanguagesSuggestions,
}) => {
    const [showIcons, setShowIcons] = useState(false);
    const [inputValueTag, setInputValueTag] = useState('');
    const [inputValueLanguage, setInputValueLanguage] = useState('');

    const debouncedInputValueTag = useDebounce(inputValueTag, 500);
    const debouncedInputValueLanguage = useDebounce(inputValueLanguage, 500);

    useEffect(() => {
        if (debouncedInputValueTag) {
            fetchTagsSuggestions(agencyId, debouncedInputValueTag).then(result => {
                tagsSuggestions = result.map(x => x.title) || [];
            });
        }
    }, [debouncedInputValueTag]);

    useEffect(() => {
        fetchStaticLanguagesSuggestions(debouncedInputValueLanguage);
    }, [debouncedInputValueLanguage]);

    const imageRef = useRef();

    return (
        <Form className="coaching-form" autoComplete="false">
            <div className="avatar-actions" onMouseEnter={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
                <input
                    accept="image/*"
                    className="upload-input"
                    id="upload-file-input"
                    type="file"
                    onChange={imageSelectedHandler}
                    ref={imageRef}
                />
                { currentImageSelected 
                    ? 
                    <>
                        <Avatar className="coach-avatar" src={currentImageSelected} />
                        { showIcons && 
                            <div className="icons-container" >
                                <IconButton className="avatar-delete-button icon-button" onClick={() => setCurrentImgSelected(null)}>
                                    <Icon>
                                        <CloseIcon className="close-icon" />
                                    </Icon>
                                </IconButton>
                                <IconButton className="avatar-edit-button icon-button" onClick={() => { imageRef.current.click() }}>
                                    <Icon>
                                        <EditSharpIcon className="edit-sharp-icon" />
                                    </Icon>
                                </IconButton>
                            </div>
                        }
                    </>
                    : 
                    <label htmlFor= "upload-file-input" className="upload-image">
                        <UploadSvg />
                    </label>
                
                }
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    className="add-session-field"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    id="add-session-name"
                    label="Name of the Coaching Session"
                    error={errors.title && touched.title}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="title" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    multiline
                    className="add-about-the-session-field"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    id="about-the-session"
                    label="About the Coaching Session"
                    error={errors.description && touched.description}
                    margin="normal"
                    variant="outlined"
                    minRows={3}
                />
                <ErrorMessage name="description" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    className="add-coach-field"
                    name="coachName"
                    value={values.coachName}
                    onChange={handleChange}
                    id="add-coach-name"
                    label="Name of the Coach"
                    error={errors.coachName && touched.coachName}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="coachName" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    multiline
                    className="add-about-the-coach-field"
                    name="aboutTheCoach"
                    value={values.aboutTheCoach}
                    onChange={handleChange}
                    id="add-about-the-coach-name"
                    label="About the Coach"
                    error={errors.aboutTheCoach && touched.aboutTheCoach}
                    margin="normal"
                    variant="outlined"
                    minRows={3}
                />
                <ErrorMessage name="aboutTheCoach" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <Typography className="form-sub-title">Languages</Typography>

                <Autocomplete
                    options={languagesSuggestions}
                    selectOnFocus
                    getOptionLabel={(option) => option}
                    onChange={(_, value) => handleAddLanguage(value)}
                    noOptionsText={values.language && !languagesSuggestions.find(x => x === values.language)
                        ? <div className="add-new-language-option" onMouseDown={() => handleAddLanguage(values.language)}>{`Add language - "${values.language}"`}</div>
                        : 'No options'
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={`${touched.language && errors.language ? "with-error" : "base-input"}`}
                            value={values.language}
                            onBlur={() => {
                                setFieldValue('language', '');
                                setInputValueLanguage('');
                            }}
                            label="Add Language"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            error={touched.language && Boolean(errors.language)}
                            helperText={touched.language && errors.language}
                            onChange={(e) => {
                                setFieldValue('language', e.target.value);
                                setInputValueLanguage(e.target.value);
                            }}
                        />
                    )}
                />
            </div>
            <div className="skills-wrapper">
                <div className="skills-container">
                    {languages.map(language => (
                        <div key={language} className="skill-box global-css-skill-box">
                            <span className="skill-name">{language}</span>
                            <button className="delete-skill-icon" type="button" onClick={() => handleRemoveLanguage(language)} ></button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="form-row">
                <Typography className="form-sub-title">Tags</Typography>

                <Autocomplete
                    options={tagsSuggestions}
                    selectOnFocus
                    getOptionLabel={(option) => option}
                    onChange={(_, value) => handleAddTag(value)}
                    noOptionsText={values.tag && !tagsSuggestions.find(x => x === values.tag)
                        ? <div className="add-new-tag-option" onMouseDown={() => handleAddTag(values.tag, true)}>{`Add tag - "${values.tag}"`}</div>
                        : 'No options'
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={`${touched.tag && errors.tag ? "with-error" : "base-input"}`}
                            value={values.tag}
                            onBlur={() => {
                                setFieldValue('tag', '');
                                setInputValueTag('');
                                tagsSuggestions = [];
                            }}
                            label="Add Tag"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            error={touched.tag && Boolean(errors.tag)}
                            helperText={touched.tag && errors.tag}
                            onChange={(e) => {
                                setFieldValue('tag', e.target.value);
                                setInputValueTag(e.target.value);
                            }}
                        />
                    )}
                />
            </div>
            <div className="skills-wrapper">
                <div className="skills-container">
                    {tags.map(tag => (
                        <div key={tag._id} className="skill-box global-css-skill-box">
                            <span className="skill-name">{tag.title}</span>
                            <button className="delete-skill-icon" type="button" onClick={() => handleRemoveTag(tag.title)} ></button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="form-row">
                <Typography>Calendly Info</Typography>
                <TextField
                    required
                    fullWidth
                    className="add-calenldy-url-field"
                    name="calendlyUrl"
                    value={values.calendlyUrl}
                    onChange={handleChange}
                    id="add-calenldy-url"
                    label="Calendly Event Link"
                    error={errors.calendlyUrl && touched.calendlyUrl}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="calendlyUrl" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    className="add-calenldy-api-key-field"
                    name="calendlyApiKey"
                    value={values.calendlyApiKey}
                    onChange={handleChange}
                    id="add-calenldy-api-key"
                    label="Calendly Api Key"
                    error={errors.calendlyApiKey && touched.calendlyApiKey}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="calendlyApiKey" component="div" className="invalid-field-message" />
            </div>
            <div className="form-row">
                <TextField
                    required
                    fullWidth
                    className="add-calenldy-access-token-field"
                    name="calendlyPersonalAccessToken"
                    value={values.calendlyPersonalAccessToken}
                    onChange={handleChange}
                    id="add-calenldy-access-token"
                    label="Calendly Access Token"
                    error={errors.calendlyPersonalAccessToken && touched.calendlyPersonalAccessToken}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="calendlyPersonalAccessToken" component="div" className="invalid-field-message" />
            </div>
        </Form>
    )
};

export default CoachingDialogFormView;
