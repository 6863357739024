import React from 'react';

import UpcomingInterviewsCard from './UpcomingInterviewCard';

import './UpcomingInterviews.scss';

const UpcomingInterviews = ({
    interviewInvitations
}) => {
    return (
        <>
            {interviewInvitations.length > 0 &&
                <div className="upcoming-interviews-wrapper">
                    <div className="upcoming-interviews-title">
                        Upcoming Interviews
                    </div>
                    <div className="upcoming-interviews-container">
                        {
                            interviewInvitations.map((interview) =>
                                <UpcomingInterviewsCard
                                    interview={interview}
                                    key={interview._id}
                                />
                            )
                        }
                    </div>
                </div>
            }
        </>
    )
};

export default UpcomingInterviews;
