import React, { useState, useRef } from 'react';

import Paper from '@material-ui/core/Paper';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import CompanyInformationAccountManagerFormView from './CompanyInformationAccountManagerFormView';
import { Formik } from 'formik';

import { validateRequiredFields } from 'utils/formValidations';

import './CompanyInformationAccountManagerForm.scss';

const CompanyInformationAccountManagerForm = ({
    saveInvoiceInfo,
    updateAccountManagerInformation,
    accountManager,
    accountManagers,
    supplierInvoiceStatus,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const accountManagerFormRef = useRef();

    const discardHandlerAccountManagerInfo = () => {
        setShowHeaderButtons(false);
        accountManagerFormRef.current.values.accountManager = accountManager?._id || '';
    };

    return (
        <div className="company-information-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Account Manager'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={() => discardHandlerAccountManagerInfo()}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => accountManagerFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}
            </SectionActionsWrapper>

            <Paper className='company-information-content-wrapper'>
                <Formik
                    initialValues={{
                        accountManager: accountManager?._id || '',
                        status: supplierInvoiceStatus || '',
                    }}
                    validate={values => {
                        return {
                            ...validateRequiredFields(values, ['accountManager', 'status']),
                        }
                    }}
                    innerRef={accountManagerFormRef}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);

                        const accountManagerInfo = { accountManagerId: values.accountManager, invoiceStatus: values.status };
                        saveInvoiceInfo(accountManagerInfo, updateAccountManagerInformation, resetForm);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <CompanyInformationAccountManagerFormView {...props}
                            handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                            accountManagers={accountManagers} />}
                </Formik>
            </Paper>
        </div>
    )
}

export default CompanyInformationAccountManagerForm;
