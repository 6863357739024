import React, { useState } from 'react';

import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@material-ui/core';

import { ReactComponent as EditIcon } from 'assets/jobs-edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/jobs-delete-icon.svg';

import { COACHING_OPPORTUNITY_STATUSES, COACHING_OPPORTUNITY_STATUSES_LABELS } from 'components/Settings/Coaching/CoachingConstants';

import './CoachingOpportunityPopover.scss';

const CoachingOpportunityPopover = ({
    handleClose,
    anchorEl,
    onEditClick,
    selectedOpportunity,
    updateStatus,
    openDeleteDialog,
}) => {
    const [status, setStatus] = useState(selectedOpportunity.status ? selectedOpportunity.status.toLowerCase() : '')

    const changeStatusToActive = (event) => {
        setStatus(COACHING_OPPORTUNITY_STATUSES_LABELS.ACTIVE);
        updateStatus(COACHING_OPPORTUNITY_STATUSES.ACTIVE);
        handleClose(event);
    };

    const changeStatusToInactive = (event) => {
        setStatus(COACHING_OPPORTUNITY_STATUSES_LABELS.INACTIVE);
        updateStatus(COACHING_OPPORTUNITY_STATUSES.INACTIVE);
        handleClose(event);
    };

    return (
        <div className="popover-container">
            <Popover
                className="options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List className="popover-list">
                    <ListItem button className="popover-list-item" onClick={(event) => onEditClick(event, selectedOpportunity)}>
                        <Typography className="list-item-text" />
                        <span className="popover-icon"><EditIcon /></span>
                        <span className="popover-text">Edit</span>
                        <Typography />
                    </ListItem>
                    <ListItem button className="popover-list-item" onClick={openDeleteDialog}>
                        <Typography className="list-item-text" />
                        <span className="popover-icon"><DeleteIcon /></span>
                        <span className="popover-text">Delete</span>
                        <Typography />
                    </ListItem>
                    <div className="status-options">
                        <button onClick={changeStatusToActive} className={`${status === COACHING_OPPORTUNITY_STATUSES_LABELS.INACTIVE ? 'white-bg' : 'green-bg'}`}>Active</button>
                        <button onClick={changeStatusToInactive} className={`${status === COACHING_OPPORTUNITY_STATUSES_LABELS.INACTIVE ? 'grey-bg' : 'white-bg'}`}>Inactive</button>
                    </div>
                </List>
            </Popover>
        </div>
    );
};

export default CoachingOpportunityPopover;
