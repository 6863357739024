import { BASE_URL } from 'constants/env';
import store from 'utils/store';
import { saveAs } from 'file-saver';
import { refreshToken as refreshTokenAction, deniedAccessRedirect } from 'actions/authActions';

import { FILE_TYPE_MAPPER, EMPTY_FILE_TYPE } from 'constants/typeConstants';

import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';
import { INVALID_SESSION_RESPONSE_MESSAGES } from 'constants/invalidSessionResponeMessages';
import { AUTH_MESSAGES } from 'constants/messageConstants';

const queryStringBuilder = params => '?' + Object.entries(params).map((x) => `${x[0]}=${x[1]}`).join('&');

const urlBuilder = (...path) => {
    let url = path
        .filter(x => x && typeof (x) == 'string')
        .join('/');

    const lastElement = path.pop();
    if (lastElement) {
        url += queryStringBuilder(lastElement);
    }

    return url;
};

const getToken = async () => await refreshTokenAction()(store.dispatch);

const requestInitBase = async (contentType, method, body, token) => {
    token = token ? token : await getToken();

    return {
        method,
        headers: {
            ...(contentType && { 'Content-Type': contentType }),
            ...(token && { 'id_token': token })
        },
        body
    };
};

const requestInit = requestInitBase.bind(null, 'application/json');

// TODO Refactor in one responseHandler
const responseHandler = async res => {
    if (!res.ok) {
        if (res.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            const response = await res.json();
            if (INVALID_SESSION_RESPONSE_MESSAGES.includes(response.message)) {
                throw response;
            } else if (AUTH_MESSAGES.UNAUTHORIZED_ACCESS_RESPONSE_MESSAGES.includes(response.message)) {
                deniedAccessRedirect()(store.dispatch);
                throw { ...response, code: res.status };
            }

            throw response;
        }

        if (process.env.NODE_ENV !== 'production') {
            console.error(res)
        }

        throw await res.json();
    }

    return res.json();
};

const responseHTMLHandler = async res => {
    if (!res.ok) {
        throw await res.json();
    }

    return res.text();
};

const baseSave = (fileName, requestCallback, endpoint, params) => requestCallback()
    .then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody))
    .then(res => {
        if (res.status === 200) {
            return res.blob();
        } else {
            return responseHandler(res)
        }
    })
    .then(data => {
        if (data.type === EMPTY_FILE_TYPE) {
            return { noFiles: true };
        } else {
            const extention = data.type.split(';')[0];
            saveAs(new Blob([data]), `${fileName}.${FILE_TYPE_MAPPER[extention]}`)
        }
    });

const requesterBuilder = BASE_URL => (endpoint, params, token) => ({
    getOne: () => requestInit('GET', null, token).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    getMany: () => requestInit('GET', null, token).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    getHTML: () => requestInit('GET').then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHTMLHandler),

    create: data => requestInit('POST', JSON.stringify(data)).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    update: data => requestInit('PUT', JSON.stringify(data), token).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    updatePatch: data => requestInit('PATCH', JSON.stringify(data)).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    delete: () => requestInit('DELETE').then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    updateMany: data => requestInit('POST', JSON.stringify(data)).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    uploadFile: data => requestInitBase(null, 'POST', data).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    updateFile: data => requestInitBase(null, 'PUT', data).then(reqBody => fetch(urlBuilder(BASE_URL, endpoint, params), reqBody)).then(responseHandler),

    saveAsAction: (fileName, body) => baseSave(fileName, requestInit.bind(null, 'POST', JSON.stringify(body)), endpoint, params),

    saveAs: fileName => baseSave(fileName, requestInit.bind(null, 'GET'), endpoint, params),
});

export default requesterBuilder(BASE_URL);
