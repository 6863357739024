import { useContext } from 'react';

import { MatchingJobCardContext } from '../MatchingJobCard';

import LocationLabel from 'components/Shared/LocationLabel';

const MatchingJobLocationLabel = () => {
    const { job } = useContext(MatchingJobCardContext);

    return <LocationLabel isMatch={job.isMatchingLocation} location={job.userLocation} />
};

export default MatchingJobLocationLabel;
