import { connect } from 'react-redux';

import { getCandidateApplicationsCountByStatus } from 'reducers';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import { FILTER_TABS, SORT_VALUES } from 'components/JobPage/AppliedCandidates/AppliedCandidatesConstants';

import './CandidateApplicationsActions.scss';

const CandidateApplicationsActions = ({
    displayMode,
    filters,
    selectCandidateStatusTab,
    handleSortChange,
    handleSearchTermChange,
    statusCounts,
}) => {
    return (
        <div className="candidate-applications-actions">
            <div className="candidate-applications-tabs-container">
                {
                    FILTER_TABS[displayMode].map((tab, i) => (
                        <div
                            key={(tab.value + i)}
                            className={`candidate-applications-tab ${filters.status === tab.name ? 'active-applications-tab' : ''}`}
                            onClick={() => selectCandidateStatusTab(tab.name)}
                        >
                            <h3 className="candidate-applications-tab-title">{tab.name} ({statusCounts[tab.value]})</h3>
                        </div>
                    ))
                }
            </div>
            <SectionActionsWrapper.LeftAlignedActions>
                <SectionActionsWrapper.LeftAlignedActions.SelectFilterList
                    name="sortBy"
                    title="Sort by"
                    value={filters.sortBy}
                    items={SORT_VALUES}
                    onChangeHandler={handleSortChange}
                    closeMenuOnSelect={true}
                />

                <SectionActionsWrapper.LeftAlignedActions.SearchFilter
                    classes={["candidate-applications"]}
                    fieldValue={filters.searchTerm}
                    placeholder="Search for a name"
                    onChangeHandler={handleSearchTermChange}
                />
            </SectionActionsWrapper.LeftAlignedActions>
        </div>
    );
};

const mapStateToProps = (state) => ({
    statusCounts: getCandidateApplicationsCountByStatus(state),
});

export default connect(mapStateToProps)(CandidateApplicationsActions);
