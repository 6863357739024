import React from 'react';
import { isMobile } from 'react-device-detect';

import { selectBackgroundImage } from 'utils/uiHelpers';

import { APP_LOGIN_BACKGROUND } from 'constants/env';

import './Guest.scss';

const GuestLayout = ({
    children,
    location,
}) => {
    const isRegisterPage = location.pathname === '/register';

    return (
        <div className="guest-wrapper">

            {!isMobile && <div className="guest-image" style={{ backgroundImage: `url(${selectBackgroundImage(APP_LOGIN_BACKGROUND, location.pathname, location?.state?.role)})` }} />}

            <div className={`guest-content${isMobile ? isRegisterPage ? ' register-mobile' : ' mobile' : ''}`}>
                <div className="guest-content-body">
                    {children}
                </div>
            </div>

        </div>
    )
};

export default GuestLayout;
