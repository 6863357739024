import React from 'react';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as PlacedIcons } from 'assets/employee-status-banner-icons/placed-icons-group.svg';
import { ReactComponent as BenchIcons } from 'assets/employee-status-banner-icons/bench-icons-group.svg';
import { ReactComponent as NonBillableIcons } from 'assets/employee-status-banner-icons/non-billable-project-icons-group.svg';
import { ReactComponent as ManagementTeamIcons } from 'assets/employee-status-banner-icons/management-team-icons-group.svg';
import { ReactComponent as ProjectEndsSoonIcons } from 'assets/employee-status-banner-icons/project-ends-soon-icons-group.svg';


export const getBannerContent = occupationStatus => {
    switch (occupationStatus) {
        case 1:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    The employee <span className="bold">is on billable project</span>
                </Typography>,
                ICONS: <PlacedIcons />
            };
        case 2:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    The employee is currently <span className="bold">on the bench</span>
                </Typography>,
                ICONS: <BenchIcons />
            };
        case 3:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    The employee is <span className="bold">on non-billable project</span>
                </Typography>,
                ICONS: <NonBillableIcons />
            };
        case 4:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    The employee is <span className="bold">on the leadership team</span>
                </Typography>,
                ICONS: <ManagementTeamIcons />
            };
        case 5:
            return {
                TEXT: <Typography className="employee-status-text" variant="h2">
                    The employee's <span className="bold">project ends soon</span>
                </Typography>,
                ICONS: <ProjectEndsSoonIcons />
            };
        default:
            return {};
    }
};

