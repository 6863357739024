import React from 'react';

import JobsDashboard from './JobsDashboard';

import { ROLES } from 'constants/userConstants';

const JobsDashboardPage = ({
    displayMode,
}) => {
    const renderJobsDashboardContent = displayMode => {
        switch (displayMode) {
            case ROLES.CLIENT:
                return (
                    <JobsDashboard.JobsDashboardClientView>
                        <div className="client-jobs-dashboard-flex-wrapper">
                            <div className="client-jobs-dashboard-content-wrapper">
                                <div className="client-jobs-dashboard-header">
                                    <JobsDashboard.JobsDashboardGreetings />
                                    <JobsDashboard.JobsDashboardJobTypeBoxes displayMode={displayMode} />
                                </div>
                                <div className="client-jobs-dashboard-body">
                                    <JobsDashboard.JobsDashboardJobsList />
                                </div>
                            </div>

                            <div className="aside-container">
                                <JobsDashboard.JobsDashboardCalendar />
                                <JobsDashboard.JobsDashboardNotifications />
                            </div>
                        </div>
                    </JobsDashboard.JobsDashboardClientView>
                );
            case ROLES.ADMIN:
                return (
                    <JobsDashboard.JobsDashboardAdminView>
                        <div className="client-jobs-dashboard-content-wrapper">
                            <div className="client-jobs-dashboard-header">
                                <JobsDashboard.JobsDashboardJobTypeBoxes displayMode={displayMode} />
                            </div>
                            <div className="client-jobs-dashboard-body">
                                <JobsDashboard.JobsDashboardJobsList />
                            </div>
                        </div>
                    </JobsDashboard.JobsDashboardAdminView>
                );
            case ROLES.SUPPLIER_ADMIN:
                return (
                    <JobsDashboard.JobsDashboardSupplierAdminView>
                        <div className="client-jobs-dashboard-content-wrapper">
                            <div className="client-jobs-dashboard-header">
                                <JobsDashboard.JobsDashboardJobTypeBoxes displayMode={displayMode} />
                            </div>
                            <div className="client-jobs-dashboard-body">
                                <JobsDashboard.JobsDashboardJobsList />
                            </div>
                        </div>
                    </JobsDashboard.JobsDashboardSupplierAdminView>
                );
            default:
                return null
        }
    };

    return (
        <JobsDashboard>
            {renderJobsDashboardContent(displayMode)}
        </JobsDashboard>
    );
};

export default JobsDashboardPage;
