export const CONFIRM_DIALOG_DELETION_CONFIRMATION_ITEMS = {
    CONFIRM_BUTTON_NAME: 'Yes',
    DISCARD_BUTTON_NAME: 'No',
    DIALOG_SUBTITLE: 'This can not be undone.',
    GET_DIALOG_TITLE: (itemToDelete) => `Are you sure you want to delete ${itemToDelete}?` 
}

export const CONFIRM_DIALOG_INVITE_USER_CONFIRMATION_ITEMS = {
    CONFIRM_BUTTON_NAME: 'Proceed',
    DISCARD_BUTTON_NAME: 'Cancel',
    GET_DIALOG_TITLE: (userRole) => `Notice: are you sure you want to invite the ${userRole}, they will receive an email invitation.\n\nYou can leave them without email and send them an invitation later.`
}

export const CONFIRM_DIALOG_MOVE_TO_AGENCY_CONFIRMATION_ITEMS = {
    CONFIRM_BUTTON_NAME: 'Proceed',
    DISCARD_BUTTON_NAME: 'Cancel',
    GET_DIALOG_TITLE: (user) => ` Notice: Are you sure you want to move ${user} to your agency ?`
}

export const CONFIRM_DIALOG_RESTORE_USER_CONFIRMATION_ITEMS = {
    CONFIRM_BUTTON_NAME: "Agree",
    DISCARD_BUTTON_NAME: "Discard",
    DIALOG_TITLE: "Restore Profile",
    DIALOG_SUBTITLE_DEFAULT: "Are you sure you want to restore this profile?\nThe user will receive an email notification with a link to reactivate their profile and reset their password.",
    DIALOG_SUBTITLE_EMPLOYEE_OR_ADMIN: "Are you sure you want to restore the user and their employment?",
}

export const CONFIRM_DIALOG_EMPLOYEE_TERMINATION_CONFIRMATION_ITEMS = {
    CONFIRM_BUTTON_NAME: 'Yes',
    DISCARD_BUTTON_NAME: 'No',
    DIALOG_TITLE: 'Are you sure you want to terminate this employment and deactivate user?',
}
