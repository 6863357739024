import React from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PerformanceBar from '../../PerformanceBar';

import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import TurnedInNotRoundedIcon from '@material-ui/icons/TurnedInNotRounded';

import './PerformanceReviewCard.scss';

const PerformanceReviewCard = ({
    cardInfo
}) => (
    <Paper className="performance-review-card">
        <div className="score-wrapper">
            <Typography className="average-score">{cardInfo.overallRating ? cardInfo.overallRating.toFixed(1) : ''}</Typography>
            <div className="bar-container">
                <PerformanceBar score={cardInfo.overallRating} />
            </div>
        </div>
        <div className="review-info">
            <div className="info-row">
                <PersonOutlineRoundedIcon fontSize="small" className="info-icon" />
                <Typography variant="body2" className="info-text">{cardInfo.reviewer}</Typography>
            </div>
            <div className="info-row">
                <DateRangeRoundedIcon fontSize="small" className="info-icon" />
                <Typography variant="body2" className="info-text">
                    {moment.utc(cardInfo.date).format("DD/MM/YY")}
                </Typography>
            </div>
            <div className="info-row last">
                <TurnedInNotRoundedIcon fontSize="small" className="info-icon text-icon" />
                {cardInfo.summary
                    ? <Typography variant="body2" className="info-text">
                        {cardInfo.summary.substring(0, 1500)}
                    </Typography>
                    : null}
            </div>
        </div>
    </Paper>
);

export default PerformanceReviewCard;
