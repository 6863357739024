import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ColumnFilterIcon } from 'assets/table-filter-columns.svg';
import { Checkbox, List, ListItem, Popover, Typography } from '@material-ui/core';
import { parseQueryString, updateQueryWithMultipleEntries } from 'utils/helpers';

import { TableWrapperContext } from '../TableWrapper';

const TableColumnsFilter = () => {
    const history = useHistory();
    const { columns, selectedColumns, defaultColumns, setSelectedColumns } = useContext(TableWrapperContext);

    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        const queryParams = parseQueryString(history.location.search, true);
        // In the query there might be columns from other tables as well
        const currentTableQueryColumns = columns.map(x => x.value).filter((column) => queryParams.columns?.includes(column));
        if (currentTableQueryColumns.length) {
            setSelectedColumns(currentTableQueryColumns);
        } else {
            setSelectedColumns(defaultColumns);
        }
    }, [history.location.search]);

    const handleOpenPopover = (event) => setAnchorEl(event.currentTarget);
    const handleClosePopover = () => setAnchorEl(null);
    const handleColumnClick = (columnValue) => {
        let newFilteredColumns = [];

        // If the column is currently selected, deselect it
        if (selectedColumns.includes(columnValue)) {
            newFilteredColumns = selectedColumns.filter(x => x !== columnValue);
        } else {
            newFilteredColumns = [...selectedColumns, columnValue];
        }

        // In the query there might be columns from other tables, so we need to preserve them
        const otherTablesColumns = parseQueryString(history.location.search, true)?.columns?.filter(column => !columns.map(x => x.value).includes(column)) || [];

        newFilteredColumns = [...otherTablesColumns, ...newFilteredColumns];

        const filterObj = { columns: newFilteredColumns };
        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, filterObj)

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    return (
        <Fragment>
            <div className={`table-filter${Boolean(anchorEl) ? ' selected' : ''}`} onClick={handleOpenPopover}>
                <ColumnFilterIcon />
                <Typography variant="body1">Columns</Typography>
            </div>

            <Popover
                className="global-table-filter-options-popover"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <List className="table-filter-list">
                    {columns.map(columnObj => (
                        <ListItem button className="popover-list-item direction-row" key={columnObj.name} onClick={() => handleColumnClick(columnObj.value)}>
                            <Checkbox
                                checked={selectedColumns.includes(columnObj.value)}
                                className="global-table-filter-checkbox"
                            />
                            <Typography variant="body1" className="list-item-text">
                                {columnObj.name}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </Fragment>
    )
};

export default TableColumnsFilter;
