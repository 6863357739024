import React, { useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import SearchMenuView from '../SearchMenuView';

import { DropdownMenuContext } from '../SearchDropdown';
import { ROLES } from 'constants/userConstants';

const SupplierAdminMenu = ({ compact }) => {
    const { searchResults, handleClick, handleBlur, displayMode } = useContext(DropdownMenuContext);

    const mapper = {
        [ROLES.EMPLOYEE]: (searchResults) => searchResults.filter(x => x.role === ''),
        [ROLES.SUPPLIER_ADMIN]: (searchResults) => searchResults.filter(x => x.role === ''),
        [ROLES.CLIENT]: (searchResults) => searchResults.filter(x => x.role === ''),
        [ROLES.RECRUITER]: (searchResults) => searchResults.filter(x => x.role === ''),
        [ROLES.ADMIN]: (searchResults) => searchResults.filter(x => x.role === ROLES.SUPPLIER_ADMIN)
    };

    const results = compact ? mapper[displayMode](searchResults).slice(0, 3) : mapper[displayMode](searchResults);

    return results.length > 0 ? (
        <div className="search-menu">
            <Typography variant="h5">Partners</Typography>
            <SearchMenuView
                results={results}
                handleClick={handleClick}
                handleBlur={handleBlur}
            />
        </div>
    ) : null
};

export default SupplierAdminMenu;
