import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useDebounce } from 'utils/hooks';
import { fetchSearchResults } from 'actions/searchActions';
import { getAgencyId, getSearchResults } from 'reducers';

import SearchEmployeeBar from './SearchEmployeeBar';
import SearchEmployeeResultsDropdown from './SearchEmployeeResultsDropdown';

import { ROLES } from 'constants/userConstants';
import './SearchEmployee.scss';

const SearchEmployee = ({
    agencyId,
    searchResults,
    fetchSearchResults,
    handleSelectedEmployees,
    searchTerm,
    handleChange
}) => {
    const [showResultsDropdown, setShowResultsDropdown] = useState(false);

    const delay = 500;
    const debouncedSearchTerm = useDebounce(searchTerm, delay);

    useEffect(() => {
        if (debouncedSearchTerm) {
            fetchSearchResults(agencyId, debouncedSearchTerm, ROLES.EMPLOYEE);
        }
    }, [debouncedSearchTerm]);

    const handleFocus = () => searchTerm ? setShowResultsDropdown(true) : setShowResultsDropdown(false);

    const handleBlur = () => setShowResultsDropdown(false);

    const handleSearchTermChange = e => {
        e.target.value
            ? setShowResultsDropdown(true)
            : setShowResultsDropdown(false);

    };

    const selectEmployeeClickHandler = (result) => {
        handleSelectedEmployees(result);
        setShowResultsDropdown(false);
    };

    return (
        <div className="search-wrapper">
            <SearchEmployeeBar
                searchTerm={searchTerm}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleSearchTermChange={(e) => { handleChange(e); handleSearchTermChange(e) }}
            />
            {showResultsDropdown && searchResults.length > 0 &&
                <SearchEmployeeResultsDropdown
                    peopleSearchResults={searchResults.filter(x => x.role === ROLES.EMPLOYEE)}
                    handleResultClick={selectEmployeeClickHandler}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
    searchResults: getSearchResults(state),
});

const mapDispatchToProps = {
    fetchSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchEmployee);
