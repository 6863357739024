import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { getBookedHolidaysCount, getPendingHistoryList } from 'reducers';
import { approveHolidayRequests, rejectHolidayRequest } from 'actions/employeeActions';

import HolidayRejectDialog from './HolidayRejectDialog';
import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';

import {
    TABLE_COLUMNS,
    DEFAULT_TABLE_COLUMNS,
    SORT_QUERY_SELECTOR,
    PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    TABLE_INITIAL_QUERY,
    CONFIRM_DELETE_DIALOG_TITLE,
} from './PendingHolidaysTableConstants';
import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';

import { parseQueryString } from 'utils/helpers';

import { ReactComponent as AddIcon } from 'assets/add-button-icon.svg';

const PendingHolidaysTable = ({
    agencyId,
    userId,
    departmentId,
    isAdminOrSupplierAdmin,
    accessAsManager,
    isTableDataLoading,
    pendingHolidaysCount,
    deleteHolidayRequests,
    handleHolidaysAction,
    approveHolidayRequests,
    rejectHolidayRequest,
    handleOpenDialog,
}) => {
    const history = useHistory();
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
    const [targetHoliday, setTargetHoliday] = useState({});

    const [checkedRows, setCheckedRows] = useState([]);

    const dataSelector = (state, page) => {
        const parsedQuery = parseQueryString(history.location.search);
        const parsedQuerySortBy = parsedQuery[SORT_QUERY_SELECTOR] || TABLE_INITIAL_QUERY.sortBy;
        const sortBy = TABLE_COLUMNS.find(x => x.value === parsedQuerySortBy).propertyName;
        const sortByDir = parsedQuery[`${SORT_QUERY_SELECTOR}Dir`] || TABLE_INITIAL_QUERY.sortByDir;

        return getPendingHistoryList(state, page - 1, DEFAULT_ITEMS_PER_PAGE, sortBy, sortByDir);
    };

    // Approve
    const handleHolidayApprove = holiday => {
        handleHolidaysAction(approveHolidayRequests, [holiday]);
    };

    // Approve checked rows
    const handleSelectedHolidaysApprove = () => {
        handleHolidaysAction(approveHolidayRequests, checkedRows);
        setCheckedRows([]);
    };

    // Reject 
    const handleOpenRejectDialog = holiday => {
        setTargetHoliday(holiday);
        setIsRejectDialogOpen(true);
    };

    const handleCloseRejectDialog = () => {
        setTargetHoliday({});
        setIsRejectDialogOpen(false);
    };

    const handleHolidayRejectSubmit = rejectionReason => {
        handleHolidaysAction(rejectHolidayRequest, { ...targetHoliday, rejectionReason });
        handleCloseRejectDialog();
    };

    // Confirm Delete
    const handleOpenConfirmDeleteDialog = holiday => {
        setTargetHoliday(holiday);
        setIsConfirmDeleteDialogOpen(true);
    };

    const handleCloseConfirmDeleteDialog = () => {
        setTargetHoliday({});
        setIsConfirmDeleteDialogOpen(false);
    };

    const handleConfirmDeleteSubmit = () => {
        deleteHolidayRequests(userId, agencyId, departmentId, [targetHoliday._id], true, accessAsManager);
        handleCloseConfirmDeleteDialog();
    };

    const adjustPendingHolidaysKeys = pendingHolidays => pendingHolidays.map(pendingHoliday => {
        return {
            from: moment.utc(pendingHoliday.startDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            to: moment.utc(pendingHoliday.endDate).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            numberOfDays: pendingHoliday.numberOfDays,
            type: pendingHoliday.type === 'Paid' && pendingHoliday.byArticle ? `${pendingHoliday.type} Art. ${pendingHoliday.byArticle}` : pendingHoliday.type,
            requestedOn: moment.utc(pendingHoliday.requestedOn).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR),
            item: pendingHoliday,
        };
    });

    const actions = [
        { title: 'Approve', handleClick: handleHolidayApprove },
        { title: 'Reject', handleClick: handleOpenRejectDialog },
        { title: 'Delete', handleClick: handleOpenConfirmDeleteDialog }
    ];

    const getActionsHandler = () => actions;

    return (
        <div className="holidays-pending-table-wrapper">
            <TableWrapper
                title="Pending Holidays"
                columns={TABLE_COLUMNS}
                defaultColumns={DEFAULT_TABLE_COLUMNS}
                dataSelector={dataSelector}
                sortQuerySelector={SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustPendingHolidaysKeys}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withCheckboxes
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                withActions={isAdminOrSupplierAdmin || accessAsManager?.length > 0}
                getActionsHandler={getActionsHandler}
                pagesCount={Math.ceil(pendingHolidaysCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        {checkedRows.length > 0 &&
                            <TableWrapper.TableAlignedItems.TableActionButton text="Approve" handleClick={handleSelectedHolidaysApprove} />
                        }
                        <TableWrapper.TableAlignedItems.TableActionButton text={"Request Holiday"} icon={<AddIcon />} handleClick={handleOpenDialog} />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <HolidayRejectDialog
                openDialog={isRejectDialogOpen}
                employeeInfo={targetHoliday}
                handleCloseDialog={handleCloseRejectDialog}
                handleHolidayReject={handleHolidayRejectSubmit}
                bookedHolidaysCountSelector={getBookedHolidaysCount}
            />

            <ConfirmDeleteDialog
                openDialog={isConfirmDeleteDialogOpen}
                itemToDelete={CONFIRM_DELETE_DIALOG_TITLE}
                handleCloseDialog={handleCloseConfirmDeleteDialog}
                handleDeleteItem={handleConfirmDeleteSubmit}
            />
        </div>
    );
};

const mapDispatchToProps = {
    approveHolidayRequests,
    rejectHolidayRequest,
};

export default connect(null, mapDispatchToProps)(PendingHolidaysTable);
