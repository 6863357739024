import React from 'react';
import { connect } from 'react-redux';

import { getPublicHolidays } from 'reducers';
import ActiveHolidays from 'components/Shared/ActiveHolidays';

const ActiveHolidaysDashboard = ({
    publicHolidays,
    dataFetchState
}) => {
    return (
        <ActiveHolidays
            publicHolidays={publicHolidays}
            dataFetchState={dataFetchState}
        />
    )
};

const mapStateToProps = state => ({
    publicHolidays: getPublicHolidays(state)
});

export default connect(mapStateToProps, null)(ActiveHolidaysDashboard);
