import React from 'react';

import MatchingProfilesTable from './MatchingProfilesTable';

import { ReactComponent as NoItemsFoundIcon } from 'assets/no-items-found.svg';

const MatchingConsultantsView = ({
    profiles,
    handleSendToClientClick,
    totalProfilesCount,
}) => {
    return (
        <>
            {!totalProfilesCount >= 0 &&
                <>
                    <MatchingProfilesTable
                        title="Agency"
                        matchingProfiles={profiles?.employees}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                    <MatchingProfilesTable
                        title="Partner"
                        matchingProfiles={profiles?.partnerEmployees}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                    <MatchingProfilesTable
                        title="Candidate"
                        matchingProfiles={profiles?.candidates}
                        handleSendToClientClick={handleSendToClientClick}
                    />
                </>
            }

            {totalProfilesCount === 0 &&
                <div className="no-candidate-found" >
                    <div>
                        <NoItemsFoundIcon className='icon' />
                    </div>
                    <p>
                        No matches found!
                    </p>
                </div>
            }
        </>
    )
};

export default MatchingConsultantsView;
