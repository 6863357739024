import { EMPLOYMENT_TYPES } from "constants/employeeConstants";

export const EMPLOYEES_HEAD_CELLS = [
    { title: 'Name', column: 'fullName' },
    { title: 'Position', column: 'position' },
    { title: 'Project', column: 'projectNames' },
    { title: 'Status', column: 'occupationStatus' },
    { title: 'Experience', column: 'experience' },
];

export const TEAM_SORT_QUERY_SELECTOR = 'teamSort';
export const TEAM_SEARCH_QUERY_SELECTOR = 'teamSearch';
export const TEAM_PAGE_QUERY_SELECTOR = 'teamTablePage';

export const TEAM_TABLE_COLUMNS = [
    { name: 'Name', value: 'fullName', isSortable: true },
    { name: 'Position', value: 'position', isSortable: true },
    { name: 'Experience', value: 'experience', isSortable: true },
    { name: 'Project', value: 'projectNames', isSortable: true },
    { name: 'Status', value: 'occupationStatus', isSortable: true },
];

export const TEAM_TABLE_QUERY_MAP = {
    page: TEAM_PAGE_QUERY_SELECTOR,
    search: TEAM_SEARCH_QUERY_SELECTOR,
    sortBy: TEAM_SORT_QUERY_SELECTOR,
    sortByDir: `${TEAM_SORT_QUERY_SELECTOR}Dir`,
};

export const DEFAULT_TEAM_ADMIN_COLUMNS = ["fullName", "position", "experience", "projectNames", "occupationStatus"];

export const TEAM_TABLE_LEGEND_ITEMS = [
    { color: "#16D696", text: "Full-Time" },
    { color: "#FFDD00", text: "Freelance" },
    { color: "#7E7E7E", text: "Contractor" },
]

export const EMPLOYMENT_TYPES_TO_CLASS_NAME_MAP = {
    [EMPLOYMENT_TYPES.FULLTIME_CONTRACT]: 'full-time',
    [EMPLOYMENT_TYPES.FREELANCE_CONTRACT]: 'freelance',
};

export const TEAM_TABLE_INITIAL_QUERY = {
    page: 1,
    search: "",
    date: null,
    sortBy: null,
    sortByDir: null
};

export const DEFAULT_ADMIN_COLUMNS = {
    fullName: true,
    position: false,
    occupationStatus: true,
    experience: true,
    projectNames: false,
};

export const DEFAULT_SUPPLIER_COLUMNS = {
    fullName: true,
    experience: true,
    projectNames: true,
    email: true,
}

export const SUPPLIER_EMPLOYEES_HEAD_CELLS = [
    { title: 'Name', column: 'fullName' },
    { title: 'Project', column: 'projectNames' },
    { title: 'E-mail', column: 'email' },
    { title: 'Experience', column: 'experience' },
];

export const EMPLOYEES_HEAD_CELLS_VALUES = {
    NAME: 'fullName',
    PROJECT: 'projectNames',
    STATUS: 'occupationStatus',
    EXPERIENCE: 'experience',
    POSITION: 'position',
};

export const EMPLOYEES_OCCUPATION_STATUSES_INITIAL_ORDER = [
    'Bench',
    'Project Ends',
    'Non-billable',
    'Leadership Team',
    'Billable',
];

export const WITH_INNER_ACTIONS_COLUMNS = ['occupationStatus'];

export const initialFilters = {
    searchTerm: '',
};
