export const DATE_FORMAT = 'DD/MM/YY';
export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_ISO_DATE_FORMAT = 'YYYY-MM';
export const MONTH_YEAR_DATE_FORMAT = 'MM-YYYY';
export const MONTH_DAY_YEAR_DATE_FORMAT = 'MMMM DD, YYYY'
export const MONTH_DAY_DATE_FORMAT = 'MMMM DD'

export const FULL_DAY_ONLY = 'DD';
export const FULL_MONTH_ONLY = 'MMMM';
export const FULL_YEAR_ONLY = 'YYYY';

export const SHORT_MONTH_FULL_YEAR = 'MMM YYYY';

export const DATE_FORMAT_WITH_DASH = 'DD-MM-YYYY';
export const DATE_FORMAT_WITH_SLASH = 'DD/MM/YYYY';
export const US_DATE_FORMAT_WITH_SLASH = 'MM/DD/YYYY';

export const DATE_FORMAT_WITH_DOTS = 'DD.MM.YY';
export const DATE_FORMAT_WITH_DOTS_FULL_YEAR = 'DD.MM.YYYY';

export const DATE_FORMAT_WITH_SLASH_SHORT_YEAR = 'DD/MM/YY';

export const CONTINENTAL_TIME_FORMAT = 'HH:mm'
export const US_TIME_FORMAT = 'hh:mm A'

export const CURRENT_QUARTER_YEAR_FORMAT = '[Q]Q YYYY';

export const MAX_DATE = new Date(8640000000000000);
export const MAX_DESCRIPTION_LENGTH = 2000;

export const MIN_DATE = new Date('1900-01-01');

export const CRYPTO_SECRET = 'supersecret';

export const TALENT_REQUEST_TYPES = {
    TEAM: 'team',
    EMPLOYEE: 'employee'
};

export const TABLE_COLUMN_DIRECTIONS = {
    ASCENDING: 'asc',
    DESCENDING: 'desc'
};

export const CUSTOM_ERROR_TYPES = {
    AUTHORIZATION: 'AuthorizationError',
};

export const DIALOG_COMMENT_INPUT = {
    ROWS: 4,
    MAX_ROWS: 8,
};

export const TEXTAREA_ROWS = {
    MIN_ROWS: 6,
    MAX_ROWS: 12,
};

export const URL_VALIDATIONS = {
    LINKED_IN: '^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)',
    GITHUB: '^(https?:\/\/)?(www\.)?github.com\/[a-zA-Z0-9(\.\?)?]',
    CALENDLY: '^(https?:\/\/)?(www\.)?calendly.com\/[a-zA-Z0-9(\.\?)?]',
};

export const EMAIL_VALIDATION_PATTERN = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const COUNTRIES_OF_EMPLOYMENT = [
    'Bulgaria',
    'Romania',
    'Ukraine',
    'Turkey',
    'Belarus',
    'Bosnia and Herzegovina',
    'Macedonia',
    'Brazil',
    'Canada',
    'Chile',
    'Colombia',
    'Georgia',
    'Germany',
    'Hungary',
    'Lithuania',
    'Poland',
    'Switzerland',
    'United States of America',
    'Uzbekistan',
];

export const ACCEPTED_FORMAT = ['ppt', 'pptx', 'xlsx', 'csv', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'pdf'];

export const INITIAL_CROP = {
    unit: 'px',
    width: 200,
    height: 200,
    x: 0,
    y: 0,
};

export const temporaryRoute = '/temporaryRoute';

export const ACCOUNT_ROUTES_MODES = {
    VERIFY_EMAIL: 'verifyEmail',
    VERIFY_ADMIN_EMAIL: 'verifyAdminEmail',
    VERIFY_EMPLOYEE_EMAIL: 'verifyEmployeeEmail',
    VERIFY_CLIENT_EMAIL: 'verifyClientEmail',
    VERIFY_SUPPLIER_ADMIN_EMAIL: 'verifySupplierAdminEmail',
    VERIFY_SUPPLIER_EMPLOYEE_EMAIL: 'verifySupplierEmployeeEmail',
    VERIFY_RECRUITER_EMAIL: 'verifyRecruiterEmail',
    VERIFY_CANDIDATE_EMAIL: 'verifyCandidateEmail',
    RESET_PASSWORD: 'resetPassword',
};

export const SEARCH_RESULTS_LIMIT = 10;

export const DOT_UNICODE = '\u2022';
