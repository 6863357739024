import React from 'react';
import { connect } from 'react-redux';

import { getAgencyName } from 'reducers';

import AppliedCandidatesList from './AppliedCandidatesList';

const AppliedCandidatesRedux = ({
    appliedConsultants,
    searchTermMatch,
    agencyName,
    ...props
}) => {
    return <AppliedCandidatesList
        appliedConsultants={appliedConsultants}
        searchTermMatch={searchTermMatch}
        agencyName={agencyName}
        {...props}
    />
};

const mapStateToProps = (state, props) => {
const { appliedConsultants, searchTermMatch } = props.dataSelector(state, props.filters);
    const agencyName = getAgencyName(state);

    return {
        appliedConsultants,
        agencyName,
        searchTermMatch,
    }
};

export default connect(mapStateToProps, null)(AppliedCandidatesRedux);
