import React from 'react';

import { ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';

import './UploadLinkDialogFormView.scss';

const UploadLinkDialogFormView = ({
    values,
    handleChange,
    errors,
    touched,
}) => (
    <form className="upload-link-dialog-form" autoComplete="off">
        <div className="fields-block">
            <div className="field">
                <TextField
                    required
                    className="name-field"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    label="Name"
                    placeholder="Web Address"
                    error={errors.name && touched.name}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="name" component="div" className="invalid-field-message" />
            </div>

            <div className="field">
                <TextField
                    required
                    className="url-field"
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    label="URL"
                    error={errors.url && touched.url}
                    margin="normal"
                    variant="outlined"
                />
                <ErrorMessage name="url" component="div" className="invalid-field-message" />
            </div>
        </div>
    </form>
);

export default UploadLinkDialogFormView;
