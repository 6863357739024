import moment from 'moment';
import { holidayTypes, defaultHolidayType, PENDING_HEAD_CELLS_VALUES } from './TeamHolidaysConstants';
import { getStateAgencyName } from 'utils/helpers';

import {
    getAgencyPendingHolidays,
    getAgencyApprovedHolidays,
    getAgencySickLeaves,
    getOwnEmployeesPendingHolidays,
    getAgencyHolidaysHistory,
    getOwnEmployeesApprovedHolidays,
    getOwnEmployeesSickLeaves,
    getOwnEmployeesHolidaysHistory
} from 'reducers';

import {
    fetchPendingHolidays,
    fetchReviewedHolidays,
    approveHolidayRequests,
    rejectHolidayRequest,
    fetchSickLeaves,
    fetchApprovedHolidays
} from 'actions/agencyActions';

import {
    fetchManagersEmployeesPendingHolidays,
    fetchManagersEmployeesReviewedHolidays,
    fetchManagersEmployeesSickLeaves,
    approveManagersEmployeesHolidaysRequests,
    rejectManagersEmployeesHolidaysRequest,
    fetchManagersEmployeesApprovedHolidays,
    downloadManagersEmployeesDocumentsFiles,
} from 'actions/managerActions';
import { downloadDocumentsFiles } from 'actions/documentActions';

const TEAM_HOLIDAYS_DISPATCH_TO_PROPS = {
    fetchPendingHolidays: (agencyId, userId, query, replaceState) => fetchPendingHolidays(agencyId, query, replaceState),
    fetchReviewedHolidays: (agencyId, userId, query, replaceState) => fetchReviewedHolidays(agencyId, query, replaceState),
    fetchSickLeaves: fetchSickLeaves,
    approveHolidayRequests: (agencyId, requests, from, to, userId, updateState) => approveHolidayRequests(agencyId, requests, from, to, updateState),
    rejectHolidayRequest: (agencyId, request, userId, updateState) => rejectHolidayRequest(agencyId, request, updateState),
    fetchApprovedHolidays: fetchApprovedHolidays,
    downloadDocumentsFiles: (agencyId, documentsData, fileName, userId, query) => downloadDocumentsFiles(agencyId, documentsData, fileName, query),
}

const DEPARTMENTS_HOLIDAYS_DISPATCH_TO_PROPS = {
    fetchPendingHolidays: fetchManagersEmployeesPendingHolidays,
    fetchReviewedHolidays: fetchManagersEmployeesReviewedHolidays,
    fetchSickLeaves: fetchManagersEmployeesSickLeaves,
    approveHolidayRequests: approveManagersEmployeesHolidaysRequests,
    rejectHolidayRequest: rejectManagersEmployeesHolidaysRequest,
    fetchApprovedHolidays: fetchManagersEmployeesApprovedHolidays,
    downloadDocumentsFiles: downloadManagersEmployeesDocumentsFiles,
}

const TEAM_HOLIDAYS_MAP_STATE_TO_PROPS = {
    pendingHolidays: (state, ...args) => getAgencyPendingHolidays(state, ...args),
    getApprovedHolidays: (state) => getAgencyApprovedHolidays(state),
    getApprovedHolidaysHistory: getAgencyHolidaysHistory,
    getSickLeaves: getAgencySickLeaves
}

const DEPARTMENTS_HOLIDAYS_MAP_STATE_TO_PROPS = {
    pendingHolidays: (state, ...args) => getOwnEmployeesPendingHolidays(state, ...args),
    getApprovedHolidays: (state) => getOwnEmployeesApprovedHolidays(state),
    getApprovedHolidaysHistory: getOwnEmployeesHolidaysHistory,
    getSickLeaves: getOwnEmployeesSickLeaves
}

export const mapDispatchToPropsPicker = (browserLocation) => {
    if (browserLocation.includes(`departments/holidays`)) {
        return DEPARTMENTS_HOLIDAYS_DISPATCH_TO_PROPS;
    } else {
        return TEAM_HOLIDAYS_DISPATCH_TO_PROPS;
    }
}

export const mapStateToPropsPicker = (browserLocation) => {
    if (browserLocation.includes(`departments/holidays`)) {
        return DEPARTMENTS_HOLIDAYS_MAP_STATE_TO_PROPS;
    } else {
        return TEAM_HOLIDAYS_MAP_STATE_TO_PROPS;
    }
}

export const getBrowserLocation = (history) => {
    const currentLocation = history.location.pathname;
    if (currentLocation !== `/${getStateAgencyName()}/dashboard`) {
        return currentLocation
    }
    return '/holidays';
}

export const mapQueryToFilters = (queryObj) => {
    const initFilters = {
        type: defaultHolidayType,
    }

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'type':
                foundItem = holidayTypes.find(y => y.name === queryObj[x]);
                acc.type = foundItem ? foundItem : acc.type;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};

const dateFieldsComparerFunction = (a, b, orderBy) => {
    const isAscending = orderBy.direction === 'asc';
    return (((a[orderBy.column] === null) - (b[orderBy.column] === null))
        || moment.utc(a[orderBy.column]).diff(moment.utc(b[orderBy.column]))) * (isAscending ? 1 : -1);
};

const decimalFieldsComparerFunction = (a, b, orderBy) => {
    const isAscending = orderBy.direction === 'asc';
    return (((a[orderBy.column] === null) - (b[orderBy.column] === null))
        || (b[orderBy.column] - a[orderBy.column])) * (isAscending ? 1 : -1);
};

const customTypeFieldComparerFunction = (a, b, orderBy) => {
    const isAscending = orderBy.direction === 'asc';
    return (((a[orderBy.column] === null) - (b[orderBy.column] === null))
        || (a[orderBy.column]?.localeCompare(b[orderBy.column])) || (a.byArticle?.localeCompare(b.byArticle))) * (isAscending ? 1 : -1);
};

export const pendingHolidaysComparerFunctions = {
    [PENDING_HEAD_CELLS_VALUES.FROM]: dateFieldsComparerFunction,
    [PENDING_HEAD_CELLS_VALUES.TO]: dateFieldsComparerFunction,
    [PENDING_HEAD_CELLS_VALUES.DAYS]: decimalFieldsComparerFunction,
    [PENDING_HEAD_CELLS_VALUES.TYPE]: customTypeFieldComparerFunction,
    default: (a, b, orderBy) => {
        const isAscending = orderBy.direction === 'asc';
        return (((a[orderBy.column] === null || a[orderBy.column] === '') - (b[orderBy.column] === null || b[orderBy.column] === '')) ||
            a[orderBy.column]?.localeCompare(b[orderBy.column])) * (isAscending ? 1 : -1);
    },
};
