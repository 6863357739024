import React from 'react';

import FlagSharpIcon from '@material-ui/icons/FlagSharp';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';

import { ReactComponent as SuitcaseSolid } from 'assets/suitcase-solid.svg';

import { INITIAL_QUERY_STRING_JOBS_DASHBOARD } from 'components/JobsDashboard/JobsDashboardConstants';
import { temporaryRoute } from 'constants/commonConstants';

export const getClientMenuItems = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <AssessmentSharpIcon />,
        ROUTE: `/${agencyRouteName}/dashboard`
    },
    {
        TITLE: 'Projects',
        ICON: <FlagSharpIcon />,
        ROUTE: `/${agencyRouteName}/current-projects`
    },
    {
        TITLE: 'Jobs Dashboard',
        ICON: <SuitcaseSolid />,
        ROUTE: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`
    },
    {
        TITLE: 'Contracts',
        ICON: <InsertDriveFileSharpIcon />,
        ROUTE: `/${agencyRouteName}/contracts`
    },
    {
        TITLE: 'Billing',
        ICON: <AttachMoneySharpIcon />,
        ROUTE: `/${agencyRouteName}/billing`
    },
    {
        TITLE: 'Invoicing',
        ICON: <AttachMoneySharpIcon />,
        ROUTE: temporaryRoute
    },
    {
        TITLE: 'Account Manager',
        ICON: <PersonOutlineIcon />,
        ROUTE: `/${agencyRouteName}/your-account-manager`
    },
	{
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: `/${agencyRouteName}/settings/user-management`,
	},
];

export const getClientMenuItemsSaas = agencyRouteName => [
    {
        TITLE: 'Dashboard',
        ICON: <AssessmentSharpIcon />,
        ROUTE: `/${agencyRouteName}/dashboard`
    },
    {
        TITLE: 'Projects',
        ICON: <FlagSharpIcon />,
        ROUTE: `/${agencyRouteName}/current-projects`
    },
    {
        TITLE: 'Jobs Dashboard',
        ICON: <SuitcaseSolid />,
        ROUTE: `/${agencyRouteName}/jobs-dashboard${INITIAL_QUERY_STRING_JOBS_DASHBOARD}`
    },
    {
        TITLE: 'Contracts',
        ICON: <InsertDriveFileSharpIcon />,
        ROUTE: `/${agencyRouteName}/contracts`
    },
    {
        TITLE: 'Billing',
        ICON: <AttachMoneySharpIcon />,
        ROUTE: `/${agencyRouteName}/billing`
    },
    {
        TITLE: 'Invoicing',
        ICON: <AttachMoneySharpIcon />,
        ROUTE: temporaryRoute
    },
    {
        TITLE: 'Account Manager',
        ICON: <PersonOutlineIcon />,
        ROUTE: `/${agencyRouteName}/your-account-manager`
    },
    {
		TITLE: 'Settings',
		ICON: <SettingsSharpIcon />,
		ROUTE: `/${agencyRouteName}/settings/user-management`,
	},
];
