import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getStateAgencyName } from 'utils/helpers';

import { 
    fetchCoachingOpportunity,
    createCoachingOpportunitySession,
    fetchEmployeeCoachingOpportunitySessions
} from 'actions/coachingOpportunitiesActions';

import { getCoachingOpportunity, getUserId } from 'reducers';

import { CircularProgress, Paper } from '@material-ui/core';

import Section from 'components/Shared/Section';
import NoMatchesFound from 'components/Shared/NoMatchesFound';
import CoachingMentoringDetails from './CoachingMentoringDetails';
import SessionCard from 'components/Shared/SessionCard';
import PreviousButton from 'components/Shared/Buttons/PreviousButton';

import './CoachingMentoring.scss';

const CoachingMentoring = ({
    history,
    agencyId,
    userId,
    match,
    fetchCoachingOpportunity,
    coachingOpportunity,
    createCoachingOpportunitySession,
    fetchEmployeeCoachingOpportunitySessions,
}) => {
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        const coachingOpportunityId = match.params.coachingOpportunityId;

        fetchCoachingOpportunity(agencyId, coachingOpportunityId).then(() => {
            fetchEmployeeCoachingOpportunitySessions(agencyId, userId, coachingOpportunityId, true).then(() => {
                setIsPageLoading(false);
            });
        });
    }, []);

    if (isPageLoading) {
        return (
            <div className="coaching-wrapper">
                <div className="loader-wrapper">
                    <CircularProgress size={50} disableShrink className="centered" />
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="button-container">
                <PreviousButton text="Back to Coaching Opportunities" handleClick={() => history.push(`/${getStateAgencyName()}/trainings`)} />
            </div>
            <div className="training-wrapper">
                <Section 
                    title="Coach Profile"
                >
                    <CoachingMentoringDetails 
                        agencyId={agencyId}
                        userId={userId}
                        coachingOpportunity={coachingOpportunity}
                        createCoachingOpportunitySession={createCoachingOpportunitySession}
                    />
                </Section>
                <Section title="Upcoming Coaching Sessions">
                    { coachingOpportunity.coachingSessions.length
                        ?
                        <div className="global-sessions-list">
                            { coachingOpportunity.coachingSessions.map((x) => 
                                <SessionCard
                                    key={x._id}
                                    session={x}
                                    title={x.coachingOpportunity.coachName}
                                    footerContent={x.coachingOpportunity.title}
                                />) }
                        </div>
                        :
                        <Paper className="no-matches-paper centered-content">
                            <NoMatchesFound text="No Upcoming Coaching Sessions" noImage />
                        </Paper>

                    }
                </Section>
            </div>
        </>
    )
};

const mapStateToProps = (state) => ({
    coachingOpportunity: getCoachingOpportunity(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    fetchCoachingOpportunity,
    createCoachingOpportunitySession,
    fetchEmployeeCoachingOpportunitySessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoachingMentoring);
