import React, { createContext } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import SelectColumns from './SelectColumns';
import SlimSelect from 'components/Shared/SlimSelect';
import MultiselectFilter from 'components/Shared/MultiselectFilter';
import MultiSelectAutocomplete from 'components/Shared/MultiSelectAutocomplete';
import SelectFilterList from 'components/Shared/SelectFilterList';
import GroupedSelectFilterList from 'components/Shared/GroupedSelectFilterList';
import DateRangeFilter from 'components/Shared/DateRangeFilter';

import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton } from '@material-ui/core';
import EventSharpIcon from '@material-ui/icons/EventSharp';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import './LeftAlignedActions.scss';

export const LeftAlignedActionsContext = createContext();
const { Provider } = LeftAlignedActionsContext;

const LeftAlignedActions = ({
    children,
}) => {
    const context = {};

    return (
        <div className="left-aligned-section-actions-container">
            <Provider value={context}>
                {children}
            </Provider>
        </div>
    );
};

const SearchFilter = ({
    fieldValue,
    onChangeHandler,
    placeholder,
    classes
}) => {
    return (
        <InputBase
            className={`search-term-field ${classes && classes.join(" ")}`}
            placeholder={placeholder ? placeholder : "Search"}
            autoComplete="off"
            name="searchTerm"
            value={fieldValue}
            onChange={onChangeHandler}
            startAdornment={(
                <InputAdornment position="start">
                    <SearchIcon className="search-icon icon" />
                </InputAdornment>
            )}
        />
    );
};

const SelectOneFilter = ({
    name,
    value,
    onChangeHandler,
    items,
    classes,
    checkSelected,
}) => {
    return (
        <SlimSelect
            className={`${classes && classes.join(" ")}`}
            // className="priority-filter"
            value={value}
            onChangeHandler={onChangeHandler}
            items={items}
            name={name}
            checkSelected={checkSelected}
        />
    );
};

const SelectManyFilter = ({
    title,
    value,
    onChangeHandler,
    items,
    name,
}) => {
    return (
        <MultiselectFilter
            // className="client-filter"
            title={title}
            value={value}
            onChangeHandler={onChangeHandler}
            items={items}
            name={name}
        />
    );
};

const SelectVisibleColumns = ({
    columnsTitles,
    displayColumns,
    setDisplayColumns
}) => {
    return <SelectColumns
        displayColumns={displayColumns}
        setDisplayColumns={setDisplayColumns}
        columnsTitles={columnsTitles}
    />
};

const SelectManyAutocomplete = ({
    name,
    value,
    onChangeHandler,
    items,
}) => {
    return (
        <MultiSelectAutocomplete
            currentlyChecked={value}
            onChangeHandler={onChangeHandler}
            items={items}
            name={name}
        />
    );
};

const DateFilter = ({
    openTo,
    views,
    format,
    value,
    minDate,
    maxDate,
    onChange,
    variant,
    inputVariant,
}) => {
    return (
        <div className="date-picker-container">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    openTo={openTo}
                    views={views}
                    format={format}
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={onChange}
                    variant={variant}
                    inputVariant={inputVariant}
                    autoOk
                    PopoverProps={{
                        className: 'date-picker-popover',
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <EventSharpIcon className='calendar-icon' fontSize='small' />
                            </InputAdornment>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

const NotificationsContainer = ({
    text,
    color,
    isLowerCase
}) => {
    return (
        <div className="notification-button-container">
            <PrimaryButton
                text={text}
                disabled={true}
                className={`${color} ${isLowerCase ? 'lower-case-button' : ''}`}
            />
        </div>
    );
};

const ClearFiltersButton = ({
    handleClearFilters,
    text
}) => {
    return <PrimaryButton
        text={text}
        handleClick={handleClearFilters}
    />
};

const RefreshIconButton = ({
    handleRefresh,
}) => {
    return (
        <IconButton onClick={handleRefresh}>
            <RefreshIcon />
        </IconButton>);
};

const SelectFilterListWrapper = ({
    name,
    value,
    title,
    items,
    onChangeHandler,
    closeMenuOnSelect,
}) => (
    <SelectFilterList
        name={name}
        value={value}
        title={title}
        items={items}
        handleChange={onChangeHandler}
        closeMenuOnSelect={closeMenuOnSelect}
    />
);

const GroupedSelectFilterListWrapper = ({
    title,
    filters,
    groups,
    handleChange,
    className,
    anchorOrigin,
    closeMenuOnSelect,
}) => {
    return (
        <GroupedSelectFilterList
            title={title}
            values={filters}
            groups={groups}
            className={className}
            handleChange={handleChange}
            anchorOrigin={anchorOrigin}
            closeMenuOnSelect={closeMenuOnSelect}
        />
    );
};

const DateRangeFilterWrapper = ({
    title,
    className,
    values,
    handleChange
}) => {
    return (
        <DateRangeFilter
            title={title}
            className={className}
            values={values}
            handleChange={handleChange}
        />
    );
};

LeftAlignedActions.SearchFilter = SearchFilter;
LeftAlignedActions.SelectOneFilter = SelectOneFilter;
LeftAlignedActions.SelectManyFilter = SelectManyFilter;
LeftAlignedActions.SelectVisibleColumns = SelectVisibleColumns;
LeftAlignedActions.SelectManyAutocomplete = SelectManyAutocomplete;
LeftAlignedActions.DateFilter = DateFilter;
LeftAlignedActions.NotificationsContainer = NotificationsContainer;
LeftAlignedActions.ClearFiltersButton = ClearFiltersButton;
LeftAlignedActions.RefreshIconButton = RefreshIconButton;
LeftAlignedActions.SelectFilterList = SelectFilterListWrapper;
LeftAlignedActions.GroupedSelectFilterListWrapper = GroupedSelectFilterListWrapper;
LeftAlignedActions.DateRangeFilterWrapper = DateRangeFilterWrapper;

export default LeftAlignedActions;
