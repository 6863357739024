import React from 'react';
import ConfirmDialog from '../ConfirmDialog';

const ConfirmCancelInterviewDialog = ({ openDialog, handleCancelInterview, handleCloseDialog }) => {

    return (
        <ConfirmDialog
            openDialog={openDialog}
            dialogTitle={'Are you sure you want to cancel this invitation?'}
            confirmButtonName={'Yes'}
            discardButtonName={'No'}
            handleConfirmDialog={handleCancelInterview}
            handleCloseDialog={handleCloseDialog}
        />

    )
}

export default ConfirmCancelInterviewDialog;
