import { combineReducers } from 'redux';

import {
    DEPARTMENT_DETAILS_FETCH_SUCCESS,
    DEPARTMENTS_FETCH_SUCCESS,
    DEPARTMENTS_REPLACE_SUCCESS,
    DEPARTMENT_CREATE_SUCCESS,
    DEPARTMENT_DELETE_SUCCESS,
    DEPARTMENT_EDIT_SUCCESS,
} from 'actions/actionTypes';

const departmentInitialState = {
    _id: '',
    name: '',
    admins: [],
    membersCount: 0,
};

const department = (state = departmentInitialState, action) => {
    if (state._id && state._id !== action.payload._id) {
        return state;
    }

    switch (action.type) {
        case DEPARTMENT_EDIT_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
}

export const departmentsList = (state = [], action) => {
    switch (action.type) {
        case DEPARTMENTS_FETCH_SUCCESS:
            return [...state, ...action.payload];
        case DEPARTMENTS_REPLACE_SUCCESS:
            return action.payload;
        case DEPARTMENT_CREATE_SUCCESS:
            return [...state, action.payload];
        case DEPARTMENT_EDIT_SUCCESS:
            return state.map(x => department(x, action))
        case DEPARTMENT_DELETE_SUCCESS:
            return state.filter(x => x._id !== action.payload)
        default:
            return state;
    }
};

const departmentDetailsInitialState = {
    _id: '',
    name: '',
    managers: [],
    members: [],
}

const departmentDetails = (state = departmentDetailsInitialState, action) => {
    switch (action.type) {
        case DEPARTMENT_DETAILS_FETCH_SUCCESS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}

const departments = combineReducers({
    departmentsList,
    departmentDetails,
});

export default departments;

export const getDepartments = (state, page, count) => {
    const from = page * count;
    const to = page * count + count;

    return state.departmentsList.slice(from, to || state.departmentsList.length);
}
export const getDepartmentDetails = state => state.departmentDetails;
