import React from 'react';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import './AssigneeDialog.scss';

const AssigneeDialog = ({
    handleSubmit,
    handleChange,
    values,
    onClose,
    open,
    admins,
}) => {
    const onFormSubmit = () => {
        handleSubmit();
    };

    return (
        <CommonFormikDialog
            className="assignee-dialog"
            open={open}
            onClose={onClose}
            dialogTitle="Edit Assignee"
            saveBtnText="SAVE"
            discardBtnText="DISCARD"
            onSave={onFormSubmit}
        >
            <form className="assignee-dialog-form" autoComplete="off">
                <div className="form-row">
                    <div className="form-cols">
                        <div className="col-1of2">
                            <TextField
                                select
                                value={values.assignee}
                                variant="outlined"
                                onChange={handleChange}
                                name="assignee"
                                id="assignee"
                                fullWidth
                            >
                                {admins.map((option) => {
                                    return (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </div>
                    </div>
                </div>
            </form>
        </CommonFormikDialog>
    );
};

export default AssigneeDialog;

