export const TABLE_COLUMNS = [
    { name: 'Name', value: 'name', isSortable: true },
    { name: 'From', value: 'from', isSortable: true },
    { name: 'To', value: 'to', isSortable: true },
    { name: 'Days', value: 'numberOfDays', isSortable: true },
    { name: 'Type', value: 'type', isSortable: true },
];

export const DEFAULT_TABLE_COLUMNS = [
    'name',
    'from',
    'to',
    'numberOfDays',
    'type',
];

export const TABLE_FILTERS = [
    { key: 'type', name: 'Type', value: ['Paid', 'Unpaid'] },
];

export const SORT_QUERY_SELECTOR = 'pendingSort';
export const PAGE_QUERY_SELECTOR = 'pendingPage';
export const SEARCH_QUERY_SELECTOR = 'pendingSearch';

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const TABLE_INITIAL_QUERY = {
    filters: JSON.stringify({}),
    page: 1,
    search: '',
    sortBy: 'from',
    sortByDir: 'desc',
    pagesToLoad: 5,
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
};

export const TABLE_QUERY_MAP = {
    filters: 'filters',
    page: PAGE_QUERY_SELECTOR,
    search: SEARCH_QUERY_SELECTOR,
    sortBy: SORT_QUERY_SELECTOR,
    sortByDir: `${SORT_QUERY_SELECTOR}Dir`,
};
