import React from 'react';
import { Formik } from 'formik';

import CommonFormikDialog from 'components/Shared/CommonFormikDialog';
import Typography from '@material-ui/core/Typography';

import './DeleteApplicatonDialog.scss';

const DeleteApplicationDialog = ({
    handleDeleteApplication,
    handleCloseDialog,
    isDialogOpen,
}) => {

    return (
        <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(false);
                await handleDeleteApplication();
                handleCloseDialog();
            }}
        >
            {(props) => (
                <CommonFormikDialog
                    className="delete-application-dialog"
                    size="sm"
                    dialogTitle="Delete Profile"
                    saveBtnText="Delete"
                    discardBtnText="Discard"
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    onSave={props.handleSubmit}
                >
                    <Typography className="delete-application-dialog-text" >
                        If you proceed this application will be deleted all together.
                        Together with all employee notifications, interview notifications, client notifications, etc.
                    </Typography>
                </CommonFormikDialog>
            )}
        </Formik>
    );
};

export default DeleteApplicationDialog;
