import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Formik } from 'formik';

import AddRecruiterFormView from './AddRecruiterFormView';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { getEmployees, getCountries } from 'reducers';
import { fetchInternalEmployees } from 'actions/employeeActions'
import { fetchAllCountries } from 'actions/staticDataActions';

import { ROLES } from 'constants/userConstants';

import {
    validateRequiredFields,
    validateEmailAddress,
    validatePhoneNumber,
    validateInputLength,
} from 'utils/formValidations';

import './AddRecruiterDialog.scss'

const AddRecruiterDialog = ({
    openDialog,
    handleCloseDialog,
    agencyId,
    handleSubmitClick,
    accountManagers,
    fetchAllCountries,
    fetchInternalEmployees,
    countries,
}) => {
    const maxLength = 100;
    const employeeRequiredFields = ['firstName', 'lastName'];
    const employeeRoles = [ROLES.ADMIN];

    useEffect(() => {
        fetchAllCountries();
        fetchInternalEmployees(agencyId, employeeRequiredFields, employeeRoles);
    }, []);

    return (
        <Formik
            initialValues={{
                name: '',
                contactPerson: '',
                email: '',
                contactNumber: '',
                accountManager: '',
                country: '',
            }}
            validate={values => {
                return {
                    ...validateRequiredFields(values, ['name', 'accountManager']),
                    ...validateInputLength(values, [
                        { property: 'name', maxLength },
                        { property: 'contactPerson', maxLength },
                        { property: 'email', maxLength },
                    ]),
                    ...validateEmailAddress(values, 'email'),
                    ...validatePhoneNumber(values, 'contactNumber')
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                values.name = values.name.trim();
                values.email = values.email.toLowerCase().trim();
                values.contactPerson = values.contactPerson.trim();
                setSubmitting(false);
                handleSubmitClick(values);
            }}
        >
            {(props) =>
                <CommonFormikDialog
                    className="add-recruiter-dialog"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    fullWidth={true}
                    maxWidth={'sm'}
                    onSave={props.handleSubmit}
                    dialogTitle='Add new recruiter'
                >
                    <AddRecruiterFormView
                        {...props}
                        agencyId={agencyId}
                        countries={countries}
                        accountManagers={accountManagers}
                    />
                </CommonFormikDialog>
            }
        </Formik>
    )
}

const mapStateToProps = (state, props) => ({
    accountManagers: getEmployees(state).slice().sort(props.sortAlphabetically),
    countries: getCountries(state),
});

const mapDispatchToProps = {
    fetchAllCountries,
    fetchInternalEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRecruiterDialog);
