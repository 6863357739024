import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import SecondaryButton from '../Buttons/SecondaryButton';

import './ConfirmDialog.scss';

const ConfirmDialog = ({
    openDialog,
    dialogTitle,
    dialogSubtitle,
    confirmButtonName,
    discardButtonName,
    handleConfirmDialog,
    handleCloseDialog,
    hasConfirmButton = true,
    hasDiscardButton = true,
}) => (
    <Dialog className="confirm-dialog-wrapper" open={openDialog} onClose={handleCloseDialog} maxWidth={'md'}>
        <MuiDialogTitle disableTypography className="confirm-dialog-header">
            <IconButton onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
        
        <DialogContent className="dialog-content">
            <Typography variant="subtitle2" className="dialog-text dialog-title">
              {dialogTitle}
            </Typography>

            <Typography variant="body1" className="dialog-text dialog-subtitle">{dialogSubtitle}</Typography>

            <DialogActions className="dialog-actions">
                { hasDiscardButton && <SecondaryButton buttonStyle="discard" handleClick={handleCloseDialog} text={discardButtonName} /> } 
                { hasConfirmButton && <SecondaryButton buttonStyle="save" handleClick={handleConfirmDialog} text={confirmButtonName} type="submit" /> }
            </DialogActions>
        </DialogContent>
    </Dialog>
);

export default ConfirmDialog;
