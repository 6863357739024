import React from 'react';
import { useHistory } from 'react-router';

import MyApplicationCard from './MyApplicationCard';
import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { ReactComponent as ApplicationIcon } from 'assets/icon-application.svg';

import { getStateAgencyName } from 'utils/helpers';

import './MyApplicationsList.scss';

const MyApplicationsList = ({ applications }) => {
    const history = useHistory();

    const handleApplyClick = () => history.push(`/${getStateAgencyName()}/job-opportunities`);

    return (
        <div className="my-applications-list-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle sectionTitle='My Applications' />
            </SectionActionsWrapper>

            {applications?.length > 0
                ? <div className="my-applications-list">
                    {applications.map(application =>
                        <MyApplicationCard
                            key={application.applicationId}
                            application={application}
                        />
                    )}
                </div>
                : <div className="no-applications-found">
                    <div className="icon-wrapper">
                        <ApplicationIcon />
                    </div>
                    <p>You haven't applied to any jobs yet.</p>
                    <PrimaryButton
                        className="no-applications-apply-btn"
                        text="Apply"
                        handleClick={handleApplyClick}
                    />
                </div>
            }
        </div>
    );
};

export default MyApplicationsList;
