import React from 'react';
import { connect } from 'react-redux';

import HottestTalentsTable from './HottestTalentsTable';

import { fetchAllHottestTalents } from 'actions/userActions';
import { getAgencyId, getHottestTalents } from 'reducers';
import { updateEmployeeOccupationStatus } from 'actions/employeeActions';

const HottestTalents = ({
    agencyId,
    fetchAllHottestTalents,
    updateEmployeeOccupationStatus,
}) => {
    const tableAction = fetchAllHottestTalents.bind(null, agencyId);

    return (
        <HottestTalentsTable
            agencyId={agencyId}
            dataSelector={getHottestTalents}
            action={tableAction}
            updateEmployeeOccupationStatus={updateEmployeeOccupationStatus}
        />
    );
};

const mapStateToProps = (state) => ({
    agencyId: getAgencyId(state),
});

const mapDispatchToProps = {
    fetchAllHottestTalents,
    updateEmployeeOccupationStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(HottestTalents);
