import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import Dialog from 'components/Shared/Dialog';
import {
    IconButton,
    DialogTitle,
    DialogContent
} from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import { getAgencyId } from 'reducers';
import { uploadTrainingFiles } from 'actions/agencyActions';
import AddFilesDialogFormView from './AddFilesDialogFormView';
import { validateFilesArrayLength } from 'utils/formValidations';

import './AddFilesDialog.scss';

const AddFilesDialog = ({
    topicId,
    agencyId,
    trainingId,
    openDialog,
    handleCloseDialog,
    uploadTrainingFiles
}) => {
    return (
        <Formik
            initialValues={{
                filesAttached: []
            }}
            validate={values => {
                return {
                    ...validateFilesArrayLength(values, 'filesAttached', 1)
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                const { filesAttached, ...rest } = values;

                const data = new FormData();

                data.append('trainingInfo', JSON.stringify({ ...rest, filesCount: filesAttached.length }));

                filesAttached.forEach((file, i) => {
                    data.append(`attachedfile${i + 1}`, file);
                });

                uploadTrainingFiles(agencyId, trainingId, topicId, data);
                handleCloseDialog();
            }}
        >
            {(props) =>
                <Dialog className="training-add-files-dialog" open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle className="dialog-header">
                        Upload Training Files
                        <IconButton className="dialog-close-btn" onClick={handleCloseDialog}>
                            <IconClose />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <AddFilesDialogFormView {...props} handleCloseDialog={handleCloseDialog} />
                    </DialogContent>
                </Dialog>
            }
        </Formik>
    )
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state)
})

const mapDispatchToProps = {
    uploadTrainingFiles
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFilesDialog);
