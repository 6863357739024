import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchClientInterviewDates } from 'actions/clientActions';
import { getClientInterviewDates, getClientInterviewsDataFetchState } from 'reducers';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';

import isDateWithInterviews from 'hoc/isDateWithInterviews';

import { logEvent } from 'utils/amplitude';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR } from 'constants/commonConstants';
import { DATE_PICKER_FORMAT_WITH_DASHES } from 'constants/timesFormat';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import { ReactComponent as LeftCalendarArrow } from 'assets/calendar-arrow-left.svg';
import { ReactComponent as RightCalendarArrow } from 'assets/calendar-arrow-right.svg';
import { ReactComponent as CalendarIcon } from 'assets/interviews-calendar-icon.svg';

import './JobCalendar.scss';

const JobCalendar = ({
    clientId,
    agencyId,
    fetchClientInterviewDates,
    interviewsData,
    dataFetchState
}) => {
    const [currentDate, setCurrentDate] = useState(moment().format());
    const [isInitialPageVisit, setIsInitialPageVisit] = useState(true);
    const endDate = moment().startOf('month').add(6, 'M').endOf('month');

    useEffect(() => {
        fetchClientInterviewDates(agencyId, clientId, moment(currentDate), moment(endDate), isInitialPageVisit);
        setIsInitialPageVisit(false);
    }, []);

    moment.updateLocale("en", {
        week: {
            dow: 1
        }
    });

    const onMonthChange = (value) => {
        const currentDateValue = moment.utc(value).add(1, 'months').format() === moment.utc().format() ? moment().utc().format() : moment.utc(value).add(1, 'months').startOf('month').format();

        setCurrentDate(currentDateValue);
    };

    const handleDayChange = (value) => {
        const interviews = interviewsData.find(event => moment(event.date).utc().format(DATE_PICKER_FORMAT_WITH_DASHES) === moment(value).utc().format(DATE_PICKER_FORMAT_WITH_DASHES));
        
        if (interviews) {
            logEvent(AMPLITUDE_EVENT_TYPES.CLIENT_CLICK_CALENDAR_EVENT);
        }
        
        setCurrentDate(moment(value).format())
    };



    return (
        <div className="jobs-calendar" role="navigation">
            <h3 className="job-calendar-title">
                <span className="job-calendar-title-icon"><CalendarIcon /></span>
                <span className="job-calendar-title-text">Interviews</span>
            </h3>
            {dataFetchState?.isDataFetching
                ? <CircularProgress size={25} className="calendar-loader" disableShrink />
                : <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        autoOk={true}
                        className="deadline-date-field"
                        name="interviews"
                        label="Interviews"
                        disableToolbar
                        minDate={moment()}
                        maxDate={endDate}
                        variant="static"
                        fullWidth
                        leftArrowIcon={<LeftCalendarArrow />}
                        rightArrowIcon={<RightCalendarArrow />}
                        inputVariant="outlined"
                        value={currentDate}
                        onMonthChange={onMonthChange}
                        onChange={value => handleDayChange(value)}
                        renderDay={isDateWithInterviews.bind(null, interviewsData)}
                        format={DATE_FORMAT_WITH_DOTS_FULL_YEAR}
                        allowKeyboardControl={true}
                    />
                </MuiPickersUtilsProvider>
            }
            <div className="jobs-calendar-events-list">
                <h3 className="jobs-calendar-events-list-date">{moment(currentDate).format(DATE_PICKER_FORMAT_WITH_DASHES) === moment().utc().format(DATE_PICKER_FORMAT_WITH_DASHES) ? "Today" : moment(currentDate).format("dddd, MMMM Do")}</h3>
                {interviewsData?.length > 0 && interviewsData?.find(event => moment(event.date).utc().format(DATE_PICKER_FORMAT_WITH_DASHES) === moment(currentDate).utc().format(DATE_PICKER_FORMAT_WITH_DASHES)) ?
                    interviewsData.
                        filter(event => moment(event.date).format(DATE_PICKER_FORMAT_WITH_DASHES) === moment(currentDate).format(DATE_PICKER_FORMAT_WITH_DASHES))
                        .map(filteredEvent => (
                            <div className="job-calendar-event-box" key={filteredEvent._id}>
                                <p className="job-calendar-event-box-position">{filteredEvent.position}</p>
                                <div className="job-calendar-event-box-information">
                                    <span className="job-calendar-event-box-applicant-name">{filteredEvent.applicantName}</span>
                                    <span className="job-calendar-event-box-event-time">{moment(filteredEvent.date).format('h:mm A')}</span>
                                </div>
                            </div>
                        ))
                    :
                    <p className="job-calendar-event-box-no-events">No scheduled interview</p>
                }
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    interviewsData: getClientInterviewDates(state),
    dataFetchState: getClientInterviewsDataFetchState(state),
});

const mapDispatchToProps = {
    fetchClientInterviewDates
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCalendar);
