import React, { useState } from 'react';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ExpandMoreSharpIcon from '@material-ui/icons/ExpandMoreSharp';

import { ReactComponent as CalendarIcon } from 'assets/icon-calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icon-clock.svg';

import { DATE_FORMAT_WITH_DOTS_FULL_YEAR, CONTINENTAL_TIME_FORMAT } from 'constants/commonConstants';
import { INITIAL_SESSIONS_SHOWN_COUNT } from './BookedSessionsConstants';
import { ROLES } from 'constants/userConstants';

import './BookedSessions.scss';

const BookedSessions = ({
    coachingSessions,
}) => {
    const [showMore, setShowMore] = useState(false);
    const sliceCount = showMore ? coachingSessions.length : INITIAL_SESSIONS_SHOWN_COUNT;

    const handleShowClick = () => setShowMore(prevState => !prevState);

    const TYPE_MAPPER = {
        [ROLES.EMPLOYEE]: 'Employee',
        [ROLES.ADMIN]: 'Employee',
    };  

    return (
        <Paper className="booked-coaching-sessions">
            <Timeline
                className="sessions-timeline-wrapper"
                align="alternate"
            >
                <TimelineItem className="placeholder-connector">
                    <TimelineSeparator>
                        <TimelineConnector />
                    </TimelineSeparator>
                </TimelineItem>
                {coachingSessions
                    .slice(0, sliceCount)
                    .map(x => (
                        <TimelineItem
                            key={x._id}
                            className="booked-session-item"
                        >
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <div className="booked-session-content-wrapper">
                                    <div className="booked-session-date-time-information">
                                        <div className="session-start-date">
                                            <CalendarIcon className="session-icon" />
                                            <p className="start-date">{moment(x.calendlySessionInfo.startDateTime).format(DATE_FORMAT_WITH_DOTS_FULL_YEAR)}</p>
                                        </div>
                                        <div className="session-start-time">
                                            <ClockIcon className="session-icon" />
                                            <p className="start-time">{moment(x.calendlySessionInfo.startDateTime).format(CONTINENTAL_TIME_FORMAT)}</p>
                                        </div>
                                    </div>

                                    <div className="booked-session-card">
                                        <div className="employee-information-wrapper">
                                            <div>
                                                <p className="employee-type">{TYPE_MAPPER[x.employee.role]}</p>
                                                <p className="employee-name">{x.employee.firstName} {x.employee.lastName}</p>
                                            </div>
                                            <Avatar
                                                alt="Profile Logo"
                                                className="employee-profile-logo"
                                                src={x.employee.profilePictureImgUrl}
                                            />
                                        </div>
                                        <div className="coaching-opportunity-information-wrapper">
                                            <p className="coaching-opportunity-title">{x.coachingOpportunity.title}</p>
                                        </div>
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
            </Timeline>

            {coachingSessions.length > INITIAL_SESSIONS_SHOWN_COUNT &&
                <div className={`show-container ${showMore && 'isShowMore'}`} onClick={handleShowClick}>
                    <p className="show-text">Show {showMore ? "Less" : "More"}</p>
                    <ExpandMoreSharpIcon className="show-icon" />
                </div>
            }
        </Paper>
    );
};

export default BookedSessions;
