import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PaperElement from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement';
import PaperHeader from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperHeader';
import PaperContent from 'components/CandidateProfileJourney/SharedCandidateJourneyComponents/PaperElement/PaperContent';

import { ReactComponent as InfoIcon } from "assets/info-icon.svg";

import { HINT } from './VideoIntroductionConstants';

import './VideoIntroductionView.scss'

const VideoIntroductionView = ({
    canBookCalndlyCall,
    agencyName,
    permission,
    setShowHintPopup,
    showHintPopup,
    handleAudioChange,
    audioInputSelected,
    audioInputList,
    handleVideoChange,
    videoInputSelected,
    videoInputList,
    videoRef,
}) => {
    return (
        <PaperElement classes={["sm"]}>
            <PaperHeader title="Personal Introduction" classes={["video-introduction-header-flex"]}>
                <InfoIcon onMouseEnter={() => setShowHintPopup(true)} onMouseLeave={() => setShowHintPopup(false)} />
                {
                    showHintPopup &&
                    <div className="info-text">
                        {HINT(agencyName)}
                    </div>
                }
            </PaperHeader>
            <PaperContent>
                <p className="paper-element-description">We'd like to know more about your personality so we ask you to record a quick informal video telling us more about yourself.{canBookCalndlyCall && ' You also have the option to book a call with our recruitment team.'} </p>

                <div className="input-container">
                    <p className="paper-element-description">Do you allow access to your camera?</p>

                    <div className="row">
                        <TextField
                            className="base-input"
                            variant="outlined"
                            select
                            disabled={!permission}
                            id="select-audio-device"
                            name="select-audio-device"
                            onChange={(event) => { handleAudioChange(event) }}
                            label="Select Audio Input"
                            InputLabelProps={{ shrink: true }}
                            value={audioInputSelected.label || ""}
                            fullWidth
                        >
                            {audioInputList.map((device, i) => (
                                <MenuItem key={device.deviceId} value={device.label}>
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>

                <div className="input-container">
                    <p className="paper-element-description">Do you allow access to your microphone?</p>

                    <div className="row">
                        <TextField
                            className="base-input"
                            variant="outlined"
                            select
                            disabled={!permission}
                            id="select-video-device"
                            name="select-video-device"
                            onChange={(event) => { handleVideoChange(event) }}
                            label="Select Video Input"
                            InputLabelProps={{ shrink: true }}
                            value={videoInputSelected.label || ""}
                            fullWidth
                        >
                            {videoInputList.map((device, i) => (
                                <MenuItem key={device.deviceId} value={device.label}>
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                </div>
                <div className="camera-container">
                    <video ref={videoRef} muted controls={false}>
                        Video stream not available.
                    </video>
                </div>
            </PaperContent>
        </PaperElement>
    )
};

export default VideoIntroductionView;
