export const REQUIRED_FIELDS = [
    'projectNames',
    'address',
    'country',
    'email',
    'contactNumber',
    'companyInfo',
    'isVetted',
    'profileSkillsNames',
    'profileSkillsTitles',
];
