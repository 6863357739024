import React from 'react';
import MainLayout from '../Main';

import './CandidateLayout.scss';

const CandidateLayout = ({ children }) => {
    return (
        <MainLayout hideHeader={true}>
            <div className="candidate-layout-wrapper">
                <main className="candidate-layout-body-wrapper">
                    <div className="candidate-layout-content">
                        {children}
                    </div>
                </main>
            </div>
        </MainLayout>
    );
};

export default CandidateLayout;
