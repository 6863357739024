export const INTERVIEW_INVITATION_STATUSES = {
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
    PENDING: 'Pending',
};

export const INTERVIEW_INVITATION_CONTRIBUTORS = {
    PARTICIPANT: 'participant',
    INTERVIEWER: 'interviewer',
};
