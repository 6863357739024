import React, { useEffect, useState, useCallback } from 'react';
import { Switch, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import { filterMenuItems } from 'utils/navigationUtils';

import {
    fetchClientInformation,
    updateBasicInformation,
    updateContactInformation,
    updateInvoiceInformation,
    updateClientAccountManagementInformation,
    updateAvatar,
    deleteAvatar,
} from 'actions/clientActions';
import { resetClientInformationStates } from 'actions/sectionsLoadersActions';
import { restoreUsers } from 'actions/userActions';

import {
    getClientBasicInformation,
    getAgencyId,
    getClientContactInformation,
    getClientInvoicingInformation,
    getClientAccountManager,
    isHiddenSections,
    getClientDeleteInformation,
} from 'reducers';

import CompanyInformation from '../CompanyInformation';
import ClientProjects from './Projects';
import Billing from '../Billing';
import Contracts from 'components/Contracts';
import Tasks from './Tasks';
import Jobs from './Jobs';
import Overview from './Overview';
import ProjectFeedbackReview from './Projects/ProjectFeedbackReview';
import JobPage from 'components/JobPage';
import SubmenuActionButton from 'components/Shared/SubmenuActionButton';
import ConfirmRestoreUserDialog from 'components/Shared/ConfirmRestoreUserDialog/ConfirmRestoreUserDialog';

import SubmenuContentLayout from 'layouts/SubmenuContentLayout';

import { getStateAgencyName } from 'utils/helpers';

import { clientSubmenuItems, HIDDEN_MENU_ITEMS } from './ClientConstants';
import { CONTRACT_TYPES } from 'constants/contractsConstants';
import { ROLES } from 'constants/userConstants';

import defaultLogo from 'assets/default-logo.svg';

import './Client.scss';

const Client = ({
    match,
    agencyId,
    basicInformation,
    contactInformation,
    invoicingInformation,
    fetchClientInformation,
    updateBasicInformation,
    updateContactInformation,
    updateInvoiceInformation,
    updateClientAccountManagementInformation,
    updateAvatar,
    deleteAvatar,
    accountManager,
    hiddenSections,
    resetClientInformationStates,
    isDeleted,
    restoreUsers,
    displayMode,
}) => {
    const { id: clientId } = useParams();

    const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

    const handleOpenRestoreDialog = () => setOpenRestoreDialog(true);
    const handleCloseRestoreDialog = () => setOpenRestoreDialog(false);

    const filterMenuItemCallback = useCallback(filterMenuItems(Object.values(clientSubmenuItems), HIDDEN_MENU_ITEMS), []);

    useEffect(() => {
        fetchClientInformation(agencyId, clientId);
        return resetClientInformationStates
    }, [clientId]);

    const restoreUser = () => {
        restoreUsers([clientId], ROLES.CLIENT);
        handleCloseRestoreDialog();
    }

    const restoreClientButton = <SubmenuActionButton handleClick={handleOpenRestoreDialog} text="Restore Profile" buttonStyle="save" />;

    return (
        <SubmenuContentLayout
            path={match.url}
            profileName={basicInformation.name}
            avatarUrl={basicInformation.logoImgUrl || defaultLogo}
            items={hiddenSections
                ? filterMenuItemCallback
                : Object.values(clientSubmenuItems)}
            actionButtons={isDeleted && restoreClientButton}
            displayMode={displayMode}
        >
            <Switch>
                <Route path={match.path} exact render={(props) => (
                    <Overview
                        {...props}
                        invoicingInformation={invoicingInformation}
                        agencyId={agencyId}
                        clientId={clientId}
                        updateAccountManagementInformation={updateClientAccountManagementInformation}
                        updateInvoiceInformation={updateInvoiceInformation}
                        accountManager={accountManager}
                    />)}
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.companyInformation.path}`}
                    render={(props) => (
                        <CompanyInformation
                            {...props}
                            updateAccountManagementInformation={updateClientAccountManagementInformation}
                            accountManager={accountManager}
                            basicInformation={basicInformation}
                            companyId={clientId}
                            agencyId={agencyId}
                            isAdmin={true}
                            contactInformation={contactInformation}
                            invoicingInformation={invoicingInformation}
                            updateBasicInformation={updateBasicInformation}
                            updateContactInformation={updateContactInformation}
                            updateInvoiceInformation={updateInvoiceInformation}
                            updateAvatar={updateAvatar}
                            deleteAvatar={deleteAvatar}
                            isEmailChangeDisabled={false}
                            isClient={true}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.contracts.path}`}
                    render={(props) => (
                        <Contracts
                            {...props}
                            userId={clientId}
                            agencyId={agencyId}
                            contractTypes={CONTRACT_TYPES.CLIENT}
                            isAdmin={true}
                            receiverRole={ROLES.CLIENT}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}/projects/reviews/:projectId`}
                    render={(props) =>
                        <ProjectFeedbackReview
                            {...props}
                            invoicingInformation={invoicingInformation}
                            clientId={clientId}
                            agencyId={agencyId}
                        />
                    }
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.projects.path}`}
                    render={(props) => (
                        <ClientProjects
                            {...props}
                            clientId={clientId}
                            agencyId={agencyId}
                            contactInformation={contactInformation}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.jobOpportunities.path}`}
                    render={(props) => (
                        <Jobs
                            {...props}
                            clientId={clientId}
                            agencyId={agencyId}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.jobOpportunities.path}/:jobId`}
                    render={(props) => (
                        <JobPage
                            {...props}
                            agencyId={agencyId}
                            backPath={`/${getStateAgencyName()}/clients/${clientId}/jobs`}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.path}${clientSubmenuItems.billing.path}`}
                    render={(props) => (
                        <Billing {...props}
                            userId={clientId}
                            agencyId={agencyId}
                            isAdmin={true}
                            invoicerRole={ROLES.CLIENT}
                        />
                    )}
                />
                <Route path={`${match.path}${clientSubmenuItems.tasksReminders.path}`} component={Tasks} />
            </Switch>

            <ConfirmRestoreUserDialog
                openDialog={openRestoreDialog}
                handleCloseDialog={handleCloseRestoreDialog}
                handleConfirmDialog={restoreUser}
            />
        </SubmenuContentLayout>
    )
};

const mapStateToProps = (state) => ({
    basicInformation: getClientBasicInformation(state),
    agencyId: getAgencyId(state),
    contactInformation: getClientContactInformation(state),
    invoicingInformation: getClientInvoicingInformation(state),
    accountManager: getClientAccountManager(state),
    hiddenSections: isHiddenSections(state),
    isDeleted: getClientDeleteInformation(state),
});

const mapDispatchToProps = {
    fetchClientInformation,
    updateBasicInformation,
    updateContactInformation,
    updateInvoiceInformation,
    updateClientAccountManagementInformation,
    updateAvatar,
    deleteAvatar,
    resetClientInformationStates,
    restoreUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);
