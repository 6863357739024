import { ReactComponent as RemoteWork } from 'assets/worker-on-sofa.svg';
import { ReactComponent as GlobalBenefits } from 'assets/meditation.svg';
import { ReactComponent as Security } from 'assets/security-image.svg';
import { ReactComponent as SupportiveCommunity } from 'assets/supportive-community.svg';

import { ReactComponent as SignUpCandidate1} from 'assets/candidate-sign-up-benefits-1.svg';
import { ReactComponent as SignUpCandidate2} from 'assets/candidate-sign-up-benefits-2.svg';
import { ReactComponent as SignUpCandidate3} from 'assets/candidate-sign-up-benefits-3.svg';
import { ReactComponent as SignUpCandidate4} from 'assets/candidate-sign-up-benefits-4.svg';

import { ReactComponent as SignUpClient1 } from 'assets/sign-up-slient1.svg';
import { ReactComponent as SignUpClient2 } from 'assets/sign-up-slient2.svg';
import { ReactComponent as SignUpClient3 } from 'assets/sign-up-slient3.svg';
import { ReactComponent as SignUpClient4 } from 'assets/sign-up-slient4.svg';

export const CANDIDATE_SIGN_UP_BENEFITS_DATA = [
    { image: SignUpCandidate1, text: 'Work from anywhere.' },
    { image: SignUpCandidate2, text: 'Enjoy global benefits.' },
    { image: SignUpCandidate3, text: 'Receive the job security you seek.' },
    { image: SignUpCandidate4, text: 'Be part of a supportive tech community.' },
];

export const CANDIDATE_SIGN_UP_SLIDES_DATA = [
    {
        image: RemoteWork,
        title: 'Work from anywhere.',
        text: 'Sign up, browse and apply for remote software jobs and connect with our growing tech community.',
    },
    {
        image: GlobalBenefits,
        title: 'Enjoy global benefits.',
        text: 'Sign up, browse and apply for remote software jobs and connect with our growing tech community.',
    },
    {
        image: Security,
        title: 'Receive the job security you seek.',
        text: 'Sign up, browse and apply for remote software jobs and connect with our growing tech community.',
    },
    {
        image: SupportiveCommunity,
        title: 'Be part of a supportive tech community.',
        text: 'Sign up, browse and apply for remote software jobs and connect with our growing tech community.',
    }
];

export const CLIENT_SIGN_UP_SLIDES_DATA = [
    {
        image: SignUpClient1,
        title: 'Find the best talent for your company in no time.',
        text: 'Source the best of hundreds of remote applicants by reviewing CVs and video interviews without leaving the platform.',
    },
    {
        image: SignUpClient2,
        title: 'Employ remote specialists globally.',
        text: 'Hire top software specialists without borders. We take care of all employment and administration challenges for you.',
    },
    {
        image: SignUpClient3,
        title: 'Ensure smooth company operations.',
        text: 'Streamline the process of tracking financial KPIs, employees\' PTO and working hours, sending invoices, and more. With just a few clicks.',
    },
    {
        image: SignUpClient4,
        title: 'Retain your best people.',
        text: 'Increase your top talent happiness and productivity by providing them with a wide range of premium employment benefits and perks.',
    }
];

