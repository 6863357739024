import React, { useEffect } from 'react';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import ErrorIcon from '@material-ui/icons/Error';

import { logEvent } from 'utils/amplitude';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import './UnfinishedProfilePopup.scss';

const UnfinishedProfilePopup = ({handleClick}) => {
    useEffect(() => {
        logEvent(AMPLITUDE_EVENT_TYPES.CANDIDATE_APPLY_FOR_JOB_BEFORE_PROFILE_FINISH);
    }, []);

    return (
        <div className="unfinished-profile-warning">
            <div className="inner">
                <div className="header">
                    <ErrorIcon className="warning-icon" />
                    <p>Oops.</p>
                </div>
                <div className="body">
                    <p className="message">You must complete the process before applying for a job.</p>
                </div>
                <PrimaryButton handleClick={handleClick} className="finishi-profile-button" text="Finish Profile"/>
            </div>
        </div>
    )
}

export default UnfinishedProfilePopup
