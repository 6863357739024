import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Typography from '@material-ui/core/Typography';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';

import { ReactComponent as DragIcon } from 'assets/drag-icon.svg';

import './SortableList.scss';

const SortableItem = SortableElement(({
    item,
    editItem,
    removeItem,
    isLast,
}) => (
    <div className="sortable-item-container">
        <div className="sortable-item-content">
            <div className="drag-icon">
                <DragIcon />
            </div>
            <Typography className="item-row-name" variant="subtitle2">{item.title || item.name || item.institution}</Typography>
            <div className="item-edit-icon-wrapper" onClick={editItem}>
                <EditSharpIcon className="item-edit-icon" />
            </div>
            <CancelSharpIcon className="item-remove-icon" onClick={removeItem} />
        </div>

        {(item.level || item.description) && <Typography className="item-row-description" variant="subtitle2">{item.level || item.description}</Typography>}
        {!isLast && <hr />}
    </div>
)
);

const SortableList = SortableContainer(({ items, onRemove, onEdit }) => (
    <div className="sort-list">
        {items.map((value, index) => {
            return (
                <SortableItem
                    key={`item-${index}`}
                    index={index}
                    removeItem={onRemove.bind(null, { ...value, index })}
                    item={value}
                    editItem={onEdit.bind(null, { ...value, index })}
                    isLast={index === items.length - 1}
                />
            )
        })}
    </div>
)
);

export default SortableList;
