import api from './api';
import requester from './requester';

const clientService = {
    registerClient: (agencyId, clientData) => requester(api.clients(agencyId)).create(clientData),
    fetchClients: agencyId => requester(api.clients(agencyId)).getMany(),
    fetchClientsPage: (agencyId, query) => requester(api.clientsPage(agencyId, query)).getMany(),
    exportClients: (agencyId, exportData) => requester(api.clientsExport(agencyId)).saveAsAction('clients-table', exportData),
    fetchClientInformation: (agencyId, clientId) => requester(api.clientInformation(agencyId, clientId)).getOne(),
    updateAvatar: (croppedImgData, agencyId, clientId) =>
        requester(api.updateClientAvatar(agencyId, clientId)).uploadFile(croppedImgData),
    deleteAvatar: (agencyId, clientId) => requester(api.deleteClientAvatar(agencyId, clientId)).delete(),
    updateBasicInformation: (agencyId, clientId, basicInfo) =>
        requester(api.updateClientBasicInformation(agencyId, clientId)).create(basicInfo),
    updateContactInformation: (agencyId, clientId, contactInfo) =>
        requester(api.updateClientContactInformation(agencyId, clientId)).create(contactInfo),
    updateInvoiceInformation: (agencyId, clientId, invoceInfo) =>
        requester(api.updateInvoiceInformation(agencyId, clientId)).create(invoceInfo),
    addClientProject: (agencyId, clientId, projectData) => requester(api.clientProjects(agencyId, clientId)).create(projectData),
    updateClientProject: (agencyId, clientId, project) => requester(api.clientProject(agencyId, clientId, project._id)).update(project),
    fetchClientProjectsWithEmployees: (agencyId, clientId) => requester(api.fetchClientProjectsWithEmployees(agencyId, clientId)).getMany(),
    fetchClientProjectsTotalRevenue: (agencyId, clientId) => requester(api.clientProjectsTotalRevenue(agencyId, clientId)).getOne(),
    fetchClientProjects: (agencyId, clientId, fields) => requester(api.fetchClientProjects(agencyId, clientId, fields)).getMany(),
    fetchClientProjectsFeedback: (agencyId, clientId) => requester(api.clientProjectsFeedback(agencyId, clientId)).getMany(),
    addFeedbackToProject: (agencyId, clientId, projectId, feedbackData) => requester(api.addFeedbackToProject(agencyId, clientId, projectId)).create(feedbackData),
    addProjectFiles: (agencyId, clientId, projectId, type, filesData) => requester(api.clientProjectDocuments(agencyId, clientId, projectId, type)).uploadFile(filesData),
    addProjectLink: (agencyId, clientId, projectId, type, link) => requester(api.clientProjectDocuments(agencyId, clientId, projectId, type)).create(link),
    fetchProjectDocuments: (agencyId, clientId, projectId) => requester(api.clientProjectDocuments(agencyId, clientId, projectId)).getOne(),
    downloadProjectDocument: (agencyId, clientId, projectId, documentId, documentType, fileName) => requester(api.clientProjectDocument(agencyId, clientId, projectId, documentId, documentType)).saveAs(fileName),
    deleteProjectDocument: (agencyId, clientId, projectId, documentId, documentType) => requester(api.clientProjectDocument(agencyId, clientId, projectId, documentId, documentType)).delete(),
    fetchProjectReviews: (agencyId, clientId, projectId) => requester(api.fetchProjectReviews(agencyId, clientId, projectId)).getMany(),
    addEmployeeToProject: (agencyId, clientId, projectId, employeeData) =>
        requester(api.addEmployeeToProject(agencyId, clientId, projectId)).update(employeeData),
    addDedicatedEmployeeToProject: (agencyId, clientId, projectId, employeeData) =>
        requester(api.addDedicatedEmployeeToProject(agencyId, clientId, projectId)).update(employeeData),
    updateProjectEmployee: (agencyId, clientId, projectId, employeeId, employeeData) =>
        requester(api.updateProjectEmployee(agencyId, clientId, projectId, employeeId)).update(employeeData),
    updateProjectDedicatedEmployee: (agencyId, clientId, projectId, employeeId, employeeData) =>
        requester(api.updateProjectDedicatedEmployee(agencyId, clientId, projectId, employeeId)).update(employeeData),
    removeProjectEmployee: (agencyId, clientId, projectId, employeeId, dateDeleted) =>
        requester(api.removeProjectEmployee(agencyId, clientId, projectId, employeeId, dateDeleted)).delete(),
    removeProjectDedicatedEmployee: (agencyId, clientId, projectId, employeeId, dateDeleted) =>
        requester(api.removeProjectDedicatedEmployee(agencyId, clientId, projectId, employeeId, dateDeleted)).delete(),
    updateAccountManagementInformation: (agencyId, clientId, updateData) =>
        requester(api.clientAccountManagementInformation(agencyId, clientId)).update(updateData),
    getAllTasks: (agencyId, clientId) => requester(api.clientTasks(agencyId, clientId)).getMany(),
    createTask: (agencyId, clientId, task) => requester(api.clientTasks(agencyId, clientId)).create(task),
    updateTask: (agencyId, clientId, task) => requester(api.clientTaskUpdate(agencyId, clientId, task._id)).update(task),
    fetchClientDashboardInformation: (agencyId, clientId) => requester(api.clientDashboardInformation(agencyId, clientId)).getOne(),
    fetchProjectEmployeesApprovedHolidays: (agencyId, clientId, projectId, from, to) => requester(api.projectEmployeesApprovedHolidays(agencyId, clientId, projectId, from, to)).getOne(),
    fetchClientProject: (agencyId, clientId, projectId) => requester(api.clientProject(agencyId, clientId, projectId)).getOne(),
    fetchAllClientTeamHistory: (agencyId, clientId) => requester(api.allClientTeamHistory(agencyId, clientId)).getMany(),
    fetchClientProjectEmployeeHistory: (agencyId, clientId, projectId, employeeId) => requester(api.clientProjectEmployeeHistory(agencyId, clientId, projectId, employeeId)).getOne(),
    fetchClientProjectDedicatedEmployeeHistory: (agencyId, clientId, projectId, employeeId, field) => requester(api.clientProjectDedicatedEmployeeHistory(agencyId, clientId, projectId, employeeId, field)).getOne(),
    updateClientFlag: (agencyId, clientId, flag) => requester(api.clientFlag(agencyId, clientId)).create(flag),
    fetchAllClientJobs: (agencyId, clientId, filters) => requester(api.allClientJobs(agencyId, clientId), { ...filters }).getMany(),
    deleteClients: (agencyId, clientIds) => requester(api.clients(agencyId, clientIds)).delete(),
    fetchClientInterviewDates: (agencyId, clientId, startDate, endDate) => requester(api.clientInterviewDates(agencyId, clientId, startDate, endDate)).getMany(),
    fetchClientJobsDashboard: (agencyId, clientId) => requester(api.clientJobsDashboard(agencyId, clientId)).getOne(),
    fetchClientNotifications: (agencyId, clientId) => requester(api.clientNotifications(agencyId, clientId)).getMany(),
    updateClientNotificationsSeenList: (agencyId, clientId, notifications) => requester(api.clientNotifications(agencyId, clientId)).update(notifications),
    fetchClientAdmins: (agencyId, clientId, query) => requester(api.clientAdmins(agencyId, clientId, query)).getMany(),
    addClientAdmin: (agencyId, clientId, clientData) => requester(api.clientAdmins(agencyId, clientId)).create(clientData),
    deleteClientAdmin: (agencyId, clientId, clientAdminId) => requester(api.clientAdmin(agencyId, clientId, clientAdminId)).delete(),
    fetchClientInvoicingInformation: (agencyId, clientId) => requester(api.clientInvoicing(agencyId, clientId)).getOne(),
};

export default clientService;
