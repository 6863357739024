import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';

import { validateEmailAddress, validatePasswordEquality, validatePasswordRequirements, validateRequiredFields } from "utils/formValidations";
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { createSSOLoggingRecord } from 'actions/loggingActions';

import RegisterFormView from '../RegisterFormView';

import { ROLES, REGISTRATION_TYPES, REFERRALS } from 'constants/userConstants';
import { IS_WHOISAVAILABLE_ENV } from 'constants/env';

const RegisterCandidateFormik = ({
    blur,
    initialValues,
    gotoUrl,
    jobOpportunityId,
    referrer,
    gclid,
    registerCandidate,
    login,
    history,
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
}) => (
    <Formik
        initialValues={initialValues}
        validate={values => {
            const requiredFields = IS_WHOISAVAILABLE_ENV
                ? ['name', 'email', 'password', 'confirmPassword']
                : ['name', 'email', 'password', 'confirmPassword', 'isTermsAndConditionsAccepted'];

                return {
                    ...validatePasswordRequirements(values, 'password'),
                    ...validatePasswordEquality(values, 'password', 'confirmPassword'),
                    ...validateRequiredFields(values, requiredFields),
                    ...validateEmailAddress(values, 'email'),
                };
            }}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                history.replace({ state: { jobOpportunityId, gotoUrl, isFromCandidateRegistration: true } });
                const { confirmPassword, ...rest } = values
                const userData = {
                    ...rest,
                    role: ROLES.CANDIDATE,
                    gotoUrl,
                    jobOpportunityId,
                    registrationType: REGISTRATION_TYPES.SELF_REGISTERED,
                    referrer: gclid && (!referrer || referrer === REFERRALS.WEBSITE_ORGANIC) ? REFERRALS.GOOGLE : referrer,
                    campaignId: gclid,
                    email: values.email.toLowerCase().trim(),
                };

                registerCandidate(userData)
                    .then(async (user) => {
                        if (user) {
                            showApplicationLoader();
                            await login(userData.email, values.password, false);
                            const agencyRouteName = user.agencyName.replaceAll(' ', '-').toLowerCase().replace(/[^a-zA-Z-]/g, '');
                            history.push({ pathname: `/${agencyRouteName}/start-journey-signup-success`, state: { jobOpportunityId, gotoUrl, isFromCandidateRegistration: true } });
                        }
                        hideApplicationLoader();
                    })
                    .catch(() => hideApplicationLoader())
            }}
        >
            {(props) => <RegisterFormView 
                {...props}
                jobOpportunityId={jobOpportunityId}
                blur={blur}
                gotoUrl={gotoUrl}
                referrer={referrer}
                campaignId={gclid}
                role={ROLES.CANDIDATE}
                login={login}
                showApplicationLoader={showApplicationLoader}
                hideApplicationLoader={hideApplicationLoader}
                createSSOLoggingRecord={createSSOLoggingRecord}
            />}
    </Formik>
);

const mapDispatchToProps = {
    showApplicationLoader,
    hideApplicationLoader,
    createSSOLoggingRecord,
};

export default connect(null, mapDispatchToProps)(RegisterCandidateFormik);
