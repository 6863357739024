import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchAgencyTasks, fetchAdmins, saveTask } from 'actions/agencyActions';
import { fetchAllTasks } from 'actions/employeeActions';
import { getAdmins, getAllTasks, getCurrentUser, getEmployeeTasks, getTasksListDataFetchState } from 'reducers';
import isHoliday from 'hoc/isHoliday';

import TasksRedux from './TasksRedux';

import { defaultOwnerValue, taskTypeSelect } from './TasksConstants';
import { TASK_ON_BEHALF_OF_TYPE } from 'constants/tasksConstants';

import { mapQueryToFilters } from './TasksUtils';
import { parseQueryString, updateQuery } from 'utils/helpers';

import './Tasks.scss';

const Tasks = ({
    agencyId,
    userId,
    submitTaskHandler,
    fetchAgencyTasks,
    fetchAllTasks,
    agencyDashboard,
    fetchAdmins,
    isHoliday,
    admins,
    currentAgencyUser,
    dataFetchState
}) => {
    const history = useHistory();

    const taskAdminsSelect = defaultOwnerValue.concat(admins.map((admin, index) => ({ value: index + 2, name: admin.name })));
    const currAdminIndex = admins.findIndex(a => a._id === userId);

    const defaultFilters = {
        owner: defaultOwnerValue[0],
        type: taskTypeSelect[0],
    }

    const defaultFiltersTasksPage = {
        owner: { value: (currAdminIndex + 2), name: admins[currAdminIndex]?.name || 'Owner' },
        type: taskTypeSelect[0],
    }

    const selectFilterMapper = {
        owner: [defaultOwnerValue[0], ...admins],
        type: taskTypeSelect,
    };

    const [filters, setFilters] = useState(agencyDashboard ? defaultFilters : defaultFiltersTasksPage);

    useEffect(() => {
        if (agencyDashboard) {
            fetchAllTasks(userId, agencyId, TASK_ON_BEHALF_OF_TYPE.OWNER);
        } else {
            fetchAgencyTasks(agencyId);
        }
        fetchAdmins(agencyId);
    }, []);

    useEffect(() => {
        const queryString = history.location.search;
        const parsedQuery = parseQueryString(queryString);

        if (Object.keys(parsedQuery).length > 0) {
            setFilters(mapQueryToFilters(parsedQuery, admins.map(x => ({ value: admins.indexOf(x) + 2, name: x.name }))));
        } else if (!agencyDashboard && admins.length > 0) {
            const queryObj = { 'owner': admins[currAdminIndex]?.name || 'Owner' };
            const query = updateQuery(history.location.search, queryObj);
            history.replace({ pathname: history.location.pathname, search: query });
        } else {
            setFilters(defaultFilters);
        }
    }, [history.location.search, admins]);


    const handleFilterChange = e => {
        const filterObj = {
            [e.target.name]: selectFilterMapper[e.target.name].find(x => selectFilterMapper[e.target.name].indexOf(x) === e.target.value - 1).name,
        };

        const updatedQuery = updateQuery(history.location.search, filterObj);

        history.replace({ pathname: history.location.pathname, search: updatedQuery });
    };

    const handleFilterReset = () => {
        history.replace();
    }

    return (
        <div className="agency-tasks-wrapper">
            <TasksRedux
                dataFetchState={dataFetchState}
                submitTaskHandler={submitTaskHandler}
                agencyId={agencyId}
                renderHolidays={isHoliday}
                isAgencyTasks={true}
                taskAdmins={admins}
                taskAdminsSelect={taskAdminsSelect}
                taskTypeSelect={taskTypeSelect}
                handleFilterChange={handleFilterChange}
                handleFilterReset={handleFilterReset}
                agencyDashboard={agencyDashboard}
                dataSelector={agencyDashboard ? getEmployeeTasks : getAllTasks}
                filters={filters}
                currentUser={currentAgencyUser}
                selectedUser={currentAgencyUser}
                expandTitle={agencyDashboard ? 'My Tasks' : 'All Tasks'}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    admins: getAdmins(state),
    currentAgencyUser: getCurrentUser(state),
    dataFetchState: getTasksListDataFetchState(state),
});

const mapDispatchToProps = {
    fetchAgencyTasks,
    submitTaskHandler: saveTask,
    isHoliday,
    fetchAdmins,
    fetchAllTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);

