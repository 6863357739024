import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Avatar, Paper } from '@material-ui/core';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';

import { showNotification } from 'actions/notificationActions';

import Subsection from 'components/Shared/Subsection';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import CalendlyDialog from 'components/Shared/CalendlyDialog';

import { COACHING_OPPORTUNITIES_MESSAGES } from 'constants/messageConstants';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';

import { ReactComponent as CoachingSessionIcon } from 'assets/coaching-session-icon.svg';

import './CoachingMentoringDetails.scss';

const CoachingMentoringDetails = ({
    agencyId,
    userId,
    coachingOpportunity,
    createCoachingOpportunitySession,
    showNotification,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);

    const handleEventScheduling = (event) => {
        const eventUri = event.data.payload.event.uri;
        createCoachingOpportunitySession(agencyId, userId, coachingOpportunity._id, eventUri);
        handleCloseDialog();
    };

    const handleBookClick = (calendlyInfo) => {
        if (calendlyInfo.errors?.invalidCalendlyInfo) {
            showNotification(COACHING_OPPORTUNITIES_MESSAGES.INVALID_CALENDLY_INFORMATION, NOTIFICATION_TYPES.ERROR);
        } else {
            handleOpenDialog();
        }
    };

    return (
        <Paper className="coaching-mentoring-details-wrapper">
            <div className="coaching-mentoring-details-content">
                <div className="coach-info">
                    <div className="coach-avatar">
                        { coachingOpportunity.coachingOpportunityImgUrl ? 
                            <Avatar 
                                alt="profile logo"
                                className="profile-logo"
                                src={coachingOpportunity.coachingOpportunityImgUrl}
                            />
                        : <Avatar className="profile-logo" /> }
                    </div>
                    <h2 className="coach-name">{coachingOpportunity.coachName}</h2>
                </div>
                <Subsection 
                    iconComponent={<AccountCircleOutlined className="icon"/>}
                    headerComponent={
                        <div className="subsection-header-wrapper">
                            <h4 className="subsection-title">About the Coach</h4>
                            {coachingOpportunity.languages?.length > 0 &&
                                <div className="languages-list">
                                    {coachingOpportunity.languages.map((language, index) => (
                                        <div key={coachingOpportunity._id + language + index} className="language-item">
                                            {language}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    }
                    content={coachingOpportunity.aboutTheCoach}
                />

                <hr />

                <Subsection 
                    iconComponent={<CoachingSessionIcon className="icon"/>}
                    headerComponent={<h4 className="subsection-title">About the Coaching Session</h4>}
                    content={coachingOpportunity.description}
                />

                <div className="global-tags-list">
                    { coachingOpportunity.tags.map(x => (
                        <div key={x._id} className="tag-name">{x.title}</div>
                    )) }
                </div>
            </div>
            <div className="coaching-mentoring-details-footer">
                <h1>{coachingOpportunity.title}</h1>
                <PrimaryButton text="Book" className="book-session-button" handleClick={() => handleBookClick(coachingOpportunity.calendlyInfo)}/>
            </div>
            {  
                isDialogOpen &&
                    <CalendlyDialog 
                        handleClose={handleCloseDialog}
                        calendlyInfo={coachingOpportunity.calendlyInfo}
                        handleEventScheduling={handleEventScheduling}
                    />
            }

        </Paper>
    )
};

const mapDispatchToProps = {
    showNotification,
};

export default connect(null, mapDispatchToProps)(CoachingMentoringDetails);
