import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getClientId, getUserId } from 'reducers';
import { fetchAdmins } from 'actions/agencyActions';
import { updateEmployeesRole } from 'actions/employeeActions';
import { fetchClientAdmins, addClientAdmin, deleteClientAdmin } from 'actions/clientActions';

import AssignAdminDialog from './AssignAdminDialog/AssignAdminDialog';
import TableWrapper from 'components/Shared/TableWrapper';
import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';

import { ReactComponent as AddButtonIcon } from 'assets/add-button-icon.svg';

import { ROLES } from 'constants/userConstants';
import { DATE_FORMAT_WITH_SLASH } from 'constants/commonConstants';
import {
    ADMIN_ACCOUNTS_TABLE_COLUMNS,
    DEFAULT_ADMIN_ACCOUNTS_TABLE_COLUMNS,
    ADMIN_ACCOUNTS_TABLE_SORT_QUERY_SELECTOR,
    ADMIN_ACCOUNTS_TABLE_PAGE_QUERY_SELECTOR,
    DEFAULT_ITEMS_PER_PAGE,
    INITIAL_QUERY,
    QUERY_PARAMS_MAP,
    ACTION_BUTTON_TEXT,
    DATA_SELECTOR,
} from './AdminAccountsTableConstants';

import { usePreloadTable } from 'utils/hooks';

const AdminAccountsTable = ({
    agencyId,
    fetchAdmins,
    updateEmployeesRole,
    displayMode,
    clientId,
    fetchClientAdmins,
    addClientAdmin,
    deleteClientAdmin,
    userId,
}) => {
    const [openAssignAdminDialog, setOpenAssignAdminDialog] = useState(false);
    const [isAddClientAdminDialogOpen, setIsAddClientAdminDialogOpen] = useState(false);
    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [adminToRemove, setAdminToRemove] = useState({});

    const tableFetchAction = displayMode === ROLES.ADMIN ? fetchAdmins.bind(null, agencyId) : fetchClientAdmins.bind(null, agencyId, clientId);
    const dataSelector = (state, page) => DATA_SELECTOR[displayMode](state, page - 1, DEFAULT_ITEMS_PER_PAGE);

    const [reload, setReload] = useState(false);
    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_PARAMS_MAP, null, tableFetchAction, reload, setReload);

    const handleOpenDialog = () => {
        switch (displayMode) {
            case ROLES.ADMIN:
                setOpenAssignAdminDialog(true);
                break;
            case ROLES.CLIENT:
                setIsAddClientAdminDialogOpen(true);
                break;
            default:
                break;
        }
    }

    const handleCloseAssignAdminDialog = () => setOpenAssignAdminDialog(false);

    const handleCloseConfirmRemoveDialog = () => {
        setOpenConfirmRemoveDialog(() => false);
        setAdminToRemove({});
    };

    const handleRemoveAdmin = async () => {
        if (displayMode === ROLES.CLIENT) {
            await deleteClientAdmin(agencyId, clientId, adminToRemove._id)
        } else {
            const ids = [adminToRemove._id];
            const role = ROLES.EMPLOYEE;
            await updateEmployeesRole(agencyId, ids, role, false);
        }
        setReload(true);
        handleCloseConfirmRemoveDialog();
    };

    const removeAdminClickHandler = admin => {
        if (!admin?.isFirst) {
            setAdminToRemove(admin);
            setOpenConfirmRemoveDialog(true);
        }
    };

    const handleCloseAddClientAdminDialog = () => {
        setIsAddClientAdminDialogOpen(false);
    }

    const handleAssignNewAdmin = async (selectedEmployees) => {
        if (selectedEmployees.length > 0) {
            const ids = selectedEmployees.map(x => { x = x._id; return x; });
            await updateEmployeesRole(agencyId, ids, ROLES.ADMIN, false);
            setReload(true);
            handleCloseAssignAdminDialog();
        }
    };

    const handleAddClientAdmin = async (values) => {
        await addClientAdmin(agencyId, clientId, values, false);
        setReload(true);
        setIsAddClientAdminDialogOpen(false);
    }

    const adjustAdminAccountsKeys = (stateData) => stateData.map((admin) => {
        admin.name = admin.name || `${admin.firstName} ${admin.lastName}`;
        return {
            name: admin.name || '-',
            position: admin.position,
            email: admin.email || '-',
            adminSince: admin.adminSince ? moment(admin.adminSince).format(DATE_FORMAT_WITH_SLASH) : '-',
            registrationDate: admin.registrationDate ? moment(admin.registrationDate).format(DATE_FORMAT_WITH_SLASH) : '-',
            item: admin,
        }
    });

    const actions = [
        { title: 'Delete', handleClick: removeAdminClickHandler }
    ];

    const getPopoverOptions = () => actions;

    return (
        <div className="admin-accounts-table-container">
            <TableWrapper
                title="Admin accounts"
                columns={ADMIN_ACCOUNTS_TABLE_COLUMNS[displayMode]}
                defaultColumns={DEFAULT_ADMIN_ACCOUNTS_TABLE_COLUMNS[displayMode]}
                dataSelector={dataSelector}
                sortQuerySelector={ADMIN_ACCOUNTS_TABLE_SORT_QUERY_SELECTOR}
                adjustKeysFunction={adjustAdminAccountsKeys}
                isTableDataLoading={isTableDataLoading}
                addMargin
                withPagination
                withActions
                getActionsHandler={getPopoverOptions}
                pagesCount={Math.ceil(totalCount / DEFAULT_ITEMS_PER_PAGE)}
                pageQuerySelector={ADMIN_ACCOUNTS_TABLE_PAGE_QUERY_SELECTOR}
            >
                <TableWrapper.TableAlignedItems>
                    <TableWrapper.TableAlignedItems.LeftAligned>
                        <TableWrapper.TableAlignedItems.TableHeader />
                    </TableWrapper.TableAlignedItems.LeftAligned>
                    <TableWrapper.TableAlignedItems.RightAligned>
                        <TableWrapper.TableAlignedItems.TableActionButton
                            text={ACTION_BUTTON_TEXT[displayMode]}
                            icon={<AddButtonIcon />}
                            handleClick={handleOpenDialog}
                        />
                    </TableWrapper.TableAlignedItems.RightAligned>
                </TableWrapper.TableAlignedItems>
                <TableWrapper.TableContent />
            </TableWrapper>

            <AssignAdminDialog
                isOpen={openAssignAdminDialog}
                handleAssignNewAdmin={handleAssignNewAdmin}
                handleCloseDialog={handleCloseAssignAdminDialog}
            />

            <AddTeamMemberDialog
                isOpen={isAddClientAdminDialogOpen}
                handleCloseDialog={handleCloseAddClientAdminDialog}
                dialogTitle={'Add new admin'}
                nameFieldLabelText={'Contact Person Name'}
                isEmailRequired={true}
                handleRegisterMember={handleAddClientAdmin}
            />

            <ConfirmDeleteDialog
                itemToDelete={adminToRemove.name}
                handleDeleteItem={() => handleRemoveAdmin()}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />
        </div>
    )
};

const mapStateToProps = (state) => ({
    clientId: getClientId(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    fetchAdmins,
    updateEmployeesRole,
    fetchClientAdmins,
    addClientAdmin,
    deleteClientAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAccountsTable);
