import React from 'react';
import { useHistory } from 'react-router-dom';

import { updateQueryWithMultipleEntries } from 'utils/helpers';

import { INITIAL_JOBS_DASHBOARD_FILTERS } from '../JobsDashboardConstants';

import './EmptyJobsList.scss';

const EmptyJobsList = ({
    filters,
    handleOpenDialog,
    isSupplierAdmin,
    displayMode,
}) => {
    const history = useHistory();

    const handleClickAddNewJob = () => {
        handleOpenDialog();
    };

    const handleClickShowAllJobs = () => {
        const updatedQuery = updateQueryWithMultipleEntries(history.location.search, INITIAL_JOBS_DASHBOARD_FILTERS[displayMode]);

        history.replace({ pathname: history.location.pathname, search: updatedQuery, state: { isReset: true } });
    };

    const getContentForEmptyState = () => {
        if (Object.values(filters).every(x => !x)) {
            return {
                title: 'You haven\'t created a job yet.',
                actionText: 'add new job',
                handleActionClick: handleClickAddNewJob,
            };
        } else {
            return {
                title: 'No jobs match your criteria.',
                actionText: 'show all jobs',
                handleActionClick: handleClickShowAllJobs,
            };
        }
    };

    const { title, actionText, handleActionClick } = getContentForEmptyState();

    return (
        <div className="empty-jobs-list-wrapper">
            <h2 className="empty-jobs-list-title">{title}</h2>
            {!isSupplierAdmin &&
                <div className="empty-jobs-list-action-wrapper">
                    Click to <button
                        className="empty-jobs-list-action-button"
                        onClick={handleActionClick}
                    >
                        {actionText}
                    </button>.
                </div>
            }
        </div>
    );
};

export default EmptyJobsList;
