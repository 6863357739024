import { useContext } from 'react';

import { MatchingJobCardContext } from '../MatchingJobCard';

import JobTypeLabel from 'components/JobsDashboard/SharedComponents/JobTypeLabel';

const MatchingJobTypeLabel = () => {
    const { job } = useContext(MatchingJobCardContext);

    return <JobTypeLabel jobType={job.jobType} />
};

export default MatchingJobTypeLabel;
