import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchAllCandidates, fetchChecklistedCandidates, exportCandidates, registerCandidate } from 'actions/candidateActions';
import { fetchAdmins } from 'actions/agencyActions';
import {
    getAgencyId,
    getUserId,
    getAdminItems,
    getEmployees,
} from 'reducers';

import RequireActionBanner from './RequireActionBanner';
import CandidatesTable from './CandidatesTable';
import AddTeamMemberDialog from 'components/Employees/AddTeamMemberDialog';
import ConfirmInviteUserDialog from 'components/Shared/ConfirmInviteUserDialog';
import CandidateInvitationLink from 'components/Shared/CandidateInvitationLink';

import { ROLES, REGISTRATION_TYPES } from 'constants/userConstants';
import { CANDIDATE_STATUSES } from 'constants/candidateConstants';
import { CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES } from 'components/CandidatesChecklist/CandidatesChecklistConstants';
import { DEFAULT_ITEMS_PER_PAGE } from 'components/Shared/TableWrapper/TableWrapperConstants';

import './Candidates.scss';
import { CircularProgress } from '@material-ui/core';
import { usePreloadTable } from 'utils/hooks';
import { INITIAL_QUERY, QUERY_MAP, tableFilters } from './CandidatesTable/CandidatesTableConstants';

const Candidates = ({
    agencyId,
    agencyName,
    userId,
    fetchAllCandidates,
    fetchChecklistedCandidates,
    registerCandidate,
    assigneeItems,
    fetchAdmins,
    exportCandidates,
}) => {
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newCandidateData, setNewCandidateData] = useState({});

    const [isDataLoading, setIsDataLoading] = useState(true);

    const candidatesTableAction = fetchAllCandidates.bind(null, agencyId);
    const candidatesSelector = (state, page) => getEmployees(state, page - 1, DEFAULT_ITEMS_PER_PAGE);
    const prefetchAction = () => fetchAdmins(agencyId);

    const assigneeFilter = { key: 'assignee', name: 'Assignee', value: assigneeItems.map(x => x.name) };

    const [isTableDataLoading, totalCount] = usePreloadTable(INITIAL_QUERY, QUERY_MAP, [...tableFilters, assigneeFilter], candidatesTableAction, null, null, prefetchAction);

    useEffect(() => {
        fetchChecklistedCandidates(agencyId, { status: CANDIDATES_CHECKLIST_STATUS_FILTER_VALUES.ACTION_REQUIRED }).then(() => {
            setIsDataLoading(false);
        });
    }, []);

    const handleOpenDialog = () => setIsAddDialogOpen(true);

    const handleCloseDialog = () => setIsAddDialogOpen(false);

    const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

    const handleRegisterCandidate = async candidateInputData => {
        candidateInputData = {
            ...candidateInputData,
            employmentInformation: { generalInfo: { isAvailable: false, isVetted: false } },
            role: ROLES.CANDIDATE,
            status: CANDIDATE_STATUSES.NEW,
            statusLastEdit: moment.utc().format(),
            registrationType: REGISTRATION_TYPES.INVITED_BY_ADMIN,
            addedBy: userId,
        };

        if (candidateInputData.email) {
            setNewCandidateData(candidateInputData);
            setOpenConfirmDialog(true);
            return;
        }

        await registerCandidate(agencyId, candidateInputData);
        handleCloseDialog();
    };

    const handleProceedClick = async () => {
        handleCloseConfirmDialog();
        await registerCandidate(agencyId, newCandidateData);
        handleCloseDialog();
        setNewCandidateData({});
    };

    return (
        <div className="candidates-table-wrapper">
            {isDataLoading ? <CircularProgress className="page-loader" /> :
                <Fragment>
                    <RequireActionBanner />

                    <CandidateInvitationLink
                        agencyId={agencyId}
                        agencyName={agencyName}
                    />

                    <CandidatesTable
                        action={candidatesTableAction}
                        dataSelector={candidatesSelector}
                        handleOpenDialog={handleOpenDialog}
                        agencyId={agencyId}
                        exportCandidates={exportCandidates}
                        assigneeItems={assigneeItems}
                        isTableDataLoading={isTableDataLoading}
                        totalCount={totalCount}
                        assigneeFilter={assigneeFilter}
                    />

                    <AddTeamMemberDialog
                        isOpen={isAddDialogOpen}
                        handleCloseDialog={handleCloseDialog}
                        handleRegisterMember={handleRegisterCandidate}
                        dialogTitle={'Add new candidate'}
                    />

                    <ConfirmInviteUserDialog
                        openDialog={openConfirmDialog}
                        invitedUser={ROLES.CANDIDATE}
                        handleInviteUser={handleProceedClick}
                        handleCloseDialog={handleCloseConfirmDialog}
                    />
                </Fragment>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    assigneeItems: getAdminItems(state),
});

const mapDispatchToProps = {
    registerCandidate,
    fetchAllCandidates,
    fetchChecklistedCandidates,
    fetchAdmins,
    exportCandidates,
};

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
