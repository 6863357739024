export const CONTRACT_ID = 'contract';
export const FULL_TIME_ID = 'full-time';

export const DIALOG_TYPES = {
    CONTRACT: 'contract',
    FULL_TIME: 'full-time',
    BILLING_TYPE: 'billing-type',
};

export const BILLING_TYPE_STATIC_VALUES = [
    { VALUE: 1, LABEL: 'Per Hour' },
    { VALUE: 2, LABEL: 'Monthly' },
];

export const BILLING_TYPES = {
    PER_HOUR: 'Per Hour',
    MONTHLY: 'Monthly',
};

export const BILLING_TYPE_TO_EMPLOYEE_MAPPER = {
    [BILLING_TYPES.PER_HOUR]: CONTRACT_ID,
    [BILLING_TYPES.MONTHLY]: FULL_TIME_ID,
};
