import React from 'react';

import './SinglePageLayout.scss';

const Page = ({
    children
}) => (
    <div id="scroller" className='single-page-container'>
            {children}
    </div >
);

export default Page;
