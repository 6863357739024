import { CONTRACT_SIGNATURE_STATUSES, CONTRACT_TYPES } from './contractsConstants';

export const DOCUMENT_TYPES = {
    FULL_TIME_CONTRACT: 'Full-time Contract',
    FREELANCE_CONTRACT: 'Freelance Contract',
    OTHER_CONTRACT: 'Contract (Other)',
    CONTRACT: 'Contract',
    HOLIDAY: 'Holiday',
};

export const DOCUMENT_STATUSES = {
    PENDING: 'Pending',
    SIGNED: 'Signed',
    REJECTED: 'Rejected',
    NOT_APPLICABLE: 'N/A',
};

export const CONTRACT_SIGNATURE_STATUSES_TO_DOCUMENT_STATUSES_MAP = {
    [CONTRACT_SIGNATURE_STATUSES.PENDING]: DOCUMENT_STATUSES.PENDING,
    [CONTRACT_SIGNATURE_STATUSES.SIGNED]: DOCUMENT_STATUSES.SIGNED,
    [CONTRACT_SIGNATURE_STATUSES.REJECTED]: DOCUMENT_STATUSES.REJECTED,
    [CONTRACT_SIGNATURE_STATUSES.NOT_APPLICABLE]: DOCUMENT_STATUSES.NOT_APPLICABLE,
};

export const CONTRACT_TYPES_TO_DOCUMENT_TYPES_MAP = {
  [CONTRACT_TYPES.EMPLOYEE[0].value]: DOCUMENT_TYPES.FULL_TIME_CONTRACT,
  [CONTRACT_TYPES.EMPLOYEE[1].value]: DOCUMENT_TYPES.FREELANCE_CONTRACT,
  [CONTRACT_TYPES.EMPLOYEE[2].value]: DOCUMENT_TYPES.OTHER_CONTRACT,
};
