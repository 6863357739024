import { logEvent } from 'utils/amplitude';
import invoiceService from 'services/invoiceService';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { INVOICES_MESSAGES } from 'constants/messageConstants';
import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';
import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';

import {
    INVOICE_ADD_SUCCESS,
    INVOICE_EDIT_SUCCESS,
    INVOICES_FETCH_SUCCESS,
    INVOICE_STATUS_UPDATE_SUCCESS,
    INVOICE_DELETE_SUCCESS,
    INVOICES_RESET_SUCCESS,
} from 'actions/actionTypes';

const addClientInvoiceSuccess = invoiceData => ({
    type: INVOICE_ADD_SUCCESS,
    payload: invoiceData
});

export const fetchInvoicesSuccess = invoices => ({
    type: INVOICES_FETCH_SUCCESS,
    payload: invoices
});

const updateInvoiceStatusSuccess = statusData => ({
    type: INVOICE_STATUS_UPDATE_SUCCESS,
    payload: statusData
});

const editInvoiceSuccess = invoice => ({
    type: INVOICE_EDIT_SUCCESS,
    payload: invoice
});

const deleteInvoiceSuccess = invoiceId => ({
    type: INVOICE_DELETE_SUCCESS,
    payload: invoiceId,
});

const invoicesReplaceSuccess = (payload) => ({
    type: INVOICES_RESET_SUCCESS,
    payload,
});

export const addInvoice = (agencyId, userId, invoiceData, senderRole, updateState=true) => async dispatch => {
    try {
        const newInvoice = await invoiceService.addInvoice(agencyId, userId, invoiceData, senderRole);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_CLIENT_INVOICE);

        if (updateState) {
            dispatch(addClientInvoiceSuccess(newInvoice));
        }

        dispatch(showNotification(INVOICES_MESSAGES.ADD_INVOICE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.ADD_INVOICE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const updateInvoiceStatus = (agencyId, invoiceId, statusData, updateState=true) => async dispatch => {
    try {
        const updatedStatusData = await invoiceService.updateInvoiceStatus(agencyId, invoiceId, statusData);

        if (updateState) {
            dispatch(updateInvoiceStatusSuccess(updatedStatusData));
        }
        
        dispatch(showNotification(INVOICES_MESSAGES.UPDATE_INVOICE_STATUS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.UPDATE_INVOICE_STATUS_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const fetchInvoices = (agencyId, userId, query, replaceState) => async dispatch => {
    try {
        query = query ? query + `&userId=${userId}` : `?userId=${userId}`;

        const result = await invoiceService.fetchInvoices(agencyId, query);

        if (replaceState) {
            dispatch(invoicesReplaceSuccess(result.invoices));
        } else {
            dispatch(fetchInvoicesSuccess(result.invoices));
        }
        return result.totalCount;
    } catch (error) {
        if (error.code === HTTP_STATUS_CODES.UNAUTHORIZED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.FETCH_INVOICE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const downloadInvoiceFiles = (agencyId, userId, invoiceId, fileName) => async dispatch => {
    try {
        await invoiceService.downloadInvoiceFiles(agencyId, userId, invoiceId, fileName);

        logEvent(AMPLITUDE_EVENT_TYPES.DOWNLOAD_INVOICE, { rolesPermitted: [ROLES.CLIENT] });
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.FETCH_INVOICE_FILES_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const exportInvoiceTimesheets = (agencyId, invoiceId, period, projectData, userId, userRole) => async dispatch => {
    try {
        const fileName = `${projectData.name}-${period}`;

        await invoiceService.exportInvoiceTimesheets(agencyId, invoiceId, period, fileName, projectData, userId, userRole);

        logEvent(AMPLITUDE_EVENT_TYPES.DOWNLOAD_TIMESHEETS, { rolesPermitted: [ROLES.CLIENT] });
        dispatch(showNotification(INVOICES_MESSAGES.EXPORT_TIMESHEETS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.EXPORT_TIMESHEETS_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};

export const resendInvoiceEmail = (agencyId, userId, invoicesData) => async dispatch => {
    try {
        await invoiceService.resendInvoiceEmail(agencyId, userId, invoicesData);

        dispatch(showNotification(INVOICES_MESSAGES.RESEND_EMAILS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.RESEND_EMAILS_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
}

export const editInvoice = (agencyId, userId, invoiceId, invoiceData) => async dispatch => {
    try {
        const editedInvoice = await invoiceService.editInvoice(agencyId, userId, invoiceId, invoiceData);

        dispatch(editInvoiceSuccess(editedInvoice));
        dispatch(showNotification(INVOICES_MESSAGES.EDIT_INVOICE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.EDIT_INVOICE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};


export const deleteInvoice = (agencyId, invoiceId) => async dispatch => {
    try {
        const { _id } = await invoiceService.deleteInvoice(agencyId, invoiceId);

        dispatch(deleteInvoiceSuccess(_id));
        dispatch(showNotification(INVOICES_MESSAGES.DELETE_INVOICE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(INVOICES_MESSAGES.DELETE_INVOICE_FAIL, NOTIFICATION_TYPES.ERROR));
    } 
};
