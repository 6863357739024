import React from 'react';

import EmployeeProjectCard from 'components/Dashboard/EmployeeProjects/EmployeeProjectCardList/EmployeeProjectCard';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';

import './Projects.scss';

const Projects = ({
    clientProjects,
    location,
    history
}) => {
    const handleProjectCardClick = () => {
        history.push(`${location.pathname}/projects`)
    };

    return (
        <div className="client-overveiw-projects-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Projects Overview'
                    className='section-title'
                />
            </SectionActionsWrapper>
            <div className="projects-content">
                {clientProjects.map((p, i) => <EmployeeProjectCard handleProjectCardClick={handleProjectCardClick} isClientOverview={true} key={i} project={p} />)}
            </div>
        </div>
    )
}

export default Projects;
