import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AddEmployeeToDepartmentAutoComplete = ({
    employees,
    errors,
    touched,
    setFieldValue,
    formikFieldName,
    inputLabel,
    inputName,
    isEdit,
    selectedEmployees
}) => {
    const [value, setValue] = useState('');

    const handleSelection = (event, employee) => {
        if (!employee || !employee.employmentInformation) {
            return;
        }

        const employeeToAdd = {
            _id: employee._id,
            name: `${employee.firstName} ${employee.lastName}`,
            profilePictureImage: employee.profilePictureImgUrl
        };

        setFieldValue(formikFieldName, [...selectedEmployees, employeeToAdd]);
        setValue('');
    }

    const handleTextInput = (event) =>{
        setValue(event.target.value);
    }

    return (
        <Autocomplete
            inputValue={value}
            disabled={isEdit}
            onChange={handleSelection}
            options={employees}
            filterOptions={(options, params) => {
                return options ? options.filter((x) => {
                    const optionName = `${x.firstName} ${x.lastName}`;
                    const valueName = params.inputValue;
                    return valueName ? optionName.toLowerCase().includes(valueName.toLowerCase()) : true;
                }) : [];
            }}
            getOptionLabel={(option) => !option.firstName && !option.lastName ? '': `${option.firstName} ${option.lastName}`}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            className="add-project-employee-autocomplete-input"
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={inputName}
                    value={value}
                    onChange={handleTextInput}
                    label={inputLabel}
                    error={errors.inputName && touched.inputName}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                />
            )}
        />
    );
};

export default AddEmployeeToDepartmentAutoComplete;
