import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchCoachingOpportunities, fetchEmployeeCoachingSessions } from 'actions/coachingOpportunitiesActions';
import { fetchTrainings } from 'actions/agencyActions';

import { 
    getAgencyTrainings,
    getCoachingOpportunities,
    getCoachingOpportunitiesPastSessions,
    getCoachingOpportunitiesUpcomingSessions,
    getDisplayMode
} from 'reducers';

import { CircularProgress, Paper } from '@material-ui/core';

import Section from 'components/Shared/Section';
import NoMatchesFound from 'components/Shared/NoMatchesFound';
import CoachingMentoring from './CoachingMentoring';
import TrainingCard from './TrainingCard';
import SessionCard from '../Shared/SessionCard';
import BookedSessions from 'components/Settings/CoachingOpportunityDetails/BookedSessions';

import './Trainings.scss';

const Trainings = ({
    agencyId,
    userId,
    displayMode,
    fetchCoachingOpportunities,
    fetchTrainings,
    fetchEmployeeCoachingSessions,
    coachingOpportunities,
    trainings,
    upcomingSessions,
    pastSessions,
    history,
}) => {
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        fetchCoachingOpportunities(agencyId, displayMode).then(() => {
            fetchTrainings(agencyId).then(() => {
                fetchEmployeeCoachingSessions(agencyId, userId).then(() => {
                    setIsPageLoading(false);
                });
            });
        });
    }, []);

    if (isPageLoading) {
        return (
            <div className="trainings-wrapper">
                <div className="loader-wrapper">
                    <CircularProgress size={50} className="centered" />
                </div>
            </div>
        );
    }

    return (
        <div className="trainings-wrapper">
            <Section
                title="Trainings"
            >
                { trainings.length
                    ?
                    <div className="trainings-list">
                        {trainings.map((x) => <TrainingCard key={x._id} training={x} history={history} />)}
                    </div>
                    :
                    <Paper className="no-matches-paper">
                        <NoMatchesFound text="No trainings" />
                    </Paper>
                }
            </Section>
            <Section
                title="Coaching & Mentoring Opportunities"
            >
                { coachingOpportunities.length
                    ? <CoachingMentoring coachingOpportunities={coachingOpportunities} />
                    :
                    <Paper className="no-matches-paper">
                        <NoMatchesFound text="No active coaching mentoring" />
                    </Paper>
                }

            </Section>
            <Section title="Upcoming Sessions">
                { upcomingSessions.length 
                    ?
                    <div className="global-sessions-list">
                        {upcomingSessions.map((x) =>
                            <SessionCard
                                key={x._id}
                                session={x}
                                title={x.coachingOpportunity.title}
                                subTitle={x.coachingOpportunity.coachName}
                                withHeaderBackground
                            />)}
                    </div>
                    :
                    <Paper className="no-matches-paper centered-content">
                        <NoMatchesFound text="No Upcoming Coaching Sessions" noImage />
                    </Paper>
                }
            </Section>
            <Section title="History of Past Sessions">
                { pastSessions.length > 0
                    ? 
                        <BookedSessions
                            coachingSessions={pastSessions}
                        />
                    :
                    <Paper className="no-matches-paper centered-content">
                        <NoMatchesFound text="No Past Coaching Sessions" />
                    </Paper>
                }
            </Section>
        </div>
    )
};

const mapStateToProps = state => ({
    coachingOpportunities: getCoachingOpportunities(state),
    trainings: getAgencyTrainings(state),
    displayMode: getDisplayMode(state),
    upcomingSessions: getCoachingOpportunitiesUpcomingSessions(state),
    pastSessions: getCoachingOpportunitiesPastSessions(state),
});

const mapDispatchToProps = {
    fetchCoachingOpportunities,
    fetchTrainings,
    fetchEmployeeCoachingSessions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainings);
