import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import RestoreIcon from '@material-ui/icons/Restore';
import Button from '@material-ui/core/Button';

const ResultsCardViewCandidate = ({
    result,
    handleClick,
    openConfirmRestoreDialog
}) => {
    const name = `${result.firstName} ${result.lastName}`;
    const picture = result.profilePictureImgUrl;

    return (
        <div className="card-view-wrapper">
            <ListItem
                className='result-list-item'
                button={!!handleClick}
                onClick={handleClick ? handleClick.bind(null, result) : null}
            >
                <div className="main-info">
                    <ListItemAvatar>
                        <Avatar src={picture} alt={name} className="search-result-avatar" />
                    </ListItemAvatar>
                    <ListItemText
                        className="name-info"
                        primary={name}
                        secondary={result.position}
                    />
                </div>
                <div className="secondary-info">
                    <ListItemText
                        primary="E-mail"
                        secondary={result.email || "N/A"}
                        className="email-field"
                    />
                    <ListItemText
                        primary="Phone"
                        secondary={result.contact?.phone || "N/A"}
                        className="phone-field"
                    />
                    <ListItemText
                        primary="Status"
                        secondary={result.status}
                        className="projects-field"
                    />
                </div>
                {result.isDeleted &&
                    <div className="restore-profile" onClick={() => openConfirmRestoreDialog({ id: result._id, role: result.role })}>
                        <div tooltip="Restore profile from here" className="restore-user-tooltip">
                            <Button >Deleted</Button>
                            <RestoreIcon className="restore-icon" />
                        </div>
                    </div>}
            </ListItem>
        </div>
    )
}

export default ResultsCardViewCandidate;
