import React, { createContext, useEffect } from 'react';
import { connect } from 'react-redux';

import JobTypeSelection from './JobTypeSelection';
import ExperienceSelection from './ExperienceSelection';

import { getAgencyId, getUserId, getCandidatePreferences } from 'reducers';
import { updateCandidatePreferences } from 'actions/candidateActions';
import { fetchJobOpportunitiesTechnologies } from 'actions/jobOpportunityActions';

import './YourProfileWrapper.scss';

export const YourProfileWrapperContext = createContext();
const { Provider } = YourProfileWrapperContext;

const YourProfileWrapper = ({
    children,
    continueJourney,
    agencyId,
    userId,
    candidatePreferences,
    fetchJobOpportunitiesTechnologies,
    updateCandidatePreferences,
}) => {
    const context = {};

    useEffect(() => {
        fetchJobOpportunitiesTechnologies(agencyId);
    }, [candidatePreferences.jobType]);

    children = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            handleContinueJourney: continueJourney,
            agencyId,
            userId,
            candidatePreferences,
            updateCandidatePreferences
        });
    });

    return (
            <Provider value={context}>
                {children}
            </Provider>
    );
};

const JobTypeSelectionWrapper = (props) => {

    return (
        <JobTypeSelection {...props} />
    );
};

const ExperienceSelectionWrapper = (props) => {

    return (
        <ExperienceSelection {...props} />
    );
};

YourProfileWrapper.JobTypeSelection = JobTypeSelectionWrapper;
YourProfileWrapper.ExperienceSelection = ExperienceSelectionWrapper;

const mapStateToProps = state => ({
    agencyId: getAgencyId(state),
    userId: getUserId(state),
    candidatePreferences: getCandidatePreferences(state),
});

const mapDispatchToProps = {
    fetchJobOpportunitiesTechnologies,
    updateCandidatePreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(YourProfileWrapper);
