import moment from 'moment';
import employeeService from 'services/employeeService';
import agencyService from 'services/agencyService';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import {
    fetchApprovedHolidaysSuccess,
    dashboardDataFetchSuccess,
    dashboardDataFetchingSuccess,
    updateMainMenuInformationSuccess
} from './agencyActions';
import {
    rejectHolidayRequestSuccess,
    deleteHolidayRequestsSuccess as deleteHolidayRequestsSuccessAsManager,
    createEmployeeHolidaysRequestsSuccess,
} from 'actions/managerActions';
import {
    cvPageUpdateSuccess,
    cvPageUpdatingSuccess,
} from 'actions/candidateActions';
import { fetchSupplierInformationSuccess } from './supplierActions';
import { toggleWorkspaceMigrationDialog } from './uiActions';

import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { showDialogActionButtonLoader, hideDialogActionButtonLoader } from './sectionsLoadersActions';

import { logEvent } from 'utils/amplitude';
import { generateNumericHash } from 'utils/helpers';

import { fetchAllStaticLanguagesSuccess } from './staticDataActions.js';
import { tasksDataFetchSuccess, tasksDataFetchingSuccess, fetchEmployeesPending, fetchEmployeesSuccess } from './sharedActions';
import { fetchUserHasNeededEvrotrustInfoSuccess, logout } from './authActions';

import {
    EDUCATION_ENTRY_ADD_SUCCESS,
    EDUCATION_ENTRY_EDIT_SUCCESS,
    EDUCATION_ENTRY_REMOVE_SUCCESS,
    LANGUAGE_ADD_SUCCESS,
    LANGUAGE_EDIT_SUCCESS,
    LANGUAGE_REMOVE_SUCCESS,
    REVIEW_CREATE_SUCCESS,
    REVIEW_EDIT_SUCCESS,
    REVIEW_DELETE_SUCCESS,
    REVIEW_GET_ALL_SUCCESS,
    PROFILE_SKILLS_UPDATE_SUCCESS,
    TRACKED_DAYS_FETCH_SUCCESS,
    TIMESHEET_EXPORT_SUCCESS,
    EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR,
    EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS,
    EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS,
    EMPLOYEE_PROJECTS_UPDATE_SUCCESS,
    PROFILE_SKILL_CREATE_SUCCESS,
    PROFILE_SKILL_DELETE_SUCCESS,
    CERTIFICATE_ADD_SUCCESS,
    CERTIFICATE_EDIT_SUCCESS,
    CERTIFICATE_REMOVE_SUCCESS,
    PROFILE_FETCH_SUCCESS,
    GENERAL_INFO_EDIT_SUCCESS,
    PERSONAL_INFO_FETCH_SUCCESS,
    CONTACT_INFO_UPDATE_SUCCESS,
    PERSONAL_INFO_UPDATE_SUCCESS,
    BASIC_INFO_UPDATE_SUCCESS,
    AUTH_BASIC_INFO_UPDATE_SUCCESS,
    EMPLOYMENT_INFO_GET_SUCCESS,
    EMPLOYMENT_INFO_UPDATE_SUCCESS,
    EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS,
    HOLIDAY_ALLOWANCE_EDIT_SUCCESS,
    EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS,
    EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS,
    EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS,
    CV_EXPORT_SUCCESS,
    TASK_ADD_SUCCESS,
    TASK_EDIT_SUCCESS,
    PERSONAL_AVATAR_EDIT_SUCCESS,
    PERSONAL_AVATAR_DELETE_SUCCESS,
    TASKS_FETCH_SUCCESS,
    EMPLOYEE_REGISTER_FETCH_SUCCESS,
    REGISTER_RECRUITER_EMPLOYEE_SUCCESS,
    HOLIDAY_REQUEST_CREATE_SUCCESS,
    EMPLOYEE_HOLIDAYS_FETCH_SUCCESS,
    EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS,
    PERSONAL_INFO_INITIAL_STATE_SET,
    EMPLOYEE_PROJECTS_FETCH_SUCCESS,
    SKILL_SUGGESTIONS_INITIAL_STATE_SET,
    EMPLOYEE_ROLE_UPDATE_SUCCESS,
    EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    BENEFIT_TEMPLATE_REQUEST_CREATE_SUCCESS,
    BENEFIT_REQUEST_CREATE_SUCCESS,
    BENEFITS_FETCH_SUCCESS,
    BENEFIT_TEMPLATES_FETCH_SUCCESS,
    BENEFITS_DELETE_SUCCESS,
    BENEFITS_UPDATE_SUCCESS,
    SKILL_SUGGESTIONS_FETCH_SUCCESS,
    SKILL_SUGGESTIONS_FETCH_PENDING,
    KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS,
    EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS,
    AUTH_USER_AVATAR_EDIT_SUCCESS,
    AUTH_USER_POSITION_EDIT_SUCCESS,
    OCCUPATION_STATUS_UPDATE_SUCCESS,
    RECRUITER_UPDATE_SUCCESS,
    EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS,
    COUNTRIES_FETCH_ALL_SUCCESS,
    AGENCY_ADMINS_FETCH_SUCCESS,
    CERTIFICATES_UPDATE_SUCCESS,
    EDUCATION_ENTRIES_UPDATE_SUCCESS,
    EMPLOYEE_TEAM_FETCH_SUCCESS,
    EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS,
    EMPLOYEES_TABLE_DATA_FETCH_SUCCESS,
    EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS,
    EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS,
    EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS,
    EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS,
    EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS,
    EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS,
    PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS,
    PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS,
    PROFILE_DATA_FETCH_SUCCESS,
    PROFILE_DATA_FETCHING_SUCCESS,
    TIME_TRACKING_DATA_FETCH_SUCCESS,
    TIME_TRACKING_DATA_FETCHING_SUCCESS,
    EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS,
    EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS,
    BENEFITS_DATA_FETCH_SUCCESS,
    BENEFITS_DATA_FETCHING_SUCCESS,
    EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS,
    EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS,
    EMPLOYMENT_DATES_UPDATE_SUCCESS,
    QUERIED_TEAM_RESET_FETCH_SUCCESS,
    TEAM_PAGE_DATA_FETCH_SUCCESS,
    EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS,
    EMPLOYEE_OVERVIEW_FETCHING_SUCCESS,
    EMPLOYEE_OVERVIEW_FETCH_SUCCESS,
} from 'actions/actionTypes';
import { MONTH_FORMAT } from 'components/Person/TimeTracking/TimeTrackingConstants';
import { ROLES } from 'constants/userConstants.js';
import { HOLIDAY_APPROVAL_STATUSES } from 'constants/holidayConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import {
    AUTH_MESSAGES,
    DASHBOARD_MESSAGES,
    EMAIL_MESSAGES,
    EMPLOYEE_MESSAGES,
    HOLIDAYS_MESSAGES,
    PERFORMANCE_REVIEWS_MESSAGES,
    TASK_MESSAGES,
} from 'constants/messageConstants';
import { HOLIDAY_REQUEST_CONSTANTS } from 'constants/actionsConstants';
import { DATE_FORMAT_WITH_DASH, MONTH_ISO_DATE_FORMAT, ISO_8601_DATE_FORMAT } from 'constants/commonConstants';
import { HOLIDAY_TYPES } from 'components/Person/Holidays/HolidaysRequests/HolidayRequestDialog/HolidayRequestDialogConstants';
import { NOTIFICATION_TYPES, NOTIFICATION_CLICKAWAY } from 'constants/notificationTypes';
import { HTTP_STATUS_CODES } from 'constants/httpStatusCodesConstants';

// Education
const addEducationEntrySuccess = data => ({
    type: EDUCATION_ENTRY_ADD_SUCCESS,
    payload: data,
});

const editEducationEntrySuccess = data => ({
    type: EDUCATION_ENTRY_EDIT_SUCCESS,
    payload: data,
});

const updateEducationEntriesSuccess = data => ({
    type: EDUCATION_ENTRIES_UPDATE_SUCCESS,
    payload: data
});

const removeEducationEntrySuccess = _id => ({
    type: EDUCATION_ENTRY_REMOVE_SUCCESS,
    payload: _id,
});

//Projects
const clearEmployeeCurrentProjectSuccess = () => ({
    type: EMPLOYEE_CURRENT_PROJECT_ENTRY_CLEAR
});

const getEmployeeProjectEntrySuccess = data => ({
    type: EMPLOYEE_PROJECT_ENTRY_GET_SUCCESS,
    payload: data,
});

const addEmployeeProjectEntrySuccess = data => ({
    type: EMPLOYEE_PROJECT_ENTRY_ADD_SUCCESS,
    payload: data,
});

const editEmployeeProjectEntrySuccess = data => ({
    type: EMPLOYEE_PROJECT_ENTRY_EDIT_SUCCESS,
    payload: data,
});

const removeEmployeeProjectEntrySuccess = entryId => ({
    type: EMPLOYEE_PROJECT_ENTRY_REMOVE_SUCCESS,
    payload: entryId,
});

const updateEmployeePersonalProjectsSuccess = data => ({
    type: EMPLOYEE_PROJECTS_UPDATE_SUCCESS,
    payload: data,
});

const updateProfileSkillsSuccess = data => ({
    type: PROFILE_SKILLS_UPDATE_SUCCESS,
    payload: data,
});

const createProfileSkillSuccess = data => ({
    type: PROFILE_SKILL_CREATE_SUCCESS,
    payload: data,
});

const deleteProfileSkillSuccess = profileSkillId => ({
    type: PROFILE_SKILL_DELETE_SUCCESS,
    payload: profileSkillId,
});

//Language
const addLanguageSuccess = data => ({
    type: LANGUAGE_ADD_SUCCESS,
    payload: data,
});

const editLanguageSuccess = data => ({
    type: LANGUAGE_EDIT_SUCCESS,
    payload: data,
});

const removeLanguageSuccess = entryId => ({
    type: LANGUAGE_REMOVE_SUCCESS,
    payload: entryId,
});

//Review
const createReviewSuccess = data => ({
    type: REVIEW_CREATE_SUCCESS,
    payload: data,
});

const updateReviewSuccess = data => ({
    type: REVIEW_EDIT_SUCCESS,
    payload: data,
});

const deleteReviewSuccess = data => ({
    type: REVIEW_DELETE_SUCCESS,
    payload: data,
});

const getAllReviewsSuccess = reviewsData => ({
    type: REVIEW_GET_ALL_SUCCESS,
    payload: reviewsData
})

//Personal Info

const fetchPersonalInfoSuccess = personalData => ({
    type: PERSONAL_INFO_FETCH_SUCCESS,
    payload: personalData
});

const updateContactInfoSuccess = contactInfo => ({
    type: CONTACT_INFO_UPDATE_SUCCESS,
    payload: contactInfo
});

export const updatePersonalInfoSuccess = personalInfo => ({
    type: PERSONAL_INFO_UPDATE_SUCCESS,
    payload: personalInfo
});

const updateBasicInfoSuccess = basicInfo => ({
    type: BASIC_INFO_UPDATE_SUCCESS,
    payload: basicInfo
});

const updateAuthBasicInfoSuccess = basicInfoData => ({
    type: AUTH_BASIC_INFO_UPDATE_SUCCESS,
    payload: basicInfoData
});

const fetchEmploymentInformationSuccess = employementInfoData => ({
    type: EMPLOYMENT_INFO_GET_SUCCESS,
    payload: employementInfoData
});

const updateEmploymentInformationSuccess = employementInfoData => ({
    type: EMPLOYMENT_INFO_UPDATE_SUCCESS,
    payload: employementInfoData
});

const editEmployeeActiveCompensationSuccess = employementActiveCompensationData => ({
    type: EMPLOYMENT_ACTIVE_COMPENSETION_EDIT_SUCCESS,
    payload: employementActiveCompensationData
})

const editHolidayAllowanceSuccess = editedHolidayAllowance => ({
    type: HOLIDAY_ALLOWANCE_EDIT_SUCCESS,
    payload: editedHolidayAllowance
});

const editBankDetailsSuccess = employmentBankDetailsData => ({
    type: EMPLOYMENT_BANK_DETAILS_EDIT_SUCCESS,
    payload: employmentBankDetailsData
});
const editContractInformationSuccess = employeeContractData => ({
    type: EMPLOYEE_CONTRACT_INFO_EDIT_SUCCESS,
    payload: employeeContractData
});
const fetchContractInformationSuccess = employeeContractData => ({
    type: EMPLOYEE_CONTRACT_INFO_FETCH_SUCCESS,
    payload: employeeContractData
});

const fetchProfileSuccess = profileData => ({
    type: PROFILE_FETCH_SUCCESS,
    payload: profileData,
});

const fetchSkillSuggestionsSuccess = skillsData => ({
    type: SKILL_SUGGESTIONS_FETCH_SUCCESS,
    payload: skillsData,
});

export const setSkillSuggestionsInitialState = () => ({
    type: SKILL_SUGGESTIONS_INITIAL_STATE_SET,
});

const fetchSkillSuggestionsPending = () => ({
    type: SKILL_SUGGESTIONS_FETCH_PENDING,
});

//Time Tracking
const fetchTrackedDaysSuccess = (trackedDays) => ({
    type: TRACKED_DAYS_FETCH_SUCCESS,
    payload: trackedDays
});

const exportTimeSheetSuccess = () => ({
    type: TIMESHEET_EXPORT_SUCCESS
});

// Certificate
const addCertificateSuccess = data => ({
    type: CERTIFICATE_ADD_SUCCESS,
    payload: data,
});

const editCertificateSuccess = data => ({
    type: CERTIFICATE_EDIT_SUCCESS,
    payload: data,
});

const updateCertificatesSuccess = data => ({
    type: CERTIFICATES_UPDATE_SUCCESS,
    payload: data,
});

const removeCertificateSuccess = certificateId => ({
    type: CERTIFICATE_REMOVE_SUCCESS,
    payload: certificateId,
});

const updateGeneralInfo = (info) => ({
    type: GENERAL_INFO_EDIT_SUCCESS,
    payload: info
});


const updateAuthUserPositionSuccess = position => ({
    type: AUTH_USER_POSITION_EDIT_SUCCESS,
    payload: position
});


const fetchKnowYourTeamSampleSuccess = employeesData => ({
    type: KNOW_YOUR_TEAM_SAMPLE_REFRESH_FETCH_SUCCESS,
    payload: employeesData
});

const fetchEmployeeTeamSuccess = employeesData => ({
    type: EMPLOYEE_TEAM_FETCH_SUCCESS,
    payload: employeesData,
});

// Tasks
const createTaskSuccess = task => ({
    type: TASK_ADD_SUCCESS,
    payload: task
});

const editTaskSuccess = task => ({
    type: TASK_EDIT_SUCCESS,
    payload: task
});

export const getAllTasksSuccess = tasks => ({
    type: TASKS_FETCH_SUCCESS,
    payload: tasks
});

//Export CV
const exportCVSuccess = () => ({
    type: CV_EXPORT_SUCCESS,
})

const updateAuthUserAvatarSuccess = pictureUrl => ({
    type: AUTH_USER_AVATAR_EDIT_SUCCESS,
    payload: pictureUrl
});

const updateAvatarSuccess = pictureUrl => ({
    type: PERSONAL_AVATAR_EDIT_SUCCESS,
    payload: pictureUrl
});

const deleteAvatarSuccess = () => ({
    type: PERSONAL_AVATAR_DELETE_SUCCESS
});

const fetchRegisterEmployeeSuccess = (userData) => ({
    type: EMPLOYEE_REGISTER_FETCH_SUCCESS,
    payload: userData
});

const fetchRegisterRecruiterEmployee = employee => ({
    type: REGISTER_RECRUITER_EMPLOYEE_SUCCESS,
    payload: employee
})

//Holidays
const createHolidaysRequestSuccess = holidays => ({
    type: HOLIDAY_REQUEST_CREATE_SUCCESS,
    payload: holidays
});

const fetchHolidaysSuccess = holidays => ({
    type: EMPLOYEE_HOLIDAYS_FETCH_SUCCESS,
    payload: holidays
});

const rejectHolidayRequestsSuccess = request => ({
    type: EMPLOYEE_HOLIDAYS_REJECT_REQUEST_SUCCESS,
    payload: request
});

const approveHolidayRequestsSuccess = request => ({
    type: EMPLOYEE_HOLIDAYS_APPROVE_REQUESTS_SUCCESS,
    payload: request
});

const fetchHolidayAllowanceSuccess = holidayAllowance => ({
    type: EMPLOYEE_HOLIDAYS_ALLOWANCE_FETCH_SUCCESS,
    payload: holidayAllowance
});

const deleteHolidayRequestsSuccess = requests => ({
    type: EMPLYOEE_HOLIDAYS_DELETE_REQUESTS_SUCCESS,
    payload: requests
});

const deleteHolidayPendingRequestsSuccess = requests => ({
    type: EMPLYOEE_HOLIDAYS_DELETE_PENDING_REQUESTS_SUCCESS,
    payload: requests
});

export const setPersonalInfoInitialState = () => ({
    type: PERSONAL_INFO_INITIAL_STATE_SET
});

const fetchEmployeeProjectsSuccess = data => ({
    type: EMPLOYEE_PROJECTS_FETCH_SUCCESS,
    payload: data,
});

const updateEmployeesRoleSuccess = data => ({
    type: EMPLOYEE_ROLE_UPDATE_SUCCESS,
    payload: data,
});

const fetchPendingHolidaysCountSuccess = pendingHolidaysCount => ({
    type: EMPLOYEE_PENDING_HOLIDAYS_COUNT_FETCH_SUCCESS,
    payload: pendingHolidaysCount
});

//benefits

const createBenefitTemplateSuccess = benefits => ({
    type: BENEFIT_TEMPLATE_REQUEST_CREATE_SUCCESS,
    payload: benefits,
})

const createBenefitSuccess = benefits => ({
    type: BENEFIT_REQUEST_CREATE_SUCCESS,
    payload: benefits
});

const fetchBenefitTemplatesSuccess = benefitTemplates => ({
    type: BENEFIT_TEMPLATES_FETCH_SUCCESS,
    payload: benefitTemplates
});

const fetchBenefitsSuccess = benefits => ({
    type: BENEFITS_FETCH_SUCCESS,
    payload: benefits
});

const removeBenefitSuccess = benefitId => ({
    type: BENEFITS_DELETE_SUCCESS,
    payload: benefitId,
});

const updateBenefitSuccess = benefits => ({
    type: BENEFITS_UPDATE_SUCCESS,
    payload: benefits,
});

const fetchEmployeeRelatedOpportunitiesSuccess = opportunitiesData => ({
    type: EMPLOYEE_RELATED_OPPORTUNITIES_FETCH_SUCCESS,
    payload: opportunitiesData
});

const updateEmployeeOccupationStatusSuccess = data => ({
    type: OCCUPATION_STATUS_UPDATE_SUCCESS,
    payload: data,
});

const updateEmployeeRecruiterSuccess = data => ({
    type: RECRUITER_UPDATE_SUCCESS,
    payload: data,
});

const changedExperienceSinceSuccess = date => ({
    type: EMPLOYEE_EXPERIENCE_SINCE_UPDATE_SUCCESS,
    payload: date
});

const fetchAllCountriesSuccess = countries => ({
    type: COUNTRIES_FETCH_ALL_SUCCESS,
    payload: countries
});

const fetchAdminsSuccess = adminsData => ({
    type: AGENCY_ADMINS_FETCH_SUCCESS,
    payload: adminsData
});

export const fetchEmployeeUploadedCvSuccess = uploadedCv => ({
    type: EMPLOYEE_UPLOADED_CV_FETCH_SUCCESS,
    payload: uploadedCv,
});

const employeesTableDataFetchSuccess = () => ({
    type: EMPLOYEES_TABLE_DATA_FETCH_SUCCESS,
});

const employeeTableDataFetchingSuccess = () => ({
    type: EMPLOYEES_TABLE_DATA_FETCHING_SUCCESS,
})

const employeesRelatedOpportunitiesDataFetchSuccess = () => ({
    type: EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCH_SUCCESS,
});

const employeesRelatedOpportunitiesDataFetchingSuccess = () => ({
    type: EMPLOYEES_RELATED_OPPORTUNITIES_DATA_FETCHING_SUCCESS,
});

const employeesPersonalInformationDataFetchSuccess = () => ({
    type: EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCH_SUCCESS,
});

const employeesPersonalInformationDataFetchingSuccess = () => ({
    type: EMPLOYEES_PERSONAL_INFORMATION_DATA_FETCHING_SUCCESS,
});

const employeesEmploymentInformationDataFetchingSuccess = () => ({
    type: EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCHING_SUCCESS,
});

const employeesEmploymentInformationDataFetchSuccess = () => ({
    type: EMPLOYEES_EMPLOYMENT_INFORMATION_DATA_FETCH_SUCCESS,
});

const performanceReviewsDataFetchSuccess = () => ({
    type: PERFORMANCE_REVIEWS_DATA_FETCH_SUCCESS,
});

const performanceReviewsDataFetchingSuccess = () => ({
    type: PERFORMANCE_REVIEWS_DATA_FETCHING_SUCCESS,
});

const profileDataFetchSuccess = () => ({
    type: PROFILE_DATA_FETCH_SUCCESS,
});

const profileDataFetchingSuccess = () => ({
    type: PROFILE_DATA_FETCHING_SUCCESS,
});

const timeTrackingDataFetchSuccess = () => ({
    type: TIME_TRACKING_DATA_FETCH_SUCCESS,
});

const timeTrackingDataFetchingSuccess = () => ({
    type: TIME_TRACKING_DATA_FETCHING_SUCCESS,
});

const employeesHolidayDataFetchSuccess = () => ({
    type: EMPLOYEES_HOLIDAYS_DATA_FETCH_SUCCESS,
});

const employeesHolidayDataFetchingSuccess = () => ({
    type: EMPLOYEES_HOLIDAYS_DATA_FETCHING_SUCCESS,
});

const benefitsDataFetchSuccess = () => ({
    type: BENEFITS_DATA_FETCH_SUCCESS,
});

const benefitsDataFetchingSuccess = () => ({
    type: BENEFITS_DATA_FETCHING_SUCCESS,
});

const employeeGeneralInfoFetchSuccess = (data) => ({
    type: EMPLOYEE_GENERAL_INFO_FETCH_SUCCESS,
    payload: data,
});

const updateEmploymentDatesSuccess = (data) => ({
    type: EMPLOYMENT_DATES_UPDATE_SUCCESS,
    payload: data,
});

export const fetchQueriedTeamResetSuccess = () => ({
    type: QUERIED_TEAM_RESET_FETCH_SUCCESS,
    payload: [],
});

const fetchTeamPageSuccess = (data) => ({
    type: TEAM_PAGE_DATA_FETCH_SUCCESS,
    payload: data,
});

const fetchEmployeeOverviewInformationSuccess = data => ({
    type: EMPLOYEE_OVERVIEW_INFORMATION_FETCH_SUCCESS,
    payload: data,
});

export const employeeOverviewFetchingSuccess = () => ({
    type: EMPLOYEE_OVERVIEW_FETCHING_SUCCESS
});

const employeeOverviewFetchSuccess = () => ({
    type: EMPLOYEE_OVERVIEW_FETCH_SUCCESS
});

export const addEducationEntry = (educationEntry, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const savedEntry = await employeeService.createEducationEntry(
            educationEntry,
            employeeId,
            agencyId,
            departmentId
        );

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_EDUCATION, { rolesPermitted: [ROLES.ADMIN] });
        dispatch(addEducationEntrySuccess(savedEntry));
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_EDUCATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_EDUCATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editEducationEntry = (educationEntry, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        await employeeService.updateEducationEntry(educationEntry, employeeId, agencyId, departmentId);

        dispatch(editEducationEntrySuccess(educationEntry));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_EDUCATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_EDUCATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateEducationEntries = (educationEntries, employeeId, agencyId) => async dispatch => {
    try {
        const updatedEducationEntries = await employeeService.updateEducationEntries(educationEntries, employeeId, agencyId);

        dispatch(updateEducationEntriesSuccess(updatedEducationEntries));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_EDUCATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_EDUCATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const removeEducationEntry = (id, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const deletedId = await employeeService.deleteEducationEntry(id, employeeId, agencyId, departmentId);

        dispatch(removeEducationEntrySuccess(deletedId));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_EDUCATION_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_EDUCATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const clearEmployeeCurrentProject = () => dispatch => {
    try {
        dispatch(clearEmployeeCurrentProjectSuccess());
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.CLEAR_EMPLOYEE_CURRENT_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR))
    }
};

export const getEmployeeProjectEntry = (projectEntryId, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const projectEntry = await employeeService.getProjectEntry(projectEntryId, employeeId, agencyId, departmentId);
        dispatch(getEmployeeProjectEntrySuccess(projectEntry));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const addEmployeeProjectEntry = (projectEntry, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const { newProjectEntry, earliestDate } = await employeeService.createProjectEntry(projectEntry, employeeId, agencyId, departmentId);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_EXPERIENCE, { rolesPermitted: [ROLES.ADMIN] });

        dispatch(addEmployeeProjectEntrySuccess(newProjectEntry));

        if (earliestDate !== '') {
            dispatch(changedExperienceSinceSuccess(earliestDate));
        }
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_PROJECT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editEmployeeProjectEntry = (projectEntry, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const { earliestDate } = await employeeService.updateProjectEntry(projectEntry, employeeId, agencyId, departmentId);
        dispatch(editEmployeeProjectEntrySuccess(projectEntry));

        if (earliestDate !== '') {
            dispatch(changedExperienceSinceSuccess(earliestDate));
        }
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_PROJECT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const removeEmployeeProjectEntry = (id, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const deletedId = await employeeService.deleteProjectEntry(id, employeeId, agencyId, departmentId);
        dispatch(removeEmployeeProjectEntrySuccess(deletedId));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_PROJECT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_PROJECT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateEmployeePersonalProjects = (projects, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const updateProjects = await employeeService.updatePersonalProjects(projects, employeeId, agencyId, departmentId);
        dispatch(updateEmployeePersonalProjectsSuccess(updateProjects));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_PROJECTS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateEmployeeCertificates = (certificates, employeeId, agencyId) => async dispatch => {
    try {
        const updatedCertificates = await employeeService.updateCertificates(certificates, employeeId, agencyId);
        dispatch(updateCertificatesSuccess(updatedCertificates));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateProfileSkills = (agencyId, employeeId, profileSkills, departmentId) => async dispatch => {
    try {
        const result = await employeeService.updateProfileSkills(agencyId, employeeId, { profileSkills }, departmentId);
        dispatch(updateProfileSkillsSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_SKILLS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_SKILLS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createProfileSkill = (agencyId, employeeId, skill, isNew, profileSkills, departmentId) => async dispatch => {
    try {
        if (profileSkills.find(x => x.name === skill.name)) {
            throw skill;
        }

        const result = await employeeService.createProfileSkill(agencyId, employeeId, skill.name, isNew, departmentId);

        dispatch(createProfileSkillSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_SKILL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_SKILL_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteProfileSkill = (agencyId, employeeId, profileSkillId, departmentId) => async dispatch => {
    try {
        const deletedProfileSkillId = await employeeService.deleteProfileSkill(agencyId, employeeId, encodeURIComponent(profileSkillId), departmentId);

        dispatch(deleteProfileSkillSuccess(deletedProfileSkillId));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_SKILL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_SKILL_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const addLanguage = (language, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const savedLanguage = await employeeService.createLanguage(
            { name: language.name, level: language.level },
            employeeId,
            agencyId,
            departmentId
        );
        logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_LANGUAGE, { rolesPermitted: [ROLES.ADMIN] });
        dispatch(addLanguageSuccess(savedLanguage));
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_LANGUAGE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_LANGUAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editLanguage = (language, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        await employeeService.updateLanguage(language, employeeId, agencyId, departmentId);
        dispatch(editLanguageSuccess(language));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_LANGUAGE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_LANGUAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const removeLanguage = (id, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const deletedId = await employeeService.deleteLanguage(id, employeeId, agencyId, departmentId);
        dispatch(removeLanguageSuccess(deletedId));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_LANGUAGE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_LANGUAGE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createReview = (reviewData, employeeId, agencyId) => async dispatch => {
    try {
        const createdReview = await employeeService.createReview(reviewData, employeeId, agencyId);
        dispatch(createReviewSuccess(createdReview));
        dispatch(showNotification(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_CREATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_CREATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
export const updateReview = (reviewData, employeeId, agencyId) => async dispatch => {
    try {
        const updatedReview = await employeeService.updateReview(reviewData, employeeId, agencyId);

        dispatch(updateReviewSuccess(updatedReview));
        dispatch(showNotification(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_EDIT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_EDIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteReview = (reviewId, employeeId, agencyId) => async dispatch => {
    try {
        const deletedReviewId = await employeeService.deleteReview(reviewId, employeeId, agencyId);

        dispatch(deleteReviewSuccess(deletedReviewId));
        dispatch(showNotification(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_DELETE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_EDIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const getAllReviews = (employeeId, agencyId, isNotInitialPageLoad) => async dispatch => {
    try {
        if (!isNotInitialPageLoad) {
            dispatch(performanceReviewsDataFetchingSuccess());
        }
        const reviewsData = await employeeService.getAllReviews(employeeId, agencyId);
        dispatch(getAllReviewsSuccess(reviewsData));

    } catch (error) {
        dispatch(authorizedShowNotification(error)(PERFORMANCE_REVIEWS_MESSAGES.PERFORMANCE_REVIEW_CREATE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(performanceReviewsDataFetchSuccess());
    }
};

export const fetchTrackedDays = (month, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        // TODO: notification track time success
        const trackedDays = await employeeService.getTrackedDays(agencyId, employeeId, month, departmentId);
        dispatch(fetchTrackedDaysSuccess(formatTrackedDays(trackedDays)));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const trackDays = (agencyId, departmentId, employeeId, month, days, data) => async dispatch => {
    try {
        const newDays = days.map(day => (day.project.isSickLeave ? {
            date: moment(day.start).format(DATE_FORMAT_WITH_DASH),
            projectId: day.project._id,
            isSickLeave: day.project.isSickLeave,
            hours: Number(day.hours),
            fileInfo: day.fileInfo,
            tasksDesc: day.tasksDesc
        } : {
            date: moment(day.start).format(DATE_FORMAT_WITH_DASH),
            projectId: day.project._id,
            hours: Number(day.hours),
            fileInfo: day.fileInfo,
            tasksDesc: day.tasksDesc
        }));

        data.append('days', JSON.stringify(newDays))

        let type = 'data';
        if (data.getAll('attachedfile1').length > 0) {
            type = 'file';
        }

        const trackedDays = await employeeService.trackDays(agencyId, departmentId, employeeId, data, month, type);
        const currentMonthDays = formatTrackedDays(trackedDays);

        logEvent(AMPLITUDE_EVENT_TYPES.TRACK_EMPLOYEE_TIME, { rolesPermitted: [ROLES.ADMIN] });
        dispatch(fetchTrackedDaysSuccess(currentMonthDays));
        dispatch(showNotification(EMPLOYEE_MESSAGES.TRACKED_DAYS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message === EMPLOYEE_MESSAGES.CANNOT_TRACK_GIVEN_DAYS || error.message === EMPLOYEE_MESSAGES.DELETE_SICK_LEAVE_UNSUCCESSFUL) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.TRACKED_DAYS_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
}

const getTrackedProjectsTitles = trackedDays => {
    const selectedProjects = [];

    trackedDays.forEach(x => {
        if (!selectedProjects.some(project => project._id === x.project._id)) {
            selectedProjects.push(x.project);
        }
    });

    return selectedProjects;
};

export const exportTimesheet = (month, employeeId, agencyId, departmentId, trackedDays) => async dispatch => {
    try {

        const currMonthDays = trackedDays.filter(x => x.start._i.includes(month));

        const trackedProjects = getTrackedProjectsTitles(currMonthDays);
        const personalInfo = await employeeService.fetchPersonalInfo(agencyId, employeeId, departmentId);
        const formattedMonth = moment(month, MONTH_FORMAT).format(MONTH_ISO_DATE_FORMAT);

        const hashDataForId = trackedProjects.length === 0
            ? `${employeeId}${month}`
            : `${employeeId}${trackedProjects[0]._id}${month}`;

        const tenDigitHashId = generateNumericHash(hashDataForId);

        const fullName = trackedProjects.length < 2
            ? `${tenDigitHashId}-${formattedMonth}-${personalInfo.profileData.firstName} ${personalInfo.profileData.lastName}${trackedProjects[0]?.name ? `-${trackedProjects[0].name}` : ''}`
            : `${formattedMonth}-${personalInfo.profileData.firstName} ${personalInfo.profileData.lastName}`;

        const result = await employeeService.getEmployeeExportedTimesheet(employeeId, month, fullName, agencyId, departmentId, trackedProjects.filter(x => !x.isSickLeave));

        logEvent(AMPLITUDE_EVENT_TYPES.EXPORT_EMPLOYEE_TIME, { rolesPermitted: [ROLES.ADMIN] });
        if (result && result.noFiles) {
            dispatch(showNotification(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_TIMESHEETS_NO_FILES, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(exportTimeSheetSuccess());
            dispatch(showNotification(EMPLOYEE_MESSAGES.EXPORT_TIMESHEETS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.EXPORT_TIMESHEETS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

const formatTrackedDays = days => {
    const formattedDays = [];
    if (days) {
        days.forEach(day => {
            day.trackedHours.forEach(hour => {
                const fileInfoData = hour.project.isSickLeave ? { fileInfo: hour.fileInfo } : {};
                formattedDays.push({
                    date: day.date,
                    project: hour.project,
                    hours: hour.hours,
                    tasksDesc: hour.tasksDesc,
                    ...fileInfoData
                })
            })
        })
    }

    return formattedDays;
}

export const addCertificate = (certificate, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const savedCertificate = await employeeService.createCertificate(
            certificate,
            employeeId,
            agencyId,
            departmentId
        );

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_CERTIFICATION, { rolesPermitted: [ROLES.ADMIN] });
        dispatch(addCertificateSuccess(savedCertificate));
        dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_CERTIFICATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editCertificate = (certificate, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        await employeeService.updateCertificate(certificate, employeeId, agencyId, departmentId);

        dispatch(editCertificateSuccess(certificate));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const removeCertificate = (id, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const deletedId = await employeeService.deleteCertificate(id, employeeId, agencyId, departmentId);

        dispatch(removeCertificateSuccess(deletedId));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_CERTIFICATE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_CERTIFICATE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchSkillSuggestions = (agencyId, inputText) => async dispatch => {
    dispatch(fetchSkillSuggestionsPending());
    try {
        inputText = encodeURIComponent(inputText);

        const skillsSuggestions = await employeeService.fetchSkillSuggestions(agencyId, inputText);
        dispatch(fetchSkillSuggestionsSuccess(skillsSuggestions));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_SKILL_SUGGESTIONS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchProfile = (employeeId, agencyId, departmentId) => async dispatch => {
    try {
        dispatch(profileDataFetchingSuccess());

        const { adminsData, ...profileData } = await employeeService.getProfile(employeeId, agencyId, departmentId);

        dispatch(fetchAllStaticLanguagesSuccess(profileData.staticLanguages));
        dispatch(fetchProfileSuccess(profileData));
        dispatch(fetchAdminsSuccess(adminsData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_PROFIOLE_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(profileDataFetchSuccess());
    }
};

export const fetchInternalEmployees = (agencyId, fields, roles) => async dispatch => {
    dispatch(fetchEmployeesPending());
    try {
        dispatch(employeeTableDataFetchingSuccess());

        const employeesData = await employeeService.fetchInternalEmployees(agencyId, fields, roles);

        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesTableDataFetchSuccess());
    }
};

export const fetchAdminTeamPage = (agencyId, query, resetState) => async dispatch => {
    try {
        if (resetState) {
            dispatch(fetchQueriedTeamResetSuccess());
        }

        const result = await employeeService.fetchAdminTeamPage(agencyId, query);

        dispatch(fetchTeamPageSuccess(result.data));
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportAdminTeam = (agencyId, searchTerm) => async dispatch => {
    try {
        await employeeService.exportAdminTeam(agencyId, { searchTerm });
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchKnowYourTeamSample = (agencyId, userId, size) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const employeesData = await employeeService.fetchKnowYourTeamSample(agencyId, userId, size);

        dispatch(fetchKnowYourTeamSampleSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchEmployeeTeam = (agencyId, employeeId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const employeesData = await employeeService.fetchEmployeeTeam(agencyId, employeeId);

        dispatch(fetchEmployeeTeamSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchAllClientConsultants = (agencyId, requiredFields) => async dispatch => {
    try {
        const employeesData = await employeeService.fetchAllClientConsultants(agencyId, requiredFields);
        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const fetchAllConsultantsData = (agencyId, requiredFields) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        const employeesData = await employeeService.fetchAllConsultantsData(agencyId, requiredFields);
        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchSupplierTeamPage = (agencyId, supplierId, query, resetState) => async dispatch => {
    try {
        if (resetState) {
            dispatch(fetchQueriedTeamResetSuccess());
        }

        const result = await employeeService.fetchSupplierTeamPage(agencyId, supplierId, query);

        dispatch(fetchEmployeesSuccess(result.data));
        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_SUPPLIER_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesTableDataFetchSuccess());
    }
};

export const fetchAllSupplierEmployees = (agencyId, supplierId, preserveState) => async dispatch => {
    if (!preserveState) {
        dispatch(fetchEmployeesPending());
    }
    try {
        dispatch(employeeTableDataFetchingSuccess());
        const employeesData = await employeeService.fetchAllSupplierEmployees(agencyId, supplierId);

        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_SUPPLIER_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesTableDataFetchSuccess());
    }
};

export const fetchAllRecruiterEmployees = (agencyId, recruiterId) => async dispatch => {
    dispatch(fetchEmployeesPending());

    try {
        const employeesData = await employeeService.fetchAllRecruiterEmployees(agencyId, recruiterId);
        dispatch(fetchEmployeesSuccess(employeesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_RECRUITER_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchPersonalInfo = (agencyId, employeeId, viewOnly, departmentId) => async dispatch => {
    dispatch(setPersonalInfoInitialState());

    try {
        dispatch(employeesPersonalInformationDataFetchingSuccess());

        const personalData = viewOnly
            ? await employeeService.fetchPersonalInfoViewer(agencyId, employeeId)
            : await employeeService.fetchPersonalInfo(agencyId, employeeId, departmentId);

        dispatch(fetchPersonalInfoSuccess(personalData.profileData));
        dispatch(fetchPendingHolidaysCountSuccess(personalData.pendingHolidaysCount));
        dispatch(fetchContractInformationSuccess({ contractInformation: personalData.contractInformationData || {} }));
        dispatch(fetchAllCountriesSuccess(personalData.countries));
        dispatch(fetchSupplierInformationSuccess({ basic: personalData.supplierData || {} }));

        return personalData.profileData;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_PERSONAL_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR))
    } finally {
        dispatch(employeesPersonalInformationDataFetchSuccess());
    }
};

export const updateContactInfo = (agencyId, departmentId, employeeId, contactInfo) => async dispatch => {
    try {
        const newContactInfo = await employeeService.updateContactInfo(agencyId, departmentId, employeeId, contactInfo);
        dispatch(updateContactInfoSuccess(newContactInfo));
        dispatch(showNotification(EMPLOYEE_MESSAGES.CONTACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const updatePersonalInfo = (agencyId, departmentId, employeeId, personalInfo) => async dispatch => {
    try {
        const newPersonalInfo = await employeeService.updatePersonalInfo(agencyId, departmentId, employeeId, personalInfo);
        dispatch(updatePersonalInfoSuccess(newPersonalInfo));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_PERSONAL_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_PERSONAL_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const exportCV = (employees, agencyId, departmentId) => async dispatch => {
    try {
        const agencyInfo = await agencyService.fetchAgencyName(agencyId);

        let fileName = '';

        if (employees.length === 1) {
            fileName = `${employees[0].firstName}-${agencyInfo.name}-CV`;
        } else {
            fileName = `${moment().format(ISO_8601_DATE_FORMAT)}-${agencyInfo.name}-CV-Export`
        }

        await employeeService.getExportedCVs(agencyId, employees.map(x => x._id), fileName, departmentId);

        dispatch(exportCVSuccess());
        dispatch(showNotification(EMPLOYEE_MESSAGES.EXPORT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const editEmployeeActiveCompensation = (activeCompensation, employeeId, agencyId) => async dispatch => {
    try {
        const updatedActiveCompensation =
            await employeeService.updateEmployeeActiveCompensation(activeCompensation, employeeId, agencyId);

        dispatch(editEmployeeActiveCompensationSuccess({ activeCompensation: updatedActiveCompensation }));
        dispatch(showNotification(EMPLOYEE_MESSAGES.EDIT_EMPLOYEE_ACTIVE_COMPENSATION, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const editHolidayAllowance = (holidayAllowance, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const editedHolidayAllowance = await employeeService.updateHolidayAllowance(holidayAllowance, employeeId, agencyId, departmentId);
        dispatch(editHolidayAllowanceSuccess(editedHolidayAllowance.holidayAllowance));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_HOLIDAY_ALLOWANCE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editBankDetails = (editBankDetails, employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const updatedBankDetails =
            await employeeService.updateBankDetails(editBankDetails, employeeId, agencyId, departmentId);

        dispatch(editBankDetailsSuccess({ bankDetails: updatedBankDetails }));
        dispatch(showNotification(EMPLOYEE_MESSAGES.EDIT_BANK_DETAILS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateBasicInfo = (agencyId, departmentId, employeeId, basicInfo, userId, email) => async dispatch => {
    try {
        const updatedBasicInfo = await employeeService.updateBasicInfo(agencyId, departmentId, employeeId, { basicInfo });
        dispatch(updateBasicInfoSuccess({ ...updatedBasicInfo, employeeId }));
        if (userId === employeeId) {
            dispatch(updateAuthBasicInfoSuccess({ ...updatedBasicInfo, employeeId }));
            const menuName = `${updatedBasicInfo.firstName} ${updatedBasicInfo.lastName}`;
            dispatch(updateMainMenuInformationSuccess({ menuName }));
        }

        if (basicInfo.role === ROLES.SUPPLIER_ADMIN && basicInfo.email !== email) {
            dispatch(logout());
            dispatch(showNotification(AUTH_MESSAGES.EMAIL_CHANGED_AND_LOGGED_OUT, NOTIFICATION_TYPES.SUCCESS));
        } else {
            dispatch(showNotification(EMPLOYEE_MESSAGES.BASIC_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const createTask = (task, employeeId, agencyId) => async dispatch => {
    try {
        const newTask = await employeeService.createTask(task, employeeId, agencyId);
        dispatch(createTaskSuccess(newTask));
        dispatch(showNotification(TASK_MESSAGES.CREATE_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.CREATE_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editTask = (task, employeeId, agencyId) => async dispatch => {
    try {
        const editedTask = await employeeService.updateTask(task, employeeId, agencyId);
        dispatch(editTaskSuccess(editedTask));
        dispatch(showNotification(TASK_MESSAGES.EDIT_TASK_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.EDIT_TASK_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const saveTask = (task, employeeId, agencyId) => dispatch => {
    task._id
        ? dispatch(editTask(task, employeeId, agencyId))
        : dispatch(createTask(task, employeeId, agencyId));
};

export const fetchAllTasks = (employeeId, agencyId, onBehalfOf) => async dispatch => {
    try {
        dispatch(tasksDataFetchingSuccess());

        const tasks = await employeeService.getAllTasks(employeeId, agencyId, onBehalfOf);
        dispatch(getAllTasksSuccess(tasks));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(TASK_MESSAGES.FETCH_TASKS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(tasksDataFetchSuccess());
    }
};

export const fetchEmploymentInformation = (agencyId, employeeId, departmentId) => async dispatch => {
    dispatch(employeesEmploymentInformationDataFetchingSuccess());

    try {
        const employmentInformationData = await employeeService.fetchEmploymentInformation(agencyId, employeeId, departmentId);
        dispatch(fetchEmploymentInformationSuccess(employmentInformationData));

        return employmentInformationData;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesEmploymentInformationDataFetchSuccess());
    }
};

export const editGeneralInfo = (info, employeeId, agencyId, departmentId, authUserId) => async dispatch => {
    try {
        const updatedGeneralInfo = await employeeService.updateGeneralInfo(info, employeeId, agencyId, departmentId);

        if (employeeId === authUserId) {
            dispatch(updateAuthUserPositionSuccess(updatedGeneralInfo.position));
        }

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_EMPLOYEE_GENERAL_DETAILS, { rolesPermitted: [ROLES.ADMIN] });
        dispatch(updateGeneralInfo(updatedGeneralInfo));
        dispatch(showNotification(EMPLOYEE_MESSAGES.EDIT_GENERAL_INFO, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateAvatar = (croppedImgData, employeeId, agencyId, departmentId, authUserId) => async dispatch => {
    try {
        const resultUploadedPictureObject = await employeeService.updateAvatar(croppedImgData, employeeId, agencyId, departmentId);

        if (authUserId === employeeId) {
            dispatch(updateAuthUserAvatarSuccess(resultUploadedPictureObject.profilePictureImgUrl));
            dispatch(updateMainMenuInformationSuccess({ menuAvatar: resultUploadedPictureObject.profilePictureImgUrl }))
        }

        dispatch(updateAvatarSuccess(resultUploadedPictureObject));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteAvatar = (employeeId, agencyId, departmentId, authUserId) => async dispatch => {
    try {
        await employeeService.deleteAvatar(employeeId, agencyId, departmentId);

        if (authUserId === employeeId) {
            dispatch(updateAuthUserAvatarSuccess(''));
            dispatch(updateMainMenuInformationSuccess({ menuAvatar: '' }))
        }

        dispatch(deleteAvatarSuccess());
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(AUTH_MESSAGES.SOMETHING_WENT_WRONG, NOTIFICATION_TYPES.ERROR));
    }
};

export const registerEmployee = (employeeData, agencyId, withoutRedux) => async dispatch => {
    try {
        dispatch(showDialogActionButtonLoader());

        const employee = await employeeService.registerEmployee({ employeeData }, agencyId);
        if (employee._id) {
            if (withoutRedux) {
                dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
                return;
            }
            if (employeeData.role === ROLES.EMPLOYEE || employeeData.role === ROLES.SUPPLIER_EMPLOYEE) {
                logEvent(AMPLITUDE_EVENT_TYPES.ADD_TEAM_MEMBER, { rolesPermitted: [ROLES.ADMIN, ROLES.SUPPLIER_ADMIN] }, { isEmailAdded: !!employeeData.email })
            }

            dispatch(fetchRegisterEmployeeSuccess(employee));
            dispatch(showNotification(EMPLOYEE_MESSAGES.ADD_EMPLOYEE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));

            if (employeeData.role === ROLES.RECRUITER_EMPLOYEE) {
                dispatch(fetchRegisterRecruiterEmployee(employee));
            }
        }
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_EMPLOYEE_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));

            if (employeeData.role === ROLES.EMPLOYEE) {
                throw (error);
            }

        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.ADD_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } finally {
        dispatch(hideDialogActionButtonLoader());
    }
};

export const createHolidayRequest = (holidayEntry, employeeId, agencyId, departmentId, accessAsManager) => async dispatch => {
    try {
        const result = await employeeService.createHolidayRequest(holidayEntry, employeeId, agencyId, departmentId);

        logEvent(AMPLITUDE_EVENT_TYPES.REQUEST_HOLIDAY, { rolesPermitted: [ROLES.ADMIN, ROLES.SUPPLIER_EMPLOYEE, ROLES.EMPLOYEE] });
        dispatch(createHolidaysRequestSuccess(result));
        if (accessAsManager) {
            dispatch(createEmployeeHolidaysRequestsSuccess(result));
        }
        if (holidayEntry.status === HOLIDAY_APPROVAL_STATUSES.APPROVED) {
            dispatch(approveHolidayRequestsSuccess(result));
        }
        dispatch(showNotification(HOLIDAYS_MESSAGES.CREATE_HOLIDAY_REQUEST_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(
            error?.message ? error.message : HOLIDAYS_MESSAGES.CREATE_HOLIDAY_REQUEST_FAIL, NOTIFICATION_TYPES.ERROR
        ));
    }
};

export const fetchHolidays = (employeeId, agencyId, departmentId, from, to) => async dispatch => {
    try {
        let holidays;
        if (from && to) {
            holidays = await employeeService.fetchHolidaysForPeriod(employeeId, agencyId, from, to, departmentId);
        } else {
            holidays = await employeeService.fetchHolidays(employeeId, agencyId, departmentId);
        }

        dispatch(fetchHolidaysSuccess(holidays));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const rejectHolidayRequest = (employeeId, agencyId, departmentId, request, accessAsManager) => async dispatch => {
    const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_REJECT_REQUEST_TYPE;
    try {
        if (request.type === HOLIDAY_TYPES.PAID || request.type === HOLIDAY_TYPES.UNPAID) {
            dispatch(showNotification(HOLIDAYS_MESSAGES.START_GENERATING_PDFS, NOTIFICATION_TYPES.INFO, null, NOTIFICATION_CLICKAWAY.DISABLED));
        }
        const result = await employeeService.updateHolidayRequests(employeeId, agencyId, departmentId, request, type);

        dispatch(rejectHolidayRequestsSuccess(result));
        if (accessAsManager) {
            dispatch(rejectHolidayRequestSuccess(result));
        }
        if (request.type === HOLIDAY_TYPES.PAID || request.type === HOLIDAY_TYPES.UNPAID) {
            dispatch(showNotification(HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
        } else {
            dispatch(showNotification(HOLIDAYS_MESSAGES.REJECT_HOLIDAY_REQUEST_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const approveHolidayRequests = (employeeId, agencyId, departmentId, requests, from, to) => async dispatch => {
    const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_APPROVE_REQUEST_TYPE;

    try {
        if (requests[0].type === HOLIDAY_TYPES.PAID || requests[0].type === HOLIDAY_TYPES.UNPAID) {
            dispatch(showNotification(HOLIDAYS_MESSAGES.START_GENERATING_PDFS, NOTIFICATION_TYPES.INFO, null, NOTIFICATION_CLICKAWAY.DISABLED));
        }
        const result = await employeeService.updateHolidayRequests(employeeId, agencyId, departmentId, { requests, from, to }, type);

        if (result) {
            dispatch(approveHolidayRequestsSuccess(result));
            if (requests[0].type === HOLIDAY_TYPES.PAID || requests[0].type === HOLIDAY_TYPES.UNPAID) {
                dispatch(showNotification(HOLIDAYS_MESSAGES.PDFS_GENERATED_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
            } else {
                dispatch(showNotification(HOLIDAYS_MESSAGES.APPROVE_HOLIDAY_REQUEST_SUCCESS, NOTIFICATION_TYPES.SUCCESS, null, NOTIFICATION_CLICKAWAY.DISABLED));
            }
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchHolidayAllowance = (employeeId, agencyId, departmentId) => async dispatch => {
    try {
        const holidayAllowance = await employeeService.fetchHolidayAllowance(employeeId, agencyId, departmentId);
        dispatch(fetchHolidayAllowanceSuccess(holidayAllowance));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.FETCH_HOLIDAY_ALLOWANCE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteHolidayRequests = (employeeId, agencyId, departmentId, requestsIds, isPending, accessAsManager, updateState = true) => async dispatch => {
    const type = HOLIDAY_REQUEST_CONSTANTS.HOLIDAY_DELETE_REQUEST_TYPE;

    try {
        const updatedRequestsIds = await employeeService.updateHolidayRequests(employeeId, agencyId, departmentId, { ids: requestsIds }, type);

        if (updateState) {
            dispatch(deleteHolidayRequestsSuccess(updatedRequestsIds));
            if (isPending) {
                dispatch(deleteHolidayPendingRequestsSuccess(requestsIds));
                if (accessAsManager) {
                    dispatch(deleteHolidayRequestsSuccessAsManager(requestsIds));
                }
            }
        }

        dispatch(showNotification(HOLIDAYS_MESSAGES.DELETE_HOLIDAY_REQUESTS_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DELETE_HOLIDAY_REQUESTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

const generateSignedHolidayFileName = (holiday, user) => {
    let from;
    let to;

    if (holiday.startDate.includes('/') || holiday.startDate.includes('/')) {
        // sometimes date is dd/mm/yy, proper format for moment is mm/dd/yy
        const [startDateDay, startDateMonth, startDateYear] = holiday.startDate.split('/');
        const [endDateDay, endDateMonth, endDateYear] = holiday.endDate.split('/');
        from = moment([startDateMonth, startDateDay, startDateYear].join('/')).format(DATE_FORMAT_WITH_DASH);
        to = moment([endDateMonth, endDateDay, endDateYear].join('/')).format(DATE_FORMAT_WITH_DASH);
    } else {
        // in other cases just take the date as it is
        from = moment(holiday.startDate).format(DATE_FORMAT_WITH_DASH);
        to = moment(holiday.endDate).format(DATE_FORMAT_WITH_DASH);
    }
    const label = holiday.numberOfDays > 1 ? 'Days' : 'Day';
    const byArticle = holiday.type === 'Paid' ? `Art.${holiday.byArticle}_` : '';
    const fileName = `${byArticle}${holiday.numberOfDays}${label}_${holiday.type}Leave_${user.firstName}-${user.lastName}_from${from}_to${to}`;
    return fileName;
}

export const downloadSignedHolidayFile = (agencyId, departmentId, holiday, user) => async dispatch => {
    try {
        const fileName = generateSignedHolidayFileName(holiday, user);
        const isDeleted = holiday.status === HOLIDAY_APPROVAL_STATUSES.DELETED;

        await employeeService.downloadSignedHolidayFile(agencyId, departmentId, holiday.employeeId, holiday._id, fileName, isDeleted);

        dispatch(showNotification(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.toString().includes(HTTP_STATUS_CODES.NOT_FOUND)) {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_NOT_FOUND_FAIL, NOTIFICATION_TYPES.ERROR));
        } else if (error.toString().includes(HTTP_STATUS_CODES.NOT_ACCEPTABLE)) {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_FAIL_UNSIGNED, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
}

export const downloadSignedHolidayFiles = (agencyId, departmentId, employeeId, holidays, user) => async dispatch => {
    try {
        const holidaysInfo = holidays.map(x => {
            const userObj = x.firstName && x.lastName
                ? { firstName: x.firstName, lastName: x.lastName }
                : user;
            return {
                id: x._id,
                employeeId: x.employeeId || employeeId,
                fileName: generateSignedHolidayFileName(x, userObj),
                status: x.status.props ? x.status.props.children : x.status
            }
        });
        const fileName = holidaysInfo.length === 1
            ? holidaysInfo[0].fileName
            : 'holidays-documents';
        await employeeService.downloadSignedHolidayFiles(agencyId, departmentId, employeeId, fileName, holidaysInfo);
        dispatch(showNotification(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.toString().includes('404')) {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_NOT_FOUND_FAIL, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.DOWNLOAD_SIGNED_HOLIDAY_FILE_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
}

export const sendHolidaySignReminderEmail = (agencyId, departmentId, employeeId, holidayId) => async dispatch => {
    try {
        await employeeService.sendHolidaySignReminderEmail(agencyId, departmentId, employeeId, holidayId);
        dispatch(showNotification(HOLIDAYS_MESSAGES.REMIND_HOLIDAY_FILE_SIGN_EMAIL_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.status === 406) {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.REMIND_HOLIDAY_FILE_SIGN_EMAIL_FAIL_406, NOTIFICATION_TYPES.ERROR));
        } else {
            dispatch(authorizedShowNotification(error)(HOLIDAYS_MESSAGES.REMIND_HOLIDAY_FILE_SIGN_EMAIL_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
}

export const fetchEmployeeViewProfileData = (userId, agencyId) => dispatch => {
    try {
        return employeeService.fetchEmployeeViewProfileData(userId, agencyId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_VIEW_PROFILE_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchEmployeeViewPublicProfileData = (userId) => dispatch => {
    try {
        return employeeService.fetchEmployeeViewPublicProfileData(userId);
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_VIEW_PROFILE_DATA_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchEmployeeProjects = (employeeId, agencyId) => async dispatch => {
    try {
        const employeeProjects = await employeeService.fetchEmployeeProjects(employeeId, agencyId);

        dispatch(fetchEmployeeProjectsSuccess(employeeProjects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const fetchEmployeeProjectsWithClientInfo = (employeeId, agencyId) => async dispatch => {
    try {
        const employeeProjects = await employeeService.fetchEmployeeProjectsWithClientInfo(employeeId, agencyId);

        dispatch(fetchEmployeeProjectsSuccess(employeeProjects));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_PROJECTS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const updateEmployeesRole = (agencyId, ids, role, updateState = true) => async dispatch => {
    try {
        const updateInformation = {
            updateData: { ids, role }
        };
        const update = await employeeService.updateEmployeesRole(agencyId, updateInformation);

        updateState && dispatch(updateEmployeesRoleSuccess(update));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_EMPLOYEES_ROLE_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_EMPLOYEES_ROLE_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createBenefitTemplate = (agencyId, employeeId, benefitData) => async dispatch => {
    try {
        const benefits = await employeeService.createBenefitTemplate(agencyId, employeeId, benefitData);

        dispatch(createBenefitTemplateSuccess(benefits));
        dispatch(showNotification(EMPLOYEE_MESSAGES.CREATE_BENEFIT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.CREATE_BENEFIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const createBenefit = (agencyId, employeeId, benefitData) => async dispatch => {
    try {
        const benefits = await employeeService.createBenefit(agencyId, employeeId, benefitData);

        logEvent(AMPLITUDE_EVENT_TYPES.ADD_PERKS_AND_EQUIPMENT);
        dispatch(createBenefitSuccess(benefits));
        dispatch(showNotification(EMPLOYEE_MESSAGES.CREATE_BENEFIT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.CREATE_BENEFIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchBenefitTemplates = (agencyId, employeeId) => async dispatch => {
    try {
        const templatesData = await employeeService.fetchBenefitTemplates(agencyId, employeeId);
        dispatch(fetchBenefitTemplatesSuccess(templatesData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_BENEFIT_TEMPLATES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchBenefits = (agencyId, employeeId) => async dispatch => {
    try {
        dispatch(benefitsDataFetchingSuccess());

        const data = await employeeService.fetchBenefits(agencyId, employeeId);

        dispatch(fetchBenefitsSuccess(data));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_BENEFITS_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(benefitsDataFetchSuccess());
    }
};

export const removeEmployeeBenefit = (agencyId, employeeId, id) => dispatch => {
    try {
        employeeService.deleteBenefit(agencyId, employeeId, id);
        dispatch(removeBenefitSuccess(id));
        dispatch(showNotification(EMPLOYEE_MESSAGES.DELETE_BENEFIT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DELETE_BENEFIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateBenefit = (agencyId, employeeId, benefitId, benefitData) => async dispatch => {
    try {
        const result = await employeeService.updateBenefit(agencyId, employeeId, benefitId, benefitData);

        dispatch(updateBenefitSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_BENEFIT_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_BENEFIT_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchTimeTrackingInformation = (agencyId, employeeId, departmentId, month) => async dispatch => {
    try {
        dispatch(timeTrackingDataFetchingSuccess());

        const timeTracking = await employeeService.fetchTimeTrackingInformation(agencyId, employeeId, departmentId, month);

        dispatch(fetchTrackedDaysSuccess(formatTrackedDays(timeTracking.trackedDays)));
        dispatch(fetchHolidaysSuccess(timeTracking.holidays));
        dispatch(fetchEmployeeProjectsSuccess(timeTracking.employeeProjects));
        dispatch(fetchContractInformationSuccess({ contractInformation: timeTracking.employeeContractInfo }));
        dispatch(employeeGeneralInfoFetchSuccess(timeTracking.generalInfo));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(timeTrackingDataFetchSuccess());
    }
};

export const editContractInformation = (agencyId, departmentId, employeeId, contractInformationData) => async dispatch => {
    try {
        const { contractInformation, employeeEvrotrustStatus } =
            await employeeService.updateContractInformation(agencyId, departmentId, employeeId, contractInformationData);

        dispatch(editContractInformationSuccess({ contractInformation }));
        dispatch(updateEmploymentInformationSuccess({ employeeEvrotrustStatus }));
        dispatch(showNotification(EMPLOYEE_MESSAGES.EDIT_CONTRACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.EDIT_CONTRACT_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const editEmploymentDates = (agencyId, departmentId, employeeId, employmentDatesData) => async dispatch => {
    try {
        const { startDate, terminationDate } = await employeeService.updateEmploymentDates(agencyId, employeeId, departmentId, employmentDatesData);

        dispatch(updateEmploymentDatesSuccess({ startDate: startDate || null, terminationDate: terminationDate || null }));
        dispatch(showNotification(EMPLOYEE_MESSAGES.EDIT_CONTRACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.EDIT_CONTRACT_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchContractInformation = (agencyId, employeeId) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        const contractInformation =
            await employeeService.fetchContractInformation(agencyId, employeeId);

        dispatch(fetchContractInformationSuccess({ contractInformation }));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_CONTRACT_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchEmployeeRelatedOpportunities = (agencyId, employeeId, departmentId) => async dispatch => {
    try {
        dispatch(employeesRelatedOpportunitiesDataFetchingSuccess());

        const res = await employeeService.fetchEmployeeRelatedOpportunities(agencyId, employeeId, departmentId);
        dispatch(fetchEmployeeRelatedOpportunitiesSuccess(res));

        return res;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_RELATED_OPPORTUNITIES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesRelatedOpportunitiesDataFetchSuccess());
    }
};

export const fetchEmployeeDashboardInformation = (agencyId, employeeId, from, to) => async dispatch => {
    try {
        dispatch(dashboardDataFetchingSuccess());

        const result = await employeeService.fetchEmployeeDashboardInformation(agencyId, employeeId, from, to);

        dispatch(fetchEmployeeProjectsSuccess(result.employeeProjects));
        dispatch(fetchApprovedHolidaysSuccess(result.holidays));
        dispatch(fetchUserHasNeededEvrotrustInfoSuccess({ hasNeededEvrotrustInfo: result.hasNeededEvrotrustInfo }));
        return result;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DASHBOARD_MESSAGES.FETCH_DASHBOARD_PAGE_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(dashboardDataFetchSuccess());
    }
};

export const updateEmployeeOccupationStatus = (agencyId, employeeId, data) => async dispatch => {
    try {
        const result = await employeeService.updateEmployeeOccupationStatus(agencyId, employeeId, data);

        dispatch(updateEmployeeOccupationStatusSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_OCCUPATION_STATUS_SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_OCCUPATION_STATUS_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateEmployeeRecruiter = (agencyId, employeeId, data) => async dispatch => {
    try {
        const result = await employeeService.updateEmployeeRecruiter(agencyId, employeeId, data);

        dispatch(updateEmployeeRecruiterSuccess(result));
        dispatch(showNotification(EMPLOYEE_MESSAGES.UPDATE_RECRUITER_SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.UPDATE_RECRUITER_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchEmployeeHolidayPage = (agencyId, employeeId, from, to, departmentId) => async dispatch => {
    try {
        dispatch(employeesHolidayDataFetchingSuccess());

        const result = await employeeService.fetchEmployeeHolidayPage(agencyId, employeeId, from, to, departmentId);

        dispatch(fetchHolidaysSuccess(result.holidays));
        dispatch(fetchHolidayAllowanceSuccess(result.holidayAllowance));
        dispatch(fetchApprovedHolidaysSuccess(result.approvedHolidays));
        dispatch(fetchPendingHolidaysCountSuccess(result.pendingHolidaysCount));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(DASHBOARD_MESSAGES.FETCH_DASHBOARD_PAGE_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeesHolidayDataFetchSuccess());
    }
};



export const fetchEmployeeOverviewInformation = (agencyId, employeeId, departmentId) => async dispatch => {
    try {
        dispatch(employeeOverviewFetchingSuccess());

        const { occupationStatus, reviews, ...rest } = await employeeService.fetchEmployeeOverviewInformation(agencyId, employeeId, departmentId);

        dispatch(fetchEmployeeOverviewInformationSuccess(rest));
        dispatch(fetchEmploymentInformationSuccess({ occupationStatus }));
        dispatch(getAllReviewsSuccess(reviews));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_OVERVIEW_INFORMATION_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(employeeOverviewFetchSuccess());
    }
};

export const fetchEmployeeUploadedCv = (agencyId, employeeId) => async dispatch => {
    dispatch(showApplicationLoader());

    try {
        const uploadedCv = await employeeService.fetchEmployeeUploadedCv(agencyId, employeeId)

        dispatch(fetchEmployeeUploadedCvSuccess(uploadedCv));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.FETCH_EMPLOYEE_UPLOADED_CV_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const downloadEmployeeCv = (agencyId, employeeId, originalFileName) => async dispatch => {
    try {
        const fileName = originalFileName || `${employeeId}-CV`;
        await employeeService.downloadEmployeeCv(agencyId, employeeId, fileName)
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.DOWNLOAD_EMPLOYEE_CV_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateEmployeeCv = (agencyId, employeeId, cvData) => async (dispatch) => {
    try {
        dispatch(cvPageUpdatingSuccess());
        const { generalInfo, ...rest } = await employeeService.updateEmployeeCv(agencyId, employeeId, cvData);

        const profileData = { ...rest, employmentInformation: { generalInfo } }
        dispatch(fetchProfileSuccess(profileData));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.UPDATE_CANDIDATE_CREATE_PROFILE_JOURNEY_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    } finally {
        dispatch(cvPageUpdateSuccess());
    }
};

export const updateWorkspaceMigrationInfo = (agencyId, employeeId, seen = true) => async (dispatch) => {
    try {
        const result = await employeeService.updateWorkspaceMigrationInfo(agencyId, employeeId, { seen });

        dispatch(toggleWorkspaceMigrationDialog(!result.seen));
    } catch (error) {
        dispatch(
            authorizedShowNotification(error)(
                EMPLOYEE_MESSAGES.EMPLOYEE_WORKSPACE_MIGRATION_INFO_UPDATE_FAIL,
                NOTIFICATION_TYPES.ERROR
            )
        );
    }
}

export const employeesTimesheetCompletion = (agencyId, employeeId, from, to) => async (dispatch) => {
    try {
        await employeeService.employeesTimesheetCompletion(agencyId, employeeId, from, to);

        dispatch(showNotification(EMPLOYEE_MESSAGES.COMPLETE_TIMESHEET_SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(EMPLOYEE_MESSAGES.COMPLETE_TIMESHEET_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};
