import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchContracts } from 'actions/contractActions';
import { fetchEmploymentInformation } from 'actions/employeeActions';
import { fetchAgencyEvrotrustInfo, fetchAgencySettings } from 'actions/agencyActions';
import { isAdminMode, isEmployeeMode, getAllContracts } from 'reducers';

import ContractsTable from 'components/Contracts/ContractsTable';
import EvrotrustInfoBanner from 'components/Person/Holidays/EvrotrustInfoBanner';

import { EMPLOYMENT_TYPES } from 'constants/employeeConstants';

const Contracts = ({
    agencyId,
    userId,
    isAdmin,
    isEmployee,
    fetchEmploymentInformation,
    fetchAgencySettings,
    fetchAgencyEvrotrustInfo,
    employmentType,
    fetchContracts
}) => {
    useEffect(() => {
        fetchEmploymentInformation(agencyId, userId);
        if (isAdmin) {
            fetchAgencySettings(agencyId);
            fetchAgencyEvrotrustInfo(agencyId);
        }
    }, []);

    const fetchAction = fetchContracts.bind(null, agencyId, userId);

    return (
        <Fragment>
            {isEmployee && employmentType !== EMPLOYMENT_TYPES.FREELANCE_CONTRACT &&
                <EvrotrustInfoBanner
                    title="Quick and easy signing of contracts"
                    content="Once a new agreement is uploaded, you will automatically receive an email notification to sign it digitally."
                />
            }
            <ContractsTable
                agencyId={agencyId}
                isAdmin={isAdmin}
                userId={userId}
                dataSelector={getAllContracts}
                fetchAction={fetchAction}
            />
        </Fragment>
    )
};

const mapStateToProps = (state) => ({
    isAdmin: isAdminMode(state),
    isEmployee: isEmployeeMode(state),
});

const mapDispatchТoProps = {
    fetchContracts,
    fetchEmploymentInformation,
    fetchAgencySettings,
    fetchAgencyEvrotrustInfo,
};

export default connect(mapStateToProps, mapDispatchТoProps)(Contracts);
