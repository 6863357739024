import React from "react";

const FieldError = ({name}) => {
    const errors = name.children;

    return Array.isArray(errors) 
        ? errors.map((error, i) => <div key={i} className="invalid-field-message"> {error} </div>)
        : <div className="invalid-field-message"> {errors} </div>
};

export default FieldError;
