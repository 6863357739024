import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Welcome from './Welcome';
import OnboardingStep from './OnboardingStep';

import { updateHomeInformation, fetchAgencyName } from 'actions/agencyActions';
import { getDisplayName, isSupplierAdminMode, isEmployeeMode, getDisplayMode, getAgencyName, getMenuInformation } from 'reducers';

import { logEvent } from 'utils/amplitude';

import { ROLES } from 'constants/userConstants';
import { AMPLITUDE_EVENT_TYPES } from 'constants/amplitudeConstants';
import { getOnboardingSteps } from './onboardingConstants';

import './Onboarding.scss';

const Onboarding = ({
    updateHomeInformation,
    fetchAgencyName,
    displayName,
    userId,
    agencyId,
    agencyName,
    displayMode,
    location,
    menuInformation,
}) => {
    const role = displayMode;
    const [isStepperActive, setIsStepperActive] = useState(role === ROLES.SUPPLIER_ADMIN || role === ROLES.SUPPLIER_ONLY);
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        fetchAgencyName(agencyId);
    }, []);

    const onboardingStepsObj = getOnboardingSteps(role, agencyName);

    const maxSteps = onboardingStepsObj.steps.length;

    const gotoUrl = location.state?.gotoUrl;

    const handleNext = () => {
        if (checkIsLastStepActive()) {
            updateHomeInformation(agencyId, userId, { isOnboardingPassed: true }, gotoUrl, role);
            logEvent(AMPLITUDE_EVENT_TYPES.FINISH_ONBOARDING);
            return;
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSkip = () => {
        updateHomeInformation(agencyId, userId, { isOnboardingPassed: true }, gotoUrl, role)
        logEvent(AMPLITUDE_EVENT_TYPES.SKIP_ONBOARDING);
    }

    const checkIsLastStepActive = () => activeStep + 1 === maxSteps;

    return (isStepperActive
        ? <OnboardingStep
            onboardingStepsObj={onboardingStepsObj}
            activeStep={activeStep}
            handleSkip={handleSkip}
            checkIsLastStepActive={checkIsLastStepActive}
            handleNext={handleNext}
            menuInformation={menuInformation}
        />
        : <Welcome 
            agencyId={agencyId}
            userId={userId}
            role={role}
            setIsStepperActive={setIsStepperActive}
            agencyName={agencyName}
            displayName={displayName}
            menuInformation={menuInformation}
            updateHomeInformation={updateHomeInformation}
            gotoUrl={gotoUrl}
        />
    )
};

const mapStateToProps = (state) => ({
    displayName: getDisplayName(state),
    agencyName: getAgencyName(state),
    isSupplierAdmin: isSupplierAdminMode(state),
    isEmployee: isEmployeeMode(state),
    displayMode: getDisplayMode(state),
    menuInformation: getMenuInformation(state),
});

const mapDispatchToProps = {
    updateHomeInformation,
    fetchAgencyName,
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);

