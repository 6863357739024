
import recruiterService from 'services/recruiterService';
import { showNotification } from 'actions/notificationActions';
import { authorizedShowNotification } from './actionHelpers';
import { showApplicationLoader, hideApplicationLoader } from 'actions/applicationLoaderActions';
import { NOTIFICATION_TYPES } from 'constants/notificationTypes';
import { RECRUITER_MESSAGES, EMAIL_MESSAGES } from 'constants/messageConstants';

import {
    RECRUITERS_FETCH_SUCCESS,
    RECRUITERS_REPLACE_SUCCESS,
    RECRUITER_REGISTER_FETCH_SUCCESS,
    RECRUITER_PROFILES_FETCH_SUCCESS,
    RECRUITER_PROFILE_INFO_FETCH_SUCCESS,
    DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS,
    UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS,
    RECRUITER_INFORMATION_FETCH_SUCCESS,
    RECRUITER_AVATAR_UPDATE_SUCCESS,
    RECRUITER_AVATAR_DELETE_SUCCESS,
    RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS,
    RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
} from 'actions/actionTypes';

const fetchRegisterRecruiterSuccess = recruiterData => ({
    type: RECRUITER_REGISTER_FETCH_SUCCESS,
    payload: recruiterData
});

const fetchRecruitersSuccess = recruiters => ({
    type: RECRUITERS_FETCH_SUCCESS,
    payload: recruiters
});

const replaceRecruitersSuccess = recruiters => ({
    type: RECRUITERS_REPLACE_SUCCESS,
    payload: recruiters
});

const fetchRecruitersManagersSuccess = accountManagers => ({
    type: RECRUITERS_ACCOUNT_MANAGERS_FETCH_SUCCESS,
    payload: accountManagers,
});

const fetchRecruiterInformationSuccess = recruiterData => ({
    type: RECRUITER_INFORMATION_FETCH_SUCCESS,
    payload: recruiterData
});

const fetchRecruiterProfilesSuccess = profilesData => ({
    type: RECRUITER_PROFILES_FETCH_SUCCESS,
    payload: profilesData
});

const fetchRecruiterProfileBasicInfoSuccess = basicInfo => ({
    type: RECRUITER_PROFILE_INFO_FETCH_SUCCESS,
    payload: basicInfo
});

const fetchDeleteProfileCVSuccess = () => ({
    type: DELETE_RECRUITER_EMPLOYEE_CV_SUCCESS,
});

const fetchUploadProfileCVSuccess = fileName => ({
    type: UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS,
    payload: fileName,
});

const updateAvatarSuccess = pictureUrl => ({
    type: RECRUITER_AVATAR_UPDATE_SUCCESS,
    payload: pictureUrl
});

const deleteAvatarSuccess = () => ({
    type: RECRUITER_AVATAR_DELETE_SUCCESS
});

const updateRecruiterContactInformationSuccess = contactInfo => ({
    type: RECRUITER_CONTACT_INFORMATION_UPDATE_SUCCESS,
    payload: contactInfo
});

const updateRecruiterBasicInformationSuccess = basicInfo => ({
    type: RECRUITER_BASIC_INFORMATION_UPDATE_SUCCESS,
    payload: basicInfo
});

const updateRecruiterAccountManagerInformationSuccess = accountManagerInfo => ({
    type: RECRUITER_ACCOUNT_MANAGER_INFORMATION_UPDATE_SUCCESS,
    payload: accountManagerInfo
});

export const registerRecruiter = (agencyId, recruiterData, updateState = true) => async dispatch => {
    try {
        const recruiter = await recruiterService.registerRecruiter(agencyId, recruiterData);

        updateState && dispatch(fetchRegisterRecruiterSuccess(recruiter));
        dispatch(showNotification(RECRUITER_MESSAGES.ADD_RECRUITER_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        if (error.message === EMAIL_MESSAGES.EMAIL_ALREADY_USED) {
            dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.ADD_RECRUITER_EMAIL_FAILURE_WARNING, NOTIFICATION_TYPES.WARNING));
        } else if (error.message === EMAIL_MESSAGES.EMAIL_DISABLED) {
            dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.WARNING));
        } else {
            dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.ADD_RECRUITER_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    }
};

export const fetchRecruiters = (agencyId, query, replaceState) => async dispatch => {
    try {
        const result = await recruiterService.fetchRecruiters(agencyId, query);

        if (replaceState) {
            dispatch(replaceRecruitersSuccess(result.recruiters));
        } else {
            dispatch(fetchRecruitersSuccess(result.recruiters));
        }

        return result.totalCount;
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchRecruitersManagers = agencyId => async dispatch => {
    try {
        const result = await recruiterService.fetchRecruitersManagers(agencyId);

        dispatch(fetchRecruitersManagersSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(error.message, NOTIFICATION_TYPES.ERROR));
    }
};

export const fetchRecruiterEmployees = (agencyId, recruiterId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const result = await recruiterService.getRecruiterEmployees(agencyId, recruiterId);
        dispatch(fetchRecruiterProfilesSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.FETCH_RECRUITER_EMPLOYEES_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const fetchRecruiterInformation = (agencyId, recruiterId) => async dispatch => {
    try {
        dispatch(showApplicationLoader());

        const recruiterData = await recruiterService.getRecruiterInformation(agencyId, recruiterId);

        dispatch(fetchRecruiterInformationSuccess(recruiterData));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.FETCH_RECRUITER_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
};

export const fetchRecruiterEmployee = (agencyId, recruiterId, profileId) => async dispatch => {
    dispatch(showApplicationLoader());
    try {
        const result = await recruiterService.getRecruiterEmployee(agencyId, recruiterId, profileId);
        dispatch(fetchRecruiterProfileBasicInfoSuccess(result));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.FETCH_RECRUITER_EMPLOYEE_FAIL, NOTIFICATION_TYPES.ERROR));
    } finally {
        dispatch(hideApplicationLoader());
    }
}

export const updateAvatar = (croppedImgData, recruiterId, agencyId) => async dispatch => {
    try {

        const resultUploadedPictureObject = await recruiterService.updateAvatar(agencyId, recruiterId, croppedImgData);

        dispatch(updateAvatarSuccess(resultUploadedPictureObject));
        dispatch(showNotification(RECRUITER_MESSAGES.UPDATE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.UPDATE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const deleteAvatar = (recruiterId, agencyId) => async dispatch => {
    try {

        await recruiterService.deleteAvatar(agencyId, recruiterId);

        dispatch(deleteAvatarSuccess());
        dispatch(showNotification(RECRUITER_MESSAGES.DELETE_AVATAR_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.DELETE_AVATAR_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateRecruiterBasicInformation = (agencyId, recruiterId, basicInfo) => async dispatch => {
    try {
        const newBasicInfo = await recruiterService.updateRecruiterBasicInformation(agencyId, recruiterId, basicInfo);

        dispatch(updateRecruiterBasicInformationSuccess({ basic: newBasicInfo }));
        dispatch(showNotification(RECRUITER_MESSAGES.UPDATE_BASIC_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.UPDATE_BASIC_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateRecruiterContactInformation = (agencyId, recruiterId, contactInfo) => async dispatch => {
    try {
        const newContactInfo = await recruiterService.updateRecruiterContactInformation(agencyId, recruiterId, contactInfo);

        dispatch(updateRecruiterContactInformationSuccess({ contact: newContactInfo }));
        dispatch(showNotification(RECRUITER_MESSAGES.UPDATE_CONTACT_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.UPDATE_CONTACT_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const updateRecruiterAccountManagerInformation = (agencyId, recruiterId, accountManagerId) => async dispatch => {
    try {
        const newAccountManagerInfo = await recruiterService.updateRecruiterAccountManagerInformation(agencyId, recruiterId, accountManagerId);

        dispatch(updateRecruiterAccountManagerInformationSuccess({ accountManager: newAccountManagerInfo }));
        dispatch(showNotification(RECRUITER_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.UPDATE_ACCOUNT_MANAGER_INFO_FAIL, NOTIFICATION_TYPES.ERROR));
    }
};

export const uploadRecruiterEmployeeCV = (agencyId, recruiterId, profileId, filesData, fileName) => async dispatch => {
    try {
        await recruiterService.uploadRecruiterEmployeeCV(agencyId, recruiterId, profileId, filesData);
        dispatch(fetchUploadProfileCVSuccess(fileName));
        dispatch(showNotification(RECRUITER_MESSAGES.UPLOAD_RECRUITER_EMPLOYEE_CV_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.UPLOAD_RECRUITER_PROFILE_CV_FAILED, NOTIFICATION_TYPES.ERROR));
    }
}

export const downloadRecruiterEmployeeCV = (agencyId, recruiterId, profileId) => async dispatch => {
    try {
        const employeeProfile = await recruiterService.getRecruiterEmployee(agencyId, recruiterId, profileId);
        if (employeeProfile.profileCV) {
            const { cloudName, fileType } = employeeProfile.profileCV;
            const dotIndex = cloudName.lastIndexOf('.');
            const fileNameWithoutExtension = cloudName.substr(0, dotIndex);
            await recruiterService.downloadRecruiterEmployeeCV(agencyId, recruiterId, profileId, cloudName, fileNameWithoutExtension, fileType);
            dispatch(showNotification(RECRUITER_MESSAGES.DOWNLOAD_RECRUITER_PROFILE_CV_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
        } else {
            dispatch(showNotification(RECRUITER_MESSAGES.DOWNLOAD_RECRUITER_PROFILE_CV_MISSING_CV_FAIL, NOTIFICATION_TYPES.ERROR));
        }
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.DOWNLOAD_RECRUITER_PROFILE_CV_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const deleteRecruiterEmployeeCV = (agencyId, recruiterId, profileId) => async dispatch => {
    try {
        await recruiterService.deleteRecruiterEmployeeCV(agencyId, recruiterId, profileId);
        dispatch(fetchDeleteProfileCVSuccess());
        dispatch(showNotification(RECRUITER_MESSAGES.DELETE_RECRUITER_PROFILE_CV_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.DELETE_RECRUITER_PROFILE_CV_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}

export const deleteRecruiterEmployees = (employeesIds) => async dispatch => {
    try {
        await recruiterService.deleteRecruiterEmployees(employeesIds);
        dispatch(showNotification(RECRUITER_MESSAGES.DELETE_RECRUITER_PROFILES_SUCCESS, NOTIFICATION_TYPES.SUCCESS));
    } catch (error) {
        dispatch(authorizedShowNotification(error)(RECRUITER_MESSAGES.DELETE_RECRUITER_PROFILES_FAIL, NOTIFICATION_TYPES.ERROR));
    }
}
