import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';

import {
    getOverallPerformance,
    getPerformanceReviews,
    getPerformanceCriterias,
    getDisplayMode
} from 'reducers';
import { getAllReviews } from 'actions/employeeActions';
import { fetchAgencyPerformanceCriterias } from 'actions/agencyActions';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PerformanceReviewCard from 'components/Person/PerformanceReview/PerformanceReviewsHistory/PerformanceReviewCard';
import PerformanceAnalytics from 'components/Shared/PerformanceAnalytics';
import PerformanceReviewDialog from 'components/Person/PerformanceReview/PerformanceReviewDialog';
import NothingToShow from 'components/NothingToShow';
import { ReactComponent as NoReviewsToShowIcon } from 'assets/no-tasks-to-show.svg';

import './EmployeePerformanceReview.scss';


const EmployeePerformanceReview = ({
    fetchAgencyPerformanceCriterias,
    performanceReviews,
    performanceCriterias,
    getAllReviews,
    agencyId,
    userId,
    role
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [employeeCurrentPerformance, setEmployeeCurrentPerformance] = useState({});

    useEffect(() => {
        getAllReviews(userId, agencyId);
        fetchAgencyPerformanceCriterias(agencyId);
    }, [])


    const handleOpenDialog = (performance) => {
        setEmployeeCurrentPerformance(performance);
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <Fragment>
            <div className="performance-review-history employee-performance-review-history">
                <SectionActionsWrapper>
                    <SectionActionsWrapper.SectionTitle
                        sectionTitle='Review history'
                    />
                </SectionActionsWrapper>

                <div className="review-cards-wrapper">
                    {performanceReviews && performanceReviews.length > 0
                        ? <div className='performance-review-cards-inner-wrapper'>
                            {performanceReviews.map(card =>
                                <ButtonBase className="performance-review-card-button-base" key={card._id} onClick={() => handleOpenDialog(card)}>
                                    <PerformanceReviewCard key={card._id} cardInfo={card} />
                                </ButtonBase>
                            ).reverse()}
                        </div>
                        : <NothingToShow items='Performance Reviews' icon={<NoReviewsToShowIcon />} />
                    }

                </div>
            </div>
            {
                performanceReviews.length > 0 &&
                <PerformanceAnalytics
                    title="Performance"
                    performanceReviews={performanceReviews}
                    performanceCriterias={performanceCriterias}
                />
            }
            <PerformanceReviewDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                performanceToEdit={employeeCurrentPerformance}
                performanceCriterias={performanceCriterias}
                role={role}
            />
        </Fragment>
    )
};

const mapStateToProps = state => ({
    performanceReviews: getPerformanceReviews(state),
    overallPerformance: getOverallPerformance(state),
    performanceCriterias: getPerformanceCriterias(state),
    role: getDisplayMode(state),
});

const mapDispatchToProps = {
    getAllReviews,
    fetchAgencyPerformanceCriterias
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePerformanceReview);
