import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getAgencyRouteName } from 'reducers';
import { transferToAgency } from 'actions/userActions';

import ConfirmMoveToAgencyDialog from 'components/Shared/ConfirmMoveToAgencyDialog';
import SelectEmploymentOptionsDialog from 'components/Shared/SelectEmploymentOptionsDialog';

const MoveToEmployeesDialog = ({
    agencyId,
    userId,
    userName,
    openDialog,
    handleCloseDialog,
    transferToAgency,
    userRole,
    agencyRouteName,
    infoText,
    startDateInitialValue,
}) => {
    const history = useHistory();
    const [openConfirmTransferDialog, setOpenConfirmTransferDialog] = useState(false);
    const [openEmploymentOptionsDialog, setOpenEmploymentOptionsDialog] = useState(openDialog);
    const [employmentOptions, setEmploymentOptions] = useState({});

    const handleCloseConfirmTransferDialog = () => setOpenConfirmTransferDialog(false);
    const handleOpenConfirmTransferDialog = () => setOpenConfirmTransferDialog(true);

    const handleCloseEmploymentOptionsDialog = () => setOpenEmploymentOptionsDialog(false);

    const handleSelectEmployeeOptions = (values) => {
        setEmploymentOptions(values);
        handleOpenConfirmTransferDialog();
    };

    const handleCandidateTransfer = async () => {
        await transferToAgency(agencyId, userId, userRole, employmentOptions);
        handleCloseConfirmTransferDialog();
        setEmploymentOptions({});
        handleCloseDialog();
        history.push(`/${agencyRouteName}/team/people/${userId}`);
    };

    return (
        <>
            <ConfirmMoveToAgencyDialog
                user={userName}
                handleMoveToAgency={handleCandidateTransfer}
                openDialog={openConfirmTransferDialog}
                handleCloseDialog={handleCloseDialog}
            />

            <SelectEmploymentOptionsDialog
                infoText={infoText}
                openDialog={openEmploymentOptionsDialog}
                handleCloseDialog={handleCloseDialog}
                handleSubmit={handleSelectEmployeeOptions}
                handleCloseCurrentDialog={handleCloseEmploymentOptionsDialog}
                startDateInitialValue={startDateInitialValue}
            />
        </>
    )
};

const mapStateToProps = state => ({
    agencyRouteName: getAgencyRouteName(state),
});

const mapDispatchtoProps = {
    transferToAgency
};

export default connect(mapStateToProps, mapDispatchtoProps)(MoveToEmployeesDialog);
