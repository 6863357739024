import api from './api';
import requester from './requester';

const managerService = {
    fetchEmployeesPendingHolidaysRequests: (agencyId, managerId, query) => requester(api.managedEmployeesPendingHolidays(agencyId, managerId, query)).getMany(),
    fetchEmployeesReviewedHolidaysRequests: (agencyId, managerId, query) => requester(api.managedEmployeesReviewedHolidays(agencyId, managerId, query)).getMany(),
    fetchEmployeesSickLeaves:  (agencyId, from, to, managerId) => requester(api.managedEmployeesSickLeaves(agencyId, from, to, managerId)).getMany(),
    updateEmployeesHolidaysRequests: (agencyId, type, request, managerId) => requester(api.managedEmployeesHolidaysRequests(agencyId, type, managerId)).updateMany(request),
    fetchManagersEmployeesApprovedHolidays: (agencyId, from, to, managerId) => requester(api.managedEmployeesApprovedHolidays(agencyId, from, to, managerId)).getMany(),
    fetchManagerEmployeesDocuments: (agencyId, managerId, query) => requester(api.managedEmployeesDocuments(agencyId, managerId, query)).getMany(),
    exportManagersEmployeesDocumentsData: (agencyId, managerId, documentsData, fileName) => requester(api.managedEmployeesDocumentsExport(agencyId, managerId)).saveAsAction(fileName, documentsData),
    downloadManagersEmployeesDocumentsFiles: (agencyId, managerId, documentsData, fileName, query) => requester(api.managedEmployeesDocumentsFiles(agencyId, managerId, query)).saveAsAction(fileName, documentsData),
}
export default managerService;
