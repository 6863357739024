import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';

import { getPersonalInfo, getCurrentUser } from 'reducers';
import { updatePersonalInfo } from 'actions/employeeActions';

import SectionActionsWrapper from 'components/Shared/SectionActionsWrapper';
import PersonalInfoPersonalFormView from './PersonalInfoPersonalFormView';

import { addUtcOffset } from 'utils/formatTimes';
import {
    validateInputLength,
    validateLinkedInURL
} from 'utils/formValidations';

import inputFieldMaxLength from './PersonalInfoPersonalFormConstants';
import './PersonalInfoPersonalForm.scss';

const PersonalInfoPersonalForm = ({
    agencyId,
    employeeId,
    departmentId,
    authUserId,
    updatePersonalInfo,
    personalInfo,
}) => {
    const [showHeaderButtons, setShowHeaderButtons] = useState(false);

    const personalFormRef = useRef();

    const discardHandler = () => {
        setShowHeaderButtons(false);
        personalFormRef.current.values.funFact = personalInfo.funFact || "";
        personalFormRef.current.values.birthdate = personalInfo.birthdate || null;
        personalFormRef.current.values.linkedIn = personalInfo.linkedIn || '';
        personalFormRef.current.values.hobbies = personalInfo.hobbies || '';
    };

    const handleUpdateInfo = async (updatedInfo, resetForm) => {
        await updatePersonalInfo(agencyId, departmentId, employeeId, updatedInfo, authUserId);
        resetForm();
    };

    return (
        <div className="personal-info-wrapper">
            <SectionActionsWrapper>
                <SectionActionsWrapper.SectionTitle
                    sectionTitle='Personal'
                />

                {showHeaderButtons && (
                    <SectionActionsWrapper.RightAlignedActions>
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Discard'
                            onClick={() => discardHandler()}
                            buttonStyle='discard'
                        />
                        <SectionActionsWrapper.RightAlignedActions.SecondaryButton
                            text='Save'
                            onClick={() => personalFormRef.current.handleSubmit()}
                            buttonStyle='save'
                        />
                    </SectionActionsWrapper.RightAlignedActions>
                )}

            </SectionActionsWrapper>


            <Paper className="personal-info-content">
                <Formik
                    initialValues={{
                        funFact: personalInfo.funFact || '',
                        birthdate: personalInfo?.birthdate ? moment.utc(personalInfo.birthdate) : null,
                        linkedIn: personalInfo.linkedIn || '',
                        hobbies: personalInfo.hobbies || ''
                    }}
                    innerRef={personalFormRef}
                    validate={values => {
                        const validateLinkedIn = values.linkedIn ? validateLinkedInURL(values, 'linkedIn') : {};
                        return {
                            ...validateInputLength(values, [{ property: 'funFact', maxLength: inputFieldMaxLength.funFact }]),
                            ...validateLinkedIn
                        };
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);
                        handleUpdateInfo({ ...values, birthdate: addUtcOffset(values.birthdate) }, resetForm);
                        setShowHeaderButtons(false);
                    }}
                    enableReinitialize={true}
                >
                    {(props) =>
                        <PersonalInfoPersonalFormView
                            {...props}
                            handleShowHeaderButtons={() => setShowHeaderButtons(true)}
                        />}
                </Formik>
            </Paper>
        </div>
    )
};

const mapStateToProps = state => ({
    personalInfo: getPersonalInfo(state),
    authUserId: getCurrentUser(state)._id,
});

const mapDispatchToProps = {
    updatePersonalInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoPersonalForm);
