export const taskPeriods = [
    { value: 1, name: 'Current' },
    { value: 2, name: 'Annual report' },
    { value: 3, name: 'Quater 1' },
    { value: 4, name: 'Quater 2' },
    { value: 5, name: 'Quater 3' },
    { value: 6, name: 'Quater 4' },
];

export const taskStatuses = {
    COMPLETED: 'completed',
}
