import React from 'react';
import { Formik, ErrorMessage } from 'formik';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CommonFormikDialog from 'components/Shared/CommonFormikDialog';

import { INTERVIEW_INVITE_OPTIONS } from './InterviewInviteOptionsDialogConstants';

import { validateRequiredFields } from 'utils/formValidations';

import './InterviewInviteOptionsDialog.scss';

const InterviewInviteOptionsDialog = ({
    isDialogOpen,
    handleCloseDialog,
    handleOpenInviteForInterviewDialog,
    handleOpenMarkAsInvitedDialog,
}) => {

    return (
        <Formik
            enableReinitialize={true}
            initialValues={{
                option: ''
            }}
            validate={(values) => {
                return { ...validateRequiredFields(values, ['option']) }
            }}
            onSubmit={(values) => {
                handleCloseDialog();
                values.option === INTERVIEW_INVITE_OPTIONS.INVITE_CANDIDATE
                    ? handleOpenInviteForInterviewDialog()
                    : handleOpenMarkAsInvitedDialog()
            }}
        >
            {(props) => {
                return (
                    <CommonFormikDialog
                        className="interview-invite-options-dialog"
                        onSave={props.handleSubmit}
                        dialogTitle="Invitation for Interview"
                        open={isDialogOpen}
                        onClose={handleCloseDialog}
                        saveBtnText="Save"
                        discardBtnText="Discard"
                        size="sm"
                    >
                        <form autoComplete="off">
                            <RadioGroup
                                value={props.values.option}
                                name="option"
                                className="options-labels-wrapper"
                                onChange={props.handleChange}
                            >
                                {Object.values(INTERVIEW_INVITE_OPTIONS)
                                    .map(option => (
                                        <FormControlLabel
                                            className={`options-label${option === props.values.option ? ' checked' : ''}`}
                                            key={option}
                                            value={option}
                                            control={
                                                <Radio
                                                    className="radio-btn"
                                                    icon={<div className="radio-btn-dot" />}
                                                    checkedIcon={<div className="radio-btn-dot" />}
                                                />
                                            }
                                            label={option}
                                            labelPlacement="start"
                                        />
                                    ))
                                }
                                <ErrorMessage name="option" component="div" className="invalid-field-message" />
                            </RadioGroup>
                        </form>
                    </CommonFormikDialog>
                );
            }}
        </Formik>
    );
};

export default InterviewInviteOptionsDialog;
