import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStateAgencyName } from 'utils/helpers';

import { ROLES } from 'constants/userConstants';

import { fetchPersonalInfo, editGeneralInfo, exportCV, updateEmployeesRole } from 'actions/employeeActions';
import { disableUsers } from 'actions/userActions';
import { getEmployeeBasicInfo, getAgencyId, isSupplierAdminMode, getGeneralInfo, getEmployees, getDisplayMode } from 'reducers';

import ConfirmDeleteDialog from 'components/Shared/ConfirmDeleteDialog';
import ConfirmMoveToAgencyDialog from 'components/Shared/ConfirmMoveToAgencyDialog';
import PersonalInfo from 'components/Person/PersonalInfo';
import Profile from 'components/Person/Profile';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PrimaryExportButton from 'components/Shared/Buttons/PrimaryExportButton';
import SecondaryButton from 'components/Shared/Buttons/SecondaryButton';

import './RecruiterEmployeeProfile.scss';

const RecruiterEmployeeProfile = ({
    agencyId,
    fetchPersonalInfo,
    exportCV,
    recruiterEmployees,
    displayMode,
    disableUsers,
    basicInfo,
    recruiterId,
    updateEmployeesRole,
}) => {

    const history = useHistory();
    const { id: recruiterEmployeeId } = useParams();
    const recruiterEmployee = recruiterEmployees.find(x => x._id === recruiterEmployeeId);

    useEffect(() => {
        fetchPersonalInfo(agencyId, recruiterEmployeeId);
    }, [recruiterEmployeeId]);

    const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
    const [openConfirmMoveToAgencyDialog, setOpenConfirmMoveToAgencyDialog] = useState(false);

    const handleCloseConfirmRemoveDialog = () => setOpenConfirmRemoveDialog(false);

    const handleCloseConfirmMoveToAgencyDialog = () => setOpenConfirmMoveToAgencyDialog(false);

    const removeRecruiterEmployeeClickHandler = () => setOpenConfirmRemoveDialog(true);

    const moveToAgencyClickHandler = () => setOpenConfirmMoveToAgencyDialog(true);

    const handleRemoveRecruiterEmployee = () => {
        disableUsers([recruiterEmployeeId], ROLES.RECRUITER_EMPLOYEE);
        setOpenConfirmRemoveDialog(false);
        history.push(`/${getStateAgencyName()}/recruiter/${recruiterId}/people`);
    };

    const moveRecruiterEmployeeToAgencyEmployeeHandler = async () => {
        await updateEmployeesRole(agencyId, [recruiterEmployeeId], ROLES.EMPLOYEE);
        history.push(`/${getStateAgencyName()}/team/people/${recruiterEmployeeId}`);
    };

    return (
        <div className="recruiter-employee-profile-wrapper">
            <div className="additional-actions-wrapper">
                <div className="buttons-wrapper">
                    <PrimaryButton className="move-to-agency-button" text="Move To Agency" handleClick={moveToAgencyClickHandler} />
                    <PrimaryExportButton className="export-cv-button" text="Export CV" handleClick={() => exportCV([recruiterEmployee], agencyId)} />
                    <SecondaryButton text="Delete" handleClick={removeRecruiterEmployeeClickHandler} buttonStyle="delete" />
                </div>
            </div>

            <PersonalInfo agencyId={agencyId} userId={recruiterEmployeeId}>
                <PersonalInfo.BasicForm />
            </PersonalInfo>

            <Profile agencyId={agencyId} userId={recruiterEmployeeId} >
                <Profile.UploadCV recruiterId={recruiterId}>
                    <Profile.UploadCV.UploadCvRecruiter />
                </Profile.UploadCV>
                <Profile.GeneralForm >
                    <Profile.GeneralForm.ActionsWrapper />
                    <Profile.GeneralForm.FormWrapper />
                </Profile.GeneralForm>
                <Profile.Languages />
                <Profile.Skills />
                <Profile.Education />
                <Profile.Certificates />
                <Profile.Projects />
            </Profile>

            <ConfirmDeleteDialog
                itemToDelete={`${basicInfo.firstName} ${basicInfo.lastName}`}
                handleDeleteItem={handleRemoveRecruiterEmployee}
                openDialog={openConfirmRemoveDialog}
                handleCloseDialog={handleCloseConfirmRemoveDialog}
            />

            <ConfirmMoveToAgencyDialog
                user={`${basicInfo.firstName} ${basicInfo.lastName}`}
                handleMoveToAgency={moveRecruiterEmployeeToAgencyEmployeeHandler}
                openDialog={openConfirmMoveToAgencyDialog}
                handleCloseDialog={handleCloseConfirmMoveToAgencyDialog}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    basicInfo: getEmployeeBasicInfo(state),
    agencyId: getAgencyId(state),
    isSupplierAdminMode: isSupplierAdminMode(state),
    generalInfo: getGeneralInfo(state),
    recruiterEmployees: getEmployees(state),
    displayMode: getDisplayMode(state),
});

const mapDispatchtoProps = {
    fetchPersonalInfo,
    exportCV,
    editGeneralInfo,
    disableUsers,
    updateEmployeesRole,
};

export default connect(mapStateToProps, mapDispatchtoProps)(RecruiterEmployeeProfile);
