import React, { useState } from 'react';

import DateRangePicker from 'components/Shared/DateRangePicker';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as DateFilterIcon } from 'assets/table-date-filter-icon.svg';

import './DateRangeFilter.scss';

const DateRangeFilter = ({
    title,
    className,
    values,
    handleChange,
    handleClear,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpen = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return (
        <div className="date-range-picker-wrapper">
            <Button
                className={`date-range-picker-button${className ? ` ${className}` : ''}${isMenuOpen || values.length > 0 ? ` active` : ''}`}
                variant="outlined"
                onClick={handleOpen}
            >
                <DateFilterIcon className="calendar-icon" />
                <Typography variant="body1">{title}</Typography>
                {values.length > 0 && <CloseIcon onClick={handleClear} />}
            </Button>
            <Popover
                className={`date-range-picker-popover${className ? ` ${className}` : ''}`}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        marginTop: '10px',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: '10px',
                        width: '210px',
                        padding: '8px 5px 11px',
                    }
                }}
            >
                <DateRangePicker
                    values={values}
                    handleChange={handleChange}
                />
            </Popover>
        </div>
    );
};

export default DateRangeFilter;
