import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { getAgencyName } from 'reducers';

import {
    addInvoice,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
} from 'actions/invoiceActions';

import DashboardSectionWrapper from '../../SharedDashboardComponents/DashboardSectionWrapper';
import InvoiceCard from './InvoiceCard';
import AddInvoiceDialog from 'components/Billing/AddInvoiceDialog';
import PrimaryAddButton from 'components/Shared/Buttons/PrimaryAddButton';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { getStateAgencyName, concatName } from 'utils/helpers';

import './ClientInvoices.scss';

const ClientInvoices = ({
    agencyId,
    userId,
    invoices,
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    invoicerRole,
    isSupplierAdmin,
    addInvoice,
    agencyName,
}) => {
    const history = useHistory();

    const [openAddInvoiceDialog, setOpenAddInvoiceDialog] = useState(false);
    const handleCloseInvoiceDialog = () => setOpenAddInvoiceDialog(false);
    const handleOpenInvoiceDialog = () => {
        setOpenAddInvoiceDialog(true);
    };

    const downloadInvoice = invoice => {
        let fileName = '';
        fileName = `${concatName(invoice.projectName)}-Invoice`;

        return downloadInvoiceFiles(agencyId, userId, invoice._id, fileName.toLocaleLowerCase());
    };

    const downloadTimesheets = invoice => {
        const period = moment.utc(invoice.period).format('MM-YYYY');
        const projectData = {
            name: invoice.projectName,
            _id: invoice.projectId
        };

        exportInvoiceTimesheets(agencyId, invoice._id, period, projectData, userId, invoicerRole);
    };

    const handleViewAllClick = () => {
        history.push(`/${getStateAgencyName()}/billing`);
    };

    return (
        <div>
            <DashboardSectionWrapper classes={['client-dashboard-invoices-wrapper']}>
                <div className="titles-wrapper">
                    <div>
                        {
                            isSupplierAdmin
                                ? <DashboardSectionWrapper.SectionTitle>Outbound Invoices</DashboardSectionWrapper.SectionTitle>
                                : <DashboardSectionWrapper.SectionTitle>Outstanding Invoices</DashboardSectionWrapper.SectionTitle>
                        }

                        {
                            isSupplierAdmin
                                ? <DashboardSectionWrapper.SectionSubTitle>
                                    This list contains the invoices you've sent to {agencyName} for the services your team has completed.
                                </DashboardSectionWrapper.SectionSubTitle>
                                : <DashboardSectionWrapper.SectionSubTitle>
                                    This list contains all your outstanding invoices which are overdue or have their due date coming up soon.
                                </DashboardSectionWrapper.SectionSubTitle>
                        }
                    </div>
                    {isSupplierAdmin &&
                        <PrimaryAddButton
                            text='Add Invoice'
                            handleClick={handleOpenInvoiceDialog}
                        />
                    }
                </div>


                <DashboardSectionWrapper.SectionContent>
                    <>
                        <div className="invoices-cards-wrapper">
                            {invoices.map(invoice => {
                                return (
                                    <InvoiceCard
                                        key={invoice._id}
                                        invoice={invoice}
                                        downloadTimesheets={downloadTimesheets}
                                        downloadInvoice={downloadInvoice}
                                    />
                                )
                            })}
                        </div>
                        <div className="view-all-action-btn-wrapper">
                            <PrimaryButton
                                text='View All'
                                handleClick={handleViewAllClick}
                            />
                        </div>
                    </>
                </DashboardSectionWrapper.SectionContent>
            </DashboardSectionWrapper>

            {
                isSupplierAdmin &&
                <AddInvoiceDialog
                    userId={userId}
                    agencyId={agencyId}
                    openDialog={openAddInvoiceDialog}
                    handleCloseDialog={handleCloseInvoiceDialog}
                    addInvoice={addInvoice}
                    isDataAvailable={true}
                />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    agencyName: getAgencyName(state),
});

const mapDispatchToProps = {
    downloadInvoiceFiles,
    exportInvoiceTimesheets,
    addInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInvoices);
