import {
    FILTER_DEFAULT_STATE,
    VETTING_STATUS_FILTER_VALUES,
    AVAILABILITY_FILTER_VALUES,
    PROFILE_TYPE_FILTER_VALUES,
    INVITATION_STATUS_FILTER_VALUES,
    LOCATION_FILTER_VALUES,
} from 'constants/matchingConsultantsConstants';

export const mapQueryToFilters = (queryObj) => {
    const initFilters = { ...FILTER_DEFAULT_STATE };

    const newFilters = Object.keys(queryObj).reduce((acc, x) => {
        let foundItem;

        switch (x) {
            case 'vettingStatus':
                foundItem = VETTING_STATUS_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.vettingStatus = foundItem ? foundItem : acc.vettingStatus;
                break;
            case 'availability':
                foundItem = AVAILABILITY_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.availability = foundItem ? foundItem : acc.availability;
                break;
            case 'profileType':
                foundItem = typeof queryObj[x] === 'string'
                    ? [PROFILE_TYPE_FILTER_VALUES.find((y) => y === queryObj[x])]
                    : queryObj[x].filter((y) => PROFILE_TYPE_FILTER_VALUES.includes(y));
                acc.profileType = foundItem ? foundItem : acc.profileType;
                break;
            case 'invitationStatus':
                foundItem = INVITATION_STATUS_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.invitationStatus = foundItem ? foundItem : acc.invitationStatus;
                break;
            case 'candidateLocation':
                foundItem = LOCATION_FILTER_VALUES.find((y) => y === queryObj[x]);
                acc.candidateLocation = foundItem ? foundItem : acc.candidateLocation;
                break;
            default:
                break;
        }
        return acc;
    }, initFilters);

    return newFilters;
};
