import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ErrorMessage } from 'formik';
import PasswordInput from 'components/Common/PasswordInput';
import TermsAndConditionsCheckBox from 'components/Shared/TermsAndConditionsCheckBox';
import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';

import { IS_WHOISAVAILABLE_ENV } from 'constants/env';

import '../PasswordSet.scss';

const PasswordSetFormView = (props) => (
    <form className="pwd-set-form" autoComplete="off">
        <div>
            <PasswordInput
                className="pwd-set-field"
                name="password"
                handleChange={props.handleChange}
                label="Password"
                id="pwd-set"
                error={props.errors.password}
                touched={props.touched.password}
            />
            <ErrorMessage name="password" component="div" className="invalid-field-message" />
        </div>
        <div>
            <PasswordInput
                className="pwd-set-field"
                name="confirmPassword"
                handleChange={props.handleChange}
                label="Confirm Password"
                id="pwd-set-confirm"
                error={props.errors.confirmPassword}
                touched={props.touched.confirmPassword}
            />
            <ErrorMessage name="confirmPassword" component="div" className="invalid-field-message" />
        </div>
        {!IS_WHOISAVAILABLE_ENV &&
            <div>
                <TermsAndConditionsCheckBox
                    isTermsAndConditionsAccepted={props.values.isTermsAndConditionsAccepted}
                    handleChange={props.handleChange}
                />
                <ErrorMessage name="isTermsAndConditionsAccepted" component="div" className="invalid-field-message" />
            </div>
        }
        <PrimaryButton
            text={props.loginLoading ? 'Set Password' : <CircularProgress color={'inherit'} size={16} />}
            type="submit"
            className="set-pwd-btn"
            handleClick={props.handleSubmit}
        />
    </form>
);

export default PasswordSetFormView;
