export const SHORT_DESCRIPTION_LENGTH = 500;

export const LOCATION_TYPES = {
    MATCHING_JOB_REQUIREMENTS: 'Matching Job Requirements',
    ALL_LOCATIONS: 'All Locations',
};

export const LOCATION_FILTER_VALUES = [
    LOCATION_TYPES.MATCHING_JOB_REQUIREMENTS,
    LOCATION_TYPES.ALL_LOCATIONS,
];

export const PROFILE_MATCHING_JOBS_FILTERS = {
    location: LOCATION_TYPES.ALL_LOCATIONS,
};

export const NOT_UNSELECTABLE_FILTERS = ['location'];

export const PROFILE_MATCHING_JOBS_FILTER_LIST_ITEMS = {
    location: { name: 'Candidate Location', values: LOCATION_FILTER_VALUES },
};
