import React from 'react';
import { Link } from 'react-router-dom';

import { getStateAgencyName } from 'utils/helpers';
import PopUpItemsEmployee from './PopUpItemsEmployee';
import PopUpItemsDedicatedEmployee from './PopUpItemsDedicatedEmployee';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Cancel';

import { ROLES } from 'constants/userConstants';
import { DIALOG_TYPES } from 'constants/clientProjectConstants';
import './EmployeeProfilePopUp.scss';

const EmployeeProfilePopUp = ({
	isOpen,
	employeeType,
	popUpPosition,
	handlePopoverClose,
	handlePopoverOpen,
	currentIdSelected,
	employees,
	handleEditEmployeeDialog,
	isClientMode,
	isAdminMode,
	isEmployeeMode,
	projectCurrency,
	handleOpenSubmitFeedbackPopup,
	isClientProjectsModuleModified,
	handleOpenDeleteDialog,
}) => {
	let employee = employees.onDemandEmployees.find((x) => x._id === currentIdSelected);
	
	if (!employee) {
		employee = employees.dedicatedEmployees.find((x) => x._id === currentIdSelected);
	}

	const edit = () => {
		const editObjectFull = {
			employee: { _id: employee._id, employeeId: employee.employeeId },
			currency: projectCurrency,
			hourRate: employee.hourRate,
			firstName: employee.firstName || '',
			lastName: employee.lastName || '',
			dateAdded: employee.dateAdded,
			billingType: employee.billingType || '',
			employerTotalCost: employee.employerTotalCost,
			monthlyServiceFee: employee.monthlyServiceFee
		};

		const type = isClientProjectsModuleModified ? DIALOG_TYPES.BILLING_TYPE : employeeType;

		handleEditEmployeeDialog(editObjectFull, type);
	};

	const remove = () => {
		handleOpenDeleteDialog(employee, employeeType)
		handlePopoverClose();
	};

	const handleEmployeeClick = (id) => {
		if (isEmployeeMode) {
			return;
		}
		if (id) {
			const supplierId = (employee.role === ROLES.SUPPLIER_ADMIN) ? employee.employeeId : employee.supplierId;
			const employeeProfileLink = (employee.role === ROLES.EMPLOYEE || employee.role === ROLES.ADMIN) ? `/${getStateAgencyName()}/team/people/${id}` : `/${getStateAgencyName()}/partners/${supplierId}/team/people/${id}`
			return isAdminMode ? employeeProfileLink : `/${getStateAgencyName()}/explore-talents/${id}`;
		}
	};

	return (
		<>
			{isOpen && employee && (
				<div
					className="employee-profile-popup-container"
					style={popUpPosition}
					onMouseEnter={() =>
						handlePopoverOpen(currentIdSelected, employeeType)
					}
					onMouseLeave={handlePopoverClose}>
					<div className="employee-header-container">
						<Link
							className="employee-full-name-container"
							to={handleEmployeeClick(employee?.employeeId)}>
							<span className="full-name">{`${employee?.firstName} ${employee?.lastName}`}</span>
						</Link>
						{!isClientMode && !isEmployeeMode && (
							<div className="employee-manage-options">
								<div>
									<IconButton className="button-container" onClick={edit}>
										<EditIcon className="button-icon" />
									</IconButton>
								</div>
								<div>
									<IconButton className="button-container" onClick={remove}>
										<RemoveIcon className="button-icon" />
									</IconButton>
								</div>
							</div>
						)}
					</div>
					<div className="employee-job-title-container">
						<span className="job-title">
							{employee?.employmentInformation?.generalInfo?.position}
						</span>
					</div>

					{employeeType === DIALOG_TYPES.CONTRACT && !isEmployeeMode && (
						<PopUpItemsEmployee
							employee={employee}
							isClientMode={isClientMode}
							projectCurrency={projectCurrency}
							handleOpenSubmitFeedbackPopup={handleOpenSubmitFeedbackPopup}
						/>
					)}

					{employeeType === DIALOG_TYPES.FULL_TIME && !isEmployeeMode && (
						<PopUpItemsDedicatedEmployee
							employee={employee}
							isClientMode={isClientMode}
							projectCurrency={projectCurrency}
							handleOpenSubmitFeedbackPopup={handleOpenSubmitFeedbackPopup}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default EmployeeProfilePopUp;
