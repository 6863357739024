import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { ROLES } from 'constants/userConstants';

import logoImg from 'assets/default-logo.svg';

const SearchMenuView = ({
    results,
    handleClick,
    handleBlur
}) => (
        <List className='results-list'>
            {results.map((result, index) => (
                <div key={index} className='result-list-item'>
                    <ListItem
                        alignItems="center"
                        button
                        onClick={handleClick ? handleClick.bind(null, handleBlur, result) : null}
                    >
                        <ListItemAvatar>
                            <Avatar className="search-result-avatar" alt={result.name || `${result.firstName} ${result.lastName}`} 
                            src={result.role === ROLES.ADMIN || result.role === ROLES.EMPLOYEE || result.role === ROLES.SUPPLIER_EMPLOYEE || result.role === ROLES.CANDIDATE ? 
                                    result.profilePictureImgUrl :
                                    (result.logoImgUrl ? result.logoImgUrl : logoImg)} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={result.name || `${result.firstName} ${result.lastName}`}
                            secondary={result.position}
                        />
                    </ListItem>
                    {results.length !== index + 1 && <Divider component="li" />}
                </div>
            ))}
        </List >
    );

export default SearchMenuView;
