import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getStateAgencyName } from 'utils/helpers';
import { createReview } from 'actions/employeeActions';
import { updateClientProject, deleteProjectEmployee, deleteDedicatedEmployeeFromProject, submitFeedbackToProject } from 'actions/clientActions';
import {
	getDisplayName,
	getPerformanceCriterias,
	getClientMenuInformation,
	getClientInvoicingInformation,
	isClientMode,
	isEmployeeMode,
	isSupplierEmployeeMode,
	isAdminMode,
} from 'reducers';
import isHoliday from 'hoc/isHoliday';

import PrimaryButton from 'components/Shared/Buttons/PrimaryButton';
import PerformanceReviewDialog from 'components/Person/PerformanceReview/PerformanceReviewDialog';
import SubmitFeedbackDialog from 'components/Shared/SubmitFeedbackDialog';

import CardHeader from './CardHeader';
import CardDescription from './CardDescription';
import CardContact from './CardContact';
import CardPeople from './CardPeople';
import AddDialog from '../../AddProjectsDialog';

import { feedbackCriteria } from '../../ProjectsConstants';
import { CURRENCIES, defaultCurrencyEUR } from 'constants/currenciesConstant';

import './CardDetails.scss';

const CardDetails = ({
	project,
	updateClientProject,
	submitFeedbackToProject,
	isClientMode,
	isEmployeeMode,
	isSupplierEmployeeMode,
	isAdminMode,
	clientInvoicing,
	performanceCriterias,
	displayName,
	clientMenuInformation,
	createReview,
	handleAddProject,
	isHoliday,
}) => {
	const history = useHistory();
	const isProjectsPage = history.location.pathname === `/${getStateAgencyName()}/projects/${project._id}/clients/${project.clientId}`;
	const { clientId, agencyId } = project;
	const clientInvoicingCurrency = (clientInvoicing.currency ? clientInvoicing.currency : defaultCurrencyEUR);
	const [currentIdSelected, setCurrentIdSelected] = useState();
	const [openDialog, setOpenDialog] = useState(false);
	const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

	const [isSubmitFeedbackOpen, setIsSubmitFeedbackOpen] = useState(false);

	const handleFeedbackSubmit = (feedback) => {
		const { details, ...ratings } = feedback;
		const feedbackWithReviewer = { details, ratings, reviewer: clientMenuInformation.contactPerson };
		submitFeedbackToProject(agencyId, clientId, project._id, feedbackWithReviewer);
	};

	const handleEditProject = project => updateClientProject(project, agencyId, clientId);

	const handleCloseDialog = () => setOpenDialog(false);

	const handleCloseFeedbackDialog = () => setOpenFeedbackDialog(false);

	const handleEditClick = () => setOpenDialog(true);

	const handleSendFeedbackClick = () => setOpenFeedbackDialog(true);

	const handleViewFeedbackClick = () => history.push(`/${getStateAgencyName()}/clients/${clientId}/projects/reviews/${project._id}`);

	const handleViewProjectDetails = () => history.push(`/${getStateAgencyName()}/projects/${project._id}/clients/${clientId}`, { goBackUrl: history.location.pathname });

	const handleOpenSubmitFeedbackDialog = () => setIsSubmitFeedbackOpen(true);
	const handleCloseSubmitFeedbackDialog = () => setIsSubmitFeedbackOpen(false);

	const projectCurrency = CURRENCIES.find(currency => currency.VALUE === clientInvoicingCurrency);

	return (
		<div className="client-project-wrapper">
			{isProjectsPage && <h1 className="client-project-title">Project Overview</h1>}
			<div className={`client-project-container${!isProjectsPage ? ' clickable' : ''}`} onClick={!isProjectsPage ? handleViewProjectDetails : null}>
				<CardHeader project={project} isAdminMode={isAdminMode} />
				<CardDescription
					project={project}
					projectCurrency={projectCurrency}
					isEmployeeMode={isEmployeeMode}
					clientName={clientMenuInformation.name}
				/>
				<CardContact project={project} />
				<CardPeople
					project={project}
					agencyId={agencyId}
					clientId={clientId}
					isClientMode={isClientMode}
					isEmployeeMode={isEmployeeMode}
					projectCurrency={projectCurrency}
					isClientInvoicingCompleted={clientInvoicing?.currency}
					handleOpenSubmitFeedbackDialog={handleOpenSubmitFeedbackDialog}
					currentIdSelected={currentIdSelected}
					setCurrentIdSelected={setCurrentIdSelected}
				/>

				<div className="client-project-container-btns-wrapper">
					{(!isClientMode && !isEmployeeMode && !isSupplierEmployeeMode && isProjectsPage) && <PrimaryButton text="Edit" handleClick={handleEditClick} />}
					{((!isClientMode && !isEmployeeMode && !isSupplierEmployeeMode && isProjectsPage) && project.hasClientReviews) && <PrimaryButton text="View Feedback" handleClick={handleViewFeedbackClick} />}
					{(isClientMode && !isEmployeeMode && isProjectsPage) && <PrimaryButton text="Submit Feedback" handleClick={handleSendFeedbackClick} />}
				</div>

				<AddDialog
					openDialog={openDialog}
					projectInfo={project}
					handleCloseDialog={handleCloseDialog}
					handleAddProject={handleAddProject}
					handleEditProject={handleEditProject}
					renderHolidays={isHoliday}
				/>
				<SubmitFeedbackDialog
					openDialog={openFeedbackDialog}
					handleCloseDialog={handleCloseFeedbackDialog}
					feedbackCriteria={feedbackCriteria}
					handleSubmit={handleFeedbackSubmit}
				/>
				<PerformanceReviewDialog
					openDialog={isSubmitFeedbackOpen}
					handleCloseDialog={handleCloseSubmitFeedbackDialog}
					displayName={displayName}
					performanceCriterias={performanceCriterias}
					handleReview={createReview}
					agencyId={agencyId}
					userId={currentIdSelected}
					project={project}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	displayName: getDisplayName(state),
	performanceCriterias: getPerformanceCriterias(state),
	clientMenuInformation: getClientMenuInformation(state),
	clientInvoicing: getClientInvoicingInformation(state),
	isClientMode: isClientMode(state),
	isEmployeeMode: isEmployeeMode(state),
	isSupplierEmployeeMode: isSupplierEmployeeMode(state),
	isAdminMode: isAdminMode(state),
});

const mapDispatchToProps = {
	submitFeedbackToProject,
	updateClientProject,
	deleteProjectEmployee,
	deleteDedicatedEmployeeFromProject,
	createReview,
	isHoliday,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);
