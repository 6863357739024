import React, { Fragment } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const SearchEmployeeResultsDropdown = ({
    peopleSearchResults,
    handleResultClick,
}) => (
        <div className="search-results-dropdown" onMouseDown={(e) => e.preventDefault()}>
            {peopleSearchResults.length > 0 &&
                <Fragment>
                    <List className="people-results-list">
                        {peopleSearchResults.map((result, index) => (
                            <div key={result._id} className="result-list-item">
                                <ListItem
                                    alignItems="flex-start"
                                    button
                                    onClick={(e) => handleResultClick(result)}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt={`${result.firstName} ${result.lastName}`} src={result.profilePictureImgUrl} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${result.firstName} ${result.lastName}`}
                                        secondary={result.position}
                                    />
                                </ListItem>
                                {peopleSearchResults.length !== index + 1 && <Divider component="li" />}
                            </div>
                        ))}
                    </List >
                </Fragment>
            }
        </div>
    );

export default SearchEmployeeResultsDropdown;
